/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Drawer,
  Fade,
  Modal,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  Grid,
  TextareaAutosize,
  FormControlLabel,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import {
  RiAddCircleLine,
  RiFilter2Line,
  RiSearchLine,
  RiCloseLine,
  RiArrowDownSLine,
  RiShareLine,
  RiPencilLine,
  RiDeleteBinLine,
  RiCloseCircleLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import ProjectList from "./projectList";
import { createFilter } from "../../store/slices/createFilterSlice";
import { useDispatch } from "react-redux";
import { fetchKeywords } from "../../store/slices/keywordSlice";
import { fetchTechnologies } from "../../store/slices/technologySlice";
import { fetchFilters } from "../../store/slices/filterSlice";

import { deleteFilterById } from "../../store/slices/filterSlice";

import { fetchFiltersById } from "../../store/slices/filterByIdSlice";
import { editfilterByIdAdmin } from "../../store/slices/editFilterSlice";
import { successToast } from "../../responses/successToast";
import { fetchUsers } from "../../store/slices/userSlice";
import { createShareFilter } from "../../store/slices/sharedFilterSlice";
import { fullProjects, updateProjects } from "../../utils/permissions";
import WithPermission from "../../components/common/withPermission";
import { fetchProjects } from "../../store/slices/projectSlice";
import { DateRangePicker } from "rsuite";
import { getDateRangeOptions } from "../../utils/DateRanges";
import { useLocation } from "react-router-dom";
import "../../styles/projects.scss";
import { styled } from "@mui/material/styles";
// SIDEBAR DRAWER TABBING PANEL
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Projects = () => {
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      status: "live",
      page: 1,
      rowsPerPage: 10,
      filterApplied: false,
      filterByIdApplied: false,
      applyFilterId: "",
      selectedKeywords: [],
      selectedTechnologies: [],
      selectedTLandBDAs: [],
      text: "",
      startDate: [],
      endDate: [],
      date: "",
      destatus: null,
      edit: false,
      keywordSwitchVal: true,
      techStackSwitchVal: true,
    };
  }
  const [rowsPerPage, setRowsPerPage] = useState(state.rowsPerPage);
  const [liveCurrentPage, setliveCurrentPage] = useState(1);
  const [developmentCurrentPage, setdevelopmentCurrentPage] = useState(1);
  const [betaCurrentPage, setbetaCurrentPage] = useState(1);
  const [abandonedCurrentPage, setabandonedCurrentPage] = useState(1);
  const [favouriteCurrentPage, setfavouriteCurrentPage] = useState(1);
  const [livefilterCurrentPage, setlivefilterCurrentPage] = useState(
    state.filterByIdApplied && state.status === "live" ? state.page : 1
  );
  const [developmentfilterCurrentPage, setdevelopmentfilterCurrentPage] =
    useState(
      state.filterByIdApplied && state.status === "developing" ? state.page : 1
    );
  const [betafilterCurrentPage, setbetafilterCurrentPage] = useState(
    state.filterByIdApplied && state.status === "beta_testing" ? state.page : 1
  );
  const [abandonedfilterCurrentPage, setabandonedfilterCurrentPage] = useState(
    state.filterByIdApplied && state.status === "abandoned" ? state.page : 1
  );
  const [searchProject, setSearchProject] = useState("");
  const keywords = useSelector((state) => state.keywords.keywords);
  const technologies = useSelector((state) => state.technologies.technologies);
  const [selectedKeywords, setSelectedKeywords] = useState(
    state.selectedKeywords
  );
  const [keywordPayload, setKeywordPayload] = useState([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState(
    state.selectedTechnologies
  );
  const [selectedTLandBDAs, setSelectedTLandBDAs] = useState(
    state.selectedTLandBDAs
  );
  const [technologyPayload, setTechnologyPayload] = useState([]);
  const [TLandBDAsPayload, setTLandBDAsPayload] = useState([]);
  const [searchText, setSearchtext] = useState(state.text);
  const [filterName, setFilterName] = useState("");
  const [searchTextError, setSearchTextError] = useState("");
  const [filterNameError, setFilterNameError] = useState("");
  const filters = useSelector((state) => state.filters.filters);
  const [filterId, setFilterId] = useState("");
  const [keywordname, setKeyWordname] = useState([]);
  const [technologyName, setTechnologyName] = useState([]);
  const [TLandBDAname, setTLandBDAname] = useState([]);
  const [filterApplied, setFilterApplied] = useState(state.filterApplied);
  const [filterByIdApplied, setFilterByIdApplied] = useState(
    state.filterByIdApplied
  );
  const [editSearchText, setEditSearchText] = useState("");
  const [editFilterName, setEditFilterName] = useState("");
  const [destatus, setDeStatus] = useState(state.destatus);
  const [editFilerId, setEditFilterId] = useState("");
  const [editsearchTextError /*setEditSearchTextError*/] = useState("");
  const [editFilterNameError, setEditFilterNameError] = useState("");

  const [isCreating, setIsCreating] = useState(true);
  const [applyFilterId, setApplyFilterId] = useState(state.applyFilterId);
  const [applyfiltersearchtext, setApplyFilterSearchText] = useState("");

  const [applyfilterkeywordid, setApplyFilterKeywordId] = useState([]);

  const [applyfiltertechnologyid, setApplyFilterTechnologyId] = useState([]);
  const [removeKeyId, setRemoveKeyId] = useState([]);
  const [removeTechId, setRemoveTechId] = useState([]);
  const [removeTLandBdaId, setRemoveTLandBdaId] = useState([]);
  const [ActivityLog, setActivityLogModal] = useState(false);
  const [editedFilterPayload, setEditedFilterPayload] = useState({
    keyword_id: [],
    technology_id: [],
    tlAndBda_id: [],
  });
  const [applyfilterpayload, setApplyFilterPayload] = useState({
    keyword_id: [],
    technology_id: [],
    tlAndBda_id: [],
  });
  const [applyfilterName, setApplyFilterName] = useState("");

  const users = useSelector((state) => state.users.users);
  const [members, setMembers] = useState([]);
  const [memberspayload, setMembersPayload] = useState([]);
  const [sharedcomment, setSharedComment] = useState("");
  const [shareid, setShareId] = useState("");
  const [isShared, setIsShared] = useState(false);
  const [membererror, setMemberError] = useState("");
  const [startDate, setStartDate] = useState(state.startDate);
  const [endDate, setEndDate] = useState(state.endDate);
  const [bidDate, setBidDate] = useState(state.date);
  const ranges = getDateRangeOptions();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const [keywordSwitchValue, setKeywordSwitchValue] = useState(
    state.keywordSwitchVal
  );
  const [techStackSwitchValue, settechStackSwitchValue] = useState(
    state.techStackSwitchVal
  );

  const keywordsData = keywords?.allKeywords?.map((keyword) => ({
    value: keyword.id,
    label: keyword.name,
  }));

  const technologiesData = technologies?.allTechnologies?.map((technology) => ({
    value: technology.id,
    label: technology.name,
  }));
  const handleKeywordMatch = (event) => {
    setKeywordSwitchValue(event.target.checked);
  };
  const handleTechStackMatch = (event) => {
    settechStackSwitchValue(event.target.checked);
  };
  const handleKeywordSelect = (selectedOptions) => {
    setSelectedKeywords(selectedOptions);
    if (selectedKeywords) {
      setSearchTextError("");
    }

    const keywordNames = selectedOptions
      .map((option) => option.label)
      .join(",");
    setKeyWordname(keywordNames);

    const selectedIds = selectedOptions.map((option) => option.value);

    setKeywordPayload(selectedIds);
    setEditedFilterPayload((prevPayload) => ({
      ...prevPayload,
      keyword_id: selectedIds,
    }));
  };

  const handleTechnologySelect = (selectedOptions) => {
    setSelectedTechnologies(selectedOptions);
    if (selectedTechnologies) {
      setSearchTextError("");
    }

    if (selectedOptions.length === 0) {
      setTechnologyName("");
    }

    const technologyNames = selectedOptions
      .map((option) => option.label)
      .join(",");

    setTechnologyName(technologyNames);

    const selectedIds = selectedOptions.map((option) => option.value);

    setTechnologyPayload(selectedIds);
    setEditedFilterPayload((prevPayload) => ({
      ...prevPayload,
      technology_id: selectedIds,
    }));
  };
  const handleTLandBdaSelect = (selectedOptions) => {
    setSelectedTLandBDAs(selectedOptions);
    if (selectedTLandBDAs) {
      setSearchTextError("");
    }
    if (selectedOptions.length === 0) {
      // Clear the technologyName state when no technology is selected
      setTLandBDAname("");
    }

    const TLandBDANames = selectedOptions
      .map((option) => option.label)
      .join(",");
    setTLandBDAname(TLandBDANames);
    // Construct the comma-separated list of selected IDs for the payload
    const selectedIds = selectedOptions.map((option) => option.value);

    setTLandBDAsPayload(selectedIds);
    setEditedFilterPayload((prevPayload) => ({
      ...prevPayload,
      tlAndBda_id: selectedIds,
    }));
  };
  const loggedInUserId = users?.loginuser?.map((login) => login.id);

  const userRoleArray = users?.loginuser?.map(
    (login) => login?.roles?.role_name
  );
  const userRole = userRoleArray ? userRoleArray[0] : null;

  const memberOptions = users?.AllTlandBda?.map((user) => ({
    value: user.id,
    label: `${user.first_name} ${user.last_name}`,
  }));

  const handleOptionChange = (selectedOptions) => {
    setMembers(selectedOptions);
    const selectedIds = selectedOptions
      ?.map((option) => option?.value)
      .join(",");
    setMembersPayload(selectedIds);
    if (selectedOptions) {
      setMemberError("");
    }
  };

  const developmentStatusOptions = [
    { value: "ongoing", label: "Ongoing" },
    { value: "planned", label: "Planned" },
    { value: "stopped", label: "Stopped" },
  ];

  const handleDevelopmentStatusChange = (selectedOption) => {
    setDeStatus(selectedOption);
    setSearchTextError("");
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const handleBidDateChange = (date) => {
    if (date) {
      setSearchTextError("");
    }

    setBidDate(date);
    if (Array.isArray(date) && date.length >= 2) {
      const formattedDates = date.map(
        (date) => date.toISOString().split("T")[0]
      );
      setStartDate(formattedDates[0]);
      setEndDate(formattedDates[1]);
    }
  };
  const handleShareModal = async () => {
    if (filtersbyid?.shareallusers?.length === 0) {
      if (memberspayload.length === 0 && members.length === 0) {
        setMemberError("Please Select any one member");
        return;
      }
    }
    await dispatch(
      createShareFilter({
        user_id: memberspayload,
        filter_id: shareid,
        filter_type: "projectfilters",
        shared_comment: sharedcomment,
        user_delete: removeKeyId,
      })
    );
    setActivityLogModal(false);
    successToast("Filter Shared Successfully");
    dispatch(fetchFilters({ filter_type: "projects" }));
    setMembers([]);
    setMembersPayload([]);
    setSharedComment("");
  };

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const handleShareFilterModal = (id) => {
    dispatch(
      fetchFiltersById({
        filterId: id,
        status: "",
      })
    );
    if (filtersbyid?.shareallusers?.length > 0) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    setActivityLogModal(true);
    setShareId(id);
  };

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const [value, setValue] = React.useState(0);
  const [isEditing, setIsEditing] = useState(state.edit);
  const [projectvalue, setProjectValue] = useState(state.status);
  const [filteredProjects, setFilteredprojects] = useState([]);
  const [filteredByIdProjects, setFilteredByIdProjects] = useState([]);
  const filtersbyid = useSelector((state) => state.filtersById.filters);
  // let filtersbyid = filterByIdApplied || isEditing ? filtersbyidVal : null;
  const [projectKeywords, setprojectKeywords] = useState([]);
  const [projectTechnologies, setProjectTechnologies] = useState([]);
  const [projectTLandBDAs, setProjectTLandBDAs] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [favProjectList, setFavProjectList] = useState([]);
  const [favProjectMatchedList, setFavProjectMatchList] = useState([]);

  const [liveapplyfilterCurrentPage, setapplylivefilterCurrentPage] = useState(
    state.filterApplied && state.status === "live" ? state.page : 1
  );
  const [
    developmentapplyfilterCurrentPage,
    setdevelopmentapplyfilterCurrentPage,
  ] = useState(
    state.filterApplied && state.status === "developing" ? state.page : 1
  );
  const [betaapplyfilterCurrentPage, setbetaapplyfilterCurrentPage] = useState(
    state.filterApplied && state.status === "beta_testing" ? state.page : 1
  );
  const [abandonedapplyfilterCurrentPage, setabandonedapplyfilterCurrentPage] =
    useState(
      state.filterApplied && state.status === "abandoned" ? state.page : 1
    );

  const totalFilters =
    (filters?.getallfilter?.length || 0) + (filters?.shareddatas?.length || 0);

  const handleChange = (event, newValue) => {
    // setSelectedKeywords([]);
    setKeywordPayload([]);
    // setSelectedTechnologies([]);
    setTLandBDAsPayload([]);
    setTechnologyPayload([]);
    setprojectKeywords([]);
    setProjectTechnologies([]);
    setprojectKeywords([]);
    // setBidDate("");
    // setSearchtext("");
    // setDeStatus(null);
    // setKeywordSwitchValue(true);
    // settechStackSwitchValue(true);
    // setEditedKeywordPayload([]);
    // setEditedTechnologyPayload([]);
    setFilterName("");
    setEditFilterName("");
    setEditSearchText("");

    setIsEditing(false);
    setValue(newValue);

    // dispatch(fetchFilters({ filter_type: "projects" }));
  };
  const handleValueChange = (newValue) => {
    setProjectValue(newValue);
  };
  const handleLivePageChange = (newPage) => {
    setliveCurrentPage(newPage);
  };
  const handlefilterLivePageChange = (newPage) => {
    setlivefilterCurrentPage(newPage);
  };
  const handleapplyfilterLivePageChange = (newPage) => {
    setapplylivefilterCurrentPage(newPage);
  };
  const handleDevelopmentPageChange = (newPage) => {
    setdevelopmentCurrentPage(newPage);
  };
  const handlefilterDevelopmentPageChange = (newPage) => {
    setdevelopmentfilterCurrentPage(newPage);
  };
  const handleapplyfilterDevelopmentPageChange = (newPage) => {
    setdevelopmentapplyfilterCurrentPage(newPage);
  };
  const handleBetaPageChange = (newPage) => {
    setbetaCurrentPage(newPage);
  };
  const handlefilterBetaPageChange = (newPage) => {
    setbetafilterCurrentPage(newPage);
  };
  const handleapplyfilterBetaPageChange = (newPage) => {
    setbetaapplyfilterCurrentPage(newPage);
  };
  const handleabandonedPageChange = (newPage) => {
    setabandonedCurrentPage(newPage);
  };
  const handlefilterabandonedPageChange = (newPage) => {
    setabandonedfilterCurrentPage(newPage);
  };
  const handleapplyfilterabandonedPageChange = (newPage) => {
    setabandonedapplyfilterCurrentPage(newPage);
  };
  const handleFavouritePageChange = (newPage) => {
    setfavouriteCurrentPage(newPage);
  };
  function capitalizeWords(input) {
    return input?.replace(/\w+/g, function (word) {
      return word?.charAt(0).toUpperCase() + word.slice(1);
    });
  }

  // START :: setSaveFilterModal
  const [SaveFilter, setSaveFilterModal] = useState(false);
  // END :: setSaveFilterModal

  // START :: setSaveFilterModal
  const [DeleteSavedFilter, setDeleteSavedFilterModal] = useState(false);
  // END :: setSaveFilterModal

  const filterData = {
    search_text: searchText,

    ...(bidDate && { start_date: startDate }),
    ...(bidDate && { end_date: endDate }),
    filter_name: filterName,
    keywordSwitchValue: keywordSwitchValue ? "and" : "or",
    techStackSwitchValue: techStackSwitchValue ? "and" : "or",
    technology_id: technologyPayload,
    keyword_id: keywordPayload,
    user_id: TLandBDAsPayload,
    development_status: destatus,
  };

  const handleClose = () => {
    if (!filterApplied) {
      setSearchtext("");
      setSelectedKeywords([]);
      setKeywordPayload([]);
      setTLandBDAsPayload([]);
      setSelectedTechnologies([]);
      setTechnologyPayload([]);
      setSelectedTLandBDAs([]);
      setTLandBDAsPayload([]);
      setBidDate("");
      setStartDate([]);
      setEndDate([]);
      setKeywordSwitchValue(true);
      settechStackSwitchValue(true);
      setDeStatus(null);
    }
    if (filterByIdApplied) {
      dispatch(
        fetchFiltersById({
          filterId: applyFilterId,
          status: projectvalue,
          page:
            projectvalue === "live"
              ? livefilterCurrentPage
              : projectvalue === "developing"
              ? developmentfilterCurrentPage
              : projectvalue === "beta_testing"
              ? betafilterCurrentPage
              : abandonedfilterCurrentPage,
          limit: +rowsPerPage,
        })
      );
    }
    setprojectKeywords([]);
    setProjectTechnologies([]);
    setprojectKeywords([]);
    setFilterName("");
    setEditFilterName("");
    setEditSearchText("");
    setOpen(false);
    setIsEditing(false);
  };

  const handleEditFilterSave = async () => {
    if (!editFilterName) {
      setEditFilterNameError("Please Enter Filter Name");
      return;
    }
    setIsEditing(true);
    setSaveFilterModal(false);
    successToast("Filter Updated Successfully");
    setValue(1);
    dispatch(fetchFilters({ filter_type: "projects" }));
  };

  const handleFiltersave = async () => {
    setIsEditing(false);
    if (isButtonDisabled) {
      return;
    }
    if (!filterName) {
      setFilterNameError("Please Enter Filter Name");
      return;
    }
    setIsButtonDisabled(true);
    try {
      const response = await dispatch(createFilter(filterData));

      if (response.success) {
        setSaveFilterModal(false);
        successToast("Filter Created Successfully");
        setValue(1);
        dispatch(fetchFilters({ filter_type: "projects" }));
      } else {
        console.error("Error creating user:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      setIsButtonDisabled(false);
    }

    dispatch(fetchFilters());
  };

  const handleSave = () => {
    setIsCreating(true);
    setIsEditing(false);
    setSearchTextError("");
    if (
      !searchText &&
      selectedKeywords?.length === 0 &&
      selectedTLandBDAs?.length === 0 &&
      selectedTechnologies?.length === 0 &&
      !bidDate &&
      !destatus
    ) {
      setSearchTextError("Please Select any one field");
      return;
    }
    setSaveFilterModal(true);
  };

  const handleDeleteModal = (id) => {
    setFilterId(id);
    setDeleteSavedFilterModal(true);
  };

  const deleteSubmitHandler = async () => {
    await dispatch(deleteFilterById(filterId));

    setDeleteSavedFilterModal(false);
    dispatch(fetchFilters({ filter_type: "projects" }));
  };

  const handleDrawer = async () => {
    // await dispatch(fetchKeywords({ query: "" }));
    // await dispatch(fetchTechnologies({ query: "" }));
    setSearchTextError("");
    setOpen(!open);
    if (!filterByIdApplied && !filterApplied) {
      setSearchtext("");
      setSelectedKeywords([]);
      setSelectedTechnologies([]);
      setSelectedTLandBDAs([]);
      setBidDate("");
      setDeStatus(null);
    }
    if (!filterApplied) {
      setSearchtext("");
      setKeyWordname("");
      setTechnologyName("");
      setTLandBDAname("");
      setStartDate([]);
      setEndDate([]);
      setDeStatus(null);
    }

    setprojectKeywords([]);
    setProjectTechnologies([]);
    setprojectKeywords([]);
    setFilterName("");
    setEditFilterName("");
    setEditSearchText("");
    setValue(0);
    if (filterByIdApplied) {
      await dispatch(
        fetchFiltersById({
          filterId: applyFilterId,
          status: projectvalue,
          page:
            projectvalue === "live"
              ? livefilterCurrentPage
              : projectvalue === "developing"
              ? developmentfilterCurrentPage
              : projectvalue === "beta_testing"
              ? betafilterCurrentPage
              : abandonedfilterCurrentPage,
          limit: +rowsPerPage,
        })
      );
    }
  };

  const handleApplyFilter = async () => {
    setSearchProject("");
    setIsCreating(false);
    setapplylivefilterCurrentPage(1);
    setdevelopmentapplyfilterCurrentPage(1);
    setbetaapplyfilterCurrentPage(1);
    setabandonedapplyfilterCurrentPage(1);
    if (
      !searchText &&
      selectedKeywords?.length === 0 &&
      selectedTechnologies?.length === 0 &&
      selectedTLandBDAs?.length === 0 &&
      !bidDate &&
      !destatus
    ) {
      setSearchTextError("Please Select any one field");
      return;
    }
    await dispatch(
      fetchFilters({
        text: searchText,
        ...(bidDate && { start_date: startDate }),
        ...(bidDate && { end_date: endDate }),
        keyword: Array.isArray(selectedKeywords)
          ? selectedKeywords.map((option) => option.label).join(",")
          : "",
        tlAndBda: Array.isArray(selectedTLandBDAs)
          ? selectedTLandBDAs.map((option) => option.value).join(",")
          : "",
        keywordSwitchValue: keywordSwitchValue ? "and" : "or",
        techStackSwitchValue: techStackSwitchValue ? "and" : "or",
        technologies: Array.isArray(selectedTechnologies)
          ? selectedTechnologies.map((option) => option.label).join(",")
          : "",
        status: projectvalue,
        development_status: destatus,
        page: 1,
        limit: +rowsPerPage,
        // projectvalue === "live"
        //   ? liveapplyfilterCurrentPage
        //   : projectvalue === "developing"
        //   ? developmentapplyfilterCurrentPage
        //   : projectvalue === "beta_testing"
        //   ? betaapplyfilterCurrentPage
        //   : abandonedapplyfilterCurrentPage,
      })
    )
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          setFilterApplied(true);
          setOpen(false);
        } else {
          console.error("Error creating user:", response.error);
        }
      })
      .catch((error) => {
        console.error("Error creating user:", error.message);
      });
    if (!filterApplied) {
      setKeyWordname("");
      setTechnologyName("");
      setTLandBDAname("");
      if (bidDate === null) {
        setStartDate([]);
        setEndDate([]);
      }
    }
  };

  const handleFilterById = async (id) => {
    setSearchProject("");
    setIsShared(false);
    setFilterByIdApplied(true);

    setOpen(!open);
    setApplyFilterId(id);
    if (projectvalue === "") {
      dispatch(
        fetchFiltersById({
          filterId: applyFilterId,
          status: "live",
          page:
            projectvalue === "live"
              ? livefilterCurrentPage
              : projectvalue === "developing"
              ? developmentfilterCurrentPage
              : projectvalue === "beta_testing"
              ? betafilterCurrentPage
              : abandonedfilterCurrentPage,
          limit: +rowsPerPage,
        })
      );
    } else {
      await dispatch(
        fetchFiltersById({
          filterId: id,
          status: projectvalue,
          page:
            projectvalue === "live"
              ? livefilterCurrentPage
              : projectvalue === "developing"
              ? developmentfilterCurrentPage
              : projectvalue === "beta_testing"
              ? betafilterCurrentPage
              : abandonedfilterCurrentPage,
          limit: +rowsPerPage,
        })
      );
    }
  };

  const handleShareFilterById = async (id) => {
    setIsShared(true);
    setIsEditing(false);
    setFilterByIdApplied(true);
    setOpen(!open);
    setApplyFilterId(id);
    await dispatch(
      fetchFiltersById({
        filterId: id,
        status: projectvalue,
      })
    );
  };

  const handleClearFilter = () => {
    setEditSearchText("");
    setprojectKeywords([]);
    setProjectTechnologies([]);
    setprojectKeywords([]);
    setKeywordSwitchValue(true);
    settechStackSwitchValue(true);
    setEditedFilterPayload({
      keyword_id: [],
      technology_id: [],
      tlAndBda_id: [],
    });
    setSearchtext("");
    setKeywordPayload([]);
    setDeStatus(null);
    setTechnologyPayload([]);
    setTLandBDAsPayload([]);
    setSelectedKeywords([]);
    setSelectedTechnologies([]);
    setSelectedTLandBDAs([]);
    setKeyWordname([]);
    setTechnologyName([]);
    setTLandBDAname([]);
    setBidDate("");
    setStartDate([]);
    setEndDate([]);
    if (filterByIdApplied) {
      setApplyFilterSearchText("");

      setApplyFilterPayload({
        keyword_id: [],
        technology_id: [],
        tlAndBda_id: [],
      });
      setApplyFilterKeywordId([]);
      setApplyFilterTechnologyId([]);
    }
  };

  const handleUpdate = async () => {
    setIsEditing(true);
    if (isButtonDisabled) {
      return;
    }
    const editFilterData = {
      search_text: searchText || "",
      filter_name: editFilterName,
      development_status: destatus,
      keywordSwitchValue: keywordSwitchValue ? "and" : "or",
      techStackSwitchValue: techStackSwitchValue ? "and" : "or",
      start_date: startDate.length === 0 || bidDate === null ? null : startDate,
      end_date: endDate.length === 0 || bidDate === null ? null : endDate,
      ...editedFilterPayload,
    };
    setIsButtonDisabled(true);
    if (filterByIdApplied) {
      setSaveFilterModal(false);
      setIsEditing(true);
      try {
        const response = await dispatch(
          editfilterByIdAdmin({
            editFilerId: applyFilterId,
            editFilterData: editFilterData,
            status: projectvalue,
          })
        );

        if (response.success) {
          successToast("Filter updated successfully!");
          // eslint-disable-next-line no-lone-blocks
          {
            isShared ? setValue(2) : setValue(1);
          }
          dispatch(fetchFilters({ filter_type: "projects" }));
          dispatch(
            fetchFiltersById({
              filterId: applyFilterId,
              status: projectvalue,
            })
          );
        } else {
          console.error("Error creating user:", response.error);
        }
      } catch (error) {
        console.error("Error creating lead:", error.message);
      } finally {
        // Enable the save button after the API call is completed
        setIsButtonDisabled(false);
      }
    }

    if (!filterByIdApplied) {
      setIsEditing(true);

      setSaveFilterModal(false);
      if (isButtonDisabled) {
        return;
      }
      try {
        const response = await dispatch(
          editfilterByIdAdmin({
            editFilerId,
            editFilterData,
            status: projectvalue,
          })
        );

        if (response.success) {
          successToast("Filter updated successfully!");
          // eslint-disable-next-line no-lone-blocks
          {
            isShared ? setValue(2) : setValue(1);
          }
          dispatch(fetchFilters({ filter_type: "projects" }));
          dispatch(
            fetchFiltersById({
              filterId: applyFilterId,
              status: projectvalue,
            })
          );
        } else {
          console.error("Error creating user:", response.error);
        }
      } catch (error) {
        console.error("Error creating lead:", error.message);
      } finally {
        // Enable the save button after the API call is completed
        setIsButtonDisabled(false);
      }
    }
  };

  const handleEditFilter = async (id) => {
    setEditFilterId(id);
    setValue(0);
    setIsEditing(true);
    // setFilterByIdApplied(true);
    await dispatch(
      fetchFiltersById({
        filterId: id,
        status: projectvalue,
      })
    );
  };

  useEffect(() => {
    if (filterApplied) {
      dispatch(
        fetchFilters({
          text: searchText,
          ...(bidDate && { start_date: startDate }),
          ...(bidDate && { end_date: endDate }),
          keyword: Array.isArray(selectedKeywords)
            ? selectedKeywords.map((option) => option.label).join(",")
            : "",
          tlAndBda: Array.isArray(selectedTLandBDAs)
            ? selectedTLandBDAs.map((option) => option.value).join(",")
            : "",
          keywordSwitchValue: keywordSwitchValue ? "and" : "or",
          techStackSwitchValue: techStackSwitchValue ? "and" : "or",
          technologies: Array.isArray(selectedTechnologies)
            ? selectedTechnologies.map((option) => option.label).join(",")
            : "",
          status: projectvalue,
          development_status: destatus,
          page:
            projectvalue === "live"
              ? liveapplyfilterCurrentPage
              : projectvalue === "developing"
              ? developmentapplyfilterCurrentPage
              : projectvalue === "beta_testing"
              ? betaapplyfilterCurrentPage
              : abandonedapplyfilterCurrentPage,
          limit: +rowsPerPage,
        })
      );
    }
    if (filterByIdApplied) {
      dispatch(
        fetchFiltersById({
          filterId: applyFilterId,
          status: projectvalue,
          page:
            projectvalue === "live"
              ? livefilterCurrentPage
              : projectvalue === "developing"
              ? developmentfilterCurrentPage
              : projectvalue === "beta_testing"
              ? betafilterCurrentPage
              : abandonedfilterCurrentPage,
          limit: +rowsPerPage,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    projectvalue,
    +rowsPerPage,
    liveapplyfilterCurrentPage,
    developmentapplyfilterCurrentPage,
    betaapplyfilterCurrentPage,
    abandonedapplyfilterCurrentPage,
    livefilterCurrentPage,
    developmentfilterCurrentPage,
    betafilterCurrentPage,
    abandonedfilterCurrentPage,
  ]);

  useEffect(() => {
    if (filtersbyid === null) {
      return;
    }
    setSearchtext(filtersbyid?.filter?.search_text);
    const startDateString = filtersbyid?.filter?.start_date;
    const endDateString = filtersbyid?.filter?.end_date;

    // Check if startDateString is a valid date string
    const startDate = new Date(startDateString);

    if (isNaN(startDate.getTime())) {
      console.error("Invalid start date format");
      // Handle the error as needed
    } else if (startDateString === null) {
      setBidDate("");
    } else {
      // startDate is a valid Date object, set the state
      setBidDate([startDate, new Date(endDateString)]);
    }
    // setStartDate(apiStartDate.toISOString().split("T")[0]);
    // setEndDate(apiEndDate.toISOString().split("T")[0]);
    setEditFilterName(filtersbyid?.filter?.filter_name);
    setDeStatus(filtersbyid?.filter?.development_status ?? null);
    setKeywordSwitchValue(
      filtersbyid?.filter?.keywordMatch === "and" ? true : false
    );
    settechStackSwitchValue(
      filtersbyid?.filter?.technologyStackMatch === "and" ? true : false
    );
    const initialKeywordOptions = filtersbyid?.keyword?.map((keywordName) => ({
      value: keywordName.keyword_id,
      label: keywordName.keywords?.keyword_name,
    }));
    setSelectedKeywords(initialKeywordOptions);
    const initialtechnologyOptions = filtersbyid?.technologyStacks?.map(
      (technologyName) => ({
        value: technologyName.technology_id,
        label: technologyName.technologies?.technology_name,
      })
    );

    setSelectedTechnologies(initialtechnologyOptions);
    const initialTLandBdaOptions = filtersbyid?.project_filter_users?.map(
      (user) => ({
        value: user.user_id,
        label:
          user.project_assignees?.first_name +
          " " +
          user.project_assignees?.last_name,
      })
    );
    setSelectedTLandBDAs(initialTLandBdaOptions);
    setEditedFilterPayload((prevPayload) => ({
      ...prevPayload,
      keyword_id: initialKeywordOptions?.map((option) => option.value),
      technology_id: initialtechnologyOptions?.map((option) => option.value),
      tlAndBda_id: initialTLandBdaOptions?.map((option) => option.value),
    }));

    if (filterByIdApplied) {
      setApplyFilterName(filtersbyid?.filter?.filter_name);

      const initialKeywordOptions = filtersbyid?.keyword?.map(
        (keywordName) => ({
          value: keywordName.keyword_id,
          label: keywordName.keywords?.keyword_name,
        })
      );
      setSelectedKeywords(initialKeywordOptions || []);
      const initialtechnologyOptions = filtersbyid?.technologyStacks?.map(
        (technologyName) => ({
          value: technologyName.technology_id,
          label: technologyName.technologies?.technology_name,
        })
      );
      setSelectedTechnologies(initialtechnologyOptions || []);
      const initialTLandBdaOptions = filtersbyid?.project_filter_users?.map(
        (user) => ({
          value: user.user_id,
          label:
            user.project_assignees?.first_name +
            " " +
            user.project_assignees?.last_name,
        })
      );
      setSelectedTLandBDAs(initialTLandBdaOptions || []);
      setApplyFilterPayload((prevPayload) => ({
        ...prevPayload,
        keyword_id: initialKeywordOptions?.map((option) => option.value),
        technology_id: initialtechnologyOptions?.map((option) => option.value),
        tlAndBda_id: initialTLandBdaOptions?.map((option) => option.value),
      }));
    }
  }, [isEditing, filtersbyid, filterByIdApplied]);

  useEffect(() => {
    dispatch(fetchKeywords({ query: "" }));
    dispatch(fetchTechnologies({ query: "" }));
  }, [dispatch]);

  useEffect(() => {
    setFilteredprojects(filters?.projects);
  }, [filters]);

  useEffect(() => {
    setFilteredByIdProjects(filtersbyid?.project);
    setFavProjectList(
      filtersbyid?.favallprojects?.map((project) => project?.projects)
    );
    const projectId = filtersbyid?.projectId;
    const projects = filtersbyid?.project;
    const matchedProjects = projects?.filter((project) =>
      projectId?.includes(project?.id)
    );

    setFavProjectMatchList(matchedProjects);
    if (filtersbyid?.shareallusers) {
      const users = filtersbyid?.shareallusers?.map((user) => ({
        value: user?.users?.id,
        label: `${user?.users?.first_name} ${user?.users?.last_name}`,
      }));
      setMembers(users);
      const projectfiltersComment =
        filtersbyid?.shareallusers[0]?.project_filters?.shared_comment;
      setSharedComment(projectfiltersComment);
    }
  }, [filtersbyid, isEdit]);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!filterApplied && !filterByIdApplied) {
      dispatch(fetchFilters({ filter_type: "projects" }));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchUsers({
        page: "",
        limit: "",
        query: "",
      })
    );
  }, [dispatch]);

  const handleSearchProjects = (event) => {
    if (
      liveCurrentPage > 1 ||
      developmentCurrentPage > 1 ||
      betaCurrentPage > 1 ||
      abandonedCurrentPage > 1
    ) {
      setliveCurrentPage(1);
      setdevelopmentCurrentPage(1);
      setbetaCurrentPage(1);
      setabandonedCurrentPage(1);
    }
    setSearchProject(event.target.value);
    setFilterApplied(false);
    setFilterByIdApplied(false);
  };

  const clearSearchHandler = () => {
    setSearchProject("");
    localStorage.setItem("searchProjectsQuery", JSON.stringify(""));
  };

  useEffect(() => {
    // Load search text from localStorage on component mount
    const savedSearchText = localStorage.getItem("searchProjectsQuery");
    if (savedSearchText) {
      setSearchProject(JSON.parse(savedSearchText));
    }
    localStorage.setItem("searchBidsQuery", JSON.stringify(""));
    localStorage.setItem("searchClientsQuery", JSON.stringify(""));
    localStorage.setItem("searchLeadsQuery", JSON.stringify(""));
    localStorage.setItem("searchUsersQuery", JSON.stringify(""));
  }, []);

  const storeProjectsSearchInLocalStorage = (event) => {
    localStorage.setItem("searchProjectsQuery", JSON.stringify(searchProject));
  };

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 55,
    height: 30,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="25" viewBox="0 0 20 20"><text x="3.5" y="15" fill="${encodeURIComponent(
            "#fff"
          )}" font-size="12"  font-family="Arial, Helvetica, sans-serif">All</text></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 26,
      height: 26,
      "&::before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="25" viewBox="0 0 20 20"><text x="0" y="15" fill="${encodeURIComponent(
          "#fff"
        )}" font-size="12" font-family="Arial, Helvetica, sans-serif">Any</text></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));
  return (
    <>
      {/*  START :: PROJECT LISTING VIEW */}
      <Box className="content-header project-list-header project-module-wrapper">
        <Box className="filter-apply-wrapper">
          <Typography variant="h2" component="h2">
            Projects
          </Typography>
          {(filterApplied || filterByIdApplied) && (
            <>
              <Typography variant="h2" component="h2">
                {" "}
                - &nbsp;
                {filterByIdApplied ? filtersbyid?.filter?.filter_name : ""}
              </Typography>
              <Button
                variant="contained"
                size="large"
                className="outline-btn btn add-btn"
                endIcon={<RiCloseLine />}
                disableRipple
                onClick={() => {
                  setFilterApplied(false);
                  setFilterByIdApplied(false);
                  setKeywordSwitchValue(true);
                  settechStackSwitchValue(true);
                  setSearchtext("");
                  setBidDate("");
                  setSelectedKeywords([]);
                  setSelectedTechnologies([]);
                  setSelectedTLandBDAs([]);
                  setKeywordPayload([]);
                  setTechnologyPayload([]);
                  setTLandBDAsPayload([]);
                  setapplylivefilterCurrentPage(1);
                  setdevelopmentapplyfilterCurrentPage(1);
                  setbetaapplyfilterCurrentPage(1);
                  setabandonedapplyfilterCurrentPage(1);
                  setlivefilterCurrentPage(1);
                  setbetafilterCurrentPage(1);
                  setdevelopmentfilterCurrentPage(1);
                  setabandonedfilterCurrentPage(1);

                  setliveCurrentPage(1);
                  setdevelopmentCurrentPage(1);
                  setbetaCurrentPage(1);
                  setabandonedCurrentPage(1);
                  dispatch(
                    fetchProjects({
                      status: projectvalue,
                      page:
                        projectvalue === "live"
                          ? liveCurrentPage
                          : projectvalue === "developing"
                          ? developmentCurrentPage
                          : projectvalue === "beta_testing"
                          ? betaCurrentPage
                          : abandonedCurrentPage,
                      limit: +rowsPerPage,
                    })
                  );
                  setliveCurrentPage(1);
                  setdevelopmentCurrentPage(1);
                  setbetaCurrentPage(1);
                  setabandonedCurrentPage(1);
                }}
              >
                Remove filter
              </Button>
            </>
          )}
        </Box>
        <Box variant="div" component="div" className="content-header-right">
          <Box variant="div" component="div" className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
              placeholder="Search here"
              onChange={handleSearchProjects}
              value={searchProject}
              onBlur={storeProjectsSearchInLocalStorage}
            />
            {searchProject && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={clearSearchHandler}
              />
            )}
          </Box>
          <WithPermission
            permission={updateProjects}
            element={
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn add-btn"
                endIcon={<RiAddCircleLine />}
                disableRipple
                onClick={() => navigate("/projects/create")}
              >
                Create Project
              </Button>
            }
          />
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiFilter2Line />}
            disableRipple
            onClick={handleDrawer}
          >
            <Typography
              variant="body1"
              component="span"
              className="filter-counte"
            >
              {totalFilters}
            </Typography>
            Filters
          </Button>
        </Box>
      </Box>
      <Box className="content-layout">
        <ProjectList
          onValueChange={handleValueChange}
          onLivePage={handleLivePageChange}
          onDevelopmentPage={handleDevelopmentPageChange}
          onBetaPage={handleBetaPageChange}
          onAbandonedpage={handleabandonedPageChange}
          onFavouritepage={handleFavouritePageChange}
          searchProject={searchProject}
          filteredProjects={filteredProjects}
          filterApplied={filterApplied}
          filteredByIdProjects={filteredByIdProjects}
          filterByIdApplied={filterByIdApplied}
          applyFilterId={applyFilterId}
          favProjectList={favProjectList}
          favProjectMatchedList={favProjectMatchedList}
          setFavProjectList={setFavProjectList}
          filtersbyid={filtersbyid}
          filters={filters}
          onLiveFilterPage={handlefilterLivePageChange}
          onDevelopmentFilterPage={handlefilterDevelopmentPageChange}
          developmentAppliedFilterPage={developmentapplyfilterCurrentPage}
          liveAppliedFilterPage={liveapplyfilterCurrentPage}
          abandonedappliedfilterCurrentPage={abandonedapplyfilterCurrentPage}
          betaAppliedfilterPage={betaapplyfilterCurrentPage}
          onBetaFilterPage={handlefilterBetaPageChange}
          onAbandonedFilterPage={handlefilterabandonedPageChange}
          onLiveapplyFilterPage={handleapplyfilterLivePageChange}
          onDevelopmentapplyFilterPage={handleapplyfilterDevelopmentPageChange}
          onBetaapplyFilterPage={handleapplyfilterBetaPageChange}
          onAbandonedapplyFilterPage={handleapplyfilterabandonedPageChange}
          searchText={searchText}
          selectedKeywords={selectedKeywords}
          keywordSwitchValue={keywordSwitchValue}
          selectedTLandBDAs={selectedTLandBDAs}
          techStackSwitchValue={techStackSwitchValue}
          selectedTechnologies={selectedTechnologies}
          startDate={startDate}
          endDate={endDate}
          bidDate={bidDate}
          destatus={destatus}
          livefilterpage={livefilterCurrentPage}
          devfilterpage={developmentfilterCurrentPage}
          betafilterpage={betafilterCurrentPage}
          abandonedfilterpage={abandonedfilterCurrentPage}
          isEditing={isEditing}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>
      {/* END :: PROJECT LISTING VIEW */}

      {/*  START :: PROJECT FILTER PANEL */}
      <Drawer
        className="side-drawer project-module-drawer"
        anchor="right"
        open={open}
        onClose={() => handleClose()}
      >
        <Box className="sidebar-tab-wrapper">
          <Box className="sidebar-tab-wrapper">
            <Box className="close-btn" onClick={() => handleClose()}>
              <RiCloseLine />
            </Box>
            <Box className="sidebar-tab">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="tab-label-btn"
                  label="Filters"
                  {...a11yProps(0)}
                />
                <Tab
                  className="tab-label-btn"
                  label="My Filters"
                  {...a11yProps(1)}
                />
                <Tab
                  className="tab-label-btn"
                  label="Shared  Filters"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
          </Box>
          <CustomTabPanel
            value={value}
            index={0}
            className="filter-content-wrapper"
          >
            <Stack spacing={2} sx={{ padding: "24px" }}>
              {isEditing || filterByIdApplied ? (
                <Box>
                  <>
                    <Typography variant="body1" component="label">
                      Filter Name
                    </Typography>
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Rename Filter"
                      value={
                        filterByIdApplied ? applyfilterName : editFilterName
                      }
                      onChange={(e) => {
                        if (filterByIdApplied) {
                          setApplyFilterName(e.target.value);
                        }
                        setEditFilterName(e.target.value);
                        if (e.target.value) {
                          setEditFilterNameError("");
                        }
                      }}
                    />
                  </>
                </Box>
              ) : null}
              <Box>
                <Typography variant="body1" component="label">
                  Search Text
                </Typography>

                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Search Here"
                  value={searchText}
                  onChange={(e) => {
                    setSearchtext(e.target.value);

                    if (e.target.value) {
                      setSearchTextError("");
                    }
                  }}
                />

                {!isEditing
                  ? searchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {searchTextError}
                      </Typography>
                    )
                  : editsearchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {editsearchTextError}
                      </Typography>
                    )}
              </Box>
              <Grid item lg={6} md={12} sm={12}>
                <Typography variant="body1" component="label">
                  Development Start Date
                </Typography>
                <DateRangePicker
                  showOneCalendar
                  style={{ width: "700px" }}
                  placeholder="Select Development Start Date"
                  className="modal-date-picker"
                  format="dd-MM-yyyy"
                  value={bidDate !== "" ? bidDate : null}
                  ranges={ranges}
                  onKeyDown={(event) => event.preventDefault()}
                  onChange={handleBidDateChange}
                />
                {!isEditing
                  ? searchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {searchTextError}
                      </Typography>
                    )
                  : editsearchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {editsearchTextError}
                      </Typography>
                    )}
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Development Status
                </Typography>
                <Select
                  name="colors"
                  options={developmentStatusOptions}
                  value={
                    developmentStatusOptions.find(
                      (Option) => Option.value === destatus
                    ) || null
                  }
                  className="muilt-select-field"
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,

                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                  onChange={(selectedOption) =>
                    handleDevelopmentStatusChange(selectedOption?.value)
                  }
                />
                {!isEditing
                  ? searchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {searchTextError}
                      </Typography>
                    )
                  : editsearchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {editsearchTextError}
                      </Typography>
                    )}
              </Grid>
              <Grid item lg={6} md={12} sm={12}>
                <Box>
                  <Typography variant="body1" component="label">
                    Users
                  </Typography>
                </Box>

                <Select
                  isMulti
                  name="colors"
                  options={memberOptions}
                  onChange={(selectedOptions, { removedValue }) => {
                    if (removedValue) {
                      const removedOptionId = removedValue.value; // assuming 'value' contains the ID
                      setRemoveTLandBdaId([
                        ...removeTLandBdaId,
                        removedOptionId,
                      ]);
                    }
                    handleTLandBdaSelect(selectedOptions);
                  }}
                  value={selectedTLandBDAs}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                />

                {!isEditing
                  ? searchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {searchTextError}
                      </Typography>
                    )
                  : editsearchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {editsearchTextError}
                      </Typography>
                    )}
              </Grid>
              <Grid item lg={6} md={12} sm={12}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body1" component="label">
                    Keywords
                  </Typography>

                  <FormControlLabel
                    control={
                      <MaterialUISwitch
                        sx={{ m: 0.5, marginLeft: "-1px" }}
                        defaultChecked
                      />
                    }
                    label="Match :"
                    labelPlacement="start"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "13px",
                        fontWeight: 600,
                      },
                    }} // Adjust the font size here
                    checked={keywordSwitchValue}
                    onChange={handleKeywordMatch}
                  />
                </Box>

                <Select
                  isMulti
                  name="colors"
                  options={keywordsData}
                  onChange={(selectedOptions, { removedValue }) => {
                    if (removedValue) {
                      // eslint-disable-next-line no-unused-vars
                      const removedOptionId = removedValue.value;
                    }

                    handleKeywordSelect(selectedOptions);
                  }}
                  value={selectedKeywords}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                />

                {!isEditing
                  ? searchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {searchTextError}
                      </Typography>
                    )
                  : editsearchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {editsearchTextError}
                      </Typography>
                    )}
              </Grid>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body1" component="label">
                    Technology Stack
                  </Typography>
                  <FormControlLabel
                    control={
                      <MaterialUISwitch
                        sx={{ m: 0.5, marginLeft: "-1px" }}
                        defaultChecked
                      />
                    }
                    label="Match :"
                    labelPlacement="start"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "13px",
                        fontWeight: 600,
                      },
                    }} // Adjust the font size here
                    checked={techStackSwitchValue}
                    onChange={handleTechStackMatch}
                  />
                </Box>
                <Select
                  isMulti
                  name="colors"
                  options={technologiesData}
                  onChange={(selectedOptions, { removedValue }) => {
                    if (removedValue) {
                      const removedOptionId = removedValue.value;

                      setRemoveTechId([...removeTechId, removedOptionId]);
                    }

                    handleTechnologySelect(selectedOptions);
                  }}
                  value={selectedTechnologies}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                />

                {!isEditing
                  ? searchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {searchTextError}
                      </Typography>
                    )
                  : editsearchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {editsearchTextError}
                      </Typography>
                    )}
              </Box>

              <Box
                className="filter-button-wrapper"
                style={{ justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn green-btn"
                  disableRipple
                  onClick={() => {
                    handleApplyFilter();
                  }}
                >
                  Apply Filter
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={
                    isEditing || filterByIdApplied ? handleUpdate : handleSave
                  }
                  disabled={isEditing && isButtonDisabled}
                >
                  {isEditing || filterByIdApplied ? "Update" : "Save"}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn cancel-filter-btn"
                  disableRipple
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn clear-filter-tab-1"
                  disableRipple
                  style={{ width: "100%" }}
                  onClick={() => handleClearFilter()}
                >
                  Clear Filter
                </Button>
              </Box>
            </Stack>
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={1}
            className="filter-content-wrapper"
          >
            {filters?.getallfilter?.length > 0 ? (
              filters?.getallfilter?.map((filter) => (
                <Box className="filer-accordion-wrapper">
                  <Accordion>
                    <AccordionSummary
                      className="accordion-title"
                      expandIcon={<RiArrowDownSLine />}
                      aria-controls="filter-content-1"
                      id="filter-header-1"
                    >
                      <Typography className="title-content">
                        {filter?.filter_name === "" ||
                        filter?.filter_name === null
                          ? "-"
                          : capitalizeWords(filter?.filter_name)}
                      </Typography>

                      <Stack
                        className="action-btn-wrap"
                        direction="row"
                        spacing={2}
                      >
                        <Button
                          variant="outlined"
                          className="action-btn view-btn"
                          startIcon={<RiFilter2Line />}
                          disableRipple
                          onClick={() => handleFilterById(filter.id)}
                        ></Button>
                        <Button
                          variant="outlined"
                          className="action-btn convert-btn"
                          startIcon={<RiShareLine />}
                          disableRipple
                          onClick={(event) => {
                            event.stopPropagation();
                            handleShareFilterModal(filter.id);
                          }}
                        ></Button>
                        <Button
                          variant="outlined"
                          className="action-btn edit-btn"
                          startIcon={<RiPencilLine />}
                          disableRipple
                          onClick={() => {
                            handleEditFilter(filter.id);
                            setIsShared(false);
                          }}
                        ></Button>
                        <WithPermission
                          permission={fullProjects}
                          element={
                            <Button
                              variant="outlined"
                              className="action-btn dlt-btn"
                              startIcon={<RiDeleteBinLine />}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteModal(filter.id);
                              }}
                              disableRipple
                            ></Button>
                          }
                        />
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails className="filter-accordion-details">
                      <Stack spacing={2} className="saved-filter-wrapper">
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title"
                          >
                            <p>Search Text :</p>
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            className="desc"
                          >
                            {filter?.search_text === null ||
                            filter?.search_text === ""
                              ? "-"
                              : filter?.search_text}
                          </Typography>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title"
                          >
                            <p> Development Start Date :</p>
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            className="desc"
                          >
                            {filter?.start_date ? (
                              <div className="dev-start-date">
                                <span>{formatDate(filter?.start_date)} -</span>
                                <span>{formatDate(filter?.end_date)}</span>
                              </div>
                            ) : (
                              "-"
                            )}
                          </Typography>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title"
                          >
                            <p> Development Status :</p>
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            className="desc"
                          >
                            {filter?.development_status === null ||
                            filter?.development_status === ""
                              ? "-"
                              : capitalizeFirstLetter(
                                  filter?.development_status
                                )}
                          </Typography>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title"
                          >
                            <p> Users :</p>
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="body2"
                              component="span"
                              className="desc"
                            >
                              {filter?.project_filter_assignees?.length > 0
                                ? filter?.project_filter_assignees?.map(
                                    (user, index) => (
                                      <Box key={user?.project_assignees?.id}>
                                        <Typography
                                          variant="body2"
                                          component="span"
                                          className="desc"
                                        >
                                          {user?.project_assignees?.first_name +
                                            " " +
                                            user?.project_assignees?.last_name}
                                        </Typography>

                                        {index <
                                          filter?.project_filter_assignees
                                            ?.length -
                                            1 && <span>, </span>}
                                      </Box>
                                    )
                                  )
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title"
                          >
                            <p> Keywords :</p>
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="body2"
                              component="span"
                              className="desc"
                            >
                              {filter?.project_filter_keywords?.length > 0
                                ? filter?.project_filter_keywords?.map(
                                    (keyword, index) => (
                                      <>
                                        <Typography
                                          variant="body2"
                                          component="span"
                                          className="desc"
                                        >
                                          {keyword?.keywords?.keyword_name}
                                        </Typography>

                                        {index <
                                          filter?.project_filter_keywords
                                            ?.length -
                                            1 && <span>, </span>}
                                      </>
                                    )
                                  )
                                : "-"}
                            </Typography>
                            <Box
                              className="saved-filter-content"
                              sx={{ marginTop: "10px" }}
                            >
                              <Typography variant="body2" className="title">
                                Match :
                              </Typography>

                              <ToggleButtonGroup
                                value={
                                  filter?.keywordMatch === "and" ? "all" : "any"
                                }
                                disabled
                                sx={{ height: "0px", marginLeft: "10px" }}
                              >
                                <ToggleButton
                                  value="all"
                                  sx={{
                                    fontSize: "12px",
                                    padding: "9px",
                                    textTransform: "none",
                                  }}
                                >
                                  All
                                </ToggleButton>
                                <ToggleButton
                                  value="any"
                                  sx={{
                                    fontSize: "12px",
                                    padding: "9px",
                                    textTransform: "none",
                                  }}
                                >
                                  Any
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title"
                          >
                            <p> Technology Stack :</p>
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="body2"
                              component="span"
                              className="desc"
                            >
                              {filter?.project_filter_technology_stacks
                                ?.length > 0
                                ? filter?.project_filter_technology_stacks?.map(
                                    (technology, index) => (
                                      <>
                                        <Typography
                                          variant="body2"
                                          component="span"
                                          className="desc"
                                        >
                                          {
                                            technology?.technologies
                                              ?.technology_name
                                          }
                                        </Typography>
                                        {index <
                                          filter
                                            ?.project_filter_technology_stacks
                                            ?.length -
                                            1 && <span>, </span>}
                                      </>
                                    )
                                  )
                                : "-"}
                            </Typography>
                            <Box
                              className="saved-filter-content"
                              sx={{ marginTop: "10px" }}
                            >
                              <Typography variant="body2" className="title">
                                Match :
                              </Typography>

                              <ToggleButtonGroup
                                value={
                                  filter?.technologyStackMatch === "and"
                                    ? "all"
                                    : "any"
                                }
                                disabled
                                sx={{ height: "0px", marginLeft: "10px" }}
                              >
                                <ToggleButton
                                  value="all"
                                  sx={{
                                    fontSize: "12px",
                                    padding: "9px",
                                    textTransform: "none",
                                  }}
                                >
                                  All
                                </ToggleButton>
                                <ToggleButton
                                  value="any"
                                  sx={{
                                    fontSize: "12px",
                                    padding: "9px",
                                    textTransform: "none",
                                  }}
                                >
                                  Any
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>
                        </Box>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))
            ) : (
              <Typography variant="body1" component="div" className="no-msg">
                No Filters Found
              </Typography>
            )}
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={2}
            className="filter-content-wrapper"
          >
            {filters?.shareddatas?.length > 0 ? (
              filters?.shareddatas?.map((filter) => (
                <Box className="filer-accordion-wrapper">
                  <Accordion>
                    <AccordionSummary
                      className="accordion-title"
                      expandIcon={<RiArrowDownSLine />}
                      aria-controls="filter-content-1"
                      id="filter-header-1"
                    >
                      <Typography className="title-content">
                        {filter?.project_filters?.filter_name === "" ||
                        filter?.project_filters?.filter_name === null
                          ? "-"
                          : capitalizeWords(
                              filter?.project_filters?.filter_name
                            )}
                      </Typography>
                      <Stack
                        className="action-btn-wrap"
                        direction="row"
                        spacing={2}
                      >
                        <Button
                          variant="outlined"
                          className="action-btn view-btn"
                          startIcon={<RiFilter2Line />}
                          disableRipple
                          onClick={() =>
                            handleShareFilterById(filter.filter_id)
                          }
                        ></Button>
                        {userRole === "Business Manager" ||
                        userRole === "Admin" ||
                        userRole === "Technical Manager" ? (
                          <>
                            <Button
                              variant="outlined"
                              className="action-btn convert-btn"
                              startIcon={<RiShareLine />}
                              disableRipple
                              onClick={(event) => {
                                event.stopPropagation();
                                handleShareFilterModal(filter.filter_id);
                              }}
                            ></Button>{" "}
                            <Button
                              variant="outlined"
                              className="action-btn edit-btn"
                              startIcon={<RiPencilLine />}
                              disableRipple
                              onClick={() => {
                                handleEditFilter(filter.filter_id);
                                setIsShared(true);
                              }}
                            ></Button>
                            <WithPermission
                              permission={fullProjects}
                              element={
                                <Button
                                  variant="outlined"
                                  className="action-btn dlt-btn"
                                  startIcon={<RiDeleteBinLine />}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleDeleteModal(filter.filter_id);
                                  }}
                                  disableRipple
                                ></Button>
                              }
                            />
                          </>
                        ) : null}
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails className="filter-accordion-details">
                      <Stack spacing={2} className="saved-filter-wrapper">
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title"
                          >
                            <p>Created By:</p>
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            className="desc"
                          >
                            {filter?.project_filters?.users?.first_name}{" "}
                            {filter?.project_filters?.users?.last_name}
                          </Typography>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title"
                          >
                            <p>Shared Comment:</p>
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            className="desc"
                          >
                            {filter?.project_filters?.shared_comment === "" ||
                            filter?.project_filters?.shared_comment === null
                              ? "-"
                              : filter?.project_filters?.shared_comment}{" "}
                          </Typography>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title"
                          >
                            <p>Search Text :</p>
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            className="desc"
                          >
                            {filter?.project_filters?.search_text === null ||
                            filter?.project_filters?.search_text === ""
                              ? "-"
                              : filter?.project_filters?.search_text}
                          </Typography>
                        </Box>

                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title"
                          >
                            <p>Development Start Date :</p>
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            className="desc"
                          >
                            {filter?.project_filters?.start_date
                              ? `${formatDate(
                                  filter?.project_filters?.start_date
                                )} - ${formatDate(
                                  filter?.project_filters?.end_date
                                )}`
                              : "-"}
                          </Typography>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title"
                          >
                            <p> Development Status</p>
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            className="desc"
                          >
                            {filter?.project_filters?.development_status ===
                            null
                              ? "-"
                              : capitalizeFirstLetter(
                                  filter?.project_filters?.development_status
                                )}
                          </Typography>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title"
                          >
                            <p> Users :</p>
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="body2"
                              component="span"
                              className="desc"
                            >
                              {filter?.project_filters?.project_filter_assignees
                                ?.length > 0
                                ? filter?.project_filters?.project_filter_assignees?.map(
                                    (user, index) => (
                                      <Box key={user?.project_assignees?.id}>
                                        <Typography
                                          variant="body2"
                                          component="span"
                                          className="desc"
                                        >
                                          {user?.project_assignees?.first_name +
                                            " " +
                                            user?.project_assignees?.last_name}
                                        </Typography>

                                        {index <
                                          filter?.project_filters
                                            ?.project_filter_assignees?.length -
                                            1 && <span>, </span>}
                                      </Box>
                                    )
                                  )
                                : "-"}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title"
                          >
                            <p>Keywords :</p>
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="body2"
                              component="span"
                              className="desc"
                            >
                              {filter?.project_filters?.project_filter_keywords
                                ?.length > 0
                                ? filter?.project_filters?.project_filter_keywords?.map(
                                    (keyword, index) => (
                                      <>
                                        <Typography
                                          variant="body2"
                                          component="span"
                                          className="desc"
                                        >
                                          {keyword?.keywords?.keyword_name}
                                        </Typography>

                                        {index <
                                          filter?.project_filters
                                            ?.project_filter_keywords?.length -
                                            1 && <span>, </span>}
                                      </>
                                    )
                                  )
                                : "-"}
                            </Typography>
                            <Box
                              className="saved-filter-content"
                              sx={{ marginTop: "10px" }}
                            >
                              <Typography variant="body2" className="title">
                                Match :
                              </Typography>

                              <ToggleButtonGroup
                                value={
                                  filter?.keywordMatch === "and" ? "all" : "any"
                                }
                                disabled
                                sx={{ height: "0px", marginLeft: "10px" }}
                              >
                                <ToggleButton
                                  value="all"
                                  sx={{
                                    fontSize: "12px",
                                    padding: "9px",
                                    textTransform: "none",
                                  }}
                                >
                                  All
                                </ToggleButton>
                                <ToggleButton
                                  value="any"
                                  sx={{
                                    fontSize: "12px",
                                    padding: "9px",
                                    textTransform: "none",
                                  }}
                                >
                                  Any
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title"
                          >
                            <p>Technology Stack :</p>
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="body2"
                              component="span"
                              className="desc"
                            >
                              {filter?.project_filters
                                ?.project_filter_technology_stacks?.length > 0
                                ? filter?.project_filters?.project_filter_technology_stacks?.map(
                                    (technology, index) => (
                                      <>
                                        <Typography
                                          variant="body2"
                                          component="span"
                                          className="desc"
                                        >
                                          {
                                            technology?.technologies
                                              ?.technology_name
                                          }
                                        </Typography>
                                        {index <
                                          filter?.project_filters
                                            ?.project_filter_technology_stacks
                                            ?.length -
                                            1 && <span>, </span>}
                                      </>
                                    )
                                  )
                                : "-"}
                            </Typography>
                            <Box
                              className="saved-filter-content"
                              sx={{ marginTop: "10px" }}
                            >
                              <Typography variant="body2" className="title">
                                Match :
                              </Typography>

                              <ToggleButtonGroup
                                value={
                                  filter?.technologyStackMatch === "and"
                                    ? "all"
                                    : "any"
                                }
                                disabled
                                sx={{ height: "0px", marginLeft: "10px" }}
                              >
                                <ToggleButton
                                  value="all"
                                  sx={{
                                    fontSize: "12px",
                                    padding: "9px",
                                    textTransform: "none",
                                  }}
                                >
                                  All
                                </ToggleButton>
                                <ToggleButton
                                  value="any"
                                  sx={{
                                    fontSize: "12px",
                                    padding: "9px",
                                    textTransform: "none",
                                  }}
                                >
                                  Any
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>
                        </Box>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))
            ) : (
              <Typography variant="body1" component="div" className="no-msg">
                No Filters Found
              </Typography>
            )}
          </CustomTabPanel>
        </Box>
      </Drawer>
      {/* END :: PROJECT FILTER PANEL */}

      {/*  START :: SAVE FILTER MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={SaveFilter}
        disabled={filterByIdApplied}
        // onClose={ExportToPdfModalClose}
        closeAfterTransition
      >
        <Fade in={SaveFilter}>
          <Box className="modal-wrapper">
            <Box
              variant="div"
              component="div"
              className="title"
              sx={{ marginBottom: "24px" }}
            >
              <Typography variant="h3" component="h3">
                Save Filter
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body">
              <Typography variant="body1" component="label">
                Name
                <Typography
                  variant="body1"
                  component="span"
                  className="asterisk"
                >
                  *
                </Typography>
              </Typography>
              {!isEditing ? (
                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Enter Save Filter Name"
                  value={filterName}
                  onChange={(e) => {
                    setFilterName(e.target.value);
                    if (e.target.value) {
                      setFilterNameError("");
                    }
                  }}
                />
              ) : (
                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Enter Save Filter Name"
                  value={editFilterName}
                  onChange={(e) => {
                    setEditFilterName(e.target.value);
                    if (e.target.value) {
                      setEditFilterNameError("");
                    }
                  }}
                />
              )}
              {!isEditing
                ? filterNameError && (
                    <Typography variant="body1" component="p" className="error">
                      {filterNameError}
                    </Typography>
                  )
                : editFilterNameError && (
                    <Typography variant="body1" component="p" className="error">
                      {editFilterNameError}
                    </Typography>
                  )}
            </Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  disabled={isButtonDisabled}
                  onClick={isEditing ? handleEditFilterSave : handleFiltersave}
                >
                  {isEditing ? "Update" : "Save"}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => setSaveFilterModal(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {/* END :: SAVE FILTER MODAL */}

      {/*  START :: DELETE SAVED FILTER MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={DeleteSavedFilter}
        closeAfterTransition
      >
        <Fade in={DeleteSavedFilter}>
          <Box className="modal-wrapper">
            <Typography variant="h3" component="h3">
              Do you want to delete this saved filter?
            </Typography>
            <Typography variant="body1" component="p">
              This action can’t be undone.
            </Typography>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={deleteSubmitHandler}
                >
                  Delete Filter
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => setDeleteSavedFilterModal(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={ActivityLog}
        // onClose={ExportToPdfModalClose}
        closeAfterTransition
      >
        <Fade in={ActivityLog}>
          <div className="modal-wrapper">
            <Box
              variant="div"
              component="div"
              className="title"
              sx={{ marginBottom: "24px" }}
            >
              <Typography variant="h3" component="h3">
                Share Filter
              </Typography>
            </Box>
            {/* <Box variant="div" component="div" className="body">   */}
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Typography variant="body1" component="label">
                  Select Member(s)
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  isMulti
                  name="colors"
                  options={memberOptions}
                  value={members}
                  onChange={(selectedOptions, { removedValue }) => {
                    if (removedValue) {
                      const removedOptionId = removedValue.value;

                      setRemoveKeyId([...removeKeyId, removedOptionId]);
                    }
                    handleOptionChange(selectedOptions);
                  }}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                />
                {membererror && (
                  <Typography variant="body1" component="p" className="error">
                    {membererror}
                  </Typography>
                )}
              </Grid>

              <Grid item lg={12} md={12} sm={12}>
                <Typography variant="body1" component="label">
                  Comments
                </Typography>
                <TextareaAutosize
                  className="textarea-field modal-textarea"
                  placeholder="Add Comments"
                  value={sharedcomment}
                  onChange={(e) => setSharedComment(e.target.value)}
                />
              </Grid>
            </Grid>

            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={handleShareModal}
                >
                  Share
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => {
                    setMembers([]);
                    setSharedComment("");
                    setActivityLogModal(false);
                    setMemberError("");
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
      {/* END :: DELETE SAVED FILTER MODAL */}
    </>
  );
};

export default Projects;

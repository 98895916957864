import {
  Box,
  Fade,
  Modal,
  Typography,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import Select from "react-select";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { covertLead } from "../../store/slices/convertLeadSlice";
import { successToast } from "../../responses/successToast";
import { fetchLeads } from "../../store/slices/leadSlice";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "rsuite";

const developmentStatusOptions = [
  { value: "ongoing", label: "Ongoing" },
  { value: "planned", label: "Planned" },
];

const ConvertLeadToProject = ({
  convertCancel,
  convertLeadId,
  value,
  leadProjectName,
  setConvertCancel,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const leadId = convertLeadId;
  const [developmentStatus, setDevelopmentStatus] = useState("planned");
  const [projectName, setProjectName] = useState("");
  const [projecterror, setProjectError] = useState("");
  const [startDate, setStartDate] = useState("");
  const handleDevelopmentStatusChange = (selectedOption) => {
    setDevelopmentStatus(selectedOption);
  };

  const handleConvertClose = () => {
    setConvertCancel(false);
    setProjectName("");
    setStartDate("");
    setProjectError("");
  };
  const submitHandler = () => {
    if (!projectName) {
      setProjectError("Please enter project name");
      return;
    }
    dispatch(
      covertLead({
        leadId,
        development_status: developmentStatus,
        start_date: startDate,
        project_name: projectName,
      })
    )
      .then((result) => {
        // Handle success
        const { success } = result;
        // console.log(success, "convert lead success");
        if (success) {
          successToast("Lead converted to project successfully");
          if (value === "view") {
            handleConvertClose();
            setProjectName("");
            setStartDate("");
            navigate("/leads");
          } else {
            dispatch(
              fetchLeads({
                page: "",
                status:
                  value === 0
                    ? ""
                    : value === 1
                    ? "open"
                    : value === 2
                    ? "estimation_requested"
                    : value === 3
                    ? "estimation_provided"
                    : value === 4
                    ? "standBy"
                    : value === 5
                    ? "closed"
                    : value === 6
                    ? "won"
                    : "lost",
                hot_lead: value === 0 ? 1 : "",
              })
            );
            handleConvertClose();
            navigate("/leads");
            setProjectName("");
            setStartDate("");
          }
        }
      })
      .catch((error) => {
        // Handle error
        console.error("Error converting lead:", error);
        handleConvertClose();
        // You can dispatch an action or show an error message here.
      });
  };

  const handleResponseDateChange = (date) => {
    setStartDate(date);
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={convertCancel}
        // onClose={ConvertLeadToProjectModalClose}
        closeAfterTransition
      >
        <Fade in={convertCancel}>
          <Box className="modal-wrapper" style={{ minHeight: "400px" }}>
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3">
                Do you want to convert this lead to project?
              </Typography>
              <Grid container spacing={2} sx={{marginTop:'8px'}}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      variant="body1"
                      component="h5"
                      style={{
                        minWidth: "190px",
                        marginBottom:"4px"
                      }}
                    >
                      Lead Project Name
                    </Typography>
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Proper Project Name"
                      value={leadProjectName}
                      style={{ width: "100%" }}
                      disabled
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      variant="body1"
                      component="h5"
                      style={{
                        marginBottom: "4px",
                      }}
                    >
                      Development Status
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                        style={{ color: "red" }}
                      >
                        *
                      </Typography>
                    </Typography>
                    <Select
                      name="colors"
                      options={developmentStatusOptions}
                      value={developmentStatusOptions.find(
                        (Option) => Option.value === developmentStatus
                      )}
                      className="muilt-select-field"
                      classNamePrefix="select"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,

                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "#8CC714",
                        },
                      })}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                        }),
                      }}
                      onChange={(selectedOption) =>
                        handleDevelopmentStatusChange(selectedOption?.value)
                      }
                    />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        variant="body1"
                        component="h5"
                        style={{
                          marginBottom: "4px",
                        }}
                      >
                        Project Name
                        <Typography
                          variant="body1"
                          component="span"
                          className="asterisk"
                          style={{ color: "red" }}
                        >
                          *
                        </Typography>
                      </Typography>
                      <Box style={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Enter Proper Project Name"
                          value={projectName}
                          onChange={(event) => {
                            setProjectName(event.target.value);
                            if (event.target.value) {
                              setProjectError("");
                            }
                          }}
                        />

                        <Typography
                          variant="p"
                          component="span"
                          style={{
                            fontSize: "11px",
                            paddingTop: "5px",
                            fontStyle: "italic",
                            fontWeight: "500",
                            color: "rgb(180 92 46)",
                          }}
                        >
                          {" "}
                          {/* <i> */} Note: Enter a brief project name (1 or max 3
                          words) that is easily identifiable in both PMS and TMS.
                          {/* </i> */}
                        </Typography>
                        {projecterror && (
                          <Typography
                            variant="body1"
                            component="p"
                            className="error"
                          >
                            {projecterror}
                          </Typography>
                        )}
                      </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>                  
                      <Typography
                        variant="body1"
                        component="h5"
                        style={{
                          marginBottom: "4px",
                        }}
                      >
                        Development Start Date
                      </Typography>
                      <DatePicker
                        oneTap
                        format="dd-MM-yyyy"
                        placeholder="Select Development Start Date"
                        onKeyDown={(event) => event.preventDefault()}
                        className="modal-date-picker"
                        onChange={handleResponseDateChange}
                      />
                </Grid>
              </Grid>
            </Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={submitHandler}
                >
                  Convert
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={handleConvertClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ConvertLeadToProject;

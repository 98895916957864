import React, { useRef, useEffect } from "react";
import Select from "react-select";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import {
  RiAddCircleLine,
  RiArrowLeftLine,
  RiDeleteBinLine,
  RiDragMove2Fill,
  RiCloseCircleLine,
} from "react-icons/ri";
import CreatableSelect from "react-select/creatable";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import { useDropzone } from "react-dropzone";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectsById } from "../../store/slices/projectbyIdSlice";
import { fetchKeywords } from "../../store/slices/keywordSlice";
import { fetchTechnologies } from "../../store/slices/technologySlice";
import { editProjectByIdAdmin } from "../../store/slices/editProject";
import { fetchUsers } from "../../store/slices/userSlice";
import AddTechnologyModal from "../../components/common/addTechnologySlice";
import AddKeywordModal from "../../components/common/addKeywordModal";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { fetchClients } from "../../store/slices/clientLeadSlice";
import { fetchContacts } from "../../store/slices/contactSlice";
import { callApi } from "../../components/common/commonfunctionforapi";
import TextModal from "../../components/common/textModal";
// import { errorToast } from "../../responses/errorToast";
import { DatePicker } from "rsuite";
import { successToast } from "../../responses/successToast";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { maskEmail, maskPhoneNumber } from "../../utils/masking";
import { selectIsAdmin } from "../../constants/selectors";
// PROJECT STATUS OPTIONS
const projectStatusOptions = [
  { value: "live", label: "Live" },
  { value: "developing", label: "Development" },
  { value: "beta_testing", label: "Beta Testing" },
  { value: "abandoned", label: "Abandoned" },
];

const developmentStatusOptions = [
  { value: "ongoing", label: "Ongoing" },
  { value: "planned", label: "Planned" },
  { value: "stopped", label: "Stopped" },
];

const modulesKeyFeature = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};

const modulesKeyIntegration = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};
const validateEmail = (email) => {
  const emailPattern =
    /^[a-zA-Z0-9](?!.*\.\.)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,63})+$/;
  return emailPattern.test(email);
};

function capitalizeWords(input) {
  return input?.replace(/\w+/g, function (word) {
    return word?.charAt(0).toUpperCase() + word.slice(1);
  });
}

const ProjectEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      status: "live",
      page: 1,
      rowsPerPage: 10,
      filterApplied: false,
      filterByIdApplied: false,
      applyFilterId: "",
      selectedKeywords: [],
      selectedTechnologies: [],
      text: "",
      startDate: [],
      endDate: [],
      date: "",
      destatus: null,
      edit: "",
      keywordSwitchVal: true,
      techStackSwitchVal: true,
    };
  }

  const { projectId } = useParams();
  const projects = useSelector((state) => state.projectsById.projects);
  const getUserRole = useSelector(selectIsAdmin);
  const [obtainedClientId, setObtainedClientId] = useState("");
  const [roleEmail, setRoleEmail] = useState(getUserRole);
  const [rolePhone, setRolePhone] = useState(getUserRole);
  const [roleContEmail, setRoleContEmail] = useState(getUserRole);
  const [roleContPhone, setRoleContPhone] = useState(getUserRole);
  const contacts = useSelector((state) => state.contacts.contacts);
  const clients = useSelector((state) => state.clientsLead.clients?.[0]);
  const [projectName, setprojectName] = useState("");
  const [projectdescription, setProjectDescription] = useState("");
  const [projectStatus, setprojectStatus] = useState(projects?.status);
  const [developmentStatus, setDevelopmentStatus] = useState(
    projects?.development_status
  );
  const [projectKeywords, setprojectKeywords] = useState([]);
  const [projectTechnologies, setProjectTechnologies] = useState([]);

  const keywords = useSelector((state) => state.keywords.keywords);
  const technologies = useSelector((state) => state.technologies.technologies);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [documentPaths, setDocumentPaths] = useState([]);
  const [selectedPrimaryTL, setSelectedPrimaryTL] = useState();
  const [selectedSecondaryTL, setSelectedSecondaryyTL] = useState();
  const [selectedPrimaryBDA, setSelectedPrimaryBDA] = useState();
  const [selectedSecondaryBDA, setSelectedSecondaryBDA] = useState();
  const [addedVideoPaths, setAddedVideoPaths] = useState([]);
  const [imagePaths, setImagePaths] = useState([]);
  const [addedDocuments /*setAddedDocuments*/] = useState([]);
  const [keywordPayload, setKeywordPayload] = useState([]);
  const [technologyPayload, setTechnologyPayload] = useState([]);

  const [keyfeature, setKeyfeature] = useState("");
  const [keyintegration, setKeyIntegration] = useState("");
  const users = useSelector((state) => state.users.users);
  const [type /*setType*/] = useState("");
  const [referenceDelete, setReferenceDelete] = useState([]);
  const [projectDelete, setProjectDelete] = useState([]);
  const [projectnameerror, setprojectnameError] = useState("");
  const [projectdescriptionerror, setprojectdescriptionError] = useState("");
  const [keywordError, setKeywordError] = useState("");
  const [technologyError, setTechnologyError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [addTechModal, setAddTechModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [techLoading, setTechLoading] = useState(false);
  const [invalidValue, setInvalidValue] = useState("");
  const [validTechName, setValidTechName] = useState("");
  const [uploadLinkError, setUploadLinkError] = useState("");
  const [value, setValue] = useState("");
  const [companies, setCompanies] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyNumber, setCompanyNumber] = useState("");
  const [companyPayload, setCompanyPayload] = useState("");
  const [companiesperson, setCompaniesPerson] = useState("");
  const [companyPayloadPerson, setCompanyPayloadPerson] = useState("");
  const [contactPerEmail, setContactPerEmail] = useState("");
  const [contactPersonPhoneNo, setContactPersonPhoneNo] = useState("");
  const [compName, setCompName] = useState("");
  const [compEmail, setCompEmail] = useState("");
  const [compNumber, setCompNumber] = useState("");
  const [conPerson, setConPerson] = useState("");
  const [conPersonEmail, setConPersonEmail] = useState("");
  const [conPersonNumber, setConPersonNumber] = useState("");
  const [primarytlerror, setPrimaryTlError] = useState("");
  const [primarybdaerror, setPrimaryBdaError] = useState("");
  const [documentUploadLink, setDocumentUploadLink] = useState("");
  const [filename, setFileName] = useState("");
  const [documentlinkerror, setdocumentLinkError] = useState("");
  const [displayedDocuments, setDisplayedDocuments] = useState([]);
  const [displayedfilenames, setDisplayedFilenames] = useState([]);
  const [exiscomperror, setExisCompError] = useState("");
  const [exispersonerror, setExisPersonError] = useState("");
  const [companynameerror, setCompanyNameError] = useState("");
  const [conPerErrorMsg, setConPerErrorMsg] = useState("");
  const [imageUploadLink, setImageUploadLink] = useState("");
  const [videoFileName, setVideoFileName] = useState("");
  const [imageFileName, setImageFileName] = useState("");
  const [displayedImages, setDisplayedImages] = useState([]);
  const [imagelinkerror, setImageLinkError] = useState("");
  const [displayedVideofilenames, setDisplayedVideoFilenames] = useState([]);
  const [displayedImagefilenames, setDisplayedImageFilenames] = useState([]);
  const [modalisopen, setModalIsOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [reasonError, setReasonError] = useState("");
  const [showReason, setShowReason] = useState(developmentStatus === "stopped");
  const [startDate, setStartDate] = useState("");
  const [proStatusErrorMsg, setProStatusErrorMsg] = useState("");
  const [deveStatuserrorMsg, setDeveStatusErrorMsg] = useState("");
  const maxLength = 31;

  const keywordData = keywords?.allKeywords?.map((keyword) => ({
    value: keyword.id,
    label: keyword.name,
  }));
  const technologyData = technologies?.allTechnologies?.map((technology) => ({
    value: technology.id,
    label: technology.name,
  }));

  const [selectedVideoIdPayload, setSelectedVideoIdPayload] = useState([]);
  const [selectedDocImgId, setSelectedDocImgId] = useState([]);
  const [removeKeyId, setRemoveKeyId] = useState([]);
  const [removeTechId, setRemoveTechId] = useState([]);

  const [selectedIdPayload, setSelectedIdPayload] = useState([]);
  const [sortingPayload, setSortingPayload] = useState([]);
  const [sortingImagePayload, setImageSortingPayload] = useState([]);
  // START :: PROJECT LINK ADD AND DELETE
  const [projectLinks, setProjectLinks] = useState([
    {
      linkText: "",
      linkId: "",
    },
  ]);
  const initialProjectLinks = projects?.project_links?.map((linkObj) => ({
    linkText: linkObj.project_link,
    linkId: linkObj.id,
  }));
  const initialReferenceLinks = projects?.project_reference_links?.map(
    (linkObj) => ({
      linkText: linkObj.project_reference_link,
      linkId: linkObj.id,
    })
  );

  const [uploadLink, setUploadLink] = useState("");
  const [displayedVideos, setDisplayedVideos] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await callApi({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/projects/${projectId}`,
        });

        const obtainedClientId = response.data.client_id;
        const contactPersonId = response.data.client_contacts.id;
        const companyPersonName =
          response.data.client_contacts.contact_person_name;
        // Fetch contacts and set obtainedClientId
        dispatch(
          fetchContacts({
            clientId: response.data.client_id,
            page: "",
            limit: "",
          })
        );

        setObtainedClientId(obtainedClientId);

        setCompaniesPerson(contactPersonId);
        setCompanyPayloadPerson(companyPersonName);
      } catch (error) {
        console.error(error.response);
      }
    };

    fetchData();
  }, [obtainedClientId, projectId, dispatch]);

  const addVideo = () => {
    if (!uploadLink) {
      setUploadLinkError("Please Enter Video cast Link");
      return;
    }
    const newVideo = uploadLink;

    setDisplayedVideos([...displayedVideos, newVideo]);
    setDisplayedVideoFilenames((prevFilenames) => [
      ...prevFilenames,
      videoFileName,
    ]);
    setUploadLink("");
    setVideoFileName("");
  };
  const addImage = () => {
    if (!imageUploadLink) {
      setImageLinkError("Please Enter Screenshot Link");
      return;
    }
    const newVideo = imageUploadLink;

    setDisplayedImages([...displayedImages, newVideo]);
    setDisplayedImageFilenames((prevFilenames) => [
      ...prevFilenames,
      imageFileName,
    ]);
    setImageFileName("");
    setImageUploadLink("");
  };
  const addDocument = (index) => {
    if (!documentUploadLink) {
      setdocumentLinkError("Please Enter Upload Link");
      return;
    }
    const newVideo = documentUploadLink;

    setDisplayedDocuments([...displayedDocuments, newVideo]);
    setDisplayedFilenames((prevFilenames) => [...prevFilenames, filename]);
    setFileName("");
    setDocumentUploadLink("");
  };
  const handleCreate = (inputValue) => {
    setIsLoading(true);
    setInvalidValue(inputValue);

    setTimeout(() => {
      setIsLoading(false);

      setShowModal(true);
    }, 1000);
  };
  const handleTechCreate = (inputValue) => {
    setTechLoading(true);
    setValidTechName(inputValue);

    setTimeout(() => {
      setTechLoading(false);

      setAddTechModal(true);
    }, 1000);
  };
  const generateUniqueId = () => {
    return Date.now().toString();
  };
  const addProjectLink = () => {
    let link = {
      linkText: "",
      linkId: generateUniqueId(),
    };
    setProjectLinks((current) => [...current, link]);
  };

  const addTextToLink = (value, i) => {
    let linkArray = [...projectLinks];
    linkArray[i].linkText = value;
    setProjectLinks(linkArray);
  };

  const handleDeleteLink = (i) => {
    let linkArray = [...projectLinks];
    const updatedProjectLinks = linkArray.filter((link) => link.linkId !== i);
    setProjectLinks(updatedProjectLinks);
    setProjectDelete([...projectDelete, i]);
  };

  const [ReferenceLinks, setReferenceLinks] = useState([
    {
      linkText: "",
      linkId: "",
    },
  ]);

  const addReferenceLink = () => {
    let link = {
      linkText: "",
      linkId: generateUniqueId(),
    };
    setReferenceLinks((current) => [...current, link]);
  };

  const addReferenceToLink = (value, i) => {
    let linkArray = [...ReferenceLinks];
    linkArray[i].linkText = value;
    setReferenceLinks(linkArray);
  };
  const handleLinkClick = (event, website) => {
    if (website && !website.startsWith("http")) {
      event.preventDefault();
      window.open(`http://${website}`, "_blank");
    }
  };
  const handleReferenceDeleteLink = (i) => {
    let linkArray = [...ReferenceLinks];
    const updatedReferenceLinks = linkArray.filter((link) => link.linkId !== i);
    setReferenceLinks(updatedReferenceLinks);
    setReferenceDelete([...referenceDelete, i]);
  };

  const handleRemoveImage = (index, arrayName) => {
    if (arrayName === "selectedImages") {
      const updatedDocPaths = [...imagePaths];

      const updatedDocuments = selectedImages.filter(
        (image) => image.linkId !== index
      );

      setSelectedImages(updatedDocuments);
      setImagePaths(updatedDocPaths);
      setSelectedIdPayload([...selectedIdPayload, index]);
    } else if (arrayName === "addedImages") {
      const updatedDocuments = [...displayedImages];
      const updatedDocPaths = [...displayedImagefilenames];

      updatedDocuments.splice(index, 1);
      updatedDocPaths.splice(index, 1);

      setDisplayedImages(updatedDocuments);
      setDisplayedImageFilenames(updatedDocPaths);
    }
  };

  const handleRemoveVideo = (index, arrayName) => {
    if (arrayName === "selectedVideos") {
      const updatedDocPaths = [...addedVideoPaths];

      const updatedDocuments = selectedVideos.filter(
        (image) => image.linkId !== index
      );

      setSelectedVideos(updatedDocuments);
      setAddedVideoPaths(updatedDocPaths);
      setSelectedVideoIdPayload([...selectedVideoIdPayload, index]);
    } else if (arrayName === "addedVideos") {
      const updatedDocuments = [...displayedVideos];
      const updatedDocPaths = [...displayedVideofilenames];

      updatedDocuments.splice(index, 1);
      updatedDocPaths.splice(index, 1);

      setDisplayedVideos(updatedDocuments);
      setDisplayedVideoFilenames(updatedDocPaths);
    }
  };
  const handleRemoveDocument = (index, arrayName) => {
    if (arrayName === "selectedDocuments") {
      const updatedDocPaths = [...documentPaths];

      const updatedDocuments = selectedDocuments.filter(
        (image) => image.linkId !== index
      );

      setSelectedDocuments(updatedDocuments);
      setDocumentPaths(updatedDocPaths);
      setSelectedDocImgId([...selectedDocImgId, index]);
    } else if (arrayName === "addedDocuments") {
      const updatedDocuments = [...displayedDocuments];
      const updatedDocPaths = [...displayedfilenames];

      updatedDocuments.splice(index, 1);
      updatedDocPaths.splice(index, 1);

      setDisplayedDocuments(updatedDocuments);
      setDisplayedFilenames(updatedDocPaths);
    }
  };
  const handleKeywordChange = (selectedOptions) => {
    if (selectedOptions) {
      setKeywordError("");
    }
    setprojectKeywords(selectedOptions);
    const selectedIds = selectedOptions.map((option) => option.value);

    setKeywordPayload(selectedIds);
  };

  const handleTechnologiesChange = (selectedOptions) => {
    if (selectedOptions) {
      setTechnologyError("");
    }
    setProjectTechnologies(selectedOptions);
    const selectedIds = selectedOptions.map((option) => option.value);

    setTechnologyPayload(selectedIds);
  };
  const handleStatusChange = (selectedOption) => {
    if (selectedOption) {
      setprojectStatus(selectedOption.value);
      setProStatusErrorMsg("");
    } else {
      setprojectStatus("");
    }
  };

  const handleDevelopmentStatusChange = (selectedOption) => {
    if (selectedOption) {
      setDevelopmentStatus(selectedOption.value);
      setShowReason(selectedOption.value === "stopped");
      setDeveStatusErrorMsg("");
    } else {
      setDevelopmentStatus("");
    }
    // if (selectedOption.value === "stopped") {
    //   setShowReason(true);
    // } else {
    //   setShowReason(false);
    // }
  };

  const handleSecondaryTLChange = (selectedOption) => {
    setSelectedSecondaryyTL(selectedOption);
  };
  const handlePrimaryBDAChange = (selectedOption) => {
    setSelectedPrimaryBDA(selectedOption);
    if (selectedOption) {
      setPrimaryBdaError("");
    }
  };
  const handlesecondaryBDAChange = (selectedOption) => {
    setSelectedSecondaryBDA(selectedOption);
  };

  const formData = new FormData();

  displayedImages.forEach((document, index) => {
    formData.append(`screenshot_files[${[index]}]`, document);
  });
  displayedImagefilenames.forEach((document, index) => {
    formData.append(`screenshot_file_name[${[index]}]`, document);
  });
  displayedVideos.forEach((document, index) => {
    formData.append(`video_files[${[index]}]`, document);
  });
  displayedVideofilenames.forEach((document, index) => {
    formData.append(`video_file_name[${[index]}]`, document);
  });
  displayedVideos.forEach((video, index) => {
    formData.append(`video_urls`, video);
  });
  displayedDocuments.forEach((document, index) => {
    formData.append(`document_files[${[index]}]`, document);
  });
  displayedfilenames.forEach((document, index) => {
    formData.append(`file_name[${[index]}]`, document);
  });

  addedDocuments.forEach((document, index) => {
    formData.append(`document_files`, document);
  });
  selectedIdPayload.forEach((image, index) => {
    formData.append(`ss_delete[]`, image);
  });
  selectedVideoIdPayload.forEach((image, index) => {
    formData.append(`video_delete[]`, image);
  });
  selectedDocImgId.forEach((image, index) => {
    formData.append(`document_delete[]`, image);
  });
  referenceDelete.forEach((id, index) => {
    formData.append("reference_link_delete", id);
  });
  projectDelete.forEach((id, index) => {
    formData.append("project_link_delete[]", id);
  });
  keywordPayload.forEach((value, index) => {
    formData.append("keyword_ids[]", value);
  });

  technologyPayload.forEach((value, index) => {
    formData.append("technology_ids[]", value);
  });
  ReferenceLinks.forEach((value, index) => {
    const linkText = value.linkText;

    if (typeof linkText === "string") {
      formData.append("reference_links[]", linkText);
    }
  });

  projectLinks.forEach((value, index) => {
    formData.append("project_link[]", value.linkText);
  });
  removeKeyId.forEach((value, index) => {
    formData.append("keyword_delete[]", value);
  });
  removeTechId.forEach((value, index) => {
    formData.append("technology_delete[]", value);
  });

  const [saveButtonClickCount, setSaveButtonClickCount] = useState(0);
  const proDescErrorMsgRef = useRef(null);
  const primaryTlRef = useRef(null);
  const contactPersonRef = useRef(null);
  const primaryBDRef = useRef(null);
  const companyNameRef = useRef(null);
  const statusRef = useRef(null);
  const devStatusRef = useRef(null);
  const keywordsErrorMsgRef = useRef(null);
  const technologyErrorMsgRef = useRef(null);
  const projectnameRef = useRef(null);
  const reasonRef = useRef(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  useEffect(() => {
    // Scroll to the keywords error message
    if (keywordError && keywordsErrorMsgRef.current) {
      keywordsErrorMsgRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [keywordError, keywordsErrorMsgRef, saveButtonClickCount]);
  useEffect(() => {
    if (deveStatuserrorMsg && devStatusRef.current) {
      devStatusRef.current.focus();
    }
  }, [devStatusRef, deveStatuserrorMsg, saveButtonClickCount]);

  useEffect(() => {
    if (proStatusErrorMsg && statusRef.current) {
      statusRef.current.focus();
    }
  }, [statusRef, proStatusErrorMsg, saveButtonClickCount]);

  useEffect(() => {
    // Scroll to the "Technology Stacks" Typography component
    if (technologyError && technologyErrorMsgRef.current) {
      technologyErrorMsgRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [technologyError, technologyErrorMsgRef, saveButtonClickCount]);

  useEffect(() => {
    // Scroll to the "Technology Stacks" Typography component
    if (primarybdaerror && primaryBDRef.current) {
      primaryBDRef.current.focus();
    }
  }, [primarybdaerror, primaryBDRef, saveButtonClickCount]);

  useEffect(() => {
    // Scroll to the "Technology Stacks" Typography component
    if (primarytlerror && primaryTlRef.current) {
      primaryTlRef.current.focus();
    }
  }, [primarytlerror, primaryTlRef, saveButtonClickCount]);

  useEffect(() => {
    // Scroll to the keywords error message
    if (projectdescriptionerror && proDescErrorMsgRef.current) {
      proDescErrorMsgRef.current.focus();
    }
  }, [projectdescriptionerror, proDescErrorMsgRef, saveButtonClickCount]);

  useEffect(() => {
    // Scroll to the "Technology Stacks" Typography component
    if (projectnameerror && projectnameRef.current) {
      projectnameRef.current.focus();
    }
  }, [projectnameerror, projectnameRef, saveButtonClickCount]);

  useEffect(() => {
    if (conPerErrorMsg && contactPersonRef.current) {
      contactPersonRef.current.focus();
    }
  }, [contactPersonRef, conPerErrorMsg, saveButtonClickCount]);

  useEffect(() => {
    if (companynameerror && companyNameRef.current) {
      companyNameRef.current.focus();
    }
  }, [companyNameRef, companynameerror, saveButtonClickCount]);

  useEffect(() => {
    if (reasonError && reasonRef.current) {
      reasonRef.current.focus();
    }
  }, [reasonRef, reasonError, saveButtonClickCount]);

  const handleDrag = (e) => {
    if (!e.destination) return;

    const updatedVideos = Array.from(selectedVideos);
    const [reorderedVideo] = updatedVideos.splice(e.source.index, 1);
    updatedVideos.splice(e.destination.index, 0, reorderedVideo);

    // Update the state with the reordered videos
    setSelectedVideos(updatedVideos);

    // Generate sorting order payload as an array
    const sortingOrderPayload = updatedVideos.map((video) => video.linkId);

    // Now you can use sortingOrderPayload as needed, e.g., in an API call
    const videoOrder = sortingOrderPayload.join(",");
    setSortingPayload(videoOrder);
  };
  formData.append("video_sorting_order", sortingPayload);

  const handleScreenShotDrag = (e) => {
    if (!e.destination) return;

    const updatedVideos = Array.from(selectedImages);
    const [reorderedVideo] = updatedVideos.splice(e.source.index, 1);
    updatedVideos.splice(e.destination.index, 0, reorderedVideo);

    // Update the state with the reordered videos
    setSelectedImages(updatedVideos);

    // Generate sorting order payload as an array
    const sortingOrderPayload = updatedVideos.map((video) => video.linkId);

    // Now you can use sortingOrderPayload as needed, e.g., in an API call
    const imgOrder = sortingOrderPayload.join(","); // Update the variable

    setImageSortingPayload(imgOrder);
  };
  formData.append("scrrenshot_sorting_order", sortingImagePayload); // Append the value to formData

  // Rest of the code remains unchanged

  const submitHandler = async () => {
    setSaveButtonClickCount((prevCount) => prevCount + 1);
    if (isButtonDisabled) {
      return;
    }
    let flag = 0;
    if (value === "new_opportunity" && !compName.trim()) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setCompanyNameError("Please enter company name");
      flag = 1;
    }
    if (value === "new_opportunity" && !conPerson.trim()) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setConPerErrorMsg("Please enter contact person name");
      flag = 1;
    }
    if (value === "existing_client" && !companies) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setExisCompError("Please Select company name");
      flag = 1;
    }
    if (value === "existing_client" && !companiesperson) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setExisPersonError("Please select contact person");
      flag = 1;
    }

    if (!projectName) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setprojectnameError("Please enter project name");
      flag = 1;
    }
    if (!projectdescription) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setprojectdescriptionError("Please enter project description");
      flag = 1;
    }

    if (keywordPayload.length === 0 && projectKeywords?.length === 0) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setKeywordError("Please enter at least one keyword");
      flag = 1;
    }
    if (technologyPayload.length === 0 && projectTechnologies?.length === 0) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setTechnologyError("Please enter at least one technology");
      flag = 1;
    }
    if (!selectedPrimaryTL) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setPrimaryTlError("Please enter primary TL");
      flag = 1;
    }
    if (!selectedPrimaryBDA) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setPrimaryBdaError("Please enter primary BDA");
      flag = 1;
    }
    if (!projectStatus) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setProStatusErrorMsg("Please select project status");
      flag = 1;
    }
    if (!developmentStatus) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setDeveStatusErrorMsg("Please select development status");
      flag = 1;
    }

    if (developmentStatus === "stopped" && !reason) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setReasonError("Please enter the reason");
      flag = 1;
    }
    if (flag !== 0) {
      return;
    }
    setIsButtonDisabled(true);

    const client_type = value;
    const projectData = {
      company_name: value === "new_opportunity" ? compName : companyPayload,
      company_email: value === "new_opportunity" ? compEmail : companyEmail,
      company_phone: value === "new_opportunity" ? compNumber : companyNumber,
      contact_person:
        value === "new_opportunity" ? conPerson : companyPayloadPerson,
      contact_person_email:
        value === "new_opportunity" ? conPersonEmail : contactPerEmail,
      contact_person_phone:
        value === "new_opportunity" ? conPersonNumber : contactPersonPhoneNo,
      project_name: projectName,
      project_description: projectdescription,
      reason: developmentStatus === "stopped" ? reason : "",
      status: projectStatus,

      development_status: developmentStatus,
      primary_tl_id: selectedPrimaryTL,

      ...(selectedSecondaryTL && { secondary_tl_id: selectedSecondaryTL }),
      primary_bda_id: selectedPrimaryBDA,

      ...(selectedSecondaryBDA && { secondary_bda_id: selectedSecondaryBDA }),
      key_features: keyfeature,
      key_integration: keyintegration,
      client_id: value === "new_opportunity" ? "" : companies,
      client_contact_id: value === "new_opportunity" ? "" : companiesperson,
      start_date: startDate === null ? "" : startDate,
      // video_sorting_order: sortingPayload,
      // scrrenshot_sorting_order: sortingImagePayload,
    };
    for (const key in projectData) {
      if (Object.hasOwnProperty.call(projectData, key)) {
        formData.append(key, projectData[key]);
      }
    }


    try {
      const response = await dispatch(
        editProjectByIdAdmin(projectId, formData, client_type)
      );

      if (response.success) {
        navigate("/projects", {
                state: {
                  page: state.page,
                  rowsPerPage: state.rowsPerPage,
                  status: state.status,
                  filterApplied: state.filterApplied,
                  text: state.text,
                  selectedKeywords: state.selectedKeywords,
                  selectedTLandBDAs:state.selectedTLandBDAs,
                  selectedTechnologies: state.selectedTechnologies,
                  keywordSwitchVal: state.keywordSwitchVal,
                  techStackSwitchVal: state.techStackSwitchVal,
                  startDate: state.startDate,
                  endDate: state.endDate,
                  date: state.date,
                  destatus: state.destatus,
                  filterByIdApplied: state.filterByIdApplied,
                  applyFilterId: state.applyFilterId,
                  edit: state.edit,
                },
              });
        successToast("Project updated successfully!");
      } else {
        console.error("Error creating user:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      setIsButtonDisabled(false);
    }
  };

  const teamleaders = users?.filteredTeamManager;

  const BDA = users?.filteredBda;

  const SecondaryTeamLeaderoptions = teamleaders
    ? teamleaders
        .filter((tl) => tl.id !== selectedPrimaryTL)
        .map((tl) => ({
          value: tl.id,
          label: `${capitalizeWords(tl.first_name)} ${capitalizeWords(
            tl.last_name
          )}`,
        }))
    : [];

  const PrimaryTeamLeaderoptions = teamleaders
    ? teamleaders
        .filter((tl) => tl.id !== selectedSecondaryTL)
        .map((tl) => ({
          value: tl.id,
          label: `${capitalizeWords(tl.first_name)} ${capitalizeWords(
            tl.last_name
          )}`,
        }))
    : [];
  const PrimaryBDAoptions = BDA
    ? BDA.filter((bda) => bda.id !== selectedSecondaryBDA).map((bda) => ({
        value: bda.id,
        label: `${capitalizeWords(bda.first_name)} ${capitalizeWords(
          bda.last_name
        )}`,
      }))
    : [];
  const SecondaryBDAoptions = BDA
    ? BDA.filter((bda) => bda.id !== selectedPrimaryBDA).map((bda) => ({
        value: bda.id,
        label: `${capitalizeWords(bda.first_name)} ${capitalizeWords(
          bda.last_name
        )}`,
      }))
    : [];
  const handlePrimaryTLChange = (selectedOption) => {
    setSelectedPrimaryTL(selectedOption);
    if (selectedOption) {
      setPrimaryTlError("");
    }
  };
  const handleKeyFeatureChange = (data) => {
    setKeyfeature(data);
  };

  const handleKeyIntegrationChange = (data) => {
    setKeyIntegration(data);
  };
  useEffect(() => {
    if (projects) {
      setValue(projects?.client_type);

      if (projects?.client_type === "existing_client") {
        setCompanyName(projects?.projectclients?.company_name);
        setCompanies(projects?.client_id);
        setCompanyPayload(projects?.projectclients?.company_name);
        setCompanyEmail(projects?.projectclients?.company_email);
        setCompanyNumber(projects?.projectclients?.company_phone);

        setCompanyPayloadPerson(projects?.client_contacts?.contact_person_name);
        setContactPerEmail(projects?.client_contacts?.contact_person_email);
        setContactPersonPhoneNo(
          projects?.client_contacts?.contact_person_phone
        );
        setCompName("");
        setCompEmail("");
        setCompNumber("");
        setConPerson("");
        setConPersonEmail("");
        setConPersonNumber("");
      } else if (projects?.client_type === "new_opportunity") {
        setCompName(projects?.projectclients?.company_name);
        setCompEmail(projects?.projectclients?.company_email);
        setCompNumber(projects?.projectclients?.company_phone);
        setConPerson(projects?.client_contacts?.contact_person_name);
        setConPersonEmail(projects?.client_contacts?.contact_person_email);
        setConPersonNumber(projects?.client_contacts?.contact_person_phone);
        setCompanyName("");
        setCompanyPayload("");
        setCompanyEmail("");
        setCompanyNumber("");
        setCompaniesPerson("");
        setCompanyPayloadPerson("");
        setContactPerEmail("");
        setContactPersonPhoneNo("");
        setCompanies("");
      }
      if (projects?.project_name === "" || projects?.project_name === null) {
        setprojectName("");
      } else {
        setprojectName(projects?.project_name);
      }
      if (
        projects?.project_description === null ||
        projects?.project_description === ""
      ) {
        setProjectDescription("");
      } else {
        setProjectDescription(projects?.project_description);
      }
      setprojectStatus(projects?.status);
      setDevelopmentStatus(projects?.development_status);
      handleDevelopmentStatusChange({ value: projects?.development_status });
      if (projects?.reason === null || projects?.reason === "") {
        setReason("");
      } else {
        setReason(projects?.reason);
      }
      if (projects?.key_features === "" || projects?.key_features === null) {
        setKeyfeature("");
      } else {
        setKeyfeature(projects?.key_features);
      }
      if (
        projects?.key_integration === "" ||
        projects?.key_integration === null
      ) {
        setKeyIntegration("");
      } else {
        setKeyIntegration(projects?.key_integration);
      }

      const initialKeywordOptions = projects?.project_keywords?.map(
        (keywordName) => ({
          value: keywordName.keyword_id,
          label: keywordName.keywords?.keyword_name,
        })
      );
      setprojectKeywords(initialKeywordOptions);
      const initialtechnologyOptions = projects?.project_technologies?.map(
        (technologyName) => ({
          value: technologyName.project_technology_id,
          label: technologyName.technologies?.technology_name,
        })
      );

      setProjectTechnologies(initialtechnologyOptions);

      if (initialProjectLinks && initialProjectLinks.length > 0) {
        setProjectLinks(initialProjectLinks);
      } else {
        setProjectLinks([
          {
            linkText: "",
          },
        ]);
      }
      if (initialReferenceLinks && initialReferenceLinks.length > 0) {
        setReferenceLinks(initialReferenceLinks);
      } else {
        setReferenceLinks([
          {
            linkText: "",
          },
        ]);
      }
    }

    if (projects?.primary_tl_id !== 0 || projects?.primary_tl_id !== null) {
      setSelectedPrimaryTL(projects?.primary_tl_id);
    } else {
      setSelectedPrimaryTL("");
    }
    if (
      projects?.secondary_bda_id === 0 ||
      projects?.secondary_bda_id === null
    ) {
      setSelectedSecondaryBDA("");
    } else {
      setSelectedSecondaryBDA(projects?.secondary_bda_id);
    }
    if (projects?.primary_bda_id !== 0 || projects?.primary_bda_id !== null) {
      setSelectedPrimaryBDA(projects?.primary_bda_id);
    } else {
      setSelectedPrimaryBDA("");
    }
    if (projects?.secondary_tl_id !== 0 || projects?.secondary_tl_id !== null) {
      setSelectedSecondaryyTL(projects?.secondary_tl_id);
    } else {
      setSelectedSecondaryyTL("");
    }

    const initialImageLinks = projects?.project_screenshot_links?.map(
      (linkObj) => ({
        linkText: linkObj.screenshot_url,
        linkId: linkObj.id,
        linkName: linkObj.file_name,
      })
    );
    setSelectedImages(initialImageLinks);

    const imageIds =
      projects?.project_screenshot_links?.map((keyword) => keyword.id) || [];
    const imageSortingPayloadString = imageIds.join(",");
    setImageSortingPayload((prevKeywordPayload) => imageSortingPayloadString);

    const initialVideoLinks = projects?.project_video_links?.map((linkObj) => ({
      linkText: linkObj.video_url,
      linkId: linkObj.id,
      linkName: linkObj.file_name,
      // linkSortId: linkObj.sort_id,
    }));
    setSelectedVideos(initialVideoLinks);

    const videoIds =
      projects?.project_video_links?.map((keyword) => keyword.id) || [];
    const sortingPayloadString = videoIds.join(",");
    setSortingPayload((prevKeywordPayload) => sortingPayloadString);

    const initialDocumentLinks = projects?.project_document_links?.map(
      (linkObj) => ({
        linkText: linkObj.project_document_link,
        linkId: linkObj.id,
        linkName: linkObj.file_name,
      })
    );
    setSelectedDocuments(initialDocumentLinks);

    const responseDate = new Date(projects?.start_date);

    if (isNaN(responseDate.getTime())) {
      // Handle the case where the date string is not a valid date
      console.error("Invalid date format:", projects?.start_date);
    } else if (projects?.start_date === null) {
      setStartDate(null);
    } else {
      setStartDate(responseDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects]);

  useEffect(() => {
    dispatch(fetchProjectsById({ projectId }));
  }, [dispatch, projectId]);

  useEffect(() => {
    dispatch(fetchKeywords({ query: "" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchTechnologies({ query: "" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchUsers({
        type: type,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const companyOptions = clients?.client?.map((client) => ({
    value: client.id,
    label: client.company_name,
  }));

  const companyDetails = clients?.client?.filter(
    (client) => companies === client?.id
  );

  const companyEmailChnage = companyDetails?.map(
    (email) => email.company_email
  )[0];

  const companyPhoneChange = companyDetails?.map(
    (phone) => phone.company_phone
  )[0];

  const handleCompanySelect = async (selectedOptions) => {
    if (selectedOptions) {
      setExisCompError("");
    }
    setCompanies(selectedOptions.value);

    setCompanyPayload(selectedOptions.label);

    const clientId = selectedOptions.value;

    dispatch(fetchContacts({ clientId: clientId, page: "", limit: "" }));
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/contact`,
        params: {
          page: "",
          limit: "",
          client_id: clientId,
        },
      });

      const primaryContact = response.data?.contacts?.find(
        (contact) => contact.is_primary
      );

      setCompaniesPerson(primaryContact?.id);
      setCompanyPayloadPerson(primaryContact?.contact_person_name);
      setContactPerEmail(primaryContact?.contact_person_email);
      setContactPersonPhoneNo(primaryContact?.contact_person_phone);
      return response.data;
    } catch (error) {
      console.error(error.response);
    }
  };

  useEffect(() => {
    setCompanyEmail(companyEmailChnage);
    setCompanyNumber(companyPhoneChange);
  }, [companyEmailChnage, companyPhoneChange]);

  const contactPersonOptions = contacts?.contacts?.map((contact) => ({
    value: contact.id,
    label: contact.contact_person_name,
  }));

  const contactPersonDetails = contacts?.contacts?.filter(
    (contact) => companiesperson === contact.id
  );

  const contactPersonEmail = contactPersonDetails?.map(
    (contact) => contact?.contact_person_email
  )[0];

  const contactPersonPhone = contactPersonDetails?.map(
    (contact) => contact.contact_person_phone
  )[0];
  const handleContactSelect = (selectedOptions) => {
    if (selectedOptions) {
      setExisPersonError("");
    }

    setCompaniesPerson(selectedOptions.value);
    setCompanyPayloadPerson(selectedOptions.label);
  };

  useEffect(() => {
    setContactPerEmail(contactPersonEmail);
    setContactPersonPhoneNo(contactPersonPhone);
  }, [contactPersonEmail, contactPersonPhone]);

  const handleResponseDateChange = (date) => {
    setStartDate(date);
  };

  function formatDate(inputDate) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  }

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => {
              navigate("/projects", {
                state: {
                  page: state.page,
                  rowsPerPage: state.rowsPerPage,
                  status: state.status,
                  filterApplied: state.filterApplied,
                  text: state.text,
                  selectedKeywords: state.selectedKeywords,
                  selectedTechnologies: state.selectedTechnologies,
                  selectedTLandBDAs:state.selectedTLandBDAs,
                  keywordSwitchVal: state.keywordSwitchVal,
                  techStackSwitchVal: state.techStackSwitchVal,
                  startDate: state.startDate,
                  endDate: state.endDate,
                  date: state.date,
                  destatus: state.destatus,
                  filterByIdApplied: state.filterByIdApplied,
                  applyFilterId: state.applyFilterId,
                  edit: state.edit,
                },
              });
              setCompName("");
              setCompEmail("");
              setCompNumber("");
              setConPerson("");
              setConPersonEmail("");
              setConPersonNumber("");
              setCompanyName("");
              setCompanyPayload("");
              setCompanyEmail("");
              setCompanyNumber("");
              setCompaniesPerson("");
              setCompanyPayloadPerson("");
              setContactPerEmail("");
              setContactPersonPhoneNo("");
            }}
          ></Button>
          Edit Project
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Grid container spacing={3}>
          <Grid item lg={8} md={12} sm={12} xs={12}>
            <Box className="card-wrapper" sx={{ height: "100%" }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Project Details
                  </Typography>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Select Customer
                  </Typography>
                  <RadioGroup
                    aria-label="company"
                    name="company"
                    value={value}
                    onChange={handleChange}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="new_opportunity"
                      control={<Radio />}
                      label={
                        <span style={{ fontSize: "14px" }}>
                          New Opportunity
                        </span>
                      }
                    />
                    <FormControlLabel
                      value="existing_client"
                      control={<Radio />}
                      label={
                        <span style={{ fontSize: "14px" }}>
                          Existing Client
                        </span>
                      }
                    />
                  </RadioGroup>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Name
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>

                  {value === "new_opportunity" ? (
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Company Name"
                      value={compName}
                      inputRef={companyNameRef}
                      onChange={(event) => {
                        setCompName(event.target.value);
                        if (event.target.value) {
                          setCompanyNameError("");
                        }
                      }}
                    />
                  ) : (
                    <Select
                      name="colors"
                      options={companyOptions}
                      className="muilt-select-field"
                      classNamePrefix="select"
                      placeholder="Select Company"
                      ref={companyNameRef}
                      onChange={(selectedOption) =>
                        handleCompanySelect(selectedOption)
                      }
                      value={
                        companyOptions?.find(
                          (option) => option?.value === companies
                        ) || null
                      }
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "#8CC714",
                        },
                      })}
                    />
                  )}
                  {value === "existing_client"
                    ? exiscomperror && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {exiscomperror}
                        </Typography>
                      )
                    : companynameerror && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {companynameerror}
                        </Typography>
                      )}
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Email
                  </Typography>
                  {value === "new_opportunity" ? (
                    <Box
                      variant="div"
                      component="div"
                      className="cross-line-icon"
                    >
                      <TextField
                        className="input-field"
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        placeholder="Enter Company Email"
                        value={roleEmail ? compEmail : maskEmail(compEmail)}
                        onChange={(event) => {
                          const email = event.target.value;
                          setCompEmail(email);
                          if (validateEmail(email)) {
                          } else {
                          }
                        }}
                        InputProps={{
                          readOnly: !roleEmail,
                        }}
                      />
                      {!roleEmail && (
                        <RiCloseCircleLine
                          className="cross-line"
                          onClick={() => {
                            setCompEmail("");
                            setRoleEmail(true); // Set role to true to make the field editable
                          }}
                        />
                      )}
                    </Box>
                  ) : (
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Company Email"
                      value={
                        getUserRole ? companyEmail : maskEmail(companyEmail)
                      }
                      disabled
                    />
                  )}
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Phone No.
                  </Typography>
                  {value === "new_opportunity" ? (
                    <Box
                      variant="div"
                      component="div"
                      className="cross-line-icon"
                    >
                      <TextField
                        className="input-field"
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        placeholder="Enter Company Phone No."
                        value={
                          rolePhone ? compNumber : maskPhoneNumber(compNumber)
                        }
                        onChange={(event) => {
                          setCompNumber(event.target.value);
                        }}
                        InputProps={{
                          readOnly: !rolePhone,
                        }}
                      />
                      {!rolePhone && (
                        <RiCloseCircleLine
                          className="cross-line"
                          onClick={() => {
                            setCompNumber("");
                            setRolePhone(true); // Set role to true to make the field editable
                          }}
                        />
                      )}
                    </Box>
                  ) : (
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Company Phone No."
                      value={
                        getUserRole
                          ? companyNumber
                          : maskPhoneNumber(companyNumber)
                      }
                      disabled
                    />
                  )}
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  {value === "new_opportunity" ? (
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Contact Person Name"
                      value={conPerson}
                      inputRef={contactPersonRef}
                      onChange={(event) => {
                        setConPerson(event.target.value);

                        if (event.target.value) {
                          setConPerErrorMsg("");
                        }
                      }}
                    />
                  ) : (
                    <Select
                      name="colors"
                      options={contactPersonOptions}
                      className="muilt-select-field"
                      classNamePrefix="select"
                      placeholder="Select Contact Person"
                      ref={contactPersonRef}
                      onChange={(selectedOption) =>
                        handleContactSelect(selectedOption)
                      }
                      value={
                        contactPersonOptions?.find(
                          (option) => option.value === companiesperson
                        ) || null
                      }
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "#8CC714",
                        },
                      })}
                    ></Select>
                  )}
                  {exispersonerror && (
                    <Typography variant="body1" component="p" className="error">
                      {exispersonerror}
                    </Typography>
                  )}
                  {conPerErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {conPerErrorMsg}
                    </Typography>
                  )}
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person's Email
                  </Typography>
                  {value === "new_opportunity" ? (
                    <Box
                      variant="div"
                      component="div"
                      className="cross-line-icon"
                    >
                      <TextField
                        className="input-field"
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        placeholder="Enter Contact Person's Email"
                        value={
                          roleContEmail
                            ? conPersonEmail
                            : maskEmail(conPersonEmail)
                        }
                        onChange={(event) => {
                          const email = event.target.value;
                          setConPersonEmail(email);
                        }}
                        InputProps={{
                          readOnly: !roleContEmail,
                        }}
                      />
                      {!roleContEmail && (
                        <RiCloseCircleLine
                          className="cross-line"
                          onClick={() => {
                            setConPersonEmail("");
                            setRoleContEmail(true); // Set role to true to make the field editable
                          }}
                        />
                      )}
                    </Box>
                  ) : (
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Contact Person's Email"
                      value={
                        getUserRole
                          ? contactPerEmail
                          : maskEmail(contactPerEmail)
                      }
                      disabled
                    />
                  )}
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person's Phone No.
                  </Typography>
                  {value === "new_opportunity" ? (
                    <Box
                      variant="div"
                      component="div"
                      className="cross-line-icon"
                    >
                      <TextField
                        className="input-field"
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        placeholder="Enter Contact Person's Phone No."
                        value={conPersonNumber}
                        onChange={(event) => {
                          setConPersonNumber(event.target.value);
                        }}
                        InputProps={{
                          readOnly: !roleContPhone,
                        }}
                      />
                      {!roleContPhone && (
                        <RiCloseCircleLine
                          className="cross-line"
                          onClick={() => {
                            setConPersonNumber("");
                            setRoleContPhone(true); // Set role to true to make the field editable
                          }}
                        />
                      )}
                    </Box>
                  ) : (
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Contact Person's Phone No."
                      value={
                        getUserRole
                          ? contactPersonPhoneNo
                          : maskPhoneNumber(contactPersonPhoneNo)
                      }
                      disabled
                    />
                  )}
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Name
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Project Name"
                    value={projectName}
                    inputRef={projectnameRef}
                    onChange={(e) => {
                      setprojectName(e.target.value);
                      if (e.target.value) {
                        setprojectnameError("");
                      }
                    }}
                  />
                  {projectnameerror && (
                    <Typography variant="body1" component="p" className="error">
                      {projectnameerror}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Description
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextareaAutosize
                    className="textarea-field"
                    placeholder="Add Project Description"
                    value={projectdescription}
                    ref={proDescErrorMsgRef}
                    onChange={(e) => {
                      setProjectDescription(e.target.value);
                      if (e.target.value) {
                        setprojectdescriptionError("");
                      }
                    }}
                  />
                  {projectdescriptionerror && (
                    <Typography variant="body1" component="p" className="error">
                      {projectdescriptionerror}
                    </Typography>
                  )}
                </Grid>
                <Typography ref={keywordsErrorMsgRef} />
                <Typography ref={technologyErrorMsgRef} />
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1" component="label">
                      Keywords
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                  </Stack>
                  <CreatableSelect
                    isClearable
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    isMulti
                    name="colors"
                    options={keywordData}
                    value={projectKeywords}
                    // ref={keywordsErrorMsgRef}
                    className="muilt-select-field"
                    classNamePrefix="select"
                    placeholder="Select Keywords"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                    onCreateOption={handleCreate}
                    onChange={(selectedOptions, { removedValue }) => {
                      if (removedValue) {
                        const removedOptionId = removedValue.value;

                        setRemoveKeyId([...removeKeyId, removedOptionId]);
                      }
                      handleKeywordChange(selectedOptions);
                    }}
                  />
                  {keywordError && (
                    <Typography variant="body1" component="p" className="error">
                      {keywordError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1" component="label">
                      Technology Stacks
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                  </Stack>
                  <CreatableSelect
                    isClearable
                    isDisabled={techLoading}
                    isLoading={techLoading}
                    isMulti
                    name="colors"
                    options={technologyData}
                    value={projectTechnologies}
                    // ref={technologyErrorMsgRef}
                    className="muilt-select-field"
                    placeholder="Select Technologies"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                    onCreateOption={handleTechCreate}
                    onChange={(selectedOptions, { removedValue }) => {
                      if (removedValue) {
                        const removedOptionId = removedValue.value;
                        setRemoveTechId([...removeTechId, removedOptionId]);
                      }
                      handleTechnologiesChange(selectedOptions);
                    }}
                  />
                  {technologyError && (
                    <Typography variant="body1" component="p" className="error">
                      {technologyError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Key Features
                  </Typography>
                  <Box className="ck-editor-wrapper">
                    <ReactQuill
                      value={keyfeature}
                      onChange={handleKeyFeatureChange}
                      modules={modulesKeyFeature}
                    />
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Key Integration
                  </Typography>
                  <Box className="ck-editor-wrapper">
                    <ReactQuill
                      value={keyintegration}
                      onChange={handleKeyIntegrationChange}
                      modules={modulesKeyIntegration}
                    />
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Links
                  </Typography>
                  <Stack container spacing={2}>
                    {projectLinks.map((element, index) => {
                      return (
                        <Box className="add-multi-input">
                          <TextField
                            className="input-field"
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Enter Project Link"
                            value={element.linkText}
                            onChange={(e) => {
                              addTextToLink(e.target.value, index);
                            }}
                          />
                          <Box className="action-btn-wrap">
                            <Box className="action-btn-wrap">
                              {index === projectLinks.length - 1 && (
                                <Button
                                  variant="outlined"
                                  className="action-btn edit-btn"
                                  startIcon={<RiAddCircleLine />}
                                  disableRipple
                                  onClick={() => addProjectLink()}
                                ></Button>
                              )}
                              {index !== projectLinks.length - 1 && (
                                <Button
                                  variant="outlined"
                                  className="action-btn dlt-btn"
                                  startIcon={<RiDeleteBinLine />}
                                  disableRipple
                                  onClick={() => {
                                    handleDeleteLink(element.linkId);
                                  }}
                                ></Button>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                  </Stack>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Primary TL
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Select
                    name="colors"
                    options={PrimaryTeamLeaderoptions}
                    value={PrimaryTeamLeaderoptions?.find(
                      (option) => option.value === selectedPrimaryTL
                    )}
                    isClearable={true}
                    className="muilt-select-field"
                    classNamePrefix="select"
                    placeholder="Select Primary TL"
                    ref={primaryTlRef}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handlePrimaryTLChange(selectedOption.value);
                      } else {
                        setSelectedPrimaryTL("");
                      }
                    }}
                  />
                  {primarytlerror && (
                    <Typography variant="body1" component="p" className="error">
                      {primarytlerror}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Secondary TL
                  </Typography>
                  <Select
                    name="colors"
                    options={SecondaryTeamLeaderoptions}
                    value={SecondaryTeamLeaderoptions?.find(
                      (Option) => Option.value === selectedSecondaryTL
                    )}
                    className="muilt-select-field"
                    classNamePrefix="select"
                    placeholder="Select Secondary TL"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                    isClearable={true}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handleSecondaryTLChange(selectedOption.value);
                      } else {
                        setSelectedSecondaryyTL("");
                      }
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Primary BD
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Select
                    name="colors"
                    options={PrimaryBDAoptions}
                    value={PrimaryBDAoptions?.find(
                      (Option) => Option.value === selectedPrimaryBDA
                    )}
                    className="muilt-select-field"
                    classNamePrefix="select"
                    placeholder="Selct Primary BD"
                    ref={primaryBDRef}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                    isClearable={true}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handlePrimaryBDAChange(selectedOption.value);
                      } else {
                        setSelectedPrimaryBDA("");
                      }
                    }}
                  />
                  {primarybdaerror && (
                    <Typography variant="body1" component="p" className="error">
                      {primarybdaerror}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Secondary BD
                  </Typography>
                  <Select
                    name="colors"
                    options={SecondaryBDAoptions}
                    value={SecondaryBDAoptions.find(
                      (Option) => Option.value === selectedSecondaryBDA
                    )}
                    className="muilt-select-field"
                    classNamePrefix="select"
                    placeholder="Select Secondary BD"
                    isClearable={true}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handlesecondaryBDAChange(selectedOption.value);
                      } else {
                        setSelectedSecondaryBDA("");
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Stack spacing={3} className="h-100">
              <Box className="card-wrapper">
                <Typography variant="body1" component="label">
                  Project Status
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  name="colors"
                  options={projectStatusOptions}
                  value={projectStatusOptions.find(
                    (Option) => Option.value === projectStatus
                  )}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  placeholder="Select Project Status"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                  isClearable={true}
                  onChange={handleStatusChange}
                />
                {proStatusErrorMsg && (
                  <Typography variant="body1" component="p" className="error">
                    {proStatusErrorMsg}
                  </Typography>
                )}
              </Box>
              <Box className="card-wrapper">
                <Typography variant="body1" component="label">
                  Development Status
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  name="colors"
                  options={developmentStatusOptions}
                  value={developmentStatusOptions.find(
                    (Option) => Option.value === developmentStatus
                  )}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  placeholder="Select Development Status"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                  isClearable={true}
                  onChange={handleDevelopmentStatusChange}
                />
                {deveStatuserrorMsg && (
                  <Typography variant="body1" component="p" className="error">
                    {deveStatuserrorMsg}
                  </Typography>
                )}

                {showReason ? (
                  <>
                    <Typography
                      variant="body1"
                      component="label"
                      style={{ marginTop: "20px" }}
                    >
                      Reason
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <TextareaAutosize
                      className="textarea-field"
                      placeholder="Enter reason"
                      value={reason}
                      ref={reasonRef}
                      onChange={(e) => {
                        setReason(e.target.value);
                        if (e.target.value) {
                          setReasonError("");
                        }
                      }}
                    />
                    {reasonError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {reasonError}
                      </Typography>
                    )}
                  </>
                ) : null}
              </Box>
              <Box className="card-wrapper">
                <Typography variant="body1" component="label">
                  Development Start Date
                </Typography>
                {projects?.start_date ? (
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                    style={{ marginTop: "25px" }}
                  >
                    {projects?.start_date === null ||
                    projects?.start_date === ""
                      ? "-"
                      : formatDate(projects?.start_date)}
                  </Typography>
                ) : (
                  <DatePicker
                    oneTap
                    format="dd-MM-yyyy"
                    placeholder="Select Development Start Date"
                    onKeyDown={(event) => event.preventDefault()}
                    className="modal-date-picker"
                    onChange={handleResponseDateChange}
                    value={startDate !== null ? startDate : null}
                    disabled={!!projects?.start_date}
                  />
                )}
              </Box>
              <Box className="card-wrapper add-scroll-space">
                <Stack spacing={3} className="view-info">
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Documents
                  </Typography>
                  <Box className="document-card-content-wrapper over-flow-auto">
                    <Box>
                      <Typography variant="body1" component="label">
                        File Name
                      </Typography>
                      <Stack container spacing={2}>
                        <Box className="add-multi-input">
                          <TextField
                            className="input-field"
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Please Enter File Name"
                            value={filename}
                            onChange={(event) => {
                              setFileName(event.target.value);
                            }}
                          />
                        </Box>
                      </Stack>
                      <Typography
                        variant="body1"
                        component="label"
                        style={{ marginTop: "10px" }}
                      >
                        URL
                      </Typography>
                      <Stack container spacing={2}>
                        <Box className="add-multi-input">
                          <TextField
                            className="input-field"
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Paste Document URL Here"
                            value={documentUploadLink}
                            onChange={(event) => {
                              setDocumentUploadLink(event.target.value);
                              if (event.target.value) {
                                setdocumentLinkError("");
                              }
                            }}
                          />
                          <Button
                            variant="outlined"
                            className="action-btn upload-btn"
                            disableRipple
                            onClick={addDocument}
                          >
                            Upload
                          </Button>
                        </Box>
                      </Stack>
                      {documentlinkerror && (
                        <Typography
                          variant="body2"
                          className="error"
                          color="error"
                        >
                          {documentlinkerror}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Stack
                    className="document-list-wrapper over-flow-auto"
                    spacing={2}
                  >
                    {selectedDocuments?.map((document) => {
                      const text = document?.linkName
                        ? document?.linkName
                        : document.linkText;
                      const displayText =
                        text.length > maxLength
                          ? text.slice(0, maxLength) + "..."
                          : text;
                      return (
                        <>
                          <Box className="document-upload-wrapper">
                            <Box
                              key={document.linkId}
                              className="document-type"
                            >
                              <Typography variant="body1" component="span">
                                <a
                                  href={document.linkText}
                                  target="_blank"
                                  style={{ color: "var(--primary-text-color)" }}
                                  rel="noopener noreferrer"
                                  onClick={(e) =>
                                    handleLinkClick(e, document.linkText)
                                  }
                                >
                                  {displayText}
                                </a>
                              </Typography>
                            </Box>
                            <Box className="document-action action-btn-wrap">
                              <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                disableRipple
                                onClick={() =>
                                  handleRemoveDocument(
                                    document.linkId,
                                    "selectedDocuments"
                                  )
                                }
                              ></Button>
                            </Box>
                          </Box>
                        </>
                      );
                    })}

                    {displayedDocuments &&
                      displayedDocuments.map((video, index) => {
                        const text = displayedfilenames[index]
                          ? displayedfilenames[index]
                          : displayedDocuments[index];
                        const displayText =
                          text.length > maxLength
                            ? text.slice(0, maxLength) + "..."
                            : text;

                        return (
                          <Box key={index} className="document-upload-wrapper">
                            <Box className="document-type">
                              <a
                                href={displayedDocuments[index]}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) =>
                                  handleLinkClick(e, displayedDocuments[index])
                                }
                              >
                                <Typography variant="body1" component="span">
                                  {displayText}
                                </Typography>
                              </a>
                            </Box>
                            <Box className="document-action action-btn-wrap">
                              <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                disableRipple
                                onClick={() =>
                                  handleRemoveDocument(index, "addedDocuments")
                                }
                              ></Button>
                            </Box>
                          </Box>
                        );
                      })}
                  </Stack>
                </Stack>
              </Box>
              <Box
                className="card-wrapper add-scroll-space view-info"
                sx={{ flex: 1 }}
              >
                <Typography variant="body1" component="label">
                  Reference Links
                </Typography>
                <Stack container spacing={2} className="over-flow-auto">
                  {ReferenceLinks.map((element, index) => {
                    return (
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Enter Reference Link"
                          value={element.linkText}
                          onChange={(e) => {
                            addReferenceToLink(e.target.value, index);
                          }}
                        />
                        <Box className="action-btn-wrap">
                          {index === ReferenceLinks.length - 1 && (
                            <Button
                              variant="outlined"
                              className="action-btn edit-btn"
                              startIcon={<RiAddCircleLine />}
                              disableRipple
                              onClick={() => addReferenceLink()}
                            ></Button>
                          )}
                          {index !== ReferenceLinks.length - 1 && (
                            <Button
                              variant="outlined"
                              className="action-btn dlt-btn"
                              startIcon={<RiDeleteBinLine />}
                              disableRipple
                              onClick={() => {
                                handleReferenceDeleteLink(element.linkId);
                              }}
                            ></Button>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                </Stack>
              </Box>
            </Stack>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="card-wrapper add-bottom-scroll-space">
              <Stack spacing={3} className="view-info">
                <Typography variant="h3" component="h3" className="card-title">
                  Project Video Casts
                </Typography>
                <Box className="document-card-content-wrapper over-flow-auto">
                  <Box>
                    <Typography variant="body1" component="label">
                      File Name
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Please Enter File Name"
                          value={videoFileName}
                          onChange={(event) => {
                            setVideoFileName(event.target.value);
                          }}
                        />
                      </Box>
                    </Stack>
                    <Typography
                      variant="body1"
                      component="label"
                      style={{ marginTop: "10px" }}
                    >
                      Video Cast URL
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Paste Video Cast URL Here"
                          value={uploadLink}
                          onChange={(event) => {
                            setUploadLink(event.target.value);
                            if (event.target.value) {
                              setUploadLinkError("");
                            }
                          }}
                        />
                        <Button
                          variant="outlined"
                          className="action-btn upload-btn"
                          disableRipple
                          onClick={addVideo}
                        >
                          Upload
                        </Button>
                      </Box>
                    </Stack>
                    {uploadLinkError && (
                      <Typography
                        variant="body2"
                        className="error"
                        color="error"
                      >
                        {uploadLinkError}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Stack className="vd-ss-wrapper over-flow-auto" spacing={2}>
                  <DragDropContext onDragEnd={handleDrag}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {selectedVideos?.map((document, index) => (
                            <Draggable
                              key={document?.linkId}
                              draggableId={document?.linkId?.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Box className="vs-ss-upload-wrapper">
                                    <Box
                                      style={{ display: "flex", gap: "10px" }}
                                    >
                                      <RiDragMove2Fill className="drag-icon" />
                                      <Box
                                        key={document.linkId}
                                        className="document-type"
                                      >
                                        <Typography
                                          variant="body1"
                                          component="span"
                                        >
                                          <a
                                            href={document.linkText}
                                            target="_blank"
                                            style={{
                                              color:
                                                "var(--primary-text-color)",
                                            }}
                                            rel="noopener noreferrer"
                                            onClick={(e) =>
                                              handleLinkClick(
                                                e,
                                                document.linkText
                                              )
                                            }
                                          >
                                            {document.linkName}
                                          </a>
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box className="document-action action-btn-wrap">
                                      <Button
                                        variant="outlined"
                                        className="action-btn dlt-btn"
                                        startIcon={<RiDeleteBinLine />}
                                        disableRipple
                                        onClick={() =>
                                          handleRemoveVideo(
                                            document.linkId,
                                            "selectedVideos"
                                          )
                                        }
                                      ></Button>
                                    </Box>
                                  </Box>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                          {displayedVideos &&
                            displayedVideos.map((video, index) => {
                              const text = displayedVideofilenames[index]
                                ? displayedVideofilenames[index]
                                : displayedVideos[index];
                              const displayText =
                                text.length > maxLength
                                  ? text.slice(0, maxLength) + "..."
                                  : text;
                              return (
                                <Box
                                  key={index}
                                  className="vs-ss-upload-wrapper"
                                >
                                  <Box style={{ display: "flex", gap: "10px" }}>
                                    <Box className="not-visible-drag-icon" />
                                    <Box className="document-type">
                                      <a
                                        href={displayedVideos[index]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) =>
                                          handleLinkClick(
                                            e,
                                            displayedVideos[index]
                                          )
                                        }
                                      >
                                        <Typography
                                          variant="body1"
                                          component="span"
                                        >
                                          {displayText}
                                        </Typography>
                                      </a>
                                    </Box>
                                  </Box>
                                  <Box className="document-action action-btn-wrap">
                                    <Button
                                      variant="outlined"
                                      className="action-btn dlt-btn"
                                      startIcon={<RiDeleteBinLine />}
                                      disableRipple
                                      onClick={() =>
                                        handleRemoveVideo(index, "addedVideos")
                                      }
                                    ></Button>
                                  </Box>
                                </Box>
                              );
                            })}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="card-wrapper add-bottom-scroll-space">
              <Stack spacing={3} className="view-info">
                <Typography variant="h3" component="h3" className="card-title">
                  Project Screen Shots
                </Typography>
                <Box className="document-card-content-wrapper over-flow-auto">
                  <Box>
                    <Typography variant="body1" component="label">
                      File Name
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Please Enter File Name"
                          value={imageFileName}
                          onChange={(event) => {
                            setImageFileName(event.target.value);
                          }}
                        />
                      </Box>
                    </Stack>
                    <Typography
                      variant="body1"
                      component="label"
                      style={{ marginTop: "10px" }}
                    >
                      Screen Shot URL
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Paste Document URL Link Here"
                          value={imageUploadLink}
                          onChange={(event) => {
                            setImageUploadLink(event.target.value);
                            if (event.target.value) {
                              setImageLinkError("");
                            }
                          }}
                        />
                        <Button
                          variant="outlined"
                          className="action-btn upload-btn"
                          disableRipple
                          onClick={addImage}
                        >
                          Upload
                        </Button>
                      </Box>
                    </Stack>
                    {imagelinkerror && (
                      <Typography
                        variant="body2"
                        className="error"
                        color="error"
                      >
                        {imagelinkerror}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Stack className="vd-ss-wrapper over-flow-auto" spacing={2}>
                  <DragDropContext onDragEnd={handleScreenShotDrag}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {selectedImages?.map((document, index) => (
                            <Draggable
                              key={document?.linkId}
                              draggableId={document?.linkId?.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <Box
                                    className="vs-ss-upload-wrapper"
                                    style={{ gap: "20px" }}
                                  >
                                    <Box
                                      style={{ display: "flex", gap: "10px" }}
                                    >
                                      <RiDragMove2Fill className="drag-icon" />
                                      <Box
                                        key={document.linkId}
                                        className="document-type"
                                      >
                                        <Typography
                                          variant="body1"
                                          component="span"
                                        >
                                          <a
                                            href={document.linkText}
                                            target="_blank"
                                            style={{
                                              color:
                                                "var(--primary-text-color)",
                                            }}
                                            rel="noopener noreferrer"
                                            onClick={(e) =>
                                              handleLinkClick(
                                                e,
                                                document.linkText
                                              )
                                            }
                                          >
                                            {document.linkName}
                                          </a>
                                        </Typography>
                                      </Box>
                                    </Box>
                                    <Box className="document-action action-btn-wrap">
                                      <Button
                                        variant="outlined"
                                        className="action-btn dlt-btn"
                                        startIcon={<RiDeleteBinLine />}
                                        disableRipple
                                        onClick={() =>
                                          handleRemoveImage(
                                            document.linkId,
                                            "selectedImages"
                                          )
                                        }
                                      ></Button>
                                    </Box>
                                  </Box>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                          {displayedImages &&
                            displayedImages.map((video, index) => {
                              const text = displayedImagefilenames[index]
                                ? displayedImagefilenames[index]
                                : displayedImages[index];
                              const displayText =
                                text.length > maxLength
                                  ? text.slice(0, maxLength) + "..."
                                  : text;
                              return (
                                <Box
                                  key={index}
                                  className="vs-ss-upload-wrapper"
                                >
                                  <Box style={{ display: "flex", gap: "10px" }}>
                                    <Box className="not-visible-drag-icon" />
                                    <Box className="document-type">
                                      <a
                                        href={displayedImages[index]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={(e) =>
                                          handleLinkClick(
                                            e,
                                            displayedImages[index]
                                          )
                                        }
                                      >
                                        <Typography
                                          variant="body1"
                                          component="span"
                                        >
                                          {displayText}
                                        </Typography>
                                      </a>
                                    </Box>
                                  </Box>
                                  <Box className="document-action action-btn-wrap">
                                    <Button
                                      variant="outlined"
                                      className="action-btn dlt-btn"
                                      startIcon={<RiDeleteBinLine />}
                                      disableRipple
                                      onClick={() =>
                                        handleRemoveImage(index, "addedImages")
                                      }
                                    ></Button>
                                  </Box>
                                </Box>
                              );
                            })}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="footer-wrapper">
        <Button
          variant="contained"
          size="large"
          className="primary-btn btn w-120"
          disableRipple
          onClick={submitHandler}
          disabled={isButtonDisabled}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="large"
          className="secondary-btn btn w-120"
          disableRipple
          onClick={() => {
            navigate("/projects", {
              state: {
                page: state.page,
                rowsPerPage: state.rowsPerPage,
                status: state.status,
                filterApplied: state.filterApplied,
                text: state.text,
                selectedKeywords: state.selectedKeywords,
                selectedTechnologies: state.selectedTechnologies,
                selectedTLandBDAs:state.selectedTLandBDAs,
                keywordSwitchVal: state.keywordSwitchVal,
                techStackSwitchVal: state.techStackSwitchVal,
                startDate: state.startDate,
                endDate: state.endDate,
                date: state.date,
                destatus: state.destatus,
                filterByIdApplied: state.filterByIdApplied,
                applyFilterId: state.applyFilterId,
                edit: state.edit,
              },
            });
            setCompName("");
            setCompEmail("");
            setCompNumber("");
            setConPerson("");
            setConPersonEmail("");
            setConPersonNumber("");
            setCompanyName("");
            setCompanyPayload("");
            setCompanyEmail("");
            setCompanyNumber("");
            setCompaniesPerson("");
            setCompanyPayloadPerson("");
            setContactPerEmail("");
            setContactPersonPhoneNo("");
          }}
        >
          Cancel
        </Button>
      </Box>
      {showModal && (
        <AddKeywordModal
          invalidValue={invalidValue}
          onClose={() => setShowModal(false)} // Close the modal when needed
        />
      )}
      {addTechModal && (
        <AddTechnologyModal
          validTechName={validTechName}
          onClose={() => setAddTechModal(false)} // Close the modal when needed
        />
      )}

      {modalisopen && (
        <TextModal
          reason={reason}
          setReason={setReason}
          setModalIsOpen={setModalIsOpen}
          modalisopen={modalisopen}
          setDevelopmentStatus={setDevelopmentStatus}
          projects={projects}
        />
      )}
    </>
  );
};

export default ProjectEdit;

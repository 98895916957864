import React, { useState, useEffect } from "react";
import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Badge,
  Toolbar,
  Typography,
  Tooltip,
  Grid,
  ListItemIcon,
} from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import { RiMenu3Fill, RiArrowRightSLine } from "react-icons/ri";
import { FiUser, FiLogOut } from "react-icons/fi";
import { MdPublishedWithChanges } from "react-icons/md";
import SiteLogo from "../../assets/images/sitelogo.png";
import userLogo from "../../assets/images/user.png";
import { Link, useNavigate } from "react-router-dom";
import ChangepasswordModal from "../common/changepasswordmodal";
import SignoutModal from "../common/signoutmodal";
import getuserDataFromCookies from "../../utils/userData";
import { useCookies } from "react-cookie";
import { callApi } from "../common/commonfunctionforapi";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import AnnoncementModelSlider from "../common/announcementModelSlider";
import { IoApps } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { handleSidebar } from "../../store/slices/drawerHandlerSlice";
import AnnouncementDrawer from "../common/Announcements/announcementsDrawer";
import { setTotalAnnouncements } from "../../store/slices/announcementSlice";
const Header = ({ isAdmin }) => {
  const sidebarState = useSelector((state) => state.sidebar.sidebarStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);
  const [singOutModalOpen, setSingOutModalOpen] = useState(false);
  const closePasswordModal = () => setPasswordModalOpen(false);
  const [userData, setUserData] = useState(getuserDataFromCookies());
  const handleOpenSingOutModal = () => setSingOutModalOpen(true);
  const hanldecloseSingOutModal = () => setSingOutModalOpen(false);
  const [announcementList, setAnnouncementList] = useState([]);
  const [viewAnnouncementOpen, setViewAnnouncementOpen] = useState(false);
  const [unreadIds, setUnreadIds] = useState([]);
  const [openAnnouncementDrawer, setOpenAnnouncementDrawer] = useState(false);

  const userApplicationPermissions = useSelector(
    (state) => state.applicationPermissions.applicationPermissions
  );
  const totalAnnouncements = useSelector((state) =>
    state?.announcements?.totalAnnouncements
      ? state?.announcements?.totalAnnouncements
      : 0
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorElAnnouncement, setAnchorElAnnouncement] = useState(false);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);
  async function fetchAllAnnouncements() {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/module/2`,
      });
      setAnnouncementList(response?.data);
      dispatch(setTotalAnnouncements(response?.data?.unreadCount));
    } catch (error) {
      console.error("Failed to fetch announcements:", error);
    }
  }

  useEffect(() => {
    fetchAllAnnouncements();
  }, [anchorElAnnouncement]);

  useEffect(() => {
    setViewAnnouncementOpen(
      announcementList?.unreadAnnouncements?.allUnread?.length > 0
    );
    setUnreadIds(
      announcementList?.unreadAnnouncements?.allUnread?.map((ann) => ann.id)
    );
  }, [announcementList]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleAnnouncementClick = (event) => {
    setAnchorElAnnouncement((prev) => !prev);
    setOpenAnnouncementDrawer(true);
  };

  const renderMenuItems = () => {
    return userApplicationPermissions?.application_details?.map(
      (item, index) => {
        if (item.app_id === 2) return null;
        return (
          <Grid
            item
            xs={4}
            // p={1}
            sx={{
              "& a": {
                textDecoration: "none", // Ensure no underline for links
              },
            }}
            key={index}
          >
            <MenuItem
              // key={index}
              onClick={handleClose}
              component={Link}
              to={item.app_url}
              target="_blank"
              sx={{
                //  padding: '7px', // for 3 menuitem in 1 grid imp
                padding: "4px",
                "&:hover": {
                  borderRadius: "12px",
                  backgroundColor: "#f8f9fa",

                  "& .MuiTypography-root": {
                    color: "black !important",
                  },
                },
              }}
            >
              <Grid
                container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <img
                    src={item.img_link?item.img_link:SiteLogo}
                    alt="logo-main"
                    loading="lazy"
                    height="32px"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      color: "white !important",
                      fontSize: "14px",
                    }}
                  >
                    {item.app_name}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          </Grid>
        );
      }
    );
  };
  useEffect(() => {
    // This effect will run whenever cookies change
    const updatedCookieUserData = getuserDataFromCookies();
    setUserData(updatedCookieUserData);
  }, [cookies]);
  return (
    <>
      <AppBar
        position="fixed"
        open={sidebarState}
        className={`navbar ${sidebarState ? "" : "sidebar-closed"} `}
      >
        <Toolbar className="nav-icon">
          <Box variant="div" component="div" className="navbar-left">
            {isAdmin ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => dispatch(handleSidebar(true))}
                className="toggle-btn"
              >
                <RiMenu3Fill className="open-menu" />
                <RiArrowRightSLine className="close-menu" />
              </IconButton>
            ) : null}
          </Box>
          <Box variant="div" component="div" className="navbar-right">
            <Typography
              variant="h1"
              noWrap
              component="h1"
              className="page-title"
            >
              Portfolio & Sales
            </Typography>
            <Box variant="div" component="div" className="user-info">
              <IconButton
                aria-label="cart"
                onClick={handleAnnouncementClick}
                sx={{ padding: "0px !important" }}
              >
                <Badge
                  badgeContent={totalAnnouncements}
                  max={999}
                  className="announcement-icon"
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "#7cb900",
                      color: "white",
                      top: "3px",
                      right: "4px",
                    },
                  }}
                >
                  <CampaignIcon
                    fill="black"
                    style={{
                      fontSize:
                        announcementList?.unreadCount === 0 ? "45px" : "43px",
                      // style={{ fontSize:'45px' }}
                    }}
                  />
                </Badge>
              </IconButton>
              <Box
                className="release-button"
                onClick={() => navigate("/release-notes")}
              >
                <Tooltip title="Release Notes">
                  <NewReleasesIcon size={36} className="release-icon" />
                </Tooltip>
              </Box>
              <IoApps
                size={36}
                fillColor="black !important"
                id="basic-button"
                aria-controls={open2 ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? "true" : undefined}
                onClick={handleClick2}
                style={{cursor:"pointer"}}
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorEl2}
                open={open2}
                onClose={handleClose2}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    // Target the root Paper component within the Menu
                    backgroundColor: "var(--primary-text-color)", // Change the background color
                    borderRadius: "12px", // Change the border radius
                    border: "2px solid white",
                    marginTop: "15px", // Add some gap from the top
                    marginLeft: "-30px",
                     width: '280px !important',
                  },
                }}
              >
                <Grid container p={1}>
                  {renderMenuItems()}
                </Grid>
              </Menu>


              <Avatar
                className="user-profile"
                src={userData?.imgUrl}
                onClick={handleClick}
                style={{ height: '36px', width: '36px' , cursor:'pointer', border:'1px solid #8cc714' }}
              ></Avatar>

              <Menu
                className="account-dropdown-menu"
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.15))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: "right",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "right",
                  vertical: "bottom",
                }}
              >
                <Box className="user-details">
                  <Box
                    className="user-img"
                    sx={{
                      padding: `${userData?.imgUrl ? "0px" : "8px"}`,
                    }}
                  >
                    <img
                      src={userData?.imgUrl || userLogo}
                      alt="account img"
                      style={{ padding: !userData?.imgUrl ? "8px" : "" }}
                    />
                  </Box>
                  <Typography
                    variant="h5"
                    component="h5"
                    className="text-green user-name"
                    align="center"
                  >
                    {userData?.fname} {userData?.lname}
                  </Typography>
                  <Typography
                    variant="span"
                    component="span"
                    className="text-grey user-position"
                    align="center"
                  >
                    {userData?.role?.charAt(0).toUpperCase() +
                      userData?.role?.slice(1)}
                  </Typography>
                </Box>
                <hr className="divider-border" />
                <Box className="organization-details">
                  <Box className="org-img">
                    <img src={SiteLogo} alt="account img" />
                  </Box>
                  <Box className="org-text">
                    <Typography
                      variant="h5"
                      component="h5"
                      className="text-green user-name"
                      align="center"
                    >
                      {userData?.companyId === 1
                        ? "Crest Infosystems Pvt. Ltd."
                        : "Crest Skillserve Pvt. Ltd."}
                    </Typography>
                    <Typography
                      variant="span"
                      component="span"
                      className="text-grey user-position"
                      align="center"
                    >
                      Organization
                    </Typography>
                  </Box>
                </Box>
                <Box className="links">
                  <a
                    href={`${process.env.REACT_APP_CENTRAL_URL}profile`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#232323", textDecoration: "none" }}
                    onclick={handleClose}
                  >
                    <MenuItem className="menu-link">
                      
                      <ListItemIcon className="link-icon">
                        <FiUser />
                      </ListItemIcon>
                      <span class="link-text">My Account</span>
                    </MenuItem>
                  </a>
                  <a
                    href={`${process.env.REACT_APP_CENTRAL_URL}change-password`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#232323", textDecoration: "none" }}
                    onclick={handleClose}
                  >
                    <MenuItem className="menu-link">
                      <ListItemIcon className="link-icon">
                        <MdPublishedWithChanges />
                      </ListItemIcon>
                      <span class="link-text">Change Password</span>
                    </MenuItem>
                  </a>
                  <MenuItem
                    className="menu-link"
                    onClick={handleOpenSingOutModal}
                  >
                    <ListItemIcon className="link-icon">
                      <FiLogOut />
                    </ListItemIcon>
                    <span class="link-text">Sign Out</span>
                  </MenuItem>
                </Box>
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>

      <ChangepasswordModal
        open={passwordModalOpen}
        handleClose={closePasswordModal}
      />
      <SignoutModal
        open={singOutModalOpen}
        handleClose={hanldecloseSingOutModal}
      />
      <AnnouncementDrawer
        openDrawer={openAnnouncementDrawer}
        setOpenDrawer={setOpenAnnouncementDrawer}
        allAnnouncements={announcementList?.allAnnouncements}
        unreadAnnouncements={announcementList?.unreadAnnouncements}
        totalCount={announcementList?.count}
        fetchAllAnnouncements={fetchAllAnnouncements}
        totalAnnouncements={announcementList?.unreadCount}
        // setnotifications={setnotifications}
        // page={page}
        // setPage={setPage}
        // markAllFlag={markAllFlag}
        // setmarkAllFlag={setmarkAllFlag}
        // settotalAnnouncements={settotalNotifications}
      />

      <AnnoncementModelSlider
        announcementList={announcementList?.unreadAnnouncements?.allUnread}
        viewAnnouncementOpen={viewAnnouncementOpen}
        setViewAnnouncementOpen={setViewAnnouncementOpen}
        unreadIds={unreadIds}
      ></AnnoncementModelSlider>
    </>
  );
};

export default Header;

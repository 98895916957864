import { Box, Fade, IconButton, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { callApi } from './commonfunctionforapi';
import { setFeedback } from '../../store/slices/feedbackSlice';
import CloseIcon from '@mui/icons-material/Close';
const PreviewCVModal = ({ open, closeModal, cvId, htmlContent }) => {
  const dispatch = useDispatch();

  const [exporting, setExporting] = useState(false);
  const [url, setUrl] = useState();
  useEffect(() => {
    const getPDF = async () => {
      setExporting(true);
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');

      const bodyChildren = Array.from(doc.body.children);

      bodyChildren.forEach((child) => {
        if (child.tagName.toLowerCase() === 'p') {
          child.remove();
        }
      });

      const updatedHTML = doc.documentElement.outerHTML;
      try {
        const data = await callApi({
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}/cv_builders/gethtmldata`,
          data: {
            cv_id: cvId,
            htmltemplate: updatedHTML,
            is_download: 'true',
          },
          responseType: 'blob',
        });
        setExporting(false);
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(
          new Blob([data], { type: 'application/pdf' })
        );
        setUrl(url);

        setTimeout(() => window.URL.revokeObjectURL(url), 10000);
      } catch (error) {
        dispatch(
          setFeedback({
            status: 'fail',
            message: error.response.message,
            code: error.response.status_code,
          })
        );
        setExporting(false);
        return { success: false, error: error.response.message };
      }
    };
    getPDF();
  }, []);

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        // onClose={ExportToPdfModalClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className='modal-wrapper-cv'>
            <Box
              variant='div'
              component='div'
              className='title'
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography variant='h3' component='h3'>
                Preview
              </Typography>
              <IconButton onClick={closeModal} aria-label='close'>
                <CloseIcon />
              </IconButton>
            </Box>
            {!exporting ? (
              <Box variant='div' component='div' sx={{ height: '92%' }} mt={1}>
                <iframe
                  src={url}
                  style={{ width: '100%', height: '100%' }}
                  title='PDF Viewer'
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Loading...
              </Box>
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default PreviewCVModal;

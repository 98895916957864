import React, { useState, useEffect } from "react";

import { Box, Button, TextField, Typography } from "@mui/material";
import {
  RiAddCircleLine,
  RiSearchLine,
  RiCloseCircleLine,
} from "react-icons/ri";
import ClientList from "./clientList";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// import { fetchClients } from "../../store/slices/clientSlice";
import WithPermission from "../../components/common/withPermission";
import { updateClients } from "../../utils/permissions";

// SIDEBAR DRAWER TABBING PANEL

const Clients = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const dispatch = useDispatch();

  // const toatlClientsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchUser, setsearchUser] = useState("");

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  
  const clearSearchHandler = () => {
    setsearchUser("");
    localStorage.setItem("searchClientsQuery", JSON.stringify(""));
  };

  useEffect(() => {
    // Load search text from localStorage on component mount
    const savedSearchText = localStorage.getItem("searchClientsQuery");
    if (savedSearchText) {
      setsearchUser(JSON.parse(savedSearchText));
    }
    localStorage.setItem("searchBidsQuery", JSON.stringify(""));
    localStorage.setItem("searchLeadsQuery", JSON.stringify(""));
    localStorage.setItem("searchProjectsQuery", JSON.stringify(""));
    localStorage.setItem("searchUsersQuery", JSON.stringify(""));
  }, []);

  const storeClientsSearchInLocalStorage = (event) => {
    // Store search text in localStorage as a JSON string
    localStorage.setItem("searchClientsQuery", JSON.stringify(searchUser));
  };

  return (
    <>
      <Box className="content-header project-list-header clients-module-wrapper">
          <Typography variant="h2" component="h2">
            Clients
          </Typography>
        <Box variant="div" component="div" className="content-header-right">
          <Box variant="div" component="div" className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
              placeholder="Search here"
              value={searchUser}
              onChange={(e) => setsearchUser(e.target.value)}
              onBlur={storeClientsSearchInLocalStorage}
            />
            {searchUser && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={clearSearchHandler}
              />
            )}
          </Box>
          <WithPermission
            permission={updateClients}
            element={
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn add-btn"
                endIcon={<RiAddCircleLine />}
                disableRipple
                onClick={() => navigate("/clients/create")}
              >
                Create Client
              </Button>
            }
          />
        </Box>
      </Box>
      <Box className="content-layout">
        <ClientList
          onPageChange={handlePageChange}
          searchUser={searchUser}
          currentPage={currentPage}
        />
      </Box>
    </>
  );
};

export default Clients;

import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import ProjectAllocationIcon from "../../assets/images/icons/Project_Allocation.svg";
import BidIcon from "../../assets/images/icons/BidIcon.svg";
import ActivitySchedule from "../../assets/images/icons/ActivitySchedule.svg";
import LeadsActivity from "../../assets/images/icons/LeadsActivity.svg";
import LeadSchedule from "../../assets/images/icons/lead-allocation.svg";

const Reports = () => {
  const navigate = useNavigate();
  return (
    <>
      {/*  START :: SETTINGS LISTING VIEW */}
      <Box className="content-header project-list-header">
        <Typography variant="h2" component="h2">
          Reports
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Box className="card-wrapper">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={4} className="report-grid-list">
              <Box
                className="report-card"
                onClick={() => navigate("/project-allocation-report")}
              >
                <Box className="report-card-content">
                  <Typography
                    component="h5"
                    variant="h5"
                    className="setting-name"
                  >
                    Project Allocation
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    See the projects assigned to Team Leaders and Business
                    Developers, along with project details
                  </Typography>
                </Box>
                <Box className="setting-icon">
                    <img src={ProjectAllocationIcon} alt="app-logo" loading="lazy"/>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} className="report-grid-list">
              <Box
                className="report-card"
                onClick={() => navigate("/bid-report-details")}
              >
                <Box className="report-card-content">
                  <Typography
                    component="h5"
                    variant="h5"
                    className="setting-name"
                  >
                    Bids Overview
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    See an overview of the bidding activity and performance of
                    each Business Developer for a specified period of time
                  </Typography>
                </Box>
                <Box className="setting-icon">
                    <img src={BidIcon} alt="app-logo" loading="lazy"/>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} className="report-grid-list">
              <Box
                className="report-card"
                onClick={() => navigate("/acitivity-schedule-report")}
              >
                <Box className="report-card-content">
                  <Typography
                    component="h5"
                    variant="h5"
                    className="setting-name"
                  >
                    Activity Schedule
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    Checkout the summary of scheduled Calls and Follow-ups for a
                    specific Project and Lead within a selected week
                  </Typography>
                </Box>
                <Box className="setting-icon">
                    <img src={ActivitySchedule} alt="app-logo" loading="lazy"/>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} className="report-grid-list">
              <Box
                className="report-card"
                onClick={() => navigate("/lead-report-details")}
              >
                <Box className="report-card-content">
                  <Typography
                    component="h5"
                    variant="h5"
                    className="setting-name"
                  >
                    Leads Closure Overview
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    Explore lead closure ratios by analyzing the relationship
                    between generated leads and successful project won
                  </Typography>
                </Box>
                <Box className="setting-icon">
                    <img src={LeadsActivity} alt="app-logo" loading="lazy"/>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} className="report-grid-list">
              <Box
                className="report-card"
                onClick={() => navigate("/lead-allocation-report")}
              >
                <Box className="report-card-content">
                  <Typography
                    component="h5"
                    variant="h5"
                    className="setting-name"
                  >
                    Lead Allocation
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{
                      fontSize: "15px",
                      fontWeight: "500",
                    }}
                  >
                    See the leads assigned to Team Leaders and Business
                    Developers, along with lead details
                  </Typography>
                </Box>
                <Box className="setting-icon">
                    <img src={LeadSchedule} alt="app-logo" loading="lazy"/>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* END :: SETTINGS LISTING VIEW */}
    </>
  );
};

export default Reports;

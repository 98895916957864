import React from "react";
// import { Box, Button, Typography } from "@mui/material";
// import { useNavigate } from "react-router-dom";

const Footer = () => {
  // const navigate = useNavigate();

  return (
    <>
      {/*  START :: CREATE PROJECT FOOTER */}
      {/* <Box
        className={
          window.location.pathname === "/projects/create"
            ? "footer-wrapper"
            : "footer-wrapper d-none"
        }
      >
        <Button
          variant="contained"
          size="large"
          className="primary-btn btn w-120"
          disableRipple
          onClick={() => navigate("/projects")}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="large"
          className="secondary-btn btn w-120"
          disableRipple
          onClick={() => navigate("/projects")}
        >
          Cancel
        </Button>
      </Box> */}
      {/*  END :: CREATE PROJECT FOOTER */}

      {/*  START :: EDIT PROJECT FOOTER */}
      {/* <Box
        className={
          window.location.pathname === "/projects/edit"
            ? "footer-wrapper"
            : "footer-wrapper d-none"
        }
      >
        <Button
          variant="contained"
          size="large"
          className="primary-btn btn w-120"
          disableRipple
          onClick={() => navigate("/projects")}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="large"
          className="secondary-btn btn w-120"
          disableRipple
          onClick={() => navigate("/projects")}
        >
          Cancel
        </Button>
      </Box> */}
      {/*  END :: EDIT PROJECT FOOTER */}

      {/*  START :: CREATE LEAD FOOTER */}
      {/* <Box
        className={
          window.location.pathname === "/leads/create"
            ? "footer-wrapper"
            : "footer-wrapper d-none"
        }
      >
        <Button
          variant="contained"
          size="large"
          className="primary-btn btn w-120"
          disableRipple
          onClick={() => navigate("/leads")}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="large"
          className="secondary-btn btn w-120"
          disableRipple
          onClick={() => navigate("/leads")}
        >
          Cancel
        </Button>
      </Box> */}
      {/*  END :: CREATE LEAD FOOTER */}

      {/*  START :: EDIT LEAD FOOTER */}
      {/* <Box
        className={
          window.location.pathname === "/leads/edit"
            ? "footer-wrapper"
            : "footer-wrapper d-none"
        }
      >
        <Button
          variant="contained"
          size="large"
          className="primary-btn btn w-120"
          disableRipple
          onClick={() => navigate("/leads")}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="large"
          className="secondary-btn btn w-120"
          disableRipple
          onClick={() => navigate("/leads")}
        >
          Cancel
        </Button>
      </Box> */}
      {/*  END :: EDIT LEAD FOOTER */}
    </>
  );
};

export default Footer;

import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  activity: null,
  loading: false,
  error: null,
};

const createActivitySlice = createSlice({
  name: "createactivity",
  initialState,
  reducers: {
    createActivityStart(state) {
      state.loading = true;
      state.error = null;
    },
    createActivitySuccess(state, action) {
      state.loading = false;
      state.project = action.payload;
    },
    createActivityFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  createActivityStart,
  createActivitySuccess,
  createActivityFailure,
} = createActivitySlice.actions;
export default createActivitySlice.reducer;

export const createActivity = (activityData) => async (dispatch) => {
  try {
    dispatch(createActivityStart());
    const response = await callApi({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/activity`,
      data: activityData,
    });

    const activity = response;
    const data = response.data;
    dispatch(createActivitySuccess(activity));
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(createActivityFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.response.data.message };
  }
};

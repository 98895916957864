import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";

const TechnologyDeleteModal = ({
  handleDeleteOpen,
  handleDeleteClose,
  handleDeleteKeyword,
  deleteKeyword,
  setdeleteKeyword,
}) => {
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={deleteKeyword}
        // onClose={LeaddeleteModalClose}
        closeAfterTransition
      >
        <Fade in={deleteKeyword}>
          <Box className="modal-wrapper">
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3">
                Do you want to delete this Technology?
              </Typography>
              <Typography variant="body1" component="p">
                This action can’t be undone.
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body"></Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={handleDeleteKeyword}
                >
                  Delete
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => setdeleteKeyword(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default TechnologyDeleteModal;

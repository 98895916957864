import React, { useState } from "react";
import Select from "react-select";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Drawer,
  Fade,
  Modal,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import {
  RiAddCircleLine,
  RiFilter2Line,
  RiSearchLine,
  RiCloseLine,
  RiArrowDownSLine,
  RiShareLine,
  RiPencilLine,
  RiDeleteBinLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import ProjectList from "./projectList";

// SIDEBAR DRAWER TABBING PANEL
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// KEYWORDS OPTIONS
const keywordsOptions = [
  { value: "Keyword1", label: "Keyword 1" },
  { value: "Keyword2", label: "Keyword 2" },
  { value: "Keyword3", label: "Keyword 3" },
  { value: "Keyword4", label: "Keyword 4" },
  { value: "Keyword5", label: "Keyword 5" },
];

// TECHNOLOGY STACKS OPTIONS
const technologystackOptions = [
  { value: "Laravel", label: "Laravel" },
  { value: "ReactJs", label: "ReactJs" },
  { value: "VueJs", label: "VueJs" },
  { value: "Wordpress", label: "Wordpress" },
  { value: "Node", label: "Node" },
  { value: "Python", label: "Python" },
];

const ProjectFilterApplied = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // SIDEBAR DRAWER TABBING PANEL
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // START :: EXPORT TO PDF MODAL
  const [SaveFilter, setSaveFilterModal] = useState(false);
  // END :: EXPORT TO PDF MODAL

  // START :: EXPORT TO PDF MODAL
  const [DeleteSavedFilter, setDeleteSavedFilterModal] = useState(false);
  // END :: EXPORT TO PDF MODAL

  return (
    <>
      {/*  START :: PROJECT LISTING VIEW */}
      <Box className="content-header project-list-header">
        <Typography variant="h2" component="h2">
          Projects
        </Typography>
        <Box variant="div" component="div" className="content-header-right">
          <Box variant="div" component="div" className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
              placeholder="Search here"
            />
          </Box>
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => navigate("/projects/create")}
          >
            Create Projects
          </Button>
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiFilter2Line />}
            disableRipple
            onClick={() => setOpen(!open)}
          >
            <Typography
              variant="body1"
              component="span"
              className="filter-counte"
            >
              3
            </Typography>
            Filter
          </Button>
        </Box>
      </Box>

      {/*  START :: PROJECT FILTER APPLIED */}
      <Box className="filter-apply-wrapper">
        <Typography variant="body1" component="span">
          6 Results Found
        </Typography>
        <Button
          variant="contained"
          size="large"
          className="outline-btn btn add-btn"
          endIcon={<RiCloseLine />}
          disableRipple
          onClick={() => navigate("/projects")}
        >
          Remove filter
        </Button>
      </Box>
      {/*  END :: PROJECT FILTER APPLIED */}

      <Box className="content-layout">
        <ProjectList />
      </Box>
      {/* END :: PROJECT LISTING VIEW */}

      {/*  START :: PROJECT FILTER PANEL */}
      <Drawer
        className="side-drawer"
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box className="sidebar-tab-wrapper">
          <Box className="sidebar-tab-wrapper">
            <Box className="close-btn" onClick={() => setOpen(false)}>
              <RiCloseLine />
            </Box>
            <Box className="sidebar-tab">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="tab-label-btn"
                  label="Filters"
                  {...a11yProps(0)}
                />
                <Tab
                  className="tab-label-btn"
                  label="Saved Filters"
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
          </Box>
          <CustomTabPanel
            value={value}
            index={0}
            className="filter-content-wrapper"
          >
            <Stack spacing={2} sx={{ padding: "24px" }}>
              <Box>
                <Typography variant="body1" component="label">
                  Search Text
                </Typography>
                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Search Here"
                />
              </Box>
              <Box>
                <Typography variant="body1" component="label">
                  Keywords
                </Typography>
                <Select
                  isMulti
                  name="colors"
                  options={keywordsOptions}
                  className="muilt-select-field"
                  classNamePrefix="select"
                />
              </Box>
              <Box>
                <Typography variant="body1" component="label">
                  Technology Stack
                </Typography>
                <Select
                  isMulti
                  name="colors"
                  options={technologystackOptions}
                  className="muilt-select-field"
                  classNamePrefix="select"
                />
              </Box>
              <Box className="filter-button-wrapper">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn green-btn"
                  disableRipple
                  // onClick={() => navigate("/projects")}
                >
                  Apply Filter
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={() => setSaveFilterModal(true)}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Stack>
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={1}
            className="filter-content-wrapper"
          >
            <Box className="filer-accordion-wrapper">
              <Accordion>
                <AccordionSummary
                  className="accordion-title"
                  expandIcon={<RiArrowDownSLine />}
                  aria-controls="filter-content-1"
                  id="filter-header-1"
                >
                  <Typography className="title-content">Filter-1</Typography>
                  <Stack
                    className="action-btn-wrap"
                    direction="row"
                    spacing={2}
                  >
                    <Button
                      variant="outlined"
                      className="action-btn view-btn"
                      startIcon={<RiFilter2Line />}
                      disableRipple
                      onClick={() => navigate("/projects/edit")}
                    ></Button>
                    <Button
                      variant="outlined"
                      className="action-btn convert-btn"
                      startIcon={<RiShareLine />}
                      disableRipple
                    ></Button>
                    <Button
                      variant="outlined"
                      className="action-btn edit-btn"
                      startIcon={<RiPencilLine />}
                      disableRipple
                    ></Button>
                    <Button
                      variant="outlined"
                      className="action-btn dlt-btn"
                      startIcon={<RiDeleteBinLine />}
                      onClick={() => setDeleteSavedFilterModal(true)}
                      disableRipple
                      // onClick={() => setProjectDelete(true)}
                    ></Button>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails className="filter-accordion-details">
                  <Stack spacing={2} className="saved-filter-wrapper">
                    <Box className="saved-filter-content">
                      <Typography
                        variant="body2"
                        component="span"
                        className="title"
                      >
                        Search Text :
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        className="desc"
                      >
                        Ecommerce
                      </Typography>
                    </Box>
                    <Box className="saved-filter-content">
                      <Typography
                        variant="body2"
                        component="span"
                        className="title"
                      >
                        Keywords :
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        className="desc"
                      >
                        Paypal, Facilitation, Collaboration
                      </Typography>
                    </Box>
                    <Box className="saved-filter-content">
                      <Typography
                        variant="body2"
                        component="span"
                        className="title"
                      >
                        Technology stack :
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        className="desc"
                      >
                        React, Laravel
                      </Typography>
                    </Box>
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className="accordion-title"
                  expandIcon={<RiArrowDownSLine />}
                  aria-controls="filter-content-2"
                  id="filter-header-2"
                >
                  <Typography className="title-content">Filter-2</Typography>
                  <Stack
                    className="action-btn-wrap"
                    direction="row"
                    spacing={2}
                  >
                    <Button
                      variant="outlined"
                      className="action-btn view-btn"
                      startIcon={<RiFilter2Line />}
                      disableRipple
                      onClick={() => navigate("/projects/edit")}
                    ></Button>
                    <Button
                      variant="outlined"
                      className="action-btn convert-btn"
                      startIcon={<RiShareLine />}
                      disableRipple
                    ></Button>
                    <Button
                      variant="outlined"
                      className="action-btn edit-btn"
                      startIcon={<RiPencilLine />}
                      disableRipple
                    ></Button>
                    <Button
                      variant="outlined"
                      className="action-btn dlt-btn"
                      startIcon={<RiDeleteBinLine />}
                      onClick={() => setDeleteSavedFilterModal(true)}
                      disableRipple
                      // onClick={() => setProjectDelete(true)}
                    ></Button>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails className="filter-accordion-details">
                  <Stack spacing={2} className="saved-filter-wrapper">
                    <Box className="saved-filter-content">
                      <Typography
                        variant="body2"
                        component="span"
                        className="title"
                      >
                        Search Text :
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        className="desc"
                      >
                        Ecommerce
                      </Typography>
                    </Box>
                    <Box className="saved-filter-content">
                      <Typography
                        variant="body2"
                        component="span"
                        className="title"
                      >
                        Keywords :
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        className="desc"
                      >
                        Paypal, Facilitation, Collaboration
                      </Typography>
                    </Box>
                    <Box className="saved-filter-content">
                      <Typography
                        variant="body2"
                        component="span"
                        className="title"
                      >
                        Technology stack :
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        className="desc"
                      >
                        React, Laravel
                      </Typography>
                    </Box>
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  className="accordion-title"
                  expandIcon={<RiArrowDownSLine />}
                  aria-controls="filter-content-3"
                  id="filter-header-3"
                >
                  <Typography className="title-content">Filter-3</Typography>
                  <Stack
                    className="action-btn-wrap"
                    direction="row"
                    spacing={2}
                  >
                    <Button
                      variant="outlined"
                      className="action-btn view-btn"
                      startIcon={<RiFilter2Line />}
                      disableRipple
                      onClick={() => navigate("/projects/edit")}
                    ></Button>
                    <Button
                      variant="outlined"
                      className="action-btn convert-btn"
                      startIcon={<RiShareLine />}
                      disableRipple
                    ></Button>
                    <Button
                      variant="outlined"
                      className="action-btn edit-btn"
                      startIcon={<RiPencilLine />}
                      disableRipple
                    ></Button>
                    <Button
                      variant="outlined"
                      className="action-btn dlt-btn"
                      startIcon={<RiDeleteBinLine />}
                      onClick={() => setDeleteSavedFilterModal(true)}
                      disableRipple
                      // onClick={() => setProjectDelete(true)}
                    ></Button>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails className="filter-accordion-details">
                  <Stack spacing={2} className="saved-filter-wrapper">
                    <Box className="saved-filter-content">
                      <Typography
                        variant="body2"
                        component="span"
                        className="title"
                      >
                        Search Text :
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        className="desc"
                      >
                        Ecommerce
                      </Typography>
                    </Box>
                    <Box className="saved-filter-content">
                      <Typography
                        variant="body2"
                        component="span"
                        className="title"
                      >
                        Keywords :
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        className="desc"
                      >
                        Paypal, Facilitation, Collaboration
                      </Typography>
                    </Box>
                    <Box className="saved-filter-content">
                      <Typography
                        variant="body2"
                        component="span"
                        className="title"
                      >
                        Technology stack :
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        className="desc"
                      >
                        React, Laravel
                      </Typography>
                    </Box>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            </Box>
          </CustomTabPanel>
        </Box>
      </Drawer>
      {/* END :: PROJECT FILTER PANEL */}

      {/*  START :: SAVE FILTER MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={SaveFilter}
        // onClose={ExportToPdfModalClose}
        closeAfterTransition
      >
        <Fade in={SaveFilter}>
          <Box className="modal-wrapper">
            <Box
              variant="div"
              component="div"
              className="title"
              sx={{ marginBottom: "24px" }}
            >
              <Typography variant="h3" component="h3">
                Save Filter
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body">
              <Typography variant="body1" component="label">
                Name
              </Typography>
              <TextField
                className="input-field"
                fullWidth
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                placeholder="Enter Save Filter Name"
              />
            </Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => setSaveFilterModal(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {/* END :: SAVE FILTER MODAL */}

      {/*  START :: DELETE SAVED FILTER MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={DeleteSavedFilter}
        closeAfterTransition
      >
        <Fade in={DeleteSavedFilter}>
          <Box className="modal-wrapper">
            <Typography variant="h3" component="h3">
              Do you want to delete this saved filter?
            </Typography>
            <Typography variant="body1" component="p">
              This action can’t be undone.
            </Typography>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                >
                  Delete Filter
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => setDeleteSavedFilterModal(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {/* END :: DELETE SAVED FILTER MODAL */}
    </>
  );
};

export default ProjectFilterApplied;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";

import { Box, TextField, Typography } from "@mui/material";
import { RiSearchLine, RiCloseCircleLine } from "react-icons/ri";

import UserList from "./userList";


const Users = () => {


  // SIDEBAR DRAWER TABBING PANEL

  const [currentPage, setCurrentPage] = useState(1);
  const [searchUser, setSearchUser] = useState("");
  const [archievedCurrentPage, setArchievedCurrentPage] = useState(1);
  const [value, setValue] = useState(0);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleChildValueChange = (newValue) => {
    setValue(newValue); // Update the value variable in the parent component
  };

  const handleArchievedPageChange = (newPage) => {
    setArchievedCurrentPage(newPage);
  };

  useEffect(() => {
    if (currentPage > 1 || archievedCurrentPage > 1) {
      setCurrentPage(1);
      setArchievedCurrentPage(1);
    }

  }, [searchUser]);

  const clearSearchHandler = () => {
    setSearchUser("");
    localStorage.setItem("searchUsersQuery", JSON.stringify(""));
  };

  useEffect(() => {

    const savedSearchText = localStorage.getItem("searchUsersQuery");
    if (savedSearchText) {
      setSearchUser(JSON.parse(savedSearchText));
    }
    localStorage.setItem("searchBidsQuery", JSON.stringify(""));
    localStorage.setItem("searchClientsQuery", JSON.stringify(""));
    localStorage.setItem("searchLeadsQuery", JSON.stringify(""));
    localStorage.setItem("searchProjectsQuery", JSON.stringify(""));
  }, []);

  const storeUsersSearchInLocalStorage = (event) => {
    
    localStorage.setItem("searchUsersQuery", JSON.stringify(searchUser));
  };

  return (
    <>
      {/*  START :: USERS LISTING VIEW */}
      <Box className="content-header user-module-wrapper">
        <Typography variant="h2" component="h2">
          Users
        </Typography>
        <Box variant="div" component="div" className="content-header-right">
          <Box variant="div" component="div" className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
              placeholder="Search here"
              onChange={(e) => {
                if (currentPage > 1 || archievedCurrentPage > 1) {
                  setCurrentPage(1);
                  setArchievedCurrentPage(1);
                }
                setSearchUser(e.target.value);
              }}
              value={searchUser}
              onBlur={storeUsersSearchInLocalStorage}
            />
            {searchUser && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={clearSearchHandler}
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box className="content-layout">
        <UserList
          onPageChange={handlePageChange}
          searchUser={searchUser}
          currentPage={currentPage}
          onValueChange={handleChildValueChange}
          onArchievePageChange={handleArchievedPageChange}
        />
      </Box>
      {/* END :: USERS LISTING VIEW */}
    </>
  );
};

export default Users;

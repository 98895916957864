import React, { useEffect, useRef } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import Select from "react-select";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

import { useState } from "react";
import { useDispatch } from "react-redux";
import { createClient } from "../../store/slices/createClientSlice";
import { successToast } from "../../responses/successToast";

const validateEmail = (email) => {
  const emailPattern =
    /^[a-zA-Z0-9](?!.*\.\.)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,63})+$/;
  return emailPattern.test(email);
};

const ClientCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [compName, setCompName] = useState("");
  const [compEmail, setCompEmail] = useState("");
  const [compNo, setCompNo] = useState("");
  const [conPerson, setConPerson] = useState("");
  const [conPerEmail, setConPerEmail] = useState("");
  const [conPerNo, setConPerNo] = useState("");
  const [website, setWebsite] = useState("");
  const [skypeid, setSkypeid] = useState("");
  const [compNameError, setCompNameError] = useState("");
  const [conPerError, setConPerError] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [compEmailError, setCompEmailError] = useState("");
  const [conPerEmailError, setConPerEmailError] = useState("");
  const [saveButtonClickCount, setSaveButtonClickCount] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const contactPersonRef = useRef(null);
  const companyNameRef = useRef(null);
  const compEmailRef = useRef(null);
  const contEmailRef = useRef(null);

  useEffect(() => {
    if (compNameError && companyNameRef.current) {
      companyNameRef.current.scrollIntoView({ behavior: "smooth" });
      companyNameRef.current.focus();
    }
  }, [companyNameRef, compNameError, saveButtonClickCount]);

  useEffect(() => {
    if (conPerError && contactPersonRef.current) {
      contactPersonRef.current.focus();
    }
  }, [contactPersonRef, conPerError, saveButtonClickCount]);

  useEffect(() => {
    if (saveButtonClickCount === 1) {
      if (compEmailError && compEmailRef.current) {
        compEmailRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [compEmailRef, compEmailError, saveButtonClickCount]);

  useEffect(() => {
    if (conPerEmailError && contEmailRef.current) {
      contEmailRef.current.focus();
    }
  }, [conPerEmailError, contEmailRef, saveButtonClickCount]);

  // console.log(selectedCountry?.name?.common, "country");
  const submitHandler = async () => {
    setSaveButtonClickCount((prevCount) => prevCount + 1);
    if (isButtonDisabled) {
      return;
    }
    let flag = 0;
    if (!compName.trim()) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setCompNameError("Please enter company name");
      flag = 1;
    }

    if (!conPerson.trim()) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setConPerError("Please enter contact person name");

      flag = 1;
    }
    if (compEmailError === "Please enter a valid email address") {
      // errorToast("Please enter a valid email address");
      flag = 1;
    }
    if (conPerEmailError === "Please enter a valid email address") {
      // errorToast("Please enter a valid email address");
      flag = 1;
    }
    if (flag !== 0) {
      return;
    }
    setIsButtonDisabled(true);
    const clientData = {
      company_name: compName,
      company_email: compEmail,
      company_phone: compNo,
      website: website,
      skype_id: skypeid,
      contact_person_name: conPerson,
      contact_person_email: conPerEmail,
      contact_person_phone: conPerNo,
      country: selectedCountry?.name?.common,
      address: address,
      city: city,
      state: state,
      zip_code: zipcode,
    };
    try {
      const response = await dispatch(createClient(clientData));

      if (response.success) {
        // console.log(response, "api response");
        navigate("/clients");
        successToast("Client created successfully!");
      } else {
        console.error("Error creating client:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      setIsButtonDisabled(false);
    }
  };

  useEffect(() => {
    // Define your API endpoint to fetch the list of countries
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        // Update the state with the fetched data and sort it alphabetically
        const sortedCountries = data.sort((a, b) =>
          a.name.common.localeCompare(b.name.common)
        );
        setCountries(sortedCountries);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/clients")}
          ></Button>
          Create Client
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              variant="h3"
              component="h3"
              className="card-title"
              ref={companyNameRef}
            />
            <Typography
              variant="h3"
              component="h3"
              className="card-title"
              ref={compEmailRef}
            />
            <Box className="card-wrapper">
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Client Details
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Name
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Company Name"
                    value={compName}
                    onChange={(event) => {
                      setCompName(event.target.value);
                      if (event.target.value) {
                        setCompNameError("");
                      }
                    }}
                  />
                  {compNameError && (
                    <Typography variant="body1" component="p" className="error">
                      {compNameError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Email
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Company Email"
                    value={compEmail}
                    onChange={(event) => {
                      const email = event.target.value;
                      setCompEmail(email);
                      if (validateEmail(email) || !email) {
                        setCompEmailError(""); // Clear the error message if the email is valid.
                      } else {
                        setCompEmailError("Please enter a valid email address");
                      }
                    }}
                  />
                  {compEmailError && (
                    <Typography variant="body2" className="error" color="error">
                      {compEmailError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Phone No.
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Company Phone No."
                    // type="number"
                    value={compNo}
                    onChange={(event) => {
                      setCompNo(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Contact Person"
                    value={conPerson}
                    inputRef={contactPersonRef}
                    onChange={(event) => {
                      setConPerson(event.target.value);
                      if (event.target.value) {
                        setConPerError("");
                      }
                    }}
                  />
                  {conPerError && (
                    <Typography variant="body1" component="p" className="error">
                      {conPerError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person's Email
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Contact Person's Email"
                    inputRef={contEmailRef}
                    value={conPerEmail}
                    // onChange={(event) => {
                    //   setConPerEmail(event.target.value);
                    // }}
                    onChange={(event) => {
                      const email = event.target.value;
                      setConPerEmail(email);
                      if (validateEmail(email) || !email) {
                        setConPerEmailError(""); // Clear the error message if the email is valid.
                      } else {
                        setConPerEmailError(
                          "Please enter a valid email address"
                        );
                      }
                    }}
                  />
                  {conPerEmailError && (
                    <Typography variant="body2" className="error" color="error">
                      {conPerEmailError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person's Phone No.
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Contact Person's Phone No."
                    // type="number"
                    value={conPerNo}
                    onChange={(event) => {
                      setConPerNo(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Website
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Website"
                    value={website}
                    onChange={(event) => {
                      setWebsite(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Skype Id
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Skype Id"
                    value={skypeid}
                    onChange={(event) => {
                      setSkypeid(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box className="card-wrapper">
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Address
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Address Line 1
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter address"
                    value={address}
                    onChange={(event) => {
                      setAddress(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    City
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter City"
                    value={city}
                    onChange={(event) => {
                      setCity(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    State
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter State"
                    value={state}
                    onChange={(event) => {
                      setState(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Country
                  </Typography>
                  <Select
                    name="colors"
                    options={countries}
                    value={selectedCountry}
                    onChange={(selectedOption) => {
                      setSelectedCountry(selectedOption);
                    }}
                    getOptionValue={(option) => option.name.common} 
                    getOptionLabel={(option) => option.name.common}
                    className="muilt-select-field"
                    classNamePrefix="select"
                    placeholder="Select Country"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Zip Code
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Zip Code"
                    value={zipcode}
                    onChange={(event) => {
                      setZipcode(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        className={
          window.location.pathname === "/clients/create"
            ? "footer-wrapper"
            : "footer-wrapper d-none"
        }
      >
        <Button
          variant="contained"
          size="large"
          className="primary-btn btn w-120"
          disableRipple
          onClick={submitHandler}
          disabled={isButtonDisabled}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="large"
          className="secondary-btn btn w-120"
          disableRipple
          onClick={() => navigate("/clients")}
        >
          Cancel
        </Button>
      </Box>
    </>
  );
};

export default ClientCreate;

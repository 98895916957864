import React, { useEffect } from 'react';
import { Box, Grid, TextField, Typography } from '@mui/material';

import Select from 'react-select';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactQuill from 'react-quill';
import { Stack } from '@mui/system';
import { fetchTechnologies } from '../../store/slices/technologySlice';
import AddTechnologyModal from '../../components/common/addTechnologySlice';

const ProjectCreate = ({
  index,
  projectForm,
  setProjectForm,
  projectErrors,
  setProjectFormError,
  setProjectExperienceError,
}) => {
  const dispatch = useDispatch();
  const [selectedProject, setSelectedProject] = useState();
  const projects = useSelector((state) => state.projects.projects);
  useEffect(() => {
    dispatch(fetchTechnologies({ query: '' }));
  }, [dispatch]);
  const projectOptions =
    projects?.allprojects?.map((project) => ({
      value: project.id,
      label: project.project_name,
    })) || [];

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],

      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
    ],
  };
  const modules_link = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
    ],
  };

  useEffect(() => {
    if (selectedProject) {
      setProjectExperienceError();

      let projectDataArray = [...projectForm];

      const project = projects?.allprojects?.find(
        (project) => project.id === selectedProject?.value
      );
      projectDataArray[index].searchProject = selectedProject;
      projectDataArray[index].title = project?.project_name;
      // projectDataArray[index].description = project?.project_description;
      const paragraphs = project?.project_description?.split('\r\n');
      let desc = '<p>';
      paragraphs?.map((item) => {
        desc += `<div>${item}</div>`;
      });
      desc += '</p>';
      projectDataArray[index].description = desc;
      let techstack = '<ul>';
      project?.project_technologies?.map((item) => {
        techstack += `<li>${item.technologies.technology_name}</li>`;
      });
      techstack += '</ul>';
      projectDataArray[index].techStack = techstack;
      let urls = '<ul>';
      project?.project_links?.map((link) => {
        urls += `<li><a href={${link?.project_link}}>${link?.project_link}</a></li> `;
      });
      urls += '</ul>';

      projectDataArray[index].projectUrls = urls;

      setProjectForm(projectDataArray);
    }
  }, [selectedProject]);

  const handleChange = (key, value) => {
    setProjectExperienceError();

    setProjectForm((prev) => {
      const newProjectsData = [...prev];

      if (newProjectsData[index]) {
        newProjectsData[index] = { ...newProjectsData[index], [key]: value };
      } else {
        newProjectsData[index] = { [key]: value };
      }

      return newProjectsData;
    });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Select
                  name='colors'
                  className='muilt-select-field'
                  options={projectOptions}
                  onChange={(selectedOption) =>
                    setSelectedProject(selectedOption)
                  }
                  value={projectForm[index]?.searchProject || null}
                  classNamePrefix='select'
                  placeholder='Search Project'
                  sx={{ backgroundColor: 'white' }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: 'var(--secondary-text-color)',
                      primary: '#8CC714',
                    },
                  })}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}></Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant='body1' component='label'>
                  Project Title
                  <Typography
                    variant='body1'
                    component='span'
                    className='asterisk'
                  >
                    *
                  </Typography>
                </Typography>
                <TextField
                  className='input-field'
                  fullWidth
                  id='outlined-basic'
                  label=''
                  variant='outlined'
                  size='small'
                  placeholder='Enter Project Title'
                  onChange={(e) => {
                    handleChange('title', e.target.value);

                    setProjectFormError((prev) => {
                      if (prev?.length > 0) {
                        const temp = [...prev];
                        if (temp[index]) {
                          temp[index].title = ''; // Ensure the element at index exists before setting role
                        }
                        return temp;
                      }
                    });
                  }}
                  value={projectForm[index]?.title || ''}
                  sx={{ backgroundColor: 'white' }}
                />
                {projectErrors?.title && (
                  <Typography variant='body1' component='p' className='error'>
                    {projectErrors?.title}
                  </Typography>
                )}
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}></Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant='body1' component='label'>
                  Project Description
                  <Typography
                    variant='body1'
                    component='span'
                    className='asterisk'
                  >
                    *
                  </Typography>
                </Typography>
                <Box className='ck-editor-wrapper'>
                  {/* <Typography className='value'>
                    {formatDescription(projectForm[index]?.description)}
                  </Typography> */}
                  <ReactQuill
                    modules={modules}
                    onChange={(e) => {
                      handleChange('description', e);
                      setProjectFormError((prev) => {
                        if (prev?.length > 0) {
                          const temp = [...prev];
                          if (temp[index]) {
                            temp[index].description = ''; // Ensure the element at index exists before setting role
                          }
                          return temp;
                        }
                      });
                    }}
                    value={projectForm[index]?.description}
                    style={{ backgroundColor: 'white' }}
                    className='cv'
                    placeholder={`e.g.
Developed a comprehensive e-commerce platform using Python and Django, featuring a robust product management system.`}
                  />
                </Box>
                {projectErrors?.description && (
                  <Typography variant='body1' component='p' className='error'>
                    {projectErrors?.description}
                  </Typography>
                )}
              </Grid>

              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <Typography variant='body1' component='label'>
                    Technology Stacks
                  </Typography>
                </Stack>

                <ReactQuill
                  modules={modules}
                  onChange={(e) => handleChange('techStack', e)}
                  value={projectForm[index]?.techStack}
                  style={{ backgroundColor: 'white' }}
                  className='cv'
                  placeholder={`e.g.
Python , Django , ML`}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant='body1' component='label'>
                  Links
                </Typography>

                <ReactQuill
                  modules={modules_link}
                  onChange={(e) => {
                    handleChange('projectUrls', e);
                  }}
                  value={projectForm[index]?.projectUrls}
                  style={{ backgroundColor: 'white' }}
                  className='cv'
                  placeholder={`e.g.
https://www.99spoons.com`}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <Typography variant='body1' component='label'>
                  Roles & Responsibilities
                </Typography>
                <Box className='ck-editor-wrapper'>
                  <ReactQuill
                    value={projectForm[index]?.roles}
                    modules={modules}
                    onChange={(e) => {
                      handleChange('roles', e);
                    }}
                    style={{ backgroundColor: 'white' }}
                    className='cv'
                    placeholder={`e.g.
• Developed responsive interfaces using React, Next.js, and Node.js. Ensure smooth integration with MySQL database for data management
`}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ProjectCreate;

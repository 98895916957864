import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  activity: null,
  loading: false,
  error: null,
};

const editActivityByIdSlice = createSlice({
  name: "editActivityById",
  initialState,
  reducers: {
    editActivityByIdStart(state) {
      state.loading = true;
      state.error = null;
    },
    editActivityByIdSuccess(state, action) {
      state.loading = false;
      state.activity = action.payload;
    },
    editActivityByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  editActivityByIdStart,
  editActivityByIdSuccess,
  editActivityByIdFailure,
} = editActivityByIdSlice.actions;
export default editActivityByIdSlice.reducer;

export const editactivityById =
  ({ activityId, activityData }) =>
  async (dispatch) => {
    try {
      dispatch(editActivityByIdStart());
      const response = await callApi({
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/projectactivity/${activityId}`,
        data: activityData,
      });

      const bid = response;
      const data = response.data;
      dispatch(editActivityByIdSuccess(bid));
      return { success: true, message: data.message };
    } catch (error) {
      dispatch(editActivityByIdFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return { success: false, error: error.response.data.message };
    }
  };

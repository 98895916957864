import { Box, Fade, Modal, TextField, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import {
  RiDeleteBinLine,
  RiPencilLine,
  RiSave2Line,
  RiCloseLine,
} from "react-icons/ri";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import KeywordDeleteModal from "./deleteKeywordModal";

const KeywordModal = ({ keywordModalOpen, handleClose }) => {
  // const keywords = useSelector((state) => state.keywords.keywords);
  const [keyword /*setKeyword*/] = useState([]);
  const [editModes, setEditModes] = useState([]);
  const [editedKeywordValues, setEditedKeywordValues] = useState({});
  const [deleteKeywordModalOpen, setDeleteKeywordModalOpen] = useState(false);
  const [keyId, setKeyId] = useState("");

  const toggleEdit = (index) => {
    // console.log(index, "index");
    const updatedEditModes = [...editModes];
    updatedEditModes[index] = !updatedEditModes[index];
    setEditModes(updatedEditModes);
  };

  const handleCancelChange = () => {
    setEditModes([]);
    setEditedKeywordValues({});
    handleClose();
  };

  const handleDeleteOpen = (keyId) => {
    // console.log(keyId, "id of key from keyword listing");
    setKeyId(keyId);
    setDeleteKeywordModalOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteKeywordModalOpen(false);
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={keywordModalOpen}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={keywordModalOpen}>
          <Box className="modal-wrapper keyword-modal">
            <Box variant="div" component="div" className="title">
              <Typography
                variant="h3"
                component="h3"
                style={{ padding: "0px 34px" }}
              >
                Keywords Listing
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body">
              <TableContainer
                className="table-listing"
                style={{ padding: "34px 0px" }}
              >
                <Table className="listing-table">
                  <TableHead>
                    <TableRow className="add-space">
                      <TableCell align="left">Keywords</TableCell>
                      <TableCell align="right" style={{ paddingRight: "68px" }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(keyword) &&
                      keyword?.map((keywordData, index) => (
                        <TableRow className="add-space" key={keywordData.id}>
                          <TableCell align="left">
                            {editModes[keywordData.id] ? (
                              // Render the editable TextField in edit mode
                              <TextField
                                className="input-field"
                                fullWidth
                                id="outlined-basic"
                                label=""
                                variant="outlined"
                                size="small"
                                value={editedKeywordValues[keywordData.id]}
                                onChange={(event) => {
                                  // console.log(
                                  //   editedKeywordValues[keywordData.id],
                                  //   "keyword value at that place"
                                  // );
                                  const updatedValue = event.target.value;

                                  // Update the edited value state
                                  setEditedKeywordValues((prevState) => ({
                                    ...prevState,
                                    [keywordData.id]: updatedValue,
                                  }));
                                }}
                              />
                            ) : (
                              // Render the non-editable TextField when not in edit mode
                              <TextField
                                className="input-field"
                                fullWidth
                                id="outlined-basic"
                                label=""
                                variant="standard"
                                size="small"
                                value={keywordData?.keyword}
                                disabled
                              />
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <Box className="table-action-btn-wrap align-right">
                              {editModes[keywordData.id] ? (
                                // Render Save and Cancel buttons in edit mode
                                <>
                                  <Button
                                    variant="outlined"
                                    className="action-btn save-btn"
                                    startIcon={<RiSave2Line />}
                                    disableRipple
                                    // onClick={() =>
                                    //   handleSaveClick(
                                    //     keywordData.id,
                                    //     editedKeywordValues[keywordData.id]
                                    //   )
                                    // }
                                  ></Button>
                                  <Button
                                    variant="outlined"
                                    className="action-btn cross-btn"
                                    startIcon={<RiCloseLine />}
                                    disableRipple
                                    onClick={() => {
                                      // Cancel editing and revert to the original keyword text
                                      toggleEdit(keywordData?.id);
                                    }}
                                  ></Button>
                                </>
                              ) : (
                                // Render Edit and Delete buttons when not in edit mode
                                <>
                                  <Button
                                    variant="outlined"
                                    className="action-btn edit-btn"
                                    startIcon={<RiPencilLine />}
                                    disableRipple
                                    // onClick={() => {
                                    //   toggleEdit(keywordData.id);
                                    //   console.log(
                                    //     keywordData.id,
                                    //     "id of the selected keyword from edit button"
                                    //   );
                                    // }}
                                  ></Button>

                                  <Button
                                    variant="outlined"
                                    className="action-btn dlt-btn"
                                    startIcon={<RiDeleteBinLine />}
                                    disableRipple
                                    // onClick={() => showAlert(keywordData.id)}
                                    onClick={() =>
                                      handleDeleteOpen(keywordData?.id)
                                    }
                                  ></Button>
                                </>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                >
                  {" "}
                  Save
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={handleCancelChange}
                >
                  {" "}
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <KeywordDeleteModal
        deleteKeywordModalOpen={deleteKeywordModalOpen}
        handleDeleteOpen={handleDeleteOpen}
        handleDeleteClose={handleDeleteClose}
        keyId={keyId}
      />
    </>
  );
};

export default KeywordModal;

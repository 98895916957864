import { Box, Fade, Modal, Typography } from '@mui/material';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { successToast } from '../../responses/successToast';

import { useDispatch } from 'react-redux';
import { callApi } from './commonfunctionforapi';
import { setFeedback } from '../../store/slices/feedbackSlice';
import ExportLoaderModal from './exportLoaderModal';
import { useNavigate } from 'react-router-dom';

const ExportPdfCVModal = ({
  exportCancel,
  setExportCancel,
  htmlContent = '',
  cvId,
  employeeName,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [exporting, setExporting] = useState(false);
  // const newDiv = ' <div  style=" min-height: 200px;display: block;"> </div>';
  // console.log(htmlContent);
  // const nospace = htmlContent.trim();
  // const newContent = nospace.slice(0, -20) + newDiv + nospace.slice(-20);

  const handleDownloadPDF = async () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const bodyChildren = Array.from(doc.body.children);

    bodyChildren.forEach((child) => {
      if (child.tagName.toLowerCase() === 'p') {
        child.remove();
      }
    });

    const updatedHTML = doc.documentElement.outerHTML;

    const data = await callApi({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/cv_builders/gethtmldata`,
      data: {
        cv_id: cvId,
        htmltemplate: htmlContent === '' ? htmlContent : updatedHTML,
        is_download: 'true',
      },
      responseType: 'blob',
    });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(
      new Blob([data], { type: 'application/pdf' })
    );
    // Create a link element
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${employeeName}_CV.pdf`); // Set the file name

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up and remove the link
    window.URL.revokeObjectURL(url);
    return url;
  };
  const submitHandler = async () => {
    setExporting(true);
    try {
      const url = await handleDownloadPDF();
      setExportCancel(false);
      successToast('Pdf exported successfully!');
      navigate('/cv-builder');
      setExporting(false);
      return { success: true, url };
    } catch (error) {
      dispatch(
        setFeedback({
          status: 'fail',
          message: error.response.message,
          code: error.response.status_code,
        })
      );
      setExporting(false);
      setExportCancel(false);
      return { success: false, error: error.response.message };
    }
  };

  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={exportCancel}
        // onClose={ExportToPdfModalClose}
        closeAfterTransition
      >
        <Fade in={exportCancel}>
          <Box className='modal-wrapper'>
            <Box variant='div' component='div' className='title'>
              <Typography variant='h3' component='h3'>
                Do you want to export this CV as pdf?
              </Typography>
            </Box>
            <Box variant='div' component='div' className='body'></Box>
            <Box variant='div' component='div' className='footer'>
              <Box variant='div' component='div' className='btn-group'>
                <Button
                  variant='contained'
                  size='large'
                  className='primary-btn btn'
                  disableRipple
                  onClick={submitHandler}
                >
                  Export
                </Button>
                <Button
                  variant='contained'
                  size='large'
                  className='secondary-btn btn'
                  disableRipple
                  onClick={() => setExportCancel(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <ExportLoaderModal exporting={exporting} />
    </>
  );
};

export default ExportPdfCVModal;

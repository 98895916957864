import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { successToast } from "../../responses/successToast";
import { fetchContacts } from "../../store/slices/contactSlice";
import { deleteContactById } from "../../store/slices/deleteContactByIdSlice";

const ContactDeleteModal = ({
  contactDelete,
  handleDeleteClose,
  selectedContactId,
  clientId,
  currentPage,
}) => {
  const dispatch = useDispatch();
  const contactId = selectedContactId;
  const toatlClientsPerPage = 10;
  // console.log(contactId, "contact to be deleted id");
  // console.log(clientId, "client to be deleted id");
  // console.log(currentPage, "current page of contact");
  const submitHandler = () => {
    dispatch(deleteContactById({ contactId }))
      .unwrap()
      .then((result) => {
        // Handle success
        const { success } = result;
        // console.log(success, "delete lead success");
        if (success) {
          successToast("Contact deleted successfully");
          dispatch(
            fetchContacts({
              clientId,
              page: "",
              limit: toatlClientsPerPage,
            })
          );
          handleDeleteClose();
        } else {
          // Handle other cases where success is false (optional)
        }
      })
      .catch((error) => {
        // Handle error (optional)
        console.error("Failed to delete user:", error);
      });
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={contactDelete}
        // onClose={ClientDeleteModal}
        closeAfterTransition
      >
        <Fade in={contactDelete}>
          <Box className="modal-wrapper">
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3">
                Do you want to delete this contact?
              </Typography>
              <Typography variant="body1" component="p">
                This action can’t be undone.
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body"></Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={submitHandler}
                >
                  Delete
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={handleDeleteClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ContactDeleteModal;

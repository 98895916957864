import {
  Box,
  Button,
  Grid,
  Typography,
  Stack,
  TextField,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  List,
  ListItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import {
  RiArrowLeftLine,
  RiDeleteBinLine,
  RiPencilLine,
  RiSearchLine,
  RiCloseCircleLine,
} from "react-icons/ri";
import { createKeyword } from "../../store/slices/createKeywordSlice";
import { successToast } from "../../responses/successToast";
import { useDispatch, useSelector } from "react-redux";
import { fetchKeywords } from "../../store/slices/keywordSlice";
import { fetchKeywordsById } from "../../store/slices/keywordbyIdSlice";
import { editKeywordById } from "../../store/slices/editKeywordSlice";
import { deleteKeywordById } from "../../store/slices/deleteKeywordSlice";
import KeywordDeleteModal from "../../components/common/deleteKeywordModal";
import KeywordSelectDeleteModal from "../../components/common/deleteselectKeywordModal";
import Tooltip from "@mui/material/Tooltip";
import ViewProjectsBidsModal from "../../components/common/ViewProjectsBids";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const sortByOptions = [
  { value: "A-Z", label: "A-Z" },
  { value: "Z-A", label: "Z-A" },
  { value: "Most Used", label: "Most Used" },
  { value: "Least Used", label: "Least Used" },
];

const KeywordSetting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [keyName, setKeyName] = useState("");
  const [keywordError, setKeywordError] = useState("");
  const keywords = useSelector((state) => state.keywords.keywords);
  const [searchText, setSearchText] = useState("");
  const [sort, setSort] = useState("A-Z");
  const keywordsById = useSelector((state) => state.keywordsbyId.keywords);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [individualCheckboxes, setIndividualCheckboxes] = useState({});
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [deleteKeyword, setdeleteKeyword] = useState(false);
  const [deleteselectKeyword, setDeleteSelectKeyword] = useState(false);
  const [deleteid, setdeleteId] = useState(null);
  const handleDeleteClose = () => setdeleteKeyword(false);
  const handleDeleteSelectClose = () => setDeleteSelectKeyword(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [viewModalOpen,setViewModalOpen]=useState(false);
  const [techStack,setTechStack]=useState(null);
  const  [techId,setTechId]=useState(null);
  const location=useLocation();
  let path = location.pathname;
  const usedKeywordsCountdata = keywords?.allKeywords?.filter(
    (keyword) => keyword.usedCount === 0
  ).length;

  const usedKeywordsCreatedCount = keywords?.mergeduserResult?.filter(
    (keyword) => keyword.usercount === 0
  ).length;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSort("A-Z");
  };
  const handleSortOptions = (selectedOptions) => {
    setSort(selectedOptions);
  };

  function truncateLabel(label, maxLength) {
    if (label.length > maxLength) {
      return label.substring(0, maxLength) + "... ";
    }
    return label;
  }

  const handleEditKeyword = async (id) => {
    setIsEditing(true);
    setEditId(id);
    await dispatch(fetchKeywordsById(id));
  };
  const handleDeleteSelected = async () => {
    const selectedKeywordIds = selectedKeywords.join(",");
    if (selectedKeywordIds) {
      await dispatch(deleteKeywordById({ deleteId: selectedKeywordIds }))
        .then((response) => {
          if (response.payload.success) {
            const updatedSelectedKeywords = selectedKeywords.filter(
              (id) => !selectedKeywordIds.includes(id)
            );

            setSelectedKeywords(updatedSelectedKeywords);
            successToast("Keyword deleted successfully!");
            dispatch(fetchKeywords({ query: searchText, order: sortValue , createdBy: keywordStatus }));
            setDeleteSelectKeyword(false);
            const anySelected = updatedSelectedKeywords.length > 0;
            if (!anySelected && selectAllChecked) {
              setSelectAllChecked(false);
            }
          } else {
            console.error("Error creating user:", response.error);
            setDeleteSelectKeyword(false);
          }
        })
        .catch((error) => {
          console.error("Error creating user:", error.message);
          setDeleteSelectKeyword(false);
        });
    }
  };
  const handleDeleteOpen = (id) => {
    setdeleteId(id);
    setdeleteKeyword(true);
  };
  const handleSelectDeleteOpen = () => {
    setDeleteSelectKeyword(true);
  };
  const handleDeleteKeyword = async () => {
    await dispatch(deleteKeywordById({ deleteId: deleteid }))
      .then((response) => {
        if (response.payload.success) {
          successToast("Keyword deleted successfully!");
          dispatch(fetchKeywords({ query: searchText, order: sortValue,createdBy:keywordStatus }));
          setdeleteKeyword(false);
          setKeyName("");
        } else {
          console.error("Error creating user:", response.error);
          setdeleteKeyword(false);
        }
      })
      .catch((error) => {
        console.error("Error creating user:", error.message);
        setdeleteKeyword(false);
      });
  };

  const sortValue =
   sort === "A-Z"
    ? "A-Z"
    : sort === "Z-A"
    ? "Z-A"
    : sort === "Most Used"
    ? "mostUsed"
    : "leastUsed";

  const keywordStatus = value === 0 ? "" : "me";

  const tabLabel = (role, modules) => {
    return (
      <Box>
        <span className="user-name">{role}</span>
      </Box>
    );
  };

  const submitHandler = async () => {
    if (isButtonDisabled) {
      return;
    }
    if (!keyName.trim()) {
      setKeywordError("Please enter name of the keyword");
      return;
    }
    setIsButtonDisabled(true);
    try {
      const response = await dispatch(createKeyword(keyName));

      if (response.success) {
        successToast("Keyword created successfully!");
        dispatch(fetchKeywords({ createdBy: keywordStatus, query: searchText, order: sortValue }));
        setKeyName("");
      } else {
        console.error("Error creating user:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      setIsButtonDisabled(false);
    }
  };
  const editsubmitHandler = async () => {
    if (isButtonDisabled) {
      return;
    }
    if (!keyName) {
      setKeywordError("Please enter name of the keyword");
      return;
    }
    setIsButtonDisabled(true);
    try {
      const response = await dispatch(
        editKeywordById({ editId, keyword_name: keyName })
      );

      if (response.success) {
        successToast("Keyword updated successfully!");
        dispatch(fetchKeywords({ createdBy: keywordStatus,query: searchText, order: sortValue }));
        setIsEditing(false);
        setKeyName("");
      } else {
        console.error("Error creating user:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      setIsButtonDisabled(false);
    }
  };

  function KeywordCheckboxList() {
    return (
      <List>
        {keywords?.allKeywords?.map((keyword, index) => (
          <ListItem className="tab-checkbox-list">
            <Tooltip
              title={
                <div style={{ color: "white", fontSize: "13px" }}>
                  {keyword?.name + " (" + keyword?.usedCount + ")"}
                </div>
              }
              arrow
              placement="bottom-end"
            >
              <FormControlLabel
                control={<Checkbox disabled={keyword?.usedCount > 0} />}
                checked={individualCheckboxes[keyword.id]}
                onChange={() => {
                  const updatedCheckboxes = { ...individualCheckboxes };
                  updatedCheckboxes[keyword.id] =
                    !individualCheckboxes[keyword.id];
                  setIndividualCheckboxes(updatedCheckboxes);
                  if (updatedCheckboxes[keyword.id]) {
                    setSelectedKeywords([...selectedKeywords, keyword.id]);
                  } else {
                    setSelectedKeywords(
                      selectedKeywords.filter((id) => id !== keyword.id)
                    );
                  }

                  const allSelected = keywords?.allKeywords?.every(
                    (k) => updatedCheckboxes[k.id]
                  );
                  setSelectAllChecked(allSelected);
                }}
                // label={
                //   truncateLabel(keyword?.name, 15) +
                //   " (" +
                //   keyword?.usedCount +
                //   ")"
                // }
                
          label={
            keyword?.usedCount > 0 ?
            <span style={{color:"inherit",cursor:"pointer"}} onClick={(e)=>{e.stopPropagation();setViewModalOpen(true);setTechStack(keyword?.name);setTechId(keyword?.id)}}>
              {truncateLabel(keyword?.name, 15) +
           " (" +
           keyword?.usedCount +
           ")"}
              </span >
              :<span style={{color:"inherit",cursor:"default"}}>{truncateLabel(keyword?.name, 15) +
                " (" +
                keyword?.usedCount +
                ")"}
          </span>
            
            
          }
                className="checkbox-style-heading"
              />
            </Tooltip>
            <Box className="checkbox-action">
              <Button
                variant="outlined"
                className="action-btn edit-btn"
                startIcon={<RiPencilLine />}
                onClick={() => handleEditKeyword(keyword.id)}
                disableRipple
              ></Button>
              <Button
                variant="outlined"
                className="action-btn dlt-btn"
                startIcon={<RiDeleteBinLine />}
                disabled={keyword?.usedCount > 0}
                onClick={() => handleDeleteOpen(keyword.id)}
                disableRipple
              ></Button>
            </Box>
          </ListItem>
        ))}
      </List>
    );
  }

  // function KeywordCheckboxList() {
  //   return (
  //     <List>
  //       {sort === "A-Z" || sort === "Z-A"
  //         ? keywords?.mergedResult?.map((keyword, index) => (
  //             <ListItem className="tab-checkbox-list">
  //               <Tooltip
  //                 title={
  //                   <div style={{ color: "white", fontSize: "13px" }}>
  //                     {keyword?.keyword_name + " (" + keyword?.count + ")"}
  //                   </div>
  //                 }
  //                 arrow
  //                 placement="bottom-end"
  //               >
  //                 <FormControlLabel
  //                   control={<Checkbox disabled={keyword?.count > 0} />}
  //                   checked={individualCheckboxes[keyword.id]}
  //                   onChange={() => {
  //                     const updatedCheckboxes = { ...individualCheckboxes };
  //                     updatedCheckboxes[keyword.id] =
  //                       !individualCheckboxes[keyword.id];
  //                     setIndividualCheckboxes(updatedCheckboxes);
  //                     if (updatedCheckboxes[keyword.id]) {
  //                       setSelectedKeywords([...selectedKeywords, keyword.id]);
  //                     } else {
  //                       setSelectedKeywords(
  //                         selectedKeywords.filter((id) => id !== keyword.id)
  //                       );
  //                     }

  //                     const allSelected = keywords?.mergedResult?.every(
  //                       (k) => updatedCheckboxes[k.id]
  //                     );
  //                     setSelectAllChecked(allSelected);
  //                   }}
  //                   label={
  //                     truncateLabel(keyword?.keyword_name, 15) +
  //                     " (" +
  //                     keyword?.count +
  //                     ")"
  //                   }
  //                   className="checkbox-style-heading"
  //                 />
  //               </Tooltip>
  //               <Box className="checkbox-action">
  //                 <Button
  //                   variant="outlined"
  //                   className="action-btn edit-btn"
  //                   startIcon={<RiPencilLine />}
  //                   onClick={() => handleEditKeyword(keyword.id)}
  //                   disableRipple
  //                 ></Button>
  //                 <Button
  //                   variant="outlined"
  //                   className="action-btn dlt-btn"
  //                   startIcon={<RiDeleteBinLine />}
  //                   disabled={keyword?.count > 0}
  //                   onClick={() => handleDeleteOpen(keyword.id)}
  //                   disableRipple
  //                 ></Button>
  //               </Box>
  //             </ListItem>
  //           ))
  //         : sort === "Most Used"
  //         ? keywords?.MostUsedkeywords?.map((keyword, index) => (
  //             <ListItem className="tab-checkbox-list">
  //               <Tooltip
  //                 title={
  //                   <div style={{ color: "white", fontSize: "13px" }}>
  //                     {keyword?.keyword_name + " (" + keyword?.count + ")"}
  //                   </div>
  //                 }
  //                 arrow
  //                 placement="bottom-end"
  //               >
  //                 <FormControlLabel
  //                   control={<Checkbox disabled={keyword?.count > 0} />}
  //                   checked={individualCheckboxes[keyword.id]}
  //                   onChange={() => {
  //                     const updatedCheckboxes = { ...individualCheckboxes };
  //                     updatedCheckboxes[keyword.id] =
  //                       !individualCheckboxes[keyword.id];
  //                     setIndividualCheckboxes(updatedCheckboxes);
  //                     if (updatedCheckboxes[keyword.id]) {
  //                       setSelectedKeywords([...selectedKeywords, keyword.id]);
  //                     } else {
  //                       setSelectedKeywords(
  //                         selectedKeywords.filter((id) => id !== keyword.id)
  //                       );
  //                     }

  //                     const allSelected = keywords?.mergedResult?.every(
  //                       (k) => updatedCheckboxes[k.id]
  //                     );
  //                     setSelectAllChecked(allSelected);
  //                   }}
  //                   label={
  //                     truncateLabel(keyword?.keyword_name, 15) +
  //                     " (" +
  //                     keyword?.count +
  //                     ")"
  //                   }
  //                   className="checkbox-style-heading"
  //                 />
  //               </Tooltip>
  //               <Box className="checkbox-action">
  //                 <Button
  //                   variant="outlined"
  //                   className="action-btn edit-btn"
  //                   startIcon={<RiPencilLine />}
  //                   onClick={() => handleEditKeyword(keyword.id)}
  //                   disableRipple
  //                 ></Button>
  //                 <Button
  //                   variant="outlined"
  //                   className="action-btn dlt-btn"
  //                   startIcon={<RiDeleteBinLine />}
  //                   disabled={keyword?.count > 0}
  //                   onClick={() => handleDeleteOpen(keyword.id)}
  //                   disableRipple
  //                 ></Button>
  //               </Box>
  //             </ListItem>
  //           ))
  //         : keywords?.AtleastUsed?.map((keyword, index) => (
  //             <ListItem className="tab-checkbox-list">
  //               <Tooltip
  //                 title={
  //                   <div style={{ color: "white", fontSize: "13px" }}>
  //                     {keyword?.keyword_name + " (" + keyword?.count + ")"}
  //                   </div>
  //                 }
  //                 arrow
  //                 placement="bottom-end"
  //               >
  //                 <FormControlLabel
  //                   control={<Checkbox disabled={keyword?.count > 0} />}
  //                   checked={individualCheckboxes[keyword.id]}
  //                   onChange={() => {
  //                     const updatedCheckboxes = { ...individualCheckboxes };
  //                     updatedCheckboxes[keyword.id] =
  //                       !individualCheckboxes[keyword.id];
  //                     setIndividualCheckboxes(updatedCheckboxes);
  //                     if (updatedCheckboxes[keyword.id]) {
  //                       setSelectedKeywords([...selectedKeywords, keyword.id]);
  //                     } else {
  //                       setSelectedKeywords(
  //                         selectedKeywords.filter((id) => id !== keyword.id)
  //                       );
  //                     }

  //                     const allSelected = keywords?.mergedResult?.every(
  //                       (k) => updatedCheckboxes[k.id]
  //                     );
  //                     setSelectAllChecked(allSelected);
  //                   }}
  //                   label={
  //                     truncateLabel(keyword?.keyword_name, 15) +
  //                     " (" +
  //                     keyword?.count +
  //                     ")"
  //                   }
  //                   className="checkbox-style-heading"
  //                 />
  //               </Tooltip>
  //               <Box className="checkbox-action">
  //                 <Button
  //                   variant="outlined"
  //                   className="action-btn edit-btn"
  //                   startIcon={<RiPencilLine />}
  //                   onClick={() => handleEditKeyword(keyword.id)}
  //                   disableRipple
  //                 ></Button>
  //                 <Button
  //                   variant="outlined"
  //                   className="action-btn dlt-btn"
  //                   startIcon={<RiDeleteBinLine />}
  //                   disabled={keyword?.count > 0}
  //                   onClick={() => handleDeleteOpen(keyword.id)}
  //                   disableRipple
  //                 ></Button>
  //               </Box>
  //             </ListItem>
  //           ))}
  //     </List>
  //   );
  // }
  // function KeywordCheckboxList1() {
  //   return (
  //     <List>
  //       {sort === "A-Z" || sort === "Z-A"
  //         ? keywords?.mergeduserResult?.map((keyword, index) => (
  //             <ListItem className="tab-checkbox-list">
  //               <Tooltip
  //                 title={
  //                   <div style={{ color: "white", fontSize: "13px" }}>
  //                     {keyword?.keyword_name + " (" + keyword?.usercount + ")"}
  //                   </div>
  //                 }
  //                 arrow
  //                 placement="bottom-end"
  //               >
  //                 <FormControlLabel
  //                   control={<Checkbox disabled={keyword?.usercount > 0} />}
  //                   checked={individualCheckboxes[keyword.id]}
  //                   onChange={() => {
  //                     const updatedCheckboxes = { ...individualCheckboxes };
  //                     updatedCheckboxes[keyword.id] =
  //                       !individualCheckboxes[keyword.id];
  //                     setIndividualCheckboxes(updatedCheckboxes);
  //                     if (updatedCheckboxes[keyword.id]) {
  //                       setSelectedKeywords([...selectedKeywords, keyword.id]);
  //                     } else {
  //                       setSelectedKeywords(
  //                         selectedKeywords.filter((id) => id !== keyword.id)
  //                       );
  //                     }

  //                     const allSelected = keywords?.mergedResult?.every(
  //                       (k) => updatedCheckboxes[k.id]
  //                     );
  //                     setSelectAllChecked(allSelected);
  //                   }}
  //                   label={
  //                     truncateLabel(keyword?.keyword_name, 15) +
  //                     " (" +
  //                     keyword?.usercount +
  //                     ")"
  //                   }
  //                   className="checkbox-style-heading"
  //                 />
  //               </Tooltip>
  //               <Box className="checkbox-action">
  //                 <Button
  //                   variant="outlined"
  //                   className="action-btn edit-btn"
  //                   startIcon={<RiPencilLine />}
  //                   onClick={() => handleEditKeyword(keyword.id)}
  //                   disableRipple
  //                 ></Button>
  //                 <Button
  //                   variant="outlined"
  //                   className="action-btn dlt-btn"
  //                   startIcon={<RiDeleteBinLine />}
  //                   disabled={keyword?.usercount > 0}
  //                   onClick={() => handleDeleteOpen(keyword.id)}
  //                   disableRipple
  //                 ></Button>
  //               </Box>
  //             </ListItem>
  //           ))
  //         : sort === "Most Used"
  //         ? keywords?.UserMostUsedkeywords?.map((keyword, index) => (
  //             <ListItem className="tab-checkbox-list">
  //               <Tooltip
  //                 title={
  //                   <div style={{ color: "white", fontSize: "13px" }}>
  //                     {keyword?.keyword_name + " (" + keyword?.usercount + ")"}
  //                   </div>
  //                 }
  //                 arrow
  //                 placement="bottom-end"
  //               >
  //                 <FormControlLabel
  //                   control={<Checkbox disabled={keyword?.usercount > 0} />}
  //                   checked={individualCheckboxes[keyword.id]}
  //                   onChange={() => {
  //                     const updatedCheckboxes = { ...individualCheckboxes };
  //                     updatedCheckboxes[keyword.id] =
  //                       !individualCheckboxes[keyword.id];
  //                     setIndividualCheckboxes(updatedCheckboxes);
  //                     if (updatedCheckboxes[keyword.id]) {
  //                       setSelectedKeywords([...selectedKeywords, keyword.id]);
  //                     } else {
  //                       setSelectedKeywords(
  //                         selectedKeywords.filter((id) => id !== keyword.id)
  //                       );
  //                     }

  //                     const allSelected = keywords?.mergedResult?.every(
  //                       (k) => updatedCheckboxes[k.id]
  //                     );
  //                     setSelectAllChecked(allSelected);
  //                   }}
  //                   label={
  //                     truncateLabel(keyword?.keyword_name, 15) +
  //                     " (" +
  //                     keyword?.usercount +
  //                     ")"
  //                   }
  //                   className="checkbox-style-heading"
  //                 />
  //               </Tooltip>
  //               <Box className="checkbox-action">
  //                 <Button
  //                   variant="outlined"
  //                   className="action-btn edit-btn"
  //                   startIcon={<RiPencilLine />}
  //                   onClick={() => handleEditKeyword(keyword.id)}
  //                   disableRipple
  //                 ></Button>
  //                 <Button
  //                   variant="outlined"
  //                   className="action-btn dlt-btn"
  //                   startIcon={<RiDeleteBinLine />}
  //                   disabled={keyword?.usercount > 0}
  //                   onClick={() => handleDeleteOpen(keyword.id)}
  //                   disableRipple
  //                 ></Button>
  //               </Box>
  //             </ListItem>
  //           ))
  //         : keywords?.UserAtleastUsed?.map((keyword, index) => (
  //             <ListItem className="tab-checkbox-list">
  //               <Tooltip
  //                 title={
  //                   <div style={{ color: "white", fontSize: "13px" }}>
  //                     {keyword?.keyword_name + " (" + keyword?.usercount + ")"}
  //                   </div>
  //                 }
  //                 arrow
  //                 placement="bottom-end"
  //               >
  //                 <FormControlLabel
  //                   control={<Checkbox disabled={keyword?.usercount > 0} />}
  //                   checked={individualCheckboxes[keyword.id]}
  //                   onChange={() => {
  //                     const updatedCheckboxes = { ...individualCheckboxes };
  //                     updatedCheckboxes[keyword.id] =
  //                       !individualCheckboxes[keyword.id];
  //                     setIndividualCheckboxes(updatedCheckboxes);
  //                     if (updatedCheckboxes[keyword.id]) {
  //                       setSelectedKeywords([...selectedKeywords, keyword.id]);
  //                     } else {
  //                       setSelectedKeywords(
  //                         selectedKeywords.filter((id) => id !== keyword.id)
  //                       );
  //                     }

  //                     const allSelected = keywords?.mergedResult?.every(
  //                       (k) => updatedCheckboxes[k.id]
  //                     );
  //                     setSelectAllChecked(allSelected);
  //                   }}
  //                   label={
  //                     truncateLabel(keyword?.keyword_name, 15) +
  //                     " (" +
  //                     keyword?.usercount +
  //                     ")"
  //                   }
  //                   className="checkbox-style-heading"
  //                 />
  //               </Tooltip>
  //               <Box className="checkbox-action">
  //                 <Button
  //                   variant="outlined"
  //                   className="action-btn edit-btn"
  //                   startIcon={<RiPencilLine />}
  //                   onClick={() => handleEditKeyword(keyword.id)}
  //                   disableRipple
  //                 ></Button>
  //                 <Button
  //                   variant="outlined"
  //                   className="action-btn dlt-btn"
  //                   startIcon={<RiDeleteBinLine />}
  //                   disabled={keyword?.usercount > 0}
  //                   onClick={() => handleDeleteOpen(keyword.id)}
  //                   disableRipple
  //                 ></Button>
  //               </Box>
  //             </ListItem>
  //           ))}
  //     </List>
  //   );
  // }

  useEffect(() => {
    if (isEditing) {
      setKeyName(keywordsById?.keyword_name);
    }
  }, [keywordsById, isEditing]);

  useEffect(() => {
    dispatch(fetchKeywords({ createdBy: keywordStatus ,query: searchText, order: sortValue }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, keywordStatus ,searchText, sort]);

  const clearSearchHandler = () => {
    setSearchText("");
  };

  // return (
  //   <>
  //     <Box className="content-wrapper">
  //       <Grid container spacing={3} className="setting-page-wrapper">
  //         <Grid item lg={4} md={12} sm={12} xs={12} className="left-warpper">
  //             <Box className="content-header p-0 m-0">
  //               <Typography variant="h2" component="h2" className="title-with-btn">
  //                 <Button
  //                   variant="outlined"
  //                   className="action-btn back-btn"
  //                   startIcon={<RiArrowLeftLine />}
  //                   disableRipple
  //                   onClick={() => navigate("/settings")}
  //                 ></Button>
  //                 Keywords
  //               </Typography>
  //             </Box>
  //             <Stack spacing={3} className="word-field-wrapper">
  //               <Box className="card-wrapper">
  //                 <Stack spacing={3}>
  //                   <Box>
  //                     <Typography variant="body1" component="label">
  //                       Keyword
  //                       <Typography
  //                         variant="body1"
  //                         component="span"
  //                         className="asterisk"
  //                       >
  //                         *
  //                       </Typography>
  //                     </Typography>
  //                     <TextField
  //                       className="input-field"
  //                       fullWidth
  //                       id="outlined-basic"
  //                       label=""
  //                       variant="outlined"
  //                       size="small"
  //                       placeholder="Enter Keyword"
  //                       value={keyName}
  //                       onChange={(event) => {
  //                         setKeyName(event.target.value);
  //                         if (event.target.value) {
  //                           setKeywordError("");
  //                         }
  //                       }}
  //                     />
  //                     {keywordError && (
  //                       <Typography variant="body1" component="p" className="error">
  //                         {keywordError}
  //                       </Typography>
  //                     )}
  //                   </Box>
  //                   <Box className="form-btn-wrapper">
  //                     <Button
  //                       variant="contained"
  //                       size="large"
  //                       className="primary-btn btn w-120"
  //                       disableRipple
  //                       disabled={isButtonDisabled}
  //                       onClick={isEditing ? editsubmitHandler : submitHandler}
  //                     >
  //                       {isEditing ? "Update" : "Save"}
  //                     </Button>
  //                     <Button
  //                       variant="contained"
  //                       size="large"
  //                       className="secondary-btn btn w-120"
  //                       disableRipple
  //                       onClick={() => {
  //                         setKeyName("");
  //                         setIsEditing(false);
  //                       }}
  //                     >
  //                       Cancel
  //                     </Button>
  //                   </Box>
  //                 </Stack>
  //               </Box>
  //               <Box className="card-wrapper setting-count-card-wrapper">
  //                 <Typography variant="h2" component="h2" className="count">
  //                   {keywords?.Totalkeyword}
  //                 </Typography>
  //                 <Typography variant="h4" component="h4" className="title">
  //                   Total Keywords
  //                 </Typography>
  //               </Box>
  //               <Box className="card-wrapper setting-count-card-wrapper">
  //                 <Typography variant="h2" component="h2" className="count">
  //                   {keywords?.TotalusedKeyword}
  //                 </Typography>
  //                 <Typography variant="h4" component="h4" className="title">
  //                   In Use
  //                 </Typography>
  //               </Box>
  //               <Box className="card-wrapper setting-count-card-wrapper">
  //                 <Typography variant="h2" component="h2" className="count">
  //                   {keywords?.Totalunusedkeyword}
  //                 </Typography>
  //                 <Typography variant="h4" component="h4" className="title">
  //                   Not In Used
  //                 </Typography>
  //               </Box>
  //             </Stack>
  //         </Grid>
  //         <Grid item lg={8} md={12} sm={12} xs={12} className="right-warpper">
  //           <Box className="content-header p-0 m-0">
  //             <Box variant="div" component="div" className="content-header-right">
  //               <Box className="setting-sort-wrapper">
  //                 <Typography
  //                   variant="h2"
  //                   component="h2"
  //                   className="sort-by-title"
  //                 >
  //                   Sort By
  //                 </Typography>
  //                 <Select
  //                   name="colors"
  //                   options={sortByOptions}
  //                   value={
  //                     sortByOptions.find((option) => option.value === sort) ||
  //                     null
  //                   }
  //                   onChange={(selectedOptions) =>
  //                     handleSortOptions(selectedOptions.value)
  //                   }
  //                   className="muilt-select-field"
  //                   classNamePrefix="select"
  //                   theme={(theme) => ({
  //                     ...theme,
  //                     borderRadius: 10,
  //                     colors: {
  //                       ...theme.colors,
  //                       primary25: "var(--secondary-text-color)",
  //                       primary: "#8CC714",
  //                     },
  //                   })}
  //                 />
  //               </Box>
  //               <Box variant="div" component="div" className="search-bar">
  //                 <RiSearchLine />
  //                 <TextField
  //                   fullWidth
  //                   id="outlined-basic"
  //                   label=""
  //                   variant="outlined"
  //                   size="small"
  //                   placeholder="Search here"
  //                   onChange={(e) => setSearchText(e.target.value)}
  //                   value={searchText}
  //                 />
  //                 {searchText && (
  //                   <RiCloseCircleLine
  //                     className="cross-line"
  //                     onClick={clearSearchHandler}
  //                   />
  //                 )}
  //               </Box>
  //             </Box>
  //           </Box>
  //           <Box className="select-all-wrapper mobile-view-checkbox">
  //             <FormControlLabel
  //               control={<Checkbox />}
  //               checked={selectAllChecked}
  //               onChange={() => {
  //                 setSelectAllChecked(!selectAllChecked);
  //                 const updatedCheckboxes = { ...individualCheckboxes };
  //                 if (value === 0) {
  //                   keywords?.mergedResult?.forEach((keyword) => {
  //                     if (!keyword?.count > 0) {
  //                       updatedCheckboxes[keyword.id] = !selectAllChecked;
  //                       if (!selectAllChecked) {
  //                         setSelectedKeywords((prevSelected) => [
  //                           ...prevSelected,
  //                           keyword.id,
  //                         ]);
  //                       } else {
  //                         setSelectedKeywords((prevSelected) =>
  //                           prevSelected.filter((id) => id !== keyword.id)
  //                         );
  //                       }
  //                     }
  //                   });
  //                 } else {
  //                   keywords?.mergeduserResult?.forEach((keyword) => {
  //                     if (!keyword?.usercount > 0) {
  //                       updatedCheckboxes[keyword.id] = !selectAllChecked;
  //                       if (!selectAllChecked) {
  //                         setSelectedKeywords((prevSelected) => [
  //                           ...prevSelected,
  //                           keyword.id,
  //                         ]);
  //                       } else {
  //                         setSelectedKeywords((prevSelected) =>
  //                           prevSelected.filter((id) => id !== keyword.id)
  //                         );
  //                       }
  //                     }
  //                   });
  //                 }
  //                 setIndividualCheckboxes(updatedCheckboxes);
  //               }}
  //               label={`Select All (${
  //                 value === 0 ? usedKeywordsCount : usedKeywordsCreatedCount
  //               })`}
  //               className="checkbox-style-heading"
  //             />
  //             {selectedKeywords.length > 0 && (
  //               <Button
  //                 variant="contained"
  //                 size="large"
  //                 className="primary-btn btn w-120"
  //                 onClick={() => handleSelectDeleteOpen()}
  //                 disableRipple
  //               >
  //                 Delete Selected
  //               </Button>
  //             )}
  //           </Box>
  //           <Box className="content-layout m-0 right-listing-wrapper">
  //             <Box
  //               variant="div"
  //               component="div"
  //               className="tab-wrapper user-listing"
  //             >
  //               <Box className="tab-checkbox-wrapper " sx={{ borderBottom: 1, borderColor: "divider" }}>
  //                 <Tabs
  //                   value={value}
  //                   onChange={handleChange}
  //                   aria-label="basic tabs example"
  //                   className="tab-area"
  //                 >
  //                   <Tab label={tabLabel("All")} {...a11yProps(0)} />
  //                   <Tab label={tabLabel("Created By Me")} {...a11yProps(1)} />
  //                 </Tabs>
  //                 <Box className="select-all-wrapper desktop-view-checkbox">
  //                     <FormControlLabel
  //                       control={<Checkbox />}
  //                       checked={selectAllChecked}
  //                       onChange={() => {
  //                         setSelectAllChecked(!selectAllChecked);
  //                         const updatedCheckboxes = { ...individualCheckboxes };
  //                         if (value === 0) {
  //                           keywords?.mergedResult?.forEach((keyword) => {
  //                             if (!keyword?.count > 0) {
  //                               updatedCheckboxes[keyword.id] = !selectAllChecked;
  //                               if (!selectAllChecked) {
  //                                 setSelectedKeywords((prevSelected) => [
  //                                   ...prevSelected,
  //                                   keyword.id,
  //                                 ]);
  //                               } else {
  //                                 setSelectedKeywords((prevSelected) =>
  //                                   prevSelected.filter((id) => id !== keyword.id)
  //                                 );
  //                               }
  //                             }
  //                           });
  //                         } else {
  //                           keywords?.mergeduserResult?.forEach((keyword) => {
  //                             if (!keyword?.usercount > 0) {
  //                               updatedCheckboxes[keyword.id] = !selectAllChecked;
  //                               if (!selectAllChecked) {
  //                                 setSelectedKeywords((prevSelected) => [
  //                                   ...prevSelected,
  //                                   keyword.id,
  //                                 ]);
  //                               } else {
  //                                 setSelectedKeywords((prevSelected) =>
  //                                   prevSelected.filter((id) => id !== keyword.id)
  //                                 );
  //                               }
  //                             }
  //                           });
  //                         }
  //                         setIndividualCheckboxes(updatedCheckboxes);
  //                       }}
  //                       label={`Select All (${
  //                         value === 0 ? usedKeywordsCount : usedKeywordsCreatedCount
  //                       })`}
  //                       className="checkbox-style-heading"
  //                     />
  //                     {selectedKeywords.length > 0 && (
  //                       <Button
  //                         variant="contained"
  //                         size="large"
  //                         className="primary-btn btn w-120"
  //                         onClick={() => handleSelectDeleteOpen()}
  //                         disableRipple
  //                       >
  //                         Delete Selected
  //                       </Button>
  //                     )}
  //                   </Box>
  //               </Box>
  //               <CustomTabPanel
  //                 value={value}
  //                 index={0}
  //                 className="user-listing-tables"
  //               >
  //                 <Box className="checkbox-list-wrapper">
  //                   <KeywordCheckboxList />
  //                 </Box>
  //               </CustomTabPanel>
  //               <CustomTabPanel
  //                 value={value}
  //                 index={1}
  //                 className="user-listing-tables"
  //               >
  //                 <Box className="checkbox-list-wrapper">
  //                   <KeywordCheckboxList1 />
  //                 </Box>
  //               </CustomTabPanel>
  //             </Box>
  //           </Box>
  //         </Grid>
  //       </Grid>
  //     </Box>
  //     {/*  START :: KEYWORDS SETTING PAGE */}
  //     <Grid container spacing={3} className="setting-page-wrapper">


  //     </Grid>
  //     <KeywordDeleteModal
  //       handleDeleteOpen={handleDeleteOpen}
  //       handleDeleteClose={handleDeleteClose}
  //       deleteKeyword={deleteKeyword}
  //       setdeleteKeyword={setdeleteKeyword}
  //       deleteid={deleteid}
  //       handleDeleteKeyword={handleDeleteKeyword}
  //     />
  //     <KeywordSelectDeleteModal
  //       handleDeleteSelectClose={handleDeleteSelectClose}
  //       deleteselectKeyword={deleteselectKeyword}
  //       setDeleteSelectKeyword={setDeleteSelectKeyword}
  //       deleteid={deleteid}
  //       handleDeleteSelected={handleDeleteSelected}
  //     />
  //     {/* END :: KEYWORDS SETTING PAGE */}
  //   </>
  // );
  return (
    <>
      <Box className="content-wrapper">
        <Grid container spacing={3} className="setting-page-wrapper">
          <Grid item lg={4} md={12} sm={12} xs={12} className="left-warpper">
              <Box className="content-header p-0 m-0">
                <Typography variant="h2" component="h2" className="title-with-btn">
                  <Button
                    variant="outlined"
                    className="action-btn back-btn"
                    startIcon={<RiArrowLeftLine />}
                    disableRipple
                    onClick={() => navigate("/settings")}
                  ></Button>
                  Keywords
                </Typography>
              </Box>
              <Stack spacing={3} className="word-field-wrapper">
                <Box className="card-wrapper">
                  <Stack spacing={3}>
                    <Box>
                      <Typography variant="body1" component="label">
                         Keyword
                        <Typography
                          variant="body1"
                          component="span"
                          className="asterisk"
                        >
                          *
                        </Typography>{" "}
                      </Typography>
                      <TextField
                        className="input-field"
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        placeholder="Enter Keyword"
                        value={keyName}
                        onChange={(event) => {
                          setKeyName(event.target.value);
                          if (event.target.value) {
                            setKeywordError("");
                          }
                        }}
                      />
                      {keywordError && (
                        <Typography variant="body1" component="p" className="error">
                          {keywordError}
                        </Typography>
                      )}
                    </Box>
                    <Box className="form-btn-wrapper">
                      <Button
                        variant="contained"
                        size="large"
                        className="primary-btn btn w-120"
                        disableRipple
                        disabled={isButtonDisabled}
                        onClick={isEditing ? editsubmitHandler : submitHandler}
                      >
                        {isEditing ? "Update" : "Save"}
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        className="secondary-btn btn w-120"
                        onClick={() => {
                          setKeyName("");
                          setIsEditing(false);
                        }}
                        disableRipple
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Stack>
                </Box>
                <Box className="card-wrapper setting-count-card-wrapper">
                  <Typography variant="h2" component="h2" className="count">
                    {keywords?.totalKeywordssCount}
                  </Typography>
                  <Typography variant="h4" component="h4" className="title">
                    Total Keywords
                  </Typography>
                </Box>
                <Box className="card-wrapper setting-count-card-wrapper">
                  <Typography variant="h2" component="h2" className="count">
                    {keywords?.usedKeywordsCount}
                  </Typography>
                  <Typography variant="h4" component="h4" className="title">
                    In Use
                  </Typography>
                </Box>
                <Box className="card-wrapper setting-count-card-wrapper">
                  <Typography variant="h2" component="h2" className="count">
                    {keywords?.unusedKeywordCount}
                  </Typography>
                  <Typography variant="h4" component="h4" className="title">
                    Not In Used
                  </Typography>
                </Box>
              </Stack>
          </Grid>
          <Grid item lg={8} md={12} sm={12} xs={12} className="right-warpper">
            <Box className="content-header p-0 m-0">
              <Box variant="div" component="div" className="content-header-right">
                <Box className="setting-sort-wrapper">
                  <Typography
                    variant="h2"
                    component="h2"
                    className="sort-by-title"
                  >
                    Sort By
                  </Typography>
                  <Select
                    name="colors"
                    options={sortByOptions}
                    value={
                      sortByOptions.find((option) => option.value === sort) ||
                      null
                    }
                    onChange={(selectedOptions) =>
                      handleSortOptions(selectedOptions.value)
                    }
                    className="muilt-select-field"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Box>
                <Box variant="div" component="div" className="search-bar">
                  <RiSearchLine />
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Search here"
                    onChange={(e) => setSearchText(e.target.value)}
                    value={searchText}
                  />
                  {searchText && (
                    <RiCloseCircleLine
                      className="cross-line"
                      onClick={clearSearchHandler}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            <Box className="select-all-wrapper mobile-view-checkbox">
                <FormControlLabel
                  control={<Checkbox />}
                  checked={selectAllChecked}
                  onChange={() => {
                    const updatedCheckboxes = { ...individualCheckboxes };

                    setSelectAllChecked(!selectAllChecked);

                    keywords?.allKeywords?.forEach((keyword) => {
                      if (!keyword?.usedCount > 0) {
                        updatedCheckboxes[keyword.id] = !selectAllChecked;
                        if (!selectAllChecked) {
                          setSelectedKeywords((prevSelected) => [
                            ...prevSelected,
                            keyword.id,
                          ]);
                        } else {
                          setSelectedKeywords((prevSelected) =>
                            prevSelected.filter((id) => id !== keyword.id)
                          );
                        }
                      }
                    });

                    setIndividualCheckboxes(updatedCheckboxes);
                  }}
                  label={`Select All (${usedKeywordsCountdata})`}
                  className="checkbox-style-heading"
                />
                {selectedKeywords.length > 0 && (
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn w-120"
                    onClick={() => handleSelectDeleteOpen()}
                    disableRipple
                  >
                    Delete Selected
                  </Button>
                )}
            </Box>
            <Box className="content-layout m-0 right-listing-wrapper">
              <Box variant="div"component="div"className="tab-wrapper user-listing"  >
                <Box  className="tab-checkbox-wrapper" sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="tab-area"
                  >
                    <Tab label={tabLabel("All")} {...a11yProps(0)} />
                    <Tab label={tabLabel("Created By Me")} {...a11yProps(1)} />
                  </Tabs>
                  <Box className="select-all-wrapper desktop-view-checkbox">
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={selectAllChecked}
                        onChange={() => {
                          const updatedCheckboxes = { ...individualCheckboxes };

                          setSelectAllChecked(!selectAllChecked);

                          keywords?.allKeywords?.forEach((keyword) => {
                            if (!keyword?.usedCount > 0) {
                              updatedCheckboxes[keyword.id] = !selectAllChecked;
                              if (!selectAllChecked) {
                                setSelectedKeywords((prevSelected) => [
                                  ...prevSelected,
                                  keyword.id,
                                ]);
                              } else {
                                setSelectedKeywords((prevSelected) =>
                                  prevSelected.filter((id) => id !== keyword.id)
                                );
                              }
                            }
                          });

                          setIndividualCheckboxes(updatedCheckboxes);
                        }}
                        label={`Select All (${usedKeywordsCountdata})`}
                        className="checkbox-style-heading"
                      />
                      {selectedKeywords.length > 0 && (
                        <Button
                          variant="contained"
                          size="large"
                          className="primary-btn btn w-120"
                          onClick={() => handleSelectDeleteOpen()}
                          disableRipple
                        >
                          Delete Selected
                        </Button>
                      )}
                  </Box>
                </Box>
                
                <CustomTabPanel
                  value={value}
                  index={0}
                  className="user-listing-tables"
                >
                  <Box className="checkbox-list-wrapper">
                    <KeywordCheckboxList count={100} />
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel
                  value={value}
                  index={1}
                  className="user-listing-tables"
                >
                  <Box className="checkbox-list-wrapper">
                    <KeywordCheckboxList count={10} />
                  </Box>
                </CustomTabPanel>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/*  START :: KEYWORDS SETTING PAGE */}
       <KeywordDeleteModal
        handleDeleteOpen={handleDeleteOpen}
        handleDeleteClose={handleDeleteClose}
        deleteKeyword={deleteKeyword}
        setdeleteKeyword={setdeleteKeyword}
        deleteid={deleteid}
        handleDeleteKeyword={handleDeleteKeyword}
      />
      <KeywordSelectDeleteModal
        handleDeleteSelectClose={handleDeleteSelectClose}
        deleteselectKeyword={deleteselectKeyword}
        setDeleteSelectKeyword={setDeleteSelectKeyword}
        deleteid={deleteid}
        handleDeleteSelected={handleDeleteSelected}
      />
       <ViewProjectsBidsModal open={viewModalOpen} techStack={techStack} path={path} techId={techId} setViewModalOpen={setViewModalOpen} sx={{width:"100px"}} componentFor="keyword"></ViewProjectsBidsModal>
      {/* END :: KEYWORDS SETTING PAGE */}
    </>
  );
};

export default KeywordSetting;

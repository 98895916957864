import { createSlice } from "@reduxjs/toolkit";
// import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  isLoading: false,
  error: null,
};

const logoutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {
    logoutStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    logoutSuccess: (state) => {
      state.isLoading = false;
      state.error = null;
    },
    logoutFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { logoutStart, logoutSuccess, logoutFailure } =
  logoutSlice.actions;

export default logoutSlice.reducer;

export const logout = () => async (dispatch) => {
  try {
    dispatch(logoutStart());
    const response = await callApi({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/users/logout`,
      //   data: values,
    });
    // eslint-disable-next-line no-unused-vars
    const data = response.data;
    // console.log(data, "data");

    dispatch(logoutSuccess());
  } catch (error) {
    dispatch(logoutFailure(error.response.data.message));
  }
};

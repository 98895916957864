import { Box, Fade, Modal, TextareaAutosize, Typography } from "@mui/material";

import React, { useState } from "react";
import Button from "@mui/material/Button";

const TextModal = ({
  reason,
  setReason,
  modalisopen,
  setModalIsOpen,
  setDevelopmentStatus,
  projects,
}) => {
  const [error, setError] = useState("");
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalisopen}
        // onClose={LeaddeleteModalClose}
        closeAfterTransition
      >
        <Fade in={modalisopen}>
          <Box className="modal-wrapper">
            <TextareaAutosize
              style={{
                height: "200px",
                width: "520px",
                border: "1px solid grey",
              }}
              placeholder="Add Reason for stopped project"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                if (e.target.value) {
                  setError("");
                }
              }}
            />
            {error && (
              <Typography variant="body1" component="p" className="error">
                {error}
              </Typography>
            )}
            <Box variant="div" component="div" className="body"></Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={() => {
                    if (!reason) {
                      setError("Please Enter Reason ");
                      return;
                    }
                    setModalIsOpen(false);
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => {
                    setModalIsOpen(false);
                    setReason("");
                    setDevelopmentStatus(projects?.development_status);
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default TextModal;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchActivityReports } from "../../store/slices/activityReportSlice";
import {
  RiArrowLeftLine,
  RiPhoneLine,
  RiStackLine,
  RiArrowLeftSLine,
  RiArrowRightSLine,
} from "react-icons/ri";
import { DateRangePicker } from "rsuite";
import { RiPhoneFill } from "react-icons/ri";
import { MdAccessAlarms } from "react-icons/md";
import PopupComponent from "../../components/common/activityDetailModal";
import ActivityPopupComponent from "../../components/common/activityDescriptionModal";
import { FaArrowTrendUp } from "react-icons/fa6";
import Select from "react-select";
import { fetchUsers } from "../../store/slices/userSlice";

moment.locale("en-GB");

function capitalizeWords(input) {
  return input?.replace(/\w+/g, function (word) {
    return word?.charAt(0).toUpperCase() + word.slice(1);
  });
}

const ActivityReportList = () => {
  const today = moment();
  const initialSelectedDates = [
    today.clone().startOf("week").toDate(),
    today.clone().endOf("week").toDate(),
  ];
  const [selectedDates, setSelectedDates] = useState(initialSelectedDates);
  const [startDate, setStartDate] = useState(
    today.clone().startOf("week").toDate().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    today.clone().endOf("week").toDate().toISOString().split("T")[0]
  );
  const activityReport = useSelector(
    (state) => state.activityreports.activityreports
  );
  const users = useSelector((state) => state.users.users.AllTlandBda);
  // console.log(users, "users");
  // console.log(activityReport, "activityReport");
  const assignee = activityReport?.usersDetails;
  // console.log(assignee, "assignee");
  const [selectedUser, setSelectedUser] = useState("");
  const [popupOpen, setPopupOpen] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const handlePopupOpen = (activities) => {
    setSelectedActivities(activities);
    setPopupOpen(true);
  };

  const handlePopupClose = () => {
    setPopupOpen(false);
  };

  const [selectedParticularActivity, setSelectedParticularActivity] =
    useState(null);
  const [ActivityPopupOpen, setActivityPopupOpen] = useState(false);

  const handleActivityClick = (activity) => {
    setSelectedParticularActivity(activity);
    setActivityPopupOpen(true);
  };

  const handleActivityPopupClose = () => {
    setActivityPopupOpen(false);
  };
  // console.log(activityReport, "activityReport");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const leadActivities = activityReport?.leadActivities || [];
  const projectActivities = activityReport?.projectActivities || [];
  // console.log(projectActivities, "projectActivities");
  // console.log(leadActivities, "leadActivities");

  const generateWeekDays = () => {
    const days = [];
    const startOfWeek = moment(selectedDates[0]);
    for (let i = 0; i < 7; i++) {
      const day = startOfWeek.clone().add(i, "days");
      days.push(day);
    }
    return days;
  };

  const weekDays = generateWeekDays();

  useEffect(() => {
    dispatch(
      fetchActivityReports({
        startdate: startDate,
        enddate: endDate,
        userId: selectedUser,
      })
    );
  }, [dispatch, startDate, endDate, selectedDates, selectedUser]);

  const timeSlots = Array.from({ length: 24 }, (_, i) =>
    i < 10 ? `0${i}:00` : `${i}:00`
  );

  // Group activities by date and time
  const groupedActivities = (activities) => {
    return activities.reduce((acc, activity) => {
      const date = moment(activity.date).format("YYYY-MM-DD");
      const time = moment(activity.time, "HH:mm:ss").format("HH:00");
      const key = `${date}-${time}`;

      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(activity);

      return acc;
    }, {});
  };

  const handleDateRangeChange = (date) => {
    // console.log(date, "CROSS BUTTON");
    if (date === null) {
      setSelectedDates(initialSelectedDates);
      const currentWeekStartDate = today
        .clone()
        .startOf("week")
        .toDate()
        .toISOString()
        .split("T")[0];
      const currentWeekEndDate = today
        .clone()
        .endOf("week")
        .toDate()
        .toISOString()
        .split("T")[0];
      setStartDate(currentWeekStartDate);
      setEndDate(currentWeekEndDate);
    } else {
      setSelectedDates(date);
    }
    if (Array.isArray(date) && date.length >= 2) {
      const formattedDates = date.map(
        (date) => date.toISOString().split("T")[0]
      );
      setStartDate(formattedDates[0]);
      setEndDate(formattedDates[1]);
    }
  };


  const handleCloseButtonClick = () => {
    // Calculate the start and end dates based on the selectedDates state
    const currentWeekStartDate = selectedDates[0].toISOString().split("T")[0];
    const currentWeekEndDate = selectedDates[1].toISOString().split("T")[0];
    setStartDate(currentWeekStartDate);
    setEndDate(currentWeekEndDate);
    // Dispatch the fetchActivityReports action with the new dates
    dispatch(
      fetchActivityReports({
        startDate: selectedDates[0].toISOString().split("T")[0],
        endDate: selectedDates[1].toISOString().split("T")[0],
      })
    );
  };

  const handleTodayButtonClick = () => {
    // Calculate the start and end dates based on the selectedDates state
    setSelectedDates([
      today.clone().startOf("week").toDate(),
      today.clone().endOf("week").toDate(),
    ]);
    setStartDate(
      today.clone().startOf("week").toDate().toISOString().split("T")[0]
    );
    setEndDate(
      today.clone().endOf("week").toDate().toISOString().split("T")[0]
    );
  };

  const handleBackButtonClick = () => {
    const previousWeekStartDate = moment(selectedDates[0])
      .subtract(1, "weeks")
      .toDate();
    const previousWeekEndDate = moment(selectedDates[1])
      .subtract(1, "weeks")
      .toDate();
    setSelectedDates([previousWeekStartDate, previousWeekEndDate]);
    setStartDate(previousWeekStartDate.toISOString().split("T")[0]);
    setEndDate(previousWeekEndDate.toISOString().split("T")[0]);
  };

  const handleNextButtonClick = () => {
    const nextWeekStartDate = moment(selectedDates[0]).add(1, "weeks").toDate();
    const nextWeekEndDate = moment(selectedDates[1]).add(1, "weeks").toDate();
    setSelectedDates([nextWeekStartDate, nextWeekEndDate]);
    setStartDate(nextWeekStartDate.toISOString().split("T")[0]);
    setEndDate(nextWeekEndDate.toISOString().split("T")[0]);
  };

  const isToday = (date) => {
    const todayDate = moment().format("YYYY-MM-DD");
    return moment(date).format("YYYY-MM-DD") === todayDate;
  };

  const handleBDASelect = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  const userOptions = users
    ? [
        {
          value: "", // or any unique value representing "All"
          label: "All",
        },
        ...users
          .filter((tl) => tl.email !== "admin@gmail.com") // Exclude options with role_name "admin"
          .map((tl) => ({
            value: tl.id,
            label: `${capitalizeWords(tl.first_name)} ${capitalizeWords(
              tl.last_name
            )}`,
          })),
      ]
    : [];

  useEffect(() => {
    dispatch(
      fetchUsers({
        page: "",
        limit: "",
        query: "",
      })
    );
  }, [dispatch]);
  return (
    <>

      <Box className="content-header activity-schedule-report">
        <Box variant="div" component="div" className="content-header-left">
          <Typography variant="h2" component="h2" className="title-with-btn">
            <Button
              variant="outlined"
              className="action-btn back-btn"
              startIcon={<RiArrowLeftLine />}
              disableRipple
              onClick={() => navigate("/reports")}
            ></Button>
            Activity Schedule
          </Typography>
          <Box className="left-tool"> 
            <Box className="calendar-ui ">
              <DateRangePicker
                oneTap
                style={{ width: "241px" }}
                showOneCalendar
                showWeekNumbers
                hoverRange="week"
                isoWeek
                value={selectedDates}
                placeholder="DD-MM-YYYY ~ DD-MM-YYYY"
                format="dd-MM-yyyy"
                onChange={(newDateRange) =>
                  handleDateRangeChange(newDateRange)
                }
                renderCalendarInfo={() => (
                  <button onClick={handleCloseButtonClick}>Close</button>
                )}
              />
            </Box>
            <Tooltip
                title={
                  <div
                    style={{
                      color: "white",
                      fontSize: "13px",
                    }}
                  >
                    Previous Week
                  </div>
                }
                arrow
                placement="bottom-start"
              >
                <Button
                  variant="contained"
                  size="large"
                  className="custom-icon-btn primary-btn"
                  disableRipple
                  style={{
                    width: "120px",
                    height: "42.3px",
                    borderRadius: "10px",
                  }}
                  onClick={handleBackButtonClick}
                  startIcon={<RiArrowLeftSLine style={{ height: "40px" }} />}
                ></Button>
            </Tooltip>

            <Tooltip
              title={
                <div
                  style={{
                    color: "white",
                    fontSize: "13px",
                    // zIndex: "10000002",
                  }}
                >
                  Next Week
                </div>
              }
              arrow
              placement="bottom-start"
            >
              <Button
                variant="contained"
                size="large"
                className="custom-icon-btn primary-btn"
                disableRipple
                style={{
                  width: "120px",
                  height: "42.3px",
                  borderRadius: "10px",
                }}
                onClick={handleNextButtonClick}
                startIcon={<RiArrowRightSLine />}
              ></Button>
            </Tooltip>

            <Tooltip
                title={
                  <div
                    style={{
                      color: "white",
                      fontSize: "13px",
                      // zIndex: "10000002",
                    }}
                  >
                    Current Week
                  </div>
                }
                arrow
                placement="bottom-start"
              >
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  style={{ width: "120px" }}
                  onClick={handleTodayButtonClick}
                >
                  Today
                </Button>
            </Tooltip>
          </Box>
        </Box>
        <Box className="content-header-right">
            <Select
              name="colors"
              options={userOptions}
              value={
                userOptions?.find((option) => option?.value === selectedUser) ||
                userOptions[0]
              }
              onChange={(selectedOption) =>
                handleBDASelect(selectedOption?.value)
              }
              className="muilt-select-field-report"
              // placeholder="Select Lead Source"
              classNamePrefix="select"
              theme={(theme) => ({
                ...theme,
                borderRadius: 10,
                colors: {
                  ...theme.colors,
                  primary25: "var(--secondary-text-color)",
                  primary: "#8CC714",
                },
              })}
            />
        </Box>
      </Box>

      <Box className="content-wrapper">
        <Box className="card-wrapper">
          <Box
            style={{
              display: "flex",
              justifyContent: "right",
              paddingBottom: "12px",
              gap: "16px",
            }}
          >
            <Box
              style={{
                display: "flex",
                paddingRight: "10px",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <Box
                style={{
                  borderRadius: "50%",
                  background: "var(--light-green)",
                  width: "14px",
                  height: "14px",
                  marginBottom: "2px",
                }}
              ></Box>
              <Typography
                variant="body1"
                className="field-title"
                style={{ display: "flex", alignItems: "center" }}
              >
                {
                  <>
                    <span style={{ fontWeight: "600", fontSize: "12px" }}>
                      Call
                    </span>
                    <Box style={{ marginLeft: "5px", marginTop: "5px" }}>
                      <RiPhoneLine style={{ height: "15px", width: "15px" }} />
                    </Box>
                  </>
                }
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                paddingRight: "10px",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <Box
                style={{
                  borderRadius: "50%",
                  background: "var(--delete-btn-hover)",
                  width: "14px",
                  height: "14px",
                  marginBottom: "2px",
                }}
              ></Box>
              <Typography
                variant="body1"
                className="field-title"
                style={{ display: "flex", alignItems: "center" }}
              >
                {
                  <>
                    <span style={{ fontWeight: "600", fontSize: "12px" }}>
                      Followup
                    </span>
                    <Box style={{ marginLeft: "5px", marginTop: "5px" }}>
                      <MdAccessAlarms
                        style={{ height: "16px", width: "16px" }}
                      />
                    </Box>
                  </>
                }
              </Typography>
            </Box>
          </Box>
          <TableContainer
            component={Paper}
            style={{
              maxHeight:
                activityReport.length !== 0 ? "calc(100vh - 230px)" : "auto",
              width: "100%",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {" "}
                  <TableCell
                    colSpan={8} // Adjust the colspan based on the number of columns
                    style={{
                      textAlign: "center",
                      position: "sticky",
                      top: 0,
                      backgroundColor: "rgb(197, 227, 137)",
                      fontWeight: 600,
                      fontSize: "16px",
                      zIndex: 2,
                    }}
                  >
                    {/* Display the starting and ending dates */}
                    {`${moment(selectedDates[0]).format(
                      "MMM DD, YYYY"
                    )} - ${moment(selectedDates[1]).format("MMM DD, YYYY")}`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      position: "sticky",
                      left: "0",
                      top: "56px",
                      zIndex: 2,
                      backgroundColor: "#323232",
                    }}
                  ></TableCell>
                  {weekDays?.map((day, index) => (
                    <TableCell
                      key={index}
                      align="center"
                      style={{
                        textAlign: "center",
                        color: "#ffffff",
                        borderLeft: "1px solid white",
                        position: "sticky",
                        top: "56px",
                        backgroundColor: "#323232",
                        zIndex: 1,
                      }}
                    >
                      {day.format("DD ddd")}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {timeSlots.map((timeSlot) => (
                  <TableRow key={timeSlot}>
                    <TableCell
                      style={{
                        textAlign: "center",
                        position: "sticky",
                        left: 0,
                        backgroundColor: "rgb(197, 227, 137)",
                        fontWeight: 600,
                        fontSize: "16px",
                        borderBottom: "0.5px solid white",
                        zIndex: 1,
                      }}
                    >
                      {timeSlot}
                    </TableCell>
                    {weekDays.map((day, index) => {
                      const key = `${day.format("YYYY-MM-DD")}-${timeSlot}`;
                      const projectActivitiesGrouped =
                        groupedActivities(projectActivities);
                      const leadActivitiesGrouped =
                        groupedActivities(leadActivities);

                      const projectActivitiesList =
                        projectActivitiesGrouped[key] || [];
                      const leadActivitiesList =
                        leadActivitiesGrouped[key] || [];

                      const allActivities = [
                        ...projectActivitiesList,
                        ...leadActivitiesList,
                      ];
                      // console.log(allActivities, "allActivities");
                      return (
                        <TableCell
                          key={index}
                          align="center"
                          style={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            padding: "10px",
                            minWidth: "180px",
                            backgroundColor: isToday(day) ? "#f2e8f4" : "white",
                            border: isToday(day)
                              ? "2px solid rgba(224, 224, 224, 1)"
                              : "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {allActivities.map((event, eventIndex) => (
                            <React.Fragment key={eventIndex}>
                              {eventIndex < 3 && (
                                <Paper
                                  key={event.id}
                                  elevation={3}
                                  style={{
                                    padding: "6px",
                                    marginBottom:
                                      allActivities?.length > 1
                                        ? "10px"
                                        : "0px",
                                    position: "relative",
                                    backgroundColor:
                                      event.activity === "call"
                                        ? "var(--light-green)"
                                        : "var(--delete-btn-hover)",
                                    display: "flex",
                                    gap: "5px",
                                    cursor: "pointer",
                                    width: "160px",
                                    height: "25px",
                                    alignItems: "center",
                                  }}
                                  onClick={() => handleActivityClick(event)}
                                >
                                  <Typography color="primary">
                                    {event.activity === "call" ? (
                                      <RiPhoneFill
                                        style={{ fontSize: "14px" }}
                                      />
                                    ) : (
                                      <MdAccessAlarms
                                        style={{ fontSize: "14px" }}
                                      />
                                    )}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                      fontSize: "13px",
                                    }}
                                    className="report-text-black"
                                  >
                                    {moment(event.time, "HH:mm:ss").format(
                                      "HH:mm"
                                    )}{" "}
                                  </Typography>

                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      width: "100px",
                                      textAlign: "left",
                                      fontSize: "13px",
                                    }}
                                    className="report-text-black"
                                  >
                                    {event.comments === "" ||
                                    event.comments === null
                                      ? "No comments"
                                      : `${event.comments}`}
                                  </Typography>
                                  <Tooltip
                                    title={
                                      <div
                                        style={{
                                          color: "white",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {event?.project_id ? "Project" : "Lead"}
                                      </div>
                                    }
                                    arrow
                                    placement="bottom-start"
                                  >
                                    <Typography color="primary">
                                      {event?.project_id ? (
                                        <RiStackLine
                                          style={{ fontSize: "14px" }}
                                        />
                                      ) : (
                                        <FaArrowTrendUp
                                          style={{ fontSize: "14px" }}
                                        />
                                      )}
                                    </Typography>
                                  </Tooltip>
                                </Paper>
                              )}
                            </React.Fragment>
                          ))}
                          {allActivities.length > 3 && (
                            <Paper
                              elevation={3}
                              style={{
                                padding: "6px",
                                position: "relative",
                                // top: "42px",
                                backgroundColor: "var(--light-grey)", // Change the background color accordingly
                                display: "flex",
                                gap: "5px",
                                cursor: "pointer",
                                width: "100px",
                                height: "25px",
                                alignItems: "center",
                              }}
                              onClick={() => handlePopupOpen(allActivities)}
                            >
                              <Typography
                                variant="subtitle1"
                                color="primary"
                                style={{
                                  color: "var(--text-grey)",
                                  fontSize: "13px",
                                }}
                              >
                                +{allActivities.length - 3} More
                              </Typography>
                            </Paper>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {popupOpen && (
          <PopupComponent
            activities={selectedActivities}
            onClose={handlePopupClose}
            popupOpen={popupOpen}
            assignee={assignee}
          />
        )}
        {ActivityPopupOpen && (
          <ActivityPopupComponent
            selectedParticularActivity={selectedParticularActivity}
            handleActivityPopupClose={handleActivityPopupClose}
            ActivityPopupOpen={ActivityPopupOpen}
            assignee={assignee}
          />
        )}
      </Box>
    </>
  );
};

export default ActivityReportList;

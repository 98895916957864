/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-redeclare */
import React, { useEffect, useState, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { RiFileCopyFill, RiLinkM } from "react-icons/ri";
import Button from "@mui/material/Button";
import ClipboardJS from "clipboard";
import { toast } from "react-toastify";
import WithPermission from "../../components/common/withPermission";
import { fetchUsers } from "../../store/slices/userSlice";
import {
  RiDeleteBinLine,
  RiEyeLine,
  RiPencilLine,
  RiMailLine,
  RiPhoneLine,
  RiStarFill,
  RiStarLine,
  RiDragMove2Fill,
} from "react-icons/ri";

import {
  Box,
  Chip,
  Tab,
  Tabs,
  Typography,
  Skeleton,
  Tooltip,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import ExportIcon from "../../assets/images/icons/convert-icon.svg";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import DeleteModal from "../../../src/components/common/deletemodal";
import ExportModal from "../../../src/components/common/exportToPdfModal";
import { fetchProjects } from "../../store/slices/projectSlice";
import { favouriteFilter } from "../../store/slices/favouriteProjectSlice";
import { successToast } from "../../responses/successToast";
import { fetchFiltersById } from "../../store/slices/filterByIdSlice";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { fullProjects, updateProjects } from "../../utils/permissions";
import { maskEmail, maskPhoneNumber } from "../../utils/masking";
import { selectIsAdmin } from "../../constants/selectors";
import { CustomTablePaginationToolbar } from "../../components/common/Pagination";

const customToolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#3e3f42",
          color: "white",
          fontSize: "11px",
          margin: "0",
          padding: "10",
          border: "1px solid white",
        },
        arrow: {
          color: "var(--color-white)",
        },
      },
    },
  },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -12,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const ProjectList = ({
  onValueChange,
  onLivePage,
  onDevelopmentPage,
  onBetaPage,
  onAbandonedpage,
  onFavouritepage,
  searchProject,
  filteredProjects,
  filterApplied,
  filterByIdApplied,
  filteredByIdProjects,
  applyFilterId,
  favProjectList,
  favProjectMatchedList,
  setFavProjectList,
  onLiveFilterPage,
  onDevelopmentFilterPage,
  onBetaFilterPage,
  onAbandonedFilterPage,
  filtersbyid,
  onLiveapplyFilterPage,
  onDevelopmentapplyFilterPage,
  onBetaapplyFilterPage,
  onAbandonedapplyFilterPage,
  developmentAppliedFilterPage,
  liveAppliedFilterPage,
  abandonedappliedfilterCurrentPage,
  betaAppliedfilterPage,
  filters,
  searchText,
  selectedKeywords,
  selectedTechnologies,
  startDate,
  endDate,
  bidDate,
  destatus,
  livefilterpage,
  devfilterpage,
  betafilterpage,
  abandonedfilterpage,
  isEditing,
  keywordSwitchValue,
  techStackSwitchValue,
  selectedTLandBDAs,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const clipboardButtonRef = useRef(null);
  let clipboardInstance = null;

  const copyLinkHandler = async (projectsdata) => {
    console.log("STARTED COPIED LINK");

    const publicLink = projectsdata?.random_link;
    console.log("PUBLIC LINK", publicLink);

    if (clipboardInstance) {
      clipboardInstance.destroy();
      clipboardInstance = null;
    }

    clipboardInstance = new ClipboardJS(clipboardButtonRef.current, {
      text: () => publicLink,
    });

    clipboardInstance.on("success", () => {
      toast.success("Copied to clipboard");
      clipboardInstance.destroy();
      clipboardInstance = null;
    });
    clipboardInstance.on("error", () => {
      toast.error("Failed to copy");
    });

    clipboardButtonRef.current.click();
  };

  let { state } = location;
  if (state === null) {
    state = {
      status: "live",
      page: 1,
      rowsPerPage: 10,
      filterApplied: false,
      filterByIdApplied: false,
      applyFilterId: "",
      selectedKeywords: [],
      selectedTechnologies: [],
      selectedTLandBDAs: [],
      text: "",
      startDate: [],
      endDate: [],
      date: "",
      destatus: null,
      edit: "",
      keywordSwitchVal: true,
      techStackSwitchVal: true,
    };
  }

  const getUserRole = useSelector(selectIsAdmin);
  const projects = useSelector((state) => state.projects.projects);

  const [liveCurrentPage, setliveCurrentPage] = useState(
    !filterApplied && !filterByIdApplied && state.status === "live"
      ? state.page
      : 1
  );
  const [developmentCurrentPage, setdevelopmentCurrentPage] = useState(
    !filterApplied && !filterByIdApplied && state.status === "developing"
      ? state.page
      : 1
  );
  const [betaCurrentPage, setbetaCurrentPage] = useState(
    !filterApplied && !filterByIdApplied && state.status === "beta_testing"
      ? state.page
      : 1
  );
  const [abandonedCurrentPage, setabandonedCurrentPage] = useState(
    !filterApplied && !filterByIdApplied && state.status === "abandoned"
      ? state.page
      : 1
  );
  // eslint-disable-next-line no-unused-vars
  const [favouriteCurrentPage, setfavouriteCurrentPage] = useState(
    !filterApplied && state.status === "" ? state.page : 1
  );
  const [liveloading, setLiveLoading] = useState(true);
  const [developmentloading, setDevelopmentLoading] = useState(true);
  const [betaloading, setBetaLoading] = useState(true);
  const [abandonedloading, setAbandonedLoading] = useState(true);
  const [, setlivefilterCurrentPage] = useState(1);
  const [, setdevelopmentfilterCurrentPage] = useState(1);
  const [, setbetafilterCurrentPage] = useState(1);
  const [, setabandonedfilterCurrentPage] = useState(1);
  const [liveapplyfilterCurrentPage, setapplylivefilterCurrentPage] =
    useState(1);
  const [
    developmentapplyfilterCurrentPage,
    setdevelopmentapplyfilterCurrentPage,
  ] = useState(1);
  const [betaapplyfilterCurrentPage, setbetaapplyfilterCurrentPage] =
    useState(1);
  const [abandonedapplyfilterCurrentPage, setabandonedapplyfilterCurrentPage] =
    useState(1);
  const limit = 10;

  const [projectDelete, setProjectDelete] = useState(false);
  // END :: DELETE MODAL

  // START :: EXPORT TO PDF MODAL
  const [exportCancel, setExportCancel] = useState(false);
  const [exportProjectId, setExportProjectId] = useState("");
  const [exportProjectName, setExportProjectName] = useState("");
  const [currPage, setCurrPage] = useState(state.page);
  // const [rowsPerPage, setRowsPerPage] = useState(state.rowsPerPage);
  // END :: EXPORT TO PDF MODAL

  // START :: TAB WISE LISTING FOR LEADS

  const [value, setValue] = useState(state.status);
  const [projectId, setProjectId] = useState(null);
  const users = useSelector((state) => state.users.users);

  const userRoleArray = users?.loginuser?.map(
    (login) => login?.roles?.role_name
  );
  const userRole = userRoleArray ? userRoleArray[0] : null;

  const handleClose = () => {
    setProjectDelete(false);
  };

  const handleChange = (selectedKey) => {
    apiCall(selectedKey);

    setValue(selectedKey);
    onValueChange(selectedKey);
  };

  useEffect(() => {
    if (filterApplied) {
      if (value === "live") {
        setCurrPage(liveapplyfilterCurrentPage);
      }
      if (value === "developing") {
        setCurrPage(developmentapplyfilterCurrentPage);
      }
      if (value === "beta_testing") {
        setCurrPage(betaapplyfilterCurrentPage);
      }
      if (value === "abandoned") {
        setCurrPage(abandonedapplyfilterCurrentPage);
      }
    }
  }, [
    filterApplied,
    liveapplyfilterCurrentPage,
    developmentapplyfilterCurrentPage,
    betaapplyfilterCurrentPage,
    abandonedapplyfilterCurrentPage,
  ]);

  function capitalizeFirstLetter(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }

  function capitalizeWords(input) {
    return input?.replace(/\w+/g, function (word) {
      return word?.charAt(0).toUpperCase() + word.slice(1);
    });
  }

  const handlePageChange = (event, newPage) => {
    if (!filterApplied && !filterByIdApplied) {
      if (value === "") {
        setfavouriteCurrentPage(newPage);
        onFavouritepage(newPage);
        setCurrPage(newPage);
      } else if (value === "live") {
        setliveCurrentPage(newPage);
        onLivePage(newPage);
        setCurrPage(newPage);
      } else if (value === "developing") {
        setdevelopmentCurrentPage(newPage);
        onDevelopmentPage(newPage);
        setCurrPage(newPage);
      } else if (value === "beta_testing") {
        setbetaCurrentPage(newPage);
        onBetaPage(newPage);
        setCurrPage(newPage);
      } else if (value === "abandoned") {
        setabandonedCurrentPage(newPage);
        onAbandonedpage(newPage);
        setCurrPage(newPage);
      }
    }
    if (filterByIdApplied) {
      if (value === "live") {
        setlivefilterCurrentPage(newPage);
        onLiveFilterPage(newPage);
      } else if (value === "developing") {
        setdevelopmentfilterCurrentPage(newPage);
        onDevelopmentFilterPage(newPage);
      } else if (value === "beta_testing") {
        setbetafilterCurrentPage(newPage);
        onBetaFilterPage(newPage);
      } else if (value === "abandoned") {
        setabandonedfilterCurrentPage(newPage);
        onAbandonedFilterPage(newPage);
      }
    }
    if (filterApplied) {
      if (value === "live") {
        setapplylivefilterCurrentPage(newPage);
        onLiveapplyFilterPage(newPage);
      } else if (value === "developing") {
        setdevelopmentapplyfilterCurrentPage(newPage);
        onDevelopmentapplyFilterPage(newPage);
      } else if (value === "beta_testing") {
        setbetaapplyfilterCurrentPage(newPage);
        onBetaapplyFilterPage(newPage);
      } else if (value === "abandoned") {
        setabandonedapplyfilterCurrentPage(newPage);
        onAbandonedapplyFilterPage(newPage);
      }
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onFavouritepage(1);
    onLivePage(1);
    onBetaPage(1);
    onDevelopmentPage(1);
    onAbandonedpage(1);
    if (!filterApplied && !filterByIdApplied) {
      if (value === "") {
        setfavouriteCurrentPage(1);
        setCurrPage(1);
      } else if (value === "live") {
        setliveCurrentPage(1);
        setCurrPage(1);
      } else if (value === "developing") {
        setdevelopmentCurrentPage(1);
        setCurrPage(1);
      } else if (value === "beta_testing") {
        setbetaCurrentPage(1);
        setCurrPage(1);
      } else if (value === "abandoned") {
        setabandonedCurrentPage(1);
        setCurrPage(1);
      }
    }
    onLiveapplyFilterPage(1);
    onDevelopmentapplyFilterPage(1);
    onBetaapplyFilterPage(1);
    onAbandonedapplyFilterPage(1);
    if (filterByIdApplied) {
      if (value === "live") {
        setlivefilterCurrentPage(1);
        setCurrPage(1);
      } else if (value === "developing") {
        setdevelopmentfilterCurrentPage(1);
        setCurrPage(1);
      } else if (value === "beta_testing") {
        setbetafilterCurrentPage(1);
        setCurrPage(1);
      } else if (value === "abandoned") {
        setabandonedfilterCurrentPage(1);
        setCurrPage(1);
      }
    }
    onLiveFilterPage(1);
    onDevelopmentFilterPage(1);
    onBetaFilterPage(1);
    onAbandonedFilterPage(1);
    if (filterApplied) {
      if (value === "live") {
        setapplylivefilterCurrentPage(1);
        setCurrPage(1);
      } else if (value === "developing") {
        setdevelopmentapplyfilterCurrentPage(1);
        setCurrPage(1);
      } else if (value === "beta_testing") {
        setbetaapplyfilterCurrentPage(1);
        setCurrPage(1);
      } else if (value === "abandoned") {
        setabandonedapplyfilterCurrentPage(1);
        setCurrPage(1);
      }
    }
  };
  const starProjectMatchId = favProjectMatchedList?.map(
    (project) => project?.id
  );

  const starProjectHandler = (projectId) => {
    let starProjectId = null; // Initialize the variable

    if (starProjectMatchId?.includes(projectId)) {
      starProjectId = projectId; // Store the projectId in the variable
    }

    const successMessage = starProjectId
      ? "Project removed from favorites successfully!"
      : "Project marked as a favorite successfully!";

    const projectIds = [];
    projectIds.push(projectId);

    const removeProjectIds = [];
    removeProjectIds.push(projectId);
    const filterData = {
      filter_id: applyFilterId,
      favourite_project_id: projectIds,
      favourite_project_remove: starProjectId,
    };
    dispatch(favouriteFilter(filterData))
      .then((response) => {
        if (response.success) {
          dispatch(
            fetchFiltersById({
              filterId: applyFilterId,
              status: value,
            })
          );
          successToast(successMessage);
          dispatch(
            fetchProjects({
              status: value,
              page:
                value === "live"
                  ? liveCurrentPage
                  : value === "developing"
                  ? developmentCurrentPage
                  : value === "beta_testing"
                  ? betaCurrentPage
                  : abandonedCurrentPage,
              limit: +rowsPerPage,
              query: searchProject,
            })
          );
        } else {
          console.error("Error creating user:", response.error);
        }
      })
      .catch((error) => {
        console.error("Error creating user:", error.message);
      });
  };

  const starRemoveProjectHandler = (projectId) => {
    const projectIds = [];
    projectIds.push(projectId);

    const filterData = {
      filter_id: applyFilterId,
      favourite_project_id: "",
      favourite_project_remove: projectIds,
    };
    dispatch(favouriteFilter(filterData))
      .then((response) => {
        if (response.success) {
          dispatch(
            fetchFiltersById({
              filterId: applyFilterId,
              status: value,
            })
          );
          successToast("Project removed from favourite successfully!");
          dispatch(
            fetchProjects({
              status: value,
              page:
                value === "live"
                  ? liveCurrentPage
                  : value === "developing"
                  ? developmentCurrentPage
                  : value === "beta_testing"
                  ? betaCurrentPage
                  : abandonedCurrentPage,
              limit: +rowsPerPage,
              query: searchProject,
            })
          );
        } else {
          console.error("Error creating user:", response.error);
        }
      })
      .catch((error) => {
        console.error("Error creating user:", error.message);
      });
  };

  const exportPdfHandler = (projectId, projectName) => {
    setExportProjectId(projectId);
    setExportProjectName(projectName);
    setExportCancel(true);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleCopySuccess = () => {
    successToast("Copied to ClipBoard");
  };
  const handleDragEnd = (e) => {
    if (userRole !== "Business Executive") {
      if (!e.destination) return;
      let tempData = Array.from(favProjectList);
      let [source_data] = tempData.splice(e.source.index, 1);
      tempData.splice(e.destination.index, 0, source_data);
      setFavProjectList(tempData);
      const rearrangedProjectIds = tempData
        .map((project) => project?.id)
        .join(",");
      dispatch(
        fetchFiltersById({
          filterId: applyFilterId,
          status: value,
          project_sequence: rearrangedProjectIds,
        })
      );
    }
  };

  const favouriteProjects = filterByIdApplied
    ? favProjectList
    : projects?.allProjects?.filter(
        (project) => value && project?.status === value
      );
  const liveProjects = filterApplied
    ? filteredProjects
    : filterByIdApplied
    ? filteredByIdProjects
    : projects?.allprojects?.filter(
        (project) => value === "live" && project?.status === "live"
      );
  const developmentProjects = filterApplied
    ? filteredProjects
    : filterByIdApplied
    ? filteredByIdProjects
    : projects?.allprojects?.filter(
        (project) => value === "developing" && project?.status === "developing"
      );

  const betaProjects = filterApplied
    ? filteredProjects
    : filterByIdApplied
    ? filteredByIdProjects
    : projects?.allprojects?.filter(
        (project) =>
          value === "beta_testing" && project?.status === "beta_testing"
      );
  const abandonedProjects = filterApplied
    ? filteredProjects
    : filterByIdApplied
    ? filteredByIdProjects
    : projects?.allprojects?.filter(
        (project) => value === "abandoned" && project?.status === "abandoned"
      );
  useEffect(() => {
    if (filterByIdApplied) {
      if (value === "live") {
        setCurrPage(livefilterpage);
      }
      if (value === "developing") {
        setCurrPage(devfilterpage);
      }
      if (value === "beta_testing") {
        setCurrPage(betafilterpage);
      }
      if (value === "abandoned") {
        setCurrPage(abandonedfilterpage);
      }
    }
    // if (filterByIdApplied) {
    //   dispatch(
    //     fetchFiltersById({
    //       filterId: applyFilterId,
    //       status: value,
    //       page:
    //         value === "live"
    //           ? livefilterCurrentPage
    //           : value === "developing"
    //           ? developmentfilterCurrentPage
    //           : value === "beta_testing"
    //           ? betafilterCurrentPage
    //           : abandonedfilterCurrentPage,
    //     })
    //   );
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    value,
    filterByIdApplied,
    livefilterpage,
    devfilterpage,
    betafilterpage,
    abandonedfilterpage,
    rowsPerPage,
  ]);
  // useEffect(() => {
  //   if (filterApplied) {
  //     dispatch(
  //       fetchFilters({
  //         text: searchText,
  //         ...(bidDate && { start_date: startDate }),
  //         ...(bidDate && { end_date: endDate }),
  //         keyword: Array.isArray(selectedKeywords)
  //           ? selectedKeywords.map((option) => option.label).join(",")
  //           : "",
  //         technologies: Array.isArray(selectedTechnologies)
  //           ? selectedTechnologies.map((option) => option.label).join(",")
  //           : "",
  //         status: value,
  //         development_status: destatus,
  //         page:
  //           value === "live"
  //             ? liveapplyfilterCurrentPage
  //             : value === "developing"
  //             ? developmentapplyfilterCurrentPage
  //             : value === "beta_testing"
  //             ? betaapplyfilterCurrentPage
  //             : abandonedapplyfilterCurrentPage,
  //       })
  //     );
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   dispatch,
  //   value,
  //   liveapplyfilterCurrentPage,
  //   developmentapplyfilterCurrentPage,
  //   betaapplyfilterCurrentPage,
  //   abandonedapplyfilterCurrentPage,
  // ]);

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }

  useEffect(() => {
    dispatch(
      fetchUsers({
        page: "",
        limit: "",
        query: "",
      })
    );
  }, [dispatch]);

  const tabContent = [
    {
      label: "Favourite",
      key: "",
      content: (
        // Content for the "Live" tab

        <TableContainer className="table-listing">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Table
              sx={{ minWidth: 650 }}
              aria-label="table"
              className="listing-table"
            >
              <TableHead>
                <TableRow>
                  {userRole !== "Business Executive" ? (
                    <TableCell align="left"></TableCell>
                  ) : null}
                  <TableCell align="left">Company Name</TableCell>
                  <TableCell align="left">Contact Person</TableCell>
                  <TableCell align="left">Project</TableCell>
                  <TableCell align="left">Handlers</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <Droppable droppableId="droppable-1">
                {(provider) => (
                  <TableBody
                    ref={provider.innerRef}
                    {...provider.droppableProps}
                  >
                    {favouriteProjects?.map((project, index) => (
                      <Draggable
                        key={project?.id?.toString()}
                        draggableId={project?.id?.toString()}
                        index={index}
                      >
                        {(provider) => (
                          <TableRow
                            key={project?.id.toString()}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            {...provider.draggableProps}
                            ref={provider.innerRef}
                          >
                            {userRole !== "Business Executive" ? (
                              <TableCell
                                component="th"
                                scope="project"
                                style={{ width: "70px" }}
                                {...provider.dragHandleProps}
                              >
                                <RiDragMove2Fill />
                              </TableCell>
                            ) : null}
                            <TableCell align="left">
                              <Tooltip
                                title={
                                  <div
                                    style={{ color: "white", fontSize: "13px" }}
                                  >
                                    {project?.projectclients?.company_name}
                                  </div>
                                }
                                arrow
                                placement="bottom-start"
                              >
                                <Box
                                  variant="div"
                                  component="div"
                                  // className="app-name d-flex"
                                  style={{
                                    marginBottom: "12px",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    width: "160px",
                                  }}
                                >
                                  {capitalizeWords(
                                    project?.projectclients?.company_name ===
                                      "" ||
                                      project?.projectclients?.company_name ===
                                        null
                                      ? "-"
                                      : project?.projectclients?.company_name
                                  )}
                                </Box>
                              </Tooltip>
                              {getUserRole ? (
                                <Box
                                  variant="div"
                                  component="div"
                                  className="app-name d-flex"
                                  style={{ marginBottom: "5px" }}
                                >
                                  {project?.projectclients?.company_email ===
                                    "" ||
                                  project?.projectclients?.company_email ===
                                    null ? (
                                    ""
                                  ) : (
                                    <Tooltip
                                      title={
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Click to Copy
                                        </div>
                                      }
                                      arrow
                                      placement="bottom-start"
                                    >
                                      <Box className="companyicons">
                                        <CopyToClipboard
                                          onCopy={handleCopySuccess}
                                          text={
                                            project?.projectclients
                                              ?.company_email
                                          }
                                        >
                                          <RiMailLine />
                                        </CopyToClipboard>
                                      </Box>
                                    </Tooltip>
                                  )}
                                  {project?.projectclients?.company_email ? (
                                    <Tooltip
                                      title={
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {" "}
                                          {
                                            project?.projectclients
                                              ?.company_email
                                          }
                                        </div>
                                      }
                                      arrow
                                      placement="bottom-start"
                                    >
                                      <Box
                                        variant="div"
                                        component="div"
                                        style={{
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          width: "130px",
                                        }}
                                      >
                                        {project?.projectclients?.company_email}
                                      </Box>
                                    </Tooltip>
                                  ) : (
                                    "-"
                                  )}
                                </Box>
                              ) : (
                                <Box
                                  variant="div"
                                  component="div"
                                  className="app-name d-flex"
                                  style={{ marginBottom: "5px" }}
                                >
                                  {project?.projectclients?.company_email ===
                                    "" ||
                                  project?.projectclients?.company_email ===
                                    null ? (
                                    ""
                                  ) : (
                                    <Box className="companyicons">
                                      <RiMailLine />
                                    </Box>
                                  )}
                                  {project?.projectclients?.company_email ? (
                                    <Box
                                      variant="div"
                                      component="div"
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        width: "130px",
                                      }}
                                    >
                                      {maskEmail(
                                        project?.projectclients?.company_email
                                      )}
                                    </Box>
                                  ) : (
                                    "-"
                                  )}
                                </Box>
                              )}

                              {getUserRole ? (
                                <Box
                                  variant="div"
                                  component="div"
                                  className="app-name d-flex"
                                  style={{ marginBottom: "5px" }}
                                >
                                  {project?.projectclients?.company_phone ===
                                    "" ||
                                  project?.projectclients?.company_phone ===
                                    null ? (
                                    ""
                                  ) : (
                                    <Tooltip
                                      title={
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Click to Copy
                                        </div>
                                      }
                                      arrow
                                      placement="bottom-start"
                                    >
                                      <Box className="companyicons">
                                        <CopyToClipboard
                                          onCopy={handleCopySuccess}
                                          text={
                                            project?.projectclients
                                              ?.company_phone
                                          }
                                        >
                                          <RiPhoneLine />
                                        </CopyToClipboard>
                                      </Box>
                                    </Tooltip>
                                  )}
                                  {project?.projectclients?.company_phone ? (
                                    <Tooltip
                                      title={
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {
                                            project?.projectclients
                                              ?.company_phone
                                          }
                                        </div>
                                      }
                                      arrow
                                      placement="bottom-start"
                                    >
                                      {" "}
                                      <Box
                                        variant="div"
                                        component="div"
                                        style={{
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          width: "130px",
                                        }}
                                      >
                                        {project?.projectclients
                                          ?.company_phone === "" ||
                                        project?.projectclients
                                          ?.company_phone === null
                                          ? "-"
                                          : project?.projectclients
                                              ?.company_phone}
                                      </Box>
                                    </Tooltip>
                                  ) : (
                                    "-"
                                  )}
                                </Box>
                              ) : (
                                <Box
                                  variant="div"
                                  component="div"
                                  className="app-name d-flex"
                                  style={{ marginBottom: "5px" }}
                                >
                                  {project?.projectclients?.company_phone ===
                                    "" ||
                                  project?.projectclients?.company_phone ===
                                    null ? (
                                    ""
                                  ) : (
                                    <Box className="companyicons">
                                      <RiPhoneLine />
                                    </Box>
                                  )}
                                  {project?.projectclients?.company_phone ? (
                                    <Box
                                      variant="div"
                                      component="div"
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        width: "130px",
                                      }}
                                    >
                                      {project?.projectclients
                                        ?.company_phone === "" ||
                                      project?.projectclients?.company_phone ===
                                        null
                                        ? "-"
                                        : maskPhoneNumber(
                                            project?.projectclients
                                              ?.company_phone
                                          )}
                                    </Box>
                                  ) : (
                                    "-"
                                  )}
                                </Box>
                              )}
                            </TableCell>
                            <TableCell align="left">
                              {" "}
                              <Tooltip
                                title={
                                  <div
                                    style={{ color: "white", fontSize: "13px" }}
                                  >
                                    {
                                      project?.client_contacts
                                        ?.contact_person_name
                                    }
                                  </div>
                                }
                                arrow
                                placement="bottom-start"
                              >
                                <Box
                                  variant="div"
                                  component="div"
                                  style={{
                                    marginBottom: "12px",
                                    fontWeight: "600",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    width: "160px",
                                  }}
                                >
                                  {capitalizeWords(
                                    project?.client_contacts
                                      ?.contact_person_name === "" ||
                                      project?.client_contacts
                                        ?.contact_person_name === null
                                      ? "-"
                                      : project?.client_contacts
                                          ?.contact_person_name
                                  )}
                                </Box>
                              </Tooltip>
                              {getUserRole ? (
                                <Box
                                  variant="div"
                                  component="div"
                                  className="app-name d-flex"
                                  style={{ marginBottom: "5px" }}
                                >
                                  {project?.client_contacts
                                    ?.contact_person_email === "" ||
                                  project?.client_contacts
                                    ?.contact_person_email === null ? (
                                    ""
                                  ) : (
                                    <Tooltip
                                      title={
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Click to Copy
                                        </div>
                                      }
                                      arrow
                                      placement="bottom-start"
                                    >
                                      <Box className="companyicons">
                                        <CopyToClipboard
                                          onCopy={handleCopySuccess}
                                          text={
                                            project?.client_contacts
                                              ?.contact_person_email
                                          }
                                        >
                                          <RiMailLine />
                                        </CopyToClipboard>
                                      </Box>
                                    </Tooltip>
                                  )}
                                  {project?.client_contacts
                                    ?.contact_person_email ? (
                                    <Tooltip
                                      title={
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {
                                            project?.client_contacts
                                              ?.contact_person_email
                                          }
                                        </div>
                                      }
                                      arrow
                                      placement="bottom-start"
                                    >
                                      {" "}
                                      <Box
                                        variant="div"
                                        component="div"
                                        style={{
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          width: "130px",
                                        }}
                                      >
                                        {project?.client_contacts
                                          ?.contact_person_email === "" ||
                                        project?.client_contacts
                                          ?.contact_person_email === null
                                          ? "-"
                                          : project?.client_contacts
                                              ?.contact_person_email}
                                      </Box>
                                    </Tooltip>
                                  ) : (
                                    "-"
                                  )}
                                </Box>
                              ) : (
                                <Box
                                  variant="div"
                                  component="div"
                                  className="app-name d-flex"
                                  style={{ marginBottom: "5px" }}
                                >
                                  {project?.client_contacts
                                    ?.contact_person_email === "" ||
                                  project?.client_contacts
                                    ?.contact_person_email === null ? (
                                    ""
                                  ) : (
                                    <Box className="companyicons">
                                      <RiMailLine />
                                    </Box>
                                  )}
                                  {project?.client_contacts
                                    ?.contact_person_email ? (
                                    <Box
                                      variant="div"
                                      component="div"
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        width: "130px",
                                      }}
                                    >
                                      {project?.client_contacts
                                        ?.contact_person_email === "" ||
                                      project?.client_contacts
                                        ?.contact_person_email === null
                                        ? "-"
                                        : maskEmail(
                                            project?.client_contacts
                                              ?.contact_person_email
                                          )}
                                    </Box>
                                  ) : (
                                    "-"
                                  )}
                                </Box>
                              )}
                              {getUserRole ? (
                                <Box
                                  variant="div"
                                  component="div"
                                  className="app-name d-flex"
                                  style={{ marginBottom: "5px" }}
                                >
                                  {project?.client_contacts
                                    ?.contact_person_phone === "" ||
                                  project?.client_contacts
                                    ?.contact_person_phone === null ? (
                                    ""
                                  ) : (
                                    <Tooltip
                                      title={
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "13px",
                                          }}
                                        >
                                          Click to Copy
                                        </div>
                                      }
                                      arrow
                                      placement="bottom-start"
                                    >
                                      <Box className="companyicons">
                                        <CopyToClipboard
                                          onCopy={handleCopySuccess}
                                          text={
                                            project?.client_contacts
                                              ?.contact_person_phone
                                          }
                                        >
                                          <RiPhoneLine />
                                        </CopyToClipboard>
                                      </Box>
                                    </Tooltip>
                                  )}
                                  {project?.client_contacts
                                    ?.contact_person_phone ? (
                                    <Tooltip
                                      title={
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {
                                            project?.client_contacts
                                              ?.contact_person_phone
                                          }
                                        </div>
                                      }
                                      arrow
                                      placement="bottom-start"
                                    >
                                      {" "}
                                      <Box
                                        variant="div"
                                        component="div"
                                        style={{
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          width: "130px",
                                        }}
                                      >
                                        {project?.client_contacts
                                          ?.contact_person_phone === "" ||
                                        project?.client_contacts
                                          ?.contact_person_phone === null
                                          ? "-"
                                          : project?.client_contacts
                                              ?.contact_person_phone}
                                      </Box>
                                    </Tooltip>
                                  ) : (
                                    "-"
                                  )}
                                </Box>
                              ) : (
                                <Box
                                  variant="div"
                                  component="div"
                                  className="app-name d-flex"
                                  style={{ marginBottom: "5px" }}
                                >
                                  {project?.client_contacts
                                    ?.contact_person_phone === "" ||
                                  project?.client_contacts
                                    ?.contact_person_phone === null ? (
                                    ""
                                  ) : (
                                    <Box className="companyicons">
                                      <RiPhoneLine />
                                    </Box>
                                  )}
                                  {project?.client_contacts
                                    ?.contact_person_phone ? (
                                    <Tooltip
                                      title={
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {
                                            project?.client_contacts
                                              ?.contact_person_phone
                                          }
                                        </div>
                                      }
                                      arrow
                                      placement="bottom-start"
                                    >
                                      {" "}
                                      <Box
                                        variant="div"
                                        component="div"
                                        style={{
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          width: "130px",
                                        }}
                                      >
                                        {project?.client_contacts
                                          ?.contact_person_phone === "" ||
                                        project?.client_contacts
                                          ?.contact_person_phone === null
                                          ? "-"
                                          : maskPhoneNumber(
                                              project?.client_contacts
                                                ?.contact_person_phone
                                            )}
                                      </Box>
                                    </Tooltip>
                                  ) : (
                                    "-"
                                  )}
                                </Box>
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ verticalAlign: "top" }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  marginBottom: "12px",
                                }}
                              >
                                <Tooltip
                                  title={
                                    <div
                                      style={{
                                        color: "white",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {project?.project_name}
                                    </div>
                                  }
                                  arrow
                                  placement="bottom-start"
                                >
                                  <Box
                                    variant="div"
                                    component="div"
                                    style={{
                                      fontWeight: "600",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      width: "160px",
                                    }}
                                  >
                                    {capitalizeWords(
                                      project?.project_name === "" ||
                                        project?.project_name === null
                                        ? "-"
                                        : project?.project_name
                                    )}
                                  </Box>
                                </Tooltip>
                                {project?.tms_project_id ? (
                                  <Tooltip
                                    title={
                                      <div
                                        style={{
                                          color: "white",
                                          fontSize: "13px",
                                        }}
                                      >
                                        Synced to TMS
                                      </div>
                                    }
                                    arrow
                                    placement="bottom-start"
                                  >
                                    <PublishedWithChangesIcon
                                      style={{
                                        fill: "green",
                                        position: "relative",
                                        top: "-2px",
                                        left: "3px",
                                        marginRight: "7px",
                                        width: "17px",
                                      }}
                                    />
                                  </Tooltip>
                                ) : null}
                              </Box>
                              <Box
                                variant="div"
                                component="div"
                                className="tag-wrap"
                                style={{ width: "220px", alignItems: "center" }}
                              >
                                {project?.project_technologies?.length > 0
                                  ? project?.project_technologies?.map(
                                      (technology, index) =>
                                        index < 2 && (
                                          <Tooltip
                                            title={
                                              <div
                                                style={{
                                                  color: "white",
                                                  fontSize: "13px",
                                                }}
                                              >
                                                {capitalizeFirstLetter(
                                                  technology?.technologies
                                                    ?.technology_name
                                                )}
                                              </div>
                                            }
                                            arrow
                                            placement="bottom-start"
                                          >
                                            <Box
                                              variant="div"
                                              component="div"
                                              // className="app-name d-flex lead"
                                              style={{
                                                fontWeight: "600",
                                                marginBottom: "12px",
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                              }}
                                            >
                                              <Chip
                                                label={
                                                  technology?.technologies
                                                    ?.technology_name
                                                }
                                              />
                                            </Box>
                                          </Tooltip>
                                        )
                                    )
                                  : null}
                                {project?.project_technologies?.length > 2 && (
                                  <Box variant="div" component="div">
                                    <Tooltip
                                      title={
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {project?.project_technologies
                                            ?.map((technology) =>
                                              capitalizeFirstLetter(
                                                technology?.technologies
                                                  ?.technology_name
                                              )
                                            )
                                            .slice(2)
                                            .join(", ")}{" "}
                                        </div>
                                      }
                                      arrow
                                      placement="bottom-start"
                                    >
                                      <Typography
                                        variant="body1"
                                        component="div"
                                        style={{ marginBottom: "12px" }}
                                      >
                                        +{" "}
                                        <Typography
                                          variant="body1"
                                          component="span"
                                          className="plus-users"
                                        >
                                          {project?.project_technologies
                                            ?.length - 2}
                                        </Typography>{" "}
                                        more
                                      </Typography>
                                    </Tooltip>
                                  </Box>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                verticalAlign: "top",
                                fontWeight: "600",
                              }}
                            >
                              <Box
                                variant="div"
                                component="div"
                                style={{
                                  fontWeight: "600",
                                }}
                                className="app-name d-flex"
                              >
                                Primary TL:
                                {project?.primary_tl === null ||
                                project?.primary_tl_id === null ? (
                                  "-"
                                ) : (
                                  <>
                                    <Tooltip
                                      title={
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {capitalizeFirstLetter(
                                            project?.primary_tl?.first_name
                                          )}{" "}
                                          {capitalizeFirstLetter(
                                            project?.primary_tl?.last_name
                                          )}{" "}
                                        </div>
                                      }
                                      arrow
                                      placement="bottom-start"
                                    >
                                      <Typography
                                        variant="body1"
                                        component="div"
                                        style={{
                                          fontWeight: "400",
                                          color: "var(--primary-text-color) ",
                                          fontSize: "0.925rem",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          width: "130px",
                                        }}
                                      >
                                        {capitalizeFirstLetter(
                                          project?.primary_tl?.first_name
                                        )}{" "}
                                        {capitalizeFirstLetter(
                                          project?.primary_tl?.last_name
                                        )}{" "}
                                      </Typography>
                                    </Tooltip>
                                  </>
                                )}
                              </Box>

                              <Box
                                variant="div"
                                component="div"
                                className="app-name d-flex"
                                style={{ marginBottom: "5px" }}
                              >
                                Primary BD:
                                {project?.primary_bda === null ||
                                project?.primary_bda_id === null ? (
                                  "-"
                                ) : (
                                  <>
                                    <Tooltip
                                      title={
                                        <div
                                          style={{
                                            color: "white",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {capitalizeFirstLetter(
                                            project?.primary_bda?.first_name
                                          )}{" "}
                                          {capitalizeFirstLetter(
                                            project?.primary_bda?.last_name
                                          )}
                                        </div>
                                      }
                                      arrow
                                      placement="bottom-start"
                                    >
                                      <Typography
                                        variant="body1"
                                        component="div"
                                        style={{
                                          fontWeight: "400",
                                          color: "var(--primary-text-color) ",
                                          fontSize: "0.925rem",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          width: "130px",
                                        }}
                                      >
                                        {capitalizeFirstLetter(
                                          project?.primary_bda?.first_name
                                        )}{" "}
                                        {capitalizeFirstLetter(
                                          project?.primary_bda?.last_name
                                        )}
                                      </Typography>
                                    </Tooltip>
                                  </>
                                )}
                              </Box>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{ verticalAlign: "top" }}
                            >
                              <Box className="action-btn-wrap">
                                <ThemeProvider theme={customToolTipTheme}>
                                  <Tooltip title="View">
                                    <span>
                                      <Button
                                        variant="outlined"
                                        className="action-btn view-btn"
                                        startIcon={<RiEyeLine />}
                                        disableRipple
                                        // title="View"
                                        onClick={() =>
                                          navigate(
                                            `/projects/${project.id}/view`,
                                            {
                                              state: {
                                                page: currPage,
                                                rowsPerPage: rowsPerPage,
                                                status: value,
                                                selectedKeywords:
                                                  selectedKeywords,
                                                selectedTechnologies:
                                                  selectedTechnologies,
                                                selectedTLandBDAs:
                                                  selectedTLandBDAs,
                                                text: searchText,
                                                destatus: destatus,
                                                startDate: startDate,
                                                endDate: endDate,
                                                keywordSwitchVal:
                                                  keywordSwitchValue,
                                                techStackSwitchVal:
                                                  techStackSwitchValue,
                                                date: bidDate,
                                                filterApplied: filterApplied,
                                                filterByIdApplied:
                                                  filterByIdApplied,
                                                applyFilterId: applyFilterId,
                                                edit: isEditing,
                                              },
                                            }
                                          )
                                        }
                                      ></Button>
                                    </span>
                                  </Tooltip>
                                </ThemeProvider>
                                <WithPermission
                                  permission={updateProjects}
                                  element={
                                    <ThemeProvider theme={customToolTipTheme}>
                                      <Tooltip title="Edit">
                                        <span>
                                          <Button
                                            variant="outlined"
                                            className="action-btn edit-btn"
                                            startIcon={<RiPencilLine />}
                                            disableRipple
                                            // title="Edit"
                                            onClick={() =>
                                              navigate(
                                                `/projects/${project.id}/edit`,
                                                {
                                                  state: {
                                                    page: currPage,
                                                    rowsPerPage: rowsPerPage,
                                                    status: value,
                                                    selectedKeywords:
                                                      selectedKeywords,
                                                    selectedTechnologies:
                                                      selectedTechnologies,
                                                    selectedTLandBDAs:
                                                      selectedTLandBDAs,
                                                    keywordSwitchVal:
                                                      keywordSwitchValue,
                                                    techStackSwitchVal:
                                                      techStackSwitchValue,
                                                    text: searchText,
                                                    destatus: destatus,
                                                    startDate: startDate,
                                                    endDate: endDate,
                                                    date: bidDate,
                                                    filterApplied:
                                                      filterApplied,
                                                    filterByIdApplied:
                                                      filterByIdApplied,
                                                    applyFilterId:
                                                      applyFilterId,
                                                    edit: isEditing,
                                                  },
                                                }
                                              )
                                            }
                                          ></Button>
                                        </span>
                                      </Tooltip>
                                    </ThemeProvider>
                                  }
                                />
                                <ThemeProvider theme={customToolTipTheme}>
                                  <Tooltip title="Export To Pdf">
                                    <span></span>
                                    <Button
                                      variant="outlined"
                                      className="action-btn export-btn"
                                      onClick={() => {
                                        exportPdfHandler(
                                          project?.id,
                                          project?.project_name
                                        );
                                      }}
                                      // title="Export To Pdf"
                                    >
                                      <img
                                        src={ExportIcon}
                                        alt="Convert Icon"
                                      />
                                    </Button>
                                  </Tooltip>
                                </ThemeProvider>

                                {filterByIdApplied &&
                                userRole !== "Business Executive" ? (
                                  <>
                                    <WithPermission
                                      permission={updateProjects}
                                      element={
                                        <ThemeProvider
                                          theme={customToolTipTheme}
                                        >
                                          <Tooltip title="Favorite">
                                            <span>
                                              <Button
                                                variant="outlined"
                                                className="action-btn dlt-btn"
                                                startIcon={<RiStarFill />}
                                                disableRipple
                                                onClick={() => {
                                                  starRemoveProjectHandler(
                                                    project?.id
                                                  );
                                                }}
                                                // title="Favorite"
                                              ></Button>
                                            </span>
                                          </Tooltip>
                                        </ThemeProvider>
                                      }
                                    />
                                  </>
                                ) : null}
                                <WithPermission
                                  permission={fullProjects}
                                  element={
                                    <ThemeProvider theme={customToolTipTheme}>
                                      <Tooltip title="Delete">
                                        <span>
                                          <Button
                                            variant="outlined"
                                            className="action-btn dlt-btn"
                                            startIcon={<RiDeleteBinLine />}
                                            disableRipple
                                            // title="Delete"
                                            onClick={() =>
                                              handleOpen(project?.id)
                                            }
                                          ></Button>
                                        </span>
                                      </Tooltip>
                                    </ThemeProvider>
                                  }
                                />
                              </Box>
                              {project?.start_date && (
                                <Tooltip
                                  title={
                                    <div
                                      style={{
                                        color: "white",
                                        fontSize: "13px",
                                      }}
                                    >
                                      Development Start Date
                                    </div>
                                  }
                                  arrow
                                  placement="bottom-start"
                                >
                                  <Box
                                    variant="div"
                                    component="div"
                                    className="app-name d-flex"
                                    style={{
                                      color: "#ff606b",
                                      fontStyle: "italic",
                                      fontWeight: "600",
                                      marginTop: "12px",
                                    }}
                                  >
                                    {formatDate(project?.start_date)}
                                  </Box>
                                </Tooltip>
                              )}
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provider.placeholder}
                    {favouriteProjects?.length === 0 ? (
                      <TableRow className="no-msg">
                        <TableCell
                          colSpan={8}
                          style={{
                            width: "100%",
                            textAlign: "center",
                            color: "var(--textgrey)",
                            fontSize: "16px",
                          }}
                          className="no-msg"
                        >
                          No Projects Found
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </DragDropContext>
        </TableContainer>
      ),
    },
    {
      label: "Live",
      key: "live",
      content: (
        // Content for the "Live" tab
        <TableContainer className="table-listing">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="table"
            className="listing-table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Company Name</TableCell>
                <TableCell align="left">Contact Person</TableCell>
                <TableCell align="left">Project </TableCell>
                <TableCell align="left">Handlers</TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {liveloading ? (
                Array.from({ length: 6 }).map((_, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Skeleton variant="text" width={100} height={40} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={100} height={40} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={100} height={40} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={100} height={40} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={100} height={40} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={100} height={40} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" width={100} height={40} />
                    </TableCell>
                  </TableRow>
                ))
              ) : liveProjects?.length > 0 ? (
                liveProjects?.map((project) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell align="left">
                      <Tooltip
                        title={
                          <div style={{ color: "white", fontSize: "13px" }}>
                            {project?.projectclients?.company_name}
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <Box
                          variant="div"
                          component="div"
                          // className="app-name d-flex"
                          style={{
                            marginBottom: "12px",
                            fontWeight: "600",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "160px",
                          }}
                        >
                          {capitalizeWords(
                            project?.projectclients?.company_name === "" ||
                              project?.projectclients?.company_name === null
                              ? "-"
                              : project?.projectclients?.company_name
                          )}
                        </Box>
                      </Tooltip>
                      {getUserRole ? (
                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                          style={{ marginBottom: "5px" }}
                        >
                          {project?.projectclients?.company_email === "" ||
                          project?.projectclients?.company_email === null ? (
                            ""
                          ) : (
                            <Tooltip
                              title={
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "13px",
                                  }}
                                >
                                  Click to Copy
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                            >
                              <Box className="companyicons">
                                <CopyToClipboard
                                  onCopy={handleCopySuccess}
                                  text={project?.projectclients?.company_email}
                                >
                                  <RiMailLine />
                                </CopyToClipboard>
                              </Box>
                            </Tooltip>
                          )}
                          {project?.projectclients?.company_email ? (
                            <Tooltip
                              title={
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "13px",
                                  }}
                                >
                                  {" "}
                                  {project?.projectclients?.company_email}
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                            >
                              <Box
                                variant="div"
                                component="div"
                                style={{
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  width: "130px",
                                }}
                              >
                                {project?.projectclients?.company_email}
                              </Box>
                            </Tooltip>
                          ) : (
                            "-"
                          )}
                        </Box>
                      ) : (
                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                          style={{ marginBottom: "5px" }}
                        >
                          {project?.projectclients?.company_email === "" ||
                          project?.projectclients?.company_email === null ? (
                            ""
                          ) : (
                            <Box className="companyicons">
                              <RiMailLine />
                            </Box>
                          )}
                          {project?.projectclients?.company_email ? (
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {maskEmail(
                                project?.projectclients?.company_email
                              )}
                            </Box>
                          ) : (
                            "-"
                          )}
                        </Box>
                      )}
                      {getUserRole ? (
                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                          style={{ marginBottom: "5px" }}
                        >
                          {project?.projectclients?.company_phone === "" ||
                          project?.projectclients?.company_phone === null ? (
                            ""
                          ) : (
                            <Tooltip
                              title={
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "13px",
                                  }}
                                >
                                  Click to Copy
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                            >
                              <Box className="companyicons">
                                <CopyToClipboard
                                  onCopy={handleCopySuccess}
                                  text={project?.projectclients?.company_phone}
                                >
                                  <RiPhoneLine />
                                </CopyToClipboard>
                              </Box>
                            </Tooltip>
                          )}
                          {project?.projectclients?.company_phone ? (
                            <Tooltip
                              title={
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "13px",
                                  }}
                                >
                                  {project?.projectclients?.company_phone}
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                            >
                              {" "}
                              <Box
                                variant="div"
                                component="div"
                                style={{
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  width: "130px",
                                }}
                              >
                                {project?.projectclients?.company_phone ===
                                  "" ||
                                project?.projectclients?.company_phone === null
                                  ? "-"
                                  : project?.projectclients?.company_phone}
                              </Box>
                            </Tooltip>
                          ) : (
                            "-"
                          )}
                        </Box>
                      ) : (
                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                          style={{ marginBottom: "5px" }}
                        >
                          {project?.projectclients?.company_phone === "" ||
                          project?.projectclients?.company_phone === null ? (
                            ""
                          ) : (
                            <Box className="companyicons">
                              <RiPhoneLine />
                            </Box>
                          )}
                          {project?.projectclients?.company_phone ? (
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {project?.projectclients?.company_phone === "" ||
                              project?.projectclients?.company_phone === null
                                ? "-"
                                : maskPhoneNumber(
                                    project?.projectclients?.company_phone
                                  )}
                            </Box>
                          ) : (
                            "-"
                          )}
                        </Box>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <Tooltip
                        title={
                          <div style={{ color: "white", fontSize: "13px" }}>
                            {project?.client_contacts?.contact_person_name}
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <Box
                          variant="div"
                          component="div"
                          style={{
                            marginBottom: "12px",
                            fontWeight: "600",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "160px",
                          }}
                        >
                          {capitalizeWords(
                            project?.client_contacts?.contact_person_name ===
                              "" ||
                              project?.client_contacts?.contact_person_name ===
                                null
                              ? "-"
                              : project?.client_contacts?.contact_person_name
                          )}
                        </Box>
                      </Tooltip>
                      {getUserRole ? (
                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                          style={{ marginBottom: "5px" }}
                        >
                          {project?.client_contacts?.contact_person_email ===
                            "" ||
                          project?.client_contacts?.contact_person_email ===
                            null ? (
                            ""
                          ) : (
                            <Tooltip
                              title={
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "13px",
                                  }}
                                >
                                  Click to Copy
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                            >
                              <Box className="companyicons">
                                <CopyToClipboard
                                  onCopy={handleCopySuccess}
                                  text={
                                    project?.client_contacts
                                      ?.contact_person_email
                                  }
                                >
                                  <RiMailLine />
                                </CopyToClipboard>
                              </Box>
                            </Tooltip>
                          )}
                          {project?.client_contacts?.contact_person_email ? (
                            <Tooltip
                              title={
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "13px",
                                  }}
                                >
                                  {
                                    project?.client_contacts
                                      ?.contact_person_email
                                  }
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                            >
                              {" "}
                              <Box
                                variant="div"
                                component="div"
                                style={{
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  width: "130px",
                                }}
                              >
                                {project?.client_contacts
                                  ?.contact_person_email === "" ||
                                project?.client_contacts
                                  ?.contact_person_email === null
                                  ? "-"
                                  : project?.client_contacts
                                      ?.contact_person_email}
                              </Box>
                            </Tooltip>
                          ) : (
                            "-"
                          )}
                        </Box>
                      ) : (
                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                          style={{ marginBottom: "5px" }}
                        >
                          {project?.client_contacts?.contact_person_email ===
                            "" ||
                          project?.client_contacts?.contact_person_email ===
                            null ? (
                            ""
                          ) : (
                            <Box className="companyicons">
                              <RiMailLine />
                            </Box>
                          )}
                          {project?.client_contacts?.contact_person_email ? (
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {project?.client_contacts
                                ?.contact_person_email === "" ||
                              project?.client_contacts?.contact_person_email ===
                                null
                                ? "-"
                                : maskEmail(
                                    project?.client_contacts
                                      ?.contact_person_email
                                  )}
                            </Box>
                          ) : (
                            "-"
                          )}
                        </Box>
                      )}
                      {getUserRole ? (
                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                          style={{ marginBottom: "5px" }}
                        >
                          {project?.client_contacts?.contact_person_phone ===
                            "" ||
                          project?.client_contacts?.contact_person_phone ===
                            null ? (
                            ""
                          ) : (
                            <Tooltip
                              title={
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "13px",
                                  }}
                                >
                                  Click to Copy
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                            >
                              <Box className="companyicons">
                                <CopyToClipboard
                                  onCopy={handleCopySuccess}
                                  text={
                                    project?.client_contacts
                                      ?.contact_person_phone
                                  }
                                >
                                  <RiPhoneLine />
                                </CopyToClipboard>
                              </Box>
                            </Tooltip>
                          )}
                          {project?.client_contacts?.contact_person_phone ? (
                            <Tooltip
                              title={
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "13px",
                                  }}
                                >
                                  {
                                    project?.client_contacts
                                      ?.contact_person_phone
                                  }
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                            >
                              {" "}
                              <Box
                                variant="div"
                                component="div"
                                style={{
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  width: "130px",
                                }}
                              >
                                {project?.client_contacts
                                  ?.contact_person_phone === "" ||
                                project?.client_contacts
                                  ?.contact_person_phone === null
                                  ? "-"
                                  : project?.client_contacts
                                      ?.contact_person_phone}
                              </Box>
                            </Tooltip>
                          ) : (
                            "-"
                          )}
                        </Box>
                      ) : (
                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                          style={{ marginBottom: "5px" }}
                        >
                          {project?.client_contacts?.contact_person_phone ===
                            "" ||
                          project?.client_contacts?.contact_person_phone ===
                            null ? (
                            ""
                          ) : (
                            <Box className="companyicons">
                              <RiPhoneLine />
                            </Box>
                          )}
                          {project?.client_contacts?.contact_person_phone ? (
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {project?.client_contacts
                                ?.contact_person_phone === "" ||
                              project?.client_contacts?.contact_person_phone ===
                                null
                                ? "-"
                                : maskPhoneNumber(
                                    project?.client_contacts
                                      ?.contact_person_phone
                                  )}
                            </Box>
                          ) : (
                            "-"
                          )}
                        </Box>
                      )}
                    </TableCell>

                    <TableCell align="left" style={{ verticalAlign: "top" }}>
                      <Box
                        style={{
                          display: "flex",
                          marginBottom: "12px",
                        }}
                      >
                        <Tooltip
                          title={
                            <div style={{ color: "white", fontSize: "13px" }}>
                              {project?.project_name}
                            </div>
                          }
                          arrow
                          placement="bottom-start"
                        >
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              fontWeight: "600",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              maxWidth: "160px",
                            }}
                          >
                            {capitalizeWords(
                              project?.project_name === "" ||
                                project?.project_name === null
                                ? "-"
                                : project?.project_name
                            )}
                          </Box>
                        </Tooltip>
                        {project?.tms_project_id ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                Synced to TMS
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <PublishedWithChangesIcon
                              style={{
                                fill: "green",
                                position: "relative",
                                top: "-2px",
                                left: "3px",
                                marginRight: "7px",
                                width: "17px",
                              }}
                            />
                          </Tooltip>
                        ) : null}
                      </Box>
                      <Box
                        variant="div"
                        component="div"
                        className="tag-wrap"
                        style={{ width: "220px", alignItems: "center" }}
                      >
                        {project?.project_technologies?.length > 0
                          ? project?.project_technologies?.map(
                              (technology, index) =>
                                index < 2 && (
                                  <Tooltip
                                    title={
                                      <div
                                        style={{
                                          color: "white",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {capitalizeFirstLetter(
                                          technology?.technologies
                                            ?.technology_name
                                        )}
                                      </div>
                                    }
                                    arrow
                                    placement="bottom-start"
                                  >
                                    <Box
                                      variant="div"
                                      component="div"
                                      // className="app-name d-flex lead"
                                      style={{
                                        fontWeight: "600",
                                        marginBottom: "12px",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                      }}
                                    >
                                      <Chip
                                        label={
                                          technology?.technologies
                                            ?.technology_name
                                        }
                                      />
                                    </Box>
                                  </Tooltip>
                                )
                            )
                          : null}
                        {project?.project_technologies?.length > 2 && (
                          <Box variant="div" component="div">
                            <Tooltip
                              title={
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "13px",
                                  }}
                                >
                                  {project?.project_technologies
                                    ?.map((technology) =>
                                      capitalizeFirstLetter(
                                        technology?.technologies
                                          ?.technology_name
                                      )
                                    )
                                    .slice(2)
                                    .join(", ")}{" "}
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                            >
                              <Typography
                                variant="body1"
                                component="div"
                                style={{ marginBottom: "12px" }}
                              >
                                +{" "}
                                <Typography
                                  variant="body1"
                                  component="span"
                                  className="plus-users"
                                >
                                  {project?.project_technologies?.length - 2}
                                </Typography>{" "}
                                more
                              </Typography>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        verticalAlign: "top",
                        fontWeight: "600",
                      }}
                    >
                      <Box
                        variant="div"
                        component="div"
                        style={{
                          fontWeight: "600",
                        }}
                        className="app-name d-flex"
                      >
                        Primary TL:
                        {project?.primary_tl === null ||
                        project?.primary_tl_id === null ? (
                          "-"
                        ) : (
                          <>
                            <Tooltip
                              title={
                                <div
                                  style={{ color: "white", fontSize: "13px" }}
                                >
                                  {capitalizeFirstLetter(
                                    project?.primary_tl?.first_name
                                  )}{" "}
                                  {capitalizeFirstLetter(
                                    project?.primary_tl?.last_name
                                  )}{" "}
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                            >
                              <Typography
                                variant="body1"
                                component="div"
                                style={{
                                  fontWeight: "400",
                                  color: "var(--primary-text-color) ",
                                  fontSize: "0.925rem",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  width: "130px",
                                }}
                              >
                                {capitalizeFirstLetter(
                                  project?.primary_tl?.first_name
                                )}{" "}
                                {capitalizeFirstLetter(
                                  project?.primary_tl?.last_name
                                )}{" "}
                              </Typography>
                            </Tooltip>
                          </>
                        )}
                      </Box>

                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        Primary BD:
                        {project?.primary_bda === null ||
                        project?.primary_bda_id === null ? (
                          "-"
                        ) : (
                          <>
                            <Tooltip
                              title={
                                <div
                                  style={{ color: "white", fontSize: "13px" }}
                                >
                                  {capitalizeFirstLetter(
                                    project?.primary_bda?.first_name
                                  )}{" "}
                                  {capitalizeFirstLetter(
                                    project?.primary_bda?.last_name
                                  )}
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                            >
                              <Typography
                                variant="body1"
                                component="div"
                                style={{
                                  fontWeight: "400",
                                  color: "var(--primary-text-color) ",
                                  fontSize: "0.925rem",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  width: "130px",
                                }}
                              >
                                {capitalizeFirstLetter(
                                  project?.primary_bda?.first_name
                                )}{" "}
                                {capitalizeFirstLetter(
                                  project?.primary_bda?.last_name
                                )}
                              </Typography>
                            </Tooltip>
                          </>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="left" style={{ verticalAlign: "top" }}>
                      <Box className="action-btn-wrap">
                        <ThemeProvider theme={customToolTipTheme}>
                          <Tooltip title="View">
                            <span>
                              <Button
                                variant="outlined"
                                className="action-btn view-btn"
                                startIcon={<RiEyeLine />}
                                disableRipple
                                // title="View"
                                onClick={() =>
                                  navigate(`/projects/${project.id}/view`, {
                                    state: {
                                      page: currPage,
                                      rowsPerPage: rowsPerPage,
                                      status: value,
                                      selectedKeywords: selectedKeywords,
                                      selectedTechnologies:
                                        selectedTechnologies,
                                      selectedTLandBDAs: selectedTLandBDAs,
                                      text: searchText,
                                      keywordSwitchVal: keywordSwitchValue,
                                      techStackSwitchVal: techStackSwitchValue,
                                      destatus: destatus,
                                      startDate: startDate,
                                      endDate: endDate,
                                      date: bidDate,
                                      filterApplied: filterApplied,
                                      filterByIdApplied: filterByIdApplied,
                                      applyFilterId: applyFilterId,
                                      edit: isEditing,
                                    },
                                  })
                                }
                              ></Button>
                            </span>
                          </Tooltip>
                        </ThemeProvider>
                        <WithPermission
                          permission={updateProjects}
                          element={
                            <ThemeProvider theme={customToolTipTheme}>
                              <Tooltip title="Edit">
                                <span>
                                  <Button
                                    variant="outlined"
                                    className="action-btn edit-btn"
                                    startIcon={<RiPencilLine />}
                                    disableRipple
                                    // title="Edit"
                                    onClick={() =>
                                      navigate(`/projects/${project.id}/edit`, {
                                        state: {
                                          page: currPage,
                                          rowsPerPage: rowsPerPage,
                                          status: value,
                                          selectedKeywords: selectedKeywords,
                                          selectedTechnologies:
                                            selectedTechnologies,
                                          selectedTLandBDAs: selectedTLandBDAs,
                                          text: searchText,
                                          keywordSwitchVal: keywordSwitchValue,
                                          techStackSwitchVal:
                                            techStackSwitchValue,
                                          destatus: destatus,
                                          startDate: startDate,
                                          endDate: endDate,
                                          date: bidDate,
                                          filterApplied: filterApplied,
                                          filterByIdApplied: filterByIdApplied,
                                          applyFilterId: applyFilterId,
                                          edit: isEditing,
                                        },
                                      })
                                    }
                                  ></Button>
                                </span>
                              </Tooltip>
                            </ThemeProvider>
                          }
                        />
                        <ThemeProvider theme={customToolTipTheme}>
                          <Tooltip title="Export To Pdf">
                            <span>
                              <Button
                                variant="outlined"
                                className="action-btn export-btn"
                                onClick={() => {
                                  exportPdfHandler(
                                    project?.id,
                                    project?.project_name
                                  );
                                }}
                                // title="Export To Pdf"
                              >
                                <img src={ExportIcon} alt="Convert Icon" />
                              </Button>
                            </span>
                          </Tooltip>
                          <Tooltip title="Copy Public Link" arrow>
                            <Button
                              variant="outlined"
                              className="action-btn copy-btn"
                              startIcon={ <RiLinkM />}
                              disableRipple
                              onClick={() => copyLinkHandler(project)}
                              ref={clipboardButtonRef}
                            ></Button>
                          </Tooltip>
                        </ThemeProvider>
                        {filterByIdApplied &&
                        userRole !== "Business Executive" ? (
                          <>
                            <WithPermission
                              permission={updateProjects}
                              element={
                                <ThemeProvider theme={customToolTipTheme}>
                                  <Tooltip title="Favorite">
                                    <span>
                                      <Button
                                        variant="outlined"
                                        className="action-btn dlt-btn"
                                        startIcon={
                                          starProjectMatchId?.includes(
                                            project?.id
                                          ) ? (
                                            <RiStarFill />
                                          ) : (
                                            <RiStarLine />
                                          ) // Empty star icon
                                        }
                                        onClick={() =>
                                          starProjectHandler(project?.id)
                                        }
                                        disableRipple
                                        // title="Favorite"
                                      ></Button>
                                    </span>
                                  </Tooltip>
                                </ThemeProvider>
                              }
                            />
                          </>
                        ) : null}
                        <WithPermission
                          permission={fullProjects}
                          element={
                            <ThemeProvider theme={customToolTipTheme}>
                              <Tooltip title="Delete">
                                <span>
                                  <Button
                                    variant="outlined"
                                    className="action-btn dlt-btn"
                                    startIcon={<RiDeleteBinLine />}
                                    disableRipple
                                    // title="Delete"
                                    onClick={() => handleOpen(project.id)}
                                  ></Button>
                                </span>
                              </Tooltip>
                            </ThemeProvider>
                          }
                        />
                      </Box>
                      {project?.start_date && (
                        <Tooltip
                          title={
                            <div
                              style={{
                                color: "white",
                                fontSize: "13px",
                              }}
                            >
                              Development Start Date
                            </div>
                          }
                          arrow
                          placement="bottom-start"
                        >
                          <Box
                            variant="div"
                            component="div"
                            className="app-name d-flex"
                            style={{
                              color: "#ff606b",
                              fontStyle: "italic",
                              fontWeight: "600",
                              marginTop: "12px",
                            }}
                          >
                            {formatDate(project?.start_date)}
                          </Box>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow className="no-msg">
                  <TableCell
                    colSpan={8}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "var(--textgrey)",
                      fontSize: "16px",
                    }}
                    className="no-msg"
                  >
                    No Projects Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {liveProjects?.length > 0 && (
            // <Box variant="div" component="div" className="pagination-wrapper">

            <CustomTablePaginationToolbar
              count={
                filterByIdApplied
                  ? filtersbyid?.totalprojectUserCount
                  : filterApplied
                  ? filters?.totalprojectUserCount
                  : projects?.total
              }
              page={
                filterByIdApplied
                  ? livefilterpage
                  : filterApplied
                  ? liveAppliedFilterPage
                  : liveCurrentPage
              }
              rowsPerPage={+rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            // </Box>
          )}
        </TableContainer>
      ),
    },
    {
      label: "Development",
      key: "developing",
      content: (
        // Content for the "Development" tab
        <TableContainer className="table-listing">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="table"
            className="listing-table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Company Name</TableCell>
                <TableCell align="left">Contact Person</TableCell>
                <TableCell align="left">Project </TableCell>
                <TableCell align="left">Handlers </TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            {developmentloading ? (
              Array.from({ length: 6 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                </TableRow>
              ))
            ) : developmentProjects?.length > 0 ? (
              developmentProjects?.map((project) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">
                    <Tooltip
                      title={
                        <div style={{ color: "white", fontSize: "13px" }}>
                          {project?.projectclients?.company_name}
                        </div>
                      }
                      arrow
                      placement="bottom-start"
                    >
                      <Box
                        variant="div"
                        component="div"
                        style={{
                          marginBottom: "12px",
                          fontWeight: "600",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "160px",
                        }}
                      >
                        {capitalizeWords(
                          project?.projectclients?.company_name === "" ||
                            project?.projectclients?.company_name === null
                            ? "-"
                            : project?.projectclients?.company_name
                        )}
                      </Box>
                    </Tooltip>
                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.projectclients?.company_email === "" ||
                        project?.projectclients?.company_email === null ? (
                          ""
                        ) : (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                Click to Copy
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box className="companyicons">
                              <CopyToClipboard
                                onCopy={handleCopySuccess}
                                text={project?.projectclients?.company_email}
                              >
                                <RiMailLine />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip>
                        )}
                        {project?.projectclients?.company_email ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {" "}
                                {project?.projectclients?.company_email}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {project?.projectclients?.company_email}
                            </Box>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.projectclients?.company_email === "" ||
                        project?.projectclients?.company_email === null ? (
                          ""
                        ) : (
                          <Box className="companyicons">
                            <RiMailLine />
                          </Box>
                        )}
                        {project?.projectclients?.company_email ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {maskEmail(project?.projectclients?.company_email)}
                          </Box>
                        ) : (
                          "-"
                        )}
                      </Box>
                    )}
                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.projectclients?.company_phone === "" ||
                        project?.projectclients?.company_phone === null ? (
                          ""
                        ) : (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                Click to Copy
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box className="companyicons">
                              <CopyToClipboard
                                onCopy={handleCopySuccess}
                                text={project?.projectclients?.company_phone}
                              >
                                <RiPhoneLine />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip>
                        )}
                        {project?.projectclients?.company_phone ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {project?.projectclients?.company_phone}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            {" "}
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {project?.projectclients?.company_phone === "" ||
                              project?.projectclients?.company_phone === null
                                ? "-"
                                : project?.projectclients?.company_phone}
                            </Box>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.projectclients?.company_phone === "" ||
                        project?.projectclients?.company_phone === null ? (
                          ""
                        ) : (
                          <Box className="companyicons">
                            <RiPhoneLine />
                          </Box>
                        )}
                        {project?.projectclients?.company_phone ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {project?.projectclients?.company_phone === "" ||
                            project?.projectclients?.company_phone === null
                              ? "-"
                              : maskPhoneNumber(
                                  project?.projectclients?.company_phone
                                )}
                          </Box>
                        ) : (
                          "-"
                        )}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Tooltip
                      title={
                        <div style={{ color: "white", fontSize: "13px" }}>
                          {project?.client_contacts?.contact_person_name}
                        </div>
                      }
                      arrow
                      placement="bottom-start"
                    >
                      <Box
                        variant="div"
                        component="div"
                        style={{
                          marginBottom: "12px",
                          fontWeight: "600",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "160px",
                        }}
                      >
                        {capitalizeWords(
                          project?.client_contacts?.contact_person_name ===
                            "" ||
                            project?.client_contacts?.contact_person_name ===
                              null
                            ? "-"
                            : project?.client_contacts?.contact_person_name
                        )}
                      </Box>
                    </Tooltip>
                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.client_contacts?.contact_person_email ===
                          "" ||
                        project?.client_contacts?.contact_person_email ===
                          null ? (
                          ""
                        ) : (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                Click to Copy
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box className="companyicons">
                              <CopyToClipboard
                                onCopy={handleCopySuccess}
                                text={
                                  project?.client_contacts?.contact_person_email
                                }
                              >
                                <RiMailLine />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip>
                        )}
                        {project?.client_contacts?.contact_person_email ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {project?.client_contacts?.contact_person_email}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            {" "}
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {project?.client_contacts
                                ?.contact_person_email === "" ||
                              project?.client_contacts?.contact_person_email ===
                                null
                                ? "-"
                                : project?.client_contacts
                                    ?.contact_person_email}
                            </Box>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.client_contacts?.contact_person_email ===
                          "" ||
                        project?.client_contacts?.contact_person_email ===
                          null ? (
                          ""
                        ) : (
                          <Box className="companyicons">
                            <RiMailLine />
                          </Box>
                        )}
                        {project?.client_contacts?.contact_person_email ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {project?.client_contacts?.contact_person_email ===
                              "" ||
                            project?.client_contacts?.contact_person_email ===
                              null
                              ? "-"
                              : maskEmail(
                                  project?.client_contacts?.contact_person_email
                                )}
                          </Box>
                        ) : (
                          "-"
                        )}
                      </Box>
                    )}
                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.client_contacts?.contact_person_phone ===
                          "" ||
                        project?.client_contacts?.contact_person_phone ===
                          null ? (
                          ""
                        ) : (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                Click to Copy
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box className="companyicons">
                              <CopyToClipboard
                                onCopy={handleCopySuccess}
                                text={
                                  project?.client_contacts?.contact_person_phone
                                }
                              >
                                <RiPhoneLine />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip>
                        )}
                        {project?.client_contacts?.contact_person_phone ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {project?.client_contacts?.contact_person_phone}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            {" "}
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {project?.client_contacts
                                ?.contact_person_phone === "" ||
                              project?.client_contacts?.contact_person_phone ===
                                null
                                ? "-"
                                : project?.client_contacts
                                    ?.contact_person_phone}
                            </Box>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.client_contacts?.contact_person_phone ===
                          "" ||
                        project?.client_contacts?.contact_person_phone ===
                          null ? (
                          ""
                        ) : (
                          <Box className="companyicons">
                            <RiPhoneLine />
                          </Box>
                        )}
                        {project?.client_contacts?.contact_person_phone ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {project?.client_contacts?.contact_person_phone ===
                              "" ||
                            project?.client_contacts?.contact_person_phone ===
                              null
                              ? "-"
                              : maskPhoneNumber(
                                  project?.client_contacts?.contact_person_phone
                                )}
                          </Box>
                        ) : (
                          "-"
                        )}
                      </Box>
                    )}
                  </TableCell>

                  <TableCell align="left" style={{ verticalAlign: "top" }}>
                    <Box
                      style={{
                        display: "flex",
                        marginBottom: "12px",
                      }}
                    >
                      <Tooltip
                        title={
                          <div style={{ color: "white", fontSize: "13px" }}>
                            {project?.project_name}
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <Box
                          variant="div"
                          component="div"
                          style={{
                            fontWeight: "600",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "160px",
                          }}
                        >
                          {capitalizeWords(
                            project?.project_name === "" ||
                              project?.project_name === null
                              ? "-"
                              : project?.project_name
                          )}
                        </Box>
                      </Tooltip>
                      {project?.tms_project_id ? (
                        <Tooltip
                          title={
                            <div
                              style={{
                                color: "white",
                                fontSize: "13px",
                              }}
                            >
                              Synced to TMS
                            </div>
                          }
                          arrow
                          placement="bottom-start"
                        >
                          <PublishedWithChangesIcon
                            style={{
                              fill: "green",
                              position: "relative",
                              top: "-2px",
                              left: "3px",
                              marginRight: "7px",
                              width: "17px",
                            }}
                          />
                        </Tooltip>
                      ) : null}
                    </Box>
                    <Box
                      variant="div"
                      component="div"
                      className="tag-wrap"
                      style={{ width: "220px", alignItems: "center" }}
                    >
                      {project?.project_technologies?.length > 0
                        ? project?.project_technologies?.map(
                            (technology, index) =>
                              index < 2 && (
                                <Tooltip
                                  title={
                                    <div
                                      style={{
                                        color: "white",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {capitalizeFirstLetter(
                                        technology?.technologies
                                          ?.technology_name
                                      )}
                                    </div>
                                  }
                                  arrow
                                  placement="bottom-start"
                                >
                                  <Box
                                    variant="div"
                                    component="div"
                                    // className="app-name d-flex lead"
                                    style={{
                                      fontWeight: "600",
                                      marginBottom: "12px",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <Chip
                                      label={
                                        technology?.technologies
                                          ?.technology_name
                                      }
                                    />
                                  </Box>
                                </Tooltip>
                              )
                          )
                        : null}
                      {project?.project_technologies?.length > 2 && (
                        <Box variant="div" component="div">
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {project?.project_technologies
                                  ?.map((technology) =>
                                    capitalizeFirstLetter(
                                      technology?.technologies?.technology_name
                                    )
                                  )
                                  .slice(2)
                                  .join(", ")}{" "}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              style={{ marginBottom: "12px" }}
                            >
                              +{" "}
                              <Typography
                                variant="body1"
                                component="span"
                                className="plus-users"
                              >
                                {project?.project_technologies?.length - 2}
                              </Typography>{" "}
                              more
                            </Typography>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </TableCell>

                  <TableCell
                    align="left"
                    style={{
                      verticalAlign: "top",
                      fontWeight: "600",
                    }}
                  >
                    <Box
                      variant="div"
                      component="div"
                      style={{
                        fontWeight: "600",
                      }}
                      className="app-name d-flex"
                    >
                      Primary TL:
                      {project?.primary_tl === null ||
                      project?.primary_tl_id === null ? (
                        "-"
                      ) : (
                        <>
                          <Tooltip
                            title={
                              <div style={{ color: "white", fontSize: "13px" }}>
                                {capitalizeFirstLetter(
                                  project?.primary_tl?.first_name
                                )}{" "}
                                {capitalizeFirstLetter(
                                  project?.primary_tl?.last_name
                                )}{" "}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              style={{
                                fontWeight: "400",
                                color: "var(--primary-text-color) ",
                                fontSize: "0.925rem",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {capitalizeFirstLetter(
                                project?.primary_tl?.first_name
                              )}{" "}
                              {capitalizeFirstLetter(
                                project?.primary_tl?.last_name
                              )}{" "}
                            </Typography>
                          </Tooltip>
                        </>
                      )}
                    </Box>

                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{ marginBottom: "5px" }}
                    >
                      Primary BD:
                      {project?.primary_bda === null ||
                      project?.primary_bda_id === null ? (
                        "-"
                      ) : (
                        <>
                          <Tooltip
                            title={
                              <div style={{ color: "white", fontSize: "13px" }}>
                                {capitalizeFirstLetter(
                                  project?.primary_bda?.first_name
                                )}{" "}
                                {capitalizeFirstLetter(
                                  project?.primary_bda?.last_name
                                )}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              style={{
                                fontWeight: "400",
                                color: "var(--primary-text-color) ",
                                fontSize: "0.925rem",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {capitalizeFirstLetter(
                                project?.primary_bda?.first_name
                              )}{" "}
                              {capitalizeFirstLetter(
                                project?.primary_bda?.last_name
                              )}
                            </Typography>
                          </Tooltip>
                        </>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="left" style={{ verticalAlign: "top" }}>
                    <Box className="action-btn-wrap">
                      <ThemeProvider theme={customToolTipTheme}>
                        <Tooltip title="View">
                          <span>
                            <Button
                              variant="outlined"
                              className="action-btn view-btn"
                              startIcon={<RiEyeLine />}
                              disableRipple
                              // title="View"
                              onClick={() =>
                                navigate(`/projects/${project.id}/view`, {
                                  state: {
                                    page: currPage,
                                    rowsPerPage: rowsPerPage,
                                    status: value,
                                    selectedKeywords: selectedKeywords,
                                    selectedTechnologies: selectedTechnologies,
                                    selectedTLandBDAs: selectedTLandBDAs,
                                    text: searchText,
                                    keywordSwitchVal: keywordSwitchValue,
                                    techStackSwitchVal: techStackSwitchValue,
                                    destatus: destatus,
                                    startDate: startDate,
                                    endDate: endDate,
                                    date: bidDate,
                                    filterApplied: filterApplied,
                                    filterByIdApplied: filterByIdApplied,
                                    applyFilterId: applyFilterId,
                                    edit: isEditing,
                                  },
                                })
                              }
                            ></Button>
                          </span>
                        </Tooltip>
                      </ThemeProvider>
                      <WithPermission
                        permission={updateProjects}
                        element={
                          <ThemeProvider theme={customToolTipTheme}>
                            <Tooltip title="Edit">
                              <span>
                                <Button
                                  variant="outlined"
                                  className="action-btn edit-btn"
                                  startIcon={<RiPencilLine />}
                                  disableRipple
                                  // title="Edit"
                                  onClick={() =>
                                    navigate(`/projects/${project.id}/edit`, {
                                      state: {
                                        page: currPage,
                                        rowsPerPage: rowsPerPage,
                                        status: value,
                                        selectedKeywords: selectedKeywords,
                                        selectedTechnologies:
                                          selectedTechnologies,
                                        selectedTLandBDAs: selectedTLandBDAs,
                                        text: searchText,
                                        keywordSwitchVal: keywordSwitchValue,
                                        techStackSwitchVal:
                                          techStackSwitchValue,
                                        destatus: destatus,
                                        startDate: startDate,
                                        endDate: endDate,
                                        date: bidDate,
                                        filterApplied: filterApplied,
                                        filterByIdApplied: filterByIdApplied,
                                        applyFilterId: applyFilterId,
                                        edit: isEditing,
                                      },
                                    })
                                  }
                                ></Button>
                              </span>
                            </Tooltip>
                          </ThemeProvider>
                        }
                      />
                      <ThemeProvider theme={customToolTipTheme}>
                        <Tooltip title="Export To Pdf">
                          <span>
                            <Button
                              variant="outlined"
                              className="action-btn export-btn"
                              onClick={() => {
                                exportPdfHandler(
                                  project?.id,
                                  project?.project_name
                                );
                              }}
                              // title="Export To Pdf"
                            >
                              <img src={ExportIcon} alt="Convert Icon" />
                            </Button>
                          </span>
                        </Tooltip>
                        <Tooltip title="Copy Public Link" arrow>
                          <Button
                            variant="outlined"
                            className="action-btn copy-btn"
                            startIcon={<RiLinkM />}
                            disableRipple
                            onClick={() => copyLinkHandler(project)}
                            ref={clipboardButtonRef}
                          ></Button>
                        </Tooltip>
                      </ThemeProvider>
                      {filterByIdApplied &&
                      userRole !== "Business Executive" ? (
                        <>
                          <WithPermission
                            permission={updateProjects}
                            element={
                              <ThemeProvider theme={customToolTipTheme}>
                                <Tooltip title="Favorite">
                                  <span>
                                    <Button
                                      variant="outlined"
                                      className="action-btn dlt-btn"
                                      startIcon={
                                        starProjectMatchId?.includes(
                                          project?.id
                                        ) ? (
                                          <RiStarFill />
                                        ) : (
                                          <RiStarLine />
                                        ) // Empty star icon
                                      }
                                      onClick={() => {
                                        starProjectHandler(project.id);
                                      }}
                                      disableRipple
                                      // title="Favorite"
                                    ></Button>
                                  </span>
                                </Tooltip>
                              </ThemeProvider>
                            }
                          />
                        </>
                      ) : null}
                      <WithPermission
                        permission={fullProjects}
                        element={
                          <ThemeProvider theme={customToolTipTheme}>
                            <Tooltip title="Delete">
                              <span>
                                <Button
                                  variant="outlined"
                                  className="action-btn dlt-btn"
                                  startIcon={<RiDeleteBinLine />}
                                  disableRipple
                                  // title="Delete"
                                  onClick={() => handleOpen(project.id)}
                                ></Button>
                              </span>
                            </Tooltip>
                          </ThemeProvider>
                        }
                      />
                    </Box>
                    {project?.start_date && (
                      <Tooltip
                        title={
                          <div
                            style={{
                              color: "white",
                              fontSize: "13px",
                            }}
                          >
                            Development Start Date
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                          style={{
                            color: "#ff606b",
                            fontStyle: "italic",
                            fontWeight: "600",
                            marginTop: "12px",
                          }}
                        >
                          {formatDate(project?.start_date)}
                        </Box>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className="no-msg">
                <TableCell
                  colSpan={8}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "var(--textgrey)",
                    fontSize: "16px",
                  }}
                  className="no-msg"
                >
                  No Projects Found
                </TableCell>
              </TableRow>
            )}
          </Table>
          {developmentProjects?.length > 0 && (
            // <Box variant="div" component="div" className="pagination-wrapper">

            <CustomTablePaginationToolbar
              count={
                filterByIdApplied
                  ? filtersbyid?.totalprojectUserCount
                  : filterApplied
                  ? filters?.totalprojectUserCount
                  : projects?.total
              }
              page={
                filterByIdApplied
                  ? devfilterpage
                  : filterApplied
                  ? developmentAppliedFilterPage
                  : developmentCurrentPage
              }
              rowsPerPage={+rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            // </Box>
          )}
        </TableContainer>
      ),
    },
    {
      label: "Beta Testing",
      key: "beta_testing",
      content: (
        // Content for the "Development" tab
        <TableContainer className="table-listing">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="table"
            className="listing-table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Company Name</TableCell>
                <TableCell align="left">Contact Person</TableCell>
                <TableCell align="left">Project </TableCell>
                <TableCell align="left">Handlers </TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            {betaloading ? (
              Array.from({ length: 6 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                </TableRow>
              ))
            ) : betaProjects?.length > 0 ? (
              betaProjects?.map((project) => (
                <TableRow
                  //   key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">
                    <Tooltip
                      title={
                        <div style={{ color: "white", fontSize: "13px" }}>
                          {project?.projectclients?.company_name}
                        </div>
                      }
                      arrow
                      placement="bottom-start"
                    >
                      <Box
                        variant="div"
                        component="div"
                        // className="app-name d-flex"
                        style={{
                          marginBottom: "12px",
                          fontWeight: "600",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "160px",
                        }}
                      >
                        {capitalizeWords(
                          project?.projectclients?.company_name === "" ||
                            project?.projectclients?.company_name === null
                            ? "-"
                            : project?.projectclients?.company_name
                        )}
                      </Box>
                    </Tooltip>

                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.projectclients?.company_email === "" ||
                        project?.projectclients?.company_email === null ? (
                          ""
                        ) : (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                Click to Copy
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box className="companyicons">
                              <CopyToClipboard
                                onCopy={handleCopySuccess}
                                text={project?.projectclients?.company_email}
                              >
                                <RiMailLine />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip>
                        )}
                        {project?.projectclients?.company_email ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {" "}
                                {project?.projectclients?.company_email}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {project?.projectclients?.company_email}
                            </Box>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.projectclients?.company_email === "" ||
                        project?.projectclients?.company_email === null ? (
                          ""
                        ) : (
                          <Box className="companyicons">
                            <RiMailLine />
                          </Box>
                        )}
                        {project?.projectclients?.company_email ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {maskEmail(project?.projectclients?.company_email)}
                          </Box>
                        ) : (
                          "-"
                        )}
                      </Box>
                    )}
                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.projectclients?.company_phone === "" ||
                        project?.projectclients?.company_phone === null ? (
                          ""
                        ) : (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                Click to Copy
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box className="companyicons">
                              <CopyToClipboard
                                onCopy={handleCopySuccess}
                                text={project?.projectclients?.company_phone}
                              >
                                <RiPhoneLine />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip>
                        )}
                        {project?.projectclients?.company_phone ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {project?.projectclients?.company_phone}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            {" "}
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {project?.projectclients?.company_phone === "" ||
                              project?.projectclients?.company_phone === null
                                ? "-"
                                : project?.projectclients?.company_phone}
                            </Box>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.projectclients?.company_phone === "" ||
                        project?.projectclients?.company_phone === null ? (
                          ""
                        ) : (
                          <Box className="companyicons">
                            <RiPhoneLine />
                          </Box>
                        )}
                        {project?.projectclients?.company_phone ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {project?.projectclients?.company_phone === "" ||
                            project?.projectclients?.company_phone === null
                              ? "-"
                              : maskPhoneNumber(
                                  project?.projectclients?.company_phone
                                )}
                          </Box>
                        ) : (
                          "-"
                        )}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Tooltip
                      title={
                        <div style={{ color: "white", fontSize: "13px" }}>
                          {project?.client_contacts?.contact_person_name}
                        </div>
                      }
                      arrow
                      placement="bottom-start"
                    >
                      <Box
                        variant="div"
                        component="div"
                        style={{
                          marginBottom: "12px",
                          fontWeight: "600",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "160px",
                        }}
                      >
                        {capitalizeWords(
                          project?.client_contacts?.contact_person_name ===
                            "" ||
                            project?.client_contacts?.contact_person_name ===
                              null
                            ? "-"
                            : project?.client_contacts?.contact_person_name
                        )}
                      </Box>
                    </Tooltip>
                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.client_contacts?.contact_person_email ===
                          "" ||
                        project?.client_contacts?.contact_person_email ===
                          null ? (
                          ""
                        ) : (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                Click to Copy
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box className="companyicons">
                              <CopyToClipboard
                                onCopy={handleCopySuccess}
                                text={
                                  project?.client_contacts?.contact_person_email
                                }
                              >
                                <RiMailLine />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip>
                        )}
                        {project?.client_contacts?.contact_person_email ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {project?.client_contacts?.contact_person_email}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            {" "}
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {project?.client_contacts
                                ?.contact_person_email === "" ||
                              project?.client_contacts?.contact_person_email ===
                                null
                                ? "-"
                                : project?.client_contacts
                                    ?.contact_person_email}
                            </Box>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.client_contacts?.contact_person_email ===
                          "" ||
                        project?.client_contacts?.contact_person_email ===
                          null ? (
                          ""
                        ) : (
                          <Box className="companyicons">
                            <RiMailLine />
                          </Box>
                        )}
                        {project?.client_contacts?.contact_person_email ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {project?.client_contacts?.contact_person_email ===
                              "" ||
                            project?.client_contacts?.contact_person_email ===
                              null
                              ? "-"
                              : maskEmail(
                                  project?.client_contacts?.contact_person_email
                                )}
                          </Box>
                        ) : (
                          "-"
                        )}
                      </Box>
                    )}
                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.client_contacts?.contact_person_phone ===
                          "" ||
                        project?.client_contacts?.contact_person_phone ===
                          null ? (
                          ""
                        ) : (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                Click to Copy
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box className="companyicons">
                              <CopyToClipboard
                                onCopy={handleCopySuccess}
                                text={
                                  project?.client_contacts?.contact_person_phone
                                }
                              >
                                <RiPhoneLine />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip>
                        )}
                        {project?.client_contacts?.contact_person_phone ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {project?.client_contacts?.contact_person_phone}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            {" "}
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {project?.client_contacts
                                ?.contact_person_phone === "" ||
                              project?.client_contacts?.contact_person_phone ===
                                null
                                ? "-"
                                : project?.client_contacts
                                    ?.contact_person_phone}
                            </Box>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.client_contacts?.contact_person_phone ===
                          "" ||
                        project?.client_contacts?.contact_person_phone ===
                          null ? (
                          ""
                        ) : (
                          <Box className="companyicons">
                            <RiPhoneLine />
                          </Box>
                        )}
                        {project?.client_contacts?.contact_person_phone ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {project?.client_contacts?.contact_person_phone ===
                              "" ||
                            project?.client_contacts?.contact_person_phone ===
                              null
                              ? "-"
                              : maskPhoneNumber(
                                  project?.client_contacts?.contact_person_phone
                                )}
                          </Box>
                        ) : (
                          "-"
                        )}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="left" style={{ verticalAlign: "top" }}>
                    <Box
                      style={{
                        display: "flex",
                        marginBottom: "12px",
                      }}
                    >
                      <Tooltip
                        title={
                          <div style={{ color: "white", fontSize: "13px" }}>
                            {project?.project_name}
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <Box
                          variant="div"
                          component="div"
                          style={{
                            fontWeight: "600",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "160px",
                          }}
                        >
                          {capitalizeWords(
                            project?.project_name === "" ||
                              project?.project_name === null
                              ? "-"
                              : project?.project_name
                          )}
                        </Box>
                      </Tooltip>
                      {project?.tms_project_id ? (
                        <Tooltip
                          title={
                            <div
                              style={{
                                color: "white",
                                fontSize: "13px",
                              }}
                            >
                              Synced to TMS
                            </div>
                          }
                          arrow
                          placement="bottom-start"
                        >
                          <PublishedWithChangesIcon
                            style={{
                              fill: "green",
                              position: "relative",
                              top: "-2px",
                              left: "3px",
                              marginRight: "7px",
                              width: "17px",
                            }}
                          />
                        </Tooltip>
                      ) : null}
                    </Box>
                    <Box
                      variant="div"
                      component="div"
                      className="tag-wrap"
                      style={{ width: "220px", alignItems: "center" }}
                    >
                      {project?.project_technologies?.length > 0
                        ? project?.project_technologies?.map(
                            (technology, index) =>
                              index < 2 && (
                                <Tooltip
                                  title={
                                    <div
                                      style={{
                                        color: "white",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {capitalizeFirstLetter(
                                        technology?.technologies
                                          ?.technology_name
                                      )}
                                    </div>
                                  }
                                  arrow
                                  placement="bottom-start"
                                >
                                  <Box
                                    variant="div"
                                    component="div"
                                    // className="app-name d-flex lead"
                                    style={{
                                      fontWeight: "600",
                                      marginBottom: "12px",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <Chip
                                      label={
                                        technology?.technologies
                                          ?.technology_name
                                      }
                                    />
                                  </Box>
                                </Tooltip>
                              )
                          )
                        : null}
                      {project?.project_technologies?.length > 2 && (
                        <Box variant="div" component="div">
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {project?.project_technologies
                                  ?.map((technology) =>
                                    capitalizeFirstLetter(
                                      technology?.technologies?.technology_name
                                    )
                                  )
                                  .slice(2)
                                  .join(", ")}{" "}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              style={{ marginBottom: "12px" }}
                            >
                              +{" "}
                              <Typography
                                variant="body1"
                                component="span"
                                className="plus-users"
                              >
                                {project?.project_technologies?.length - 2}
                              </Typography>{" "}
                              more
                            </Typography>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      verticalAlign: "top",
                      fontWeight: "600",
                    }}
                  >
                    <Box
                      variant="div"
                      component="div"
                      style={{
                        fontWeight: "600",
                      }}
                      className="app-name d-flex"
                    >
                      Primary TL:
                      {project?.primary_tl === null ||
                      project?.primary_tl_id === null ? (
                        "-"
                      ) : (
                        <>
                          <Tooltip
                            title={
                              <div style={{ color: "white", fontSize: "13px" }}>
                                {capitalizeFirstLetter(
                                  project?.primary_tl?.first_name
                                )}{" "}
                                {capitalizeFirstLetter(
                                  project?.primary_tl?.last_name
                                )}{" "}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              style={{
                                fontWeight: "400",
                                color: "var(--primary-text-color) ",
                                fontSize: "0.925rem",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {capitalizeFirstLetter(
                                project?.primary_tl?.first_name
                              )}{" "}
                              {capitalizeFirstLetter(
                                project?.primary_tl?.last_name
                              )}{" "}
                            </Typography>
                          </Tooltip>
                        </>
                      )}
                    </Box>

                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{ marginBottom: "5px" }}
                    >
                      Primary BD:
                      {project?.primary_bda === null ||
                      project?.primary_bda_id === null ? (
                        "-"
                      ) : (
                        <>
                          <Tooltip
                            title={
                              <div style={{ color: "white", fontSize: "13px" }}>
                                {capitalizeFirstLetter(
                                  project?.primary_bda?.first_name
                                )}{" "}
                                {capitalizeFirstLetter(
                                  project?.primary_bda?.last_name
                                )}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              style={{
                                fontWeight: "400",
                                color: "var(--primary-text-color) ",
                                fontSize: "0.925rem",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {capitalizeFirstLetter(
                                project?.primary_bda?.first_name
                              )}{" "}
                              {capitalizeFirstLetter(
                                project?.primary_bda?.last_name
                              )}
                            </Typography>
                          </Tooltip>
                        </>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="left" style={{ verticalAlign: "top" }}>
                    <Box className="action-btn-wrap">
                      <ThemeProvider theme={customToolTipTheme}>
                        <Tooltip title="View">
                          <span>
                            <Button
                              variant="outlined"
                              className="action-btn view-btn"
                              startIcon={<RiEyeLine />}
                              disableRipple
                              // title="View"
                              onClick={() =>
                                navigate(`/projects/${project.id}/view`, {
                                  state: {
                                    page: currPage,
                                    rowsPerPage: rowsPerPage,
                                    status: value,
                                    selectedKeywords: selectedKeywords,
                                    selectedTechnologies: selectedTechnologies,
                                    selectedTLandBDAs: selectedTLandBDAs,
                                    text: searchText,
                                    keywordSwitchVal: keywordSwitchValue,
                                    techStackSwitchVal: techStackSwitchValue,
                                    destatus: destatus,
                                    startDate: startDate,
                                    endDate: endDate,
                                    date: bidDate,
                                    filterApplied: filterApplied,
                                    filterByIdApplied: filterByIdApplied,
                                    applyFilterId: applyFilterId,
                                    edit: isEditing,
                                  },
                                })
                              }
                            ></Button>
                          </span>
                        </Tooltip>
                      </ThemeProvider>
                      <WithPermission
                        permission={updateProjects}
                        element={
                          <ThemeProvider theme={customToolTipTheme}>
                            <Tooltip title="Edit">
                              <span>
                                <Button
                                  variant="outlined"
                                  className="action-btn edit-btn"
                                  startIcon={<RiPencilLine />}
                                  disableRipple
                                  // title="Edit"
                                  onClick={() =>
                                    navigate(`/projects/${project.id}/edit`, {
                                      state: {
                                        page: currPage,
                                        rowsPerPage: rowsPerPage,
                                        status: value,
                                        selectedKeywords: selectedKeywords,
                                        selectedTLandBDAs: selectedTLandBDAs,
                                        selectedTechnologies:
                                          selectedTechnologies,
                                        text: searchText,
                                        keywordSwitchVal: keywordSwitchValue,
                                        techStackSwitchVal:
                                          techStackSwitchValue,
                                        destatus: destatus,
                                        startDate: startDate,
                                        endDate: endDate,
                                        date: bidDate,
                                        filterApplied: filterApplied,
                                        filterByIdApplied: filterByIdApplied,
                                        applyFilterId: applyFilterId,
                                        edit: isEditing,
                                      },
                                    })
                                  }
                                ></Button>
                              </span>
                            </Tooltip>
                          </ThemeProvider>
                        }
                      />
                      <ThemeProvider theme={customToolTipTheme}>
                        <Tooltip title="Export To Pdf">
                          <span>
                            <Button
                              variant="outlined"
                              className="action-btn export-btn"
                              onClick={() => {
                                exportPdfHandler(
                                  project?.id,
                                  project?.project_name
                                );
                              }}
                              // title="Export To Pdf"
                            >
                              <img src={ExportIcon} alt="Convert Icon" />
                            </Button>
                          </span>
                        </Tooltip>
                        <Tooltip title="Copy Public Link" arrow>
                          <Button
                            variant="outlined"
                            className="action-btn copy-btn"
                            startIcon={<RiLinkM />}
                            disableRipple
                            onClick={() => copyLinkHandler(project)}
                            ref={clipboardButtonRef}
                          ></Button>
                        </Tooltip>
                      </ThemeProvider>
                      {filterByIdApplied &&
                      userRole !== "Business Executive" ? (
                        <>
                          <WithPermission
                            permission={updateProjects}
                            element={
                              <ThemeProvider theme={customToolTipTheme}>
                                <Tooltip title="Favorite">
                                  <span>
                                    <Button
                                      variant="outlined"
                                      className="action-btn dlt-btn"
                                      startIcon={
                                        starProjectMatchId?.includes(
                                          project?.id
                                        ) ? (
                                          <RiStarFill />
                                        ) : (
                                          <RiStarLine />
                                        ) // Empty star icon
                                      }
                                      onClick={() =>
                                        starProjectHandler(project.id)
                                      }
                                      disableRipple
                                      // title="Favorite"
                                    ></Button>
                                  </span>
                                </Tooltip>
                              </ThemeProvider>
                            }
                          />
                        </>
                      ) : null}
                      <WithPermission
                        permission={fullProjects}
                        element={
                          <ThemeProvider theme={customToolTipTheme}>
                            <Tooltip title="Delete">
                              <span>
                                <Button
                                  variant="outlined"
                                  className="action-btn dlt-btn"
                                  startIcon={<RiDeleteBinLine />}
                                  disableRipple
                                  // title="Delete"
                                  onClick={() => handleOpen(project.id)}
                                ></Button>
                              </span>
                            </Tooltip>
                          </ThemeProvider>
                        }
                      />
                    </Box>
                    {project?.start_date && (
                      <Tooltip
                        title={
                          <div
                            style={{
                              color: "white",
                              fontSize: "13px",
                            }}
                          >
                            Development Start Date
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                          style={{
                            color: "#ff606b",
                            fontStyle: "italic",
                            fontWeight: "600",
                            marginTop: "12px",
                          }}
                        >
                          {formatDate(project?.start_date)}
                        </Box>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className="no-msg">
                <TableCell
                  colSpan={8}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "var(--textgrey)",
                    fontSize: "16px",
                  }}
                  className="no-msg"
                >
                  No Projects Found
                </TableCell>
              </TableRow>
            )}
          </Table>
          {betaProjects?.length > 0 && (
            // <Box variant="div" component="div" className="pagination-wrapper">

            <CustomTablePaginationToolbar
              count={
                filterByIdApplied
                  ? filtersbyid?.totalprojectUserCount
                  : filterApplied
                  ? filters?.totalprojectUserCount
                  : projects?.total
              }
              page={
                filterByIdApplied
                  ? betafilterpage
                  : filterApplied
                  ? betaAppliedfilterPage
                  : betaCurrentPage
              }
              rowsPerPage={+rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            // </Box>
          )}
        </TableContainer>
      ),
    },
    {
      label: "Abandoned",
      key: "abandoned",
      content: (
        // Content for the "Development" tab
        <TableContainer className="table-listing">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="table"
            className="listing-table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Company Name</TableCell>
                <TableCell align="left">Contact Person</TableCell>
                <TableCell align="left">Project </TableCell>
                <TableCell align="left">Handlers </TableCell>
                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            {abandonedloading ? (
              Array.from({ length: 6 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width={100} height={40} />
                  </TableCell>
                </TableRow>
              ))
            ) : abandonedProjects?.length > 0 ? (
              abandonedProjects?.map((project) => (
                <TableRow
                  //   key={row.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell align="left">
                    <Tooltip
                      title={
                        <div style={{ color: "white", fontSize: "13px" }}>
                          {project?.projectclients?.company_name}
                        </div>
                      }
                      arrow
                      placement="bottom-start"
                    >
                      <Box
                        variant="div"
                        component="div"
                        // className="app-name d-flex"
                        style={{
                          marginBottom: "12px",
                          fontWeight: "600",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "160px",
                        }}
                      >
                        {capitalizeWords(
                          project?.projectclients?.company_name === "" ||
                            project?.projectclients?.company_name === null
                            ? "-"
                            : project?.projectclients?.company_name
                        )}
                      </Box>
                    </Tooltip>
                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.projectclients?.company_email === "" ||
                        project?.projectclients?.company_email === null ? (
                          ""
                        ) : (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                Click to Copy
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box className="companyicons">
                              <CopyToClipboard
                                onCopy={handleCopySuccess}
                                text={project?.projectclients?.company_email}
                              >
                                <RiMailLine />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip>
                        )}
                        {project?.projectclients?.company_email ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {" "}
                                {project?.projectclients?.company_email}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {project?.projectclients?.company_email}
                            </Box>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.projectclients?.company_email === "" ||
                        project?.projectclients?.company_email === null ? (
                          ""
                        ) : (
                          <Box className="companyicons">
                            <RiMailLine />
                          </Box>
                        )}
                        {project?.projectclients?.company_email ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {maskEmail(project?.projectclients?.company_email)}
                          </Box>
                        ) : (
                          "-"
                        )}
                      </Box>
                    )}
                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.projectclients?.company_phone === "" ||
                        project?.projectclients?.company_phone === null ? (
                          ""
                        ) : (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                Click to Copy
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box className="companyicons">
                              <CopyToClipboard
                                onCopy={handleCopySuccess}
                                text={project?.projectclients?.company_phone}
                              >
                                <RiPhoneLine />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip>
                        )}
                        {project?.projectclients?.company_phone ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {project?.projectclients?.company_phone}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            {" "}
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {project?.projectclients?.company_phone === "" ||
                              project?.projectclients?.company_phone === null
                                ? "-"
                                : project?.projectclients?.company_phone}
                            </Box>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.projectclients?.company_phone === "" ||
                        project?.projectclients?.company_phone === null ? (
                          ""
                        ) : (
                          <Box className="companyicons">
                            <RiPhoneLine />
                          </Box>
                        )}
                        {project?.projectclients?.company_phone ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {project?.projectclients?.company_phone === "" ||
                            project?.projectclients?.company_phone === null
                              ? "-"
                              : maskPhoneNumber(
                                  project?.projectclients?.company_phone
                                )}
                          </Box>
                        ) : (
                          "-"
                        )}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <Tooltip
                      title={
                        <div style={{ color: "white", fontSize: "13px" }}>
                          {project?.client_contacts?.contact_person_name}
                        </div>
                      }
                      arrow
                      placement="bottom-start"
                    >
                      <Box
                        variant="div"
                        component="div"
                        style={{
                          marginBottom: "12px",
                          fontWeight: "600",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "160px",
                        }}
                      >
                        {capitalizeWords(
                          project?.client_contacts?.contact_person_name ===
                            "" ||
                            project?.client_contacts?.contact_person_name ===
                              null
                            ? "-"
                            : project?.client_contacts?.contact_person_name
                        )}
                      </Box>
                    </Tooltip>
                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.client_contacts?.contact_person_email ===
                          "" ||
                        project?.client_contacts?.contact_person_email ===
                          null ? (
                          ""
                        ) : (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                Click to Copy
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box className="companyicons">
                              <CopyToClipboard
                                onCopy={handleCopySuccess}
                                text={
                                  project?.client_contacts?.contact_person_email
                                }
                              >
                                <RiMailLine />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip>
                        )}
                        {project?.client_contacts?.contact_person_email ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {project?.client_contacts?.contact_person_email}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            {" "}
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {project?.client_contacts
                                ?.contact_person_email === "" ||
                              project?.client_contacts?.contact_person_email ===
                                null
                                ? "-"
                                : project?.client_contacts
                                    ?.contact_person_email}
                            </Box>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.client_contacts?.contact_person_email ===
                          "" ||
                        project?.client_contacts?.contact_person_email ===
                          null ? (
                          ""
                        ) : (
                          <Box className="companyicons">
                            <RiMailLine />
                          </Box>
                        )}
                        {project?.client_contacts?.contact_person_email ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {project?.client_contacts?.contact_person_email ===
                              "" ||
                            project?.client_contacts?.contact_person_email ===
                              null
                              ? "-"
                              : maskEmail(
                                  project?.client_contacts?.contact_person_email
                                )}
                          </Box>
                        ) : (
                          "-"
                        )}
                      </Box>
                    )}
                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.client_contacts?.contact_person_phone ===
                          "" ||
                        project?.client_contacts?.contact_person_phone ===
                          null ? (
                          ""
                        ) : (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                Click to Copy
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box className="companyicons">
                              <CopyToClipboard
                                onCopy={handleCopySuccess}
                                text={
                                  project?.client_contacts?.contact_person_phone
                                }
                              >
                                <RiPhoneLine />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip>
                        )}
                        {project?.client_contacts?.contact_person_phone ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {project?.client_contacts?.contact_person_phone}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            {" "}
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {project?.client_contacts
                                ?.contact_person_phone === "" ||
                              project?.client_contacts?.contact_person_phone ===
                                null
                                ? "-"
                                : project?.client_contacts
                                    ?.contact_person_phone}
                            </Box>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {project?.client_contacts?.contact_person_phone ===
                          "" ||
                        project?.client_contacts?.contact_person_phone ===
                          null ? (
                          ""
                        ) : (
                          <Box className="companyicons">
                            <RiPhoneLine />
                          </Box>
                        )}
                        {project?.client_contacts?.contact_person_phone ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {project?.client_contacts?.contact_person_phone ===
                              "" ||
                            project?.client_contacts?.contact_person_phone ===
                              null
                              ? "-"
                              : maskPhoneNumber(
                                  project?.client_contacts?.contact_person_phone
                                )}
                          </Box>
                        ) : (
                          "-"
                        )}
                      </Box>
                    )}
                  </TableCell>

                  <TableCell align="left" style={{ verticalAlign: "top" }}>
                    <Box
                      style={{
                        display: "flex",
                        marginBottom: "12px",
                      }}
                    >
                      <Tooltip
                        title={
                          <div style={{ color: "white", fontSize: "13px" }}>
                            {project?.project_name}
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <Box
                          variant="div"
                          component="div"
                          style={{
                            fontWeight: "600",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "160px",
                          }}
                        >
                          {capitalizeWords(
                            project?.project_name === "" ||
                              project?.project_name === null
                              ? "-"
                              : project?.project_name
                          )}
                        </Box>
                      </Tooltip>
                      {project?.tms_project_id ? (
                        <Tooltip
                          title={
                            <div
                              style={{
                                color: "white",
                                fontSize: "13px",
                              }}
                            >
                              Synced to TMS
                            </div>
                          }
                          arrow
                          placement="bottom-start"
                        >
                          <PublishedWithChangesIcon
                            style={{
                              fill: "green",
                              position: "relative",
                              top: "-2px",
                              left: "3px",
                              marginRight: "7px",
                              width: "17px",
                            }}
                          />
                        </Tooltip>
                      ) : null}
                    </Box>
                    <Box
                      variant="div"
                      component="div"
                      className="tag-wrap"
                      style={{ width: "220px", alignItems: "center" }}
                    >
                      {project?.project_technologies?.length > 0
                        ? project?.project_technologies?.map(
                            (technology, index) =>
                              index < 2 && (
                                <Tooltip
                                  title={
                                    <div
                                      style={{
                                        color: "white",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {capitalizeFirstLetter(
                                        technology?.technologies
                                          ?.technology_name
                                      )}
                                    </div>
                                  }
                                  arrow
                                  placement="bottom-start"
                                >
                                  <Box
                                    variant="div"
                                    component="div"
                                    // className="app-name d-flex lead"
                                    style={{
                                      fontWeight: "600",
                                      marginBottom: "12px",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <Chip
                                      label={
                                        technology?.technologies
                                          ?.technology_name
                                      }
                                    />
                                  </Box>
                                </Tooltip>
                              )
                          )
                        : null}
                      {project?.project_technologies?.length > 2 && (
                        <Box variant="div" component="div">
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {project?.project_technologies
                                  ?.map((technology) =>
                                    capitalizeFirstLetter(
                                      technology?.technologies?.technology_name
                                    )
                                  )
                                  .slice(2)
                                  .join(", ")}{" "}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              style={{ marginBottom: "12px" }}
                            >
                              +{" "}
                              <Typography
                                variant="body1"
                                component="span"
                                className="plus-users"
                              >
                                {project?.project_technologies?.length - 2}
                              </Typography>{" "}
                              more
                            </Typography>
                          </Tooltip>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{
                      verticalAlign: "top",
                      fontWeight: "600",
                    }}
                  >
                    <Box
                      variant="div"
                      component="div"
                      style={{
                        fontWeight: "600",
                      }}
                      className="app-name d-flex"
                    >
                      Primary TL:
                      {project?.primary_tl === null ||
                      project?.primary_tl_id === null ? (
                        "-"
                      ) : (
                        <>
                          <Tooltip
                            title={
                              <div style={{ color: "white", fontSize: "13px" }}>
                                {capitalizeFirstLetter(
                                  project?.primary_tl?.first_name
                                )}{" "}
                                {capitalizeFirstLetter(
                                  project?.primary_tl?.last_name
                                )}{" "}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              style={{
                                fontWeight: "400",
                                color: "var(--primary-text-color) ",
                                fontSize: "0.925rem",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {capitalizeFirstLetter(
                                project?.primary_tl?.first_name
                              )}{" "}
                              {capitalizeFirstLetter(
                                project?.primary_tl?.last_name
                              )}{" "}
                            </Typography>
                          </Tooltip>
                        </>
                      )}
                    </Box>

                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{ marginBottom: "5px" }}
                    >
                      Primary BD:
                      {project?.primary_bda === null ||
                      project?.primary_bda_id === null ? (
                        "-"
                      ) : (
                        <>
                          <Tooltip
                            title={
                              <div style={{ color: "white", fontSize: "13px" }}>
                                {capitalizeFirstLetter(
                                  project?.primary_bda?.first_name
                                )}{" "}
                                {capitalizeFirstLetter(
                                  project?.primary_bda?.last_name
                                )}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              style={{
                                fontWeight: "400",
                                color: "var(--primary-text-color) ",
                                fontSize: "0.925rem",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {capitalizeFirstLetter(
                                project?.primary_bda?.first_name
                              )}{" "}
                              {capitalizeFirstLetter(
                                project?.primary_bda?.last_name
                              )}
                            </Typography>
                          </Tooltip>
                        </>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell align="left" style={{ verticalAlign: "top" }}>
                    <Box className="action-btn-wrap">
                      <ThemeProvider theme={customToolTipTheme}>
                        <Tooltip title="View">
                          <span>
                            <Button
                              variant="outlined"
                              className="action-btn view-btn"
                              startIcon={<RiEyeLine />}
                              disableRipple
                              // title="View"
                              onClick={() =>
                                navigate(`/projects/${project.id}/view`, {
                                  state: {
                                    page: currPage,
                                    rowsPerPage: rowsPerPage,
                                    status: value,
                                    selectedKeywords: selectedKeywords,
                                    selectedTechnologies: selectedTechnologies,
                                    selectedTLandBDAs: selectedTLandBDAs,
                                    text: searchText,
                                    keywordSwitchVal: keywordSwitchValue,
                                    techStackSwitchVal: techStackSwitchValue,
                                    destatus: destatus,
                                    startDate: startDate,
                                    endDate: endDate,
                                    date: bidDate,
                                    filterApplied: filterApplied,
                                    filterByIdApplied: filterByIdApplied,
                                    applyFilterId: applyFilterId,
                                    edit: isEditing,
                                  },
                                })
                              }
                            ></Button>
                          </span>
                        </Tooltip>
                      </ThemeProvider>
                      <WithPermission
                        permission={updateProjects}
                        element={
                          <ThemeProvider theme={customToolTipTheme}>
                            <Tooltip title="Edit">
                              <span>
                                <Button
                                  variant="outlined"
                                  className="action-btn edit-btn"
                                  startIcon={<RiPencilLine />}
                                  disableRipple
                                  // title="Edit"
                                  onClick={() =>
                                    navigate(`/projects/${project.id}/edit`, {
                                      state: {
                                        page: currPage,
                                        rowsPerPage: rowsPerPage,
                                        status: value,
                                        selectedKeywords: selectedKeywords,
                                        selectedTLandBDAs: selectedTLandBDAs,
                                        selectedTechnologies:
                                          selectedTechnologies,
                                        text: searchText,
                                        keywordSwitchVal: keywordSwitchValue,
                                        techStackSwitchVal:
                                          techStackSwitchValue,
                                        destatus: destatus,
                                        startDate: startDate,
                                        endDate: endDate,
                                        date: bidDate,
                                        filterApplied: filterApplied,
                                        filterByIdApplied: filterByIdApplied,
                                        applyFilterId: applyFilterId,
                                        edit: isEditing,
                                      },
                                    })
                                  }
                                ></Button>
                              </span>
                            </Tooltip>
                          </ThemeProvider>
                        }
                      />
                      <ThemeProvider theme={customToolTipTheme}>
                        <Tooltip title="Export To Pdf">
                          <span>
                            <Button
                              variant="outlined"
                              className="action-btn export-btn"
                              onClick={() => {
                                exportPdfHandler(
                                  project?.id,
                                  project?.project_name
                                );
                              }}
                              // title="Export To Pdf"
                            >
                              <img src={ExportIcon} alt="Convert Icon" />
                            </Button>
                          </span>
                        </Tooltip>
                        <Tooltip title="Copy Public Link" arrow>
                          <Button
                            variant="outlined"
                            className="action-btn copy-btn"
                            startIcon={<RiLinkM />}
                            disableRipple
                            onClick={() => copyLinkHandler(project)}
                            ref={clipboardButtonRef}
                          ></Button>
                        </Tooltip>
                      </ThemeProvider>
                      {filterByIdApplied &&
                      userRole !== "Business Executive" ? (
                        <>
                          <WithPermission
                            permission={updateProjects}
                            element={
                              <ThemeProvider theme={customToolTipTheme}>
                                <Tooltip title="Favorite">
                                  <span>
                                    <Button
                                      variant="outlined"
                                      className="action-btn dlt-btn"
                                      startIcon={
                                        starProjectMatchId?.includes(
                                          project?.id
                                        ) ? (
                                          <RiStarFill />
                                        ) : (
                                          <RiStarLine />
                                        ) // Empty star icon
                                      }
                                      onClick={() =>
                                        starProjectHandler(project.id)
                                      }
                                      disableRipple
                                      // title="Favorite"
                                    ></Button>
                                  </span>
                                </Tooltip>
                              </ThemeProvider>
                            }
                          />
                        </>
                      ) : null}
                      <WithPermission
                        permission={fullProjects}
                        element={
                          <ThemeProvider theme={customToolTipTheme}>
                            <Tooltip title="Delete">
                              <span>
                                <Button
                                  variant="outlined"
                                  className="action-btn dlt-btn"
                                  startIcon={<RiDeleteBinLine />}
                                  disableRipple
                                  // title="Delete"
                                  onClick={() => handleOpen(project.id)}
                                ></Button>
                              </span>
                            </Tooltip>
                          </ThemeProvider>
                        }
                      />
                    </Box>
                    {project?.start_date && (
                      <Tooltip
                        title={
                          <div
                            style={{
                              color: "white",
                              fontSize: "13px",
                            }}
                          >
                            Development Start Date
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                          style={{
                            color: "#ff606b",
                            fontStyle: "italic",
                            fontWeight: "600",
                            marginTop: "12px",
                          }}
                        >
                          {formatDate(project?.start_date)}
                        </Box>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow className="no-msg">
                <TableCell
                  colSpan={8}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "var(--textgrey)",
                    fontSize: "16px",
                  }}
                  className="no-msg"
                >
                  No Projects Found
                </TableCell>
              </TableRow>
            )}
          </Table>
          {abandonedProjects?.length > 0 && (
            // <Box variant="div" component="div" className="pagination-wrapper">

            <CustomTablePaginationToolbar
              count={
                filterByIdApplied
                  ? filtersbyid?.totalprojectUserCount
                  : filterApplied
                  ? filters?.totalprojectUserCount
                  : projects?.total
              }
              page={
                filterByIdApplied
                  ? abandonedfilterpage
                  : filterApplied
                  ? abandonedappliedfilterCurrentPage
                  : abandonedCurrentPage
              }
              rowsPerPage={+rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            // </Box>
          )}
        </TableContainer>
      ),
    },
    // Add content for other tabs in a similar fashion
  ];

  const handleOpen = (id) => {
    setProjectDelete(true);
    setProjectId(id);
  };
  function capitalizeFirstLetter(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }

  const fetchData = async (i) => {
    setLiveLoading(true);
    setDevelopmentLoading(true);
    setBetaLoading(true);
    setAbandonedLoading(true);
    try {
      await dispatch(
        fetchProjects({
          status: i,
          page:
            value === "live"
              ? liveCurrentPage
              : value === "developing"
              ? developmentCurrentPage
              : value === "beta_testing"
              ? betaCurrentPage
              : abandonedCurrentPage,
          limit: +rowsPerPage,
          query: searchProject,
        })
      ).then(() => {
        // After data is fetched, set loading to false
        setLiveLoading(false);
        setDevelopmentLoading(false);
        setBetaLoading(false);
        setAbandonedLoading(false);
      });
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching users:", error);
    }
  };
  useEffect(() => {
    if (!filterByIdApplied) {
      if (value === "") {
        setValue("live");
        fetchData("live");
        dispatch(
          fetchFiltersById({
            filterId: applyFilterId,
            status: "live",
          })
        );
      } else {
        setValue(value);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByIdApplied, value, applyFilterId, dispatch, +rowsPerPage]);

  useEffect(() => {
    setLiveLoading(true);
    setDevelopmentLoading(true);
    setBetaLoading(true);
    setAbandonedLoading(true);
    const fetchData1 = async () => {
      if (filterApplied) {
        setLiveLoading(false);
        setDevelopmentLoading(false);
        setBetaLoading(false);
        setAbandonedLoading(false);
        return;
      }
      try {
        await dispatch(
          fetchProjects({
            status: value,
            page:
              value === "live"
                ? liveCurrentPage
                : value === "developing"
                ? developmentCurrentPage
                : value === "beta_testing"
                ? betaCurrentPage
                : abandonedCurrentPage,
            limit: +rowsPerPage,
            query: searchProject,
          })
        );
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchData1().then(() => {
      setLiveLoading(false);
      setDevelopmentLoading(false);
      setBetaLoading(false);
      setAbandonedLoading(false);
    });
  }, [
    dispatch,
    liveCurrentPage,
    developmentCurrentPage,
    betaCurrentPage,
    abandonedCurrentPage,
    searchProject,
    value,
    projectId,
    rowsPerPage,
  ]);

  useEffect(() => {
    let savedSearchText = localStorage.getItem("searchProjectsQuery");
    savedSearchText = savedSearchText?.replace(/^"(.+(?="$))"$/, "$1");
    if (searchProject.trim() !== "" && searchProject !== savedSearchText) {
      if (
        liveCurrentPage > 1 ||
        developmentCurrentPage > 1 ||
        betaCurrentPage > 1 ||
        abandonedCurrentPage > 1
      ) {
        setliveCurrentPage(1);
        setdevelopmentCurrentPage(1);
        setbetaCurrentPage(1);
        setabandonedCurrentPage(1);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProject]);

  const apiCall = (i) => {
    fetchData(i);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      window.history.replaceState({}, "");
    });
    return () => {
      window.history.replaceState({}, "");
    };
  }, []);

  return (
    <>
      <Box variant="div" component="div" className="tab-wrapper user-listing">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            aria-label="basic tabs example"
            className="custom-auto"
          >
            {tabContent.map((tab, index) => {
              if (tab.label === "Favourite" && !filterByIdApplied) {
                return null; // Don't render the tab
              }
              return (
                <Tab
                  key={tab.key}
                  label={
                    filterByIdApplied ? (
                      <StyledBadge
                        badgeContent={
                          tab.label === "Live"
                            ? filtersbyid?.liveprojectcount
                            : tab.label === "Development"
                            ? filtersbyid?.developmentProjectscount
                            : tab.label === "Beta Testing"
                            ? filtersbyid?.betaProjectscount
                            : tab.label === "Abandoned"
                            ? filtersbyid?.AbandonedProjectscount
                            : tab.label === "Favourite"
                            ? filtersbyid?.favouriteprojectcount
                            : 0
                        }
                        max={9999}
                        color="success"
                      >
                        {tab.label}
                      </StyledBadge>
                    ) : filterApplied ? (
                      <StyledBadge
                        badgeContent={
                          tab.label === "Live"
                            ? filters?.liveprojectcount
                            : tab.label === "Development"
                            ? filters?.developmentProjectscount
                            : tab.label === "Beta Testing"
                            ? filters?.betaProjectscount
                            : tab.label === "Abandoned"
                            ? filters?.AbandonedProjectscount
                            : 0
                        }
                        max={9999}
                        color="success"
                      >
                        {tab.label}
                      </StyledBadge>
                    ) : (
                      <StyledBadge
                        badgeContent={
                          tab.label === "Live"
                            ? projects?.counts?.liveProjectCount
                            : tab.label === "Development"
                            ? projects?.counts?.developmentProjectsCount
                            : tab.label === "Beta Testing"
                            ? projects?.counts?.betaProjectsCount
                            : tab.label === "Abandoned"
                            ? projects?.counts?.abandonedProjectsCount
                            : 0
                        }
                        max={9999}
                        color="success"
                      >
                        {tab.label}
                      </StyledBadge>
                    )
                  }
                  value={tab.key}
                  onClick={() => handleChange(tab.key)}
                  style={{
                    // Apply your styling here
                    color: "black",
                    padding: "8px 25px",
                    cursor: "pointer",
                    fontSize: "16px",
                    textTransform: "capitalize",
                    // Add more styles as needed
                  }}
                />
              );
            })}
          </Tabs>
        </Box>

        {tabContent.map((tab, index) => (
          <CustomTabPanel key={tab.key} value={value} index={tab.key}>
            {tab.content}
          </CustomTabPanel>
        ))}
      </Box>
      <DeleteModal
        projectDelete={projectDelete}
        projectId={projectId}
        limit={limit}
        handleClose={handleClose}
        liveCurrentPage={liveCurrentPage}
        developmentCurrentPage={developmentCurrentPage}
        abandonedCurrentPage={abandonedCurrentPage}
        betaCurrentPage={betaCurrentPage}
        projects={projects}
        value={value}
        filterByIdApplied={filterByIdApplied}
        applyFilterId={applyFilterId}
        filterApplied={filterApplied}
        searchProject={searchProject}
      />
      <ExportModal
        exportCancel={exportCancel}
        setExportCancel={setExportCancel}
        exportProjectId={exportProjectId}
        exportProjectName={exportProjectName}
        status={value}
        limit={limit}
        filterByIdApplied={filterByIdApplied}
        applyFilterId={applyFilterId}
        filterApplied={filterApplied}
        searchText={searchText}
        selectedKeywords={selectedKeywords}
        selectedTechnologies={selectedTechnologies}
      />
    </>
  );
};

export default ProjectList;

import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { deleteProjectsById } from "../../store/slices/deleteProjectById";
import { useDispatch } from "react-redux";
import { fetchProjects } from "../../store/slices/projectSlice";
import { fetchFiltersById } from "../../store/slices/filterByIdSlice";
import { fetchFilters } from "../../store/slices/filterSlice";
import { successToast } from "../../responses/successToast";
const DeleteModal = ({
  projectDelete,

  projectId,
  value,
  filterByIdApplied,
  applyFilterId,
  filterApplied,
  limit,
  handleClose,
}) => {
  const dispatch = useDispatch();

  const submitHandler = () => {
    if (filterByIdApplied) {
      dispatch(deleteProjectsById({ projectId }))
        .unwrap()
        .then((result) => {
          const { success } = result;

          if (success) {
            successToast("Project deleted successfully");
            dispatch(
              fetchFiltersById({
                filterId: applyFilterId,
                status: value,
              })
            );
            handleClose();
          } else {
          }
        })
        .catch((error) => {
          console.error("Failed to delete user:", error);
        });
    } else if (filterApplied) {
      dispatch(deleteProjectsById({ projectId }))
        .unwrap()
        .then((result) => {
          const { success } = result;

          if (success) {
            successToast("Project deleted successfully");
            dispatch(fetchFilters({ filter_type: "projects" }));
            handleClose();
          } else {
          }
        })
        .catch((error) => {
          console.error("Failed to delete user:", error);
        });
    } else {
      dispatch(deleteProjectsById({ projectId }))
        .unwrap()
        .then((result) => {
          const { success } = result;

          if (success) {
            successToast("Project deleted successfully");
            dispatch(
              fetchProjects({
                status: value,
                page: "",
                limit: limit,
              })
            );

            handleClose();
          } else {
          }
        })
        .catch((error) => {
          console.error("Failed to delete user:", error);
        });
    }
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={projectDelete}
        closeAfterTransition
      >
        <Fade in={projectDelete}>
          <Box className="modal-wrapper">
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3">
                Do you want to delete this project?
              </Typography>
              <Typography variant="body1" component="p">
                This action can’t be undone.
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body"></Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={submitHandler}
                >
                  Delete
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default DeleteModal;

import { Box, Fade, Modal, Typography } from "@mui/material";
import React  from "react";
import Button from "@mui/material/Button";

const ProjectEmailAlertModal = ({
  sameEmailMessage,
  emailOpen,
  setEmailOpen,
  setCompEmail,
  compEmail,
}) => {
  //   const submitHandler = () => {
  //     // Perform actions specific to the "Convert" button click here
  //     onSubmit(); // Call the onSubmit function passed from the parent component
  //   };

  //   const cancelHandler = () => {
  //     setConvertCancel(false); // Close the modal when "Cancel" button is clicked
  //   };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={emailOpen}
        // onClose={ConvertLeadToProjectModalClose}
        closeAfterTransition
      >
        <Fade in={emailOpen}>
          <Box className="modal-wrapper">
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3">
                Following projects have been found with the same email. :{" "}
              </Typography>
              <Typography variant="h3" component="h3">
                {compEmail}
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body">
              <ol>
                {sameEmailMessage?.data?.map((person, index) => (
                  <li key={index} style={{ marginTop: "12px" }}>
                    <Typography variant="body1" component="span">
                      <a
                        href={`/projects/${person?.project_id}/view`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#232323" }}
                      >
                        {person.project_name}&nbsp;&#40;
                      </a>
                    </Typography>

                    <Typography
                      variant="body1"
                      component="span"
                      style={{ fontWeight: "600" }}
                    >
                      Client:&nbsp;
                    </Typography>
                    <Typography variant="body1" component="span">
                      <a
                        href={`/clients/${person?.id}/view`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#232323" }}
                      >
                        {person.company_name}&#41;
                      </a>
                    </Typography>
                  </li>
                ))}
              </ol>
            </Box>
            <Box variant="div" component="div" className="body">
              <Typography
                variant="h3"
                component="h3"
                style={{ marginTop: "8px" }}
              >
                Do you want to continue?
              </Typography>
            </Box>

            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={() => {
                    setEmailOpen(false);
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => {
                    setEmailOpen(false);
                    setCompEmail("");
                  }}
                >
                  No
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ProjectEmailAlertModal;

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import feedbackReducer from './slices/feedbackSlice';
import createLeadReducer from './slices/createLeadSlice';
import keywordReducer from './slices/keywordSlice';
import technologyReducer from './slices/technologySlice';
import leadsbyIdReducer from './slices/leadbyIdSlice';
import feedbacksReducer from './slices/getFeedbackSlice';
import leadReducer from './slices/leadSlice';
import deleteLeadByIdReducer from './slices/deleteLeadByIdSlice';
import creatProjectReducer from './slices/createProjectSlice';
import projectReducer from './slices/projectSlice';
import editLeadByIdReducer from './slices/leadEditByIdSlice';
import projectByIdReducer from './slices/projectbyIdSlice';
import userReducer from './slices/userSlice';
import userByIdReducer from './slices/userbyIdSlice';
import deleteprojectByIdReducer from './slices/deleteProjectById';
import activityReducer from './slices/activitylogSlice';
import createActivityReducer from './slices/cresteActivitySlice';
import editProjectByIdReducer from './slices/editProject';
import deleteKeywordByIdReducer from './slices/deleteKeywordSlice';
import deleteTechnologyByIdReducer from './slices/deleteTechnologyByIdSlice';
import createKeywordReducer from './slices/createKeywordSlice';
import createTechnologyReducer from './slices/createTechnologySlice';
import editKeywordReducer from './slices/editKeywordSlice';
import editTechnologyReducer from './slices/editTechnologySlice';
import convertLeadReducer from './slices/convertLeadSlice';
import createFilterReducer from './slices/createFilterSlice';
import filterReducer from './slices/filterSlice';
import filterByIdReducer from './slices/filterByIdSlice';
import editFilterByIdReducer from './slices/editFilterSlice';

import createClientReducer from './slices/createClientSlice';
import clientReducer from './slices/clientSlice';
import clientByIdReducer from './slices/clientbyIdSlice';
import contactReducer from './slices/contactSlice';
import authReducer from './slices/authSlice';
import sidebarReducer from './slices/drawerHandlerSlice.js';
import logoutReducer from './slices/logoutSlice';
import clientLeadReducer from './slices/clientLeadSlice';
import createLeadFilterReducer from './slices/createLeadFilterSlice';
import filterLeadReducer from './slices/leadFilterSlice';
import filterLeadByIdReducer from './slices/leadFilterByIdSlice';
import editLeadFilterByIdReducer from './slices/editLeadFilterSlice';
import editClientByIdReducer from './slices/clientEditBySlice.js';
import deleteClientByIdReducer from './slices/deleteClientByIdSlice';
import createContactReducer from './slices/createContactSlice';
import editContactByIdReducer from './slices/editContactByIdSlice';
import contactByIdReducer from './slices/contactByIdSlice';
import deleteContactByIdReducer from './slices/deleteContactByIdSlice';
import createShareFilterReducer from './slices/sharedFilterSlice';
import createProjectActivityReducer from './slices/createProjectActivitySlice';
import projectActivityReducer from './slices/projectActivityLogSlice';
import getPublicLinkByIdReducer from './slices/publicLinkSlice';
import favouriteFilterReducer from './slices/favouriteProjectSlice';
import permissionReducer from './slices/permissionSlice';
import keywordbyIdReducer from './slices/keywordbyIdSlice';
import technologyByIdReducer from './slices/technologybyIdSlice';
import BidReducer from './slices/bidSlice';
import bidByIdReducer from './slices/bidByIdSlice';
import createBidReducer from './slices/createBidSlice';
import deleteBidByIdReducer from './slices/deleteBidByIdSlice';
import editBidByIdReducer from './slices/editBidByIdSlice.js';
import reportReducer from './slices/reportSlice.js';
import bidFilterReducer from './slices/bidFilterSlice';
import releaseNotesReducer from './slices/releaseNotesSlice';
import releaseNotesDescReducer from './slices/releaseNotesDescSlice';
import profileReducer from './slices/searchProfileSlice.js';
import convertBidReducer from './slices/convertBidSlice';
import bidReportReducer from './slices/bidReportSlice';
import leadReportReducer from './slices/leadReportSlice';
import activityReportReducer from './slices/activityReportSlice';
import deleteProjectActivityByIdReducer from './slices/deleteProjectActivitySlice';
import deleteLeadActivityByIdReducer from './slices/deleteLeadActivitySlice';
import activityByIdReducer from './slices/activityByIdSlice';
import editActivityByIdReducer from './slices/editActivitySlice.js';
import leadActivityByIdReducer from './slices/leadActivitybyIdSlice.js';
import editLeadActivityByIdReducer from './slices/editLeadActivitySlice.js';
import createSettingReducer from './slices/createAcitivitySetting.js';
import settingSliceReducer from './slices/getReminderSlice.js';
import userRolesReducer from './slices/userRoleSlice';
import leadAllocationReportReducer from './slices/leadAllocationReportSlice.js';
import createLeadSettingReducer from './slices/leadSettingSlice.js';
import leadSettingReducer from './slices/getLeadSettingSlice.js';
import applicationPermissionReducer from './slices/applicationPermissionSlice.js';
import announcementReducer from "./slices/announcementSlice.js";
import userListReducer from "./slices/listAuthorSlice.js";
import createTemplateCVReducer from './slices/cv-builder/createTemplateCVSlice.js';
import templatesReducer from './slices/cv-builder/templateSlice.js';
import CVsReducer from './slices/cv-builder/CVSlice.js';
import templateByIdReducer from './slices/cv-builder/templateByIdSlice.js';
import deleteTemplateCVByIdReducer from './slices/cv-builder/deleteTemplateCVbyIdSlice.js';
import editTemplateCVByIdReducer from './slices/cv-builder/editTemplateCVbyIdSlice.js';
import thunk from 'redux-thunk';

const rootReducer = combineReducers({
  createLead: createLeadReducer,
  keywords: keywordReducer,
  technologies: technologyReducer,
  leadsbyId: leadsbyIdReducer,
  leads: leadReducer,
  feedbacks: feedbacksReducer,
  deleteLeadById: deleteLeadByIdReducer,
  createProject: creatProjectReducer,
  projects: projectReducer,
  editLeadById: editLeadByIdReducer,
  createKeyword: createKeywordReducer,
  feedback: feedbackReducer,
  users: userReducer,
  usersById: userByIdReducer,
  projectsById: projectByIdReducer,
  deleteprojectsById: deleteprojectByIdReducer,
  activities: activityReducer,
  createactivity: createActivityReducer,
  editprojectById: editProjectByIdReducer,
  deleteKeywordById: deleteKeywordByIdReducer,
  deleteTechnologyById: deleteTechnologyByIdReducer,
  createTechnology: createTechnologyReducer,
  editKeyword: editKeywordReducer,
  editTechnology: editTechnologyReducer,
  convertLead: convertLeadReducer,
  createFilter: createFilterReducer,
  filters: filterReducer,
  filtersById: filterByIdReducer,
  editFilterById: editFilterByIdReducer,
  createClient: createClientReducer,
  clients: clientReducer,
  clientsById: clientByIdReducer,
  contacts: contactReducer,
  auth: authReducer,
  sidebar: sidebarReducer,
  logout: logoutReducer,
  clientsLead: clientLeadReducer,
  createLeadFilter: createLeadFilterReducer,
  filtersLead: filterLeadReducer,
  filtersLeadById: filterLeadByIdReducer,
  editLeadFilterById: editLeadFilterByIdReducer,
  editClientById: editClientByIdReducer,
  deleteClientById: deleteClientByIdReducer,
  createContact: createContactReducer,
  editContactById: editContactByIdReducer,
  contactById: contactByIdReducer,
  deleteContactById: deleteContactByIdReducer,
  createShareFilter: createShareFilterReducer,
  createProjectActivity: createProjectActivityReducer,
  projectActivities: projectActivityReducer,
  linksbyId: getPublicLinkByIdReducer,
  favouriteFilter: favouriteFilterReducer,
  permissions: permissionReducer,
  keywordsbyId: keywordbyIdReducer,
  technologiesbyId: technologyByIdReducer,
  bids: BidReducer,
  bidsbyId: bidByIdReducer,
  createBid: createBidReducer,
  deleteBidById: deleteBidByIdReducer,
  editBidById: editBidByIdReducer,
  reports: reportReducer,
  bidfilters: bidFilterReducer,
  releaseNotes: releaseNotesReducer,
  releaseNotesDesc: releaseNotesDescReducer,
  profiles: profileReducer,
  convertBid: convertBidReducer,
  bidReports: bidReportReducer,
  activityreports: activityReportReducer,
  deleteProjectActivityById: deleteProjectActivityByIdReducer,
  deleteLeadActivityById: deleteLeadActivityByIdReducer,
  activitybyId: activityByIdReducer,
  editActivityById: editActivityByIdReducer,
  leadactivitybyId: leadActivityByIdReducer,
  editLeadActivityById: editLeadActivityByIdReducer,
  createSetting: createSettingReducer,
  settings: settingSliceReducer,
  leadReports: leadReportReducer,
  userRoles: userRolesReducer,
  leadreports: leadAllocationReportReducer,
  createLeadSetting: createLeadSettingReducer,
  leadsetiing: leadSettingReducer,
  applicationPermissions: applicationPermissionReducer,
  createTemplateCV: createTemplateCVReducer,
  templates: templatesReducer,
  templatebyId: templateByIdReducer,
  CVs: CVsReducer,
  deleteTemplateCVById: deleteTemplateCVByIdReducer,
  editTemplateCVById: editTemplateCVByIdReducer,
  announcements: announcementReducer,
  userList: userListReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export default store;

import { callApi } from "../../components/common/commonfunctionforapi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const fetchActivities = createAsyncThunk(
  "activities/fetchActivities",
  async ({ leadId, state }, { rejectWithValue }) => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/activity/${leadId}`,
        params: {
          state: state,
        },
      });
      // console.log(response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  activities: [],
  status: "idle",
  error: null,
};
const activitySlice = createSlice({
  name: "activities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivities.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchActivities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.activities = action.payload;
      })
      .addMatcher(
        // Handle both rejected and fulfilled states with the same action
        (action) => action.type.startsWith("activities/fetchActivities/"),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            // Handle the rejected state with the defined value
            state.status = "failed";
            state.error = action.payload.message;
          }
        }
      );
  },
});

export default activitySlice.reducer;

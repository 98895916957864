import React, { useEffect, useRef } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "rsuite";
import Select from "react-select";
import { RiArrowLeftLine } from "react-icons/ri";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBidsById } from "../../store/slices/bidByIdSlice";
import { fetchUsers } from "../../store/slices/userSlice";
import { fetchKeywords } from "../../store/slices/keywordSlice";
import { fetchTechnologies } from "../../store/slices/technologySlice";
import { editBidById } from "../../store/slices/editBidByIdSlice";
import CreatableSelect from "react-select/creatable";
import { successToast } from "../../responses/successToast";
// import { errorToast } from "../../responses/errorToast";
import AddKeywordModal from "../../components/common/addKeywordModal";
import AddTechnologyModal from "../../components/common/addTechnologySlice";
import { fetchProfiles } from "../../store/slices/searchProfileSlice";
import Autocomplete from "@mui/material/Autocomplete";

const leadSourceOptions = [
  { value: "Upwork", label: "Upwork" },
  { value: "LinkedIn", label: "LinkedIn" },
  { value: "Guru", label: "Guru" },
  { value: "PPH", label: "PPH" },
  { value: "Fiverr", label: "Fiverr" },
  { value: "Clutch", label: "Clutch" },
  { value: "Website", label: "Website" },
  { value: "Email", label: "Email" },
  { value: "Skype", label: "Skype" },
  { value: "Recommendation", label: "Recommendation" },
  { value: "Freelancer.com", label: "Freelancer.com" },
];

const bidOptions = [
  { value: "Fulltime", label: "Full-Time" },
  { value: "Parttime", label: "Part-Time" },
];

const billingOptions = [
  { value: "Hourly", label: "Hourly" },
  { value: "Fixedcost", label: "Fixed-Cost" },
];

const BidEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
       page: 1,
      rowsPerPage: 10,
      filteredPage: 1,
      filterApplied: false,
      bd: "",
      keywords: "",
      technologies: "",
      keywordPayload: "",
      technologyPayload: "",
      platform: "",
      billingtype: "",
      bidtype: "",
      bidDate: "",
      startDate: "",
      endDate: "",
      text: "",
      converted: "",
      keywordSwitchVal: true,
      techStackSwitchVal: true,
    };
  }
  const { bidId } = useParams();
  const bids = useSelector((state) => state.bidsbyId.bids);
  const [bd, setBd] = useState("");
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [platform, setPlatform] = useState("");
  const [bidtype, setBidType] = useState("");
  const [billingtype, setBillingType] = useState("");
  const [clientbudget, setClientBudget] = useState("");
  const [bidValue, setBidValue] = useState("");
  const [bidDate, setBidDate] = useState("");
  const [responseDate, setResponseDate] = useState("");
  const [closureDate, setClosureDate] = useState("");
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [keywordPayload, setKeywordPayload] = useState([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const [technologyPayload, setTechnologyPayload] = useState([]);
  const [bderror, setBDError] = useState("");
  const [platformerror, setPlatformError] = useState("");
  const [invalidValue, setInvalidValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [techLoading, setTechLoading] = useState(false);
  const [addTechModal, setAddTechModal] = useState(false);
  const [validTechName, setValidTechName] = useState("");
  const [urlerror, setUrlError] = useState("");
  const [bidDateError, setBidDateError] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [profile, setProfile] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [responseerror, setResponseError] = useState("");
  const [biderror, setBidError] = useState("");
  const [titlerror, setTitleError] = useState("");
  const [reserror, setResError] = useState("");
  const [error, setError] = useState("");

  const keywords = useSelector((state) => state.keywords.keywords);
  const technologies = useSelector((state) => state.technologies.technologies);
  const users = useSelector((state) => state.users.users);
  const BDA = users?.filteredBdaAndBde;

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    date.setHours(date.getHours() + 5);
    date.setMinutes(date.getMinutes() + 30);
    return date;
  }

  const keywordsData = keywords?.allKeywords?.map((keyword) => ({
    value: keyword.id,
    label: keyword.name,
  }));

  const handleKeywordSelect = (selectedOptions) => {
    setSelectedKeywords(selectedOptions);

    const selectedIds = selectedOptions.map((option) => option.value);
    setKeywordPayload(selectedIds);
  };

  const handleTechnologySelect = (selectedOptions) => {
    setSelectedTechnologies(selectedOptions);

    const selectedIds = selectedOptions.map((option) => option.value);

    setTechnologyPayload(selectedIds);
  };

  const technologiesData = technologies?.allTechnologies?.map((technology) => ({
    value: technology.id,
    label: technology.name,
  }));

  function capitalizeWords(input) {
    return input?.replace(/\w+/g, function (word) {
      return word?.charAt(0).toUpperCase() + word.slice(1);
    });
  }

  const BDAOptions = BDA?.map((tl) => ({
    value: tl.id,
    label: `${capitalizeWords(tl.first_name)} ${capitalizeWords(
      tl.last_name
    )} (${tl.role_name})`,
  }));

  const handleBDASelect = (selectedOption) => {
    setBd(selectedOption);
    setBDError("");
  };

  const handlePlatform = (selectedOption) => {
    setPlatform(selectedOption);
  };

  const handleBid = (selectedOption) => {
    setBidType(selectedOption);
  };

  const handleBilling = (selectedOption) => {
    setBillingType(selectedOption);
  };

  const handleBidDateChange = (date) => {
    if (date) {
      setError("");
      setBidError("");
      setResError("");
    }
    setBidDate(date);
    if (date !== "" || date !== "") {
      setBidDateError("");
    }
  };

  const handleResponseDateChange = (date) => {
    if (date) {
      setError("");
      setResError("");
      setResponseError("");
    }
    setResponseDate(date);
  };

  const handleClosureDateChange = (date) => {
    if (date) {
      setResponseError("");
      setBidError("");
      setError("");
    }
    setClosureDate(date);
  };

  const handleCreate = (inputValue) => {
    setIsLoading(true);
    setInvalidValue(inputValue);

    setTimeout(() => {
      setIsLoading(false);
      setShowModal(true);
    }, 1000);
  };

  const handleTechCreate = (inputValue) => {
    setTechLoading(true);
    setValidTechName(inputValue);

    setTimeout(() => {
      setTechLoading(false);

      setAddTechModal(true);
    }, 1000);
  };


  const [saveButtonClickCount, setSaveButtonClickCount] = useState(0);
  const bdRef = useRef(null);
  const platformRef = useRef(null);
  const titleRef = useRef(null);
  const urlRef = useRef(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (bderror && bdRef.current) {
      bdRef.current.focus();
    }
  }, [bdRef, bderror, saveButtonClickCount]);

  useEffect(() => {
    if (platformerror && platformRef.current) {
      platformRef.current.focus();
    }
  }, [platformRef, platformerror, saveButtonClickCount]);

  useEffect(() => {
    if (titlerror && titleRef.current) {
      titleRef.current.focus();
    }
  }, [titleRef, titlerror, saveButtonClickCount]);

  useEffect(() => {
    if (urlerror && urlRef.current) {
      urlRef.current.focus();
    }
  }, [urlRef, urlerror, saveButtonClickCount]);

  const submitHandler = async () => {
    setSaveButtonClickCount((prevCount) => prevCount + 1);
    let flag = 0;
    if (isButtonDisabled) {
      return;
    }
    if (!bd) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setBDError("Please select BD");
      flag = 1;
    }

    if (!platform) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setPlatformError("Please select platform");
      flag = 1;
    }

    if (!title) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setTitleError("Please enter bid title");
      flag = 1;
    }

    if (!url) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setUrlError("Please enter URL");
      flag = 1;
    }

    if (bidDate === "" || bidDate === null) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setBidDateError("Please enter bid date");
      flag = 1;
    }

    if (
      responseDate &&
      closureDate &&
      bidDate > closureDate &&
      bidDate > responseDate
    ) {
      setError(
        "Bid date should be  smaller than response date and closure date"
      );
      flag = 1;
    }

    if (responseDate && bidDate > responseDate) {
      setResError("Bid date should be smaller than response date");
      flag = 1;
    }

    if (closureDate && responseDate > closureDate) {
      setResponseError("Response date should be  smaller than closure date ");

      flag = 1;
    }

    if (closureDate && bidDate > closureDate) {
      setBidError("Bid date should be smaller than closure date");

      flag = 1;
    }
    if (flag !== 0) {
      return;
    }
    setError("");
    setResponseError("");
    setBidError("");
    setResError("");

    setIsButtonDisabled(true);

    const bidData = {
      bd_id: bd,
      title: title,
      url: url,
      platform: platform,
      bid_type: bidtype === "" ? null : bidtype,
      billing_type: billingtype,
      client_budget: clientbudget,
      bid_value: bidValue,
      keyword_ids: keywordPayload,
      technology_ids: technologyPayload,
      bid_date: bidDate ? formatDate(bidDate) : "",
      response_date: responseDate ? formatDate(responseDate) : "",
      closure_date: closureDate ? formatDate(closureDate) : "",
      bid_profile: selectedProfile,
    };
    try {
      const response = await dispatch(editBidById(bidId, bidData));

      if (response.success) {
        navigate("/bids");
        successToast("Bid updated successfully!");
      } else {
        console.error("Error creating bid:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      setIsButtonDisabled(false);
    }
  };

  useEffect(() => {
    dispatch(fetchBidsById({ bidId }));
  }, [dispatch, bidId]);

  useEffect(() => {
    dispatch(fetchUsers({ page: "", query: "", limit: "" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchKeywords({ query: "" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchTechnologies({ query: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (bids) {
      setBd(bids?.bd_id);
      setPlatform(bids?.platform);
      if (bids?.title === null || bids?.title === "") {
        setTitle("");
      } else {
        setTitle(bids?.title);
      }
      if (bids?.url === null || bids?.url === "") {
        setUrl("");
      } else {
        setUrl(bids?.url);
      }

      setBidType(bids?.bid_type);
      setBillingType(bids?.billing_type);
      if (bids?.client_budget === null || bids?.client_budget === "") {
        setClientBudget("");
      } else {
        setClientBudget(bids?.client_budget);
      }
      if (bids?.bid_value === null || bids?.bid_value === "") {
        setBidValue("");
      } else {
        setBidValue(bids?.bid_value);
      }

      const bidDate = new Date(bids?.bid_date);
      const responseDate = new Date(bids?.response_date);
      const closureDate = new Date(bids?.closure_date);

      // Function to reduce hours and minutes
      // const reduceHoursAndMinutes = (date) => {
      //   date.setHours(date.getHours() - 5); // Reduce 5 hours
      //   date.setMinutes(date.getMinutes() - 30); // Reduce 30 minutes
      // };

      if (bids?.bid_date === null) {
        setBidDate("");
      } else {
        // reduceHoursAndMinutes(bidDate);
        setBidDate(bidDate);
      }

      if (bids?.response_date === null) {
        setResponseDate("");
      } else {
        //reduceHoursAndMinutes(responseDate);
        setResponseDate(responseDate);
      }

      if (bids?.closure_date === null) {
        setClosureDate("");
      } else {
        // reduceHoursAndMinutes(closureDate);
        setClosureDate(closureDate);
      }

      const initialKeywordOptions = bids?.bid_keywords?.map((keywordName) => ({
        value: keywordName?.keywords?.id,
        label: keywordName?.keywords?.keyword_name,
        id: keywordName?.id,
      }));

      setSelectedKeywords(initialKeywordOptions);
      setKeywordPayload(initialKeywordOptions.value);

      const initialTechnologyOptions = bids?.bid_technologies?.map(
        (techName) => ({
          value: techName?.technologies?.id,
          label: techName?.technologies?.technology_name,
          id: techName?.id,
        })
      );

      setSelectedTechnologies(initialTechnologyOptions);
      setTechnologyPayload(initialTechnologyOptions.value);

      if (bids?.bid_profile === null || bids?.bid_profile === "") {
        setSelectedProfile("");
        setProfile("");
      } else {
        setSelectedProfile(bids?.bid_profile);
        setProfile(bids?.bid_profile);
      }
    }
  }, [bids]);

  const handleProfileChange = async (value) => {
    setProfile(value);

    try {
      const action = await dispatch(fetchProfiles({ search: value }));

      if (fetchProfiles.fulfilled.match(action)) {
        const data = action.payload;

        if (Array.isArray(data)) {
          setOptions(data);
        } else {
          console.error("API response is not an array:", data);
          setOptions([]);
        }

        // Check if the selected profile exists in the suggestions
        const selectedOption = data.find(
          (option) => `${option.first_name} ${option.last_name}` === value
        );

        if (selectedOption) {
          // If selected profile is from suggestions, use its value
          setSelectedProfile(selectedOption);
        } else {
          // If it's a new profile, use the typed value
          setSelectedProfile(value);
        }
      }
    } catch (error) {
      console.error("Error fetching profiles:", error);
      setOptions([]);
    }
  };

  useEffect(() => {
    if (billingtype === "Fixedcost") {
      setBidType("");
    }
  }, [billingtype]);


  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() =>
              navigate("/bids", {
                state: {
                  page: state.page,
                  rowsPerPage:state.rowsPerPage,
                  filteredPage: state.filteredPage,
                  filterApplied: state.filterApplied,
                  bd: state.bd,
                  platform: state.platform,
                  bidtype: state.bidtype,
                  billingtype: state.billingtype,
                   keywordSwitchVal: state.keywordSwitchVal,
                  techStackSwitchVal: state.techStackSwitchVal,
                  bidDate: state.bidDate,
                  startDate: state.startDate,
                  endDate: state.endDate,
                  keywordPayload: state.keywordPayload,
                  technologyPayload: state.technologyPayload,
                  keywords: state.keywords,
                  technologies: state.technologies,
                  text: state.text,
                  converted: state.converted,
                },
              })
            }
          ></Button>
          Edit Bid
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Box className="card-wrapper">
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="h3"
                component="h3"
                className="card-title"
              >
                Bid Details
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                BD
                <Typography
                  variant="body1"
                  component="span"
                  className="asterisk"
                >
                  *
                </Typography>
              </Typography>
              <Select
                name="colors"
                className="muilt-select-field"
                options={BDAOptions}
                ref={bdRef}
                value={
                  BDAOptions?.find((option) => option?.value === bd) || null
                }
                onChange={(selectedOption) =>
                  handleBDASelect(selectedOption?.value)
                }
                classNamePrefix="select"
                placeholder="Select BD"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
              />
              {bderror && (
                <Typography variant="body1" component="p" className="error">
                  {bderror}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12} className="empty-grid-field"></Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                Platform
                <Typography
                  variant="body1"
                  component="span"
                  className="asterisk"
                >
                  *
                </Typography>
              </Typography>
              <Select
                name="colors"
                className="muilt-select-field"
                options={leadSourceOptions}
                ref={platformRef}
                value={
                  leadSourceOptions?.find(
                    (option) => option?.value === platform
                  ) || null
                }
                onChange={(selectedOption) =>
                  handlePlatform(selectedOption?.value)
                }
                classNamePrefix="select"
                placeholder="Select Platform"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
              />
              {platformerror && (
                <Typography variant="body1" component="p" className="error">
                  {platformerror}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                Profile Used
                {/* <Typography
                  variant="body1"
                  component="span"
                  className="asterisk"
                >
                  *
                </Typography> */}
              </Typography>
              <Autocomplete
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={options.map(
                  (option) => `${option.first_name} ${option.last_name}`
                )}
                value={selectedProfile} // Set the selected profile directly
                onChange={(event, newValue) => setSelectedProfile(newValue)}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                    placeholder="Type to search..."
                    onChange={(e) => handleProfileChange(e.target.value)}
                  />
                )}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                Bid Title
                <Typography
                  variant="body1"
                  component="span"
                  className="asterisk"
                >
                  *
                </Typography>
              </Typography>
              <TextField
                className="input-field"
                fullWidth
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                placeholder="Enter Title"
                value={title}
                inputRef={titleRef}
                onChange={(e) => {
                  setTitle(e.target.value);
                  if (e.target.value) {
                    setTitleError("");
                  }
                }}
              />
              {titlerror && (
                <Typography variant="body1" component="p" className="error">
                  {titlerror}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                URL
                <Typography
                  variant="body1"
                  component="span"
                  className="asterisk"
                >
                  *
                </Typography>
              </Typography>
              <TextField
                className="input-field"
                fullWidth
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                placeholder="Enter URL"
                value={url}
                inputRef={urlRef}
                onChange={(e) => {
                  setUrl(e.target.value);
                  if (e.target.value) {
                    setUrlError("");
                  }
                }}
                // type="number"
              />
              {urlerror && (
                <Typography variant="body1" component="p" className="error">
                  {urlerror}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                Keywords
              </Typography>
              <CreatableSelect
                isClearable
                isDisabled={isLoading}
                isLoading={isLoading}
                isMulti
                onCreateOption={handleCreate}
                name="colors"
                className="muilt-select-field"
                options={keywordsData}
                classNamePrefix="select"
                placeholder="Select Keywords"
                onChange={handleKeywordSelect}
                value={selectedKeywords}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                Technology Stacks
              </Typography>
              <CreatableSelect
                isClearable
                isDisabled={techLoading}
                isLoading={techLoading}
                isMulti
                onCreateOption={handleTechCreate}
                name="colors"
                className="muilt-select-field"
                classNamePrefix="select"
                placeholder="Select Technologies"
                options={technologiesData}
                onChange={handleTechnologySelect}
                value={selectedTechnologies}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                Billing Type
              </Typography>
              <Select
                name="colors"
                className="muilt-select-field"
                classNamePrefix="select"
                placeholder="Select Billing Type"
                options={billingOptions}
                value={
                  billingOptions?.find(
                    (option) => option?.value === billingtype
                  ) || null
                }
                onChange={(selectedOption) =>
                  handleBilling(selectedOption?.value)
                }
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                Bid Type
              </Typography>
              <Select
                name="colors"
                className="muilt-select-field"
                options={bidOptions}
                value={
                  bidOptions?.find((option) => option?.value === bidtype) ||
                  null
                }
                onChange={(selectedOption) =>
                  handleBid(selectedOption?.value)
                }
                isDisabled={billingtype === "Fixedcost"}
                classNamePrefix="select"
                placeholder="Select Bid Type"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                Client Budget
              </Typography>
              <TextField
                className="input-field"
                fullWidth
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                placeholder="Enter Client Budget"
                value={clientbudget}
                onChange={(e) => setClientBudget(e.target.value)}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                Bid Value
              </Typography>
              <TextField
                className="input-field"
                fullWidth
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                placeholder="Enter Bid Value"
                value={bidValue}
                onChange={(e) => setBidValue(e.target.value)}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                Bid Date
                <Typography
                  variant="body1"
                  component="span"
                  className="asterisk"
                >
                  *
                </Typography>
              </Typography>
              <DatePicker
                oneTap
                format="dd-MM-yyyy"
                placeholder="Select Bid Date"
                className="modal-date-picker"
                onKeyDown={(event) => event.preventDefault()}
                onChange={handleBidDateChange}
                value={bidDate !== "" ? bidDate : null}
                placement="autoVerticalStart"
              />
              {bidDateError && (
                <Typography variant="body1" component="p" className="error">
                  {bidDateError}
                </Typography>
              )}
              {biderror && (
                <Typography variant="body1" component="p" className="error">
                  {biderror}
                </Typography>
              )}
              {error && (
                <Typography variant="body1" component="p" className="error">
                  {error}
                </Typography>
              )}
              {reserror && (
                <Typography variant="body1" component="p" className="error">
                  {reserror}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                Response Date
              </Typography>
              <DatePicker
                oneTap
                format="dd-MM-yyyy"
                placeholder="Select Response Date"
                className="modal-date-picker"
                onKeyDown={(event) => event.preventDefault()}
                onChange={handleResponseDateChange}
                // shouldDisableDate={shouldDisableBidDate}
                value={responseDate !== "" ? responseDate : null}
                placement="autoVerticalStart"
              />
              {responseerror && (
                <Typography variant="body1" component="p" className="error">
                  {responseerror}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                Closure Date
              </Typography>
              <DatePicker
                oneTap
                format="dd-MM-yyyy"
                placeholder="Select Closure Date"
                onKeyDown={(event) => event.preventDefault()}
                className="modal-date-picker"
                onChange={handleClosureDateChange}
                // shouldDisableDate={shouldDisableDate}
                value={closureDate !== "" ? closureDate : null}
                placement="autoVerticalStart"
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className="footer-wrapper">
        <Button
          variant="contained"
          size="large"
          className="primary-btn btn w-120"
          disableRipple
          onClick={submitHandler}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="large"
          className="secondary-btn btn w-120"
          disableRipple
          onClick={() =>
            navigate("/bids", {
              state: {
                page: state.page,
                rowsPerPage:state.rowsPerPage,
                filteredPage: state.filteredPage,
                filterApplied: state.filterApplied,
                bd: state.bd,
                platform: state.platform,
                bidtype: state.bidtype,
                billingtype: state.billingtype,
                bidDate: state.bidDate,
                startDate: state.startDate,
                 keywordSwitchVal: state.keywordSwitchVal,
                  techStackSwitchVal: state.techStackSwitchVal,
                endDate: state.endDate,
                keywordPayload: state.keywordPayload,
                technologyPayload: state.technologyPayload,
                keywords: state.keywords,
                technologies: state.technologies,
                text: state.text,
                converted: state.converted,
              },
            })
          }
        >
          Cancel
        </Button>
      </Box>
      {showModal && (
        <AddKeywordModal
          invalidValue={invalidValue}
          onClose={() => setShowModal(false)}
        />
      )}
      {addTechModal && (
        <AddTechnologyModal
          validTechName={validTechName}
          onClose={() => setAddTechModal(false)}
        />
      )}
    </>
  );
};

export default BidEdit;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../components/common/commonfunctionforapi";

export const fetchSettings = createAsyncThunk(
  "settings/fetchFeedbacks",
  async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/activity-setting`,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const settingsSlice = createSlice({
  name: "activytsetiing",
  initialState: {
    settings: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.settings = action.payload;
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = settingsSlice.actions;
export default settingsSlice.reducer;

import { module, permissions } from '../constants/constant';

const viewDashboard = {
  [module.DASHBOARD]: permissions.VIEW,
};

const viewProjects = {
  [module.PROJECTS]: permissions.VIEW,
};

const updateProjects = {
  [module.PROJECTS]: permissions.ADD_UPDATE,
};

const viewLeads = {
  [module.LEADS]: permissions.VIEW,
};

const updateLeads = {
  [module.LEADS]: permissions.ADD_UPDATE,
};

const viewUsers = {
  [module.USERS]: permissions.VIEW,
};

const viewClients = {
  [module.CLIENT]: permissions.VIEW,
};

const updateClients = {
  [module.CLIENT]: permissions.ADD_UPDATE,
};

const fullClients = {
  [module.CLIENT]: permissions.FULL,
};

const fullLeads = {
  [module.LEADS]: permissions.FULL,
};
const fullProjects = {
  [module.PROJECTS]: permissions.FULL,
};
const viewReports = {
  [module.REPORTS]: permissions.VIEW,
};

const viewBids = {
  [module.BIDS]: permissions.VIEW,
};

const viewSettings = {
  [module.SETTINGS]: permissions.VIEW,
};

const fullBids = {
  [module.BIDS]: permissions.FULL,
};

const updateBids = {
  [module.BIDS]: permissions.ADD_UPDATE,
};

//make permission for cv-builder module
const viewCVBuider = {
  [module.CV_BUILDER]: permissions.VIEW,
};
const fullCVBuilder = {
  [module.CV_BUILDER]: permissions.FULL,
};
const fullAnnouncements = {
  [module.ANNOUNCEMENTS]: permissions.FULL,
};
export {
  viewDashboard,
  viewProjects,
  updateProjects,
  viewLeads,
  updateLeads,
  viewUsers,
  viewClients,
  updateClients,
  fullClients,
  fullLeads,
  fullProjects,
  viewReports,
  viewBids,
  fullBids,
  updateBids,
  viewSettings,
  viewCVBuider,
  fullCVBuilder,
  fullAnnouncements,
};

import { callApi } from "../../components/common/commonfunctionforapi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";

export const fetchActivityReports = createAsyncThunk(
  "activities/fetchReports",
  async ({ startdate, enddate, userId }, { dispatch, rejectWithValue }) => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/reports/activities`,
        params: {
          startdate: startdate,
          enddate: enddate,
          userId: userId,
        },
      });
      // console.log(response.data);

      return response.data;
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  activityreports: [],
  status: "idle",
  error: null,
};
const ActivityReportSlice = createSlice({
  name: "activityreports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchActivityReports.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchActivityReports.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.activityreports = action.payload;
      })

      .addMatcher(
        (action) => action.type.startsWith("reports/fetchReports"),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            state.status = "failed";
            state.error = action.payload.message;
          }
        }
      );
  },
});
export const { updateReports } = ActivityReportSlice.actions;
export default ActivityReportSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setFeedback } from './feedbackSlice';
import { callApi } from '../../components/common/commonfunctionforapi';

// Async thunk action to delete the user by ID
export const deleteBidById = createAsyncThunk(
  'deleteBidById/deleteBid',
  async ({ clientId }, { dispatch }) => {
    // Assuming you have a token stored in the auth slice
    try {
      const response = await callApi({
        method: 'delete',
        url: `${process.env.REACT_APP_API_URL}/bids/${clientId}`,
      });
      // console.log(response.data);
      return { success: true, message: response.data.message };
    } catch (error) {
      dispatch(
        setFeedback({
          status: 'fail',
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      throw new Error(error.response.data.message || 'Failed to delete user.');
    }
  }
);

// Create the deleteLeadById slice with initial state
const deleteBidByIdSlice = createSlice({
  name: 'deleteBidById',
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteBidById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteBidById.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteBidById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default deleteBidByIdSlice.reducer;

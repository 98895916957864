import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

import KeywordIcon from "../../assets/images/icons/keywordIcon.svg";
import TechnologyStacksIcon from "../../assets/images/icons/technologyStacksIcon.svg";
import Frame from "../../assets/images/icons/Frame.svg";

const Settings = () => {
  const navigate = useNavigate();
  return (
    <>
      {/*  START :: SETTINGS LISTING VIEW */}
      <Box className="content-header project-list-header">
        <Typography variant="h2" component="h2">
          Settings
        </Typography>
      </Box>
      <Box className="content-wrapper">
        <Box className="card-wrapper">
          <Grid container spacing={3}>
            <Grid item lg={3} md={4} sm={6} xs={12} className="setting-grid-list">
              <Box
                className="setting-card"
                onClick={() => navigate("/keyword-setting")}
              >
                <Box class="setting-icon">
                  <img src={KeywordIcon} alt="app-logo" loading="lazy" />
                </Box>
                <Typography component="h5" variant="h5" className="setting-name">
                  Keywords
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12} className="setting-grid-list">
              <Box
                className="setting-card"
                onClick={() => navigate("/technology-stack-setting")}
              >
                <Box class="setting-icon">
                  <img src={TechnologyStacksIcon} alt="app-logo" loading="lazy" />
                </Box>
                <Typography component="h5" variant="h5" className="setting-name">
                  Technology Stacks
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12} className="setting-grid-list">
              <Box
                className="setting-card"
                onClick={() => navigate("/activity-setting")}
              >
                <Box class="setting-icon">
                  <img src={Frame} alt="app-logo" loading="lazy" />
                </Box>
                <Typography component="h5" variant="h5" className="setting-name">
                  Notifications
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* END :: SETTINGS LISTING VIEW */}
    </>
  );
};

export default Settings;

import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";

import Button from "@mui/material/Button";
import { deleteLeadById } from "../../store/slices/deleteLeadByIdSlice";
import { useDispatch } from "react-redux";
import { successToast } from "../../responses/successToast";
import { fetchLeads } from "../../store/slices/leadSlice";
import { fetchLeadFilters } from "../../store/slices/leadFilterSlice";
import { fetchLeadFiltersById } from "../../store/slices/leadFilterByIdSlice";

const LeadDeleteModal = ({
  leadDelete,
  handleDeleteClose,
  selectedLeadId,
  value,
  leadStatus,
  applyFilterId,
  filterByIdApplied,
  filterApplied,
  searchText,
  selectedKeywords,
  selectedTechnologies,
}) => {
  const dispatch = useDispatch();
  const leadId = selectedLeadId;

  const submitHandler = () => {
    if (filterApplied) {
      dispatch(deleteLeadById({ leadId }))
        .unwrap()
        .then((result) => {
          // Handle success
          const { success } = result;
          // console.log(success, "delete lead success");
          if (success) {
            successToast("Lead deleted successfully");
            dispatch(
              fetchLeadFilters({
                text: searchText,
                keyword: Array.isArray(selectedKeywords)
                  ? selectedKeywords.map((option) => option.label).join(",")
                  : "",
                technologies: Array.isArray(selectedTechnologies)
                  ? selectedTechnologies.map((option) => option.label).join(",")
                  : "",
                status:
                  value === 0
                    ? ""
                    : value === 1
                    ? "open"
                    : value === 2
                    ? "estimation_requested"
                    : value === 3
                    ? "estimation_provided"
                    : value === 4
                    ? "standBy"
                    : value === 5
                    ? "closed"
                    : value === 6
                    ? "won"
                    : "lost",
                hot_lead: value === 0 ? 1 : "",
              })
            );
            handleDeleteClose();
          } else {
            // Handle other cases where success is false (optional)
          }
        })
        .catch((error) => {
          // Handle error (optional)
          console.error("Failed to delete user:", error);
        });
    } else if (filterByIdApplied) {
      dispatch(deleteLeadById({ leadId }))
        .unwrap()
        .then((result) => {
          const { success } = result;

          if (success) {
            successToast("Lead deleted successfully");
            dispatch(
              fetchLeadFiltersById({
                filterId: applyFilterId,
                status:
                  value === 0
                    ? ""
                    : value === 1
                    ? "open"
                    : value === 2
                    ? "estimation_requested"
                    : value === 3
                    ? "estimation_provided"
                    : value === 4
                    ? "standBy"
                    : value === 5
                    ? "closed"
                    : value === 6
                    ? "won"
                    : "lost",
                hot_lead: value === 0 ? 1 : "",
              })
            );
            handleDeleteClose();
          } else {
          }
        })
        .catch((error) => {
          console.error("Failed to delete user:", error);
        });
    } else {
      dispatch(deleteLeadById({ leadId }))
        .unwrap()
        .then((result) => {
          const { success } = result;

          if (success) {
            successToast("Lead deleted successfully");
            dispatch(
              fetchLeads({
                page: "",
                status:
                  value === 0
                    ? ""
                    : value === 1
                    ? "open"
                    : value === 2
                    ? "estimation_requested"
                    : value === 3
                    ? "estimation_provided"
                    : value === 4
                    ? "standBy"
                    : value === 5
                    ? "closed"
                    : value === 6
                    ? "won"
                    : "lost",
                hot_lead: value === 0 ? 1 : "",
              })
            );

            handleDeleteClose();
          } else {
          }
        })
        .catch((error) => {
          console.error("Failed to delete user:", error);
        });
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={leadDelete}
        // onClose={LeaddeleteModalClose}
        closeAfterTransition
      >
        <Fade in={leadDelete}>
          <Box className="modal-wrapper">
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3">
                Do you want to delete this lead?
              </Typography>
              <Typography variant="body1" component="p">
                This action can’t be undone.
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body"></Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={submitHandler}
                >
                  Delete
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={handleDeleteClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default LeadDeleteModal;

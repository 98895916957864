import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import {
  RiDeleteBinLine,
  RiEyeLine,
  RiPencilLine,
  RiMailLine,
  RiPhoneLine,
} from "react-icons/ri";
import {
  Box,
  Skeleton,
  Tooltip,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchClients } from "../../store/slices/clientSlice";
import ClientDeleteModal from "../../components/common/deleteclientmodal";
import WithPermission from "../../components/common/withPermission";
import { fullClients, updateClients } from "../../utils/permissions";
import { successToast } from "../../responses/successToast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { maskEmail, maskPhoneNumber } from "../../utils/masking";
import { selectIsAdmin } from "../../constants/selectors";
import { CustomTablePaginationToolbar } from "../../components/common/Pagination";

const customToolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#3e3f42",
          color: "white",
          fontSize: "11px",
          margin: "0",
          padding: "10",
          border: "1px solid white",
        },
        arrow: {
          color: "var(--color-white)",
        },
      },
    },
  },
});

const ClientList = ({ onPageChange, searchUser }) => {
  const getUserRole = useSelector(selectIsAdmin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { state } = location;
   if (state === null) {
    state = {
      page: 1,
      status: 0,
      rowsPerPage:10
    };
  }
  const clients = useSelector((state) => state.clients.clients[0]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(
    state !== null ? state.page : 1
  );
  const [rowsPerPage, setRowsPerPage] = useState(state.rowsPerPage);

  // CLIENT DELETE
  const [clientDelete, setClientDelete] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const handleDeleteOpen = (clientId) => {
    setSelectedClientId(clientId);
    setClientDelete(true);
  };

  const handleDeleteClose = () => setClientDelete(false);
  // CLIENT DELETE COMPLETE

  const handlePageChange = (event, newPage) => {
    onPageChange(newPage);
    setCurrentPage(newPage);
  };
const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };
  function capitalizeWords(input) {
    return input?.replace(/\w+/g, function (word) {
      return word?.charAt(0).toUpperCase() + word.slice(1);
    });
  }

  const handleCopySuccess = () => {
    successToast("Copied to ClipBoard");
  };

  useEffect(() => {
    setLoading(true);
    dispatch(
      fetchClients({
        page: currentPage,
        limit: +rowsPerPage,
        query: searchUser,
      })
    );
    setLoading(false);
  }, [dispatch, currentPage, searchUser,rowsPerPage]);

  useEffect(() => {
    let savedSearchText = localStorage.getItem("searchClientsQuery");
    savedSearchText = savedSearchText?.replace(/^"(.+(?="$))"$/, "$1");

    if (searchUser.trim() !== "" && searchUser !== savedSearchText) {
      if (currentPage > 1) {
        setCurrentPage(1);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchUser]);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      // console.log("client refersh useEffect");
      window.history.replaceState({}, "");
      // console.log(state, "state");
    });
    return () => {
      // console.log("client refersh useEffect");
      window.history.replaceState({}, "");
      // console.log(state, "state");
    };
  }, []);

  return (
    <>
      {loading ? (
        <TableContainer className="table-listing">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="table"
            className="listing-table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Company Name</TableCell>
                {/* <TableCell align="left">Comapny Email</TableCell>
                <TableCell align="left">Company Phone No.</TableCell> */}
                <TableCell align="left">Address</TableCell>

                <TableCell align="left">Primary Contact</TableCell>
                <TableCell align="left">No. Of Contacts</TableCell>
                <TableCell align="left">No. Of projects</TableCell>

                {/* <TableCell align="left">Contact Person's Phone No.</TableCell> */}

                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: 6 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" width="100%" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width="100%" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width="100%" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width="100%" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width="100%" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width="100%" height={40} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : clients?.client?.length > 0 ? (
        <TableContainer className="table-listing">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="table"
            className="listing-table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Company Name</TableCell>
                {/* <TableCell align="left">Comapny Email</TableCell>
                <TableCell align="left">Company Phone No.</TableCell> */}
                <TableCell align="left">Address</TableCell>

                <TableCell align="left">Primary Contact</TableCell>
                <TableCell align="left">Counts</TableCell>
                {/* <TableCell align="left">No. Of projects</TableCell> */}

                {/* <TableCell align="left">Contact Person's Phone No.</TableCell> */}

                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients?.client?.map((client) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left" style={{ verticalAlign: "top" }}>
                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{
                        marginBottom: "12px",
                        fontWeight: "600",
                      }}
                    >
                      {client?.company_name ? (
                        <Tooltip
                          title={
                            <div style={{ color: "white", fontSize: "13px" }}>
                              {capitalizeWords(client?.company_name)}
                            </div>
                          }
                          arrow
                          placement="bottom-start"
                        >
                          <Box
                            variant="div"
                            component="div"
                            // className="app-name d-flex"
                            style={{
                              fontWeight: "600",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "160px",
                            }}
                          >
                            {" "}
                            {client?.company_name === "" ||
                            client?.company_name === null
                              ? "-"
                              : capitalizeWords(client?.company_name)}
                          </Box>
                        </Tooltip>
                      ) : (
                        "-"
                      )}
                    </Box>
                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {client?.company_email === "" ||
                        client?.company_email === null ? (
                          ""
                        ) : (
                          <Tooltip
                            title={
                              <div style={{ color: "white", fontSize: "13px" }}>
                                Click to Copy
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box className="companyicons">
                              <CopyToClipboard
                                onCopy={handleCopySuccess}
                                text={client?.company_email}
                              >
                                <RiMailLine />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip>
                        )}
                        {client?.company_email ? (
                          <Tooltip
                            title={
                              <div style={{ color: "white", fontSize: "13px" }}>
                                {client?.company_email}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                marginBottom: "5px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "160px",
                              }}
                            >
                              {" "}
                              {client?.company_email === "" ||
                              client?.company_email === null
                                ? "-"
                                : client?.company_email}
                            </Box>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {client?.company_email === "" ||
                        client?.company_email === null ? (
                          ""
                        ) : (
                          <Box
                            className="companyicons"
                            style={{ cursor: "auto" }}
                          >
                            <RiMailLine />
                          </Box>
                        )}
                        {client?.company_email ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              marginBottom: "5px",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "160px",
                            }}
                          >
                            {" "}
                            {client?.company_email === "" ||
                            client?.company_email === null
                              ? "-"
                              : maskEmail(client?.company_email)}
                          </Box>
                        ) : (
                          "-"
                        )}
                      </Box>
                    )}
                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{
                          marginBottom: "5px",
                        }}
                      >
                        {client?.company_phone === "" ||
                        client?.company_phone === null ? (
                          ""
                        ) : (
                          <Tooltip
                            title={
                              <div style={{ color: "white", fontSize: "13px" }}>
                                Click to Copy
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box className="companyicons">
                              <CopyToClipboard
                                onCopy={handleCopySuccess}
                                text={client?.company_phone}
                              >
                                <RiPhoneLine />
                              </CopyToClipboard>
                            </Box>
                          </Tooltip>
                        )}
                        {client?.company_phone ? (
                          <Tooltip
                            title={
                              <div style={{ color: "white", fontSize: "13px" }}>
                                {client?.company_phone}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box
                              variant="div"
                              component="div"
                              // className="app-name d-flex"
                              style={{
                                // marginBottom: "12px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "160px",
                              }}
                            >
                              {" "}
                              {client?.company_phone === "" ||
                              client?.company_phone === null
                                ? "-"
                                : client?.company_phone}
                            </Box>
                          </Tooltip>
                        ) : (
                          "-"
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{
                          marginBottom: "5px",
                        }}
                      >
                        {client?.company_phone === "" ||
                        client?.company_phone === null ? (
                          ""
                        ) : (
                          <Box
                            className="companyicons"
                            style={{ cursor: "auto" }}
                          >
                            <RiPhoneLine />
                          </Box>
                        )}
                        {client?.company_phone ? (
                          <Box
                            variant="div"
                            component="div"
                            // className="app-name d-flex"
                            style={{
                              // marginBottom: "12px",

                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "160px",
                            }}
                          >
                            {" "}
                            {client?.company_phone === "" ||
                            client?.company_phone === null
                              ? "-"
                              : maskPhoneNumber(client?.company_phone)}
                          </Box>
                        ) : (
                          "-"
                        )}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="left" style={{ verticalAlign: "top" }}>
                    {client?.address ||
                    client?.city ||
                    client?.state ||
                    client?.zip_code ||
                    client?.country ? (
                      <Tooltip
                        title={
                          <div style={{ color: "white", fontSize: "13px" }}>
                            {client?.address && (
                              <>
                                {client.address}
                                <br />
                              </>
                            )}
                            {(client?.city ||
                              client?.state ||
                              client?.zip_code ||
                              client?.country) && (
                              <>
                                {client.city && <>{client.city}, </>}
                                {client.state && <>{client.state} </>}
                                {client.zip_code && (
                                  <>
                                    {client.zip_code}
                                    <br />
                                  </>
                                )}
                                {client.country && <>{client.country}</>}
                              </>
                            )}
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                          style={{ gap: "0px" }}
                        >
                          {client?.address && client?.address !== "" && (
                            <React.Fragment>
                              <br />

                              <Box
                                variant="div"
                                component="div"
                                // className="app-name d-flex"
                                style={{
                                  marginBottom: "5px",

                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  width: "160px",
                                }}
                              >
                                {" "}
                                {client?.address},
                              </Box>
                            </React.Fragment>
                          )}
                        </Box>

                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                        >
                          {client?.city && client?.city !== "" && (
                            <React.Fragment>{client?.city},</React.Fragment>
                          )}

                          {client?.state && client?.state !== "" && (
                            <React.Fragment>
                              &nbsp;{client?.state}{" "}
                            </React.Fragment>
                          )}
                          {client?.zip_code && client?.zip_code !== "" && (
                            <React.Fragment>
                              {client?.zip_code}
                              <br />
                            </React.Fragment>
                          )}

                          {client?.country && client?.country !== "" && (
                            <React.Fragment>
                              {client?.country}
                              <br />
                            </React.Fragment>
                          )}

                          {client?.address === "" &&
                            client?.city === "" &&
                            client?.state === "" &&
                            client?.zip_code === "" &&
                            client?.country === null && (
                              <React.Fragment>-</React.Fragment>
                            )}
                          {client?.address === null &&
                            client?.city === null &&
                            client?.state === null &&
                            client?.zip_code === null &&
                            client?.country === null && (
                              <React.Fragment>-</React.Fragment>
                            )}
                        </Box>
                      </Tooltip>
                    ) : (
                      "-"
                    )}
                  </TableCell>

                  <TableCell align="left" style={{ verticalAlign: "top" }}>
                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{
                        marginBottom: "12px",
                        fontWeight: "600",
                      }}
                    >
                      <Tooltip
                        title={
                          <div style={{ color: "white", fontSize: "13px" }}>
                            {
                              client?.client_contacts?.find(
                                (contact) => contact.is_primary
                              )?.contact_person_name
                            }
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        {" "}
                        <Box
                          variant="div"
                          component="div"
                          // className="app-name d-flex"
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "160px",
                          }}
                        >
                          {" "}
                          {client?.client_contacts?.find(
                            (contact) => contact.is_primary
                          )?.contact_person_name
                            ? capitalizeWords(
                                client?.client_contacts?.find(
                                  (contact) => contact.is_primary
                                )?.contact_person_name
                              )
                            : "-"}
                        </Box>
                      </Tooltip>
                    </Box>
                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {client?.client_contacts?.map((client) =>
                          client?.contact_person_email === "" ||
                          client?.contact_person_email === null ? (
                            "-"
                          ) : (
                            <>
                              <Tooltip
                                title={
                                  <div
                                    style={{ color: "white", fontSize: "13px" }}
                                  >
                                    Click to Copy
                                  </div>
                                }
                                arrow
                                placement="bottom-start"
                              >
                                <Box className="companyicons">
                                  <CopyToClipboard
                                    onCopy={handleCopySuccess}
                                    text={client?.contact_person_email}
                                  >
                                    <RiMailLine />
                                  </CopyToClipboard>
                                </Box>
                              </Tooltip>
                              {client?.contact_person_email ? (
                                <Tooltip
                                  title={
                                    <div
                                      style={{
                                        color: "white",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {client?.contact_person_email}
                                    </div>
                                  }
                                  arrow
                                  placement="bottom-start"
                                >
                                  <Box
                                    variant="div"
                                    component="div"
                                    // className="app-name d-flex"
                                    style={{
                                      marginBottom: "5px",

                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      width: "160px",
                                    }}
                                  >
                                    {" "}
                                    {client?.contact_person_email}
                                  </Box>
                                </Tooltip>
                              ) : (
                                "-"
                              )}
                            </>
                          )
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {client?.client_contacts?.map((client) =>
                          client?.contact_person_email === "" ||
                          client?.contact_person_email === null ? (
                            "-"
                          ) : (
                            <>
                              <Box
                                className="companyicons"
                                style={{ cursor: "auto" }}
                              >
                                <RiMailLine />
                              </Box>
                              {client?.contact_person_email ? (
                                <Box
                                  variant="div"
                                  component="div"
                                  // className="app-name d-flex"
                                  style={{
                                    marginBottom: "5px",

                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    width: "160px",
                                  }}
                                >
                                  {" "}
                                  {maskEmail(client?.contact_person_email)}
                                </Box>
                              ) : (
                                "-"
                              )}
                            </>
                          )
                        )}
                      </Box>
                    )}
                    {getUserRole ? (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {client?.client_contacts?.map((client) =>
                          client?.contact_person_phone === "" ||
                          client?.contact_person_phone === null ? (
                            "-"
                          ) : (
                            <>
                              <Tooltip
                                title={
                                  <div
                                    style={{ color: "white", fontSize: "13px" }}
                                  >
                                    Click to Copy
                                  </div>
                                }
                                arrow
                                placement="bottom-start"
                              >
                                <Box className="companyicons">
                                  <CopyToClipboard
                                    onCopy={handleCopySuccess}
                                    text={client?.contact_person_phone}
                                  >
                                    <RiPhoneLine />
                                  </CopyToClipboard>
                                </Box>
                              </Tooltip>
                              {client?.contact_person_phone ? (
                                <Tooltip
                                  title={
                                    <div
                                      style={{
                                        color: "white",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {client?.contact_person_phone}
                                    </div>
                                  }
                                  arrow
                                  placement="bottom-start"
                                >
                                  <Box
                                    variant="div"
                                    component="div"
                                    // className="app-name d-flex"
                                    style={{
                                      // marginBottom: "5px",

                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      width: "160px",
                                    }}
                                  >
                                    {" "}
                                    {client?.contact_person_phone === "" ||
                                    client?.contact_person_phone === null
                                      ? "-"
                                      : client?.contact_person_phone}
                                  </Box>
                                </Tooltip>
                              ) : (
                                "-"
                              )}
                            </>
                          )
                        )}
                      </Box>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{ marginBottom: "5px" }}
                      >
                        {client?.client_contacts?.map((client) =>
                          client?.contact_person_phone === "" ||
                          client?.contact_person_phone === null ? (
                            "-"
                          ) : (
                            <>
                              <Box
                                className="companyicons"
                                style={{ cursor: "auto" }}
                              >
                                <RiPhoneLine />
                              </Box>
                              {client?.contact_person_phone ? (
                                <Box
                                  variant="div"
                                  component="div"
                                  // className="app-name d-flex"
                                  style={{
                                    // marginBottom: "5px",

                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    width: "160px",
                                  }}
                                >
                                  {" "}
                                  {client?.contact_person_phone === "" ||
                                  client?.contact_person_phone === null
                                    ? "-"
                                    : maskPhoneNumber(
                                        client?.contact_person_phone
                                      )}
                                </Box>
                              ) : (
                                "-"
                              )}
                            </>
                          )
                        )}
                      </Box>
                    )}
                  </TableCell>
                  <TableCell align="left" style={{ verticalAlign: "top" }}>
                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{
                        marginBottom: "12px",
                        fontWeight: "600",
                      }}
                    >
                      Contacts:{" "}
                      <Typography
                        variant="body1"
                        component="div"
                        style={{ fontWeight: "400px" }}
                      >
                        {client?.count_of_client_contacts}
                      </Typography>
                    </Box>

                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{
                        marginBottom: "12px",
                        fontWeight: "600",
                      }}
                    >
                      Projects:{" "}
                      <Typography
                        variant="body1"
                        component="div"
                        style={{ fontWeight: "400px" }}
                      >
                        {client?.count_of_projects}
                      </Typography>
                    </Box>
                  </TableCell>
                  {/* <TableCell align="left">
                    {" "}
                    {client?.client_contacts?.map((client) =>
                      client?.contact_person_phone === "" ||
                      client?.contact_person_phone === null
                        ? "-"
                        : client?.contact_person_phone
                    )}
                  </TableCell> */}
                  <TableCell align="left" style={{ verticalAlign: "top" }}>
                    <Box className="action-btn-wrap">
                      <ThemeProvider theme={customToolTipTheme}>
                        <Tooltip title="View">
                          <span>
                            <Button
                              variant="outlined"
                              className="action-btn view-btn"
                              startIcon={<RiEyeLine />}
                              disableRipple
                              // title="View"
                              onClick={() => {
                                // console.log({ page: currentPage });
                                navigate(`/clients/${client.id}/view`, {
                                  state: { page: currentPage,rowsPerPage:rowsPerPage },
                                });
                              }}
                            ></Button>
                          </span>
                        </Tooltip>
                      </ThemeProvider>
                      <WithPermission
                        permission={updateClients}
                        element={
                          <ThemeProvider theme={customToolTipTheme}>
                            <Tooltip title="Edit">
                              <span>
                                <Button
                                  variant="outlined"
                                  className="action-btn edit-btn"
                                  startIcon={<RiPencilLine />}
                                  disableRipple
                                  // title="Edit"
                                  onClick={() =>
                                    navigate(`/clients/${client.id}/edit`, {
                                      state: { page: currentPage,rowsPerPage:rowsPerPage },
                                    })
                                  }
                                ></Button>
                              </span>
                            </Tooltip>
                          </ThemeProvider>
                        }
                      />
                      <WithPermission
                        permission={fullClients}
                        element={
                          <ThemeProvider theme={customToolTipTheme}>
                            <Tooltip title="Delete">
                              <span>
                                <Button
                                  variant="outlined"
                                  className="action-btn dlt-btn"
                                  startIcon={<RiDeleteBinLine />}
                                  disableRipple
                                  // title="Delete"
                                  onClick={() => handleDeleteOpen(client.id)}
                                ></Button>
                              </span>
                            </Tooltip>
                          </ThemeProvider>
                        }
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* <Box variant="div" component="div" className="pagination-wrapper"> */}
            {/* <Pagination
              count={clients?.totalPages}
              page={currentPage}
              onChange={handlePageChange}
              shape="rounded"
            /> */}
            <CustomTablePaginationToolbar
                      count={clients?.total || 0}
                      page={currentPage}
                      rowsPerPage={+rowsPerPage}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
          {/* </Box> */}
        </TableContainer>
      ) : (
        <TableContainer className="listing-table">
        <Table
          sx={{ minWidth: 650 }}
          aria-label="table"
          className="listing-table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Company Name</TableCell>

              <TableCell align="left">Address</TableCell>

              <TableCell align="left">Primary Contact</TableCell>
              <TableCell align="left">Counts</TableCell>

              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className="no-msg">
              <TableCell
                colSpan={8}
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "var(--textgrey)",
                  fontSize: "16px",
                }}
                className="no-msg"
              >
                No Clients Found
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        </TableContainer>
      )}
      <ClientDeleteModal
        handleDeleteOpen={handleDeleteOpen}
        handleDeleteClose={handleDeleteClose}
        clientDelete={clientDelete}
        selectedClientId={selectedClientId}
        currentPage={currentPage}
      />
    </>
  );
};

export default ClientList;

import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import {
  RiDeleteBinLine,
  RiEyeLine,
  RiPencilLine,
  RiRepeat2Line,
} from "react-icons/ri";
import {
  Box,
  Typography,
  Skeleton,
  Chip,
  Tooltip,
  createTheme,
  ThemeProvider,
} from "@mui/material";
// eslint-disable-next-line no-unused-vars
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBids } from "../../store/slices/bidSlice";
import BidDeleteModal from "../../components/common/deleteBidModal";
import { fullBids, updateBids } from "../../utils/permissions";
import WithPermission from "../../components/common/withPermission";
import ConvertBidToLeadModal from "../../components/common/convertBidToLeadModal";
import { CustomTablePaginationToolbar } from "../../components/common/Pagination";

const customToolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#3e3f42",
          color: "white",
          fontSize: "11px",
          margin: "0",
          padding: "10",
          border: "1px solid white",
        },
        arrow: {
          color: "var(--color-white)",
        },
      },
    },
  },
});

const BidList = ({
  onPageChange,
  filters,
  filterCurrentPage,
  filterApplied,
  onFilterPageChange,
  filteredProjects,
  endDate,
  currentpage,
  rowsPerPage,
  setRowsPerPage,
  setCurrentPage,
  startDate,
  bd,
  platform,
  keywordPayload,
  technologyPayload,
  billingType,
  bidType,
  searchText,
  isChecked,
  searchUser,
  selectedKeywords,
  selectedTechnologies,
  bidDate,
  keywordSwitchValue,
  techStackSwitchValue,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const bids = useSelector((state) => state.bids.bids);
  const [loading, setLoading] = useState(true);

  const [clientDelete, setClientDelete] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);

  const [convertCancel, setConvertCancel] = useState(false);
  const [convertBidId, setConvertBidId] = useState(null);
  const handleConvertOpen = (bidId) => {
    setConvertBidId(bidId);

    setConvertCancel(true);
  };

  const handleConvertClose = () => setConvertCancel(false);

  const handleDeleteOpen = (clientId) => {
    setSelectedClientId(clientId);
    setClientDelete(true);
  };

  const handleDeleteClose = () => setClientDelete(false);

  const handlePageChange = (event, newPage) => {
    onPageChange(newPage);
    setCurrentPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
    onPageChange(1);
    onFilterPageChange(1);
  };
  const handleFilterPageChange = (event, newPage) => {
    onFilterPageChange(newPage);
  };

  function capitalizeWords(input) {
    return input?.replace(/\w+/g, function (word) {
      return word?.charAt(0).toUpperCase() + word.slice(1);
    });
  }

  function formatDate(inputDate) {
    const date = new Date(inputDate);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const handleLinkClick = (event, website) => {
    if (website && !website.startsWith("http")) {
      event.preventDefault();
      window.open(`http://${website}`, "_blank");
    }
  };

  const bidList = filterApplied ? filteredProjects : bids?.allbids;

  useEffect(() => {
    setLoading(true);
    dispatch(
      fetchBids({
        page: currentpage,
        limit: +rowsPerPage,
        query: searchUser,
      })
    );
    setLoading(false);
  }, [dispatch, currentpage, searchUser, rowsPerPage]);

  useEffect(() => {
    let savedSearchText = localStorage.getItem("searchBidsQuery");
    savedSearchText = savedSearchText?.replace(/^"(.+(?="$))"$/, "$1");

    if (searchUser.trim() !== "" && searchUser !== savedSearchText) {
      if (currentpage > 1) {
        setCurrentPage(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchUser]);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      window.history.replaceState({}, "");
    });
    return () => {
      window.history.replaceState({}, "");
    };
  }, []);

  return (
    <>
      {loading ? (
        <TableContainer className="table-listing">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="table"
            className="listing-table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">BD</TableCell>

                <TableCell align="left">Platform</TableCell>

                <TableCell align="left">Title</TableCell>
                <TableCell align="left">Billing/Bid Type</TableCell>
                <TableCell align="left">Bid Date</TableCell>

                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from({ length: 6 }).map((_, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Skeleton variant="text" width="100%" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width="100%" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width="100%" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width="100%" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width="100%" height={40} />
                  </TableCell>
                  <TableCell>
                    <Skeleton variant="text" width="100%" height={40} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : bidList?.length > 0 ? (
        <>
          <TableContainer className="table-listing">
            <Table
              sx={{ minWidth: 650 }}
              aria-label="table"
              className="listing-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">BD</TableCell>

                  <TableCell align="left">Platform</TableCell>

                  <TableCell align="left">Title</TableCell>
                  <TableCell align="left">Billing/Bid Type</TableCell>
                  <TableCell align="left">Bid Date</TableCell>

                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bidList?.map((bid) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left" style={{ verticalAlign: "top" }}>
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{
                          marginBottom: "12px",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        {bid?.bd === "" || bid?.bd === null ? (
                          "-"
                        ) : (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {capitalizeWords(bid?.bd?.first_name)}{" "}
                                {capitalizeWords(bid?.bd?.last_name)}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              style={{
                                fontWeight: "400",
                                color: "var(--primary-text-color) ",
                                fontSize: "0.875rem",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "110px",
                              }}
                            >
                              {capitalizeWords(bid?.bd?.first_name)}{" "}
                              {capitalizeWords(bid?.bd?.last_name)}
                            </Typography>
                          </Tooltip>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="left" style={{ verticalAlign: "top" }}>
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{
                          marginBottom: "12px",
                          fontWeight: "400",
                        }}
                      >
                        {" "}
                        {bid?.platform === null || bid?.platform === ""
                          ? "-"
                          : bid?.platform}
                      </Box>
                    </TableCell>

                    <TableCell align="left" style={{ verticalAlign: "top" }}>
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{
                          marginBottom: "12px",
                          fontWeight: "400",
                        }}
                      >
                        {bid?.url === "" || bid?.url === null ? (
                          bid?.title === null || bid?.title === "" ? (
                            "-"
                          ) : (
                            <Tooltip
                              title={
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "13px",
                                  }}
                                >
                                  {bid?.title}{" "}
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                            >
                              <Typography
                                variant="body1"
                                component="div"
                                style={{
                                  fontWeight: "400",
                                  color: "var(--primary-text-color) ",
                                  fontSize: "0.875rem",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  width: "200px",
                                }}
                              >
                                {bid?.title}{" "}
                              </Typography>
                            </Tooltip>
                          )
                        ) : bid?.url && bid?.title ? (
                          <a
                            href={bid?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "black" }}
                            onClick={(e) => handleLinkClick(e, bid?.url)}
                          >
                            {bid?.title === null || bid?.title === "" ? (
                              "-"
                            ) : (
                              <Tooltip
                                title={
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {capitalizeWords(bid?.title)}{" "}
                                  </div>
                                }
                                arrow
                                placement="bottom-start"
                              >
                                <Typography
                                  variant="body1"
                                  component="div"
                                  style={{
                                    fontWeight: "400",
                                    color: "var(--primary-text-color) ",
                                    fontSize: "0.875rem",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    width: "200px",
                                  }}
                                >
                                  {capitalizeWords(bid?.title)}{" "}
                                </Typography>
                              </Tooltip>
                            )}
                          </a>
                        ) : bid?.url === null || bid?.url === "" ? (
                          "-"
                        ) : (
                          <a
                            href={bid?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "black" }}
                            onClick={(e) => handleLinkClick(e, bid?.url)}
                          >
                            <Tooltip
                              title={
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "13px",
                                  }}
                                >
                                  {bid?.url}{" "}
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                            >
                              <Typography
                                variant="body1"
                                component="div"
                                style={{
                                  fontWeight: "400",
                                  color: "var(--primary-text-color) ",
                                  fontSize: "0.875rem",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  width: "200px",
                                }}
                              >
                                {bid?.url}{" "}
                              </Typography>
                            </Tooltip>
                          </a>
                        )}
                      </Box>
                      <Box
                        variant="div"
                        component="div"
                        className="tag-wrap"
                        style={{ width: "200px", alignItems: "center" }}
                      >
                        {bid?.bid_technologies?.length > 0
                          ? bid?.bid_technologies?.map(
                              (bid, index) =>
                                index < 2 && (
                                  <Tooltip
                                    title={
                                      <div
                                        style={{
                                          color: "white",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {capitalizeWords(
                                          bid?.technologies?.technology_name
                                        )}{" "}
                                      </div>
                                    }
                                    arrow
                                    placement="bottom-start"
                                  >
                                    <Box
                                      variant="div"
                                      component="div"
                                      // className="app-name d-flex lead"
                                      style={{
                                        fontWeight: "600",
                                        marginBottom: "12px",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                      }}
                                    >
                                      <Chip
                                        label={
                                          bid?.technologies?.technology_name
                                        }
                                      />
                                    </Box>
                                  </Tooltip>
                                )
                            )
                          : "-"}
                        {bid?.bid_technologies?.length > 2 && (
                          <Box variant="div" component="div">
                            <Tooltip
                              title={
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "13px",
                                  }}
                                >
                                  {bid?.bid_technologies
                                    ?.map((bid, index) =>
                                      capitalizeWords(
                                        bid?.technologies?.technology_name
                                      )
                                    )
                                    .slice(2)
                                    .join(", ")}{" "}
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                            >
                              <Typography
                                variant="body1"
                                component="div"
                                style={{ marginBottom: "12px" }}
                              >
                                +{" "}
                                <Typography
                                  variant="body1"
                                  component="span"
                                  className="plus-users"
                                  style={{ fontSize: "0.875rem" }}
                                >
                                  {bid?.bid_technologies?.length - 2}
                                </Typography>{" "}
                                more
                              </Typography>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="left" style={{ verticalAlign: "top" }}>
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{
                          marginBottom: "12px",
                          fontWeight: "600",
                        }}
                      >
                        Billing Type:&nbsp;
                        {bid?.billing_type === null ||
                        bid?.billing_type === "" ? (
                          "-"
                        ) : (
                          <Typography
                            variant="body1"
                            component="div"
                            style={{
                              fontWeight: "400",
                              color: "var(--primary-text-color) ",
                              fontSize: "0.925rem",
                            }}
                          >
                            {bid?.billing_type === "Fixedcost"
                              ? "Fixed-Cost"
                              : bid?.billing_type}
                          </Typography>
                        )}
                      </Box>
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{
                          marginBottom: "12px",
                          fontWeight: "600",
                        }}
                      >
                        Bid Type:&nbsp;
                        {bid?.bid_type === null || bid?.bid_type === "" ? (
                          "-"
                        ) : (
                          <Typography
                            variant="body1"
                            component="div"
                            style={{
                              fontWeight: "400",
                              color: "var(--primary-text-color) ",
                              fontSize: "0.925rem",
                            }}
                          >
                            {bid?.bid_type === "Fulltime"
                              ? "Full-Time"
                              : "Part-Time"}
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="left" style={{ verticalAlign: "top" }}>
                      <Box
                        variant="div"
                        component="div"
                        className="app-name d-flex"
                        style={{
                          marginBottom: "12px",
                          fontWeight: "400",
                        }}
                      >
                        {bid?.bid_date === null || bid?.bid_date === ""
                          ? "-"
                          : formatDate(bid?.bid_date)}
                      </Box>
                    </TableCell>

                    <TableCell align="left" style={{ verticalAlign: "top" }}>
                      <Box className="action-btn-wrap">
                        <ThemeProvider theme={customToolTipTheme}>
                          <Tooltip title="View">
                            <span>
                              <Button
                                variant="outlined"
                                className="action-btn view-btn"
                                startIcon={<RiEyeLine />}
                                disableRipple
                                // title="View"
                                onClick={() =>
                                  navigate(`/bids/${bid?.id}/view`, {
                                    state: {
                                      page: currentpage,
                                      rowsPerPage: rowsPerPage,
                                      filterApplied: filterApplied,
                                      filteredPage: filterCurrentPage,
                                      bd: bd,
                                      platform: platform,
                                      keywords: selectedKeywords,
                                      technologies: selectedTechnologies,
                                      keywordSwitchVal: keywordSwitchValue,
                                      techStackSwitchVal: techStackSwitchValue,
                                      startDate: startDate,
                                      endDate: endDate,
                                      bidDate: bidDate,
                                      bidtype: bidType,
                                      billingtype: billingType,
                                      keywordPayload: keywordPayload,
                                      technologyPayload: technologyPayload,
                                      text: searchText,
                                      converted: isChecked,
                                    },
                                  })
                                }
                              ></Button>
                            </span>
                          </Tooltip>
                        </ThemeProvider>

                        <WithPermission
                          permission={updateBids}
                          element={
                            <ThemeProvider theme={customToolTipTheme}>
                              <Tooltip title="Edit">
                                <span>
                                  <Button
                                    variant="outlined"
                                    className="action-btn edit-btn"
                                    startIcon={<RiPencilLine />}
                                    disableRipple
                                    // title="Edit"
                                    onClick={() =>
                                      navigate(`/bids/${bid?.id}/edit`, {
                                        state: {
                                          page: currentpage,
                                          rowsPerPage: rowsPerPage,
                                          filterApplied: filterApplied,
                                          filteredPage: filterCurrentPage,
                                          bd: bd,
                                          platform: platform,
                                          keywords: selectedKeywords,
                                          technologies: selectedTechnologies,
                                          keywordSwitchVal: keywordSwitchValue,
                                          techStackSwitchVal:
                                            techStackSwitchValue,
                                          startDate: startDate,
                                          endDate: endDate,
                                          bidDate: bidDate,
                                          bidtype: bidType,
                                          billingtype: billingType,
                                          keywordPayload: keywordPayload,
                                          technologyPayload: technologyPayload,
                                          text: searchText,
                                          converted: isChecked,
                                        },
                                      })
                                    }
                                  ></Button>
                                </span>
                              </Tooltip>
                            </ThemeProvider>
                          }
                        />
                        <WithPermission
                          permission={updateBids}
                          element={
                            <ThemeProvider theme={customToolTipTheme}>
                              <Tooltip
                                title={
                                  bid?.lead_id === null
                                    ? "Convert Bid to Lead"
                                    : "Bid has already been converted into lead"
                                }
                              >
                                <span>
                                  <Button
                                    variant="outlined"
                                    startIcon={<RiRepeat2Line />}
                                    className="action-btn convert-btn"
                                    onClick={() => handleConvertOpen(bid?.id)}
                                    disabled={bid?.lead_id}
                                  ></Button>
                                </span>
                              </Tooltip>
                            </ThemeProvider>
                          }
                        />
                        <WithPermission
                          permission={fullBids}
                          element={
                            <ThemeProvider theme={customToolTipTheme}>
                              <Tooltip title="Delete">
                                <span>
                                  <Button
                                    variant="outlined"
                                    className="action-btn dlt-btn"
                                    startIcon={<RiDeleteBinLine />}
                                    disableRipple
                                    // title="Delete"
                                    onClick={() => handleDeleteOpen(bid.id)}
                                  ></Button>
                                </span>
                              </Tooltip>
                            </ThemeProvider>
                          }
                        />
                      </Box>
                      {bid?.lead_id === null ? null : (
                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                          style={{
                            color: "#ff606b",
                            fontStyle: "italic",
                            fontWeight: "600",
                            marginTop: "10px",
                          }}
                        >
                          Successful Bid
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {/* <Box variant="div" component="div" className="pagination-wrapper"> */}
            {/* <Pagination
                count={filterApplied ? filters?.totalPages : bids?.totalPages}
                page={filterApplied ? filterCurrentPage : currentpage}
                onChange={
                  filterApplied ? handleFilterPageChange : handlePageChange
                }
                shape="rounded"
              /> */}
            <CustomTablePaginationToolbar
              count={filterApplied ? filters?.total : bids?.total || 0}
              page={filterApplied ? filterCurrentPage : currentpage}
              rowsPerPage={+rowsPerPage}
              onPageChange={
                filterApplied ? handleFilterPageChange : handlePageChange
              }
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {/* </Box> */}
          </TableContainer>
        </>
      ) : (
        <TableContainer className="table-listing">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="table"
            className="listing-table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">BD</TableCell>

                <TableCell align="left">Platform</TableCell>

                <TableCell align="left">Title</TableCell>
                <TableCell align="left">Billing/Bid Type</TableCell>
                <TableCell align="left">Bid Date</TableCell>

                <TableCell align="left">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className="no-msg">
                <TableCell
                  colSpan={8}
                  style={{
                    width: "100%",
                    textAlign: "center",
                    color: "var(--textgrey)",
                    fontSize: "16px",
                  }}
                  className="no-msg"
                >
                  No Bids Found
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <ConvertBidToLeadModal
        handleConvertOpen={handleConvertOpen}
        handleConvertClose={handleConvertClose}
        convertCancel={convertCancel}
        setConvertCancel={setConvertCancel}
        convertBidId={convertBidId}
      />

      <BidDeleteModal
        handleDeleteOpen={handleDeleteOpen}
        handleDeleteClose={handleDeleteClose}
        clientDelete={clientDelete}
        selectedClientId={selectedClientId}
        currentPage={currentpage}
        filterApplied={filterApplied}
        filteredCurrentPage={filterCurrentPage}
        endDate={endDate}
        startDate={startDate}
        bd={bd}
        platform={platform}
        keywordPayload={keywordPayload}
        technologyPayload={technologyPayload}
        billingType={billingType}
        bidType={bidType}
        searchText={searchText}
        isChecked={isChecked}
      />
    </>
  );
};

export default BidList;

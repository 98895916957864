import { Box, Fade, Modal, Slide, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { RiCloseLine } from "react-icons/ri";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import { callApi } from "./commonfunctionforapi";
import Cookies from "js-cookie";
import { setTotalAnnouncements } from "../../store/slices/announcementSlice";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
const AnnoncementModelSlider = ({
  openReadAllModal,
  viewAnnouncementOpen,
  setViewAnnouncementOpen,
  announcement,
  announcementList,
  unreadIds,
}) => {
  // const [currentSlide,setCurrentSlide]=useState(0);
  const userId = Cookies.get(process.env.REACT_APP_USER_ID_COOKIE);
  const dispatch = useDispatch();
  const handleSlideChange = async (oldIndex, newIndex) => {
    let unreadIds = [
      announcementList[oldIndex].id,
      announcementList[newIndex].id,
    ];

    await axios.patch(
      `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/${userId}/read`,
      {
        announcement_id: unreadIds,
        is_all_read: false,
      }
    );
    const response = await callApi({
      method: "get",
      url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/module/3`,
    });

    dispatch(setTotalAnnouncements(response?.data?.unreadCount));
  };
  useEffect(() => {
    async function readFirst() {
      // console.log("readFirst inside ")

      // Mark the first announcement as read
      await axios.patch(
        `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/${userId}/read`,
        {
          announcement_id: [announcementList[0]?.id],
          is_all_read: false,
        }
      );
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/module/2`,
      });

      dispatch(setTotalAnnouncements(response?.data?.unreadCount));
    }
    if (viewAnnouncementOpen && announcementList?.length > 0) {
      readFirst();
    }
  }, [viewAnnouncementOpen, announcementList]);

  const settings = {
    dots: announcementList?.length > 1,
    arrow: announcementList?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: announcementList?.length > 1,
    autoplay: false,
    prevArrow:
      announcementList?.length > 1 ? (
        <ChevronLeftOutlinedIcon sx={{ fontSize: "35px" }} />
      ) : null, // Custom prev arrow
    nextArrow:
      announcementList?.length > 1 ? (
        <ChevronRightOutlinedIcon sx={{ fontSize: "35px" }} />
      ) : null, // Custom next arrow
    beforeChange: handleSlideChange,
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewAnnouncementOpen}
        // onClose={setViewAnnouncementOpen(false)}
        closeAfterTransition
        // sx={{ transform: 'translate(-50%, -50%)' }}
        disableAutoFocus // Prevent autofocus which may jump the modal
        BackdropProps={{
          timeout: 500, // Delay for backdrop to match the Slide animation
        }}
      >
        <Slide in={viewAnnouncementOpen} direction="down">
          <Box
            className="modal-wrapper-announcement-slider  modal-ui"
            sx={{
              position: "absolute",
              top: {
                xs: "10%", // For extra small screens (mobile)
                sm: "15%", // For small screens (tablets)
                md: "20%", // For medium screens (desktops)
                lg: "20%", // For large screens
              },
              left: {
                xs: "7%", // Left position for small screens (mobile)
                sm: "7%", // For tablets or small devices
                md: "15%", // For desktops
                lg: "15%", // For large screens
              },
              // transform: 'translate(-50%, -50%) !important', // Initial transform
              transition: "top 0.5s ease", // Ensure smooth sliding
            }}
          >
            <Box className="modal-body">
              <Box
                variant="div"
                component="div"
                className="title"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                  }}
                >
                  <CampaignOutlinedIcon
                    sx={{ fontSize: "35px" }}
                  ></CampaignOutlinedIcon>
                  <Typography variant="h6">New Announcement !</Typography>
                </Box>
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn custom-icon-btn"
                    disableRipple
                    endIcon={<RiCloseLine />}
                    onClick={async () => {
                      setViewAnnouncementOpen(false);
                      await axios.post(
                        `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/popupClosed`,
                        {
                          ids: unreadIds,
                        }
                      );

                      //   dispatch(setTotalAnnouncements(response?.data?.unreadCount));
                    }}
                  ></Button>
                </Box>
              </Box>
              {/* {console.log(announcementList,"announcementList")} */}

              <Slider {...settings}>
                {announcementList?.length > 0
                  ? announcementList?.map((announcement, index) => {
                      return (
                        <Box
                          key={index}
                          variant="div"
                          component="div"
                          sx={{ minHeight: "100%", height: "100%" }}
                          className="announcement-detail"
                        >
                          <Typography
                            variant="body1"
                            component="label"
                            className="announcement-title"
                            sx={{
                              paddingBottom: "8px",
                              cursor: "default",
                              whiteSpace: "pre-wrap",
                              color: "#7cb900 !important",
                            }}
                          >
                            {announcement?.title || "No Title"}
                          </Typography>
                          <Box
                            sx={{
                              width: "100%",
                              margin: "0px",
                              marginBottom: "25px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              component="label"
                              className="announcement-desc"
                              sx={{
                                cursor: "default",
                                whiteSpace: "pre-wrap",
                                // paddingRight:"0px",
                                // padding:"100px",
                                color: "black",
                                minHeight: {
                                  xs: "50vh", // Extra small screens
                                  sm: "40vh", // Small screens
                                  md: "30vh", // Medium screens
                                },
                                height: {
                                  xs: "50vh", // Extra small screens
                                  sm: "40vh", // Small screens
                                  md: "30vh", // Medium screens
                                },
                                maxHeight: "calc(100vh-200px)",
                                overflowY: "auto",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: `<div style="display: flex; flex-direction: column;">${
                                  announcement?.description || "No Content"
                                }</div>`,
                              }}
                            />
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "10px",
                            }}
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              sx={{ fontSize: "15px" }}
                            >
                              {`${announcement?.authorInfo?.first_name} ${announcement?.authorInfo?.last_name}` ||
                                "Anonymous"}
                            </Typography>
                            <Typography
                              variant="body1"
                              component="div"
                              sx={{ fontSize: "15px" }}
                            >
                              {announcement?.formattedDate}
                            </Typography>
                          </Box>
                        </Box>
                      );
                    })
                  : null}
              </Slider>
            </Box>
          </Box>
        </Slide>
      </Modal>
    </>
  );
};

export default AnnoncementModelSlider;

import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { RiCloseLine, RiCloseCircleLine } from "react-icons/ri";
import { editContactByIdAdmin } from "../../store/slices/editContactByIdSlice";
import { successToast } from "../../responses/successToast";
import { fetchContacts } from "../../store/slices/contactSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchContactById } from "../../store/slices/contactByIdSlice";
import { maskEmail, maskPhoneNumber } from "../../utils/masking";
import { selectIsAdmin } from "../../constants/selectors";

const EditcontactDrawer = ({
  editOpen,
  setEditOpen,
  currentPage,
  contId,
  clientId,
}) => {
  const dispatch = useDispatch();
  const contacts = useSelector((state) => state.contactById.contacts);
  const getUserRole = useSelector(selectIsAdmin);
  const [roleEmail, setRoleEmail] = useState(getUserRole);
  const [rolePhone, setRolePhone] = useState(getUserRole);
  const [conPer, setConPer] = useState("");
  const [conPerNo, setConPerNo] = useState("");
  const [conPerEmail, setConPerEmail] = useState("");
  const [nameError, setNameError] = useState("");
  const [contactError, setContactError] = useState("");
  const toatlClientsPerPage = 10;
  const contactId = contId;
  // console.log(contactId, "contactid from drawer");
  // console.log(contId, "contid from drawer");
  const handleCloseDrawer = () => {
    setEditOpen(false);
    setConPer(contacts?.contact_person_name);
    setConPerEmail(contacts?.contact_person_email);
    setConPerNo(contacts?.contact_person_phone);
    setNameError("");
    setContactError("");
    setRoleEmail(false);
    setRolePhone(false);
  };

  const validateEmail = (email) => {
    const emailPattern =
      /^[a-zA-Z0-9](?!.*\.\.)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,63})+$/;
    return emailPattern.test(email);
  };

  useEffect(() => {
    dispatch(fetchContactById({ contId }));
  }, [dispatch, contId]);
  // console.log(contacts, "contact data");

  useEffect(() => {
    setConPer(contacts?.contact_person_name);
    if (
      contacts?.contact_person_email === null ||
      contacts?.contact_person_email === ""
    ) {
      setConPerEmail("");
      setRoleEmail(true);
    } else {
      setConPerEmail(contacts?.contact_person_email);
      // setRoleEmail(false);
    }
    if (
      contacts?.contact_person_phone === null ||
      contacts?.contact_person_phone === ""
    ) {
      setConPerNo("");
      setRolePhone(true);
    } else {
      setConPerNo(contacts?.contact_person_phone);
      // setRolePhone(false);
    }
  }, [contacts]);

  const submitContact = () => {
    setNameError("");
    setContactError("");

    // Validate name and either email or contact number
    let flag = 0;
    if (!conPer.trim()) {
      setNameError("Please enter contact person name");
      flag = 1;
    }

    if (!conPerEmail.trim() && !conPerNo.trim()) {
      setContactError("Please enter either email or contact number");
      flag = 1;
    }
    if (conPerEmail.trim() && !validateEmail(conPerEmail.trim())) {
      setContactError("Please enter valid email");
      flag = 1;
    }

    if (flag !== 0) {
      return;
    }
    const contactData = {
      contact_person_name: conPer,
      contact_person_email: getUserRole
        ? conPerEmail
        : conPerEmail || contacts?.contact_person_email,
      contact_person_phone: getUserRole
        ? conPerNo
        : conPerNo || contacts?.contact_person_phone,
      is_Primary: contacts?.is_primary,
    };
    dispatch(editContactByIdAdmin(contactId, contactData))
      .then((response) => {
        if (response.success) {
          // console.log(response, "api response");
          setEditOpen(false);
          successToast("Contact updated successfully!");
          dispatch(
            fetchContacts({
              clientId: clientId,
              page: "",
              limit: toatlClientsPerPage,
            })
          );
        } else {
          console.error("Error creating client:", response.error);
        }
      })
      .catch((error) => {
        console.error("Error creating client:", error.message);
      });
  };

  // const updateContact = () => {
  //   // Handle update logic here
  // };

  return (
    <Drawer
      className="side-drawer client-contact-drawer"
      anchor="right"
      open={editOpen}
      onClose={() => {
        handleCloseDrawer();
      }}
    >
      <Box className="sidebar-tab-wrapper">
        <Box className="close-btn" onClick={handleCloseDrawer}>
          <RiCloseLine />
        </Box>
        <Box className="sidebar-tab">
          <Tab
            className="tab-label-btn contact-label"
            label="Edit Contact"
            disabled
          ></Tab>
        </Box>
      </Box>

      <Stack spacing={2} sx={{ padding: "24px" }}>
        <Box>
          <Typography variant="body1" component="label">
            Contact Person
            <Typography variant="body1" component="span" className="asterisk">
              *
            </Typography>
          </Typography>
          <TextField
            className="input-field"
            fullWidth
            id="outlined-basic"
            label=""
            variant="outlined"
            size="small"
            placeholder="Enter Contact Person"
            value={conPer}
            onChange={(event) => {
              setConPer(event.target.value);
              if (event.target.value) {
                setNameError("");
              } else {
                setNameError("Please enter contact person name");
              }
            }}
          />
          {nameError && (
            <Typography variant="body2" className="error" color="error">
              {nameError}
            </Typography>
          )}
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Contact Person's Phone No.
          </Typography>
          <Box variant="div" component="div" className="cross-line-icon">
            <TextField
              className="input-field"
              fullWidth
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
              placeholder="Enter Contact Person's Phone No."
              // type="number"
              value={rolePhone ? conPerNo : maskPhoneNumber(conPerNo)}
              onChange={(event) => {
                setConPerNo(event.target.value);
                if (event.target.value) {
                  setContactError("");
                }
              }}
              InputProps={{
                readOnly: !rolePhone && conPerNo,
              }}
            />
            {!rolePhone && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={() => {
                  setConPerNo("");
                  setRolePhone(true); // Set role to true to make the field editable
                }}
              />
            )}
          </Box>
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Contact Person's Email
          </Typography>
          <Box variant="div" component="div" className="cross-line-icon">
            <TextField
              className="input-field"
              fullWidth
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
              placeholder="Enter Contact Person's Email"
              value={roleEmail ? conPerEmail : maskEmail(conPerEmail)}
              onChange={(event) => {
                setConPerEmail(event.target.value);
                if (event.target.value && validateEmail(event.target.value)) {
                  setContactError("");
                } else if (
                  event.target.value &&
                  !validateEmail(event.target.value)
                ) {
                  setContactError("Please enter valid email");
                } else {
                  setContactError(
                    "Please enter either email or contact number"
                  );
                }
              }}
              InputProps={{
                readOnly: !roleEmail && conPerEmail,
              }}
            />
            {!roleEmail && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={() => {
                  setConPerEmail("");
                  setRoleEmail(true); // Set role to true to make the field editable
                }}
              />
            )}
          </Box>
          {contactError && (
            <Typography variant="body2" className="error" color="error">
              {contactError}
            </Typography>
          )}
        </Box>
        <Box className="filter-button-wrapper">
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn"
            disableRipple
            onClick={submitContact}
          >
            Save
          </Button>
          <Button
            variant="contained"
            size="large"
            className="secondary-btn btn"
            disableRipple
            onClick={handleCloseDrawer}
          >
            Cancel
          </Button>
        </Box>
      </Stack>
    </Drawer>
  );
};

export default EditcontactDrawer;

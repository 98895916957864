import { callApi } from "../../components/common/commonfunctionforapi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const fetchLeadReports = createAsyncThunk(
  "leads/fetchLeadReports",
  async ({ startDate, endDate, user }, { rejectWithValue }) => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/reports/leads`,
        params: {
          startdate: startDate,
          enddate: endDate,
          user: user,
        },
      });
      // console.log(response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  leadReports: [],
  status: "idle",
  error: null,
};
const leadReportSlice = createSlice({
  name: "leadReports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadReports.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLeadReports.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.leadReports = action.payload;
      })
      .addMatcher(
        // Handle both rejected and fulfilled states with the same action
        (action) => action.type.startsWith("leads/fetchLeadReports/"),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            // Handle the rejected state with the defined value
            state.status = "failed";
            state.error = action.payload.message;
          }
        }
      );
  },
});

export default leadReportSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";
// import { successToast } from "../../responses/successToast";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const createClientSlice = createSlice({
  name: "createClient",
  initialState,
  reducers: {
    createClientStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createClientSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    createClientFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { createClientStart, createClientSuccess, createClientFailure } =
  createClientSlice.actions;

export default createClientSlice.reducer;

export const createClient = (clientData) => async (dispatch) => {
  try {
    dispatch(createClientStart());

    const response = await callApi({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/client`,
      data: clientData,
    });

    const data = response.data;
    // console.log(response.data, "lead slice");
    dispatch(createClientSuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(createClientFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status_code,
      })
    );
    return { success: false, error: error.response.message };
  }
};

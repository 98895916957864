import { callApi } from '../../../components/common/commonfunctionforapi';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
export const fetchTemplateById = createAsyncThunk(
  'Template/fetchTemplateById',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await callApi({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/cv_builders/${id}`,
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  template: null,
  status: 'idle',
  error: null,
};
const templateByIdSlice = createSlice({
  name: 'templatebyId',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplateById.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTemplateById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.template = action.payload;
      })
      .addMatcher(
        // Handle both rejected and fulfilled states with the same action
        (action) => action.type.startsWith('Template/fetchTemplateById/'),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            // Handle the rejected state with the defined value
            state.status = 'failed';
            state.error = action.payload.message;
          }
        }
      );
  },
});

export default templateByIdSlice.reducer;

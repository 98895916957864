import React, { useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@mui/material';

import '../../styles/cv-builder.scss';
import { RiArrowLeftLine } from 'react-icons/ri';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTemplateById } from '../../store/slices/cv-builder/templateByIdSlice';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import JoditEditor from 'jodit-react';

import '../../styles/cv-builder.scss';

import ExportPdfCVModal from '../../components/common/exportPDFCVModal';

import { callApi } from '../../components/common/commonfunctionforapi';
import { successToast } from '../../responses/successToast';
import Loader from '../../components/common/loader';
import { CvHtmlFormat } from './cvHtmlFormat';
import axios from 'axios';
import { setUserRole } from '../../store/slices/userRoleSlice';
import { Jodit } from 'jodit';
import PreviewCVModal from '../../components/common/previewCVModal';
const AllTemplate = {
  id: 0,
  name: 'All',
};
const CVEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      template: AllTemplate,
    };
  }
  const dispatch = useDispatch();
  const { id, cvId } = useParams();
  const cv = useSelector((state) => state.templatebyId.template);
  const sidebarState = useSelector((state) => state.sidebar.sidebarStatus);
  const [htmlContent, setHtmlContent] = useState();
  const [exportCancel, setExportCancel] = useState(false);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isSmallDevice = useMediaQuery('only screen and (max-width : 576px)');
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const editor = useRef(null);

  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      height: '480px',
      width: '100%',
      showTooltip: true,
      useNativeTooltip: true,
      enableDragAndDropFileToEditor: true,
      autofocus: true,
      cursorAfterAutofocus: 'end',
      mobile: 'void',

      buttons: [
        'bold',
        'italic',
        'underline',
        '|',
        'ul',
        'ol',
        '|',
        'font',
        'fontsize',
        'brush',

        '|',

        'link',
        '|',
        'left',
        'center',
        'right',
        'justify',
        '|',
        'undo',
        'redo',
        '|',
        'hr',
      ],
      buttonsXS: [
        'bold',
        'italic',
        'underline',
        '|',
        'ul',
        'ol',
        '|',
        'font',
        'fontsize',
        'brush',

        '|',

        'link',
        '|',
        'left',
        'center',
        'right',
        'justify',
        '|',
        'undo',
        'redo',
        '|',
        'hr',
      ],
      buttonsSM: [
        'bold',
        'italic',
        'underline',
        '|',
        'ul',
        'ol',
        '|',
        'font',
        'fontsize',
        'brush',

        '|',

        'link',
        '|',
        'left',
        'center',
        'right',
        'justify',
        '|',
        'undo',
        'redo',
        '|',
        'hr',
      ],
      buttonsMD: [
        'bold',
        'italic',
        'underline',
        '|',
        'ul',
        'ol',
        '|',
        'font',
        'fontsize',
        'brush',

        '|',

        'link',
        '|',
        'left',
        'center',
        'right',
        'justify',
        '|',
        'undo',
        'redo',
        '|',
        'hr',
      ],
      askBeforePasteHTML: true,
      // processPasteHTML: true,
      // nl2brInPlainText: true,
      // pasteExcludeStripTags: Jodit.atom(['br', 'style']),
      pasteHTMLActionList: Jodit.atom([
        { value: Jodit.constants.INSERT_AS_HTML, text: 'Keep Formatting' },
        // { value: Jodit.constants.INSERT_AS_TEXT, text: 'Insert as Text' },
        { value: Jodit.constants.INSERT_ONLY_TEXT, text: 'Insert only Text' },
      ]),
      uploader: { insertImageAsBase64URI: true },
      removeButtons: ['brush', 'file'],
      showXPathInStatusbar: false,
      // askBeforePasteHTML: true,
      // askBeforePasteFromWord: true,
      // defaultActionOnPaste: 'insert_only_text',

      toolbarAdaptive: true,
      toolbarSticky: true,
      beautifyHTMLCDNUrlsJS: true,
      editHTMLDocumentMode: true,
      defaultMode: 'MODE_WYSIWYG',
      iframe: true,
      iframeDefaultSrc:
        'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css',
      iframeBaseUrl:
        'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css',
      iframeStyle: `
      @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css');
      body { font-family: 'Calibri', sans-serif; }
    `,
      extraAllowedContent: 'i[*]{*}(*); span[*]{*}(*);',
    }),
    []
  );

  useEffect(() => {
    dispatch(fetchTemplateById({ id: cvId })).then((response) => {
      if (response?.payload?.deletedAt || response?.error) {
        navigate('/not-found');
      }
    });
  }, [dispatch, cvId, navigate]);

  useEffect(() => {
    if (cv?.id) {
      setHtmlContent(cv?.html_template);
    }
  }, [cv]);

  const handleSaveasDraft = async () => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');

    const bodyChildren = Array.from(doc.body.children);

    bodyChildren.forEach((child) => {
      if (child.tagName.toLowerCase() === 'p') {
        child.remove();
      }
    });

    const updatedHTML = doc.documentElement.outerHTML;

    setLoading(true);
    try {
      await callApi({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/cv_builders/gethtmldata`,
        data: {
          cv_id: cvId,
          htmltemplate: updatedHTML,
          is_download: 'false',
        },
      });
      navigate('/cv-builder', {
        state: {
          template: state.template,
        },
      });
      successToast('CV Saved Succesfully.');
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const handleReset = () => {
    setAnchorEl(false);
    if (cv?.id) {
      setHtmlContent(cv?.html_template);
    }
  };
  const handlePreview = async () => {
    setOpen(true);
    setAnchorEl(false);
  };
  const handleResetTemplate = async () => {
    setAnchorEl(false);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/cv_builders/${id}`
    );
    if (data) {
      const htmltemplate = CvHtmlFormat(
        data?.data,
        cv?.employee_name,
        cv?.cv_title,
        cv?.education
      );
      setHtmlContent(htmltemplate);
    }
  };
  const handleClosePreview = () => {
    setOpen(false);
  };

  return (
    <Box className='cvbuilder-edit-container'>
      <Box className='content-header'>
        <Typography variant='h2' component='h2' className='title-with-btn'>
          <Button
            variant='outlined'
            className='action-btn back-btn'
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() =>
              navigate('/cv-builder', {
                state: {
                  template: state.template,
                },
              })
            }
          ></Button>
          Edit CV (Template: {cv?.template_name})
        </Typography>
      </Box>

      <Box className='content-wrapper'>
        {loading && <Loader />}
        {htmlContent ? (
          <JoditEditor
            ref={editor}
            value={htmlContent}
            config={config}
            tabIndex={1}
            onChange={(newContent) => setHtmlContent(newContent)}
          />
        ) : (
          <JoditEditor
            ref={editor}
            value='<html><body>Loading...</body></html>'
            config={config}
            tabIndex={1}
          />
        )}
      </Box>

      <Box
        className={
          sidebarState ? 'footer-wrapper' : 'footer-wrapper full-width-btn'
        }
      >
        {!isSmallDevice && (
          <>
            {' '}
            <Button
              variant='contained'
              size='large'
              className='primary-btn btn w-120'
              disableRipple
              onClick={handlePreview}
            >
              Preview
            </Button>
            <Button
              variant='contained'
              size='large'
              className='primary-btn btn w-120'
              disableRipple
              onClick={handleResetTemplate}
            >
              Reset To Template
            </Button>
            <Button
              variant='contained'
              size='large'
              className='primary-btn btn w-120'
              disableRipple
              onClick={handleReset}
            >
              Reset
            </Button>
            <Button
              variant='contained'
              size='large'
              className='primary-btn btn w-120'
              disableRipple
              onClick={handleSaveasDraft}
            >
              Save as Draft
            </Button>
          </>
        )}

        <Button
          variant='contained'
          size='large'
          className='primary-btn btn w-120'
          disableRipple
          onClick={() => setExportCancel(true)}
        >
          Download PDF
        </Button>
        <Button
          variant='contained'
          size='large'
          className='secondary-btn btn w-120'
          onClick={() =>
            navigate('/cv-builder', {
              state: {
                template: state.template,
              },
            })
          }
          disableRipple
        >
          Cancel
        </Button>
        {isSmallDevice && (
          <>
            <IconButton
              aria-label='more'
              aria-controls='long-menu'
              aria-haspopup='true'
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              PaperProps={{
                style: {
                  marginTop: '-10px',
                },
              }}
              keepMounted
            >
              <MenuItem
                onClick={handlePreview}
                sx={{
                  padding: '10px 20px',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
              >
                Preview
              </MenuItem>
              <MenuItem
                onClick={handleResetTemplate}
                sx={{
                  padding: '10px 20px',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
              >
                Reset To Template
              </MenuItem>
              <MenuItem
                onClick={handleReset}
                sx={{
                  padding: '10px 20px',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
              >
                Reset
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleSaveasDraft();
                  setAnchorEl(false);
                }}
                sx={{
                  padding: '10px 20px',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                  },
                }}
              >
                Save As Draft
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>

      <ExportPdfCVModal
        exportCancel={exportCancel}
        setExportCancel={setExportCancel}
        cvId={cvId}
        htmlContent={htmlContent}
        employeeName={cv?.employee_name}
      />
      {open && (
        <PreviewCVModal
          closeModal={handleClosePreview}
          open={open}
          cvId={cvId}
          htmlContent={htmlContent}
        />
      )}
    </Box>
  );
};

export default CVEdit;

import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import {
  Box,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  Tooltip,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { TbArrowsSort } from "react-icons/tb";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../store/slices/userSlice";
import { RiEyeLine } from "react-icons/ri";
import { CustomTablePaginationToolbar } from "../../components/common/Pagination";

const customToolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#3e3f42",
          color: "white",
          fontSize: "11px",
          margin: "0",
          padding: "10",
          border: "1px solid white",
        },
        arrow: {
          color: "var(--color-white)",
        },
      },
    },
  },
});

function CustomTabPanel(props) {
  const { children, value, index, usersPerPage, currentPage, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const tabLabel = (role, modules) => {
  return (
    <Box>
      <Typography variant="body1" component="span" className="user-name">
        {role}
      </Typography>{" "}
    </Box>
  );
};

const UserList = ({
  onPageChange,
  searchUser,
  onValueChange,
  onArchievePageChange,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      page: 1,
      status: 0,
      rowsPerPage: 10,
    };
  }
  const [loading, setLoading] = useState(false);
  const users = useSelector((state) => state.users.users);
   const [order, setOrder] = useState("ASC");
  const [value, setValue] = useState(state.status);
  const userType = value === 0 ? "active" : "archive";
  const [rowsPerPage, setRowsPerPage] = useState(state.rowsPerPage);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(
    state.status === 0 ? state.page : 1
  );
  const [curPage, setCurrPage] = useState(state.page);
  const [archievedCurrentPage, setArchievedCurrentPage] = useState(
    state.status === 1 ? state.page : 1
  );
  const [loadingArchive, setLoadingArchive] = useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onValueChange(newValue);
  };

  // START :: DELETE MODAL
  function capitalizeFirstLetter(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }
  const handlePageChange = (event, newPage) => {
    if (value === 0) {
      onPageChange(newPage);
      setCurrentPage(newPage);
      setCurrPage(newPage);
    } else {
      setArchievedCurrentPage(newPage);
      onArchievePageChange(newPage);
      setCurrPage(newPage);
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const archievedUsers = users?.Allusers?.filter(
    (user) => user.status === "archive"
  );
  const activeUsers = users?.Allusers?.filter(
    (user) => user.status === "active"
  );
  // END :: DELETE MODAL

  useEffect(() => {
    setLoadingArchive(true);
    dispatch(
      fetchUsers({
        status: userType,
        page: value === 0 ? currentPage : archievedCurrentPage,
        limit: +rowsPerPage,
        query: searchUser,
        order,
      })
    );
    if (value === 0) {
      setLoading(false);
    } else {
      setLoadingArchive(false);
    }
  }, [
    dispatch,
    currentPage,
    archievedCurrentPage,
    searchUser,
    value,
    userType,
    rowsPerPage,
    order
  ]);

  useEffect(() => {
    let savedSearchText = localStorage.getItem("searchUsersQuery");
    savedSearchText = savedSearchText?.replace(/^"(.+(?="$))"$/, "$1");
    if (searchUser.trim() !== "" && searchUser !== savedSearchText) {
      if (currentPage > 1 || archievedCurrentPage > 1) {
        setCurrentPage(1);
        setArchievedCurrentPage(1);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchUser]);

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      window.history.replaceState({}, "");
    });
    return () => {
      window.history.replaceState({}, "");
    };
  }, []);


  return (
    <>
      <Box variant="div" component="div" className="tab-wrapper user-listing">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tab-area user-tab-wrapper"
          >
            <Tab label={tabLabel("Active")} {...a11yProps(0)} />

            <Tab label={tabLabel("Archived")} {...a11yProps(1)} />
          </Tabs>
        </Box>
        {value === 0 ? (
          <>
            {loading ? (
              Array.from({ length: 6 }).map((_, index) => (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  key={`skeleton-${index}`}
                >
                  <TableCell>
                    <Skeleton animation="wave" height={40} width="100%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={40} width="100%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={40} width="100%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={40} width="100%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={40} width="100%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={40} width="100%" />
                  </TableCell>
                  <TableCell>
                    <Skeleton animation="wave" height={40} width={40} />
                  </TableCell>
                </TableRow>
              ))
            ) : activeUsers?.length > 0 ? (
              <CustomTabPanel
                value={value}
                index={0}
                className="user-listing-tables"
                key={`user-list-${0}`}
              >
                <TableContainer className="table-listing">
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="table"
                    className="listing-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "6px",
                          }}
                        >
                          Emp ID
                          <TbArrowsSort
                            style={{
                              stroke: "rgb(0 0 0 / 52%)",
                              width: "14px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              setOrder(order === "DESC" ? "ASC" : "DESC")
                            }
                          />
                        </TableCell>
                        <TableCell align="left">Employee Name</TableCell>
                        <TableCell align="left">Email Id</TableCell>
                        <TableCell align="left">Mobile No</TableCell>
                        <TableCell align="left">Company</TableCell>
                        <TableCell align="left">Role</TableCell>
                        <TableCell align="left">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {activeUsers?.map((user) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={user.id}
                        >
                          <TableCell align="left">
                            <Box
                              variant="div"
                              component="div"
                              className="app-name d-flex"
                            >
                              {user.employee_number ?? "-"}
                            </Box>
                          </TableCell>

                          <TableCell align="left">
                            {capitalizeFirstLetter(user.first_name)}{" "}
                            {capitalizeFirstLetter(user.last_name)}
                          </TableCell>
                          <TableCell align="left">{user.email}</TableCell>
                          <TableCell align="left">
                            {user.contact_number}
                          </TableCell>
                          <TableCell align="left">
                            {user.company_name ?? "-"}
                          </TableCell>

                          <TableCell align="left">{user.role_name}</TableCell>

                          <TableCell align="left">
                            <Box className="action-btn-wrap">
                              <ThemeProvider theme={customToolTipTheme}>
                                <Tooltip title="View">
                                  <span>
                                    <Button
                                      variant="outlined"
                                      className="action-btn view-btn"
                                      startIcon={<RiEyeLine />}
                                      disableRipple
                                      onClick={() => {
                                        navigate(`/users/${user.id}/view`, {
                                          state: {
                                            page: curPage,
                                            status: value,
                                            rowsPerPage: rowsPerPage,
                                          },
                                        });
                                      }}
                                    ></Button>
                                  </span>
                                </Tooltip>
                              </ThemeProvider>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <CustomTablePaginationToolbar
                  count={users?.total || 0}
                  page={currentPage}
                  rowsPerPage={+rowsPerPage}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </CustomTabPanel>
            ) : (
              <>
                {" "}
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Employee ID</TableCell>
                      <TableCell align="left">Employee Name</TableCell>
                      <TableCell align="left">Email Id</TableCell>
                      <TableCell align="left">Mobile No</TableCell>
                      <TableCell align="left">Company</TableCell>
                      <TableCell align="left">Role</TableCell>
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow className="no-msg">
                      <TableCell
                        colSpan={8}
                        style={{
                          width: "100%",
                          textAlign: "center",
                          color: "var(--textgrey)",
                          fontSize: "16px",
                        }}
                        className="no-msg"
                      >
                        No Users Found
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </>
            )}
          </>
        ) : loadingArchive ? (
          Array.from({ length: 6 }).map((_, index) => (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              key={`skeleton-${index}`}
            >
              <TableCell>
                <Skeleton animation="wave" height={40} width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" height={40} width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" height={40} width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" height={40} width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" height={40} width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" height={40} width="100%" />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" height={40} width={40} />
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" height={40} width={40} />
              </TableCell>
            </TableRow>
          ))
        ) : archievedUsers?.length > 0 ? (
          <CustomTabPanel
            value={value}
            index={1}
            className="user-listing-tables"
            key={`user-list-${0}`}
          >
            <TableContainer className="table-listing">
              <Table
                sx={{ minWidth: 650 }}
                aria-label="table"
                className="listing-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Employee ID</TableCell>
                    <TableCell align="left">Employee Name</TableCell>
                    <TableCell align="left">Email Id</TableCell>
                    <TableCell align="left">Mobile No</TableCell>
                    <TableCell align="left">Company</TableCell>
                    <TableCell align="left">Role</TableCell>

                    {/* <TableCell align="left">Designation</TableCell> */}
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {archievedUsers?.map((user) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={user.id}
                    >
                      <TableCell align="left">
                        <Box
                          variant="div"
                          component="div"
                          className="app-name d-flex"
                        >
                          {user.employee_number ?? "-"}
                        </Box>
                      </TableCell>

                      <TableCell align="left">
                        {capitalizeFirstLetter(user.first_name)}{" "}
                        {capitalizeFirstLetter(user.last_name)}
                      </TableCell>
                      <TableCell align="left">{user.email}</TableCell>
                      <TableCell align="left">{user.contact_number}</TableCell>
                      <TableCell align="left">
                        {user.company_name ?? "-"}
                      </TableCell>
                      <TableCell align="left">{user.role_name}</TableCell>

                      <TableCell align="left">
                        <Box className="action-btn-wrap">
                          <ThemeProvider theme={customToolTipTheme}>
                            <Tooltip title="View">
                              <span>
                                <Button
                                  variant="outlined"
                                  className="action-btn view-btn"
                                  startIcon={<RiEyeLine />}
                                  disableRipple
                                  onClick={() =>
                                    navigate(`/users/${user.id}/view`, {
                                      state: {
                                        page: curPage,
                                        status: value,
                                        rowsPerPage: rowsPerPage,
                                      },
                                    })
                                  }
                                ></Button>
                              </span>
                            </Tooltip>
                          </ThemeProvider>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {/* <Box variant="div" component="div" className="pagination-wrapper"> */}
              {/* <Pagination
                  count={users?.totalPages}
                  page={archievedCurrentPage}
                  onChange={handlePageChange}
                  shape="rounded"
                /> */}
              <CustomTablePaginationToolbar
                count={users?.total || 0}
                page={currentPage}
                rowsPerPage={+rowsPerPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              {/* </Box> */}
            </TableContainer>
          </CustomTabPanel>
        ) : (
          <>
            {" "}
            <Table
              sx={{ minWidth: 650 }}
              aria-label="table"
              className="listing-table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="left">Employee ID</TableCell>
                  <TableCell align="left">Employee Name</TableCell>
                  <TableCell align="left">Email Id</TableCell>
                  <TableCell align="left">Mobile No</TableCell>
                  <TableCell align="left">Role</TableCell>
                  <TableCell align="left">Company</TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className="no-msg">
                  <TableCell
                    colSpan={8}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "var(--textgrey)",
                      fontSize: "16px",
                    }}
                    className="no-msg"
                  >
                    No Users Found
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        )}
      </Box>
    </>
  );
};

export default UserList;

import { callApi } from "../../components/common/commonfunctionforapi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async ({status, page, limit, query, type,order }, { dispatch, rejectWithValue }) => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/users`,
        params: {
          status:status,
          page: page,
          limit: limit,
          query: query,
          type: type,
          order
        },
      });
      // console.log(response.data);

      return response.data;
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  users: [],
  status: "idle",
  error: null,
};
const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload;
      })
      .addMatcher(
        // Handle both rejected and fulfilled states with the same action
        (action) => action.type.startsWith("users/fetchUsers/"),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            // Handle the rejected state with the defined value
            state.status = "failed";
            state.error = action.payload.message;
          }
        }
      );
  },
});

export default userSlice.reducer;

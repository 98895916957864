import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";
import { setUserPermissions } from "./permissionSlice";
import { setUserRole } from "./userRoleSlice";
import getTokenFromCookies from "../../utils/token";
import { fetchApplicationPermissions } from "./applicationPermissionSlice";

const initialState = {
  user: null,
  isLoading: false,
  error: null,
  userRole: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.isLoading = false;
      state.user = action.payload;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { loginStart, loginSuccess, loginFailure } = authSlice.actions;

export default authSlice.reducer;

//API CALL
export const login = (values) => async (dispatch) => {
  try {
    dispatch(loginStart());

    const response = await callApi({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/users/login`,
      data: values,
    });
    const data = response.data;
    // console.log(data, "data");
    dispatch(loginSuccess(data));
    const [resData] = data;
    const permissions = resData?.user?.role_permissions?.permissions || [];
    const userRole = resData?.user?.role_permissions?.role_details?.role_name;
    dispatch(setUserPermissions(permissions));
    dispatch(setUserRole(userRole));
    const token = getTokenFromCookies();
    if (token) dispatch(fetchApplicationPermissions({}));
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(loginFailure(error.response.data.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.response.data.message };
  }
};

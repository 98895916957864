import React, { useLayoutEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import NoAccess from '../components/common/noAccess';
import Layout from '../components/layout';
import getTokenFromCookies from '../utils/token';
import { getHasPermission } from '../utils/userPermissions';
import Loader from '../components/common/loader';

const ProtectedRoute = ({
  permission: requiredPermission = {},
  component: Component,
}) => {
  const [hasAccess, setHasAccess] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const { userPermissions, status } = useSelector((state) => ({
    userPermissions: state.permissions.rolePermissions,
    status: state.permissions.status,
  }));

  const isLoading = useMemo(() => {
    return status === 'loading';
  }, [status]);

  let token = getTokenFromCookies();

  useLayoutEffect(() => {
    // Reset state when permission changes
    setIsFetching(() => true);
    setHasAccess(() => false);

    const hasPermissionFetched = !!Object.keys(userPermissions).length;
    if (!hasPermissionFetched) return;

    const access = getHasPermission(requiredPermission, userPermissions);

    setHasAccess(() => access);
    setIsFetching(() => false);

    return () => {
      setHasAccess(() => false);
      setIsFetching(() => true);
    };
  }, [requiredPermission, userPermissions]);

  if (isLoading) return <Loader />;

  return token ? (
    <>
      {isFetching ? (
        <Layout>
          <Loader />
        </Layout>
      ) : hasAccess ? (
        <Layout>{Component}</Layout>
      ) : (
        <NoAccess />
      )}
    </>
  ) : (
    <Navigate to='/signin' replace={true} />
  );
};

export default ProtectedRoute;

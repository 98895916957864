import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";
// import { successToast } from "../../responses/successToast";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const createLeadSlice = createSlice({
  name: "createLead",
  initialState,
  reducers: {
    createLeadStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createLeadSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    createLeadFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { createLeadStart, createLeadSuccess, createLeadFailure } =
  createLeadSlice.actions;

export default createLeadSlice.reducer;

export const createLead =
  ({ formData, client_type }) =>
  async (dispatch) => {
    try {
      dispatch(createLeadStart());

      const response = await callApi({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/leads`,
        data: formData,
        params: {
          client_type: client_type,
        },
      });

      const data = response.data;
      // console.log(response.data, "lead slice");
      dispatch(createLeadSuccess());
      // successToast("Lead creatde successfully!");
      return { success: true, message: data.message };
    } catch (error) {
      dispatch(createLeadFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status_code,
        })
      );
      return { success: false, error: error.response.message };
    }
  };

// userRolesSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userRole: null,
};

const userRolesSlice = createSlice({
  name: "userRoles",
  initialState,
  reducers: {
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
  },
});

export const { setUserRole } = userRolesSlice.actions;
export default userRolesSlice.reducer;

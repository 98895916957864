import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  activity: null,
  loading: false,
  error: null,
};

const editLeadActivityByIdSlice = createSlice({
  name: "editLeadActivityById",
  initialState,
  reducers: {
    editLeadActivityByIdStart(state) {
      state.loading = true;
      state.error = null;
    },
    editLeadActivityByIdSuccess(state, action) {
      state.loading = false;
      state.activity = action.payload;
    },
    editLeadActivityByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  editLeadActivityByIdStart,
  editLeadActivityByIdSuccess,
  editLeadActivityByIdFailure,
} = editLeadActivityByIdSlice.actions;
export default editLeadActivityByIdSlice.reducer;

export const editleadActivityById =
  ({ activityId, activityData }) =>
  async (dispatch) => {
    try {
      dispatch(editLeadActivityByIdStart());
      const response = await callApi({
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/activity/${activityId}`,
        data: activityData,
      });

      const bid = response;
      const data = response.data;
      dispatch(editLeadActivityByIdSuccess(bid));
      return { success: true, message: data.message };
    } catch (error) {
      dispatch(editLeadActivityByIdFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return { success: false, error: error.response.data.message };
    }
  };

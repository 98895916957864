import {
  Box,
  Fade,
  Modal,
  Typography,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { fetchLeadsById } from "../../store/slices/leadbyIdSlice";
import { successToast } from "../../responses/successToast";
import { fetchLeads } from "../../store/slices/leadSlice";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "rsuite";
import axios from "axios";

const AttachLeadToProject = ({
  attachCancel,
  convertLeadId,
  value,
  leadProjectName,
  setAttachCancel,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const leadId = convertLeadId;
  const [projectName, setProjectName] = useState("");
  const [projecterror, setProjectError] = useState("");
  const [startDate, setStartDate] = useState("");
  const [projectIDSelected, setProjectIDSelected] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const handleDevelopmentStatusChange = (selectedOption) => {
    setProjectError("");
    setProjectIDSelected(selectedOption);
  };

  const defaultValue = (options, value) => {
    if (Array.isArray(value)) {
      return options
        ? options?.filter((option) => value?.find((t) => t === option?.value))
        : [];
    }
    return options ? options?.find((option) => option?.value === value) : null;
  };

  useEffect(() => {
    setProjectOptions(
      projectList.map((item) => {
        return {
          value: item.id,
          label: item.project_name,
        };
      })
    );
  }, [projectList]);

  // useEffect(()=>{
  //    console.log(projectOptions)
  // },[projectOptions])

  const callProjectWithoutLeads = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/leads/data/projects`
      );
      setProjectList(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    callProjectWithoutLeads();
  }, [attachCancel]);

  const handleConvertClose = () => {
    setAttachCancel(false);
    setProjectName("");
    setStartDate("");
    setProjectIDSelected(null);
  };
  const submitHandler = () => {
    if (!projectIDSelected) {
      setProjectError("Please Select Project");
      return;
    }
    dispatch(fetchLeadsById({ leadId: leadId, projectId: projectIDSelected }));

    successToast("Lead Attached to project successfully");

    setAttachCancel(false);
    navigate("/leads");
  };

  const handleResponseDateChange = (date) => {
    setStartDate(date);
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={attachCancel}
        // onClose={ConvertLeadToProjectModalClose}
        closeAfterTransition
      >
        <Fade in={attachCancel}>
          <Box className="modal-wrapper" style={{ minHeight: "200px" }}>
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3" sx={{marginBottom:"24px"}}>
                Choose Project to attach it with this Lead
              </Typography>
              <Grid container spacing={2} sx={{marginTop:"8px"}}>
                <Grid item lg={12} md={12} sm={12} xs={12}>   
                      <Typography
                        variant="body1"
                        component="h5"
                        style={{
                          marginBottom: "4px",
                          marginRight: "20px",
                          minWidth: "190px",
                        }}
                      >
                        Choose Project
                        <Typography
                          variant="body1"
                          component="span"
                          className="asterisk"
                          style={{ color: "red" }}
                        >
                          *
                        </Typography>
                      </Typography>
                      <Select
                        name="colors"
                        options={projectOptions}
                        value={defaultValue(projectOptions, projectIDSelected)}
                        className="muilt-select-field"
                        classNamePrefix="select"
                        classNames='w-100'
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 10,
                          width:"100%",

                          colors: {
                            ...theme.colors,
                            primary25: "var(--secondary-text-color)",
                            primary: "#8CC714",
                          },
                        })}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                          }),
                        }}
                        onChange={(selectedOption) =>
                          handleDevelopmentStatusChange(selectedOption?.value)
                        }
                      />
                </Grid>
                {projecterror && (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        variant="body1"
                        component="h5"
                        style={{
                          marginBottom: "4px",
                          marginRight: "20px",
                          minWidth: "190px",
                        }}
                      ></Typography>
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                          style={{ margin: "0px" }}
                        >
                          {projecterror}
                        </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={submitHandler}
                >
                  Attach
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={handleConvertClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AttachLeadToProject;

import { callApi } from "../../components/common/commonfunctionforapi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const fetchLeadActivitiesById = createAsyncThunk(
  "activities/fetchActivitiesById",
  async ({ activityId }, { rejectWithValue }) => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/activity/get/${activityId}`,
      });
      // console.log(response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  activities: null,
  status: "idle",
  error: null,
};
const leadActivityByIdSlice = createSlice({
  name: "leadactivitybyId",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadActivitiesById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLeadActivitiesById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.activities = action.payload;
      })
      .addMatcher(
        // Handle both rejected and fulfilled states with the same action
        (action) => action.type.startsWith("activities/fetchActivitiesById/"),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            // Handle the rejected state with the defined value
            state.status = "failed";
            state.error = action.payload.message;
          }
        }
      );
  },
});

export default leadActivityByIdSlice.reducer;

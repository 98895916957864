import './styles/globals.scss';
import './styles/projects.scss';
import './styles/leads.scss';
import './styles/users.scss';
import './styles/settings.scss';
import './styles/reports.scss';

import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { fetchPermissions } from './store/slices/permissionSlice';
import { useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import getTokenFromCookies from './utils/token';
import { fetchApplicationPermissions } from './store/slices/applicationPermissionSlice';

function App() {
  const dispatch = useDispatch();
  const token = getTokenFromCookies();

  useEffect(() => {
    if (token) dispatch(fetchApplicationPermissions({}));
  }, [token, dispatch]);
  useLayoutEffect(() => {
    const token = getTokenFromCookies();

    if (token) dispatch(fetchPermissions({}));
  }, [dispatch]);

  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Routes />
      </BrowserRouter>
    </>
  );
}

export default App;

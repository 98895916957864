import {
  Box,
  // eslint-disable-next-line no-unused-vars
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Typography,
  Chip,
  Tab,
  Tabs,
} from "@mui/material";
import React from "react";
import { DateRangePicker } from "rsuite";
import { useNavigate } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeadReports } from "../../store/slices/leadReportSlice";
import { getDateRangeOptions } from "../../utils/DateRanges";

const LeadReportList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const leads = useSelector((state) => state.leadReports.leadReports);
  // console.log(leads, "leads");
  const initialSelectedDates = [new Date(), new Date()];
  const [selectedDates, setSelectedDates] = useState(initialSelectedDates);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [value, setValue] = useState(0);
  const ranges = getDateRangeOptions();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleDateRangeChange = (date) => {
    // console.log(date);
    if (date === null) {
      setSelectedDates(initialSelectedDates);
      setStartDate(new Date().toISOString().split("T")[0]);
      setEndDate(new Date().toISOString().split("T")[0]);
    } else {
      setSelectedDates(date);
    }
    if (Array.isArray(date) && date.length >= 2) {
      const formattedDates = date.map(
        (date) => date.toISOString().split("T")[0]
      );
      setStartDate(formattedDates[0]);
      setEndDate(formattedDates[1]);
    }
  };

  // function formatDate(inputDate) {
  //   const date = new Date(inputDate);
  //   const day = date.getDate();
  //   const month = date.getMonth() + 1; // Months are zero-indexed
  //   const year = date.getFullYear();

  //   const formattedDay = day < 10 ? `0${day}` : day;
  //   const formattedMonth = month < 10 ? `0${month}` : month;

  //   const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

  //   return formattedDate;
  // }

  // const generateDateRange = (start, end) => {
  //   const dateRange = [];
  //   const current = new Date(start);
  //   while (current <= end) {
  //     dateRange.push(new Date(current));
  //     current.setDate(current.getDate() + 1);
  //   }
  //   return dateRange;
  // };

  // const datesToShow = generateDateRange(new Date(startDate), new Date(endDate));

  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];

  const handleCloseButtonClick = () => {
    dispatch(
      fetchLeadReports({ startDate: formattedToday, endDate: formattedToday })
    );
  };

  useEffect(() => {
    dispatch(
      fetchLeadReports({
        startDate: startDate,
        endDate: endDate,
        user: value === 0 ? "tl" : "bd",
      })
    );
  }, [dispatch, startDate, endDate, selectedDates, value]);

  return (
    <>

      <Box className="content-header leads-overview-filter bid-overview-wrapper">
        <Box className="report-top-filter">
          <Typography variant="h2" component="h2" className="title-with-btn">
              <Button
                  variant="outlined"
                  className="action-btn back-btn"
                  startIcon={<RiArrowLeftLine />}
                  disableRipple
                  onClick={() => navigate("/reports")}
                  ></Button>
                Leads Overview
          </Typography>
          <DateRangePicker
            showOneCalendar
            className="modal-date-picker"
            style={{ width: "241px" }}
            ranges={ranges}
            onChange={handleDateRangeChange}
            value={selectedDates}
            placeholder="DD-MM-YYYY~DD-MM-YYYY"
            format="dd-MM-yyyy"
            renderStaticRangeLabel={() => null} // This removes the predefined ranges label
            renderCalendarInfo={() => (
              <button onClick={handleCloseButtonClick}>Close</button>
            )}
          />
        </Box>
      </Box>
      <Box className="content-layout leads-overflow-wrapper">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="tab-project-wrapper">
              <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="tab-area"
                  >
                    <Tab
                      label="TL"
                      style={{ color: "var(--primary-text-color)" }}/>
                    <Tab label="BD"style={{ color: "var(--primary-text-color)" }}/>
              </Tabs>
            <Box variant="div" component="div" className="content-header-right">
                <Box
                  style={{
                      display: "flex",
                      paddingRight: "10px",
                      gap: "8px",
                      alignItems: "center",
                      marginRight: "16px",
                      flexWrap:"wrap"
                    }}
                  >
                    <Box sx={{display:'flex', alignItems:"center", gap:"8px"}}>
                      <Box
                        style={{
                          borderRadius: "50%",
                          background: "var(--light-green)",
                          width: "12px",
                          height: "12px",
                          marginBottom: "2px",
                        }}
                      ></Box>
                      <Typography variant="body1" className="field-title">
                        {
                          <span style={{ fontWeight: "600", fontSize: "12px" }}>
                            Ongoing
                          </span>
                        }
                      </Typography>
                    </Box>
                    <Box sx={{display:'flex', alignItems:"center", gap:"8px"}}>
                      <Box
                        style={{
                          borderRadius: "50%",
                          background: "var(--fire-btn-hover)",
                          width: "12px",
                          height: "12px",
                          marginBottom: "2px",
                        }}
                      ></Box>
                      <Typography variant="body1" className="field-title">
                        {
                          <span style={{ fontWeight: "600", fontSize: "12px" }}>
                            Planned
                          </span>
                        }
                      </Typography>
                    </Box>
                    <Box sx={{display:'flex', alignItems:"center", gap:"8px"}}>
                      <Box
                        style={{
                          borderRadius: "50%",
                          background: "var(--delete-btn-hover)",
                          width: "12px",
                          height: "12px",
                          marginBottom: "2px",
                        }}
                      ></Box>
                      <Typography variant="body1" className="field-title">
                        {
                          <span style={{ fontWeight: "600", fontSize: "12px" }}>
                            Stopped
                          </span>
                        }
                      </Typography>
                    </Box>
                </Box>
            </Box>
          </Box>
          <Box  sx={{padding:"24px"}}>
            <TableContainer
              className="billable-report-table-wrapper"
              style={{
                maxHeight:
                  leads?.length !== 0 ? "calc(100vh - 230px)" : "auto",
                borderRadius: "5px",
                width: "100%",
                overflowX: "auto",
                whiteSpace: "nowrap",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        position: "sticky",
                        left: "0",
                        top: "0px",
                        zIndex: 3,
                        backgroundColor: "#323232",
                        minWidth: "300px",
                        width: "300px",
                        borderBottom: "2px solid white",
                      }}
                      className="report-table-date"
                    >
                      {" "}
                    </TableCell>

                    <>
                      <TableCell
                        //   key={index + "billable"}
                        style={{
                          textAlign: "center",
                          color: "#ffffff",
                          borderLeft: "1px solid white",
                          position: "sticky",
                          top: "0px",
                          backgroundColor: "#323232",
                          zIndex: 2,
                          fontWeight: 600,
                          borderBottom: "2px solid white",
                          width: "280px",
                          minWidth: "280px",
                        }}
                      >
                        Leads
                      </TableCell>
                      <TableCell
                        //   key={index + "nonbillable"}
                        style={{
                          textAlign: "center",
                          color: "#ffffff",
                          borderLeft: "1px solid white",
                          position: "sticky",
                          top: "0px",
                          backgroundColor: "#323232",
                          zIndex: 2,
                          fontWeight: 600,
                          borderBottom: "2px solid white",
                          width: "280px",
                          minWidth: "280px",
                          boxSizing: "border-box",
                        }}
                      >
                        <Typography
                          variant="div"
                          component="div"
                          style={{ color: "#ffffff" }}
                        >
                          Projects
                        </Typography>
                      </TableCell>
                      <TableCell
                        //   key={index + "closure"}
                        style={{
                          textAlign: "center",
                          color: "#ffffff",
                          borderLeft: "1px solid white",
                          position: "sticky",
                          top: "0px",
                          backgroundColor: "#323232",
                          zIndex: 2,
                          fontWeight: 600,
                          borderBottom: "2px solid white",
                          width: "280px",
                          minWidth: "280px",
                        }}
                      >
                        Success Rate (%)
                      </TableCell>
                    </>
                  </TableRow>

                  {leads?.map((lead, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            position: "sticky",
                            left: "0",
                            zIndex: 1,
                            // backgroundColor: "#ffff",
                            borderBottom: "none",
                            backgroundColor: "rgb(197, 227, 137)",
                            fontWeight: 600,
                          }}
                        >
                          <Box>{`${lead.first_name} ${lead.last_name}`}</Box>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {lead.leadsCount}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {lead.projectsCount}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                          }}
                        >
                          {`${lead.successRatio} %`}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          sx={{
                            textAlign: "left",
                            position: "sticky",
                            left: "0",
                            zIndex: 1,
                            // backgroundColor: "#ffff",
                            backgroundColor: "rgb(197, 227, 137)",
                            borderBottom: "1px solid white",
                          }}
                        ></TableCell>
                        <TableCell
                          colSpan={3}
                          sx={{
                            borderRight: "1px solid rgba(224, 224, 224, 1)",
                            maxWidth: "500px", // Set your desired maximum width
                            whiteSpace: "normal", // Allow content to wrap
                          }}
                        >
                          {/* Display project names */}
                          <Box
                            variant="div"
                            component="div"
                            className="report-tag-wrap"
                            style={{ flexWrap: "wrap" }}
                          >
                            {lead.project_project_name.map((project) => (
                              <Chip
                                label={project.project_name}
                                key={project.id} // Make sure to add a unique key prop
                                component="a" // Render Chip as an anchor element
                                href={`/projects/${project.id}/view`} // URL for the project view page
                                target="_blank"
                                clickable
                                className={`chip-hover ${
                                  project.development_status === "ongoing"
                                    ? "ongoing-class"
                                    : project.development_status ===
                                      "planned"
                                    ? "planned-class"
                                    : project.development_status ===
                                      "stopped"
                                    ? "stopped-class"
                                    : ""
                                }`}
                              />
                            ))}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableHead>
              </Table>
            </TableContainer>
          </Box>
      </Box>
    </>
  );
};

export default LeadReportList;

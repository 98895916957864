import React, { useRef, useEffect } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Stack,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import {
  RiAddCircleLine,
  RiArrowLeftLine,
  RiDeleteBinLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { fetchKeywords } from "../../store/slices/keywordSlice";
import { fetchTechnologies } from "../../store/slices/technologySlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createProject } from "../../store/slices/createProjectSlice";
import { successToast } from "../../responses/successToast";
import KeywordModal from "../../components/common/keywordModal";
import TechnologyModal from "../../components/common/technologyModal";
import AddKeywordModal from "../../components/common/addKeywordModal";
import AddTechnologyModal from "../../components/common/addTechnologySlice";
import { fetchClients } from "../../store/slices/clientLeadSlice";
import { fetchContacts } from "../../store/slices/contactSlice";
import { fetchUsers } from "../../store/slices/userSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { callApi } from "../../components/common/commonfunctionforapi";
import ProjectEmailAlertModal from "../../components/common/projectEmailAlertModal";
import { DatePicker } from "rsuite";
import { maskEmail, maskPhoneNumber } from "../../utils/masking";
import { selectIsAdmin } from "../../constants/selectors";
// PROJECT STATUS OPTIONS
const projectStatusOptions = [
  { value: "live", label: "Live" },
  { value: "developing", label: "Development" },
  { value: "beta_testing", label: "Beta Testing" },
  { value: "abandoned", label: "Abandoned" },
];

const developmentStatusOptions = [
  { value: "ongoing", label: "Ongoing" },
  { value: "planned", label: "Planned" },
];

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};

function capitalizeWords(input) {
  return input?.replace(/\w+/g, function (word) {
    return word?.charAt(0).toUpperCase() + word.slice(1);
  });
}

const ProjectCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getUserRole = useSelector(selectIsAdmin);
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const keywords = useSelector((state) => state.keywords.keywords);
  const technologies = useSelector((state) => state.technologies.technologies);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [keywordPayload, setKeywordPayload] = useState([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const [technologyPayload, setTechnologyPayload] = useState([]);
  const [keyFeatures, setKeyFeatures] = useState("");
  const [keyIntegrations, setKeyIntegrations] = useState("");
  const [primaryTl, setPrimaryTl] = useState("");
  const [secondaryTl, setSecondaryTl] = useState("");
  const [primaryBD, setPrimaryBD] = useState("");
  const [secondaryBD, setSecondaryBD] = useState("");
  const [projectStatus, setProjectStatus] = useState("");
  const [developmentStatus, setDevelopmentStatus] = useState("");
  const [uploadLink, setUploadLink] = useState("");
  const [documentUploadLink, setDocumentUploadLink] = useState("");
  const [filename, setFileName] = useState("");
  const [displayedVideos, setDisplayedVideos] = useState([]);
  const [displayedDocuments, setDisplayedDocuments] = useState([]);
  const [displayedfilenames, setDisplayedFilenames] = useState([]);
  const [commonErrorMsg, setCommonErrorMsg] = useState("");
  const [proNameErrorMsg, setProNameErrorMsg] = useState("");
  const [proDescErrorMsg, setProDescErrorMsg] = useState("");
  const [keywordsErrorMsg, setKeywordsErrorMsg] = useState("");
  const [technologyErrorMsg, setTechnologyErrorMsg] = useState("");
  const [priTLErrorMsg, setPriTLErrorMsg] = useState("");
  const [priBDErrorMsg, setPriBDErrorMsg] = useState("");
  const [proStatusErrorMsg, setProStatusErrorMsg] = useState("");
  const [deveStatuserrorMsg, setDeveStatusErrorMsg] = useState("");
  const [keywordModalOpen, setKeywordModalOpen] = useState(false);
  const [technologyModalOpen, setTechnologyModalOpen] = useState(false);
  const [invalidValue, setInvalidValue] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [techLoading, setTechLoading] = useState(false);
  const [addTechModal, setAddTechModal] = useState(false);
  const [validTechName, setValidTechName] = useState("");
  const [type /*setType*/] = useState("");
  const [uploadLinkError, setUploadLinkError] = useState("");
  const [value, setValue] = useState("new_opportunity");
  const clients = useSelector((state) => state.clientsLead.clients?.[0]);
  const [companies, setCompanies] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyPayload, setCompanyPayload] = useState("");
  const [compEmailError, setCompEmailError] = useState("");
  const [conPerEmailError, setContactPerEmailError] = useState("");
  const [compName, setCompName] = useState("");
  const [compEmail, setCompEmail] = useState("");
  const [compNumber, setCompNumber] = useState("");
  const [conPerson, setConPerson] = useState("");
  const [conPersonEmail, setConPersonEmail] = useState("");
  const [conPersonNumber, setConPersonNumber] = useState("");
  const [conPerErrorMsg, setConPerErrorMsg] = useState("");
  const contacts = useSelector((state) => state.contacts.contacts);
  const [companiesperson, setCompaniesPerson] = useState("");
  const [companyPayloadPerson, setCompanyPayloadPerson] = useState("");
  const [companyerror, setCompanyError] = useState("");
  const [documentlinkerror, setdocumentLinkError] = useState("");
  const [imageUploadLink, setImageUploadLink] = useState("");
  const [videoFileName, setVideoFileName] = useState("");
  const [imageFileName, setImageFileName] = useState("");
  const [displayedImages, setDisplayedImages] = useState([]);
  const [imagelinkerror, setImageLinkError] = useState("");
  const [displayedVideofilenames, setDisplayedVideoFilenames] = useState([]);
  const [displayedImagefilenames, setDisplayedImageFilenames] = useState([]);
  const [emailOpen, setEmailOpen] = useState(false);
  const [sameEmailMessage, setSameEmailMessage] = useState("");
  const [startDate, setStartDate] = useState("");

  const maxLength = 31;

  const validateEmail = (email) => {
    const emailPattern =
      /^[a-zA-Z0-9](?!.*\.\.)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,63})+$/;
    return emailPattern.test(email);
  };
  
  const handleRemoveImage = (index) => {
    const updatedVideos = [...displayedImages];
    const updatedVideoPaths = [...displayedImagefilenames];

    updatedVideos.splice(index, 1);
    updatedVideoPaths.splice(index, 1);

    setDisplayedImages(updatedVideos);
    setDisplayedImageFilenames(updatedVideoPaths);
  };

  const handleRemoveVideo = (index, fromArray) => {
    const updatedVideos = [...displayedVideos];
    const updatedVideoPaths = [...displayedVideofilenames];

    updatedVideos.splice(index, 1);
    updatedVideoPaths.splice(index, 1);

    setDisplayedVideos(updatedVideos);
    setDisplayedVideoFilenames(updatedVideoPaths);
  };

  const handleRemoveDocument = (index) => {
    const updatedDocuments = [...displayedDocuments];
    const updatedDocPaths = [...displayedfilenames];

    updatedDocuments.splice(index, 1);
    updatedDocPaths.splice(index, 1);

    setDisplayedDocuments(updatedDocuments);
    setDisplayedFilenames(updatedDocPaths);
  };

  const addVideo = () => {
    if (!uploadLink) {
      setUploadLinkError("Please Enter Video cast Link");
      return;
    }
    const newVideo = uploadLink;

    setDisplayedVideos([...displayedVideos, newVideo]);
    setDisplayedVideoFilenames((prevFilenames) => [
      ...prevFilenames,
      videoFileName,
    ]);
    setVideoFileName("");
    setUploadLink("");
  };

  const addImage = () => {
    if (!imageUploadLink) {
      setImageLinkError("Please Enter Screenshot Link");
      return;
    }
    const newVideo = imageUploadLink;

    setDisplayedImages([...displayedImages, newVideo]);
    setDisplayedImageFilenames((prevFilenames) => [
      ...prevFilenames,
      imageFileName,
    ]);
    setImageFileName("");
    setImageUploadLink("");
  };

  const addDocument = (index) => {
    if (!documentUploadLink) {
      setdocumentLinkError("Please Enter Document Link");
      return;
    }
    const newVideo = documentUploadLink;

    setDisplayedDocuments([...displayedDocuments, newVideo]);
    setDisplayedFilenames((prevFilenames) => [...prevFilenames, filename]);
    setFileName("");
    setDocumentUploadLink("");
  };

  const formData = new FormData();

  displayedImages.forEach((image, index) => {
    formData.append(`screenshot_files[${[index]}]`, image);
  });
  displayedImagefilenames.forEach((image, index) => {
    formData.append(`screenshot_file_name[${[index]}]`, image);
  });

  displayedVideos.forEach((video, index) => {
    formData.append(`video_urls[${[index]}]`, video);
  });
  displayedVideofilenames.forEach((video, index) => {
    formData.append(`video_file_name[${[index]}]`, video);
  });

  displayedDocuments.forEach((document, index) => {
    formData.append(`document_files[${[index]}]`, document);
  });
  displayedfilenames.forEach((document, index) => {
    formData.append(`file_name[${[index]}]`, document);
  });
  // START :: PROJECT LINK ADD AND DELETE
  const [projectLinks, setProjectLinks] = useState([
    {
      linkText: "",
    },
  ]);
  const addProjectLink = () => {
    let link = {
      linkText: "",
    };
    setProjectLinks((current) => [...current, link]);
  };

  const addTextToLink = (value, i) => {
    let linkArray = [...projectLinks];
    linkArray[i].linkText = value;
    setProjectLinks(linkArray);
  };

  const handleDeleteLink = (i) => {
    let linkArray = [...projectLinks];
    linkArray.splice(i, 1);
    setProjectLinks(linkArray);
  };
  // END :: PROJECT LINK ADD AND DELETE

  // START :: REFERENCE LINK ADD AND DELETE
  const [ReferenceLinks, setReferenceLinks] = useState([
    {
      linkText: "",
    },
  ]);
  const addReferenceLink = () => {
    let link = {
      linkText: "",
    };
    setReferenceLinks((current) => [...current, link]);
  };

  const addReferenceToLink = (value, i) => {
    let linkArray = [...ReferenceLinks];
    linkArray[i].linkText = value;
    setReferenceLinks(linkArray);
  };

  const handleReferenceDeleteLink = (i) => {
    let linkArray = [...ReferenceLinks];
    linkArray.splice(i, 1);
    setReferenceLinks(linkArray);
  };

  const [defaultContactPerson, setDefaultContactPerson] = useState(null);
  const [selectedContact, setSelectedContact] = useState(defaultContactPerson);

  useEffect(() => {
    if (contacts?.contacts && contacts?.contacts.length > 0) {
      const primaryContact = contacts?.contacts?.find(
        (contact) => contact.is_primary
      );

      if (primaryContact) {
        setSelectedContact({
          label: primaryContact.contact_person_name,
          value: primaryContact.id,
        });
      }
    }
  }, [contacts, companyName]);

  const [saveButtonClickCount, setSaveButtonClickCount] = useState(0);
  const proDescErrorMsgRef = useRef(null);
  const primaryTlRef = useRef(null);
  const contactPersonRef = useRef(null);
  const primaryBDRef = useRef(null);
  const companyNameRef = useRef(null);
  const statusRef = useRef(null);
  const devStatusRef = useRef(null);
  const keywordsErrorMsgRef = useRef(null);
  const technologyErrorMsgRef = useRef(null);
  const projectnameRef = useRef(null);
  const compEmailRef = useRef(null);
  const contEmailRef = useRef(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (compEmailError && compEmailRef.current) {
      compEmailRef.current.focus();
    }
  }, [compEmailRef, compEmailError, saveButtonClickCount]);

  useEffect(() => {
    if (conPerEmailError && contEmailRef.current) {
      contEmailRef.current.focus();
    }
  }, [conPerEmailError, contEmailRef, saveButtonClickCount]);

  useEffect(() => {
    if (deveStatuserrorMsg && devStatusRef.current) {
      devStatusRef.current.focus();
    }
  }, [devStatusRef, deveStatuserrorMsg, saveButtonClickCount]);

  useEffect(() => {
    if (proStatusErrorMsg && statusRef.current) {
      statusRef.current.focus();
    }
  }, [statusRef, proStatusErrorMsg, saveButtonClickCount]);

  useEffect(() => {
    // Scroll to the "Technology Stacks" Typography component
    if (priBDErrorMsg && primaryBDRef.current) {
      primaryBDRef.current.focus();
    }
  }, [priBDErrorMsg, primaryBDRef, saveButtonClickCount]);

  useEffect(() => {
    // Scroll to the "Technology Stacks" Typography component
    if (priTLErrorMsg && primaryTlRef.current) {
      primaryTlRef.current.focus();
    }
  }, [priTLErrorMsg, primaryTlRef, saveButtonClickCount]);

  useEffect(() => {
    // Scroll to the "Technology Stacks" Typography component
    if (technologyErrorMsg && technologyErrorMsgRef.current) {
      technologyErrorMsgRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [technologyErrorMsg, technologyErrorMsgRef, saveButtonClickCount]);

  useEffect(() => {
    // Scroll to the keywords error message
    if (keywordsErrorMsg && keywordsErrorMsgRef.current) {
      keywordsErrorMsgRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [keywordsErrorMsg, keywordsErrorMsgRef, saveButtonClickCount]);

  useEffect(() => {
    // Scroll to the keywords error message
    if (proDescErrorMsg && proDescErrorMsgRef.current) {
      proDescErrorMsgRef.current.focus();
    }
  }, [proDescErrorMsg, proDescErrorMsgRef, saveButtonClickCount]);

  useEffect(() => {
    // Scroll to the "Technology Stacks" Typography component
    if (proNameErrorMsg && projectnameRef.current) {
      projectnameRef.current.focus();
    }
  }, [proNameErrorMsg, projectnameRef, saveButtonClickCount]);

  useEffect(() => {
    if (conPerErrorMsg && contactPersonRef.current) {
      contactPersonRef.current.focus();
    }
  }, [contactPersonRef, conPerErrorMsg, saveButtonClickCount]);

  useEffect(() => {
    if (companyerror && companyNameRef.current) {
      companyNameRef.current.focus();
    }
  }, [companyNameRef, companyerror, saveButtonClickCount]);

  useEffect(() => {
    if (selectedContact) {
      setDefaultContactPerson(selectedContact);
      setCompanyPayloadPerson(selectedContact.label);
      setCompaniesPerson(selectedContact.value);
    }
  }, [selectedContact, companyName]);

  // };
  // END :: REFERENCE LINK ADD AND DELETE
  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchKeywords({ query: "" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchTechnologies({ query: "" }));
  }, [dispatch]);

  const keywordsData = keywords?.allKeywords?.map((keyword) => ({
    value: keyword.id,
    label: keyword.name,
  }));
  const handleLinkClick = (event, website) => {
    if (website && !website.startsWith("http")) {
      event.preventDefault();
      window.open(`http://${website}`, "_blank");
    }
  };

  const handleCreate = (inputValue) => {
    setIsLoading(true);
    setInvalidValue(inputValue);

    setTimeout(() => {
      setIsLoading(false);
      setShowModal(true);
    }, 1000); // Simulate the loading process
  };

  const handleKeywordSelect = (selectedOptions) => {
    setSelectedKeywords(selectedOptions);

    const selectedIds = selectedOptions.map((option) => option.value);

    setKeywordPayload(selectedIds);
    setKeywordsErrorMsg("");
    setCommonErrorMsg("");
  };

  const technologiesData = technologies?.allTechnologies?.map((technology) => ({
    value: technology.id,
    label: technology.name,
  }));

  const handleTechCreate = (inputValue) => {
    setTechLoading(true);
    setValidTechName(inputValue);

    setTimeout(() => {
      setTechLoading(false);

      setAddTechModal(true);
    }, 1000); // Simulate the loading process
  };

  const handleTechnologySelect = (selectedOptions) => {
    setSelectedTechnologies(selectedOptions);

    const selectedIds = selectedOptions.map((option) => option.value);

    setTechnologyPayload(selectedIds);
    setTechnologyErrorMsg("");
    setCommonErrorMsg("");
  };

  //PROJECT DESCRIPTION area
  const handledescriptionChange = (event) => {
    const value = event.target.value;

    setProjectDescription(value);
    setProDescErrorMsg("");
    setCommonErrorMsg("");
  };
  const handleKeyFeatureChange = (data) => {
    setKeyFeatures(data);
  };

  const handleKeyIntegrationChange = (data) => {
    setKeyIntegrations(data);
  };

  const handlePrimaryTLSelect = (selectedValue) => {
    if (selectedValue.value !== null) {
      setPrimaryTl(null);
    }
    setPrimaryTl(selectedValue);
    setPriTLErrorMsg("");
    setCommonErrorMsg("");
  };

  const handleSecondaryTLSelect = (selectedValue) => {
    if (selectedValue.value !== null) {
      setSecondaryTl(null);
    }

    setSecondaryTl(selectedValue);
  };

  const handlePrimaryBDSelect = (selectedValue) => {
    if (selectedValue.value !== null) {
      setPrimaryBD(null);
    }

    setPrimaryBD(selectedValue);
    setPriBDErrorMsg("");
    setCommonErrorMsg("");
  };

  const handleSecondaryBDSelect = (selectedValue) => {
    if (selectedValue.value !== null) {
      setSecondaryBD(null);
    }

    setSecondaryBD(selectedValue);
  };

  const handleProjectStatusSelect = (selectedValue) => {
    setProjectStatus(selectedValue);
    setProStatusErrorMsg("");
    setCommonErrorMsg("");
  };
  const handleDevelopmentStatusChange = (selectedOption) => {
    setDevelopmentStatus(selectedOption);
    setDeveStatusErrorMsg("");
    setCommonErrorMsg("");
  };
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  keywordPayload.forEach((value, index) => {
    formData.append("keyword_ids[]", value);
  });
  technologyPayload.forEach((value, index) => {
    formData.append("technology_ids[]", value);
  });

  const referenceLinksArray = ReferenceLinks.map((element) => element.linkText);

  referenceLinksArray.forEach((value, index) => {
    formData.append("reference_links[]", value); // Assuming it's a comma-separated string
  });

  const projectLinksArray = projectLinks.map((element) => element.linkText);

  projectLinksArray.forEach((value, index) => {
    formData.append("project_link[]", value);
  });
  const companyOptions = clients?.client?.map((client) => ({
    value: client.id,
    label: client.company_name,
  }));

  const companyDetails = clients?.client?.filter(
    (client) => companies.value === client.id
  );

  const companyEmail = companyDetails?.map((email) => email.company_email);
  const maskedCompanyEmail = companyEmail?.map((email) => maskEmail(email));
  const companyPhone = companyDetails?.map((phone) => phone.company_phone);
  const maskedComapnyPhone = companyPhone?.map((phone) =>
    maskPhoneNumber(phone)
  );
  const clientId = companyDetails?.[0]?.client_contacts?.map(
    (contact) => contact.client_id
  )[0];
  const contactPersonOptions = contacts?.contacts?.map((contact) => ({
    value: contact.id,
    label: contact.contact_person_name,
  }));
  const contactPersonDetails = contacts?.contacts?.filter(
    (contact) => selectedContact?.value === contact.id
  );
  const contactPersonEmail = contactPersonDetails?.map(
    (contact) => contact?.contact_person_email
  );
  const contactPersonEmailMasked = contactPersonEmail?.map((email) =>
    maskEmail(email)
  );
  const contactPersonPhone = contactPersonDetails?.map(
    (contact) => contact.contact_person_phone
  );
  const contactPersonPhoneMasked = contactPersonPhone?.map((phone) =>
    maskPhoneNumber(phone)
  );
  const submitData = async () => {
    setSaveButtonClickCount((prevCount) => prevCount + 1);
    if (isButtonDisabled) {
      return;
    }
    let flag = 0;
    if (value === "new_opportunity") {
      if (!compName.trim()) {
        // errorToast("Please fill in all the required fields marked with a star ( * )");
        setCompanyError("Please enter company name");

        flag = 1;
      }
      if (!conPerson.trim()) {
        // errorToast("Please fill in all the required fields marked with a star ( * )");
        setConPerErrorMsg("Please enter contact person name");
        flag = 1;
      }
    }
    if (value === "existing_client") {
      if (!companyPayload) {
        // errorToast("Please fill in all the required fields marked with a star ( * )");
        setCompanyError("Please select company name");
        flag = 1;
      }
      if (!companyPayloadPerson) {
        // errorToast("Please fill in all the required fields marked with a star ( * )");
        setConPerErrorMsg("Please select contact person name");
        flag = 1;
      }
    }
    if (compEmailError === "Please enter a valid email address") {
      // errorToast("Please enter a valid email address")
      flag = 1;
    }
    if (conPerEmailError === "Please enter a valid email address") {
      // errorToast("Please enter a valid email address")
      flag = 1;
    }
    if (!projectName) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setProNameErrorMsg("Please enter project name");
      flag = 1;
    }
    if (!projectDescription) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setProDescErrorMsg("Please enter project description");
      flag = 1;
    }
    if (keywordPayload.length === 0) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setKeywordsErrorMsg("Please select keywords for the project");
      flag = 1;
    }
    if (technologyPayload.length === 0) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setTechnologyErrorMsg("Please select technology for the project");
      flag = 1;
    }
    if (!primaryTl) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setPriTLErrorMsg("Please select primary TL");
      flag = 1;
    }
    if (!primaryBD) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setPriBDErrorMsg("Please select primary BD");
      flag = 1;
    }
    if (!projectStatus) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setProStatusErrorMsg("Please select project status");
      flag = 1;
    }
    if (!developmentStatus) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setDeveStatusErrorMsg("Please select development status");
      flag = 1;
    }
    if (flag !== 0) {
      return;
    }
    setIsButtonDisabled(true);

    const projectData = {
      company_name: value === "new_opportunity" ? compName : companyPayload,
      company_email: value === "new_opportunity" ? compEmail : companyEmail,
      company_phone: value === "new_opportunity" ? compNumber : companyPhone,
      contact_person:
        value === "new_opportunity" ? conPerson : companyPayloadPerson,
      contact_person_email:
        value === "new_opportunity" ? conPersonEmail : contactPersonEmail,
      contact_person_phone:
        value === "new_opportunity" ? conPersonNumber : contactPersonPhone,
      project_name: projectName,
      project_description: projectDescription,
      primary_tl_id: primaryTl,
      secondary_tl_id: secondaryTl,
      primary_bda_id: primaryBD,
      secondary_bda_id: secondaryBD,
      status: projectStatus,
      development_status: developmentStatus,
      key_features: keyFeatures,
      key_integration: keyIntegrations,
      client_id: clientId,
      client_contact_id: value === "new_opportunity" ? "" : companiesperson,
      start_date: startDate,
    };

    for (const key in projectData) {
      if (Object.hasOwnProperty.call(projectData, key)) {
        formData.append(key, projectData[key]);
      }
    }
    try {
      const response = await dispatch(
        createProject({ formData, client_type: value })
      );

      if (response.success) {
        navigate("/projects");
        successToast("Project created successfully!");
      } else {
        console.error("Error creating user:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      setIsButtonDisabled(false);
    }
  };
  const handleCompanySelect = (selectedOptions) => {
    if (selectedOptions) {
      setCompanyError("");
    }
    setCompanies(selectedOptions);
    setCompanyName(selectedOptions.value);
    setCompanyPayload(selectedOptions.label);
  };

  const closeKeyworddModal = () => setKeywordModalOpen(false);

  const closeTechnologyModal = () => setTechnologyModalOpen(false);

  useEffect(() => {
    dispatch(
      fetchUsers({
        type: type,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  useEffect(() => {
    if (value === "existing_client") {
      setCompEmailError("");
      setCompanyError("");
      setContactPerEmailError("");
      setConPerErrorMsg("");
    }
  }, [value]);
  useEffect(() => {
    dispatch(fetchContacts({ clientId: clientId, page: "", limit: "" }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, companyName]);

  const users = useSelector((state) => state.users.users);

  const teamleaders = users?.filteredTeamManager;

  const bda = users?.filteredBda;

  const tlOptions = teamleaders?.map((user) => ({
    value: user.id,
    label: `${capitalizeWords(user.first_name)} ${capitalizeWords(
      user.last_name
    )}`,
  }));

  const PrimaryTeamLeaderoptions = teamleaders
    ? teamleaders
        .filter((tl) => tl.id !== secondaryTl)
        .map((tl) => ({
          value: tl.id,
          label: `${capitalizeWords(tl.first_name)} ${capitalizeWords(
            tl.last_name
          )}`,
        }))
    : [];
  const SecondaryTeamLeaderoptions = teamleaders
    ? teamleaders
        .filter((tl) => tl.id !== primaryTl)
        .map((tl) => ({
          value: tl.id,
          label: `${capitalizeWords(tl.first_name)} ${capitalizeWords(
            tl.last_name
          )}`,
        }))
    : [];

  const bdaOptions = bda?.map((user) => ({
    value: user.id,
    label: `${capitalizeWords(user.first_name)} ${capitalizeWords(
      user.last_name
    )}`,
  }));
  const PrimaryBDeroptions = bda
    ? bda
        .filter((tl) => tl.id !== secondaryBD)
        .map((tl) => ({
          value: tl.id,
          label: `${capitalizeWords(tl.first_name)} ${capitalizeWords(
            tl.last_name
          )}`,
        }))
    : [];
  const SecondaryBDoptions = bda
    ? bda
        .filter((tl) => tl.id !== primaryBD)
        .map((tl) => ({
          value: tl.id,
          label: `${capitalizeWords(tl.first_name)} ${capitalizeWords(
            tl.last_name
          )}`,
        }))
    : [];

  const emailCheckHandler = async () => {
    if (compEmail && validateEmail(compEmail)) {
      setCompEmailError("");
    } else {
      setCompEmailError("Please enter a valid email address");
    }
    if (!compEmail) {
      setCompEmailError("");
    }

    try {
      if (compEmail) {
        const response = await callApi({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/projects/checkemail`,
          params: {
            email: compEmail,
          },
        });

        setSameEmailMessage(response);
        if (response.data.length > 0) {
          setEmailOpen(true);
        }
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleResponseDateChange = (date) => {
    setStartDate(date);
  };

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/projects")}
          ></Button>
          Create Project
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Grid container spacing={3}>
          <Grid item lg={8} md={12} sm={12} xs={12}>
            <Box className="card-wrapper" sx={{ height: "100%" }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Project Details
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Select Customer
                  </Typography>
                  <RadioGroup
                    aria-label="company"
                    name="company"
                    value={value}
                    onChange={handleChange}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="new_opportunity"
                      control={<Radio />}
                      label={
                        <span style={{ fontSize: "14px" }}>
                          New Opportunity
                        </span>
                      }
                    />
                    <FormControlLabel
                      value="existing_client"
                      control={<Radio />}
                      label={
                        <span style={{ fontSize: "14px" }}>
                          Existing Client
                        </span>
                      }
                    />
                  </RadioGroup>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Name
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>

                  {value === "new_opportunity" ? (
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Company Name"
                      value={compName}
                      inputRef={companyNameRef}
                      onChange={(event) => {
                        setCompName(event.target.value);
                        if (event.target.value) {
                          setCompanyError("");
                        }
                      }}
                    />
                  ) : (
                    <Select
                      name="colors"
                      options={companyOptions}
                      className="muilt-select-field"
                      classNamePrefix="select"
                      placeholder="Select Company"
                      ref={companyNameRef}
                      value={
                        companyOptions?.find(
                          (option) => option.value === companyName
                        ) || null
                      }
                      onChange={handleCompanySelect}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "#8CC714",
                        },
                      })}
                    />
                  )}
                  {companyerror && (
                    <Typography variant="body1" component="p" className="error">
                      {companyerror}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Email
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Company Email"
                    inputRef={compEmailRef}
                    value={
                      value === "existing_client"
                        ? getUserRole
                          ? companyEmail
                          : maskedCompanyEmail
                        : compEmail
                    }
                    onChange={(event) => {
                      const email = event.target.value;
                      setCompEmail(email);
                      if (value === "new_opportunity") {
                        if (validateEmail(email) || !email) {
                          setCompEmailError("");
                        } else {
                          setCompEmailError(
                            "Please enter a valid email address"
                          );
                        }
                      }
                    }}
                    onBlur={() => {
                      emailCheckHandler();
                    }}
                    disabled={value === "existing_client"}
                  />
                  {compEmailError && (
                    <Typography variant="body2" className="error" color="error">
                      {compEmailError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Phone No.
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Company Phone No."
                    value={
                      value === "existing_client"
                        ? getUserRole
                          ? companyPhone
                          : maskedComapnyPhone
                        : compNumber
                    }
                    onChange={(event) => {
                      setCompNumber(event.target.value);
                    }}
                    disabled={value === "existing_client"}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>

                  {value === "new_opportunity" ? (
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Contact Person Name"
                      value={conPerson}
                      inputRef={contactPersonRef}
                      onChange={(event) => {
                        setConPerson(event.target.value);

                        if (event.target.value) {
                          setConPerErrorMsg("");
                        }
                      }}
                    />
                  ) : (
                    <Select
                      name="colors"
                      options={companyName ? contactPersonOptions : []}
                      className="muilt-select-field"
                      classNamePrefix="select"
                      placeholder="Select Contact Person"
                      onChange={(newValue) => setSelectedContact(newValue)}
                      value={companyName ? selectedContact : null}
                      ref={contactPersonRef}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "#8CC714",
                        },
                      })}
                    />
                  )}
                  {conPerErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {conPerErrorMsg}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person's Email
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Contact Person's Email"
                    inputRef={contEmailRef}
                    value={
                      value === "existing_client" && companyName
                        ? getUserRole
                          ? contactPersonEmail
                          : contactPersonEmailMasked
                        : conPersonEmail
                    }
                    onChange={(event) => {
                      const email = event.target.value;
                      setConPersonEmail(email);
                      if (value === "new_opportunity") {
                        if (validateEmail(email) || !email) {
                          setContactPerEmailError("");
                        } else {
                          setContactPerEmailError(
                            "Please enter a valid email address"
                          );
                        }
                      }
                    }}
                    onBlur={() => {
                      if (conPersonEmail && validateEmail(conPersonEmail)) {
                        setContactPerEmailError("");
                      } else {
                        setContactPerEmailError(
                          "Please enter a valid email address"
                        );
                      }
                      if (!conPersonEmail) {
                        setContactPerEmailError("");
                      }
                    }}
                    disabled={value === "existing_client"}
                  />
                  {conPerEmailError && (
                    <Typography variant="body2" className="error" color="error">
                      {conPerEmailError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person's Phone No.
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Contact Person's Phone No."
                    value={
                      value === "existing_client" && companyName
                        ? getUserRole
                          ? contactPersonPhone
                          : contactPersonPhoneMasked
                        : conPersonNumber
                    }
                    onChange={(event) => {
                      setConPersonNumber(event.target.value);
                    }}
                    disabled={value === "existing_client"}
                  />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Name
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                      ref={projectnameRef}
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Project Name"
                    value={projectName}
                    inputRef={projectnameRef}
                    onChange={(event) => {
                      setProjectName(event.target.value);
                      if (event.target.value) {
                        setProNameErrorMsg("");
                        setCommonErrorMsg("");
                      }
                    }}
                  />
                  {proNameErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {proNameErrorMsg}
                    </Typography>
                  )}
                  {commonErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {commonErrorMsg}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Description
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextareaAutosize
                    className="textarea-field"
                    placeholder="Add Project Description"
                    value={projectDescription}
                    ref={proDescErrorMsgRef}
                    onChange={handledescriptionChange}
                  />
                  {proDescErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {proDescErrorMsg}
                    </Typography>
                  )}
                  {commonErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {commonErrorMsg}
                    </Typography>
                  )}
                </Grid>
                <Typography ref={keywordsErrorMsgRef} />
                <Typography ref={technologyErrorMsgRef} />
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1" component="label">
                      Keywords
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                  </Stack>
                  <CreatableSelect
                    isClearable
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    isMulti
                    name="colors"
                    options={keywordsData}
                    onChange={handleKeywordSelect}
                    value={selectedKeywords}
                    className="muilt-select-field"
                    placeholder="Select Keywords"
                    onCreateOption={handleCreate}
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                  {keywordsErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {keywordsErrorMsg}
                    </Typography>
                  )}
                  {commonErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {commonErrorMsg}
                    </Typography>
                  )}
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1" component="label">
                      Technology Stacks
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                  </Stack>
                  <CreatableSelect
                    isClearable
                    isDisabled={techLoading}
                    isLoading={techLoading}
                    isMulti
                    name="colors"
                    options={technologiesData}
                    onChange={handleTechnologySelect}
                    value={selectedTechnologies}
                    onCreateOption={handleTechCreate}
                    className="muilt-select-field"
                    placeholder="Select Technologies"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                  {technologyErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {technologyErrorMsg}
                    </Typography>
                  )}
                  {commonErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {commonErrorMsg}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Key Features
                  </Typography>
                  <Box className="ck-editor-wrapper">
                    <ReactQuill
                      value={keyFeatures}
                      onChange={handleKeyFeatureChange}
                      modules={modules}
                    />
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Key Integration
                  </Typography>
                  <Box className="ck-editor-wrapper">
                    <ReactQuill
                      value={keyIntegrations}
                      onChange={handleKeyIntegrationChange}
                      modules={modules}
                    />
                  </Box>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Links
                  </Typography>
                  <Stack container spacing={2}>
                    {projectLinks.map((element, index) => {
                      return (
                        <Box className="add-multi-input">
                          <TextField
                            className="input-field"
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Enter Project Link"
                            value={element.linkText}
                            onChange={(e) => {
                              addTextToLink(e.target.value, index);
                            }}
                          />
                          <Box className="action-btn-wrap">
                            {index === projectLinks.length - 1 && (
                              <Button
                                variant="outlined"
                                className="action-btn edit-btn"
                                startIcon={<RiAddCircleLine />}
                                disableRipple
                                onClick={() => addProjectLink()}
                              ></Button>
                            )}
                            {index !== projectLinks.length - 1 && (
                              <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                disableRipple
                                onClick={() => {
                                  handleDeleteLink(index);
                                }}
                              ></Button>
                            )}
                          </Box>
                        </Box>
                      );
                    })}
                  </Stack>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Primary TL
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Select
                    name="colors"
                    options={PrimaryTeamLeaderoptions}
                    ref={primaryTlRef}
                    value={
                      tlOptions?.find((option) => option.value === primaryTl) ||
                      null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handlePrimaryTLSelect(selectedOption?.value);
                      } else {
                        setPrimaryTl("");
                      }
                    }}
                    isClearable={true}
                    className="muilt-select-field"
                    classNamePrefix="select"
                    placeholder="Select Primary TL"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                  {priTLErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {priTLErrorMsg}
                    </Typography>
                  )}
                  {commonErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {commonErrorMsg}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Secondary TL
                  </Typography>
                  <Select
                    name="colors"
                    options={SecondaryTeamLeaderoptions}
                    value={
                      tlOptions?.find(
                        (option) => option.value === secondaryTl
                      ) || null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handleSecondaryTLSelect(selectedOption?.value);
                      } else {
                        setSecondaryTl("");
                      }
                    }}
                    className="muilt-select-field"
                    classNamePrefix="select"
                    placeholder="Select Secondary TL"
                    isClearable={true}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Primary BD
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Select
                    name="colors"
                    options={PrimaryBDeroptions}
                    ref={primaryBDRef}
                    value={
                      bdaOptions?.find(
                        (option) => option.value === primaryBD
                      ) || null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handlePrimaryBDSelect(selectedOption?.value);
                      } else {
                        setPrimaryBD("");
                      }
                    }}
                    isClearable={true}
                    className="muilt-select-field"
                    classNamePrefix="select"
                    placeholder="Select Primary BD"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                  {priBDErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {priBDErrorMsg}
                    </Typography>
                  )}
                  {commonErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {commonErrorMsg}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Secondary BD
                  </Typography>
                  <Select
                    name="colors"
                    options={SecondaryBDoptions}
                    isClearable={true}
                    value={
                      bdaOptions?.find(
                        (option) => option.value === secondaryBD
                      ) || null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handleSecondaryBDSelect(selectedOption?.value);
                      } else {
                        setSecondaryBD("");
                      }
                    }}
                    className="muilt-select-field"
                    classNamePrefix="select"
                    placeholder="Selct Secondary BD"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Stack spacing={3} className="h-100">
              <Box className="card-wrapper">
                <Typography variant="body1" component="label">
                  Project Status
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  name="colors"
                  options={projectStatusOptions}
                  value={
                    projectStatusOptions.find(
                      (option) => option.value === projectStatus
                    ) || null
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      handleProjectStatusSelect(selectedOption?.value);
                    } else {
                      setProjectStatus("");
                    }
                  }}
                  isClearable={true}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  placeholder="Select Project Status"
                  ref={statusRef}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                />
                {proStatusErrorMsg && (
                  <Typography variant="body1" component="p" className="error">
                    {proStatusErrorMsg}
                  </Typography>
                )}
                {commonErrorMsg && (
                  <Typography variant="body1" component="p" className="error">
                    {commonErrorMsg}
                  </Typography>
                )}
              </Box>
              <Box className="card-wrapper">
                <Typography variant="body1" component="label">
                  Development Status
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  name="colors"
                  options={developmentStatusOptions}
                  ref={devStatusRef}
                  value={developmentStatusOptions.find(
                    (Option) => Option.value === developmentStatus
                  )}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  placeholder="Select Development Status"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                  isClearable={true}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      handleDevelopmentStatusChange(selectedOption?.value);
                    } else {
                      setDevelopmentStatus("");
                    }
                  }}
                />
                {deveStatuserrorMsg && (
                  <Typography variant="body1" component="p" className="error">
                    {deveStatuserrorMsg}
                  </Typography>
                )}
                {commonErrorMsg && (
                  <Typography variant="body1" component="p" className="error">
                    {commonErrorMsg}
                  </Typography>
                )}
              </Box>
              <Box className="card-wrapper">
                <Typography variant="body1" component="label">
                  Development Start Date
                  {/* <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography> */}
                </Typography>
                <DatePicker
                  oneTap
                  format="dd-MM-yyyy"
                  placeholder="Select Development Start Date"
                  onKeyDown={(event) => event.preventDefault()}
                  className="modal-date-picker"
                  onChange={handleResponseDateChange}
                />
              </Box>
              <Box className="card-wrapper add-scroll-space">
                <Stack spacing={3} className="view-info">
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Documents
                  </Typography>
                  <Box className="document-card-content-wrapper over-flow-auto">
                    <Box>
                      <Typography variant="body1" component="label">
                        File Name
                      </Typography>
                      <Stack container spacing={2}>
                        <Box className="add-multi-input">
                          <TextField
                            className="input-field"
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Please Enter File Name"
                            value={filename}
                            onChange={(event) => {
                              setFileName(event.target.value);
                            }}
                          />
                        </Box>
                      </Stack>
                      <Typography
                        variant="body1"
                        component="label"
                        style={{ marginTop: "10px" }}
                      >
                        URL
                      </Typography>
                      <Stack container spacing={2}>
                        <Box className="add-multi-input">
                          <TextField
                            className="input-field"
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Paste Document URL Here"
                            value={documentUploadLink}
                            onChange={(event) => {
                              setDocumentUploadLink(event.target.value);
                              if (event.target.value) {
                                setdocumentLinkError("");
                              }
                            }}
                          />
                          <Button
                            variant="outlined"
                            className="action-btn upload-btn"
                            disableRipple
                            onClick={addDocument}
                          >
                            Upload
                          </Button>
                        </Box>
                      </Stack>
                      {documentlinkerror && (
                        <Typography
                          variant="body2"
                          className="error"
                          color="error"
                        >
                          {documentlinkerror}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Stack
                    className="document-list-wrapper over-flow-auto"
                    spacing={2}
                  >
                    {displayedDocuments &&
                      displayedDocuments.map((video, index) => {
                        const text = displayedfilenames[index]
                          ? displayedfilenames[index]
                          : displayedDocuments[index];
                        const displayText =
                          text.length > maxLength
                            ? text.slice(0, maxLength) + "..."
                            : text;
                        return (
                          <Box key={index} className="document-upload-wrapper">
                            <Box className="document-type">
                              <a
                                href={displayedDocuments[index]}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) =>
                                  handleLinkClick(e, displayedDocuments[index])
                                }
                              >
                                <Typography variant="body1" component="span">
                                  {displayText}
                                </Typography>
                              </a>
                            </Box>
                            <Box className="document-action action-btn-wrap">
                              <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                disableRipple
                                onClick={() => handleRemoveDocument(index)}
                              ></Button>
                            </Box>
                          </Box>
                        );
                      })}
                  </Stack>
                </Stack>
              </Box>

              <Box
                className="card-wrapper add-scroll-space view-info"
                sx={{ flex: 1 }}
              >
                <Typography variant="body1" component="label">
                  Reference Links
                </Typography>
                <Stack container spacing={2} className="over-flow-auto">
                  {ReferenceLinks.map((element, index) => {
                    return (
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Enter Reference Link"
                          value={element.linkText}
                          onChange={(e) => {
                            addReferenceToLink(e.target.value, index);
                          }}
                        />
                        <Box className="action-btn-wrap">
                          {index === ReferenceLinks.length - 1 && (
                            <Button
                              variant="outlined"
                              className="action-btn edit-btn"
                              startIcon={<RiAddCircleLine />}
                              disableRipple
                              onClick={() => addReferenceLink()}
                            ></Button>
                          )}
                          {index !== ReferenceLinks.length - 1 && (
                            <Button
                              variant="outlined"
                              className="action-btn dlt-btn"
                              startIcon={<RiDeleteBinLine />}
                              disableRipple
                              onClick={() => {
                                handleReferenceDeleteLink(index);
                              }}
                            ></Button>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                </Stack>
              </Box>
            </Stack>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="card-wrapper add-bottom-scroll-space">
              <Stack spacing={3} className="view-info">
                <Typography variant="h3" component="h3" className="card-title">
                  Project Video Casts
                </Typography>
                <Box className="document-card-content-wrapper over-flow-auto">
                  <Box>
                    <Typography variant="body1" component="label">
                      File Name
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Please Enter File Name"
                          value={videoFileName}
                          onChange={(event) => {
                            setVideoFileName(event.target.value);
                          }}
                        />
                      </Box>
                    </Stack>
                    <Typography
                      variant="body1"
                      component="label"
                      style={{ marginTop: "10px" }}
                    >
                      Video Cast URL
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Paste Video Cast URL Here"
                          value={uploadLink}
                          onChange={(event) => {
                            setUploadLink(event.target.value);
                            if (event.target.value) {
                              setUploadLinkError("");
                            }
                          }}
                        />
                        <Button
                          variant="outlined"
                          className="action-btn upload-btn"
                          disableRipple
                          onClick={addVideo}
                        >
                          Upload
                        </Button>
                      </Box>
                    </Stack>
                    {uploadLinkError && (
                      <Typography
                        variant="body2"
                        className="error"
                        color="error"
                      >
                        {uploadLinkError}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Stack
                  className="document-list-wrapper over-flow-auto"
                  spacing={2}
                >
                  {displayedVideos &&
                    displayedVideos.map((video, index) => {
                      const text = displayedVideofilenames[index]
                        ? displayedVideofilenames[index]
                        : displayedVideos[index];
                      const displayText =
                        text.length > maxLength
                          ? text.slice(0, maxLength) + "..."
                          : text;
                      return (
                        <Box key={index} className="document-upload-wrapper">
                          <Box className="document-type">
                            <a
                              href={displayedVideos[index]}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) =>
                                handleLinkClick(e, displayedVideos[index])
                              }
                            >
                              <Typography variant="body1" component="span">
                                {displayText}
                              </Typography>
                            </a>
                          </Box>
                          <Box className="document-action action-btn-wrap">
                            <Button
                              variant="outlined"
                              className="action-btn dlt-btn"
                              startIcon={<RiDeleteBinLine />}
                              disableRipple
                              onClick={() => handleRemoveVideo(index)}
                            ></Button>
                          </Box>
                        </Box>
                      );
                    })}
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="card-wrapper add-bottom-scroll-space">
              <Stack spacing={3} className="view-info">
                <Typography variant="h3" component="h3" className="card-title">
                  Project Screen Shots
                </Typography>
                <Box className="document-card-content-wrapper over-flow-auto">
                  <Box>
                    <Typography variant="body1" component="label">
                      File Name
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Please Enter File Name"
                          value={imageFileName}
                          onChange={(event) => {
                            setImageFileName(event.target.value);
                          }}
                        />
                      </Box>
                    </Stack>
                    <Typography
                      variant="body1"
                      component="label"
                      style={{ marginTop: "10px" }}
                    >
                      Screen Shot URL
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Paste Screen Shot URL Here"
                          value={imageUploadLink}
                          onChange={(event) => {
                            setImageUploadLink(event.target.value);
                            if (event.target.value) {
                              setImageLinkError("");
                            }
                          }}
                        />
                        <Button
                          variant="outlined"
                          className="action-btn upload-btn"
                          disableRipple
                          onClick={addImage}
                        >
                          Upload
                        </Button>
                      </Box>
                    </Stack>
                    {imagelinkerror && (
                      <Typography
                        variant="body2"
                        className="error"
                        color="error"
                      >
                        {imagelinkerror}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Stack
                  className="document-list-wrapper over-flow-auto"
                  spacing={2}
                >
                  {displayedImages &&
                    displayedImages.map((video, index) => {
                      const text = displayedImagefilenames[index]
                        ? displayedImagefilenames[index]
                        : displayedImages[index];
                      const displayText =
                        text.length > maxLength
                          ? text.slice(0, maxLength) + "..."
                          : text;
                      return (
                        <Box key={index} className="document-upload-wrapper">
                          <Box className="document-type">
                            <a
                              href={displayedImages[index]}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) =>
                                handleLinkClick(e, displayedImages[index])
                              }
                            >
                              <Typography variant="body1" component="span">
                                {displayText}
                              </Typography>
                            </a>
                          </Box>
                          <Box className="document-action action-btn-wrap">
                            <Button
                              variant="outlined"
                              className="action-btn dlt-btn"
                              startIcon={<RiDeleteBinLine />}
                              disableRipple
                              onClick={() => handleRemoveImage(index)}
                            ></Button>
                          </Box>
                        </Box>
                      );
                    })}
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="footer-wrapper">
        <Button
          variant="contained"
          size="large"
          className="primary-btn btn w-120"
          disableRipple
          onClick={submitData}
          disabled={isButtonDisabled}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="large"
          className="secondary-btn btn w-120"
          disableRipple
          onClick={() => navigate("/projects")}
        >
          Cancel
        </Button>
      </Box>
      <KeywordModal
        keywordModalOpen={keywordModalOpen}
        handleClose={closeKeyworddModal}
      />
      <TechnologyModal
        technologyModalOpen={technologyModalOpen}
        handleClose={closeTechnologyModal}
      />
      {showModal && (
        <AddKeywordModal
          invalidValue={invalidValue}
          onClose={() => setShowModal(false)}
        />
      )}
      {addTechModal && (
        <AddTechnologyModal
          validTechName={validTechName}
          onClose={() => setAddTechModal(false)}
        />
      )}
      <ProjectEmailAlertModal
        emailOpen={emailOpen}
        setEmailOpen={setEmailOpen}
        compEmail={compEmail}
        setCompEmail={setCompEmail}
        sameEmailMessage={sameEmailMessage}
      />
    </>
  );
};

export default ProjectCreate;

import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { successToast } from "../../responses/successToast";
import { fetchActivities } from "../../store/slices/activitylogSlice";
import { deleteLeadActivityById } from "../../store/slices/deleteLeadActivitySlice";

const LeadActivityDeleteModal = ({
  selectedActivityId,
  handleActivityDeleteClose,
  activityDelete,
  value,
  leadId,
}) => {
  const dispatch = useDispatch();
  const activityId = selectedActivityId;
  // console.log(selectedActivityId, "id values", value, leadId);
  const submitHandler = () => {
    dispatch(deleteLeadActivityById({ activityId }))
      .unwrap()
      .then((result) => {
        const { success } = result;
        if (success) {
          successToast("Activity deleted successfully");
          dispatch(
            fetchActivities({
              leadId,
              state:
                value === 0 ? "activities" : value === 1 ? "status" : "updates",
            })
          );
          handleActivityDeleteClose();
        } else {
          handleActivityDeleteClose();
        }
      })
      .catch((error) => {
        console.error("Failed to delete user:", error);
        handleActivityDeleteClose();
      });
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={activityDelete}
        // onClose={ClientDeleteModal}
        closeAfterTransition
      >
        <Fade in={activityDelete}>
          <Box className="modal-wrapper">
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3">
                Do you want to delete this activity?
              </Typography>
              <Typography variant="body1" component="p">
                This action can’t be undone.
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body"></Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={submitHandler}
                >
                  Delete
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={handleActivityDeleteClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default LeadActivityDeleteModal;

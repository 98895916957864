export const CvHtmlFormat = (
  templateData,
  employeeName,
  cvTitle,
  education
) => {
  const assetsUrl = process.env.REACT_APP_API_URL;

  return `
       <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" />
      <title>Dipesh - Fullstack Developer</title>
      <style>
      ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: #7cb900;
  border-radius: 10px;
}
      html{
        -webkit-print-color-adjust: exact;
      }
        body {
          font-family: 'Calibri', sans-serif;
          margin: 0;
          padding: 0;
          background-color: #ffffff;
          color: #333333;
        }
        .container {
          max-width: 1024px;
          margin: 0 auto;
          background-color: #ffffff;
          padding: 24px;
          display: flex;
          flex-direction: column;
         
         
        }
        .header {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          padding-bottom: 30px;
          border-bottom: 2px solid #8bc34a;
        }
        .header img {
          max-width: 150px;
          margin-right: 20px;
        }
        .header h1 {
          font-size: 24px;
          color: #333333;
          margin: 0;
        }
        .section-content ul {
          margin-block-start: 0;
          margin-block-end: 0;
        }
        .section-content ol {
          margin-block-start: 0;
          margin-block-end: 0;
        }
        .section-content h4 {
          margin-block-start: 0;
          margin-block-end: 15px;
          text-decoration:underline;
        }
        .section {
          margin-bottom: 10px;
          padding-bottom: 20px;
          border-bottom: 2px solid #8bc34a;
        }
        .section h3 {
          color: #8bc34a;
          font-size: 18px;
          width: 100%;
          margin-block-start: 0;
        }
        .hr-custom {
          color: #8bc34a;
          background-color: #8bc34a;
        }
        .flex-class {
          display: flex;
          flex-direction: column;
        }
        .section ul li {
          font-size: 18px;
          line-height: 1.5;
          font-weight: 500;
        }
        .section ol li {
          font-size: 18px;
          line-height: 1.5;
          font-weight: 500;
        }
        .section p {
          font-size: 18px;
          line-height: 1.5;
          font-weight: 500;
          color: black !important;
          margin-block-end: 0;
          margin-block-start: 0;
        }
        .heading-text {
          width: 100%;
        }
        .simple-paragraph {
          font-size: 18px;
          line-height: 1.5;
          font-weight: 500;
          color: black !important;
        }
        .footer {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0;
          border-top: 1px solid #ddd;
          font-size: 15px;
          line-height: 1.5;
          font-weight: 500;
          margin-top: 30px;
        }
        .footer div {
          margin: 0 10px;
          text-align: center;
        }
        .footer a {
          color: #333;
          text-decoration: none;
          margin-left: 5px;
        }
        .footer a:hover {
          text-decoration: underline;
        }
        .footer i {
          margin-right: 5px;
        }
        .footer p {
          margin: 0;
        }
        .flex-inner-footer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .flex-footer {
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        .flex-location {
          display: flex;
          align-items: center;
        }
        .logo {
          margin-bottom: 20px !important;
          display: inline-block;
          padding: 5px 10px;
          background-color: black;
        }
        .simple-paragraph-footer {
          font-size: 14px;
          line-height: 1.5;
          font-weight: 500;
          color: black !important;
        }
          .footerr{
           min-height: 200px;
        display: block;
          }
        
      </style>
    </head>
    <body>
      <div
        class="bookmark"
        style="position: fixed; text-align: center; opacity: 0.08; top: 50%; transform: translateY(-50%); width: calc(100% - 40px); pointer-events: none;">
        <img src="${assetsUrl}/assets/icons/crest-logo.png" style="width: 450px" alt="crestinfosystems" />
      </div>
      <div class="container">
        <div class="header" style="text-align: center">
          <h1 style="font-weight: 700">${employeeName} - ${cvTitle}</h1>
        </div>
        ${
          templateData?.summary &&
          templateData?.summary !== '<p><br></p>' &&
          templateData?.summary !== ''
            ? `
          <div class='section'>
            <div class='flex-class'>
              <div class='heading-text'>
                <h3>SUMMARY</h3>
              </div>
              <div class='section-content'>${templateData?.summary}</div>
            </div>
          </div>
        `
            : ''
        }
              ${
                templateData?.skill_and_abilities &&
                templateData?.skill_and_abilities !== '<p><br></p>' &&
                templateData?.skill_and_abilities !== '' &&
                templateData?.skill_and_abilities !== '<ul></ul>'
                  ? `
        <div class="section">
          <div class="flex-class">
            <div class="heading-text">
              <h3>SKILLS & ABILITIES</h3>
            </div>
            <div class="section-content">
              ${templateData?.skill_and_abilities}
            </div>
          </div>
        </div>
         `
                  : ''
              }

            ${
              templateData?.roles &&
              templateData?.roles !== '<p><br></p>' &&
              templateData?.roles !== '' &&
              templateData?.roles !== '<ul></ul>'
                ? `
        <div class="section">
          <div class="flex-class">
            <div class="heading-text">
              <h3>ROLES & RESPONSIBILITIES</h3>
            </div>
            <div class="section-content">
              ${templateData?.roles}
            </div>
          </div>
        </div>
         `
                : ''
            }
        <div class="section">
          <div class="flex-class">
            <div class="heading-text">
              <h3>PROJECTS EXPERIENCE</h3>
            </div>
            <div class="section-content">
              ${templateData?.cv_projects
                .map(
                  (project, i) => `
                <div class=" ${i > 0 ? 'margin-topp' : ''}">
                  <h4 style="font-size: 20px"><strong>${
                    project.project_title
                  }</strong></h4>
                  ${
                    project.web_url !== '<p><br></p>' &&
                    project.web_url !== '<ul></ul>' &&
                    project.web_url !== ''
                      ? `
                    <div>              
                        ${project.web_url}
                    </div>
                    <br />
                  `
                      : ''
                  }
                  ${
                    project.description !== '<p><br></p>' &&
                    project.description !== ''
                      ? `
                     <p><strong>Description</strong></p>
                  <div class="simple-paragraph">${project.description}</div>
                  <br/>
                    `
                      : ''
                  }                
                   ${
                     project.techstacks !== '<p><br></p>' &&
                     project.techstacks !== '<ul></ul>' &&
                     project.techstacks !== ''
                       ? `
                  <p><strong>Technology Used</strong></p>
                  <div class="simple-paragraph">${project.techstacks}</div>
                   <br/>
                  `
                       : ''
                   }
                     ${
                       project.roles !== '<p><br></p>' &&
                       project.roles !== '<ul></ul>' &&
                       project.roles !== ''
                         ? `
                  <p><strong>Roles & Responsibilities</strong></p>
                  <div class="simple-paragraph">${project.roles}
                 </div>
                 <br/>
                  `
                         : ''
                     }
                </div>
                ${
                  templateData?.cv_projects?.length - 1 !== i ? `<hr/>` : ''
                }    
              `
                )
                .join('')}
            </div>
          </div>
        </div>
        
        <div class="section education-section">
          <div class="flex-class">
            <div class="heading-text">
              <h3>EDUCATION</h3>
            </div>
            <div class="section-content">
             ${education}
            </div>
          </div>
        </div>
         ${
           templateData?.notes &&
           templateData?.notes !== '<p><br></p>' &&
           templateData?.notes !== '' &&
           templateData?.notes !== '<ul></ul>'
             ? `
          <div class='section'>
            <div class='flex-class'>
              <div class='heading-text'>
                <h3>NOTES</h3>
              </div>
              <div class='section-content'>${templateData?.notes}</div>
            </div>
          </div>
        `
             : ''
         }
      </div></body></html>`;
};

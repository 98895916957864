import { callApi } from "../../components/common/commonfunctionforapi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const deleteProjectsById = createAsyncThunk(
  "projects/fetchProjectsById",
  async ({ projectId }, { rejectWithValue }) => {
    // console.log(projectId);
    try {
      const response = await callApi({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/projects/${projectId}`,
      });
      // console.log(response.data);

      return { success: true, message: response.data.message };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  //data: null,
};
const deleteprojectByIdSlice = createSlice({
  name: "deleteprojectsById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteProjectsById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteProjectsById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // state.data = action.payload;
      })
      .addCase(deleteProjectsById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default deleteprojectByIdSlice.reducer;

import { Box, Button, Grid, Typography, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { RiArrowLeftLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

import { fetchUsers } from "../../store/slices/userSlice";
import { createSetting } from "../../store/slices/createAcitivitySetting";
import { successToast } from "../../responses/successToast";
import { fetchSettings } from "../../store/slices/getReminderSlice";
import { createLeadSetting } from "../../store/slices/leadSettingSlice";
import { fetchLeadSettings } from "../../store/slices/getLeadSettingSlice";
import Loader from "../../components/common/loader";

// LEAD STATUS OPTIONS
const leadStatusOptions = [
  { value: "open", label: "Open" },
  { value: "estimation_requested", label: "Estimation Requested" },
  { value: "estimation_provided", label: "Estimation Provided" },
  { value: "closed", label: "Not Responding" },
  { value: "standBy", label: "StandBy" },
];

function capitalizeFirstLetter(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}

function formatStatus(str) {
  const words = str?.split("_");
  const capitalizedWords = words?.map((word) => capitalizeFirstLetter(word));
  return capitalizedWords?.join(" ");
}

const ActivitySetting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.users);
  const settings = useSelector((state) => state.settings.settings);
  const leadSettings = useSelector((state) => state.leadsetiing.leadsetiing);
  // console.log(leadSettings, "leadSettings");
  const [members, setMembers] = useState([]);
  const [memberspayload, setMembersPayload] = useState([]);
  const [firstReminder, setFirstReminder] = useState();
  const [secondReminder, setSecondReminder] = useState();
  const [emailDays, setEmailDays] = useState("");
  const [removeKeyId, setRemoveKeyId] = useState([]);
  const [leadStatus, setLeadStatus] = useState([]);
  const [leadStatusPayload, setLeadStatusPayload] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [leadStatusErrorMsg, setLeadStatusErrorMsg] = useState("");
  const [removeStatusId, setRemoveStatusId] = useState([]);
  // const [selectedUsers, setSelectedUsers] = useState([]);
  // const [userPayload, seUserPayload] = useState([]);
  const loggedInUserId = users?.loginuser?.map((login) => login.id);

  const userRoleArray = users?.loginuser?.map(
    (login) => login?.roles?.role_name
  );
  // eslint-disable-next-line no-unused-vars
  const userRole = userRoleArray ? userRoleArray[0] : null;

  const memberOptions = users?.AllTlandBda?.map((user) => ({
    value: user.id,
    label: `${user.first_name} ${user.last_name}`,
  }));

  const handleOptionChange = (selectedOptions) => {
    // console.log(selectedOptions);
    setMembers(selectedOptions);
    const selectedIds = selectedOptions?.map((option) => option?.value);
    setMembersPayload(selectedIds);
  };

  useEffect(() => {
    dispatch(
      fetchUsers({
        page: "",
        limit: "",
        query: "",
      })
    );
  }, [dispatch]);

  const submitHandler = async () => {
    const settingData = {
      first_reminder: firstReminder,
      second_reminder: secondReminder,
      cc_user_id: memberspayload,
      delete_cc_user_id: removeKeyId,
    };
    const leadSettingData = {
      status: leadStatusPayload,
      delete_status: removeStatusId,
      number_of_days: emailDays,
    };

    try {
      // First API call
      const response1 = await dispatch(createSetting(settingData));

      if (response1.success) {
        // If the first API call is successful, proceed to the second API call
        const response2 = await dispatch(createLeadSetting(leadSettingData));

        if (response2.success) {
          // Both API calls are successful
          successToast("Settings updated successfully");
          setRemoveKeyId([]);
          setRemoveStatusId([]);
          dispatch(fetchSettings());
        } else {
          console.error("Error creating lead setting:", response2.error);
        }
      } else {
        console.error("Error creating setting:", response1.error);
      }
    } catch (error) {
      console.error("Error in API calls:", error.message);
    }
  };

  useEffect(() => {
    dispatch(fetchSettings())
      .then(() => {
        // setLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        // setLoading(false); // Set loading to false in case of an error
        console.error("Error fetching user data:", error);
      });
  }, [dispatch]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchLeadSettings())
      .then(() => {
        setIsLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        setIsLoading(false); // Set loading to false in case of an error
        console.error("Error fetching user data:", error);
      });
  }, [dispatch]);

  useEffect(() => {
    const initialMemberOptions = settings?.setting_assignee_users?.map(
      (keywordName) => ({
        value: keywordName?.assignee_user_id,
        label: `${keywordName?.user?.first_name} ${keywordName?.user?.last_name}`,
      })
    );
    // console.log(initialMemberOptions, "initialMemberOptions");
    // Set the initial options for the Select component
    setFirstReminder(settings?.first_reminder);
    setSecondReminder(settings?.second_reminder);
    setMembers(initialMemberOptions);
  }, [settings]);

  useEffect(() => {
    // console.log(
    //   leadStatusOptions?.map((value) => value?.value),
    //   leadStatusOptions?.map((value) => value?.label),
    //   "*+*+*+*+*"
    // );
    const initialStatusOptions = leadSettings?.data?.map((status) => {
      const isStatusIncluded = leadStatusOptions
        ?.map((value) => value?.value)
        .includes(status?.status_name);

      return {
        value: status?.status_name,
        label: isStatusIncluded
          ? formatStatus(
              status?.status_name === "closed"
                ? "Not Responding"
                : status?.status_name
            )
          : undefined,
      };
    });

    // console.log(initialStatusOptions, "initialStatusOptions");
    setLeadStatus(initialStatusOptions);
    const emailDays = leadSettings?.data[0]?.number_of_days;
    // console.log(emailDays, "emailDays");
    setEmailDays(emailDays);
  }, [leadSettings]);

  const handleFirstReminderChange = (event) => {
    let value = parseInt(event.target.value, 10) || 5;
    value = Math.max(5, Math.round(value / 5) * 5); // Ensure multiples of 5 and non-negative
    setFirstReminder(value === 0 ? 5 : value);
  };

  const handleSecondReminderChange = (event) => {
    let value = parseInt(event.target.value, 10) || 5;
    value = Math.max(5, Math.round(value / 5) * 5); // Ensure multiples of 5 and non-negative
    setSecondReminder(value === 0 ? 5 : value);
  };

  const handleLeadStatusSelect = (selectedOptions) => {
    // console.log(selectedOptions, "+++++++++++");
    setLeadStatus(selectedOptions);
    const selectedIds = selectedOptions?.map((option) => option?.value);
    setLeadStatusPayload(selectedIds);
  };
  // console.log(leadStatusPayload, "leadstatsupayload", removeStatusId);
  const handleKeyDown = (e) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Enter",
      "Home",
      "End",
    ];
    const isAllowedKey = allowedKeys.includes(e.key);
    const isNumeric = /^[0-9]/.test(e.key);

    if (!isAllowedKey && !isNumeric) {
      e.preventDefault();
    }
  };

  return (
    <>
      {/*  START :: KEYWORDS SETTING PAGE */}
      {isLoading && <Loader />}

      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/settings")}
          ></Button>
          Notifications
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="card-wrapper">
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      variant="h3"
                      component="h3"
                      className="card-title"
                    >
                      Call and Followup Activities Email
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                    First Reminder (In Minutes)
                    </Typography>
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Minutes"
                      type="number"
                      value={firstReminder}
                      onChange={handleFirstReminderChange}
                      inputProps={{ step: 5 }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                    Second Reminder (In Minutes)
                    </Typography>
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Minutes"
                      type="number"
                      value={secondReminder}
                      onChange={handleSecondReminderChange}
                      inputProps={{ step: 5 }}
                    />
                  </Grid>
                  
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="card-wrapper">
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      variant="h3"
                      component="h3"
                      className="card-title"
                    >
                      Slow Moving Leads Email
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                    Lead Status
                    </Typography>
                    <Select
                        isMulti
                        name="colors"
                        options={leadStatusOptions}
                        className="muilt-select-field"
                        value={leadStatus}
                        onChange={(selectedOptions, { removedValue }) => {
                          // console.log(selectedOptions, "selected options");
                          if (removedValue) {
                            // console.log(removedValue, "///");
                            const removedOptionId = removedValue.value; // assuming 'value' contains the ID
                            // console.log(removedOptionId, "99999");
                            setRemoveStatusId([...removeStatusId, removedOptionId]);
                          }
                          handleLeadStatusSelect(selectedOptions);
                        }}
                        classNamePrefix="select"
                        placeholder="Select status for sending Email"
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 10,
                          colors: {
                            ...theme.colors,
                            primary25: "var(--secondary-text-color)",
                            primary: "#8CC714",
                          },
                        })}
                      />
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                    Duration To Find Slow Moving Leads (In Days)
                    </Typography>
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter no of days to consider as slow moving leads"
                      // type="number"
                      value={emailDays}
                      onKeyDown={handleKeyDown}
                      onChange={(event) => {
                        setEmailDays(event.target.value);
                      }}
                    />
                  </Grid>
                  
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="card-wrapper">
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      variant="h3"
                      component="h3"
                      className="card-title"
                    >
                      Additional Email Recipients
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Select
                      isMulti
                      name="colors"
                      options={memberOptions}
                      value={members}
                      onChange={(selectedOptions, { removedValue }) => {
                        if (removedValue) {
                          const removedOptionId = removedValue.value; // assuming 'value' contains the ID
                          setRemoveKeyId([...removeKeyId, removedOptionId]);
                        }
                        handleOptionChange(selectedOptions);
                      }}
                      className="muilt-select-field"
                      classNamePrefix="select"
                      placeholder="Select Additional Recepients"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "#8CC714",
                        },
                      })}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
        </Grid>
      </Box>

      {/* END :: KEYWORDS SETTING PAGE */}
      <Box className="footer-wrapper">
        <Button
          variant="contained"
          size="large"
          className="primary-btn btn w-120"
          disableRipple
          onClick={submitHandler}
          // disabled={isButtonDisabled}
        >
          Save
        </Button>
        <Button
          variant="contained"
          size="large"
          className="secondary-btn btn w-120"
          disableRipple
          onClick={() => navigate("/settings")}
        >
          Cancel
        </Button>
      </Box>
    </>
  );
};

export default ActivitySetting;

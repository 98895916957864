import { callApi } from "../../components/common/commonfunctionforapi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
export const fetchBidFilters = createAsyncThunk(
  "filters/fetchBidFilters",
  async (
    {
      search_text,
      startdate,
      enddate,
      bd_id,
      platform,
      billing_type,
      bid_type,
      keyword_id,
      technology_id,
      page,
      limit,
      converted,
      keywordSwitchValue,
      techStackSwitchValue,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/bidsfilter`,
        params: {
          search_text: search_text,
          startdate: startdate,
          enddate: enddate,
          bd_id: bd_id,
          platform: platform,
          billing_type: billing_type,
          keywordSwitchValue,
          techStackSwitchValue,
          bid_type: bid_type,
          keyword_id: keyword_id,
          technology_id: technology_id,
          page: page,
          limit,
          converted: converted,
        },
      });

      return response.data;
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  bidfilters: [],
  status: "idle",
  error: null,
};
const bidFilterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBidFilters.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBidFilters.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.bidfilters = action.payload;
        state.error = null;
      })

      .addMatcher(
        // Handle both rejected and fulfilled states with the same action
        (action) => action.type.startsWith("filters/fetchBidFilters/"),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            // Handle the rejected state with the defined value
            state.status = "failed";
            state.error = action.payload.message;
          }
        }
      );
  },
});
// export const { deleteFilterByIdSuccess } = filterSlice.actions;
export default bidFilterSlice.reducer;

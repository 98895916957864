import { callApi } from '../../../components/common/commonfunctionforapi';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setFeedback } from '.././feedbackSlice';

export const fetchCVs = createAsyncThunk(
  'CVs/fetchCVs',
  async (
    { page, pageSize, templateId, search },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await callApi({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/cv_builders/allcv`,
        params: {
          page: page,
          pageSize: pageSize,
          templateId: templateId,
          search: search,
        },
      });

      return response.data;
    } catch (error) {
      dispatch(
        setFeedback({
          status: 'fail',
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  CVs: [],
  status: 'idle',
  error: null,
};
const CVslice = createSlice({
  name: 'CVs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCVs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCVs.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.CVs = action.payload;
      })
      .addMatcher(
        // Handle both rejected and fulfilled states with the same action
        (action) => action.type.startsWith('CVs/fetchCVs/'),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            // Handle the rejected state with the defined value
            state.status = 'failed';
            state.error = action.payload.message;
          }
        }
      );
  },
});

export default CVslice.reducer;

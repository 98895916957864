import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  filter: null,
  loading: false,
  error: null,
};

const editLeadFilterByIdSlice = createSlice({
  name: "editLeadFilterById",
  initialState,
  reducers: {
    editLeadFilterByIdStart(state) {
      state.loading = true;
      state.error = null;
    },
    editLeadFilterByIdSuccess(state, action) {
      state.loading = false;
      state.filter = action.payload;
    },
    editLeadFilterByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  editLeadFilterByIdStart,
  editLeadFilterByIdSuccess,
  editLeadFilterByIdFailure,
} = editLeadFilterByIdSlice.actions;
export default editLeadFilterByIdSlice.reducer;

export const editleadfilterByIdAdmin =
  ({ editFilerId, editFilterData, status }) =>
  async (dispatch) => {
    try {
      dispatch(editLeadFilterByIdStart());
      const response = await callApi({
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/filters/${editFilerId}`,
        data: editFilterData,
        params: {
          filter_type: "leads",
          status: status,
        },
      });

      const filter = response;
      const data = response.data;
      dispatch(editLeadFilterByIdSuccess(filter));
      return { success: true, message: data.message };
    } catch (error) {
      dispatch(editLeadFilterByIdFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return { success: false, error: error.response.data.message };
    }
  };

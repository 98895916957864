import React from "react";

import { Box, Grid, Typography, Skeleton } from "@mui/material";

const ProjectDocuments = ({
  ProjectDocumentName,
  ProjectDocumentImg,
  ProjectDocumentLink,
  loading,
  handleLinkClick,
}) => {
  return loading ? (
    // If loading is true, render the skeleton loader
    <Grid item lg={4} md={4} sm={6} xs={12}>
      <Box className="document-card-wrapper">
        <Skeleton variant="rectangular" width={350} height={460} />
      </Box>
    </Grid>
  ) : (
    // If loading is false, render the actual content
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <Box className="document-card-wrapper">
        {/* <img src={ProjectDocumentImg} alt="Documents" /> */}
        <a
          href={ProjectDocumentLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => handleLinkClick(e, ProjectDocumentLink)}
        >
          <Typography variant="body1" component="span">
            {ProjectDocumentName}
          </Typography>
        </a>
      </Box>
    </Grid>
  );
  // <>
  //   <Grid item lg={4} md={4} sm={6} xs={12}>
  //     <Box className="document-card-wrapper">
  //       <img src={ProjectDocumentImg} alt="Documents" />
  //       <a
  //         href={ProjectDocumentLink}
  //         target="_blank"
  //         rel="noopener noreferrer"
  //       >
  //         <Typography variant="body1" component="span">
  //           {ProjectDocumentName}
  //         </Typography>
  //       </a>
  //     </Box>
  //   </Grid>
  // </>
};

export default ProjectDocuments;

import React, { useEffect, useState } from "react";

import {
  Button,
  Box,
  Grid,
  Typography,
  Skeleton,
  Divider,
  Chip,
} from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  RiArrowLeftLine,
  RiPencilLine,
  RiEyeLine,
  RiRepeat2Line,
} from "react-icons/ri";

import { useDispatch, useSelector } from "react-redux";

import { fetchBidsById } from "../../store/slices/bidByIdSlice";
import { updateBids, viewBids } from "../../utils/permissions";
import WithPermission from "../../components/common/withPermission";
import ConvertBidToLeadViewModal from "../../components/common/convertBidToLeadViewModal";

const BidView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location?.state?.path;
  let { state } = location;
  if (state === null) {
    state = {
       page: 1,
      rowsPerPage: 10,
      filteredPage: 1,
      filterApplied: false,
      bd: "",
      keywords: "",
      technologies: "",
      keywordPayload: "",
      technologyPayload: "",
      platform: "",
      billingtype: "",
      bidtype: "",
      bidDate: "",
      startDate: "",
      endDate: "",
      text: "",
      converted: "",
      keywordSwitchVal: true,
      techStackSwitchVal: true,
    };
  }

  const { bidId } = useParams();
  const bids = useSelector((state) => state.bidsbyId.bids);
  const [loading, setLoading] = useState(true);
  const [convertCancel, setConvertCancel] = useState(false);
  const [convertBidId, setConvertBidId] = useState(null);

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const options = { year: "numeric", month: "long", day: "numeric" };

    return date.toLocaleDateString(undefined, options);
  }

  const handleConvertOpen = (bidId) => {
    setConvertBidId(bidId);

    setConvertCancel(true);
  };

  const handleConvertClose = () => setConvertCancel(false);

  function capitalizeFirstLetter(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }
  const website = bids?.url;
  const handleLinkClick = (event) => {
    if (website && !website.startsWith("http")) {
      event.preventDefault();
      window.open(`http://${website}`, "_blank");
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(fetchBidsById({ bidId })).then((response) => {
      setLoading(false);
      if (response?.payload?.deletedAt||response?.error) {
        navigate("/not-found");
      }
    });
    setLoading(false);
  }, [dispatch, bidId,navigate]);

  return (
    <>
      {/* START :: LEAD VIEW HEADER */}
      <Box className="content-header view-lead-header bid-module-wrapper">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() =>path?navigate(path):
              navigate("/bids", {
                state: {
                  page: state.page,
                  rowsPerPage:state.rowsPerPage,
                  filteredPage: state.filteredPage,
                  filterApplied: state.filterApplied,
                  bd: state.bd,
                  platform: state.platform,
                  bidtype: state.bidtype,
                  billingtype: state.billingtype,
                   keywordSwitchVal: state.keywordSwitchVal,
                  techStackSwitchVal: state.techStackSwitchVal,
                  bidDate: state.bidDate,
                  startDate: state.startDate,
                  endDate: state.endDate,
                  keywordPayload: state.keywordPayload,
                  technologyPayload: state.technologyPayload,
                  keywords: state.keywords,
                  technologies: state.technologies,
                  text: state.text,
                  converted: state.converted,
                },
              })
            }
          ></Button>
          View Bid
        </Typography>
        <Box variant="div" component="div" className="content-header-right">
          {bids?.lead_id === null ? (
            <WithPermission
              permission={viewBids}
              element={
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn add-btn lead-btn"
                  disableRipple
                  endIcon={<RiRepeat2Line />}
                  onClick={() => handleConvertOpen(bids?.id)}
                >
                  Convert To Lead
                </Button>
              }
            />
          ) : null}
          {bids?.lead_id ? (
            <WithPermission
              permission={viewBids}
              element={
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn add-btn lead-btn"
                  disableRipple
                  endIcon={<RiEyeLine />}
                  onClick={() => navigate(`/leads/${bids?.lead_id}/view`)}
                >
                  View Lead
                </Button>
              }
            />
          ) : null}
          <WithPermission
            permission={updateBids}
            element={
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn only-icon-btn"
                endIcon={<RiPencilLine />}
                disableRipple
                onClick={() =>
                  navigate(`/bids/${bidId}/edit`, {
                    state: {
                      page: state.page,
                      rowsPerPage:state.rowsPerPage,
                      filteredPage: state.filteredPage,
                      filterApplied: state.filterApplied,
                      bd: state.bd,
                      platform: state.platform,
                      bidtype: state.bidtype,
                      billingtype: state.billingtype,
                      bidDate: state.bidDate,
                      startDate: state.startDate,
                      endDate: state.endDate,
                      keywordPayload: state.keywordPayload,
                      technologyPayload: state.technologyPayload,
                      keywords: state.keywords,
                      technologies: state.technologies,
                      text: state.text,
                      converted: state.converted,
                    },
                  })
                }
              ></Button>
            }
          />
        </Box>
      </Box>

      {/* END :: LEAD VIEW HEADER */}

      {/* START :: LEAD VIEW CONTENT */}
      <Box className="content-wrapper">
        <Box className="card-wrapper">
          <Grid
            container
            spacing={2}
            className="view-info"
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="h3"
                component="h3"
                className="card-title"
              >
                Bid Details
              </Typography>
            </Grid>

            <Grid item lg={12} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                BD
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography
                  variant="body1"
                  component="span"
                  className="value"
                >
                  {bids?.bd === null
                    ? "-"
                    : ` ${capitalizeFirstLetter(bids?.bd?.first_name)}
                  ${capitalizeFirstLetter(bids?.bd?.last_name)}`}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                Platform
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography
                  variant="body1"
                  component="span"
                  className="value"
                >
                  {bids?.platform === null || bids?.platform === ""
                    ? "-"
                    : bids?.platform}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                Profile Used
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography
                  variant="body1"
                  component="span"
                  className="value"
                >
                  {bids?.bid_profile === null || bids?.bid_profile === ""
                    ? "-"
                    : bids?.bid_profile}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                Title
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography
                  variant="body1"
                  component="span"
                  className="value"
                >
                  {bids?.title === "" || bids?.title === null ? (
                    "-"
                  ) : (
                    <a
                      href={website}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleLinkClick}
                      style={{ color: "#232323", textDecoration: "none" }}
                    >
                      {bids?.title}
                    </a>
                  )}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                URL
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <a
                  href={website}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleLinkClick}
                  style={{ wordBreak: "break-all" }}
                >
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {website === "" || website === null ? "-" : website}
                  </Typography>
                </a>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                Keywords
              </Typography>
              {loading ? (
                <Box display="flex">
                  {Array.from({ length: 3 }).map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="text"
                      width={100}
                      height={40}
                    />
                  ))}
                </Box>
              ) : (
                <Box variant="div" component="div" className="tag-wrap">
                  {bids?.bid_keywords?.length > 0
                    ? bids?.bid_keywords?.map((bid) => (
                        <Chip label={bid?.keywords?.keyword_name} />
                      ))
                    : "-"}
                </Box>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' } }}>
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                Technology Stacks
              </Typography>
              {loading ? (
                <Box display="flex">
                  {Array.from({ length: 3 }).map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="text"
                      width={100}
                      height={40}
                    />
                  ))}
                </Box>
              ) : (
                <Box variant="div" component="div" className="tag-wrap">
                  {bids?.bid_technologies?.length > 0
                    ? bids?.bid_technologies?.map((bid) => (
                        <Chip label={bid?.technologies?.technology_name} />
                      ))
                    : "-"}
                </Box>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                Billing Type
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography
                  variant="body1"
                  component="span"
                  className="value"
                >
                  {bids?.billing_type === null || bids?.billing_type === ""
                    ? "-"
                    : bids?.billing_type === "Fixedcost"
                    ? "Fixed-Cost"
                    : bids?.billing_type}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                Bid Type
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography
                  variant="body1"
                  component="span"
                  className="value"
                >
                  {bids?.bid_type === null || bids?.bid_type === ""
                    ? "-"
                    : bids?.bid_type === "Fulltime"
                    ? "Full-Time"
                    : "Part-Time"}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                Client Budget
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography
                  variant="body1"
                  component="span"
                  className="value"
                >
                  {bids?.client_budget === null ||
                  bids?.client_budget === ""
                    ? "-"
                    : bids?.client_budget}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                Bid Value
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography
                  variant="body1"
                  component="span"
                  className="value"
                >
                  {bids?.bid_value === null || bids?.bid_value === ""
                    ? "-"
                    : bids?.bid_value}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                Bid Date
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography
                  variant="body1"
                  component="span"
                  className="value"
                >
                  {bids?.bid_date === null || bids?.bid_date === ""
                    ? "-"
                    : formatDate(bids?.bid_date)}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ display: { xs: 'block', sm: 'none', md: 'none', lg: 'none' } }}>
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                Response Date
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography
                  variant="body1"
                  component="span"
                  className="value"
                >
                  {bids?.response_date === null ||
                  bids?.response_date === ""
                    ? "-"
                    : formatDate(bids?.response_date)}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Typography variant="body1" component="label">
                Closure Date
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography
                  variant="body1"
                  component="span"
                  className="value"
                >
                  {bids?.closure_date === null || bids?.closure_date === ""
                    ? "-"
                    : formatDate(bids?.closure_date)}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>

      <ConvertBidToLeadViewModal
        handleConvertOpen={handleConvertOpen}
        handleConvertClose={handleConvertClose}
        convertCancel={convertCancel}
        setConvertCancel={setConvertCancel}
        convertBidId={convertBidId}
      />
    </>
  );
};

export default BidView;

/* eslint-disable no-unused-vars */
// EmailMaskUtils.js
export const maskEmail = (email) => {
  if (!email) {
    return ""; // or return some default value, depending on your requirements
  }

  // Extract first and last letters of the email
  const firstLetter = email?.charAt(0);
  const lastLetter = email?.charAt(email?.length - 1);

  // Extract parts of the email address
  const atIndex = email?.indexOf("@");
  const dotIndex = email?.lastIndexOf(".");

  if (atIndex === -1 || dotIndex === -1 || atIndex >= dotIndex) {
    // Invalid email format, handle accordingly
    return email;
  }

  const username = email?.slice(0, atIndex);
  const domain = email?.slice(atIndex + 1, dotIndex);
  const extension = email?.slice(dotIndex + 1);

  // Mask the email parts
  const maskedUsername = `${firstLetter}****`;
  const maskedDomain = `***`;
  const maskedExtension = `**${lastLetter}`;

  // Combine masked parts to form the final masked email
  const maskedEmail = `${maskedUsername}@${maskedDomain}.${maskedExtension}`;

  return maskedEmail;
};

// PhoneMaskUtils.js
export const maskPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return ""; // or return some default value, depending on your requirements
  }

  // Remove non-numeric characters from the phone number
  const numericPhoneNumber = phoneNumber?.replace(/\D/g, "");

  // Check if numericPhoneNumber is empty after removing non-numeric characters
  if (!numericPhoneNumber) {
    return ""; // or handle accordingly based on your requirements
  }

  // Extract first and last digits of the phone number
  const firstDigit = numericPhoneNumber?.charAt(0);
  const lastDigit = numericPhoneNumber?.charAt(numericPhoneNumber?.length - 1);

  // Mask the digits in between with stars
  const maskedDigits = "********";

  // Combine masked parts to form the final masked phone number
  const maskedPhoneNumber = `${firstDigit}${maskedDigits}${lastDigit}`;

  return maskedPhoneNumber;
};

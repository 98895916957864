import { Box, Fade, Modal, Typography, Grid, Stack } from "@mui/material";
import Select from "react-select";
import React, { useState } from "react";
import Button from "@mui/material/Button";

const developmentStatusOptions = [
  { value: "ongoing", label: "Ongoing" },
  { value: "planned", label: "Planned" },
];

const ConvertAlertModal = ({
  convertCancel,
  setConvertCancel,
  leadStatus,
  setLeadStatus,
  iswonButtonDisabled,
  initialLeadStatus,
  onSubmit,
}) => {
  const [developmentStatus, setDevelopmentStatus] = useState("planned");
  const submitHandler = () => {
    // Perform actions specific to the "Convert" button click here
    onSubmit(developmentStatus); // Call the onSubmit function passed from the parent component
  };

  const cancelHandler = () => {
    setConvertCancel(false); // Close the modal when "Cancel" button is clicked
  };

  const handleDevelopmentStatusChange = (selectedOption) => {
    setDevelopmentStatus(selectedOption);
  };
  // console.log(leadPayload, "wonSubmit");
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={convertCancel}
        // onClose={ConvertLeadToProjectModalClose}
        closeAfterTransition
      >
        <Fade in={convertCancel}>
          <Box className="modal-wrapper">
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3">
                Do you want to convert this lead to project?
              </Typography>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Stack spacing={3} className="h-100">
                  <Box
                    className="card-wrapper"
                    style={{ marginTop: "40px", display: "flex" }}
                  >
                    <Typography
                      variant="body1"
                      component="h5"
                      style={{
                        marginBottom: "10px",
                        marginRight: "20px",
                      }}
                    >
                      Development Status
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                        style={{ color: "red" }}
                      >
                        *
                      </Typography>
                    </Typography>
                    <Select
                      name="colors"
                      options={developmentStatusOptions}
                      value={developmentStatusOptions.find(
                        (Option) => Option.value === developmentStatus
                      )}
                      className="muilt-select-field"
                      classNamePrefix="select"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "#8CC714",
                        },
                      })}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          width: "300px",
                          marginTop: "-10px", // Set your desired width
                        }),
                      }}
                      onChange={(selectedOption) =>
                        handleDevelopmentStatusChange(selectedOption?.value)
                      }
                    />
                  </Box>
                </Stack>
              </Grid>
              {/* <Typography variant="body1" component="p">
                  This action can’t be undone.
              </Typography> */}
            </Box>
            <Box variant="div" component="div" className="body"></Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={submitHandler}
                  disabled={iswonButtonDisabled}
                >
                  Convert
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={cancelHandler}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ConvertAlertModal;

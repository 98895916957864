import { Box, Fade, Modal, Typography } from '@mui/material';
import React from 'react';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { successToast } from '../../responses/successToast';
import { fetchClients } from '../../store/slices/clientSlice';
import { deleteTemplateCVById } from '../../store/slices/cv-builder/deleteTemplateCVbyIdSlice';
import { fetchTemplates } from '../../store/slices/cv-builder/templateSlice';
import { fetchCVs } from '../../store/slices/cv-builder/CVSlice';

const CVTemplateDeleteModal = ({
  open,
  handleDeleteClose,
  id,
  isTemplate,
  setSelectedTemplate,
  selectedTemplate,
  setPage,
}) => {
  const dispatch = useDispatch();
  const word = isTemplate ? 'Template' : 'CV';
  const submitHandler = () => {
    dispatch(deleteTemplateCVById({ id }))
      .unwrap()
      .then((result) => {
        const { success } = result;

        if (success) {
          successToast(`${word} deleted successfully`);
          if (word === 'Template') {
            dispatch(fetchTemplates({}));
            setSelectedTemplate({ id: 0, label: 'All' });
          } else {
            if (selectedTemplate?.id === 0) {
              dispatch(fetchCVs({ page: 1, pageSize: 10 }));
            } else {
              dispatch(
                fetchCVs({
                  page: 1,
                  pageSize: 10,
                  templateId: selectedTemplate?.id,
                })
              );
            }
            setPage(1);
          }

          //   dispatch(fetchClients({ page: currentPage, limit: 10 }))
          //     .unwrap()
          //     .then((bidsResult) => {
          //       // console.log(bidsResult);

          //       const totalPages = bidsResult[0].totalPages || 1;
          //       // console.log(totalPages);
          //       const updatedPage = Math.min(currentPage, totalPages);
          //       // console.log(updatedPage);

          //       dispatch(fetchClients({ page: updatedPage, limit: 10 }));
          //     });
          handleDeleteClose();
        } else {
          handleDeleteClose();
        }
      })
      .catch((error) => {
        console.error(`Failed to delete ${word}:`, error);
        handleDeleteClose();
      });
  };
  return (
    <>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={open}
        // onClose={ClientDeleteModal}
        closeAfterTransition
      >
        <Fade in={open}>
          <Box className='modal-wrapper'>
            <Box variant='div' component='div' className='title'>
              <Typography variant='h3' component='h3'>
                Do you want to delete this {word}?
              </Typography>
              <Typography variant='body1' component='p'>
                This action can’t be undone.
              </Typography>
            </Box>
            <Box variant='div' component='div' className='body'></Box>
            <Box variant='div' component='div' className='footer'>
              <Box variant='div' component='div' className='btn-group'>
                <Button
                  variant='contained'
                  size='large'
                  className='primary-btn btn'
                  disableRipple
                  onClick={submitHandler}
                >
                  Delete
                </Button>
                <Button
                  variant='contained'
                  size='large'
                  className='secondary-btn btn'
                  disableRipple
                  onClick={handleDeleteClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default CVTemplateDeleteModal;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Button,
  Paper,
  Grid,
  Switch,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import { RiArrowLeftLine, RiArrowDownCircleLine } from "react-icons/ri";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { fetchLeadAllocationReports } from "../../store/slices/leadAllocationReportSlice";
import Loader from "./../../components/common/loader";

function capitalizeWords(input) {
  return input?.replace(/\w+/g, function (word) {
    return word?.charAt(0).toUpperCase() + word.slice(1);
  });
}

function capitalizeFirstLetter(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}

function formatStatus(str) {
  const words = str?.split("_");
  const capitalizedWords = words?.map((word) => capitalizeFirstLetter(word));
  return capitalizedWords?.join(" ");
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -16,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    background: "#ebf5d9",
    fontWeight: "700",
  },
}));

function truncateLabel(label, maxLength) {
  if (label?.length > maxLength) {
    return label.substring(0, maxLength) + "... ";
  }
  return label;
}

// function formatDate(inputDate) {
//   const options = { year: "numeric", month: "short", day: "numeric" };
//   return new Date(inputDate).toLocaleDateString(undefined, options);
// }

const KanbanCard = ({
  value,
  title,
  onClick,
  contactPerson,
  status,
  BDM,
  BDE,
  TL,
  DayDifference,
  projectId,
}) => {
  const getCardClass = (status) => {
    switch (status) {
      case "open":
        return "open";
      case "estimation_requested":
        return "requested";
      case "estimation_provided":
        return "provided";
      case "standBy":
        return "standBy";
      case "closed":
        return "closed";
      case "won":
        return "won";
      case "lost":
        return "lost";
      default:
        return "open";
    }
  };
  const cardClass = getCardClass(status);

  const handleCardClick = () => {
    // Navigate to the desired path in a new tab
    window.open(`/leads/${projectId}/view`, "_blank");
  };

  return (
    <Paper
      elevation={3}
      className={`allocation-card-${cardClass}`}
      onClick={handleCardClick}
    >
      {title === "" ? (
        <Typography
          variant="h6"
          style={{
            fontSize: "16px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          -
        </Typography>
      ) : (
        <Box
          variant="div"
          component="div"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <Tooltip
            title={
              <div
                style={{
                  color: "white",
                  fontSize: "13px",
                }}
              >
                {title}
              </div>
            }
            arrow
            placement="bottom-start"
          >
            <Typography
              variant="h6"
              style={{
                fontSize: "16px",
              }}
            >
              {truncateLabel(capitalizeWords(title), 22)}
            </Typography>
          </Tooltip>
        </Box>
      )}
      <Box>
        <Typography variant="body1" className="field-title">
          Contact Person:{" "}
          {
            <Tooltip
              title={
                <div style={{ color: "white", fontSize: "13px" }}>
                  {capitalizeWords(contactPerson)}
                </div>
              }
              arrow
              placement="bottom-start"
            >
              <span style={{ fontWeight: "600" }}>
                {truncateLabel(capitalizeWords(contactPerson), 13)}
              </span>
            </Tooltip>
          }
        </Typography>
        {value === 0 ? (
          <Typography variant="body1" className="field-title">
            BDM:{" "}
            <span style={{ fontWeight: "600" }}>
              {BDM?.first_name ? (
                <Tooltip
                  title={
                    <div style={{ color: "white", fontSize: "13px" }}>
                      {capitalizeWords(BDM.first_name)}{" "}
                      {capitalizeWords(BDM.last_name)}
                    </div>
                  }
                  arrow
                  placement="bottom-start"
                >
                  {truncateLabel(
                    `${capitalizeWords(BDM.first_name)} ${capitalizeWords(
                      BDM.last_name
                    )}`,
                    16
                  )}
                </Tooltip>
              ) : (
                "-"
              )}
            </span>
          </Typography>
        ) : null}

        {value === 1 ? (
          <Typography variant="body1" className="field-title">
            TL:{" "}
            <span style={{ fontWeight: "600" }}>
              {TL?.first_name ? (
                <Tooltip
                  title={
                    <div style={{ color: "white", fontSize: "13px" }}>
                      {capitalizeWords(TL.first_name)}{" "}
                      {capitalizeWords(TL.last_name)}
                    </div>
                  }
                  arrow
                  placement="bottom-start"
                >
                  {truncateLabel(
                    `${capitalizeWords(TL.first_name)} ${capitalizeWords(
                      TL.last_name
                    )}`,
                    14
                  )}
                </Tooltip>
              ) : (
                "-"
              )}
            </span>
          </Typography>
        ) : null}

        <Typography variant="body1" className="field-title">
          BDE:{" "}
          <span style={{ fontWeight: "600" }}>
            {BDE?.first_name ? (
              <Tooltip
                title={
                  <div style={{ color: "white", fontSize: "13px" }}>
                    {capitalizeWords(BDE.first_name)}{" "}
                    {capitalizeWords(BDE.last_name)}
                  </div>
                }
                arrow
                placement="bottom-start"
              >
                {truncateLabel(
                  `${capitalizeWords(BDE.first_name)} ${capitalizeWords(
                    BDE.last_name
                  )}`,
                  16
                )}
              </Tooltip>
            ) : (
              "-"
            )}
          </span>
        </Typography>

        <Box
          style={{
            display: "flex",
            paddingRight: "10px",
            gap: "7px",
            alignItems: "center",
          }}
        >

          <Typography
            variant="body1"
            style={{
              fontSize: "13px",
              fontWeight: "600",
              fontStyle: "italic",
            }}
          >
            <span style={{ color: "#ff606b" }}>{`Since ${
              DayDifference === 0
                ? "Today"
                : DayDifference === 1
                ? "Yesterday"
                : DayDifference
            } ${
              DayDifference === 0 ? "" : DayDifference === 1 ? "" : "days"
            }`}</span>
          </Typography>
        </Box>
        <Box style={{ justifyContent: "space-between" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "right",
              paddingRight: "10px",
              gap: "7px",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Box
              style={{
                borderRadius: "50%",
                background:
                  status === "open"
                    ? "var(--convert-btn-icon)"
                    : status === "estimation_requested"
                    ? "#ccaa02"
                    : status === "estimation_provided"
                    ? "var(--export-btn-icon)"
                    : status === "standBy"
                    ? "var(--textgrey)"
                    : status === "closed"
                    ? "var(--fire-btn-icon)"
                    : status === "won"
                    ? "var(--green)"
                    : status === "lost"
                    ? "var(--delete-btn-icon)"
                    : "green",
                width: "8px",
                height: "8px",
                marginBottom: "2px",
              }}
            ></Box>
            <Tooltip
              title={
                <div style={{ color: "white", fontSize: "13px" }}>
                  {formatStatus(
                    status === "closed" ? "Not Responding" : status
                  )}
                </div>
              }
              arrow
              placement="bottom-start"
            >
              <Typography variant="body1" className="field-title-1">
                <span>
                  {formatStatus(
                    status === "closed" ? "Not Responding" : status
                  )}
                </span>
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

const LeadReport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.leadreports.leadreports);
  // console.log(reports, "reports");
  const [value, setValue] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [rows, setRows] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);

  const loadMoreHandler = () => {
    setRows((prev) => prev + 10);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setRows(10);
  };

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
    setRows(10);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await dispatch(
          fetchLeadAllocationReports({
            role: value === 0 ? "TL" : "BD",
            hide: isChecked === true ? 1 : 0,
            raw: rows,
          })
        );

        // Check if any user's total_leads_count exceeds the total number of rows
        const shouldShowLoadMore = response.payload.some(
          (userData) => userData.total_leads_count > rows
        );

        setShowLoadMore(shouldShowLoadMore);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, isChecked, rows, value]);

  const ProjectCard = ({ project, navigate }) => (
    <Grid item key={project.id} style={{ marginBottom: "16px" }}>
      <Paper className="custom-column">
        <KanbanCard
          key={project?.id}
          value={value}
          title={capitalizeWords(project?.project_name)}
          onClick={() => navigate(`/leads/${project?.id}`)}
          contactPerson={capitalizeWords(project?.contact_person_name)}
          status={project?.status}
          BDM={{
            first_name: capitalizeWords(project?.bdm_first_name),
            last_name: capitalizeWords(project?.bdm_last_name),
          }}
          BDE={{
            first_name: capitalizeWords(project?.bde_first_name),
            last_name: capitalizeWords(project?.bde_last_name),
          }}
          TL={{
            first_name: capitalizeWords(project?.tl_first_name),
            last_name: capitalizeWords(project?.tl_last_name),
          }}
          DayDifference={project?.daysDifference}
          projectId={project?.id}
        />
      </Paper>
    </Grid>
  );

  return (
    <>
      {/* ... Header code ... */}
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => { 
              navigate("/reports");
            }}
          ></Button>
          Lead Allocation
        </Typography>
      </Box>
      {isLoading && !showLoadMore && <Loader />}
      <Box className="content-layout" sx={{paddingBottom:"12px"}}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="tab-project-wrapper lead-allocation-wrapper">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="tab-area"
          >
            <Tab label="TL" style={{ color: "var(--primary-text-color)" }} />
            <Tab label="BD" style={{ color: "var(--primary-text-color)" }} />
          </Tabs>
          <Box variant="div" component="div" className="content-header-right ">
            <Box
              style={{
                display: "flex",
                paddingRight: "10px",
                gap: "8px",
                alignItems: "center",
                flexWrap:"wrap"
              }}
            >
              <Box sx={{display:'flex', alignItems:"center", gap:"8px"}}>
                <Box
                  style={{
                    borderRadius: "50%",
                    background: "var(--convert-btn-icon)",
                    width: "12px",
                    height: "12px",
                    marginBottom: "2px",
                  }}
                ></Box>
                <Typography variant="body1" className="field-title">
                  {
                    <span style={{ fontWeight: "600", fontSize: "12px" }}>
                      Open
                    </span>
                  }
                </Typography>
              </Box>
              <Box sx={{display:'flex', alignItems:"center", gap:"8px"}}>
                <Box
                  style={{
                    borderRadius: "50%",
                    background: "#ccaa02",
                    width: "12px",
                    height: "12px",
                    marginBottom: "2px",
                  }}
                ></Box>
                <Typography variant="body1" className="field-title">
                  {
                    <span style={{ fontWeight: "600", fontSize: "12px" }}>
                      Estimation Requested
                    </span>
                  }
                </Typography>
              </Box>
              <Box sx={{display:'flex', alignItems:"center", gap:"8px"}}>
                <Box
                  style={{
                    borderRadius: "50%",
                    background: "var(--export-btn-icon)",
                    width: "12px",
                    height: "12px",
                    marginBottom: "2px",
                  }}
                ></Box>
                <Typography variant="body1" className="field-title">
                  {
                    <span style={{ fontWeight: "600", fontSize: "12px" }}>
                      Estimation Provided
                    </span>
                  }
                </Typography>
              </Box>
              <Box sx={{display:'flex', alignItems:"center", gap:"8px"}}>
                <Box
                  style={{
                    borderRadius: "50%",
                    background: "var(--textgrey)",
                    width: "12px",
                    height: "12px",
                    marginBottom: "2px",
                  }}
                ></Box>
                <Typography variant="body1" className="field-title">
                  {
                    <span style={{ fontWeight: "600", fontSize: "12px" }}>
                      StandBy
                    </span>
                  }
                </Typography>
              </Box>
              <Box sx={{display:'flex', alignItems:"center", gap:"8px"}}>
                <Box
                  style={{
                    borderRadius: "50%",
                    background: "var(--fire-btn-icon)",
                    width: "12px",
                    height: "12px",
                    marginBottom: "2px",
                  }}
                ></Box>
                <Typography variant="body1" className="field-title">
                  {
                    <span style={{ fontWeight: "600", fontSize: "12px" }}>
                      Not Responding
                    </span>
                  }
                </Typography>
              </Box>
              <Box sx={{display:'flex', alignItems:"center", gap:"8px"}}>
                {isChecked ? (
                  <Box
                    style={{
                      borderRadius: "50%",
                      background: "var(--green)",
                      width: "12px",
                      height: "12px",
                      marginBottom: "2px",
                    }}
                  ></Box>
                ) : null}
                {isChecked ? (
                  <Typography variant="body1" className="field-title">
                    {
                      <span style={{ fontWeight: "600", fontSize: "12px" }}>
                        Won
                      </span>
                    }
                  </Typography>
                ) : null}
              </Box>
              <Box sx={{display:'flex', alignItems:"center", gap:"8px"}}>
                {isChecked ? (
                  <Box
                    style={{
                      borderRadius: "50%",
                      background: "var(--delete-btn-icon)",
                      width: "12px",
                      height: "12px",
                      marginBottom: "2px",
                    }}
                  ></Box>
                ) : null}
                {isChecked ? (
                  <Typography variant="body1" className="field-title">
                    {
                      <span style={{ fontWeight: "600", fontSize: "12px" }}>
                        Lost
                      </span>
                    }
                  </Typography>
                ) : null}
              </Box>
            </Box>
            <FormControlLabel
              control={
                <Switch checked={isChecked} onChange={handleSwitchChange} />
              }
              label="Show All Leads"
            />
          </Box>
        </Box>
        <TableContainer
          className="billable-report-table-wrapper"
          style={{
              maxHeight: "calc(100vh - 230px)",
              borderRadius: "5px",
              width: "calc(100% - 50px)",
              overflowX: "auto",
              whiteSpace: "nowrap",
              marginTop: "24px",
              paddingBottom: "4px",
              marginLeft: "24px",
          }}
          // component={Paper}
        >
          <Table stickyHeader>
            <TableHead className="head-row">
              <TableRow>
                {reports?.map((report) => (
                  <TableCell
                    key={report.id}
                    align="center"
                    sx={{
                      // zIndex: 10000,
                      backgroundColor: "#323232",
                      color: "#ffffff",
                      padding: "5px",
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                    onMouseEnter={() => setTooltipOpen(true)}
                    onMouseLeave={() => setTooltipOpen(false)}
                  >
                    {(report?.first_name + report?.last_name)?.length > 16 ? (
                      <Tooltip
                        title={
                          <div
                            style={{
                              color: "white",
                              fontSize: "13px",
                            }}
                          >
                            {capitalizeWords(report?.first_name)}{" "}
                            {capitalizeWords(report?.last_name)}{" "}
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <span style={{ color: "white" }}>
                          {truncateLabel(
                            capitalizeWords(
                              report?.first_name + " " + report?.last_name
                            ),
                            16
                          )}
                        </span>
                      </Tooltip>
                    ) : (
                      <>
                        {capitalizeWords(report?.first_name)}{" "}
                        {capitalizeWords(report?.last_name)}
                      </>
                    )}
                    <StyledBadge
                      badgeContent={
                        report?.total_leads_count === 0
                          ? "0"
                          : report?.total_leads_count
                      }
                    />
                    {isTooltipOpen && (
                      <Tooltip
                        title={capitalizeWords(
                          `${report?.first_name} ${reports.last_name}`
                        )}
                      ></Tooltip>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody className="body-row">
              <TableRow>
                {reports?.map((report) =>
                  report?.leads?.length > 0 ? (
                    <TableCell>
                      <Box>
                        {report?.leads?.map((project) => (
                          <ProjectCard
                            key={project?.id}
                            project={project}
                            navigate={navigate}
                          />
                        ))}
                      </Box>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <Box>
                        <Grid>
                          <Paper className="custom-column">
                            <Typography
                              variant="body1"
                              className="field-title"
                              style={{
                                backgroundColor: "var(--light-grey)",
                                paddingLeft: "7px",
                                height: "150px",
                                borderLeft: "4px solid var(--textgrey)",
                                borderRadius:"4px"
                              }}
                            >
                              <Typography
                                component="h6"
                                variant="h6"
                                style={{
                                  paddingTop: "10px",
                                  fontWeight: "500",
                                  fontSize: "16px",
                                }}
                              >
                                No Leads Found
                              </Typography>
                            </Typography>
                          </Paper>
                        </Grid>
                      </Box>
                    </TableCell>
                  )
                )}
              </TableRow>
            </TableBody>
          </Table>
          {isLoading && showLoadMore && (
            <Box className="report-load-more-section">
              <CircularProgress />
            </Box>
          )}
          {showLoadMore && !isLoading && (
            <Box className="report-load-more-section">
              <Button
                variant="contained"
                size="medium"
                className="primary-btn btn add-btn"
                endIcon={<RiArrowDownCircleLine />}
                disableRipple
                onClick={loadMoreHandler}
              >
                <Typography variant="body1" component="p">
                  Load More
                </Typography>
              </Button>
            </Box>
          )}
        </TableContainer>
      </Box>
    </>
  );
};

export default LeadReport;

import React from 'react';

import CvTemplateList from './CvTemplateList';

export default function CVBuilder() {
  return (
    <div>
      <CvTemplateList />
    </div>
  );
}

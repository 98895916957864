import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import logo from "../../assets/images/pms-logo.svg";
import PatternBig from "../../assets/images/Big-Ellipse.png";
import PatternSmall from "../../assets/images/Small-Ellipse.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../store/slices/authSlice";
import { useFormik } from "formik";
import { successToast } from "../../responses/successToast";
import * as Yup from "yup";
import {
  getRememberMeStorage,
  removeRememberMeStorage,
  setRememberMeStorage,
} from "../../utils/rememberMe";
// import { encrypt, decrypt } from "n-krypta";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter email address"),
  password: Yup.string().required("Please enter password"),
});

// const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const storedCredentials = getRememberMeStorage();

  const formik = useFormik({
    initialValues: {
      email: storedCredentials?.email || "", // Set initial values from stored credentials
      password: storedCredentials?.password || "",
      rememberMe: Boolean(storedCredentials),
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(login(values))
        .then((response) => {
          if (response.success) {
            successToast("Login successful");
            navigate("/dashboard");

            // If "Remember Me" is checked, store the credentials
            if (formik.values.rememberMe) {
              setRememberMeStorage(values);
            } else {
              // If not checked, remove stored credentials
              removeRememberMeStorage();
            }
          } else {
            console.error("Login failed. Please check your credentials.");
          }
        })
        .catch((error) => {
          console.error("API call error:", error);
        });
    },
  });

  return (
    <>
      <Box variant="div" component="div" className="login-main-wrap ">
        <Box variant="div" component="div" className="logo-wrap">
          <img src={logo} alt="logo-main" loading="lazy" />
          {/* <h2>Project Management System</h2> */}
        </Box>

        <Box variant="div" component="div" className="login-form-wrap p-24">
          <form onSubmit={formik.handleSubmit}>
            <Box variant="div" component="div" className="title">
              {/* <h3></h3> */}
              <Typography variant="h3" component="h3">
                Sign In
              </Typography>
              <Typography variant="body1" component="p">
                Enter your details to sign in to your account.
              </Typography>
            </Box>

            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Box variant="div" component="div">
                  <Typography variant="body1" component="label">
                    Email Id
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic email"
                    label=""
                    variant="outlined"
                    placeholder="Enter Email"
                    size="small"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={
                      formik.touched.email && formik.errors.email ? (
                        <span className="error-message-login">
                          {formik.errors.email}
                        </span>
                      ) : null
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12} mt={1}>
                <Box variant="div" component="div">
                  <Typography variant="body1" component="label">
                    Password
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic password"
                    label=""
                    variant="outlined"
                    placeholder="Enter Password"
                    size="small"
                    name="password"
                    type="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password ? (
                        <span className="error-message-login">
                          {formik.errors.password}
                        </span>
                      ) : null
                    }
                  />
                  <Box variant="div" component="div" className="forgot-pw">
                    <Button
                      // href={`${process.env.CENTARL_URL}/forgetpassword`}
                      className="link-btn"
                      disableRipple
                      // onClick={navigateHandler}
                    >
                      {/* <Link
                        to={`${process.env.REACT_APP_CENTRAL_URL}forgetpassword`}
                        style={{ color: "#232323", textDecoration: "none" }}
                      >
                        Forgot Password?
                      </Link> */}
                      <a
                        href={`${process.env.REACT_APP_CENTRAL_URL}forgetpassword`}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: "#232323", textDecoration: "none" }}
                      >
                        Forgot Password?
                      </a>
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                className="checkbox-style login-checkbox"
                control={
                  <Checkbox
                    disableRipple
                    checked={formik.values.rememberMe}
                    onChange={formik.handleChange}
                    name="rememberMe"
                  />
                }
                label="Remember Me"
              />
            </Grid>
            <Button
              variant="contained"
              size="large"
              className="primary-btn btn"
              disableRipple
              type="submit"
              // onClick={() => navigate("/dashboard")}
            >
              {" "}
              Sign In
            </Button>
          </form>
        </Box>

        <Box variant="div" component="div" className="pattern-big">
          <img src={PatternBig} alt="pattern" loading="lazy" />
        </Box>
        <Box variant="div" component="div" className="pattern-small">
          <img src={PatternSmall} alt="pattern" loading="lazy" />
        </Box>
      </Box>
    </>
  );
};

export default Login;

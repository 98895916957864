import { createSlice } from '@reduxjs/toolkit';
import { setFeedback } from '../feedbackSlice';
import { callApi } from '../../../components/common/commonfunctionforapi';

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const createTemplateCVSlice = createSlice({
  name: 'createTemplateCV',
  initialState,
  reducers: {
    createTemplateCVStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createTemplateCVSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    createTemplateCVFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  createTemplateCVStart,
  createTemplateCVSuccess,
  createTemplateCVFailure,
} = createTemplateCVSlice.actions;

export default createTemplateCVSlice.reducer;

export const createTemplateCV = (Data) => async (dispatch) => {
  try {
    dispatch(createTemplateCVStart());

    const response = await callApi({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/cv_builders`,
      data: Data,
    });

    const data = response.data;

    dispatch(createTemplateCVSuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, message: data.message, data: data };
  } catch (error) {
    dispatch(createTemplateCVFailure(error.message));
    dispatch(
      setFeedback({
        status: 'fail',
        message: error.response.data.message,
        code: error.response.status_code,
      })
    );
    return { success: false, error: error.response.message };
  }
};

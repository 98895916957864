import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  activity: null,
  loading: false,
  error: null,
};

const createProjectActivitySlice = createSlice({
  name: "createprojectactivity",
  initialState,
  reducers: {
    createProjectActivityStart(state) {
      state.loading = true;
      state.error = null;
    },
    createProjectActivitySuccess(state, action) {
      state.loading = false;
      state.project = action.payload;
    },
    createProjectActivityFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  createProjectActivityStart,
  createProjectActivitySuccess,
  createProjectActivityFailure,
} = createProjectActivitySlice.actions;
export default createProjectActivitySlice.reducer;

export const createProjectActivity = (activityData) => async (dispatch) => {
  try {
    dispatch(createProjectActivityStart());
    const response = await callApi({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/projectactivity`,
      data: activityData,
    });

    const activity = response;
    const data = response.data;
    dispatch(createProjectActivitySuccess(activity));
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(createProjectActivityFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.response.data.message };
  }
};

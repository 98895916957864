import Cookies from "js-cookie";

const USER_ID_COOKIE = process.env.REACT_APP_USER_ID_COOKIE;

const getIdFromCookies = () => {
  const userId = Cookies.get(USER_ID_COOKIE);
  return userId;
};

export default getIdFromCookies;

import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  user: null,
  loading: false,
  error: null,
};

const editClientByIdSlice = createSlice({
  name: "editClientById",
  initialState,
  reducers: {
    editClientByIdStart(state) {
      state.loading = true;
      state.error = null;
    },
    editClientByIdSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    editClientByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  editClientByIdStart,
  editClientByIdSuccess,
  editClientByIdFailure,
} = editClientByIdSlice.actions;
export default editClientByIdSlice.reducer;

export const editClientByIdAdmin =
  (clientId, clientData) => async (dispatch) => {
    try {
      dispatch(editClientByIdStart());
      const response = await callApi({
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/client/${clientId}`,
        data: clientData,
      });

      const client = response;
      const data = response.data;
      dispatch(editClientByIdSuccess(client));
      return { success: true, message: data.message };
    } catch (error) {
      dispatch(editClientByIdFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return { success: false, error: error.response.data.message };
    }
  };

// PopupComponent.jsx

import {
  Box,
  Fade,
  Grid,
  Modal,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import moment from "moment";
import { RiPhoneFill, RiCloseLine, RiStackLine } from "react-icons/ri";
import { MdAccessAlarms } from "react-icons/md";
import ActivityPopupComponent from "./activityDescriptionModal";
import { FaArrowTrendUp } from "react-icons/fa6";

const PopupComponent = ({ activities, onClose, popupOpen, assignee }) => {
  // console.log(activities, "activities");
  // console.log(assignee, "assignee");
  const [selectedParticularActivity, setSelectedParticularActivity] =
    useState(null);
  const [ActivityPopupOpen, setActivityPopupOpen] = useState(false);

  const handleActivityClick = (activity) => {
    setSelectedParticularActivity(activity);
    setActivityPopupOpen(true);
  };

  const handleActivityPopupClose = () => {
    setActivityPopupOpen(false);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={popupOpen}
        onClose={onClose}
        closeAfterTransition
        // style={{ maxHeight: "400px", overflow: "scroll" }}
      >
        <Fade in={popupOpen}>
          <Box className="modal-wrapper">
            <Box
              variant="div"
              component="div"
              className="title"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
              }}
            >
              <Typography variant="h6">Activity Details</Typography>

              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn custom-icon-btn"
                  disableRipple
                  endIcon={<RiCloseLine />}
                  onClick={onClose}
                ></Button>
              </Box>
            </Box>
            <Box
              variant="div"
              component="div"
              className="body"
              style={{ maxHeight: "400px", overflow: "auto" }}
            >
              {activities.map((event, index) => (
                <Grid
                  item
                  xs={12}
                  key={index}
                  // style={{ display: "flex", gap: "5px", alignItems: "center" }}
                  style={{
                    padding: "6px",
                    marginBottom: activities?.length > 1 ? "10px" : "0px",
                    // marginTop: "10px",
                    // position: "relative",
                    backgroundColor:
                      event.activity === "call"
                        ? "var(--light-green)"
                        : "var(--delete-btn-hover)",
                    display: "flex",
                    gap: "10px",
                    borderRadius: "10px",
                    cursor: "pointer",
                    alignItems: "center",
                  }}
                  onClick={() => handleActivityClick(event)}
                >
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    style={{ display: "flex", gap: "10px" }}
                  >
                    {event.activity === "call" ? (
                      <RiPhoneFill />
                    ) : (
                      <MdAccessAlarms />
                    )}
                    <Typography
                      className="report-text-black"
                      style={{ width: "50px" }}
                    >
                      {moment(event.time, "HH:mm:ss").format("HH:mm")}
                    </Typography>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{
                      fontSize: "1rem",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      maxWidth: "350px",
                    }}
                    className="report-text-black"
                  >
                    {event.comments === "" || event.comments === null
                      ? "No comments"
                      : event.comments}
                  </Typography>
                  {event?.project_id ? (
                    <Tooltip
                      title={
                        <div
                          style={{
                            color: "white",
                            fontSize: "13px",
                            zIndex: "10000002",
                          }}
                        >
                          Project
                        </div>
                      }
                      arrow
                      placement="bottom-start"
                    >
                      <span>
                        <Button
                          variant="outlined"
                          startIcon={<RiStackLine />}
                          className="action-btn"
                        ></Button>
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={
                        <div
                          style={{
                            color: "white",
                            fontSize: "13px",
                            zIndex: "10000002",
                          }}
                        >
                          Lead
                        </div>
                      }
                      arrow
                      placement="bottom-start"
                    >
                      <span>
                        <Button
                          variant="outlined"
                          startIcon={<FaArrowTrendUp />}
                          className="action-btn"
                        ></Button>
                      </span>
                    </Tooltip>
                  )}
                </Grid>
              ))}
            </Box>
          </Box>
        </Fade>
      </Modal>
      {ActivityPopupOpen && (
        <ActivityPopupComponent
          selectedParticularActivity={selectedParticularActivity}
          handleActivityPopupClose={handleActivityPopupClose}
          ActivityPopupOpen={ActivityPopupOpen}
          assignee={assignee}
        />
      )}
    </>
  );
};

export default PopupComponent;

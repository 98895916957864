import { callApi } from "../../components/common/commonfunctionforapi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const fetchProjectsById = createAsyncThunk(
  "projects/fetchProjectsById",
  async ({ projectId }, { rejectWithValue }) => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/projects/${projectId}`,
      });
      // console.log(response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  projects: null,
  status: "idle",
  error: null,
};
const projectByIdSlice = createSlice({
  name: "projectsbyId",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectsById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProjectsById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.projects = action.payload;
      })
      .addMatcher(
        // Handle both rejected and fulfilled states with the same action
        (action) => action.type.startsWith("projects/fetchprojectsById/"),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            // Handle the rejected state with the defined value
            state.status = "failed";
            state.error = action.payload.message;
          }
        }
      );
  },
});

export default projectByIdSlice.reducer;

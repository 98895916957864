import React, { useEffect, useState } from 'react';
import {
  RiHome5Line,
  RiLogoutBoxLine,
  RiStackLine,
  RiGroupLine,
  RiUserFollowLine,
  RiSettings2Line,
  RiAuctionLine,
  RiFileList3Line,
  RiMenu3Fill,
} from 'react-icons/ri';
import { CgFileDocument } from 'react-icons/cg';
import { FaRegFileLines } from 'react-icons/fa6';
import {
  Backdrop,
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { FaArrowTrendUp } from 'react-icons/fa6';
import { useNavigate, useLocation } from 'react-router-dom';
import SignoutModal from '../common/signoutmodal';
import WithPermission from '../common/withPermission';
import logo from '../../assets/images/pms-logo.svg';

import {
  viewClients,
  viewDashboard,
  viewLeads,
  viewProjects,
  viewUsers,
  viewBids,
  viewReports,
  viewSettings,
  viewCVBuider,
} from '../../utils/permissions';
import { useDispatch, useSelector } from 'react-redux';
import { handleSidebar } from '../../store/slices/drawerHandlerSlice';

const MenuOptions = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    permission: viewDashboard,
    icon: <RiHome5Line />,
  },
  {
    label: 'Bids',
    path: '/bids',
    permission: viewBids,
    icon: <RiAuctionLine />,
  },
  {
    label: 'Leads',
    path: '/leads',
    permission: viewLeads,
    icon: <FaArrowTrendUp size={22} />,
  },
  {
    label: 'Projects',
    path: '/projects',
    permission: viewProjects,
    icon: <RiStackLine />,
  },

  {
    label: 'Clients',
    path: '/clients',
    permission: viewClients,
    icon: <RiUserFollowLine />,
  },
  {
    label: 'CV Builder',
    path: '/cv-builder',
    permission: viewCVBuider,
    icon: <CgFileDocument style={{ fill: 'currentColor' }} />,
  },
  {
    label: 'Reports',
    path: '/reports',
    permission: viewReports,
    icon: <RiFileList3Line />,
  },

  {
    label: 'Users',
    path: '/users',
    permission: viewUsers,
    icon: <RiGroupLine />,
  },

  {
    label: 'Settings',
    path: '/settings',
    permission: viewSettings,
    icon: <RiSettings2Line />,
  },
];
const SideMenu = ({
  openSideMenu,
  setOpenSideMenu,
  isMobile,
  handleDrawerClose,
  isAdmin,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const isMenuActive = (itemPath) => {
    return location.pathname.includes(itemPath);
  };
  const sidebarState = useSelector((state) => state.sidebar.sidebarStatus);

  useEffect(() => {
    if (isMobile && sidebarState) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [sidebarState, isMobile]);
  return (
    <>
      <Drawer
        anchor='left'
        className={`sidebar-wrapper ${
          sidebarState ? 'sidebar-menu-open' : 'sidebar-menu-closed'
        }`}
        variant='persistent'
        ModalProps={{
          keepMounted: true, // Better performance on mobile.
          disableEnforceFocus: true, // Allow interaction outside drawer.
        }}
        open={sidebarState}
      >
        <Box className='list-wrap' sx={{ border: '0px' }}>
          <Box className='logo-box' sx={{ display: 'flex' }}>
            <img
              src={logo}
              alt='logo-main'
              loading='lazy'
              className='large-logo'
              style={{ border: '0px solid white' }}
              onClick={() => navigate('/dashboard')}
            />
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={() => dispatch(handleSidebar(false))}
              // onClick={() => handleDrawerClose()}
              className='toggle-btn'
              sx={{ border: '0px solid grey' }}
            >
              <RiMenu3Fill className='open-menu' fill='white' />
            </IconButton>
          </Box>
          <Box className='Menu-list-wrapper-sidebar'>
            <List className='sidebar first-menu-list'>
              {MenuOptions.map((item, index) => {
                return (
                  <WithPermission
                    key={`${item}-${index}`}
                    permission={item.permission}
                    element={
                      <ListItem
                        disablePadding
                        onClick={() => {
                          if (isMobile) {
                            dispatch(handleSidebar(false));
                            setTimeout(() => {
                              navigate(item.path);
                            }, 400); // Adjust the delay time according to your transition duration
                          } else {
                            navigate(item.path);
                          }
                        }}
                        className={
                          location.pathname === item.path ||
                          isMenuActive(item.path) ||
                          (location.pathname.includes('setting') &&
                            item.path.includes('setting')) ||
                          (location.pathname.includes('report') &&
                            item.path.includes('report'))
                            ? 'activemenu'
                            : ''
                        }
                      >
                        <ListItemButton>
                          <ListItemIcon
                            className='icon-wrap'
                            title={item.label}
                          >
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={item.label}
                            className='menu-text-wrap'
                          />
                        </ListItemButton>
                      </ListItem>
                    }
                  />
                );
              })}
            </List>
            <List className='sidebar last-menu-list'>
              <ListItem>
                <ListItemButton onClick={handleOpen}>
                  <ListItemIcon className='icon-wrap' title='Sign Out'>
                    <RiLogoutBoxLine />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Sign Out'}
                    className='menu-text-wrap'
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>
      {isMobile && (
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}
          open={sidebarState}
          onClick={handleDrawerClose}
        />
      )}
      <SignoutModal open={open} handleClose={handleClose} />
    </>
  );
};

export default SideMenu;

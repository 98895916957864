import { Box, Fade, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { successToast } from "../../responses/successToast";
import { fetchProjects } from "../../store/slices/projectSlice";
import { useDispatch } from "react-redux";
import { callApi } from "./commonfunctionforapi";
import { setFeedback } from "../../store/slices/feedbackSlice";
import ExportLoaderModal from "./exportLoaderModal";
import { fetchFilters } from "../../store/slices/filterSlice";
import { fetchFiltersById } from "../../store/slices/filterByIdSlice";

const ExportToPdf = ({
  exportCancel,
  setExportCancel,
  exportProjectId,
  exportProjectName,
  status,
  filterByIdApplied,
  applyFilterId,
  filterApplied,
  searchText,
  selectedKeywords,
  selectedTechnologies,
  limit,
}) => {
  const dispatch = useDispatch();
  const [exporting, setExporting] = useState(false);
  const projectId = exportProjectId;
  const submitHandler = async () => {
    setExporting(true);
    try {
      const response = await callApi({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/projects/${projectId}/export-pdf`,
        responseType: "blob",
      });

      const data = response;
      // console.log(response, "export pfd slice");
      const blob = new Blob([data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      downloadFileFromS3Url(blobUrl);
      if (status !== 0) {
        if (filterApplied) {
          dispatch(
            fetchFilters({
              text: searchText,
              keyword: Array.isArray(selectedKeywords)
                ? selectedKeywords.map((option) => option.label).join(",")
                : "",
              technologies: Array.isArray(selectedTechnologies)
                ? selectedTechnologies.map((option) => option.label).join(",")
                : "",
              status: status,
            })
          );
        } else if (filterByIdApplied) {
          dispatch(
            fetchFiltersById({
              filterId: applyFilterId,
              status: status,
            })
          );
        } else {
          dispatch(
            fetchProjects({
              status: status,
              page: "",
              limit: limit,
            })
          );
        }
      }
      setExportCancel(false);
      successToast("Pdf exported successfully!");
      setExporting(false);
      return { success: true, blobUrl };
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.message,
          code: error.response.status_code,
        })
      );
      setExporting(false);
      setExportCancel(false);
      return { success: false, error: error.response.message };
    }
  };

  const downloadFileFromS3Url = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${exportProjectName}.pdf`);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={exportCancel}
        // onClose={ExportToPdfModalClose}
        closeAfterTransition
      >
        <Fade in={exportCancel}>
          <Box className="modal-wrapper">
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3">
                Do you want to export this project as pdf?
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body"></Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={submitHandler}
                >
                  Export
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => setExportCancel(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <ExportLoaderModal exporting={exporting} />
    </>
  );
};

export default ExportToPdf;

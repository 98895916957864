import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";
// import { successToast } from "../../responses/successToast";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const createProjectSlice = createSlice({
  name: "createProject",
  initialState,
  reducers: {
    createProjectStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createProjectSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    createProjectFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  createProjectStart,
  createProjectSuccess,
  createProjectFailure,
} = createProjectSlice.actions;

export default createProjectSlice.reducer;

export const createProject =
  ({ formData, client_type }) =>
  async (dispatch) => {
    try {
      dispatch(createProjectStart());

      const response = await callApi({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/projects`,
        data: formData,
        params: {
          client_type: client_type,
        },
      });

      const data = response.data;
      // console.log(response.data, "lead slice");
      dispatch(createProjectSuccess());
      // successToast("Lead creatde successfully!");
      return { success: true, message: data.message };
    } catch (error) {
      dispatch(createProjectFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return { success: false, error: error.response.data.message };
    }
  };

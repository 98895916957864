import React, { useEffect } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';

import Select from 'react-select';

import '../../styles/cv-builder.scss';
import { RiArrowLeftLine } from 'react-icons/ri';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ReactQuill from 'react-quill';
import { fetchProjects } from '../../store/slices/projectSlice';

import { fetchTemplates } from '../../store/slices/cv-builder/templateSlice';
import { createTemplateCV } from '../../store/slices/cv-builder/createTemplateCVSlice';

import { CvHtmlFormat } from './cvHtmlFormat';
import { Loader } from 'rsuite';

const CvCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const templates = useSelector((state) => state.templates.templates);

  useEffect(() => {
    setLoading(true);

    dispatch(fetchTemplates({}));

    setLoading(false);
  }, [dispatch]);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [employeeName, setEmployeeName] = useState();
  const [cvTitle, setCvTitle] = useState();
  const [education, setEducation] = useState();

  const [cvTitleError, setCvTitleError] = useState();
  const [employeeNameError, setEmployeeNameError] = useState();
  const [educationError, setEducationError] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const templateOptions = templates?.map((temp) => ({
    value: temp.id,
    label: temp.template_name,
  }));

  const validateForm = () => {
    let isValid = true;
    if (!cvTitle || !cvTitle.trim()) {
      setCvTitleError('CV Title is required.');

      isValid = false;
    } else {
      setCvTitleError('');
    }
    if (!employeeName || !employeeName.trim()) {
      setEmployeeNameError('Employee Name is required.');

      isValid = false;
    } else {
      setEmployeeNameError('');
    }
    if (!education || !education.trim() || education === '<p><br></p>') {
      setEducationError('Education is required.');

      isValid = false;
    } else {
      setEducationError('');
    }

    return isValid;
  };
  useEffect(() => {
    if (templateOptions.length > 0)
      setSelectedTemplate(templateOptions?.find((temp) => +temp.value === +id));
  }, [templates]);

  const submitHandler = async () => {
    if (!validateForm()) {
      return;
    }
    setIsButtonDisabled(true);
    const templateData = templates.find(
      (template) => template.id === selectedTemplate?.value
    );

    const htmlContent = CvHtmlFormat(
      templateData,
      employeeName,
      cvTitle,
      education
    );

    const cvData = {
      employee_name: employeeName,
      cv_title: cvTitle,
      education,
      is_user_cv: 'true',
      html_template: htmlContent,
      template_id: selectedTemplate?.value,
      skill_and_abilities: templateData?.skill_and_abilities,
      template_name: templateData?.template_name,
      summary: templateData?.summary,
      projects: templateData?.cv_projects,
    };

    try {
      const response = await dispatch(createTemplateCV(cvData));

      if (response.success) {
        navigate(`/cv-builder/template/${id}/cv/${response?.data?.id}/edit`, {
          state: {
            template: state.template,
          },
        });
      } else {
        console.error('Error creating cv:', response.error);
      }
    } catch (error) {
      console.error('Error creating cv:', error.message);
    } finally {
      setIsButtonDisabled(false);
    }
  };
  useEffect(() => {
    dispatch(fetchProjects({ page: '', status: '', limit: '', query: '' }));
  }, [dispatch]);
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  };

  if (loading) return <Loader />;
  return (
    <>
      <Box className='content-header '>
        <Typography variant='h2' component='h2' className='title-with-btn'>
          <Button
            variant='outlined'
            className='action-btn back-btn'
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() =>
              navigate('/cv-builder', {
                state: {
                  template: state.template,
                },
              })
            }
          ></Button>
          Create CV
        </Typography>
        <Box variant='div' component='div' className='content-header-right'>
          <Typography variant='h3'> Template:</Typography>
          <Select
            name='colors'
            className='select-field'
            options={templateOptions}
            value={selectedTemplate}
            onChange={(updated) => {
              setSelectedTemplate(updated);
            }}
            classNamePrefix='select'
            placeholder='Search Template'
            theme={(theme) => ({
              ...theme,
              borderRadius: 10,
              colors: {
                ...theme.colors,
                primary25: 'var(--secondary-text-color)',
                primary: '#8CC714',
              },
            })}
          />
        </Box>
      </Box>

      <Box className='content-wrapper cv'>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant='h3' component='h3' className='card-title' />
            <Box className='card-wrapper'>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant='h3'
                    component='h3'
                    className='card-title'
                  >
                    CV Details
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant='body1' component='label'>
                    Employee Name
                    <Typography
                      variant='body1'
                      component='span'
                      className='asterisk'
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    className='input-field'
                    fullWidth
                    id='outlined-basic'
                    label=''
                    variant='outlined'
                    size='small'
                    placeholder='Enter Employee Name'
                    value={employeeName}
                    onChange={(e) => {
                      setEmployeeName(e.target.value);
                      setEmployeeNameError('');
                    }}
                  />
                  {employeeNameError && (
                    <Typography variant='body1' component='p' className='error'>
                      {employeeNameError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant='body1' component='label'>
                    CV Title
                    <Typography
                      variant='body1'
                      component='span'
                      className='asterisk'
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    className='input-field'
                    fullWidth
                    id='outlined-basic'
                    label=''
                    variant='outlined'
                    size='small'
                    placeholder='e.g. FullStack Developer'
                    value={cvTitle}
                    onChange={(e) => {
                      setCvTitle(e.target.value);
                      setCvTitleError('');
                    }}
                  />
                  {cvTitleError && (
                    <Typography variant='body1' component='p' className='error'>
                      {cvTitleError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={6} sm={12} xs={12}>
                  <Typography variant='body1' component='label'>
                    Education
                    <Typography
                      variant='body1'
                      component='span'
                      className='asterisk'
                    >
                      *
                    </Typography>
                  </Typography>
                  <Box className='ck-editor-wrapper'>
                    <ReactQuill
                      value={education}
                      onChange={(e) => {
                        setEducation(e);
                        setEducationError('');
                      }}
                      modules={modules}
                      placeholder={`e.g.
(Gujarat Technical University - GTU), Surat, Gujarat.`}
                    />
                    {educationError && (
                      <Typography
                        variant='body1'
                        component='p'
                        className='error'
                      >
                        {educationError}
                      </Typography>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className='footer-wrapper'>
        <Button
          variant='contained'
          size='large'
          className='primary-btn btn w-120'
          disableRipple
          onClick={submitHandler}
          disabled={isButtonDisabled}
        >
          Next
        </Button>

        <Button
          variant='contained'
          size='large'
          className='secondary-btn btn w-120'
          disableRipple
          onClick={() =>
            navigate('/cv-builder', {
              state: {
                template: state?.template || null,
              },
            })
          }
        >
          Cancel
        </Button>
      </Box>
    </>
  );
};

export default CvCreate;

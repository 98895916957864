import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Grid,
  Typography,
  Divider,
  Skeleton,
  Checkbox,
  Pagination,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { RiAddCircleLine, RiDeleteBinLine, RiPencilLine } from "react-icons/ri";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";

import { useDispatch, useSelector } from "react-redux";
import { fetchClientsById } from "../../store/slices/clientbyIdSlice";
import { fetchContacts } from "../../store/slices/contactSlice";

import { successToast } from "../../responses/successToast";
import "../../styles/client.scss";
import ContactDeleteModal from "../../components/common/deleteContactModal";
import ContactDrawer from "../../components/common/contactDrawer";
import EditcontactDrawer from "../../components/common/editContactDrawer";
import { editContactByIdAdmin } from "../../store/slices/editContactByIdSlice";
import { fetchContactById } from "../../store/slices/contactByIdSlice";
import { updateClients, fullClients } from "../../utils/permissions";
import WithPermission from "../../components/common/withPermission";
import { maskEmail, maskPhoneNumber } from "../../utils/masking";
import { selectIsAdmin } from "../../constants/selectors";

const ClientView = () => {
  // console.log(getUserRole());
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      page: 1,
      status: 0,
      rowsPerPage: 10,
    };
  }
  const { clientId } = useParams();
  const getUserRole = useSelector(selectIsAdmin);
  const clients = useSelector((state) => state.clientsById.clients?.[0]);
  const contacts = useSelector((state) => state.contacts.contacts);

  const [loading, setLoading] = useState(true);
  // drawer for add contact
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const toatlClientsPerPage = 10;
  useEffect(() => {
    setLoading(true);

    dispatch(
      fetchContacts({ clientId, page: currentPage, limit: toatlClientsPerPage })
    );

    setLoading(false);
  }, [dispatch, clientId, currentPage]);
  //

  // drawer for update contact
  const [editContact /*setEditContact*/] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [contId, setContId] = useState(null);

  const handleEditOpen = (contactId) => {
    setContId(contactId);
    dispatch(fetchContactById({ contId: contactId }));
    setEditOpen(true);
  };
  // update primary contact
  const [selectedPrimaryContact, setSelectedPrimaryContact] = useState(null);

  const handleCheckboxClick = (contactId) => {
    // Find the contact with the given ID from the 'contacts.contacts' array
    const contact = contacts?.contacts.find((c) => c.id === contactId);

    if (contact && contact.is_primary) {
      return;
    }

    const newIsPrimaryValue = contact.is_primary ? 0 : 1;

    setSelectedPrimaryContact(contactId);

    const { contact_person_email, contact_person_name, contact_person_phone } =
      contact;

    const contactData = {
      contact_person_name: contact_person_name,
      contact_person_email: contact_person_email,
      contact_person_phone: contact_person_phone,
      is_primary: newIsPrimaryValue,
    };

    dispatch(editContactByIdAdmin(contactId, contactData))
      .then((response) => {
        if (response.success) {
          setEditOpen(false);
          successToast("Primary contact person updated successfully!");
          dispatch(
            fetchContacts({
              clientId: clientId,
              page: currentPage,
              limit: toatlClientsPerPage,
            })
          );
        } else {
          console.error("Error updating contact:", response.error);
        }
      })
      .catch((error) => {
        console.error("Error updating contact:", error.message);
      });
  };
  //

  // CLIENT DELETE
  const [contactDelete, setContactDelete] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const handleDeleteOpen = (clientId) => {
    setSelectedContactId(clientId);
    setContactDelete(true);
  };
  const handleDeleteClose = () => setContactDelete(false);
  // CLIENT DELETE COMPLETE

  function capitalizeWords(input) {
    return input?.replace(/\w+/g, function (word) {
      return word?.charAt(0).toUpperCase() + word.slice(1);
    });
  }

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const website = clients?.website;

  const handleLinkClick = (event) => {
    if (website && !website.startsWith("http")) {
      // If the URL doesn't start with "http" or "https," prevent default behavior
      event.preventDefault();
      window.open(`http://${website}`, "_blank");
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(fetchClientsById({ clientId }));
    setLoading(false);
  }, [dispatch, clientId]);

  return (
    <>
      {/* START :: LEAD VIEW HEADER */}
      <Box className="content-header view-clients-module-wrapper">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() =>
              navigate("/clients", {
                state: { page: state !== null ? state.page : 1 ,rowsPerPage:state.rowsPerPage},
              })
            }
          ></Button>
          View Client
        </Typography>
        <Box variant="div" component="div" className="content-header-right">
          <WithPermission
            permission={updateClients}
            element={
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn add-btn"
                disableRipple
                endIcon={<RiAddCircleLine />}
                onClick={() => setOpen(!open)}
              >
                Add Contact
              </Button>
            }
          />
          <WithPermission
            permission={updateClients}
            element={
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn only-icon-btn"
                endIcon={<RiPencilLine />}
                disableRipple
                onClick={() => navigate(`/clients/${clientId}/edit`)}
              ></Button>
            }
          />
        </Box>
      </Box>
      {/* END :: LEAD VIEW HEADER */}

      {/* START :: LEAD VIEW CONTENT */}
      <Box className="content-wrapper">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box className="card-wrapper">
              <Grid container rowSpacing={2} className="view-info">
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Name
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {capitalizeWords(
                        clients?.company_name === "" ||
                          clients?.company_name === null
                          ? "-"
                          : clients?.company_name
                      )}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Email
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {getUserRole
                        ? clients?.company_email === "" ||
                          clients?.company_email === null
                          ? "-"
                          : clients?.company_email
                        : clients?.company_email === "" ||
                          clients?.company_email === null
                        ? "-"
                        : maskEmail(clients?.company_email)}
                    </Typography>
                  )}
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Phone No.
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {getUserRole
                        ? clients?.company_phone === "" ||
                          clients?.company_phone === null
                          ? "-"
                          : clients?.company_phone
                        : clients?.company_phone === "" ||
                          clients?.company_phone === null
                        ? "-"
                        : maskPhoneNumber(clients?.company_phone)}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Website
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <a
                      href={website}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={handleLinkClick}
                    >
                      <Typography
                        variant="body1"
                        component="span"
                        className="value"
                        style={{ cursor: "pointer" }}
                      >
                        {website === "" || website === null ? "-" : website}
                      </Typography>
                    </a>
                  )}
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Address
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {clients?.address && clients?.address !== "" && (
                        <React.Fragment>
                          {clients.address}
                          <br />
                        </React.Fragment>
                      )}
                      {clients?.city && clients?.city !== "" && (
                        <React.Fragment>{clients.city},</React.Fragment>
                      )}
                      {clients?.state && clients?.state !== "" && (
                        <React.Fragment>{clients.state} </React.Fragment>
                      )}
                      {clients?.zip_code && clients?.zip_code !== "" && (
                        <React.Fragment>
                          {clients.zip_code}
                          <br />
                        </React.Fragment>
                      )}
                      {clients?.country && clients?.country !== "" && (
                        <React.Fragment>{clients.country}</React.Fragment>
                      )}
                      {!clients?.address &&
                        !clients?.city &&
                        !clients?.state &&
                        !clients?.country &&
                        !clients?.zip_code && (
                          <React.Fragment>-</React.Fragment>
                        )}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Skype Id
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {clients?.skype_id === "" || clients?.skype_id === null
                        ? "-"
                        : clients?.skype_id}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box className="card-wrapper p-0">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography
                  variant="h3"
                  component="h3"
                  className="card-title title-space"
                >
                  Contacts
                </Typography>
              </Grid>
              {loading ? (
                <TableContainer className="table-listing">
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="table"
                    className="listing-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Set as Primary</TableCell>
                        <TableCell align="left">Contact Person</TableCell>
                        <TableCell align="left">
                          Contact Person's Phone No.
                        </TableCell>
                        <TableCell align="left">
                          Contact Person's Email
                        </TableCell>
                        <TableCell align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.from({ length: 6 }).map((_, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Skeleton variant="text" width="100%" height={40} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width="100%" height={40} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width="100%" height={40} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width="100%" height={40} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width="100%" height={40} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width="100%" height={40} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : contacts?.contacts?.length > 0 ? (
                <TableContainer className="table-listing">
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="table"
                    className="listing-table"
                  >
                    <TableHead>
                      <TableRow>
                        <WithPermission
                          permission={updateClients}
                          element={
                            <TableCell align="left">Set as Primary</TableCell>
                          }
                        />
                        <TableCell align="left">Contact Person</TableCell>
                        <TableCell align="left">
                          Contact Person's Phone No.
                        </TableCell>
                        <TableCell align="left">
                          Contact Person's Email
                        </TableCell>
                        <WithPermission
                          permission={updateClients}
                          element={<TableCell align="left">Actions</TableCell>}
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contacts?.contacts?.map((contact) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={contact.id}
                        >
                          {" "}
                          <WithPermission
                            permission={updateClients}
                            element={
                              <TableCell align="left">
                                <Checkbox
                                  checked={
                                    contact.is_primary ||
                                    selectedPrimaryContact === contact.id
                                  }
                                  onClick={() =>
                                    handleCheckboxClick(contact?.id)
                                  }
                                />
                              </TableCell>
                            }
                          />
                          <TableCell align="left">
                            {contact?.contact_person_name === "" ||
                            contact?.contact_person_name === null
                              ? "-"
                              : capitalizeWords(contact?.contact_person_name)}
                          </TableCell>
                          <TableCell align="left">
                            {getUserRole
                              ? contact?.contact_person_phone === "" ||
                                contact?.contact_person_phone === null
                                ? "-"
                                : contact?.contact_person_phone
                              : contact?.contact_person_phone === "" ||
                                contact?.contact_person_phone === null
                              ? "-"
                              : maskPhoneNumber(contact?.contact_person_phone)}
                          </TableCell>
                          <TableCell align="left">
                            {getUserRole
                              ? contact?.contact_person_email === "" ||
                                contact?.contact_person_email === null
                                ? "-"
                                : contact?.contact_person_email
                              : contact?.contact_person_email === "" ||
                                contact?.contact_person_email === null
                              ? "-"
                              : maskEmail(contact?.contact_person_email)}
                          </TableCell>
                          <TableCell align="left">
                            <Box className="action-btn-wrap">
                              <WithPermission
                                permission={updateClients}
                                element={
                                  <Button
                                    variant="outlined"
                                    className="action-btn edit-btn"
                                    startIcon={<RiPencilLine />}
                                    title="Edit"
                                    onClick={() => {
                                      handleEditOpen(contact?.id);
                                    }}
                                    disableRipple
                                  ></Button>
                                }
                              />
                              <WithPermission
                                permission={fullClients}
                                element={
                                  <Button
                                    variant="outlined"
                                    className="action-btn dlt-btn"
                                    startIcon={<RiDeleteBinLine />}
                                    title="Delete"
                                    onClick={() =>
                                      handleDeleteOpen(contact?.id)
                                    }
                                    disableRipple
                                  ></Button>
                                }
                              />
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Box
                    variant="div"
                    component="div"
                    className="pagination-wrapper"
                  >
                    <Pagination
                      count={contacts?.totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      shape="rounded"
                    />
                  </Box>
                </TableContainer>
              ) : (
                <Typography variant="body1" component="div" className="no-msg">
                  No Contacts Found.
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/*  START :: LEAD FILTER PANEL */}
      <ContactDrawer
        open={open}
        setOpen={setOpen}
        editContact={editContact}
        currentPage={currentPage}
        clientId={clientId}
      />
      {/* END :: LEAD FILTER PANEL */}
      <EditcontactDrawer
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        handleEditOpen={handleEditOpen}
        contId={contId}
        currentPage={currentPage}
        clientId={clientId}
      />
      <ContactDeleteModal
        handleDeleteOpen={handleDeleteOpen}
        handleDeleteClose={handleDeleteClose}
        contactDelete={contactDelete}
        selectedContactId={selectedContactId}
        clientId={clientId}
        currentPage={currentPage}
      />
    </>
  );
};

export default ClientView;

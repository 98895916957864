import React, { useState, useEffect } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Header from "./header";
import SideMenu from "./sideMenu";
import Footer from "./footer";
import PatternBig from "../../assets/images/Big-Ellipse.png";
import PatternSmall from "../../assets/images/Small-Ellipse.png";
import { useDispatch, useSelector } from "react-redux";
import { handleSidebar } from "../../store/slices/drawerHandlerSlice";

const Layout = ({ children }) => {
  const sidebarState = useSelector((state) => state.sidebar.sidebarStatus);
  const dispatch = useDispatch()
  const [isAdmin /*setIsAdmin*/] = useState(true);
  const isMobile = useMediaQuery("(max-width:991.98px)");
  const [openSideMenu, setOpenSideMenu] = useState(true);
  const handleDrawerClose = () => {

    dispatch(handleSidebar(false));
    };
    useEffect(() => {
      const mediaQuery = window.matchMedia("(min-width: 992px)");
      
      const handleMediaQueryChange = (event) => {
        dispatch(handleSidebar( event.matches));
    };
    handleMediaQueryChange(mediaQuery);
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);
  return (
    <Box>
      <Header
        setOpenSideMenu={setOpenSideMenu}
        handleDrawerClose={handleDrawerClose}
        openSideMenu={openSideMenu}
        isAdmin={isAdmin}
        isMobile={isMobile}
      />
      {isAdmin ? (
        <SideMenu
          openSideMenu={openSideMenu}
          setOpenSideMenu={setOpenSideMenu}
          isMobile={isMobile}
          handleDrawerClose={handleDrawerClose}
          isAdmin={isAdmin}
        />
      ) : null}

      <main
        className={`main-content  ${
          sidebarState ? "open" : "full-width close"
        } `}
      >
        {children}
        <Box variant="div" component="div" className="pattern-big">
          <img src={PatternBig} alt="pattern" loading="lazy" />
        </Box>
        <Box variant="div" component="div" className="pattern-small">
          <img src={PatternSmall} alt="pattern" loading="lazy" />
        </Box>
      </main>
      <Footer />
    </Box>
  );
};

export default Layout;

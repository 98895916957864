import { callApi } from "../../components/common/commonfunctionforapi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const fetchProjects = createAsyncThunk(
  "projects/fetchProjects",
  async ({ page, status, limit, query }, { rejectWithValue }) => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/projects`,
        params: {
          page: page,
          status: status,
          limit: limit,
          query: query,
        },
      });
      // console.log(response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  projects: [],
  status: "idle",
  error: null,
};
const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.projects = action.payload;
      })

      .addMatcher(
        (action) => action.type.startsWith("projects/fetchProjects"),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            state.status = "failed";
            state.error = action.payload.message;
          }
        }
      );
  },
});
export const { updateProjects } = projectSlice.actions;
export default projectSlice.reducer;

import { Box, Button, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import ReleaseNote from "./Releasenote";
import { useDispatch, useSelector } from "react-redux";

import { fetchReleaseNotes } from "../../store/slices/releaseNotesSlice";

const ReleaseNotes = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const notes = useSelector((state) => state.releaseNotes.releasenotes);
  const totalCount = notes?.totalCount;
  // const [appList, setAppList] = useState([]);
  const [rows, setRows] = useState(5);
  const loadMoreHandler = () => {
    setRows((prev) => prev + 5);
  };

  const getLocalTimeZone = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZone;
  };

  const fetchReleaseNotesData = useCallback(async () => {
    try {
      setIsLoading(true);

      dispatch(
        fetchReleaseNotes({
          allNotes: 2,
          app_id: 2,
          page: 1,
          rows: rows,
          timezone: getLocalTimeZone(),
        })
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, rows]);

  useEffect(() => {
    fetchReleaseNotesData();
  }, [fetchReleaseNotesData]);

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2">
          Release Notes
        </Typography>
      </Box>

      {notes?.findAll?.map(({ id, version_name, date }, index) => {
        return (
          <ReleaseNote
            key={id + "_release_notes"}
            id={id}
            version={version_name}
            date={date}
          />
        );
      })}

      {!notes?.findAll?.length && (
        <Typography variant="p" component={"p"} textAlign={"center"}>
          No release notes
        </Typography>
      )}

      {rows > 5 && isLoading && (
        <Box className="load-more-section">
          <span class="loader"></span>
        </Box>
      )}

      {totalCount > rows && (
        <Box className="load-more-section">
          <Button
            variant="contained"
            size="medium"
            className="primary-btn btn add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={loadMoreHandler}
          >
            <Typography variant="body1" component="p">
              Load More
            </Typography>
          </Button>
        </Box>
      )}
    </>
  );
};

export default ReleaseNotes;

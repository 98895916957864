import {
  Box,
  Button,
  Grid,
  Typography,
  Stack,
  TextField,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  List,
  ListItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import {
  RiArrowLeftLine,
  RiDeleteBinLine,
  RiPencilLine,
  RiSearchLine,
  RiCloseCircleLine,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { createTechnology } from "../../store/slices/createTechnologySlice";
import { successToast } from "../../responses/successToast";
import { fetchTechnologies } from "../../store/slices/technologySlice";
import { fetchTechnologiesById } from "../../store/slices/technologybyIdSlice";
import { editTechnologyById } from "../../store/slices/editTechnologySlice";
import { deleteTechnologyById } from "../../store/slices/deleteTechnologyByIdSlice";
import TechnologyDeleteModal from "../../components/common/deleteTechnologyModal";
import TechnologySelectDeleteModal from "../../components/common/deleteselectTechnologyModal";
import Tooltip from "@mui/material/Tooltip";
import ViewProjectsBidsModal from "../../components/common/ViewProjectsBids";
import { color, width } from "@mui/system";
import { useLocation } from "react-router-dom";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// PROJECT STATUS OPTIONS
const sortByOptions = [
  { value: "A-Z", label: "A-Z" },
  { value: "Z-A", label: "Z-A" },
  { value: "Most Used", label: "Most Used" },
  { value: "Least Used", label: "Least Used" },
];

const TechnologySetting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [techName, setTechName] = useState("");
  const [techError, setTechError] = useState("");
  const technologies = useSelector((state) => state.technologies.technologies);
  const [sort, setSort] = useState("A-Z");
  const [searchText, setSearchText] = useState("");
  const technologiesById = useSelector(
    (state) => state.technologiesbyId.technologies
  );
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [individualCheckboxes, setIndividualCheckboxes] = useState({});
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [deleteKeyword, setdeleteKeyword] = useState(false);
  const [deleteselectKeyword, setDeleteSelectKeyword] = useState(false);
  const [deleteid, setdeleteId] = useState(null);
  const [viewModalOpen,setViewModalOpen]=useState(false);
  const [techStack,setTechStack]=useState(null);
  let [techId,setTechId]=useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleDeleteClose = () => setdeleteKeyword(false);
  const handleDeleteSelectClose = () => setDeleteSelectKeyword(false);
  const location = useLocation();
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSort("A-Z");
  };
  const handleSortOptions = (selectedOptions) => {
    setSort(selectedOptions);
  };

  function truncateLabel(label, maxLength) {
    if (label.length > maxLength) {
      return label.substring(0, maxLength) + "... ";
    }
    return label;
  }

  const usedKeywordsCount = technologies?.allTechnologies?.filter(
    (keyword) => keyword.usedCount === 0
  ).length;

  const handleEditTechnology = async (id) => {
    setIsEditing(true);
    setEditId(id);
    await dispatch(fetchTechnologiesById(id));
  };

  const handleDeleteSelected = async () => {
    const selectedKeywordIds = selectedKeywords.join(",");

    if (selectedKeywordIds) {
      await dispatch(deleteTechnologyById({ ids: selectedKeywordIds }))
        .then((response) => {
          if (response.payload.success) {
            const updatedSelectedKeywords = selectedKeywords.filter(
              (id) => !selectedKeywordIds.includes(id)
            );

            setSelectedKeywords(updatedSelectedKeywords);

            successToast("Technology deleted successfully!");
            dispatch(
              fetchTechnologies({
                query: searchText,
                order: sortValue,
                createdBy: technologyStatus,
              })
            );
            setDeleteSelectKeyword(false);
            const anySelected = updatedSelectedKeywords.length > 0;
            if (!anySelected && selectAllChecked) {
              setSelectAllChecked(false);
            }
          } else {
            console.error("Error deleting keyword:", response.error);
          }
        })
        .catch((error) => {
          console.error("Error deleting keyword:", error.message);
        });
    }
  };
  const handleDeleteOpen = (id) => {
    setdeleteId(id);
    setdeleteKeyword(true);
  };
  const handleSelectDeleteOpen = () => {
    setDeleteSelectKeyword(true);
  };
  const handleDeleteKeyword = async () => {
    await dispatch(deleteTechnologyById({ ids: deleteid }))
      .then((response) => {
        if (response.payload.success) {
          successToast("Technology deleted successfully!");
          dispatch(
            fetchTechnologies({
              query: searchText,
              order: sortValue,
              createdBy: technologyStatus,
            })
          );
          setdeleteKeyword(false);
        } else {
          console.error("Error creating user:", response.error);
        }
      })
      .catch((error) => {
        console.error("Error creating user:", error.message);
      });
  };

  const sortValue =
    sort === "A-Z"
      ? "A-Z"
      : sort === "Z-A"
      ? "Z-A"
      : sort === "Most Used"
      ? "mostUsed"
      : "leastUsed";
  const technologyStatus = value === 0 ? "" : "me";

  const tabLabel = (role, modules) => {
    return (
      <Box>
        <span className="user-name">{role}</span>
      </Box>
    );
  };
  const editsubmitHandler = async () => {
    if (isButtonDisabled) {
      return;
    }
    if (!techName) {
      setTechError("Please enter name of the technology");
      return;
    }
    setIsButtonDisabled(true);
    try {
      const response = await dispatch(
        editTechnologyById({ editId, technology_name: techName })
      );

      if (response.success) {
        successToast("Technology updated successfully!");
        dispatch(
          fetchTechnologies({
            createdBy: technologyStatus,
            order: sortValue,
            query: searchText,
          })
        );
        setTechName("");
        setIsEditing(false);
      } else {
        console.error("Error creating user:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      setIsButtonDisabled(false);
    }
  };
  const submitHandler = async () => {
    if (isButtonDisabled) {
      return;
    }
    if (!techName.trim()) {
      setTechError("Please enter name of the technology");
      return;
    }
    setIsButtonDisabled(true);
    try {
      const response = await dispatch(createTechnology(techName));

      if (response.success) {
        successToast("Technology created successfully!");
        dispatch(
          fetchTechnologies({
            createdBy: technologyStatus,
            order: sortValue,
            query: searchText,
          })
        );
        setTechName("");
      } else {
        console.error("Error creating user:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      setIsButtonDisabled(false);
    }
  };

  function KeywordCheckboxList() {
    return (
      <List>
        {technologies?.allTechnologies?.map((technology, index) => (
          <ListItem className="tab-checkbox-list">
            <Tooltip
              title={
                <div style={{ color: "white", fontSize: "13px" }}>
                  {technology?.name + " (" + technology?.usedCount + ")"}
                </div>
              }
              arrow
              placement="bottom-end"
            >
              <FormControlLabel
                control={<Checkbox disabled={technology?.usedCount > 0} />}
                checked={individualCheckboxes[technology.id]}
                
                onChange={() => {
                  const updatedCheckboxes = { ...individualCheckboxes };
                  updatedCheckboxes[technology.id] =
                    !individualCheckboxes[technology.id];
                  setIndividualCheckboxes(updatedCheckboxes);
                  if (updatedCheckboxes[technology.id]) {
                    setSelectedKeywords([...selectedKeywords, technology.id]);
                  } else {
                    setSelectedKeywords(
                      selectedKeywords.filter((id) => id !== technology.id)
                    );
                  }
                 
                  const allSelected = technologies?.allTechnologies?.every(
                    (k) => updatedCheckboxes[k.id]
                  );
                  setSelectAllChecked(allSelected);
                }}
                
         
          label={technology?.usedCount > 0?
            <span style={{color:"inherit",cursor:"pointer"}}  onClick={(e)=>{e.stopPropagation();setViewModalOpen(true);setTechStack(technology?.name);setTechId(technology?.id)}}>
              {truncateLabel(technology?.name, 15) +
           " (" +
           technology?.usedCount +
           ")"}
              </span>:
              <span style={{color:"inherit",cursor:"default"}}>{truncateLabel(technology?.name, 15) +
                " (" +
                technology?.usedCount +
                ")"}</span>
          
            
            
          }
                
                className="checkbox-style-heading"
              />
            </Tooltip>
            <Box className="checkbox-action">
              <Button
                variant="outlined"
                className="action-btn edit-btn"
                startIcon={<RiPencilLine />}
                onClick={() => handleEditTechnology(technology.id)}
                disableRipple
              ></Button>
              <Button
                variant="outlined"
                className="action-btn dlt-btn"
                startIcon={<RiDeleteBinLine />}
                disabled={technology?.usedCount > 0}
                onClick={() => handleDeleteOpen(technology.id)}
                disableRipple
              ></Button>
            </Box>
          </ListItem>
        ))}
      </List>
    );
  }
  useEffect(() => {
    if (isEditing) {
      setTechName(technologiesById?.technology_name);
    }
  }, [technologiesById, isEditing]);
  useEffect(() => {
    dispatch(
      fetchTechnologies({
        createdBy: technologyStatus,
        order: sortValue,
        query: searchText,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, technologyStatus, sort, searchText]);

  const clearSearchHandler = () => {
    setSearchText("");
  };

  return (
    <>

      <Box className="content-wrapper">
        <Grid container spacing={3} className="setting-page-wrapper">
          <Grid item lg={4} md={12} sm={12} xs={12} className="left-warpper">
              <Box className="content-header p-0 m-0">
                <Typography variant="h2" component="h2" className="title-with-btn">
                  <Button
                    variant="outlined"
                    className="action-btn back-btn"
                    startIcon={<RiArrowLeftLine />}
                    disableRipple
                    onClick={() => navigate("/settings")}
                  ></Button>
                  Technology Stacks
                </Typography>
              </Box>
              <Stack spacing={3} className="word-field-wrapper">
                <Box className="card-wrapper">
                  <Stack spacing={3}>
                    <Box>
                      <Typography variant="body1" component="label">
                        Technology
                        <Typography
                          variant="body1"
                          component="span"
                          className="asterisk"
                        >
                          *
                        </Typography>{" "}
                      </Typography>
                      <TextField
                        className="input-field"
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        placeholder="Enter Technology"
                        value={techName}
                        onChange={(event) => {
                          setTechName(event.target.value);
                          if (event.target.value) {
                            setTechError("");
                          }
                        }}
                      />
                      {techError && (
                        <Typography variant="body1" component="p" className="error">
                          {techError}
                        </Typography>
                      )}
                    </Box>
                    <Box className="form-btn-wrapper">
                      <Button
                        variant="contained"
                        size="large"
                        className="primary-btn btn w-120"
                        disableRipple
                        disabled={isButtonDisabled}
                        onClick={isEditing ? editsubmitHandler : submitHandler}
                      >
                        {isEditing ? "Update" : "Save"}
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        className="secondary-btn btn w-120"
                        onClick={() => {
                          setTechName("");
                          setIsEditing(false);
                        }}
                        disableRipple
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Stack>
                </Box>
                <Box className="card-wrapper setting-count-card-wrapper">
                  <Typography variant="h2" component="h2" className="count">
                    {technologies?.totalTechnologiesCount}
                  </Typography>
                  <Typography variant="h4" component="h4" className="title">
                    Total Technologies
                  </Typography>
                </Box>
                <Box className="card-wrapper setting-count-card-wrapper">
                  <Typography variant="h2" component="h2" className="count">
                    {technologies?.usedTechnologiesCount}
                  </Typography>
                  <Typography variant="h4" component="h4" className="title">
                    In Use
                  </Typography>
                </Box>
                <Box className="card-wrapper setting-count-card-wrapper">
                  <Typography variant="h2" component="h2" className="count">
                    {technologies?.unusedTechnologiesCount}
                  </Typography>
                  <Typography variant="h4" component="h4" className="title">
                    Not In Used
                  </Typography>
                </Box>
              </Stack>
          </Grid>
          <Grid item lg={8} md={12} sm={12} xs={12} className="right-warpper">
            <Box className="content-header p-0 m-0">
              <Box variant="div" component="div" className="content-header-right">
                <Box className="setting-sort-wrapper">
                  <Typography
                    variant="h2"
                    component="h2"
                    className="sort-by-title"
                  >
                    Sort By
                  </Typography>
                  <Select
                    name="colors"
                    options={sortByOptions}
                    value={
                      sortByOptions.find((option) => option.value === sort) ||
                      null
                    }
                    onChange={(selectedOptions) =>
                      handleSortOptions(selectedOptions.value)
                    }
                    className="muilt-select-field"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Box>
                <Box variant="div" component="div" className="search-bar">
                  <RiSearchLine />
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Search here"
                    onChange={(e) => setSearchText(e.target.value)}
                    value={searchText}
                  />
                  {searchText && (
                    <RiCloseCircleLine
                      className="cross-line"
                      onClick={clearSearchHandler}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            <Box className="select-all-wrapper mobile-view-checkbox">
                <FormControlLabel
                  control={<Checkbox />}
                  checked={selectAllChecked}
                  onChange={() => {
                    const updatedCheckboxes = { ...individualCheckboxes };

                    setSelectAllChecked(!selectAllChecked);

                    technologies?.allTechnologies?.forEach((keyword) => {
                      if (!keyword?.usedCount > 0) {
                        updatedCheckboxes[keyword.id] = !selectAllChecked;
                        if (!selectAllChecked) {
                          setSelectedKeywords((prevSelected) => [
                            ...prevSelected,
                            keyword.id,
                          ]);
                        } else {
                          setSelectedKeywords((prevSelected) =>
                            prevSelected.filter((id) => id !== keyword.id)
                          );
                        }
                      }
                    });

                    setIndividualCheckboxes(updatedCheckboxes);
                  }}
                  label={`Select All (${usedKeywordsCount})`}
                  className="checkbox-style-heading"
                />
                {selectedKeywords.length > 0 && (
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn w-120"
                    onClick={() => handleSelectDeleteOpen()}
                    disableRipple
                  >
                    Delete Selected
                  </Button>
                )}
            </Box>
            <Box className="content-layout m-0 right-listing-wrapper">
              <Box variant="div"component="div"className="tab-wrapper user-listing"  >
                <Box  className="tab-checkbox-wrapper" sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="tab-area"
                    
                  >
                    <Tab label={tabLabel("All")} {...a11yProps(0)} />
                    <Tab label={tabLabel("Created By Me")} {...a11yProps(1)} />
                  </Tabs>
                  <Box className="select-all-wrapper desktop-view-checkbox">
                      <FormControlLabel
                        control={<Checkbox />}
                        checked={selectAllChecked}
                        onChange={() => {
                          const updatedCheckboxes = { ...individualCheckboxes };

                          setSelectAllChecked(!selectAllChecked);

                          technologies?.allTechnologies?.forEach((keyword) => {
                            if (!keyword?.usedCount > 0) {
                              updatedCheckboxes[keyword.id] = !selectAllChecked;
                              if (!selectAllChecked) {
                                setSelectedKeywords((prevSelected) => [
                                  ...prevSelected,
                                  keyword.id,
                                ]);
                              } else {
                                setSelectedKeywords((prevSelected) =>
                                  prevSelected.filter((id) => id !== keyword.id)
                                );
                              }
                            }
                          });

                          setIndividualCheckboxes(updatedCheckboxes);
                        }}
                        label={`Select All (${usedKeywordsCount})`}
                        className="checkbox-style-heading"
                      />
                      {selectedKeywords.length > 0 && (
                        <Button
                          variant="contained"
                          size="large"
                          className="primary-btn btn w-120"
                          onClick={() => handleSelectDeleteOpen()}
                          disableRipple
                        >
                          Delete Selected
                        </Button>
                      )}
                  </Box>
                </Box>
                
                <CustomTabPanel
                  value={value}
                  index={0}
                  className="user-listing-tables"
                >
                  <Box className="checkbox-list-wrapper">
                    <KeywordCheckboxList count={100} />
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel
                  value={value}
                  index={1}
                  className="user-listing-tables"
                >
                  <Box className="checkbox-list-wrapper">
                    <KeywordCheckboxList count={10} />
                  </Box>
                </CustomTabPanel>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/*  START :: KEYWORDS SETTING PAGE */}

      <TechnologyDeleteModal
        handleDeleteOpen={handleDeleteOpen}
        handleDeleteClose={handleDeleteClose}
        deleteKeyword={deleteKeyword}
        setdeleteKeyword={setdeleteKeyword}
        deleteid={deleteid}
        handleDeleteKeyword={handleDeleteKeyword}
      />
      <TechnologySelectDeleteModal
        handleDeleteSelectClose={handleDeleteSelectClose}
        deleteselectKeyword={deleteselectKeyword}
        setDeleteSelectKeyword={setDeleteSelectKeyword}
        deleteid={deleteid}
        handleDeleteSelected={handleDeleteSelected}
      />
      <ViewProjectsBidsModal open={viewModalOpen} path={location.pathname} techStack={techStack} techId={techId} setViewModalOpen={setViewModalOpen}  componentFor="technology"></ViewProjectsBidsModal>
      {/* END :: KEYWORDS SETTING PAGE */}
    </>
  );
};

export default TechnologySetting;

import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  project: null,
  loading: false,
  error: null,
};

const editContactByIdSlice = createSlice({
  name: "editContactById",
  initialState,
  reducers: {
    editContactByIdStart(state) {
      state.loading = true;
      state.error = null;
    },
    editContactByIdSuccess(state, action) {
      state.loading = false;
      state.project = action.payload;
    },
    editContactByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  editContactByIdStart,
  editContactByIdSuccess,
  editContactByIdFailure,
} = editContactByIdSlice.actions;
export default editContactByIdSlice.reducer;

export const editContactByIdAdmin =
  (contactId, contactData) => async (dispatch) => {
    try {
      dispatch(editContactByIdStart());
      const response = await callApi({
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/contact/${contactId}`,
        data: contactData,
      });

      const contact = response;
      const data = response.data;
      dispatch(editContactByIdSuccess(contact));
      return { success: true, message: data.message };
    } catch (error) {
      dispatch(editContactByIdFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return { success: false, error: error.response.data.message };
    }
  };

import React from "react";
import { Box, Fade, Grid, Modal, Typography, Divider } from "@mui/material";
import Button from "@mui/material/Button";
// import moment from "moment";
import { RiCloseLine, RiPhoneFill } from "react-icons/ri";
import { MdAccessAlarms } from "react-icons/md";

function capitalizeFirstLetter(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
}

const ActivityPopupComponent = ({
  selectedParticularActivity,
  handleActivityPopupClose,
  ActivityPopupOpen,
  assignee,
}) => {
  // console.log(selectedParticularActivity, "selectedparticular activity");
  // console.log(assignee, "assignee");
  const projectClientsArray = Array.isArray(
    selectedParticularActivity?.project?.clients
  )
    ? selectedParticularActivity?.project?.clients
    : [];
  const projectCompName = projectClientsArray.map(
    (name) => name?.company_name
  )[0];
  // console.log(projectCompName, "project comp name");
  const projectContactName =
    selectedParticularActivity?.project?.client_contacts?.contact_person_name;
  // console.log(projectContactName, "projectContactName")

  // console.log(projectCompName, "project comp name");
  // const leadClientsArray = Array.isArray(selectedParticularActivity?.lead?.clients) ? selectedParticularActivity?.lead?.clients : [];
  // const leadExistingCompName = selectedParticularActivity?.lead?.clients?.company_name;
  // console.log(leadExistingCompName, "leadExistingCompName");

  const leadNewCompName = selectedParticularActivity?.lead?.company_name;
  // console.log(leadNewCompName, "leadNewCompName")

  // const leadExisitngContactName = selectedParticularActivity?.lead?.client_contacts?.contact_person_name;
  // console.log(leadExisitngContactName, "leadExisitngContactName")

  const leadNewContactName = selectedParticularActivity?.lead?.contact_person;
  // console.log(leadNewContactName, "leadNewContactName")

  // const userfromselectedactivity =
  //   selectedParticularActivity?.lead_assignee_users;
  // console.log(userfromselectedactivity, "userfromselectedactivity");

  // const idsArray1 = assignee.map((item) => ({
  //   id: item.id,
  //   firstName: item.first_name,
  //   lastName: item.last_name,
  // }));
  // console.log(idsArray1, "idsArray1");

  // const userIDsArray2 = userfromselectedactivity.map((item) => ({
  //   id: item.user_id,
  //   firstName: item.user.first_name,
  //   lastName: item.user.last_name,
  // }));
  // console.log(userIDsArray2, "userIDsArray2");

  // // Get corresponding first_name and last_name from the first array
  // const uniqueUsers = idsArray1.filter((item) => userIDsArray2.includes(item));
  // const notuniqueUsersAssignee = idsArray1.filter(
  //   (item) => !userIDsArray2.includes(item)
  // );

  // const notuniqueUsersSelectedactivity = userIDsArray2.filter(
  //   (item) => !idsArray1.includes(item)
  // );

  // console.log(
  //   "Unique users:",
  //   uniqueUsers,
  //   "non unique",
  //   notuniqueUsersAssignee,
  //   notuniqueUsersSelectedactivity
  // );

  // const usersFromDetails = assignee.map((user) => ({
  //   id: user?.id,
  //   first_name: user?.first_name,
  //   last_name: user?.last_name,
  // }));

  const usersFromActivity =
    selectedParticularActivity?.lead_assignee_users?.map((assignee) => ({
      id: assignee?.user_id,
      first_name: assignee?.user?.first_name,
      last_name: assignee?.user?.last_name,
    })) || [];

  // Combine and filter unique users
  // const allUsers = [...usersFromDetails, ...usersFromActivity];
  // const uniqueUsers =
  //   Array.from(new Set(allUsers?.map((user) => user?.id)))?.map((id) => {
  //     return allUsers?.find((user) => user?.id === id);
  //   }) || [];

  // console.log("Unique users:", uniqueUsers);

  const uniqueUsersNames =
    usersFromActivity?.map((user) => `${user.first_name} ${user.last_name}`) ||
    [];

  // console.log("Unique users names:", uniqueUsersNames);

  const commaSeparatedNames = uniqueUsersNames?.join(", ") || "";

  // console.log("Unique users names:", commaSeparatedNames);

  const userFromProjectActivity =
    selectedParticularActivity?.project_assignee_users?.map((assignee) => ({
      id: assignee?.user_id,
      first_name: assignee?.user?.first_name,
      last_name: assignee?.user?.last_name,
    })) || [];

  // const projectAllUsers = [...usersFromDetails, ...userFromProjectActivity];

  // const uniqueProjectUsers = Array?.from(
  //   new Set(projectAllUsers?.map((user) => user?.id))
  // ).map((id) => {
  //   return projectAllUsers?.find((user) => user?.id === id);
  // });

  const uniqueProjectUsersNames = userFromProjectActivity?.map(
    (user) => `${user.first_name} ${user.last_name}`
  );

  // console.log("Unique users names:", uniqueProjectUsersNames);

  const commaSeparatedProjectUserNames = uniqueProjectUsersNames?.join(", ");

  // console.log("Unique users names:", commaSeparatedProjectUserNames);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={ActivityPopupOpen}
      onClose={handleActivityPopupClose}
      closeAfterTransition
      style={{ zIndex: 10000001 }}
    >
      <Fade in={ActivityPopupOpen}>
        <Box
          className="modal-wrapper"
          style={{
            padding: "25px",
          }}
        >
          <Box
            variant="div"
            component="div"
            // className="title"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography variant="h6">
              {selectedParticularActivity?.activity === "call" ? (
                <RiPhoneFill
                  style={{
                    fontSize: "30px",
                    top: "6px",
                    position: "relative",
                  }}
                />
              ) : (
                <MdAccessAlarms
                  style={{
                    fontSize: "30px",
                    top: "8px",
                    position: "relative",
                  }}
                />
              )}
              &nbsp;
              {capitalizeFirstLetter(selectedParticularActivity?.activity)}{" "}
              Detail
            </Typography>

            <Box variant="div" component="div" className="btn-group">
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn custom-icon-btn"
                disableRipple
                endIcon={<RiCloseLine />}
                onClick={handleActivityPopupClose}
              ></Button>
            </Box>
          </Box>
          <Box variant="div" component="div" className="body">
            <Grid container spacing={2} mt={2}>
              {selectedParticularActivity && (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{ maxHeight: "400px", overflow: "auto" }}
                  >
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography variant="body1" component="label">
                        Project
                      </Typography>
                      <a
                        href={
                          selectedParticularActivity?.project_id
                            ? `/projects/${selectedParticularActivity?.project_id}/view`
                            : `/leads/${selectedParticularActivity?.lead_id}/view`
                        }
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Typography
                          variant="body1"
                          component="span"
                          className="value blue-text"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {selectedParticularActivity?.project?.project_name ===
                            "" ||
                          selectedParticularActivity?.project?.project_name ===
                            null
                            ? "-"
                            : selectedParticularActivity?.project?.project_name}
                          {selectedParticularActivity?.lead?.project_name ===
                            "" ||
                          selectedParticularActivity?.lead?.project_name ===
                            null
                            ? "-"
                            : selectedParticularActivity?.lead?.project_name}
                        </Typography>
                      </a>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                      <Divider className="divider" />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                      <Typography variant="body1" component="label">
                        Assignee
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="value"
                      >
                        {/* {commaSeparatedNames} */}
                        {commaSeparatedProjectUserNames.length > 0 ||
                        commaSeparatedNames.length > 0
                          ? selectedParticularActivity?.project_assignee_users
                            ? commaSeparatedProjectUserNames ||
                              "No assignee found"
                            : commaSeparatedNames || "No assignee found"
                          : "No assignee found"}
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                      <Divider className="divider" />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      mt={2}
                      style={{ display: "flex" }}
                    >
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="body1" component="label">
                          Company Name
                        </Typography>

                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                          style={{
                            wordBreak: "break-word",
                          }}
                        >
                          {projectCompName === "" || projectCompName === null
                            ? "-"
                            : projectCompName}
                          {/* {leadExistingCompName ===
                            "" ||
                            leadExistingCompName ===
                            null
                            ? "-"
                            : leadExistingCompName} */}
                          {leadNewContactName === "" || leadNewCompName === null
                            ? "-"
                            : leadNewCompName}
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="body1" component="label">
                          Contact Person
                        </Typography>

                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                          style={{
                            wordBreak: "break-word",
                          }}
                        >
                          {projectContactName === "" ||
                          projectContactName === null
                            ? "-"
                            : projectContactName}
                          {/* {leadExisitngContactName ===
                            "" ||
                            leadExisitngContactName ===
                            null
                            ? "-"
                            : leadExisitngContactName} */}
                          {leadNewContactName === "" ||
                          leadNewContactName === null
                            ? "-"
                            : leadNewContactName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                      <Divider className="divider" />
                    </Grid>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      mt={2}
                      style={{ display: "flex" }}
                    >
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="body1" component="label">
                          Date
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                        >
                          {selectedParticularActivity?.date === "" ||
                          selectedParticularActivity?.date === null
                            ? "-"
                            : formatDate(selectedParticularActivity?.date)}
                        </Typography>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Typography variant="body1" component="label">
                          Time
                        </Typography>
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                        >
                          {selectedParticularActivity?.time === "" ||
                          selectedParticularActivity?.time === null
                            ? "-"
                            : selectedParticularActivity?.time}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                      <Divider className="divider" />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12} mt={2}>
                      <Typography variant="body1" component="label">
                        Comments
                      </Typography>
                      <Typography
                        variant="body1"
                        component="span"
                        className="value"
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {selectedParticularActivity?.comments === "" ||
                        selectedParticularActivity?.comments === null
                          ? "No comments"
                          : selectedParticularActivity?.comments}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ActivityPopupComponent;

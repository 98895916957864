/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import WithPermission from "../../components/common/withPermission";
import { fullLeads, updateLeads } from "../../utils/permissions";
import { styled } from "@mui/material/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  RiDeleteBinLine,
  RiEyeLine,
  RiPencilLine,
  RiRepeat2Line,
  RiMailLine,
  RiPhoneLine,
  RiFireLine,
  RiFireFill,
} from "react-icons/ri";
// import DummyImage from "../../assets/images/DummyImg.png";
import {
  Box,
  Chip,
  Tab,
  Tabs,
  Typography,
  Skeleton,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import Badge from "@mui/material/Badge";
import { useLocation, useNavigate } from "react-router-dom";
import LeadDeleteModal from "../../components/common/leaddeletemodal";
import ConvertModal from "../../components/common/convertLeadToProjectModal";
import { fetchLeads } from "../../store/slices/leadSlice";
import { editLeadByIdAdmin } from "../../store/slices/leadEditByIdSlice";
import { successToast } from "../../responses/successToast";
import { fetchLeadFiltersById } from "../../store/slices/leadFilterByIdSlice";
import { fetchLeadFilters } from "../../store/slices/leadFilterSlice";
import Tooltip from "@mui/material/Tooltip";
import { maskEmail, maskPhoneNumber } from "../../utils/masking";
import { selectIsAdmin } from "../../constants/selectors";
import { CustomTablePaginationToolbar } from "../../components/common/Pagination";

const customToolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#3e3f42",
          color: "white",
          fontSize: "11px",
          margin: "0",
          padding: "10",
          border: "1px solid white",
        },
        arrow: {
          color: "var(--color-white)",
        },
      },
    },
  },
});

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -12,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const LeadList = ({
  onValueChange,
  onHotPageChange,
  onOpenPageChange,
  onEstimationReqPageChange,
  onEstimationProvPageChange,
  onClosedPageChange,
  onWonPageChange,
  onLostPageChange,
  onStandByPageChange,
  searchUser,
  filteredProjects,
  filterApplied,
  filterByIdApplied,
  filteredByIdProjects,
  hotleadProjects,
  hotLeadApplyProjects,
  applyFilterId,
  selectedKeywords,
  selectedTLandBDAs,
  selectedTechnologies,
  searchText,
  filters,
  filtersbyid,
  onOpenFilterPageChange,
  onHotFilterPageChange,
  onEstimationReqFilterPageChange,
  onEstimationProvFilterPageChange,
  onClosedFilterPageChange,
  onWonFilterPageChange,
  onLostFilterPageChange,
  onStandByFilterPageChange,
  onOpenapplyFilterPageChange,
  onHotapplyFilterPageChange,
  onEstimationReqapplyFilterPageChange,
  onEstimationProvapplyFilterPageChange,
  onClosedapplyFilterPageChange,
  wonApplyFilterPage,
  hotappliedfilterCurrentPage,
  lostAppliedFilterPageChange,
  standbyappliedfiltercurrentpage,
  estimationappliedfilterProvidedCurrentPage,
  closedAppliedFilterPage,
  estimationReqAppliedFilterPage,
  openappliedFilterPage,
  onWonappliedFilterPageChange,
  onLostapplyFilterPageChange,
  onStandByapplyFilterPageChange,
  estimationlink,
  wonfilterPage,
  lostfilterPage,
  openfilterpage,
  hotfilterpage,
  estimationprovidedfitlerpage,
  estimationrequestedfilterpage,
  standbyfilterpage,
  closedfilterpage,
  isEditing,
  keywordSwitchValue,
  techStackSwitchValue,
  TLandBDAsSwitchValue,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      status: 0,
      page: 1,
      rowsPerPage: 10,
      filterApplied: false,
      filterByIdApplied: false,
      applyFilterId: "",
      selectedKeywords: [],
      selectedTLandBDAs: [],
      selectedTechnologies: [],
      text: "",
      converted: "",
      edit: "",
      keywordSwitchVal: true,
      techStackSwitchVal: true,
      TLandBDAsSwitchVal: true,
    };
  }
  const getUserRole = useSelector(selectIsAdmin);
  const leads = useSelector((state) => state.leads.leads[0]);
  const [hotloading, setHotLoading] = useState(true);
  const [openloading, setOpenLoading] = useState(true);
  const [estimationRequestedloading, setEstimationRequestedLoading] =
    useState(true);
  const [estimationProvidedloading, setEstimationProvidedLoading] =
    useState(true);
  const [closeloading, setCloseLoading] = useState(true);
  const [wonloading, setWonLoading] = useState(true);
  const [lostloading, setLostLoading] = useState(true);
  const [standbyloading, setStandByLoading] = useState(true);
  const [hotCurrentPage, setHotCurrentPage] = useState(
    !filterApplied && !filterByIdApplied && state.status === 0 ? state.page : 1
  );
  const [hotfilterCurrentPage, setHotfilterCurrentPage] = useState(1);
  const [openCurrentPage, setOpenCurrentPage] = useState(
    !filterApplied && !filterByIdApplied && state.status === 1 ? state.page : 1
  );
  const [openfilterCurrentPage, setOpenfilterCurrentPage] = useState(1);
  const [estimationRequestedCurrentPage, setEstimationRequestedCurrentPage] =
    useState(
      !filterApplied && !filterByIdApplied && state.status === 2
        ? state.page
        : 1
    );
  const [
    estimationfilterRequestedCurrentPage,
    setEstimationfilterRequestedCurrentPage,
  ] = useState(1);
  const [estimationProvidedCurrentPage, setEstimationProvidedCurrentPage] =
    useState(
      !filterApplied && !filterByIdApplied && state.status === 3
        ? state.page
        : 1
    );
  const [
    estimationfilterProvidedCurrentPage,
    setEstimationfilterProvidedCurrentPage,
  ] = useState(1);

  const [closeCurrentPage, setCloseCurrentPage] = useState(
    !filterApplied && !filterByIdApplied && state.status === 5 ? state.page : 1
  );
  const [closefilterCurrentPage, setClosefilterCurrentPage] = useState(1);
  const [wonCurrentPage, setWonCurrentPage] = useState(
    !filterApplied && !filterByIdApplied && state.status === 6 ? state.page : 1
  );
  const [wonfilterCurrentPage, setWonfilterCurrentPage] = useState(1);
  const [lostCurrentPage, setLostCurrentPage] = useState(
    !filterApplied && !filterByIdApplied && state.status === 7 ? state.page : 1
  );
  const [lostCurrentfilterPage, setLostfilterCurrentPage] = useState(1);
  const [standbycurrentpage, setStandByCurrentPage] = useState(
    !filterApplied && !filterByIdApplied && state.status === 4 ? state.page : 1
  );
  const [standbyfiltercurrentpage, setStandByfilterCurrentPage] = useState(1);
  const [hotapplyfilterCurrentPage, setHotapplyfilterCurrentPage] = useState(1);
  const [
    estimationapplyfilterRequestedCurrentPage,
    setEstimationapplyfilterRequestedCurrentPage,
  ] = useState(1);
  const [
    estimationapplyfilterProvidedCurrentPage,
    setEstimationapplyfilterProvidedCurrentPage,
  ] = useState(1);
  const [openapplyfilterCurrentPage, setOpenapplyfilterCurrentPage] =
    useState(1);
  const [closeapplyfilterCurrentPage, setCloseapplyfilterCurrentPage] =
    useState(1);
  const [wonapplyfilterCurrentPage, setWonapplyfilterCurrentPage] = useState(1);
  const [lostapplyCurrentfilterPage, setLostapplyfilterCurrentPage] =
    useState(1);
  const [standbyapplyfiltercurrentpage, setStandByapplyfilterCurrentPage] =
    useState(1);
  // START :: DELETE MODAL

  const [leadDelete, setLeadDelete] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState(null);
  const handleDeleteOpen = (leadId) => {
    setSelectedLeadId(leadId);
    setLeadDelete(true);
  };
  const handleDeleteClose = () => setLeadDelete(false);
  // END :: DELETE MODAL

  // START :: CONVERT LEAD TO PROJECT
  const [convertCancel, setConvertCancel] = useState(false);
  const [convertLeadId, setConvertLeadId] = useState(null);
  const [clientId, setClientId] = useState(null);
  const [leadProjectName, setLeadProjectName] = useState("");

  const [currPage, setCurrPage] = useState(state.page);
  const handleConvertOpen = (leadId, clientId, projectName) => {
    setConvertLeadId(leadId);
    setClientId(clientId);
    setLeadProjectName(projectName);
    setConvertCancel(true);
  };
  const handleConvertClose = () => setConvertCancel(false);

  const [value, setValue] = useState(state.status);

  const limit = 10;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onValueChange(newValue);
  };

  useEffect(() => {
    if (filterApplied) {
      if (value === 0) {
        setCurrPage(hotappliedfilterCurrentPage);
      }
      if (value === 1) {
        setCurrPage(openappliedFilterPage);
      }
      if (value === 2) {
        setCurrPage(estimationReqAppliedFilterPage);
      }
      if (value === 3) {
        setCurrPage(estimationappliedfilterProvidedCurrentPage);
      }
      if (value === 4) {
        setCurrPage(standbyappliedfiltercurrentpage);
      }
      if (value === 5) {
        setCurrPage(closedAppliedFilterPage);
      }
      if (value === 6) {
        setCurrPage(wonApplyFilterPage);
      }
      if (value === 7) {
        setCurrPage(lostAppliedFilterPageChange);
      }
    }
  }, [
    filterApplied,
    wonApplyFilterPage,
    lostAppliedFilterPageChange,
    closedAppliedFilterPage,
    standbyappliedfiltercurrentpage,
    estimationappliedfilterProvidedCurrentPage,
    estimationReqAppliedFilterPage,
    openappliedFilterPage,
    hotappliedfilterCurrentPage,
  ]);

  function capitalizeFirstLetter(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }

  function capitalizeWords(input) {
    return input?.replace(/\w+/g, function (word) {
      return word?.charAt(0).toUpperCase() + word.slice(1);
    });
  }
  const handlePageChange = (event, newPage) => {
    if (!filterApplied && !filterByIdApplied) {
      if (value === 0) {
        setHotCurrentPage(newPage);
        onHotPageChange(newPage);
        setCurrPage(newPage);
      } else if (value === 1) {
        setOpenCurrentPage(newPage);
        onOpenPageChange(newPage);
        setCurrPage(newPage);
      } else if (value === 2) {
        setEstimationRequestedCurrentPage(newPage);
        onEstimationReqPageChange(newPage);
        setCurrPage(newPage);
      } else if (value === 3) {
        setEstimationProvidedCurrentPage(newPage);
        onEstimationProvPageChange(newPage);
        setCurrPage(newPage);
      } else if (value === 4) {
        setStandByCurrentPage(newPage);
        onStandByPageChange(newPage);
        setCurrPage(newPage);
      } else if (value === 5) {
        setCloseCurrentPage(newPage);
        onClosedPageChange(newPage);
        setCurrPage(newPage);
      } else if (value === 6) {
        setWonCurrentPage(newPage);
        onWonFilterPageChange(newPage);
        onWonPageChange(newPage);
        setCurrPage(newPage);
      } else if (value === 7) {
        setLostCurrentPage(newPage);
        onLostPageChange(newPage);
        setCurrPage(newPage);
      }
    }

    if (filterByIdApplied) {
      if (value === 0) {
        setHotfilterCurrentPage(newPage);
        onHotFilterPageChange(newPage);
      } else if (value === 1) {
        setOpenfilterCurrentPage(newPage);
        onOpenFilterPageChange(newPage);
      } else if (value === 2) {
        setEstimationfilterRequestedCurrentPage(newPage);
        onEstimationReqFilterPageChange(newPage);
      } else if (value === 3) {
        setEstimationfilterProvidedCurrentPage(newPage);
        onEstimationProvFilterPageChange(newPage);
      } else if (value === 4) {
        setStandByfilterCurrentPage(newPage);
        onStandByFilterPageChange(newPage);
      } else if (value === 5) {
        setClosefilterCurrentPage(newPage);
        onClosedFilterPageChange(newPage);
      } else if (value === 6) {
        setWonfilterCurrentPage(newPage);
        onWonFilterPageChange(newPage);
      } else if (value === 7) {
        setLostfilterCurrentPage(newPage);
        onLostFilterPageChange(newPage);
      }
    }
    if (filterApplied) {
      if (value === 0) {
        setHotapplyfilterCurrentPage(newPage);
        onHotapplyFilterPageChange(newPage);
      } else if (value === 1) {
        setOpenapplyfilterCurrentPage(newPage);
        onOpenapplyFilterPageChange(newPage);
      } else if (value === 2) {
        setEstimationapplyfilterRequestedCurrentPage(newPage);
        onEstimationReqapplyFilterPageChange(newPage);
      } else if (value === 3) {
        setEstimationapplyfilterProvidedCurrentPage(newPage);
        onEstimationProvapplyFilterPageChange(newPage);
      } else if (value === 4) {
        setStandByapplyfilterCurrentPage(newPage);
        onStandByapplyFilterPageChange(newPage);
      } else if (value === 5) {
        setCloseapplyfilterCurrentPage(newPage);
        onClosedapplyFilterPageChange(newPage);
      } else if (value === 6) {
        setWonapplyfilterCurrentPage(newPage);
        onWonappliedFilterPageChange(newPage);
      } else if (value === 7) {
        setLostapplyfilterCurrentPage(newPage);
        onLostapplyFilterPageChange(newPage);
      }
    }
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    onHotPageChange(1);
    onOpenPageChange(1);
    onEstimationReqPageChange(1);
    onEstimationProvPageChange(1);
    onClosedPageChange(1);
    onWonPageChange(1);
    onLostPageChange(1);
    onStandByPageChange(1);
    onOpenFilterPageChange(1);
    onHotFilterPageChange(1);
    onEstimationReqFilterPageChange(1);
    onEstimationProvFilterPageChange(1);
    onClosedFilterPageChange(1);
    onWonFilterPageChange(1);
    onLostFilterPageChange(1);
    onStandByFilterPageChange(1);
    onOpenapplyFilterPageChange(1);
    onHotapplyFilterPageChange(1);
    onEstimationReqapplyFilterPageChange(1);
    onEstimationProvapplyFilterPageChange(1);
    onClosedapplyFilterPageChange(1);
    onWonappliedFilterPageChange(1);
    onLostapplyFilterPageChange(1);
    onStandByapplyFilterPageChange(1);
    if (!filterApplied && !filterByIdApplied) {
      if (value === 0) {
        setHotCurrentPage(1);
        setCurrPage(1);
      } else if (value === 1) {
        setOpenCurrentPage(1);
        setCurrPage(1);
      } else if (value === 2) {
        setEstimationRequestedCurrentPage(1);
        setCurrPage(1);
      } else if (value === 3) {
        setEstimationProvidedCurrentPage(1);
        setCurrPage(1);
      } else if (value === 4) {
        setStandByCurrentPage(1);
        setCurrPage(1);
      } else if (value === 5) {
        setCloseCurrentPage(1);
        setCurrPage(1);
      } else if (value === 6) {
        setWonCurrentPage(1);
        setCurrPage(1);
      } else if (value === 7) {
        setLostCurrentPage(1);
        setCurrPage(1);
      }
    }

    if (filterByIdApplied) {
      if (value === 0) {
        setHotfilterCurrentPage(1);
      } else if (value === 1) {
        setOpenfilterCurrentPage(1);
      } else if (value === 2) {
        setEstimationfilterRequestedCurrentPage(1);
      } else if (value === 3) {
        setEstimationfilterProvidedCurrentPage(1);
      } else if (value === 4) {
        setStandByfilterCurrentPage(1);
      } else if (value === 5) {
        setClosefilterCurrentPage(1);
      } else if (value === 6) {
        setWonfilterCurrentPage(1);
      } else if (value === 7) {
        setLostfilterCurrentPage(1);
      }
    }
    if (filterApplied) {
      if (value === 0) {
        setHotapplyfilterCurrentPage(1);
      } else if (value === 1) {
        setOpenapplyfilterCurrentPage(1);
      } else if (value === 2) {
        setEstimationapplyfilterRequestedCurrentPage(1);
      } else if (value === 3) {
        setEstimationapplyfilterProvidedCurrentPage(1);
      } else if (value === 4) {
        setStandByapplyfilterCurrentPage(1);
      } else if (value === 5) {
        setCloseapplyfilterCurrentPage(1);
      } else if (value === 6) {
        setWonapplyfilterCurrentPage(1);
      } else if (value === 7) {
        setLostapplyfilterCurrentPage(1);
      }
    }
  };
  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const tabLabel = (role, modules) => {
    return (
      <Box>
        <span className="user-name">{role}</span>
      </Box>
    );
  };

  const hotLeads = filterApplied
    ? hotLeadApplyProjects
    : filterByIdApplied
    ? hotleadProjects
    : leads?.allLeads?.filter((lead) => value === 0 && lead.hot_lead === true);

  const openLeads = filterApplied
    ? filteredProjects
    : filterByIdApplied
    ? filteredByIdProjects
    : leads?.allLeads?.filter((lead) => value === 1 && lead.status === "open");
  const estimationrequestedLeads = filterApplied
    ? filteredProjects
    : filterByIdApplied
    ? filteredByIdProjects
    : leads?.allLeads?.filter(
        (lead) => value === 2 && lead.status === "estimation_requested"
      );
  const estimationprovidedLeads = filterApplied
    ? filteredProjects
    : filterByIdApplied
    ? filteredByIdProjects
    : leads?.allLeads?.filter(
        (lead) => value === 3 && lead.status === "estimation_provided"
      );
  const standByLeads = filterApplied
    ? filteredProjects
    : filterByIdApplied
    ? filteredByIdProjects
    : leads?.allLeads?.filter(
        (lead) => value === 4 && lead.status === "standBy"
      );
  const closeLeads = filterApplied
    ? filteredProjects
    : filterByIdApplied
    ? filteredByIdProjects
    : leads?.allLeads?.filter(
        (lead) => value === 5 && lead.status === "closed"
      );
  const wonLeads = filterApplied
    ? filteredProjects
    : filterByIdApplied
    ? filteredByIdProjects
    : leads?.allLeads?.filter((lead) => value === 6 && lead.status === "won");
  const lostLeads = filterApplied
    ? filteredProjects
    : filterByIdApplied
    ? filteredByIdProjects
    : leads?.allLeads?.filter((lead) => value === 7 && lead.status === "lost");

  const handleCopySuccess = () => {
    successToast("Copied to ClipBoard");
  };

  useEffect(() => {
    setHotLoading(true);
    setOpenLoading(true);
    setEstimationRequestedLoading(true);
    setEstimationProvidedLoading(true);
    setCloseLoading(true);
    setWonLoading(true);
    setLostLoading(true);
    setStandByLoading(true);
    const fetchData = async () => {
      if (filterApplied) {
        setHotLoading(false);
        setOpenLoading(false);
        setEstimationRequestedLoading(false);
        setEstimationProvidedLoading(false);
        setCloseLoading(false);
        setWonLoading(false);
        setLostLoading(false);
        setStandByLoading(false);
        return;
      }
      try {
        await dispatch(
          fetchLeads({
            page:
              value === 0
                ? hotCurrentPage
                : value === 1
                ? openCurrentPage
                : value === 2
                ? estimationRequestedCurrentPage
                : value === 3
                ? estimationProvidedCurrentPage
                : value === 4
                ? standbycurrentpage
                : value === 5
                ? closeCurrentPage
                : value === 6
                ? wonCurrentPage
                : lostCurrentPage,
            status:
              value === 0
                ? ""
                : value === 1
                ? "open"
                : value === 2
                ? "estimation_requested"
                : value === 3
                ? "estimation_provided"
                : value === 4
                ? "standBy"
                : value === 5
                ? "closed"
                : value === 6
                ? "won"
                : "lost",
            limit: +rowsPerPage,
            query: searchUser,
            hot_lead: value === 0 ? 1 : "",
          })
        );

        if (value === 0) {
          setHotLoading(false);
        } else if (value === 1) {
          setOpenLoading(false);
        } else if (value === 2) {
          setEstimationRequestedLoading(false);
        } else if (value === 3) {
          setEstimationProvidedLoading(false);
        } else if (value === 4) {
          setStandByLoading(false);
        } else if (value === 5) {
          setCloseLoading(false);
        } else if (value === 6) {
          setWonLoading(false);
        } else {
          setLostLoading(false);
        }
      } catch (error) {
        if (value === 0) {
          setHotLoading(false);
        } else if (value === 1) {
          setOpenLoading(false);
        } else if (value === 2) {
          setEstimationRequestedLoading(false);
        } else if (value === 3) {
          setEstimationProvidedLoading(false);
        } else if (value === 4) {
          setStandByLoading(false);
        } else if (value === 5) {
          setCloseLoading(false);
        } else if (value === 6) {
          setWonLoading(false);
        } else {
          setLostLoading(false);
        }
        console.error("Error fetching users:", error);
      }
    };
    fetchData();
  }, [
    dispatch,
    value,
    searchUser,
    openCurrentPage,
    estimationRequestedCurrentPage,
    estimationProvidedCurrentPage,
    closeCurrentPage,
    wonCurrentPage,
    lostCurrentPage,
    hotCurrentPage,
    standbycurrentpage,
    rowsPerPage,
  ]);

  useEffect(() => {
    let savedSearchText = localStorage.getItem("searchLeadsQuery");
    savedSearchText = savedSearchText?.replace(/^"(.+(?="$))"$/, "$1");
    if (searchUser.trim() !== "" && searchUser !== savedSearchText) {
      if (
        hotCurrentPage > 1 ||
        openCurrentPage > 1 ||
        estimationRequestedCurrentPage > 1 ||
        estimationProvidedCurrentPage > 1 ||
        closeCurrentPage > 1 ||
        wonCurrentPage > 1 ||
        lostCurrentPage ||
        standbycurrentpage > 1
      ) {
        setHotCurrentPage(1);
        setOpenCurrentPage(1);
        setEstimationRequestedCurrentPage(1);
        setEstimationProvidedCurrentPage(1);
        setCloseCurrentPage(1);
        setWonCurrentPage(1);
        setLostCurrentPage(1);
        setStandByCurrentPage(1);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchUser]);

  const formData = new FormData();
  const handleHotConvert = (leadId) => {
    const leadToUpdate = leads?.allLeads?.find((lead) => lead.id === leadId);
    if (leadToUpdate) {
      const {
        hot_lead,
        bda_id,
        bde_id,
        tl_id,
        client_id,
        client_contact_id,
        project_name,
        project_id,
        lead_technology,
        change_status_time,
        lead_keywords,
        lead_project_links,
        lead_reference_links,
        lead_document_links,
        lead_screenshot_links,
        lead_video_links,
        lead_feedbacks,
        clients,
        bde,
        bda,
        tl,
        client_contacts,
        key_features,
        key_integration,
        id,
        bid_id,
        createdAt,
        updatedAt,
        daysDifference,
        deletedAt,
        ...leadDataWithoutHotLead
      } = leadToUpdate;
      const successMessage =
        hot_lead === 0 || hot_lead === false
          ? "Lead marked as Hot successfully"
          : "Lead removed from Hot List";
      let updatedClientId = client_id === null ? "" : client_id;
      let UpdatedClientContactId =
        client_contact_id === null ? "" : client_contact_id;
      const isHotLead = leadToUpdate.hot_lead === true; // Check for true
      const updatedHotLeadStatus = !isHotLead;
      const leadData = {
        ...leadDataWithoutHotLead,
        hot_lead: updatedHotLeadStatus,
        client_id: updatedClientId,
        client_contact_id: UpdatedClientContactId,
      };

      // Append the lead data to the FormData object
      for (const key in leadData) {
        formData.append(key, leadData[key]);
      }
      if (filterByIdApplied) {
        dispatch(editLeadByIdAdmin(leadId, formData))
          .then((response) => {
            if (response.success) {
              navigate("/leads");
              successToast(successMessage);
              dispatch(
                fetchLeadFiltersById({
                  filterId: applyFilterId,
                  status:
                    value === 0
                      ? ""
                      : value === 1
                      ? "open"
                      : value === 2
                      ? "estimation_requested"
                      : value === 3
                      ? "estimation_provided"
                      : value === 4
                      ? "standBy"
                      : value === 5
                      ? "closed"
                      : value === 6
                      ? "won"
                      : "lost",
                })
              );
              dispatch(
                fetchLeads({
                  page: "",
                  status:
                    value === 0
                      ? ""
                      : value === 1
                      ? "open"
                      : value === 2
                      ? "estimation_requested"
                      : value === 3
                      ? "estimation_provided"
                      : value === 4
                      ? "standBy"
                      : value === 5
                      ? "closed"
                      : value === 6
                      ? "won"
                      : "lost",
                  hot_lead: value === 0 ? 1 : "",
                  limit: +rowsPerPage,
                })
              );
            } else {
              console.error("Error creating user:", response.error);
            }
          })

          .catch((error) => {
            console.error("Error creating user:", error.message);
          });
      } else if (filterApplied) {
        dispatch(editLeadByIdAdmin(leadId, formData))
          .then((response) => {
            if (response.success) {
              navigate("/leads");
              successToast(successMessage);
              dispatch(
                fetchLeadFilters({
                  text: searchText,
                  keyword: Array.isArray(selectedKeywords)
                    ? selectedKeywords.map((option) => option.label).join(",")
                    : "",
                  technologies: Array.isArray(selectedTechnologies)
                    ? selectedTechnologies
                        .map((option) => option.label)
                        .join(",")
                    : "",
                  status:
                    value === 0
                      ? ""
                      : value === 1
                      ? "open"
                      : value === 2
                      ? "estimation_requested"
                      : value === 3
                      ? "estimation_provided"
                      : value === 4
                      ? "standBy"
                      : value === 5
                      ? "closed"
                      : value === 6
                      ? "won"
                      : "lost",
                })
              );
              dispatch(
                fetchLeads({
                  page: "",
                  status:
                    value === 0
                      ? ""
                      : value === 1
                      ? "open"
                      : value === 2
                      ? "estimation_requested"
                      : value === 3
                      ? "estimation_provided"
                      : value === 4
                      ? "standBy"
                      : value === 5
                      ? "closed"
                      : value === 6
                      ? "won"
                      : "lost",
                  hot_lead: value === 0 ? 1 : "",
                  limit: +rowsPerPage,
                })
              );
            } else {
              console.error("Error creating user:", response.error);
            }
          })

          .catch((error) => {
            console.error("Error creating user:", error.message);
          });
      } else {
        dispatch(editLeadByIdAdmin(leadId, formData))
          .then((response) => {
            if (response.success) {
              navigate("/leads");
              successToast(successMessage);
              dispatch(
                fetchLeads({
                  page: "",
                  status:
                    value === 0
                      ? ""
                      : value === 1
                      ? "open"
                      : value === 2
                      ? "estimation_requested"
                      : value === 3
                      ? "estimation_provided"
                      : value === 4
                      ? "standBy"
                      : value === 5
                      ? "closed"
                      : value === 6
                      ? "won"
                      : "lost",
                  hot_lead: value === 0 ? 1 : "",
                  limit: +rowsPerPage,
                })
              );
            } else {
              console.error("Error creating user:", response.error);
            }
          })

          .catch((error) => {
            console.error("Error creating user:", error.message);
          });
      }
    }
  };
  useEffect(() => {
    if (filterByIdApplied) {
      if (value === 0) {
        setCurrPage(hotfilterpage);
      }
      if (value === 1) {
        setCurrPage(openfilterpage);
      }
      if (value === 2) {
        setCurrPage(estimationrequestedfilterpage);
      }
      if (value === 3) {
        setCurrPage(estimationprovidedfitlerpage);
      }
      if (value === 4) {
        setCurrPage(standbyfilterpage);
      }
      if (value === 5) {
        setCurrPage(closedfilterpage);
      }
      if (value === 6) {
        setCurrPage(wonfilterPage);
      }
      if (value === 7) {
        setCurrPage(lostfilterPage);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    value,
    filterByIdApplied,
    openfilterpage,
    hotfilterpage,
    estimationrequestedfilterpage,
    estimationprovidedfitlerpage,
    wonfilterPage,
    lostfilterPage,
    standbyfilterpage,
    closedfilterpage,
  ]);

  const renderUserTable = (userList) => {
    return (
      <TableContainer className="table-listing">
        <Table
          sx={{ minWidth: 650 }}
          aria-label="table"
          className="listing-table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">Company Name</TableCell>
              <TableCell align="left">Contact Person</TableCell>
              <TableCell align="left">Project</TableCell>
              <TableCell align="left">Handlers</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userList?.map((lead) => (
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="left" style={{ verticalAlign: "top" }}>
                  {lead?.company_name ? (
                    lead?.company_name !== "null" ? (
                      <Tooltip
                        title={
                          <div
                            style={{
                              color: "white",
                              fontSize: "13px",
                            }}
                          >
                            {lead?.company_name}
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <Box
                          variant="div"
                          component="div"
                          style={{
                            marginBottom: "12px",
                            fontWeight: "600",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "160px",
                          }}
                        >
                          {capitalizeWords(
                            lead?.company_name === "" ||
                              lead?.company_name === null ||
                              lead?.company_name === "null"
                              ? "-"
                              : lead?.company_name
                          )}
                        </Box>
                      </Tooltip>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        style={{
                          marginBottom: "12px",
                          fontWeight: "600",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "160px",
                        }}
                      >
                        {capitalizeWords(
                          lead?.company_name === "" ||
                            lead?.company_name === null ||
                            lead?.company_name === "null"
                            ? "-"
                            : lead?.company_name
                        )}
                      </Box>
                    )
                  ) : (
                    "-"
                  )}
                  {getUserRole ? (
                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{ marginBottom: "5px" }}
                    >
                      {lead?.company_email === "" ||
                      lead?.company_email === null ||
                      lead?.company_name === "null" ? (
                        ""
                      ) : (
                        <Tooltip
                          title={
                            <div
                              style={{
                                color: "white",
                                fontSize: "13px",
                              }}
                            >
                              Click to Copy
                            </div>
                          }
                          arrow
                          placement="bottom-start"
                        >
                          <Box className="companyicons">
                            <CopyToClipboard
                              onCopy={handleCopySuccess}
                              text={lead?.company_email}
                            >
                              <RiMailLine />
                            </CopyToClipboard>
                          </Box>
                        </Tooltip>
                      )}
                      {lead?.company_email ? (
                        lead?.company_email !== "null" ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {lead?.company_email}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {lead?.company_email === "" ||
                              lead?.company_email === null ||
                              lead?.company_name === "null"
                                ? "-"
                                : lead?.company_email}
                            </Box>
                          </Tooltip>
                        ) : (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {lead?.company_email === "" ||
                            lead?.company_email === null ||
                            lead?.company_name === "null"
                              ? "-"
                              : lead?.company_email}
                          </Box>
                        )
                      ) : (
                        "-"
                      )}
                    </Box>
                  ) : (
                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{ marginBottom: "5px" }}
                    >
                      {lead?.company_email === "" ||
                      lead?.company_email === null ||
                      lead?.company_name === "null" ? (
                        ""
                      ) : (
                        <Box className="companyicons">
                          <RiMailLine />
                        </Box>
                      )}
                      {lead?.company_email ? (
                        lead?.company_email !== "null" ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {lead?.company_email === "" ||
                            lead?.company_email === null ||
                            lead?.company_name === "null"
                              ? "-"
                              : maskEmail(lead?.company_email)}
                          </Box>
                        ) : (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {lead?.company_email === "" ||
                            lead?.company_email === null ||
                            lead?.company_name === "null"
                              ? "-"
                              : maskEmail(lead?.company_email)}
                          </Box>
                        )
                      ) : (
                        "-"
                      )}
                    </Box>
                  )}
                  {getUserRole ? (
                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{ marginBottom: "5px" }}
                    >
                      {lead?.company_phone === "" ||
                      lead?.company_phone === null ||
                      lead?.company_phone === "null" ? (
                        ""
                      ) : (
                        <Tooltip
                          title={
                            <div
                              style={{
                                color: "white",
                                fontSize: "13px",
                              }}
                            >
                              Click to Copy
                            </div>
                          }
                          arrow
                          placement="bottom-start"
                        >
                          <Box className="companyicons">
                            <CopyToClipboard
                              onCopy={handleCopySuccess}
                              text={lead?.company_phone}
                            >
                              <RiPhoneLine />
                            </CopyToClipboard>
                          </Box>
                        </Tooltip>
                      )}
                      {lead?.company_phone ? (
                        lead?.company_email !== "null" ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {lead?.company_phone}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {lead?.company_phone === "" ||
                              lead?.company_phone === null ||
                              lead?.company_phone === "null"
                                ? "-"
                                : lead?.company_phone}
                            </Box>
                          </Tooltip>
                        ) : (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {lead?.company_phone === "" ||
                            lead?.company_phone === null ||
                            lead?.company_phone === "null"
                              ? "-"
                              : lead?.company_phone}
                          </Box>
                        )
                      ) : (
                        "-"
                      )}
                    </Box>
                  ) : (
                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{ marginBottom: "5px" }}
                    >
                      {lead?.company_phone === "" ||
                      lead?.company_phone === null ||
                      lead?.company_phone === "null" ? (
                        ""
                      ) : (
                        <Box className="companyicons">
                          <RiPhoneLine />
                        </Box>
                      )}
                      {lead?.company_phone ? (
                        lead?.company_email !== "null" ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {lead?.company_phone === "" ||
                            lead?.company_phone === null ||
                            lead?.company_phone === "null"
                              ? "-"
                              : maskPhoneNumber(lead?.company_phone)}
                          </Box>
                        ) : (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {lead?.company_phone === "" ||
                            lead?.company_phone === null ||
                            lead?.company_phone === "null"
                              ? "-"
                              : maskPhoneNumber(lead?.company_phone)}
                          </Box>
                        )
                      ) : (
                        "-"
                      )}
                    </Box>
                  )}
                </TableCell>

                <TableCell align="left" style={{ verticalAlign: "top" }}>
                  {" "}
                  {lead?.contact_person ? (
                    lead?.contact_person !== "null" ? (
                      <Tooltip
                        title={
                          <div
                            style={{
                              color: "white",
                              fontSize: "13px",
                            }}
                          >
                            {lead?.contact_person}
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <Box
                          variant="div"
                          component="div"
                          style={{
                            marginBottom: "12px",
                            fontWeight: "600",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "160px",
                          }}
                        >
                          {capitalizeWords(
                            lead?.contact_person === "null" ||
                              lead?.contact_person === null
                              ? "-"
                              : lead?.contact_person
                          )}
                        </Box>
                      </Tooltip>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        style={{
                          marginBottom: "12px",
                          fontWeight: "600",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "160px",
                        }}
                      >
                        {capitalizeWords(
                          lead?.contact_person === "null" ||
                            lead?.contact_person === null
                            ? "-"
                            : lead?.contact_person
                        )}
                      </Box>
                    )
                  ) : (
                    "-"
                  )}
                  {getUserRole ? (
                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{ marginBottom: "5px" }}
                    >
                      {lead?.contact_person_email === "" ||
                      lead?.contact_person_email === "null" ||
                      lead?.contact_person_email === null ? (
                        ""
                      ) : (
                        <Tooltip
                          title={
                            <div
                              style={{
                                color: "white",
                                fontSize: "13px",
                              }}
                            >
                              Click to Copy
                            </div>
                          }
                          arrow
                          placement="bottom-start"
                        >
                          <Box className="companyicons">
                            <CopyToClipboard
                              onCopy={handleCopySuccess}
                              text={lead?.contact_person_email}
                            >
                              <RiMailLine />
                            </CopyToClipboard>
                          </Box>
                        </Tooltip>
                      )}
                      {lead?.contact_person_email ? (
                        lead?.contact_person_email !== "null" ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {lead?.contact_person_email}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {lead?.contact_person_email === "" ||
                              lead?.contact_person_email === "null" ||
                              lead?.contact_person_email === null
                                ? "-"
                                : lead?.contact_person_email}
                            </Box>
                          </Tooltip>
                        ) : (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {lead?.contact_person_email === "" ||
                            lead?.contact_person_email === null ||
                            lead?.contact_person_email === "null"
                              ? "-"
                              : lead?.contact_person_email}
                          </Box>
                        )
                      ) : (
                        "-"
                      )}
                    </Box>
                  ) : (
                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{ marginBottom: "5px" }}
                    >
                      {lead?.contact_person_email === "" ||
                      lead?.contact_person_email === "null" ||
                      lead?.contact_person_email === null ? (
                        ""
                      ) : (
                        <Box className="companyicons">
                          <RiMailLine />
                        </Box>
                      )}
                      {lead?.contact_person_email ? (
                        lead?.contact_person_email !== "null" ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {lead?.contact_person_email === "" ||
                            lead?.contact_person_email === "null" ||
                            lead?.contact_person_email === null
                              ? "-"
                              : maskEmail(lead?.contact_person_email)}
                          </Box>
                        ) : (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {lead?.contact_person_email === "" ||
                            lead?.contact_person_email === null ||
                            lead?.contact_person_email === "null"
                              ? "-"
                              : maskEmail(lead?.contact_person_email)}
                          </Box>
                        )
                      ) : (
                        "-"
                      )}
                    </Box>
                  )}
                  {getUserRole ? (
                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{ marginBottom: "5px" }}
                    >
                      {lead?.contact_person_phone === "" ||
                      lead?.contact_person_phone === "null" ||
                      lead?.contact_person_phone === null ? (
                        ""
                      ) : (
                        <Tooltip
                          title={
                            <div
                              style={{
                                color: "white",
                                fontSize: "13px",
                              }}
                            >
                              Click to Copy
                            </div>
                          }
                          arrow
                          placement="bottom-start"
                        >
                          <Box className="companyicons">
                            <CopyToClipboard
                              onCopy={handleCopySuccess}
                              text={lead?.contact_person_phone}
                            >
                              <RiPhoneLine />
                            </CopyToClipboard>
                          </Box>
                        </Tooltip>
                      )}
                      {lead?.contact_person_phone ? (
                        lead?.contact_person_email !== "null" ? (
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "13px",
                                }}
                              >
                                {lead?.contact_person_phone}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Box
                              variant="div"
                              component="div"
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "130px",
                              }}
                            >
                              {lead?.contact_person_phone === "" ||
                              lead?.contact_person_phone === "null" ||
                              lead?.contact_person_phone === null
                                ? "-"
                                : lead?.contact_person_phone}
                            </Box>
                          </Tooltip>
                        ) : (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {lead?.contact_person_phone === "" ||
                            lead?.contact_person_phone === null ||
                            lead?.contact_person_email === "null"
                              ? "-"
                              : lead?.contact_person_phone}
                          </Box>
                        )
                      ) : (
                        "-"
                      )}
                    </Box>
                  ) : (
                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{ marginBottom: "5px" }}
                    >
                      {lead?.contact_person_phone === "" ||
                      lead?.contact_person_phone === "null" ||
                      lead?.contact_person_phone === null ? (
                        ""
                      ) : (
                        <Box className="companyicons">
                          <RiPhoneLine />
                        </Box>
                      )}
                      {lead?.contact_person_phone ? (
                        lead?.contact_person_email !== "null" ? (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {lead?.contact_person_phone === "" ||
                            lead?.contact_person_phone === "null" ||
                            lead?.contact_person_phone === null
                              ? "-"
                              : maskPhoneNumber(lead?.contact_person_phone)}
                          </Box>
                        ) : (
                          <Box
                            variant="div"
                            component="div"
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "130px",
                            }}
                          >
                            {lead?.contact_person_phone === "" ||
                            lead?.contact_person_phone === null ||
                            lead?.contact_person_email === "null"
                              ? "-"
                              : maskPhoneNumber(lead?.contact_person_phone)}
                          </Box>
                        )
                      ) : (
                        "-"
                      )}
                    </Box>
                  )}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    verticalAlign: "top",
                  }}
                >
                  {" "}
                  {lead?.project_name ? (
                    lead?.project_name !== "null" ? (
                      <Tooltip
                        title={
                          <div
                            style={{
                              color: "white",
                              fontSize: "13px",
                            }}
                          >
                            {lead?.project_name}
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <Box
                          variant="div"
                          component="div"
                          style={{
                            fontWeight: "600",
                            marginBottom: "12px",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "160px",
                          }}
                        >
                          {capitalizeWords(
                            lead?.project_name === "" ||
                              lead?.project_name === "null" ||
                              lead?.project_name === null
                              ? "-"
                              : lead?.project_name
                          )}
                        </Box>
                      </Tooltip>
                    ) : (
                      <Box
                        variant="div"
                        component="div"
                        style={{
                          fontWeight: "600",
                          marginBottom: "12px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          width: "160px",
                        }}
                      >
                        {capitalizeWords(
                          lead?.project_name === "" ||
                            lead?.project_name === "null" ||
                            lead?.project_name === null
                            ? "-"
                            : lead?.project_name
                        )}
                      </Box>
                    )
                  ) : (
                    "-"
                  )}
                  <Box
                    variant="div"
                    component="div"
                    className="tag-wrap"
                    style={{
                      marginBottom: "12px",
                      width: "220px",
                      alignItems: "center",
                    }}
                  >
                    {lead?.lead_technology?.length > 0
                      ? lead?.lead_technology?.map(
                          (technology, index) =>
                            index < 2 && (
                              <Tooltip
                                title={
                                  <div
                                    style={{
                                      color: "white",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {capitalizeFirstLetter(
                                      technology?.lead_technology
                                        ?.technology_name
                                    )}
                                  </div>
                                }
                                arrow
                                placement="bottom-start"
                              >
                                <Box
                                  variant="div"
                                  component="div"
                                  // className="app-name d-flex lead"
                                  style={{
                                    fontWeight: "600",
                                    // marginBottom: "12px",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                  }}
                                >
                                  <Chip
                                    label={
                                      technology?.lead_technology
                                        ?.technology_name
                                    }
                                  />
                                </Box>
                              </Tooltip>
                            )
                        )
                      : "-"}
                    {lead?.lead_technology?.length > 2 && (
                      <Box variant="div" component="div">
                        <Tooltip
                          title={
                            <div
                              style={{
                                color: "white",
                                fontSize: "13px",
                              }}
                            >
                              {lead?.lead_technology
                                ?.map((technology) =>
                                  capitalizeFirstLetter(
                                    technology?.lead_technology?.technology_name
                                  )
                                )
                                .slice(2)
                                .join(", ")}{" "}
                            </div>
                          }
                          arrow
                          placement="bottom-start"
                        >
                          <Typography
                            variant="body1"
                            component="div"
                            // style={{ marginBottom: "12px" }}
                          >
                            +{" "}
                            <Typography
                              variant="body1"
                              component="span"
                              className="plus-users"
                              style={{ fontSize: "0.875rem" }}
                            >
                              {lead?.lead_technology?.length - 2}
                            </Typography>{" "}
                            more
                          </Typography>
                        </Tooltip>
                      </Box>
                    )}
                  </Box>
                  <Box
                    variant="div"
                    component="div"
                    className="app-name d-flex"
                    style={{ fontWeight: "600" }}
                  >
                    {" "}
                    Estimation:
                    <Typography
                      variant="body1"
                      component="div"
                      style={{
                        fontWeight: "400",
                        color: "var(--primary-text-color) ",
                        fontSize: "0.925rem",
                      }}
                    >
                      {lead?.estimation_hours === null ||
                      lead?.estimation_hours === "null" ||
                      lead?.estimation_hours === ""
                        ? "-"
                        : `${lead?.estimation_hours} Hrs`}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    verticalAlign: "top",
                    fontWeight: "600",
                  }}
                >
                  <Box
                    variant="div"
                    component="div"
                    className="app-name d-flex"
                    style={{ fontWeight: "600" }}
                  >
                    TL:&nbsp;
                    {lead?.tl === null || lead?.tl_id === null ? (
                      "-"
                    ) : (
                      <Tooltip
                        title={
                          <div
                            style={{
                              color: "white",
                              fontSize: "13px",
                            }}
                          >
                            {capitalizeFirstLetter(lead?.tl?.first_name)}{" "}
                            {capitalizeFirstLetter(lead?.tl?.last_name)}{" "}
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <Typography
                          variant="body1"
                          component="div"
                          style={{
                            fontWeight: "400",
                            color: "var(--primary-text-color) ",
                            fontSize: "0.925rem",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "130px",
                          }}
                        >
                          {capitalizeFirstLetter(lead?.tl?.first_name)}{" "}
                          {capitalizeFirstLetter(lead?.tl?.last_name)}{" "}
                        </Typography>
                      </Tooltip>
                    )}
                  </Box>

                  <Box
                    variant="div"
                    component="div"
                    className="app-name d-flex"
                  >
                    BDM:&nbsp;
                    {lead?.bda === null || lead?.bda_id === null ? (
                      "-"
                    ) : (
                      <Tooltip
                        title={
                          <div
                            style={{
                              color: "white",
                              fontSize: "13px",
                            }}
                          >
                            {capitalizeFirstLetter(lead?.bda?.first_name)}{" "}
                            {capitalizeFirstLetter(lead?.bda?.last_name)}
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <Typography
                          variant="body1"
                          component="div"
                          style={{
                            fontWeight: "400",
                            color: "var(--primary-text-color) ",
                            fontSize: "0.925rem",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "115px",
                          }}
                        >
                          {capitalizeFirstLetter(lead?.bda?.first_name)}{" "}
                          {capitalizeFirstLetter(lead?.bda?.last_name)}
                        </Typography>
                      </Tooltip>
                    )}
                  </Box>

                  <Box
                    variant="div"
                    component="div"
                    className="app-name d-flex"
                    style={{ fontWeight: "600" }}
                  >
                    BDE:&nbsp;
                    {lead?.bde === null || lead?.bde_id === null ? (
                      "-"
                    ) : (
                      <Tooltip
                        title={
                          <div
                            style={{
                              color: "white",
                              fontSize: "13px",
                            }}
                          >
                            {capitalizeFirstLetter(lead?.bde?.first_name)}{" "}
                            {capitalizeFirstLetter(lead?.bde?.last_name)}{" "}
                          </div>
                        }
                        arrow
                        placement="bottom-start"
                      >
                        <Typography
                          variant="body1"
                          component="div"
                          style={{
                            fontWeight: "400",
                            color: "var(--primary-text-color) ",
                            fontSize: "0.925rem",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "115px",
                          }}
                        >
                          {capitalizeFirstLetter(lead?.bde?.first_name)}{" "}
                          {capitalizeFirstLetter(lead?.bde?.last_name)}{" "}
                        </Typography>
                      </Tooltip>
                    )}
                  </Box>
                </TableCell>
                <TableCell align="left" style={{ verticalAlign: "top" }}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      className="action-btn-wrap"
                      style={{ marginBottom: "12px" }}
                    >
                      <ThemeProvider theme={customToolTipTheme}>
                        <Tooltip title="View">
                          <span>
                            <Button
                              variant="outlined"
                              className="action-btn view-btn"
                              startIcon={<RiEyeLine />}
                              disableRipple
                              onClick={() =>
                                navigate(`/leads/${lead.id}/view`, {
                                  state: {
                                    page: currPage,
                                    rowsPerPage: rowsPerPage,
                                    status: value,
                                    filterApplied: filterApplied,
                                    filterByIdApplied: filterByIdApplied,
                                    applyFilterId: applyFilterId,
                                    selectedKeywords: selectedKeywords,
                                    selectedTechnologies: selectedTechnologies,
                                    selectedTLandBDAs: selectedTLandBDAs,
                                    keywordSwitchVal: keywordSwitchValue,
                                    techStackSwitchVal: techStackSwitchValue,
                                    text: searchText,
                                    converted: estimationlink,
                                    edit: isEditing,
                                    TLandBDAsSwitchVal: TLandBDAsSwitchValue,
                                  },
                                })
                              }
                            ></Button>
                          </span>
                        </Tooltip>
                      </ThemeProvider>
                      <WithPermission
                        permission={updateLeads}
                        element={
                          <ThemeProvider theme={customToolTipTheme}>
                            <Tooltip title="Edit">
                              <span>
                                <Button
                                  variant="outlined"
                                  className="action-btn edit-btn"
                                  startIcon={<RiPencilLine />}
                                  disableRipple
                                  // title="Edit"
                                  onClick={() =>
                                    navigate(`/leads/${lead?.id}/edit`, {
                                      state: {
                                        page: currPage,
                                        rowsPerPage: rowsPerPage,
                                        status: value,
                                        filterApplied: filterApplied,
                                        filterByIdApplied: filterByIdApplied,
                                        applyFilterId: applyFilterId,
                                        selectedKeywords: selectedKeywords,
                                        selectedTLandBDAs: selectedTLandBDAs,
                                        keywordSwitchVal: keywordSwitchValue,
                                        TLandBDAsSwitchVal:
                                          TLandBDAsSwitchValue,
                                        techStackSwitchVal:
                                          techStackSwitchValue,
                                        selectedTechnologies:
                                          selectedTechnologies,
                                        text: searchText,
                                        converted: estimationlink,
                                        edit: isEditing,
                                      },
                                    })
                                  }
                                ></Button>
                              </span>
                            </Tooltip>
                          </ThemeProvider>
                        }
                      />
                      {value === 6 ? null : (
                        <WithPermission
                          permission={updateLeads}
                          element={
                            <ThemeProvider theme={customToolTipTheme}>
                              <Tooltip
                                title={
                                  lead?.status === "won"
                                    ? "Lead has already been converted into project"
                                    : "Convert Lead To Project"
                                }
                              >
                                <span>
                                  <Button
                                    variant="outlined"
                                    startIcon={<RiRepeat2Line />}
                                    className="action-btn convert-btn"
                                    onClick={() => {
                                      handleConvertOpen(
                                        lead?.id,
                                        lead?.client_id,
                                        lead?.project_name
                                      );
                                    }}
                                    disabled={lead?.status === "won"}
                                  ></Button>
                                </span>
                              </Tooltip>
                            </ThemeProvider>
                          }
                        />
                      )}
                      <WithPermission
                        permission={fullLeads}
                        element={
                          <ThemeProvider theme={customToolTipTheme}>
                            <Tooltip title="Delete">
                              <span>
                                <Button
                                  variant="outlined"
                                  className="action-btn dlt-btn"
                                  startIcon={<RiDeleteBinLine />}
                                  disableRipple
                                  // title="Delete"
                                  onClick={() => handleDeleteOpen(lead.id)}
                                ></Button>
                              </span>
                            </Tooltip>
                          </ThemeProvider>
                        }
                      />
                      <WithPermission
                        permission={updateLeads}
                        element={
                          <>
                            <ThemeProvider theme={customToolTipTheme}>
                              <Tooltip
                                title={
                                  lead.hot_lead === 1 || lead.hot_lead === true
                                    ? "Remove from Hot Lead"
                                    : "Make It Hot Lead"
                                }
                              >
                                <span>
                                  <Button
                                    variant="outlined"
                                    className="action-btn fire-btn"
                                    startIcon={
                                      lead.hot_lead === 1 ||
                                      lead.hot_lead === true ? (
                                        <RiFireFill /> // Filled fire icon when hot_lead is 1
                                      ) : (
                                        <RiFireLine /> // Unfilled fire icon when hot_lead is 0
                                      )
                                    }
                                    onClick={() => handleHotConvert(lead.id)}
                                    disableRipple
                                  ></Button>
                                </span>
                              </Tooltip>
                            </ThemeProvider>
                          </>
                        }
                      />
                    </Box>
                    <Box
                      variant="div"
                      component="div"
                      className="app-name d-flex"
                      style={{
                        color: "#ff606b",
                        fontStyle: "italic",
                        fontWeight: "600",
                      }}
                    >
                      {`Since ${
                        lead?.daysDifference === 0
                          ? "Today"
                          : lead?.daysDifference === 1
                          ? "Yesterday"
                          : lead?.daysDifference
                      } ${
                        lead?.daysDifference === 0
                          ? ""
                          : lead?.daysDifference === 1
                          ? ""
                          : "days"
                      }`}
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* <Box variant="div" component="div" className="pagination-wrapper"> */}
        {value === 0 ? (
          <>
            {/* <Pagination
              count={
                filterByIdApplied
                  ? filtersbyid?.totalhotleadPages
                  : filterApplied
                  ? filters?.totalhotleadPages
                  : leads?.totalPages
              }
              page={
                filterByIdApplied
                  ? hotfilterpage
                  : filterApplied
                  ? hotappliedfilterCurrentPage
                  : hotCurrentPage
              }
              onChange={handlePageChange}
              shape="rounded"
            /> */}
            <CustomTablePaginationToolbar
              count={
                filterByIdApplied
                  ? filtersbyid?.totalhotleadUserCount
                  : filterApplied
                  ? filters?.totalhotleadUserCount
                  : leads?.total || 0
              }
              page={
                filterByIdApplied
                  ? hotfilterpage
                  : filterApplied
                  ? hotappliedfilterCurrentPage
                  : hotCurrentPage
              }
              rowsPerPage={+rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : value === 1 ? (
          <>
            {/* <Pagination
              count={
                filterByIdApplied
                  ? filtersbyid?.totalleadPages
                  : filterApplied
                  ? filters?.totalleadPages
                  : leads?.totalPages
              }
              page={
                filterByIdApplied
                  ? openfilterpage
                  : filterApplied
                  ? openappliedFilterPage
                  : openCurrentPage
              }
              onChange={handlePageChange}
              shape="rounded"
            /> */}
            <CustomTablePaginationToolbar
              count={
                filterByIdApplied
                  ? filtersbyid?.totalleadUserCount
                  : filterApplied
                  ? filters?.totalleadUserCount
                  : leads?.total || 0
              }
              page={
                filterByIdApplied
                  ? openfilterpage
                  : filterApplied
                  ? openappliedFilterPage
                  : openCurrentPage
              }
              rowsPerPage={+rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : value === 2 ? (
          <>
            {/* <Pagination
              count={
                filterByIdApplied
                  ? filtersbyid?.totalleadPages
                  : filterApplied
                  ? filters?.totalleadPages
                  : leads?.totalPages
              }
              page={
                filterByIdApplied
                  ? estimationrequestedfilterpage
                  : filterApplied
                  ? estimationReqAppliedFilterPage
                  : estimationRequestedCurrentPage
              }
              onChange={handlePageChange}
              shape="rounded"
            /> */}
            <CustomTablePaginationToolbar
              count={
                filterByIdApplied
                  ? filtersbyid?.totalleadUserCount
                  : filterApplied
                  ? filters?.totalleadUserCount
                  : leads?.total || 0
              }
              page={
                filterByIdApplied
                  ? estimationrequestedfilterpage
                  : filterApplied
                  ? estimationReqAppliedFilterPage
                  : estimationRequestedCurrentPage
              }
              rowsPerPage={+rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : value === 3 ? (
          <>
            {/* <Pagination
              count={
                filterByIdApplied
                  ? filtersbyid?.totalleadPages
                  : filterApplied
                  ? filters?.totalleadPages
                  : leads?.totalPages
              }
              page={
                filterByIdApplied
                  ? estimationprovidedfitlerpage
                  : filterApplied
                  ? estimationappliedfilterProvidedCurrentPage
                  : estimationProvidedCurrentPage
              }
              onChange={handlePageChange}
              shape="rounded"
            /> */}
            <CustomTablePaginationToolbar
              count={
                filterByIdApplied
                  ? filtersbyid?.totalleadUserCount
                  : filterApplied
                  ? filters?.totalleadUserCount
                  : leads?.total || 0
              }
              page={
                filterByIdApplied
                  ? estimationprovidedfitlerpage
                  : filterApplied
                  ? estimationappliedfilterProvidedCurrentPage
                  : estimationProvidedCurrentPage
              }
              rowsPerPage={+rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : value === 4 ? (
          <>
            {/* <Pagination
              count={
                filterByIdApplied
                  ? filtersbyid?.totalleadPages
                  : filterApplied
                  ? filters?.totalleadPages
                  : leads?.totalPages
              }
              page={
                filterByIdApplied
                  ? standbyfilterpage
                  : filterApplied
                  ? standbyappliedfiltercurrentpage
                  : standbycurrentpage
              }
              onChange={handlePageChange}
              shape="rounded"
            /> */}
            <CustomTablePaginationToolbar
              count={
                filterByIdApplied
                  ? filtersbyid?.totalleadUserCount
                  : filterApplied
                  ? filters?.totalleadUserCount
                  : leads?.total || 0
              }
              page={
                filterByIdApplied
                  ? standbyfilterpage
                  : filterApplied
                  ? standbyappliedfiltercurrentpage
                  : standbycurrentpage
              }
              rowsPerPage={+rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : value === 5 ? (
          <>
            {/* <Pagination
              count={
                filterByIdApplied
                  ? filtersbyid?.totalleadPages
                  : filterApplied
                  ? filters?.totalleadPages
                  : leads?.totalPages
              }
              page={
                filterByIdApplied
                  ? closedfilterpage
                  : filterApplied
                  ? closedAppliedFilterPage
                  : closeCurrentPage
              }
              onChange={handlePageChange}
              shape="rounded"
            /> */}
            <CustomTablePaginationToolbar
              count={
                filterByIdApplied
                  ? filtersbyid?.totalleadUserCount
                  : filterApplied
                  ? filters?.totalleadUserCount
                  : leads?.total || 0
              }
              page={
                filterByIdApplied
                  ? closedfilterpage
                  : filterApplied
                  ? closedAppliedFilterPage
                  : closeCurrentPage
              }
              rowsPerPage={+rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : value === 6 ? (
          <>
            {/* <Pagination
              count={
                filterByIdApplied
                  ? filtersbyid?.totalleadPages
                  : filterApplied
                  ? filters?.totalleadPages
                  : leads?.totalPages
              }
              page={
                filterByIdApplied
                  ? wonfilterPage
                  : filterApplied
                  ? wonApplyFilterPage
                  : wonCurrentPage
              }
              onChange={handlePageChange}
              shape="rounded"
            /> */}
            <CustomTablePaginationToolbar
              count={
                filterByIdApplied
                  ? filtersbyid?.totalleadUserCount
                  : filterApplied
                  ? filters?.totalleadUserCount
                  : leads?.total || 0
              }
              page={
                filterByIdApplied
                  ? wonfilterPage
                  : filterApplied
                  ? wonApplyFilterPage
                  : wonCurrentPage
              }
              rowsPerPage={+rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <>
            {/* <Pagination
              count={
                filterByIdApplied
                  ? filtersbyid?.totalleadPages
                  : filterApplied
                  ? filters?.totalleadPages
                  : leads?.totalPages
              }
              page={
                filterByIdApplied
                  ? lostfilterPage
                  : filterApplied
                  ? lostAppliedFilterPageChange
                  : lostCurrentPage
              }
              onChange={handlePageChange}
              shape="rounded"
            /> */}
            <CustomTablePaginationToolbar
              count={
                filterByIdApplied
                  ? filtersbyid?.totalleadUserCount
                  : filterApplied
                  ? filters?.totalleadUserCount
                  : leads?.total || 0
              }
              page={
                filterByIdApplied
                  ? lostfilterPage
                  : filterApplied
                  ? lostAppliedFilterPageChange
                  : lostCurrentPage
              }
              rowsPerPage={+rowsPerPage}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
        {/* </Box> */}
      </TableContainer>
    );
  };

  const renderNoUsersFoundMessage = () => {
    return (
      <CustomTabPanel
        value={value}
        index={value}
        className="user-listing-tables"
      >
        <Typography variant="body1" component="div" className="no-msg">
          No Leads Found
        </Typography>
      </CustomTabPanel>
    );
  };

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      window.history.replaceState({}, "");
    });
    return () => {
      window.history.replaceState({}, "");
    };
  }, []);

  return (
    <>
      <Box variant="div" component="div" className="tab-wrapper user-listing">
        {/* START :: LEAD TABS NAME */}
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="custom-auto" // style={{ overflowX: "scroll" }}
          >
            <Tab
              style={{ padding: "8px 25px" }}
              label={
                filterByIdApplied ? (
                  <StyledBadge
                    badgeContent={filtersbyid?.hotleadsscount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Hot")}
                  </StyledBadge>
                ) : filterApplied ? (
                  <StyledBadge
                    badgeContent={filters?.hotleadsscount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Hot")}
                  </StyledBadge>
                ) : (
                  <StyledBadge
                    badgeContent={leads?.counts?.hotleadsscount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Hot")}
                  </StyledBadge>
                )
              }
              {...a11yProps(0)}
            />

            <Tab
              style={{ padding: "8px 25px" }}
              label={
                filterByIdApplied ? (
                  <StyledBadge
                    badgeContent={filtersbyid?.openleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Open")}
                  </StyledBadge>
                ) : filterApplied ? (
                  <StyledBadge
                    badgeContent={filters?.openleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Open")}
                  </StyledBadge>
                ) : (
                  <StyledBadge
                    badgeContent={leads?.counts?.openleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Open")}
                  </StyledBadge>
                )
              }
              {...a11yProps(1)}
            />

            <Tab
              style={{ padding: "8px 25px" }}
              label={
                filterByIdApplied ? (
                  <StyledBadge
                    badgeContent={filtersbyid?.estimationrequestedleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Estimation Requested")}
                  </StyledBadge>
                ) : filterApplied ? (
                  <StyledBadge
                    badgeContent={filters?.estimationrequestedleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Estimation Requested")}
                  </StyledBadge>
                ) : (
                  <StyledBadge
                    badgeContent={leads?.counts?.estimationrequestedleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Estimation Requested")}
                  </StyledBadge>
                )
              }
              {...a11yProps(2)}
            />

            <Tab
              style={{ padding: "8px 25px" }}
              label={
                filterByIdApplied ? (
                  <StyledBadge
                    badgeContent={filtersbyid?.estimationprovidedleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Estimation Provided")}
                  </StyledBadge>
                ) : filterApplied ? (
                  <StyledBadge
                    badgeContent={filters?.estimationprovidedleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Estimation Provided")}
                  </StyledBadge>
                ) : (
                  <StyledBadge
                    badgeContent={leads?.counts?.estimationprovidedleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Estimation Provided")}
                  </StyledBadge>
                )
              }
              {...a11yProps(3)}
            />

            <Tab
              style={{ padding: "8px 25px" }}
              label={
                filterByIdApplied ? (
                  <StyledBadge
                    badgeContent={filtersbyid?.standbyleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("StandBy")}
                  </StyledBadge>
                ) : filterApplied ? (
                  <StyledBadge
                    badgeContent={filters?.standbyleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("StandBy")}
                  </StyledBadge>
                ) : (
                  <StyledBadge
                    badgeContent={leads?.counts?.standbyleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("StandBy")}
                  </StyledBadge>
                )
              }
              {...a11yProps(4)}
            />
            <Tab
              style={{ padding: "8px 25px" }}
              label={
                filterByIdApplied ? (
                  <StyledBadge
                    badgeContent={filtersbyid?.notrespondingleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Not Responding")}
                  </StyledBadge>
                ) : filterApplied ? (
                  <StyledBadge
                    badgeContent={filters?.notrespondingleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Not Responding")}
                  </StyledBadge>
                ) : (
                  <StyledBadge
                    badgeContent={leads?.counts?.notrespondingleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Not Responding")}
                  </StyledBadge>
                )
              }
              {...a11yProps(5)}
            />
            <Tab
              style={{ padding: "8px 25px" }}
              label={
                filterByIdApplied ? (
                  <StyledBadge
                    badgeContent={filtersbyid?.wonleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Won")}
                  </StyledBadge>
                ) : filterApplied ? (
                  <StyledBadge
                    badgeContent={filters?.wonleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Won")}
                  </StyledBadge>
                ) : (
                  <StyledBadge
                    badgeContent={leads?.counts?.wonleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Won")}
                  </StyledBadge>
                )
              }
              {...a11yProps(6)}
            />
            <Tab
              style={{ padding: "8px 25px" }}
              label={
                filterByIdApplied ? (
                  <StyledBadge
                    badgeContent={filtersbyid?.lostleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Lost")}
                  </StyledBadge>
                ) : filterApplied ? (
                  <StyledBadge
                    badgeContent={filters?.lostleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Lost")}
                  </StyledBadge>
                ) : (
                  <StyledBadge
                    badgeContent={leads?.counts?.lostleadcount}
                    color="success"
                    max={9999}
                  >
                    {tabLabel("Lost")}
                  </StyledBadge>
                )
              }
              {...a11yProps(7)}
            />
          </Tabs>
        </Box>

        {value === 0 ? (
          <>
            {hotloading ? (
              <TableContainer className="table-listing">
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Company Name</TableCell>
                      <TableCell align="left">Contact Person</TableCell>
                      <TableCell align="left">Project</TableCell>
                      <TableCell align="left">Handlers</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : hotLeads?.length > 0 ? (
              <CustomTabPanel
                value={value}
                index={0}
                className="user-listing-tables"
              >
                {renderUserTable(hotLeads)}
              </CustomTabPanel>
            ) : (
              renderNoUsersFoundMessage()
            )}{" "}
          </>
        ) : value === 1 ? (
          // Render for internal users
          <>
            {openloading ? (
              <TableContainer className="table-listing">
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Company Name</TableCell>
                      <TableCell align="left">Contact Person</TableCell>
                      <TableCell align="left">Project</TableCell>
                      <TableCell align="left">Handlers</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : openLeads?.length > 0 ? (
              <CustomTabPanel
                value={value}
                index={1}
                className="user-listing-tables"
              >
                {renderUserTable(openLeads)}
              </CustomTabPanel>
            ) : (
              renderNoUsersFoundMessage()
            )}
          </>
        ) : value === 2 ? (
          <>
            {/* END :: OPEN TAB CONTENT */}
            {/* START :: ESTIMATION REQUESTED TAB CONTENT */}
            {estimationRequestedloading ? (
              <TableContainer className="table-listing">
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Company Name</TableCell>
                      <TableCell align="left">Contact Person</TableCell>
                      <TableCell align="left">Project</TableCell>
                      <TableCell align="left">Handlers</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : estimationrequestedLeads?.length > 0 ? (
              <CustomTabPanel
                value={value}
                index={2}
                className="user-listing-tables"
              >
                {renderUserTable(estimationrequestedLeads)}
              </CustomTabPanel>
            ) : (
              <CustomTabPanel
                value={value}
                index={2}
                className="user-listing-tables"
              >
                <Typography variant="body1" component="div" className="no-msg">
                  No Leads Found
                </Typography>
              </CustomTabPanel>
            )}{" "}
          </>
        ) : value === 3 ? (
          <>
            {/* END :: ESTIMATION REQUESTED TAB CONTENT */}
            {/* START :: ESTIMATION PROVIDED TAB CONTENT */}
            {estimationProvidedloading ? (
              <TableContainer className="table-listing">
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Company Name</TableCell>
                      <TableCell align="left">Contact Person</TableCell>
                      <TableCell align="left">Project</TableCell>
                      <TableCell align="left">Handlers</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : estimationprovidedLeads?.length > 0 ? (
              <CustomTabPanel
                value={value}
                index={3}
                className="user-listing-tables"
              >
                {renderUserTable(estimationprovidedLeads)}
              </CustomTabPanel>
            ) : (
              renderNoUsersFoundMessage()
            )}
          </>
        ) : value === 4 ? (
          <>
            {standbyloading ? (
              <TableContainer className="table-listing">
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Company Name</TableCell>
                      <TableCell align="left">Contact Person</TableCell>
                      <TableCell align="left">Project</TableCell>
                      <TableCell align="left">Handlers</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : standByLeads?.length > 0 ? (
              <CustomTabPanel
                value={value}
                index={4}
                className="user-listing-tables"
              >
                {renderUserTable(standByLeads)}
              </CustomTabPanel>
            ) : (
              renderNoUsersFoundMessage()
            )}
          </>
        ) : value === 5 ? (
          <>
            {closeloading ? (
              <TableContainer className="table-listing">
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Company Name</TableCell>
                      <TableCell align="left">Contact Person</TableCell>
                      <TableCell align="left">Project</TableCell>
                      <TableCell align="left">Handlers</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : closeLeads?.length > 0 ? (
              <CustomTabPanel
                value={value}
                index={5}
                className="user-listing-tables"
              >
                {renderUserTable(closeLeads)}
              </CustomTabPanel>
            ) : (
              renderNoUsersFoundMessage()
            )}
          </>
        ) : value === 6 ? (
          <>
            {wonloading ? (
              <TableContainer className="table-listing">
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Company Name</TableCell>
                      <TableCell align="left">Contact Person</TableCell>
                      <TableCell align="left">Project</TableCell>
                      <TableCell align="left">Handlers</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : wonLeads?.length > 0 ? (
              <CustomTabPanel
                value={value}
                index={6}
                className="user-listing-tables"
              >
                {renderUserTable(wonLeads)}
              </CustomTabPanel>
            ) : (
              renderNoUsersFoundMessage()
            )}
          </>
        ) : (
          <>
            {/* END :: WON TAB CONTENT */}
            {lostloading ? (
              <TableContainer className="table-listing">
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="table"
                  className="listing-table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Company Name</TableCell>
                      <TableCell align="left">Contact Person</TableCell>
                      <TableCell align="left">Project</TableCell>
                      <TableCell align="left">Handlers</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.from({ length: 6 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="100%" height={40} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : lostLeads?.length > 0 ? (
              <CustomTabPanel
                value={value}
                index={7}
                className="user-listing-tables"
              >
                {renderUserTable(lostLeads)}
              </CustomTabPanel>
            ) : (
              renderNoUsersFoundMessage()
            )}
          </>
        )}
        {/* END :: LOST TAB CONTENT */}
      </Box>

      <LeadDeleteModal
        handleDeleteOpen={handleDeleteOpen}
        handleDeleteClose={handleDeleteClose}
        leadDelete={leadDelete}
        selectedLeadId={selectedLeadId}
        value={value}
        applyFilterId={applyFilterId}
        filterByIdApplied={filterByIdApplied}
        filterApplied={filterApplied}
        searchText={searchText}
        selectedKeywords={selectedKeywords}
        selectedTechnologies={selectedTechnologies}
        hotCurrentPage={hotCurrentPage}
        openCurrentPage={openCurrentPage}
        estimationRequestedCurrentPage={estimationRequestedCurrentPage}
        estimationProvidedCurrentPage={estimationProvidedCurrentPage}
        standbycurrentpage={standbycurrentpage}
        closeCurrentPage={closeCurrentPage}
        wonCurrentPage={wonCurrentPage}
        lostCurrentPage={lostCurrentPage}
      />
      <ConvertModal
        handleConvertOpen={handleConvertOpen}
        handleConvertClose={handleConvertClose}
        convertCancel={convertCancel}
        convertLeadId={convertLeadId}
        clientId={clientId}
        value={value}
        leadProjectName={leadProjectName}
        setConvertCancel={setConvertCancel}
      />
    </>
  );
};

export default LeadList;

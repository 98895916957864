import { createSlice } from '@reduxjs/toolkit';
import { setFeedback } from '.././feedbackSlice';
import { callApi } from '../../../components/common/commonfunctionforapi';

const initialState = {
  user: null,
  loading: false,
  error: null,
};

const editTemplateCVByIdSlice = createSlice({
  name: 'editTemplateCVById',
  initialState,
  reducers: {
    editTemplateCVByIdStart(state) {
      state.loading = true;
      state.error = null;
    },
    editTemplateCVByIdSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    editTemplateCVByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  editTemplateCVByIdStart,
  editTemplateCVByIdSuccess,
  editTemplateCVByIdFailure,
} = editTemplateCVByIdSlice.actions;
export default editTemplateCVByIdSlice.reducer;

export const editTemplateCVById = (id, TemplateCVData) => async (dispatch) => {
  try {
    dispatch(editTemplateCVByIdStart());
    const response = await callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_API_URL}/cv_builders/${id}`,
      data: TemplateCVData,
    });

    const TemplateCV = response;
    const data = response.data;
    dispatch(editTemplateCVByIdSuccess(TemplateCV));
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(editTemplateCVByIdFailure(error.message));
    dispatch(
      setFeedback({
        status: 'fail',
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.response.data.message };
  }
};

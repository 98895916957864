import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { setFeedback } from "../../store/slices/feedbackSlice";
import { useDispatch } from "react-redux";
import { callApi } from "./commonfunctionforapi";

const LeadLostConformationModal = ({
  leadId,
  showConfirmationModal,
  setShowConfirmationModal,
  setLeadStatus,
  leadStatus,
  setLeadStatusErrorMsg,
  activityDeleted,
}) => {
  const dispatch = useDispatch();
  //   const leadId = selectedLeadId;

  const handleLeadLostClose = () => {
    setLeadStatus(leadStatus);
    setShowConfirmationModal(false);
  };
  const submitHandler = async () => {
    try {
      const response = await callApi({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/leads/deleteactivities/${leadId}`,
      });
      setLeadStatusErrorMsg("");
      activityDeleted();
      // console.log(response.data);
      return { success: true, message: response.data.message };
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      throw new Error(error.response.data.message || "Failed to delete user.");
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showConfirmationModal}
        // onClose={LeaddeleteModalClose}
        closeAfterTransition
      >
        <Fade in={showConfirmationModal}>
          <Box className="modal-wrapper">
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3">
                Are you sure you want to mark lead as Lost?
              </Typography>
              <Typography variant="body1" component="p">
                All future calls and follow-ups will be cancelled for this lead.
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body"></Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={submitHandler}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={handleLeadLostClose}
                >
                  No
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default LeadLostConformationModal;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../components/common/commonfunctionforapi";

export const fetchLeadSettings = createAsyncThunk(
  "leadSettings/fetchLeadSettings",
  async () => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/leadmail-setting`,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
);

const leadSettingsSlice = createSlice({
  name: "leadsetiing",
  initialState: {
    settings: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadSettings.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLeadSettings.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.leadsetiing = action.payload;
      })
      .addCase(fetchLeadSettings.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = leadSettingsSlice.actions;
export default leadSettingsSlice.reducer;

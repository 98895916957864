import React, { useEffect, useRef } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  List,
  TextField,
  Typography,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import '../../styles/cv-builder.scss';
import {
  RiAddCircleLine,
  RiArrowLeftLine,
  RiDeleteBinLine,
} from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';

import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { successToast } from '../../responses/successToast';

import ReactQuill from 'react-quill';
import { fetchProjects } from '../../store/slices/projectSlice';
import ProjectCreate from './projectCreate';
import { Stack } from '@mui/system';
import { createTemplateCV } from '../../store/slices/cv-builder/createTemplateCVSlice';
const TemplateCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [projectForm, setProjectForm] = useState([
    {
      title: '',
      projectUrls: '',
      description: '',
      techStack: '',
      roles: '',
      searchProject: '',
    },
  ]);
  const [accordionExpanded, setAccordionExpanded] = useState(0);
  const handleAccordionChange = (index) => (event, isExpanded) => {
    setAccordionExpanded(isExpanded ? index : false);
  };
  const [templateName, setTemplateName] = useState('');
  const [summary, setSummary] = useState('');
  const [skills, setSkills] = useState('');
  const [otherNotes, setOtherNotes] = useState('');
  const [roles, setRoles] = useState('');
  const [templateNameError, setTemplateNameError] = useState();
  const [projectFormError, setProjectFormError] = useState();
  const [projectExperienceError, setProjectExperienceError] = useState();

  const templateNameRef = useRef();
  const projectExperinceRef = useRef();
  const skillsRef = useRef();

  useEffect(() => {
    setAccordionExpanded(projectForm?.length - 1);
  }, [projectForm?.length]);

  const validateForm = (filteredProjects) => {
    let isValid = true;
    if (!templateName.trim()) {
      setTemplateNameError('Template Name is required.');
      templateNameRef.current.focus();
      isValid = false;
    } else {
      setTemplateNameError('');
    }

    let projectErrors = [];
    filteredProjects.forEach((project, index) => {
      const error = { title: '', description: '' };
      if (!project.title.trim()) {
        error.title = 'Project title is required.';
        isValid = false;
      }
      if (
        !project.description ||
        !project.description.trim() ||
        project.description === '<p></p>' ||
        project.description === '<p><br></p>'
      ) {
        error.description = 'Project Description is required.';
        isValid = false;
      }

      projectErrors[index] = error;
    });
    setProjectFormError(projectErrors);

    return isValid;
  };
  const isEmptyProject = (project) => {
    return (
      (!project.title || !project.title.trim()) &&
      (!project.description ||
        !project.description.trim() ||
        project.description === '<p></p>' ||
        project.description === '<p><br></p>') &&
      (!project.techStack ||
        !project.techStack.trim() ||
        project.techStack === '<ul></ul>' ||
        project.techStack === '<p><br></p>') &&
      (!project.roles ||
        !project.roles.trim() ||
        project.roles === '<p><br></p>') &&
      (!project.projectUrls ||
        !project.projectUrls.trim() ||
        project.projectUrls === '<ul></ul>' ||
        project.projectUrls === '<p><br></p>')
    );
  };
  const submitHandler = async (action) => {
    if (isButtonDisabled) {
      return;
    }
    setAccordionExpanded(null);
    const filteredProjects = projectForm.filter(
      (project) => !isEmptyProject(project)
    );

    if (!validateForm(filteredProjects)) {
      return;
    }
    if (filteredProjects.length <= 0) {
      setProjectExperienceError('Minimum 1 project is required');
      projectExperinceRef.current.focus();
      return;
    }
    setProjectForm(filteredProjects);

    let projects = filteredProjects.map((project) => {
      return {
        roles: project.roles,
        project_title: project.title,
        web_url: project.projectUrls,
        description: project.description,
        techstacks: project.techStack,
      };
    });

    const templateData = {
      template_name: templateName,
      summary,
      skill_and_abilities: skills,
      roles: roles,
      notes: otherNotes,
      is_user_cv: 'false',

      projects: projects,
    };
    setIsButtonDisabled(true);
    try {
      const response = await dispatch(createTemplateCV(templateData));

      if (response.success) {
        if (action === 'createCv') {
          navigate(`/cv-builder/template/${response.data.id}/cv/create`, {
            state: {
              template: response.data,
            },
          });
        } else {
          navigate('/cv-builder', {
            state: {
              template: response.data,
            },
          });
        }

        successToast('Template created successfully!');
      } else {
        console.error('Error creating template:', response.error);
      }
    } catch (error) {
      console.error('Error creating template:', error.message);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  useEffect(() => {
    dispatch(
      fetchProjects({ page: '', status: '', limit: '100000', query: '' })
    );
  }, [dispatch]);
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],

      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
    ],
  };

  const addProjects = () => {
    let data = {
      title: '',
      projectUrls: '',
      description: '',
      techStack: '',
      roles: '',
      searchProject: '',
    };
    setProjectForm((current) => [...current, data]);
  };

  const addProjectData = (key, value, i) => {
    let projectDataArray = [...projectForm];

    projectDataArray[i][key] = value;
    setProjectForm(projectDataArray);
  };

  const handleDeleteProject = (i) => {
    let projectDataArray = [...projectForm];
    projectDataArray.splice(i, 1);
    setProjectForm(projectDataArray);
  };
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(projectForm);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setProjectForm(items);
  };

  return (
    <>
      <Box className='cv-create-container'>
        <Box className='content-header'>
          <Typography variant='h2' component='h2' className='title-with-btn'>
            <Button
              variant='outlined'
              className='action-btn back-btn'
              startIcon={<RiArrowLeftLine />}
              disableRipple
              onClick={() => navigate('/cv-builder')}
            ></Button>
            Create Template
          </Typography>
        </Box>

        <Box className='content-wrapper'>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant='h3' component='h3' className='card-title' />
              <Box className='card-wrapper'>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      variant='h3'
                      component='h3'
                      className='card-title'
                      // ref={bdRef}
                    >
                      Template Details
                    </Typography>
                  </Grid>

                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <Typography variant='body1' component='label'>
                      Template Name
                      <Typography
                        variant='body1'
                        component='span'
                        className='asterisk'
                      >
                        *
                      </Typography>
                    </Typography>
                    <TextField
                      className='input-field'
                      fullWidth
                      id='outlined-basic'
                      label=''
                      variant='outlined'
                      size='small'
                      placeholder='e.g. Python 5 Years Experience'
                      name='template_name'
                      inputRef={templateNameRef}
                      value={templateName}
                      onChange={(e) => {
                        setTemplateName(e.target.value);
                        setTemplateNameError();
                      }}
                    />
                    {templateNameError && (
                      <Typography
                        variant='body1'
                        component='p'
                        className='error'
                      >
                        {templateNameError}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={12} xs={12}></Grid>
                  <Grid item lg={12} md={6} sm={12} xs={12}>
                    <Typography variant='body1' component='label'>
                      Summary
                    </Typography>
                    <Box className='ck-editor-wrapper '>
                      <ReactQuill
                        onChange={(e) => {
                          setSummary(e);
                        }}
                        modules={modules}
                        name='summary'
                        value={summary}
                        className='cv'
                        placeholder={`e.g.
With 5 years of extensive experience as a Fullstack Developer, I demonstrated expertise in a wide spectrum of technologies, covering both frontend and backend development.`}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={6} sm={12} xs={12}>
                    <Typography variant='body1' component='label'>
                      Skills & Abilities
                    </Typography>
                    <Box className='ck-editor-wrapper'>
                      <ReactQuill
                        value={skills}
                        onChange={(e) => {
                          setSkills(e);
                        }}
                        className='cv'
                        ref={skillsRef}
                        modules={modules}
                        placeholder={`e.g.
• Frontend engines and frameworks: React, jQuery Bootstrap`}
                      />
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={6} sm={12} xs={12}>
                    <Typography variant='body1' component='label'>
                      Roles & Responsibilities
                    </Typography>
                    <Box className='ck-editor-wrapper'>
                      <ReactQuill
                        value={roles}
                        modules={modules}
                        onChange={(e) => {
                          setRoles(e);
                        }}
                        style={{ backgroundColor: 'white' }}
                        className='cv'
                        placeholder={`e.g.
• Developed responsive interfaces using React, Next.js, and Node.js. Ensure smooth integration with MySQL database for data management
`}
                      />
                    </Box>
                  </Grid>

                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <div ref={projectExperinceRef} tabIndex={0}>
                      <Typography variant='body1' component='label'>
                        Project Experience
                      </Typography>
                    </div>
                    {projectExperienceError && (
                      <Typography
                        variant='body1'
                        component='p'
                        className='error'
                      >
                        {projectExperienceError}
                      </Typography>
                    )}

                    <Stack container spacing={2} mt={1}>
                      <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId='droppable' type='video'>
                          {(provided) => (
                            <Box
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '14px',
                              }}
                              // sx={ListStyle}
                            >
                              {projectForm.map((element, index) => {
                                return (
                                  <>
                                    <Draggable
                                      key={index}
                                      draggableId={`video-${index}`}
                                      index={index}
                                      type='project' // Make sure to set the type
                                      isDragDisabled={
                                        accordionExpanded === index
                                      }
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                        >
                                          <Box
                                            key={index}
                                            className={
                                              index !== projectForm.length - 1
                                                ? 'add-multi-project'
                                                : 'add-multi-project'
                                            }
                                          >
                                            <Accordion
                                              expanded={
                                                accordionExpanded === index
                                              }
                                              onChange={handleAccordionChange(
                                                index
                                              )}
                                              sx={{
                                                backgroundColor: '#f9f9f9',
                                              }}
                                              className={
                                                projectFormError &&
                                                (projectFormError[index]
                                                  ?.description ||
                                                  projectFormError[index]
                                                    ?.title) &&
                                                `red-border`
                                              }
                                            >
                                              <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                sx={{ paddingLeft: '6px' }}
                                              >
                                                <DragIndicatorIcon
                                                  style={{
                                                    fill:
                                                      accordionExpanded ===
                                                      index
                                                        ? '#ffffff'
                                                        : 'var(--primary-text-color)',
                                                  }}
                                                />
                                                <Typography
                                                  variant='body1'
                                                  component='label'
                                                  sx={{
                                                    fontSize: '16px !important',
                                                  }}
                                                >
                                                  {element.title ||
                                                    '[Select Project]'}
                                                </Typography>
                                              </AccordionSummary>
                                              <AccordionDetails
                                                sx={{ padding: '16px' }}
                                              >
                                                <ProjectCreate
                                                  addProjectData={
                                                    addProjectData
                                                  }
                                                  index={index}
                                                  setProjectForm={
                                                    setProjectForm
                                                  }
                                                  projectForm={projectForm}
                                                  element={element}
                                                  projectErrors={
                                                    projectFormError &&
                                                    projectFormError[index]
                                                  }
                                                  setProjectFormError={
                                                    setProjectFormError
                                                  }
                                                  setProjectExperienceError={
                                                    setProjectExperienceError
                                                  }
                                                />
                                              </AccordionDetails>
                                            </Accordion>
                                            <Box className='action-btn-wrap '>
                                              <Button
                                                variant='outlined'
                                                className='action-btn dlt-btn'
                                                startIcon={<RiDeleteBinLine />}
                                                disableRipple
                                                onClick={() =>
                                                  handleDeleteProject(index)
                                                }
                                                style={{
                                                  position: 'relative',
                                                  top: '4px',
                                                }}
                                              ></Button>
                                            </Box>
                                          </Box>
                                        </div>
                                      )}
                                    </Draggable>
                                  </>
                                );
                              })}
                              {provided.placeholder}
                            </Box>
                          )}
                        </Droppable>
                      </DragDropContext>
                      <Box className='action-btn-wrap '>
                        <Button
                          variant='contained'
                          size='large'
                          className='primary-btn btn add-btn'
                          endIcon={<RiAddCircleLine />}
                          disableRipple
                          sx={{
                            backgroundColor: 'rgb(124, 185, 0)',
                            color: 'white',
                          }}
                          onClick={() => addProjects()}
                        >
                          Add Project
                        </Button>
                      </Box>
                    </Stack>
                  </Grid>

                  <Grid item lg={12} md={6} sm={12} xs={12}>
                    <Typography variant='body1' component='label'>
                      Notes
                    </Typography>
                    <Box className='ck-editor-wrapper'>
                      <ReactQuill
                        value={otherNotes}
                        modules={modules}
                        onChange={(e) => {
                          setOtherNotes(e);
                        }}
                        style={{ backgroundColor: 'white' }}
                        className='cv'
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className='footer-wrapper'>
          <Button
            variant='contained'
            size='large'
            className='primary-btn btn w-120'
            disableRipple
            type='submit'
            onClick={() => submitHandler()}
            disabled={isButtonDisabled}
          >
            Save
          </Button>
          <Button
            variant='contained'
            size='large'
            className='primary-btn btn w-120'
            disableRipple
            type='submit'
            onClick={() => submitHandler('createCv')}
            disabled={isButtonDisabled}
          >
            Create CV
          </Button>
          <Button
            variant='contained'
            size='large'
            className='secondary-btn btn w-120'
            disableRipple
            onClick={() => navigate('/cv-builder')}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TemplateCreate;

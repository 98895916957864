import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Typography, Box, Grid, Divider, Button } from '@mui/material';

import { RiAddCircleLine, RiArrowLeftLine, RiPencilLine } from 'react-icons/ri';

import { fetchTemplateById } from '../../store/slices/cv-builder/templateByIdSlice';
import '../../styles/cv-builder.scss';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/common/loader';

function CvTemplateDetail() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const template = useSelector((state) => state.templatebyId.template);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchTemplateById({ id })).then((response) => {
      setLoading(false);
      if (response?.payload?.deletedAt || response?.error) {
        navigate('/not-found');
      }
    });
  }, [dispatch, id, navigate]);

  return (
    <>
      {!template || loading ? (
        <Loader />
      ) : (
        <>
          {' '}
          <Box className='content-header project-list-header project-module-wrapper'>
            <Typography variant='h2' component='h2' className='title-with-btn'>
              <Button
                variant='outlined'
                className='action-btn back-btn'
                startIcon={<RiArrowLeftLine />}
                disableRipple
                onClick={() =>
                  navigate('/cv-builder', {
                    state: {
                      template: state?.template,
                    },
                  })
                }
              ></Button>
              {template?.template_name}
            </Typography>
            <Box variant='div' component='div' className='content-header-right'>
              <Button
                variant='contained'
                size='large'
                className='primary-btn btn add-btn activity-btn'
                endIcon={<RiAddCircleLine />}
                disableRipple
                onClick={() =>
                  navigate(`/cv-builder/template/${id}/cv/create`, {
                    state: {
                      template: template,
                    },
                  })
                }
              >
                Create CV
              </Button>
              <Button
                variant='outlined'
                size='large'
                className='primary-btn btn only-icon-btn'
                startIcon={<RiPencilLine size={18} />}
                onClick={() =>
                  navigate(`/cv-builder/template/${template.id}/edit`, {
                    state: {
                      template: template,
                    },
                  })
                }
                disableRipple
              ></Button>
            </Box>
          </Box>
          <Box className='content-wrapper cv-builder-wrapper'>
            <Box className='card-wrapper  '>
              <Grid container spacing={2} className='view-info '>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <Typography
                    variant='h3'
                    component='h3'
                    className='card-title'
                  >
                    Template Details
                  </Typography>
                </Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}></Grid>
                <Grid item lg={6} md={12} sm={12} xs={12}>
                  <Typography variant='body1' component='label'>
                    Template Name
                  </Typography>
                  <Typography
                    variant='body1'
                    component='span'
                    className='value'
                    style={{ wordBreak: 'break-all' }}
                  >
                    {template?.template_name}
                  </Typography>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant='body1' component='label'>
                    Summary
                  </Typography>
                  {template?.summary && template?.summary !== '<p><br></p>' ? (
                    <>
                      <Typography
                        variant='body1'
                        component='span'
                        className='value template-view'
                        dangerouslySetInnerHTML={{ __html: template?.summary }}
                      ></Typography>
                    </>
                  ) : (
                    <Typography
                      variant='body1'
                      component='span'
                      className='value template-view'
                    >
                      -
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant='body1' component='label'>
                    Skills & Abilities
                  </Typography>
                  {template?.skill_and_abilities &&
                  template?.skill_and_abilities !== '<p><br></p>' &&
                  template?.skill_and_abilities !== '<ul></ul>' ? (
                    <>
                      <Typography
                        variant='body1'
                        component='span'
                        className='value template-view'
                        dangerouslySetInnerHTML={{
                          __html: template?.skill_and_abilities,
                        }}
                      ></Typography>
                    </>
                  ) : (
                    <Typography
                      variant='body1'
                      component='span'
                      className='value template-view'
                    >
                      -
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant='body1' component='label'>
                    Roles & Responsibilities
                  </Typography>
                  {template?.roles &&
                  template?.roles !== '<p><br></p>' &&
                  template?.roles !== '<ul></ul>' ? (
                    <>
                      <Typography
                        variant='body1'
                        component='span'
                        className='value template-view'
                        dangerouslySetInnerHTML={{
                          __html: template?.roles,
                        }}
                      ></Typography>
                    </>
                  ) : (
                    <Typography
                      variant='body1'
                      component='span'
                      className='value template-view'
                    >
                      -
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant='body1' component='label' mb={2}>
                    Project Experience:
                  </Typography>
                  <ol style={{ paddingInlineStart: '20px' }}>
                    {template?.cv_projects?.map((item, index) => (
                      <li key={index}>
                        <Grid
                          container
                          spacing={2}
                          className='view-info'
                          mb={2}
                        >
                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant='body1' component='label'>
                              {item.project_title}
                            </Typography>
                          </Grid>

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant='body1' component='label'>
                              Project Description
                            </Typography>

                            <Typography
                              variant='body1'
                              component='span'
                              className='value template-view'
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></Typography>
                          </Grid>

                          <Grid
                            item
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            sx={{
                              display: {
                                xs: 'block',
                                sm: 'block',
                                md: 'none',
                                lg: 'none',
                              },
                            }}
                          >
                            <Divider className='divider' />
                          </Grid>

                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography
                              variant='body1'
                              component='label'
                              sx={{ marginBottom: '4px' }}
                            >
                              Technology Stacks
                            </Typography>
                            {item.techstacks &&
                            item.techstacks !== '<ul></ul>' &&
                            item.techstacks !== '<p><br></p>' ? (
                              <Typography
                                variant='body1'
                                component='span'
                                className='value template-view'
                                dangerouslySetInnerHTML={{
                                  __html: item.techstacks,
                                }}
                              ></Typography>
                            ) : (
                              <Typography
                                variant='body1'
                                component='span'
                                className='value template-view'
                              >
                                -
                              </Typography>
                            )}
                          </Grid>

                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Typography variant='body1' component='label'>
                              Links
                            </Typography>
                            {item.web_url !== '' &&
                            item.web_url !== '<ul></ul>' &&
                            item.web_url !== '<p><br></p>' ? (
                              <Typography
                                variant='body1'
                                component='span'
                                className='value template-view'
                                onClick={(e) => {
                                  e.preventDefault();
                          
                                  // Ensure the clicked element is an anchor tag
                                  if (e.target.tagName === 'A') {
                                    let url = e.target.getAttribute('href');
                          
                                    // Remove any curly braces from the URL
                                    url = url.replace(/{|}/g, '');
                                    console.log("url",url)
                          
                                    // Validate the URL
                                    try {
                                      if (/www\./i.test(url)) {
                                        // Remove everything before 'www'
                                        url = url.replace(/^.*www\./, 'www.');
                                        // Prepend 'http://' if not already present
                                        url = `http://${url.replace(/^www\./, '')}`;
                                        
                                    } else if (!/^https?:\/\//i.test(url)) {
                                        // If the URL doesn't start with 'http://' or 'https://', prepend 'http://'
                                        url = `http://${url}`;
                                    } 

                                      // This will throw an error if the URL is invalid
                                      let validatedUrl = new URL(url);

                                      // Open the validated URL in a new tab
                                      window.open(validatedUrl.href, '_blank');
                                    } catch (error) {
                                      console.warn('Invalid URL:', url);
                                      // Optionally, you can show an error message or handle invalid URLs
                                    }
                                  }
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: item.web_url,
                                }}
                              ></Typography>
                            ) : (
                              <Typography
                                variant='body1'
                                component='span'
                                className='value template-view'
                              >
                                -
                              </Typography>
                            )}
                          </Grid>

                          <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Typography variant='body1' component='label'>
                              Roles & Responsibilities
                            </Typography>
                            {item.roles !== '' &&
                            item.roles !== '<ul></ul>' &&
                            item.roles !== '<p><br></p>' ? (
                              <Typography
                                variant='body1'
                                component='span'
                                className='value template-view'
                                dangerouslySetInnerHTML={{
                                  __html: item.roles,
                                }}
                              ></Typography>
                            ) : (
                              <Typography
                                variant='body1'
                                component='span'
                                className='value template-view'
                              >
                                -
                              </Typography>
                            )}
                          </Grid>

                          {index !== template?.cv_projects?.length - 1 && (
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                              <Divider className='divider' />
                            </Grid>
                          )}
                        </Grid>
                      </li>
                    ))}
                  </ol>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Divider />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant='body1' component='label'>
                    Notes
                  </Typography>
                  {template?.notes &&
                  template?.notes !== '<p><br></p>' &&
                  template?.notes !== '<ul></ul>' ? (
                    <>
                      <Typography
                        variant='body1'
                        component='span'
                        className='value template-view'
                        dangerouslySetInnerHTML={{
                          __html: template?.notes,
                        }}
                      ></Typography>
                    </>
                  ) : (
                    <Typography
                      variant='body1'
                      component='span'
                      className='value template-view'
                    >
                      -
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}

export default CvTemplateDetail;

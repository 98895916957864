import React from 'react';

import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from './protectedRoute';

import Login from '../pages/auth/login';
import Dashboard from '../pages/dashboard';

import Projects from '../pages/projects';
import ProjectView from '../pages/projects/projectView';
import ProjectCreate from '../pages/projects/projectCreate';
import ProjectEdit from '../pages/projects/projectEdit';
import ProjectFilterApplied from '../pages/projects/projectFilterApplied';

import Leads from '../pages/leads';
import LeadView from '../pages/leads/leadView';
import LeadCreate from '../pages/leads/leadCreate';
import LeadEdit from '../pages/leads/leadEdit';
import LeadsFilterApplied from '../pages/leads/leadFilterApplied';

import Users from '../pages/users';
import UserView from '../pages/users/userView';

import PageNotFound from '../components/common/pageNotFound';

import Clients from '../pages/client';
import ClientView from '../pages/client/clientView';
import ClientCreate from '../pages/client/clientCreate';
import ClientEdit from '../pages/client/clientEdit';

import Reports from '../pages/reports';
import ReportList from '../pages/reports/reportList';
import getTokenFromCookies from '../utils/token';
import Settings from '../pages/settings';
import KeywordSetting from '../pages/settings/keywordSetting';
import TechnologySetting from '../pages/settings/technologySetting';

import Bids from '../pages/bids';
import BidView from '../pages/bids/bidView';
import BidCreate from '../pages/bids/bidCreate';
import BidEdit from '../pages/bids/bidEdit';
import ReleaseNotes from '../pages/release-notes';
import ActivityList from '../pages/reports/Activity';

import {
  viewDashboard,
  viewProjects,
  updateProjects,
  viewLeads,
  updateLeads,
  viewUsers,
  viewClients,
  updateClients,
  viewReports,
  viewSettings,
  viewBids,
  updateBids,
  viewCVBuider,
  fullAnnouncements
} from '../utils/permissions';
import BidReportList from '../pages/reports/bidReportList';
import ActivitySetting from '../pages/settings/activitySetting';
import LeadReportList from '../pages/reports/leadReportList';
import LeadReport from '../pages/reports/leadReport';
import CVBuilder from '../pages/cv-builder';
import CvTemplateDetail from '../pages/cv-builder/CvTemplateDetail';
import TemplateCreate from '../pages/cv-builder/TemplateCreate';
import CvCreate from '../pages/cv-builder/CvCreate';

import TemplateEdit from '../pages/cv-builder/TemplateEdit';
import CVEdit from '../pages/cv-builder/CVEdit';
import DisplayAnnouncements from "../pages/announcements";

const PublicRouteWrapper = ({ children }) => {
  const token = getTokenFromCookies();

  return !token ? children : <Navigate to='/dashboard' />;
};

const Routes = () => {
  const token = getTokenFromCookies();

  return (
    <>
      <ReactRoutes>
        {/* Auth */}
        <Route
          path='/signin'
          element={
            <PublicRouteWrapper>
              <Login />
            </PublicRouteWrapper>
          }
        />
        <Route
          path='/'
          element={!token ? <Login /> : <Navigate to='/dashboard' />}
        />

        {/* Dashboard */}
        <Route
          path='/dashboard'
          element={
            <ProtectedRoute
              key='dashboard'
              permission={viewDashboard}
              component={<Dashboard />}
            />
          }
        />

        {/* Projects */}
        <Route
          path='/projects'
          element={
            <ProtectedRoute
              key='projects'
              permission={viewProjects}
              component={<Projects />}
            />
          }
        />
        <Route
          path='/projects/:projectId/view'
          element={
            <ProtectedRoute
              key='view-project'
              permission={viewProjects}
              component={<ProjectView />}
            />
          }
        />
        <Route
          path='/projects/create'
          element={
            <ProtectedRoute
              key='create-project'
              permission={updateProjects}
              component={<ProjectCreate />}
            />
          }
        />
        <Route
          path='/projects/:projectId/edit'
          element={
            <ProtectedRoute
              key='edit-project'
              permission={updateProjects}
              component={<ProjectEdit />}
            />
          }
        />
        <Route
          path='/projects/filter-applied'
          element={
            <ProtectedRoute
              key='filter-project'
              permission={viewProjects}
              component={<ProjectFilterApplied />}
            />
          }
        />

        {/* Leads */}
        <Route
          path='/leads'
          element={
            <ProtectedRoute
              key='leads'
              permission={viewLeads}
              component={<Leads />}
            />
          }
        />
        <Route
          path='/leads/:leadId/view'
          element={
            <ProtectedRoute
              key='view-leads'
              permission={viewLeads}
              component={<LeadView />}
            />
          }
        />
        <Route
          path='/leads/create'
          element={
            <ProtectedRoute
              key='create-leads'
              permission={updateLeads}
              component={<LeadCreate />}
            />
          }
        />
        <Route
          path='/leads/:leadId/edit'
          element={
            <ProtectedRoute
              key='edit-leads'
              permission={updateLeads}
              component={<LeadEdit />}
            />
          }
        />
        <Route
          path='/leads/filter-applied'
          element={
            <ProtectedRoute
              key='view-leads'
              permission={viewLeads}
              component={<LeadsFilterApplied />}
            />
          }
        />

        {/* Users */}
        <Route
          path='/users'
          element={
            <ProtectedRoute
              key='users'
              permission={viewUsers}
              component={<Users />}
            />
          }
        />
        <Route
          path='/users/:userId/view'
          element={
            <ProtectedRoute
              key='view-users'
              permission={viewUsers}
              component={<UserView />}
            />
          }
        />

        {/* Clients */}
        <Route
          path='/clients'
          element={
            <ProtectedRoute
              key='clients'
              permission={viewClients}
              component={<Clients />}
            />
          }
        />
        <Route
          path='/clients/:clientId/view'
          element={
            <ProtectedRoute
              key='view-clients'
              permission={viewClients}
              component={<ClientView />}
            />
          }
        />
        <Route
          path='/clients/create'
          element={
            <ProtectedRoute
              key='create-clients'
              permission={updateClients}
              component={<ClientCreate />}
            />
          }
        />
        <Route
          path='/clients/:clientId/edit'
          element={
            <ProtectedRoute
              key='edit-clients'
              permission={updateClients}
              component={<ClientEdit />}
            />
          }
        />

        {/* Reports */}
        <Route
          key='reports'
          path='/reports'
          element={
            <ProtectedRoute permission={viewReports} component={<Reports />} />
          }
        />

        <Route
          key='reports'
          path='/project-allocation-report'
          element={
            <ProtectedRoute permission={{}} component={<ReportList />} />
          }
        />
        <Route
          key='reports'
          path='/bid-report-details'
          element={
            <ProtectedRoute permission={{}} component={<BidReportList />} />
          }
        />
        <Route
          key='reports'
          path='/lead-report-details'
          element={
            <ProtectedRoute permission={{}} component={<LeadReportList />} />
          }
        />
        <Route
          key='reports'
          path='/acitivity-schedule-report'
          element={
            <ProtectedRoute permission={{}} component={<ActivityList />} />
          }
        />
        <Route
          key='reports'
          path='/lead-allocation-report'
          element={
            <ProtectedRoute permission={{}} component={<LeadReport />} />
          }
        />
        <Route
          path='/settings'
          element={
            <ProtectedRoute
              key='settings'
              permission={{}}
              component={<Settings />}
            />
          }
        />
        <Route
          path='/keyword-setting'
          element={
            <ProtectedRoute
              key='keyword-setting'
              permission={viewSettings}
              component={<KeywordSetting />}
            />
          }
        />
        <Route
          path='/technology-stack-setting'
          element={
            <ProtectedRoute
              key='technology-setting'
              permission={viewSettings}
              component={<TechnologySetting />}
            />
          }
        />

        <Route
          path='/activity-setting'
          element={
            <ProtectedRoute
              key='activity-setting'
              permission={viewSettings}
              component={<ActivitySetting />}
            />
          }
        />

        <Route
          path='/bids'
          element={
            <ProtectedRoute
              key='bids'
              permission={viewBids}
              component={<Bids />}
            />
          }
        />

        <Route
          path='/bids/create'
          element={
            <ProtectedRoute
              key='bids-create'
              permission={updateBids}
              component={<BidCreate />}
            />
          }
        />

        <Route
          path='/bids/:bidId/view'
          element={
            <ProtectedRoute
              key='bids-view'
              permission={viewBids}
              component={<BidView />}
            />
          }
        />

        <Route
          path='/bids/:bidId/edit'
          element={
            <ProtectedRoute
              key='bids-edit'
              permission={updateBids}
              component={<BidEdit />}
            />
          }
        />

        {/* cv-builder routes */}
        <Route
          path='/cv-builder'
          element={
            <ProtectedRoute
              key='cv-builder'
              permission={viewCVBuider}
              component={<CVBuilder />}
            />
          }
        />
        <Route
          path='/cv-builder/template/create'
          element={
            <ProtectedRoute
              key='template-create'
              permission={viewCVBuider}
              component={<TemplateCreate />}
            />
          }
        />
        <Route
          path='/cv-builder/template/:id/edit'
          element={
            <ProtectedRoute
              key='template-edit'
              permission={viewCVBuider}
              component={<TemplateEdit />}
            />
          }
        />
        <Route
          path='/cv-builder/template/:id/view'
          element={
            <ProtectedRoute
              key='template-view'
              permission={viewCVBuider}
              component={<CvTemplateDetail />}
            />
          }
        />
        <Route
          path='/cv-builder/template/:id/cv/create'
          element={
            <ProtectedRoute
              key='cv-create'
              permission={viewCVBuider}
              component={<CvCreate />}
            />
          }
        />
        <Route
          path='/cv-builder/template/:id/cv/:cvId/edit'
          element={
            <ProtectedRoute
              key='cv-edit'
              permission={viewCVBuider}
              component={<CVEdit />}
            />
          }
        />

        <Route
          path='/release-notes'
          element={
            <ProtectedRoute
              key='release-notes'
              permission={{}}
              component={<ReleaseNotes />}
            />
          }
        />
        <Route
          path="/announcements"
          element={
            <ProtectedRoute
              key="announcements"
              permission={fullAnnouncements}
              component={<DisplayAnnouncements />}
            />
          }
        />

        {/* Miscellaneous */}
        <Route path='*' element={<PageNotFound key='not-found' />} />
      </ReactRoutes>
    </>
  );
};

export default Routes;

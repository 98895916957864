import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callApi } from "../../components/common/commonfunctionforapi";
import { setFeedback } from "./feedbackSlice";

export const fetchKeywords = createAsyncThunk(
  "keyword/fetchKeywords",
  async ({createdBy, order, query }, { dispatch, rejectWithValue }) => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/keywords`,
        params: {
            createdBy: createdBy,
            order: order,
            query: query,
          },
      });
      return response.data;
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      throw error;
    }
  }
);

const keywordSlice = createSlice({
  name: "keywords",
  initialState: {
    keywords: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchKeywords.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchKeywords.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.keywords = action.payload;
      })
      .addCase(fetchKeywords.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = keywordSlice.actions;
export default keywordSlice.reducer;

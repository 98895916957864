import { Box, CircularProgress, Fade, Modal, Typography } from "@mui/material";
import React from "react";

const ExportLoaderModal = ({ exporting }) => {
  // console.log(leadPayload, "wonSubmit");
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={exporting}
        // onClose={ConvertLeadToProjectModalClose}
        closeAfterTransition
      >
        <Fade in={exporting}>
          <Box className="modal-wrapper">
            <Box variant="div" component="div" className="body">
              <Typography variant="h3" component="h3">
                Please wait while we are exporting your PDF...
              </Typography>
            </Box>
            <Box
              variant="div"
              component="div"
              className="body"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              <CircularProgress />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ExportLoaderModal;

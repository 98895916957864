import { callApi } from "../../components/common/commonfunctionforapi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const fetchReleaseNotes = createAsyncThunk(
  "releasenotes/fetchReleaseNotes",
  async ({ allNotes, app_id, page, rows, timezone }, { rejectWithValue }) => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/release-note`,
        params: {
          allNotes: allNotes,
          app_id: app_id,
          page: page,
          rows: rows,
          timezone: timezone,
        },
      });
      // console.log(response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  releasenotes: [],
  status: "idle",
  error: null,
};
const releaseNotesSlice = createSlice({
  name: "releaseNotes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReleaseNotes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReleaseNotes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.releasenotes = action.payload;
      })

      .addMatcher(
        (action) => action.type.startsWith("releasenotes/fetchReleaseNotes"),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            state.status = "failed";
            state.error = action.payload.message;
          }
        }
      );
  },
});

export default releaseNotesSlice.reducer;

import { callApi } from "../../components/common/commonfunctionforapi";
import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
// export const fetchPermissions = createAsyncThunk(
export const fetchApplicationPermissions = (_) => async (dispatch) => {
  try {
    dispatch(fetchAppPermissionsStart());

    const response = await callApi({
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/users/applications`,
    });

    const data = response.data;
    dispatch(fetchAppPermissionSuccess(data));

    return { success: true, message: data.message };
  } catch (error) {
    dispatch(fetchAppPermissionFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.response.data.message };
  }
};
const initialState = {
  status: "idle",
  error: null,
  applicationPermissions: {},
};
const applicationPermissionSlice = createSlice({
  name: "applicationPermissions",
  initialState,
  reducers: {
    fetchAppPermissionsStart: (state, action) => {
      state.status = "loading";
      state.error = null;
    },
    fetchAppPermissionSuccess: (state, action) => {
      state.status = "succeeded";
      state.applicationPermissions = action.payload;
    },
    fetchAppPermissionFailure: (state, action) => {
      state.status = "failed";
      state.error = action.payload.message;
    },
  },
});

export const {
  setUserAppPermissions,
  fetchAppPermissionsStart,
  fetchAppPermissionSuccess,
  fetchAppPermissionFailure,
} = applicationPermissionSlice.actions;
export default applicationPermissionSlice.reducer;

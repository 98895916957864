import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  user: null,
  loading: false,
  error: null,
};

const editLeadByIdSlice = createSlice({
  name: "editLeadById",
  initialState,
  reducers: {
    editLeadByIdStart(state) {
      state.loading = true;
      state.error = null;
    },
    editLeadByIdSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    editLeadByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { editLeadByIdStart, editLeadByIdSuccess, editLeadByIdFailure } =
  editLeadByIdSlice.actions;
export default editLeadByIdSlice.reducer;

export const editLeadByIdAdmin = (leadId, leadData) => async (dispatch) => {
  try {
    dispatch(editLeadByIdStart());
    const response = await callApi({
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/leads/${leadId}`,
      data: leadData,
    });

    const lead = response;
    const data = response.data;
    dispatch(editLeadByIdSuccess(lead));
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(editLeadByIdFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.response.data.message };
  }
};

import { Box, Grid, Typography } from "@mui/material";
import Marquee from "react-marquee-slider";
import axios from "axios";
import "../../styles/announcement.scss";
import { callApi } from "../../components/common/commonfunctionforapi";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import React from "react";
import CampaignIcon from "@mui/icons-material/Campaign";
import AnnouncementModal2 from "../../components/common/announcementModel2";

const Dashboard = () => {
  const userId = Cookies.get(process.env.REACT_APP_USER_ID_COOKIE);
  const [announcementList, setAnnouncementList] = useState([]);

  async function fetchAllAnnouncements() {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/module/2`,
      });

      setAnnouncementList(response?.data?.marquee_announcements);
    } catch (error) {
      console.error("Failed to fetch announcements:", error);
    }
  }

  useEffect(() => {
    fetchAllAnnouncements();
  }, []);

  const announcements = announcementList?.map((an) => ({
    id: an?.id,
    title: an?.title,
    description: an?.description,
    author: `${an?.authorInfo?.first_name} ${an?.authorInfo?.last_name}`,
    date: an?.createdAt,
  }));

  const [isPaused, setIsPaused] = useState(false);
  const [viewAnnouncementOpen, setViewAnnouncementOpen] = useState(false);
  const [announcement, setAnnouncement] = useState("");
  return (
    <>
      {announcements?.length > 0 && (
        <Box className="announcement-contents">
          <Box
            className="announcement-marque"
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
          >
            <Box
              sx={{
                display: "inline-block",
                paddingRight: "5px",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CampaignIcon
                sx={{
                  color: "rgb(124, 185, 0)",
                  fontSize: { xs: "24px", sm: "32px" },
                }}
              />
            </Box>
            <Box sx={{ flex: 1, minWidth: 0 }}>
              <Marquee
                velocity={
                  isPaused ? 0 : Math.min(25, 300 / announcements?.length)
                }
                direction="rtl"
                resetAfterTries={100}
              >
                {announcements.map((announcement, index) => (
                  <Box key={index} className="marque-list">
                    {/* Bullet point at the start of each announcement */}
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold !important",
                        color: "rgb(35, 35, 35) !important",
                        paddingRight: "10px", // Space after the bullet
                      }}
                    >
                      •
                    </Typography>
                    {/* Announcement text */}
                    <Typography
                      variant="body1"
                      className="marque-text"
                      onClick={async () => {
                        try {
                          setViewAnnouncementOpen(true);
                          setAnnouncement({
                            name: announcement?.title,
                            date: announcement?.date,
                            content: announcement?.description,
                            authorName: announcement?.author,
                          });
                          setIsPaused(false);

                          await axios.patch(
                            `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/announcement/${userId}/read`,
                            {
                              announcement_id: [announcement?.id],
                              is_all_read: false,
                            }
                          );
                        } catch (error) {
                          console.error(
                            "Failed to mark announcement as read:",
                            error
                          );
                        }
                      }}
                    >
                      {announcement?.title}
                    </Typography>
                  </Box>
                ))}
              </Marquee>
            </Box>
          </Box>

          <AnnouncementModal2
            announcement={announcement}
            viewAnnouncementOpen={viewAnnouncementOpen}
            setViewAnnouncementOpen={setViewAnnouncementOpen}
          ></AnnouncementModal2>
        </Box>
      )}
      <Box className="content-wrapper" sx={{ marginTop: "32px" }}>
        <Box className="card-wrapper">
          <Grid container spacing={3}>
            <Grid item lg={12}>
              <Typography
                variant="h3"
                component="h3"
                className="card-title"
                sx={{ marginBottom: "16px" }}
              >
                Project Details
              </Typography>
              <Typography variant="p" component="p">
                Some kind of analytics i.e summary of all applications , total
                no of roles, total no of modules, total no of users etc etc..
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;

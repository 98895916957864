/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Drawer,
  Fade,
  Modal,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  Grid,
  TextareaAutosize,
  Switch,
  FormControlLabel,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import {
  RiAddCircleLine,
  RiFilter2Line,
  RiSearchLine,
  RiCloseLine,
  RiArrowDownSLine,
  RiShareLine,
  RiPencilLine,
  RiDeleteBinLine,
  RiCloseCircleLine,
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import LeadList from "./leadList";
import { createLeadFilter } from "../../store/slices/createLeadFilterSlice";
import { fetchLeadFilters } from "../../store/slices/leadFilterSlice";
import { fetchLeadFiltersById } from "../../store/slices/leadFilterByIdSlice";
import { deleteFilterById } from "../../store/slices/leadFilterSlice";
import { editleadfilterByIdAdmin } from "../../store/slices/editLeadFilterSlice";
import { fetchKeywords } from "../../store/slices/keywordSlice";
import { fetchTechnologies } from "../../store/slices/technologySlice";
import { successToast } from "../../responses/successToast";
import { fetchUsers } from "../../store/slices/userSlice";
import { createShareFilter } from "../../store/slices/sharedFilterSlice";
import WithPermission from "../../components/common/withPermission";
import { updateLeads, fullLeads } from "../../utils/permissions";
import { fetchLeads } from "../../store/slices/leadSlice";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Leads = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      status: 0,
      page: 1,
      rowsPerPage: 10,
      filterApplied: false,
      filterByIdApplied: false,
      applyFilterId: "",
      selectedKeywords: [],
      selectedTLandBDAs: [],
      selectedTechnologies: [],
      text: "",
      converted: "",
      edit: "",
      keywordSwitchVal: true,
      techStackSwitchVal: true,
    };
  }
  const [rowsPerPage, setRowsPerPage] = useState(state.rowsPerPage);
  const [keywordSwitchValue, setKeywordSwitchValue] = useState(
    state.keywordSwitchVal
  );
  const [techStackSwitchValue, settechStackSwitchValue] = useState(
    state.techStackSwitchVal
  );

  const [open, setOpen] = useState(false);
  const [hotCurrentPage, setHotCurrentPage] = useState(1);
  const [hotfilterCurrentPage, setHotfilterCurrentPage] = useState(
    state.filterByIdApplied && state.status === 0 ? state.page : 1
  );
  const [hotapplyfilterCurrentPage, setHotapplyfilterCurrentPage] = useState(
    state.filterApplied && state.status === 0 ? state.page : 1
  );
  const [openCurrentPage, setOpenCurrentPage] = useState(1);
  const [estimationRequestedCurrentPage, setEstimationRequestedCurrentPage] =
    useState(1);
  const [
    estimationfilterRequestedCurrentPage,
    setEstimationfilterRequestedCurrentPage,
  ] = useState(state.filterByIdApplied && state.status === 2 ? state.page : 1);
  const [
    estimationapplyfilterRequestedCurrentPage,
    setEstimationapplyfilterRequestedCurrentPage,
  ] = useState(state.filterApplied && state.status === 2 ? state.page : 1);
  const [estimationProvidedCurrentPage, setEstimationProvidedCurrentPage] =
    useState(1);
  const [
    estimationfilterProvidedCurrentPage,
    setEstimationfilterProvidedCurrentPage,
  ] = useState(state.filterByIdApplied && state.status === 3 ? state.page : 1);
  const [
    estimationapplyfilterProvidedCurrentPage,
    setEstimationapplyfilterProvidedCurrentPage,
  ] = useState(state.filterApplied && state.status === 3 ? state.page : 1);
  const [openfilterCurrentPage, setOpenfilterCurrentPage] = useState(
    state.filterByIdApplied && state.status === 1 ? state.page : 1
  );
  const [openapplyfilterCurrentPage, setOpenapplyfilterCurrentPage] = useState(
    state.filterApplied && state.status === 1 ? state.page : 1
  );
  const [closeCurrentPage, setCloseCurrentPage] = useState(1);
  const [closefilterCurrentPage, setClosefilterCurrentPage] = useState(
    state.filterByIdApplied && state.status === 5 ? state.page : 1
  );
  const [closeapplyfilterCurrentPage, setCloseapplyfilterCurrentPage] =
    useState(state.filterApplied && state.status === 5 ? state.page : 1);
  const [wonCurrentPage, setWonCurrentPage] = useState(1);
  const [wonfilterCurrentPage, setWonfilterCurrentPage] = useState(
    state.filterByIdApplied && state.status === 6 ? state.page : 1
  );
  const [wonapplyfilterCurrentPage, setWonapplyfilterCurrentPage] = useState(
    state.filterApplied && state.status === 6 ? state.page : 1
  );
  const [lostCurrentPage, setLostCurrentPage] = useState(1);
  const [lostCurrentfilterPage, setLostfilterCurrentPage] = useState(
    state.filterByIdApplied && state.status === 7 ? state.page : 1
  );
  const [lostapplyCurrentfilterPage, setLostapplyfilterCurrentPage] = useState(
    state.filterApplied && state.status === 7 ? state.page : 1
  );
  const [standbycurrentpage, setStandByCurrentPage] = useState(1);
  const [standbyfiltercurrentpage, setStandByfilterCurrentPage] = useState(
    state.filterByIdApplied && state.status === 4 ? state.page : 1
  );
  const [standbyapplyfiltercurrentpage, setStandByapplyfilterCurrentPage] =
    useState(state.filterApplied && state.status === 4 ? state.page : 1);
  const [value, setValue] = React.useState(0);
  const [searchUser, setsearchUser] = useState("");
  const keywords = useSelector((state) => state.keywords.keywords);
  const technologies = useSelector((state) => state.technologies.technologies);
  const [selectedKeywords, setSelectedKeywords] = useState(
    state.selectedKeywords
  );
  const [selectedTLandBDAs, setSelectedTLandBDAs] = useState(
    state.selectedTLandBDAs
  );
  const [TLandBDAsPayload, setTLandBDAsPayload] = useState([]);
  const [keywordPayload, setKeywordPayload] = useState([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState(
    state.selectedTechnologies
  );
  const [technologyPayload, setTechnologyPayload] = useState([]);
  const [searchText, setSearchtext] = useState(state.text);
  const [filterName, setFilterName] = useState("");
  const [searchTextError, setSearchTextError] = useState("");
  const [filterNameError, setFilterNameError] = useState("");
  const filters = useSelector((state) => state.filtersLead.filters);
  const [filterId, setFilterId] = useState("");
  const [keywordname, setKeyWordname] = useState([]);
  const [TLandBDAname, setTLandBDAname] = useState([]);
  const [technologyName, setTechnologyName] = useState([]);
  const [filterApplied, setFilterApplied] = useState(state.filterApplied);
  const [filterByIdApplied, setFilterByIdApplied] = useState(
    state.filterByIdApplied
  );
  const [editSearchText, setEditSearchText] = useState("");
  const [editFilterName, setEditFilterName] = useState("");

  const [editFilerId, setEditFilterId] = useState("");
  const [editsearchTextError, setEditSearchTextError] = useState("");
  const [editFilterNameError, setEditFilterNameError] = useState("");
  const [projectvalue, setProjectValue] = useState(state.status);
  const [applyFilterId, setApplyFilterId] = useState(state.applyFilterId);
  const [applyfiltersearchtext, setApplyFilterSearchText] = useState("");

  const [applyfilterkeywordid, setApplyFilterKeywordId] = useState([]);

  const [applyfiltertechnologyid, setApplyFilterTechnologyId] = useState([]);
  const [applyfilterTlAndBdaId, setApplyFilterTlAndBdaId] = useState([]);
  const [removeKeyId, setRemoveKeyId] = useState([]);
  const [removeTLandBdaId, setRemoveTLandBdaId] = useState([]);
  const [removeTechId, setRemoveTechId] = useState([]);
  const [ActivityLog, setActivityLogModal] = useState(false);
  const [editedFilterPayload, setEditedFilterPayload] = useState({
    keyword_id: [],
    technology_id: [],
    tlAndBda_id: [],
  });
  const [applyfilterpayload, setApplyFilterPayload] = useState({
    keyword_id: [],
    technology_id: [],
    tlAndBda_id: [],
  });
  const users = useSelector((state) => state.users.users);
  const [members, setMembers] = useState([]);
  const [memberspayload, setMembersPayload] = useState([]);
  const [sharedcomment, setSharedComment] = useState("");
  const [shareid, setShareId] = useState("");
  const [isShared, setIsShared] = useState(false);
  const [membererror, setMemberError] = useState("");
  const [applyfilterName, setApplyFilterName] = useState("");
  const [estimationlink, setEstimationLink] = useState(state.converted);

  const leadStatus =
    projectvalue === 0
      ? ""
      : projectvalue === 1
      ? "open"
      : projectvalue === 2
      ? "estimation_requested"
      : projectvalue === 3
      ? "estimation_provided"
      : projectvalue === 4
      ? "standBy"
      : projectvalue === 5
      ? "closed"
      : projectvalue === 6
      ? "won"
      : "lost";

  const keywordsData = keywords?.allKeywords?.map((keyword) => ({
    value: keyword.id,
    label: keyword.name,
  }));

  const technologiesData = technologies?.allTechnologies?.map((technology) => ({
    value: technology.id,
    label: technology.name,
  }));
  const handleKeywordMatch = (event) => {
    setKeywordSwitchValue(event.target.checked);
  };
  const handleKeywordSelect = (selectedOptions) => {
    setSelectedKeywords(selectedOptions);
    if (selectedKeywords) {
      setSearchTextError("");
      setEditSearchTextError("");
    }
    if (selectedOptions.length === 0) {
      // Clear the technologyName state when no technology is selected
      setKeyWordname("");
    }

    const keywordNames = selectedOptions
      .map((option) => option.label)
      .join(",");
    setKeyWordname(keywordNames);
    // Construct the comma-separated list of selected IDs for the payload
    const selectedIds = selectedOptions.map((option) => option.value);
    setKeywordPayload(selectedIds);
    setEditedFilterPayload((prevPayload) => ({
      ...prevPayload,
      keyword_id: selectedIds,
    }));
  };
  const handleTLandBdaSelect = (selectedOptions) => {
    setSelectedTLandBDAs(selectedOptions);
    if (selectedTLandBDAs) {
      setSearchTextError("");
      setEditSearchTextError("");
    }
    if (selectedOptions.length === 0) {
      // Clear the technologyName state when no technology is selected
      setTLandBDAname("");
    }

    const TLandBDANames = selectedOptions
      .map((option) => option.label)
      .join(",");
    setTLandBDAname(TLandBDANames);
    // Construct the comma-separated list of selected IDs for the payload
    const selectedIds = selectedOptions.map((option) => option.value);

    setTLandBDAsPayload(selectedIds);
    setEditedFilterPayload((prevPayload) => ({
      ...prevPayload,
      tlAndBda_id: selectedIds,
    }));
  };

  const handleTechnologySelect = (selectedOptions) => {
    setSelectedTechnologies(selectedOptions);
    if (selectedTechnologies) {
      setSearchTextError("");
      setEditSearchTextError("");
    }
    if (selectedOptions.length === 0) {
      setTechnologyName("");
    }

    const technologyNames = selectedOptions
      .map((option) => option.label)
      .join(",");

    setTechnologyName(technologyNames);
    // Construct the comma-separated list of selected IDs for the payload
    const selectedIds = selectedOptions.map((option) => option.value);

    setTechnologyPayload(selectedIds);
    setEditedFilterPayload((prevPayload) => ({
      ...prevPayload,
      technology_id: selectedIds,
    }));
  };
  const handleTechStackMatch = (event) => {
    settechStackSwitchValue(event.target.checked);
  };
  function capitalizeWords(input) {
    return input?.replace(/\w+/g, function (word) {
      return word?.charAt(0).toUpperCase() + word.slice(1);
    });
  }

  function capitalizeFirstLetter(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }

  const userRoleArray = users?.loginuser?.map(
    (login) => login?.roles?.role_name
  );
  const userRole = userRoleArray ? userRoleArray[0] : null;

  const loggedInUserId = users?.loginuser?.map((login) => login.id);

  const memberOptions = users?.AllTlandBda?.map((user) => ({
    value: user.id,
    label: `${user.first_name} ${user.last_name}`,
  }));

  const handleOptionChange = (selectedOptions) => {
    if (selectedOptions) {
      setMemberError("");
    }
    setMembers(selectedOptions);
    const selectedIds = selectedOptions
      ?.map((option) => option.value)
      .join(",");
    setMembersPayload(selectedIds);
  };

  const handleShareModal = async () => {
    if (filtersbyid?.sharedallusers?.length === 0) {
      if (memberspayload.length === 0 && members.length === 0) {
        setMemberError("Please Select any one member");
        return;
      }
    }
    await dispatch(
      createShareFilter({
        user_id: memberspayload,
        filter_id: shareid,
        filter_type: "leadfilters",
        shared_comment: sharedcomment,
        user_delete: removeKeyId,
      })
    );
    setActivityLogModal(false);
    successToast("Filter Shared Successfully");
    dispatch(fetchLeadFilters({ filter_type: "leads" }));
    setMembers([]);
    setMembersPayload([]);
    setSharedComment("");
  };

  const handleShareFilterModal = (id) => {
    dispatch(
      fetchLeadFiltersById({
        filterId: id,
        status: "",
      })
    );
    if (filtersbyid?.sharedallusers?.length > 0) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
    setActivityLogModal(true);
    setShareId(id);
  };

  const [filteredProjects, setFilteredprojects] = useState([]);
  const [filteredByIdProjects, setFilteredByIdProjects] = useState([]);
  const [isEditing, setIsEditing] = useState(state.edit);
  const filtersbyid = useSelector((state) => state.filtersLeadById.filters);
  // let filtersbyid = filterByIdApplied || isEditing ? filtersbyidVal : null;
  const [projectKeywords, setprojectKeywords] = useState([]);
  const [projectTechnologies, setProjectTechnologies] = useState([]);
  const [projectTLandBDAs, setProjectTLandBDAs] = useState([]);
  const [hotleadProjects, setHotLeadProjects] = useState([]);
  const [hotLeadApplyProjects, setHotLeadApplyProjects] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const totalFilters =
    (filters?.getallfilter?.length || 0) + (filters?.shareddatas?.length || 0);
  const handleChange = (event, newValue) => {
    setKeywordPayload([]);
    setTechnologyPayload([]);
    setTLandBDAsPayload([]);
    setprojectKeywords([]);
    setProjectTLandBDAs([]);
    setProjectTechnologies([]);
    setFilterName("");
    setEditFilterName("");
    setEditSearchText("");
    setIsEditing(false);
    setValue(newValue);
  };

  const filterData = {
    search_text: searchText,
    filter_name: filterName,
    user_id: TLandBDAsPayload,
    technology_id: technologyPayload,
    keyword_id: keywordPayload,
    keywordSwitchValue: keywordSwitchValue ? "and" : "or",
    techStackSwitchValue: techStackSwitchValue ? "and" : "or",
    estimationprovidedlead: estimationlink === true ? "yes" : "no",
  };

  const handleClose = () => {
    if (!filterApplied) {
      setSearchtext("");
      setSelectedKeywords([]);
      setKeywordPayload([]);
      setSelectedTLandBDAs([]);
      setTLandBDAsPayload([]);
      setSelectedTechnologies([]);
      setTechnologyPayload([]);
      setEstimationLink(false);
      setKeywordSwitchValue(true);
      settechStackSwitchValue(true);
    }
    if (filterByIdApplied) {
      dispatch(
        fetchLeadFiltersById({
          filterId: applyFilterId,
          status: leadStatus,
          page:
            projectvalue === 0
              ? hotfilterCurrentPage
              : projectvalue === 1
              ? openfilterCurrentPage
              : projectvalue === 2
              ? estimationfilterRequestedCurrentPage
              : projectvalue === 3
              ? estimationfilterProvidedCurrentPage
              : projectvalue === 4
              ? standbyfiltercurrentpage
              : projectvalue === 5
              ? closefilterCurrentPage
              : projectvalue === 6
              ? wonfilterCurrentPage
              : lostCurrentfilterPage,
          limit: +rowsPerPage,
        })
      );
    }

    setprojectKeywords([]);
    setProjectTechnologies([]);
    setProjectTLandBDAs([]);
    setFilterName("");
    setEditFilterName("");
    setEditSearchText("");

    setOpen(false);
    setIsEditing(false);
  };
  const handleEditFilterSave = async () => {
    if (!editFilterName) {
      setEditFilterNameError("Please Enter Filter Name");
      return;
    }
    setIsEditing(true);
    setSaveFilterModal(false);

    successToast("Filter Updated Successfully");
    setValue(1);
    dispatch(fetchLeadFilters({ filter_type: "leads" }));
  };
  const handleFiltersave = async () => {
    setIsEditing(false);
    if (isButtonDisabled) {
      return;
    }
    if (!filterName) {
      setFilterNameError("Please Enter Filter Name");
      return;
    }
    setIsButtonDisabled(true);
    try {
      const response = await dispatch(createLeadFilter(filterData));

      if (response.success) {
        setSaveFilterModal(false);
        successToast("Filter Created Successfully");
        setValue(1);
        dispatch(fetchLeadFilters({ filter_type: "leads" }));
      } else {
        console.error("Error creating user:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      setIsButtonDisabled(false);
    }
  };

  const handleSave = () => {
    setIsEditing(false);
    setSearchTextError("");
    if (
      !searchText &&
      selectedKeywords?.length === 0 &&
      selectedTLandBDAs?.length === 0 &&
      selectedTechnologies?.length === 0 &&
      estimationlink === false
    ) {
      setSearchTextError("Please Select any one field");
      return;
    }
    setSaveFilterModal(true);
  };
  const handleUpdate = async () => {
    setIsEditing(true);
    if (isButtonDisabled) {
      return;
    }

    const editFilterData = {
      search_text: searchText,
      filter_name: editFilterName,
      estimationprovidedlead: estimationlink === true ? "yes" : "no",
      keywordSwitchValue: keywordSwitchValue ? "and" : "or",
      techStackSwitchValue: techStackSwitchValue ? "and" : "or",

      ...editedFilterPayload,
    };
    setIsButtonDisabled(true);
    if (filterByIdApplied) {
      setSaveFilterModal(false);
      setIsEditing(true);
      try {
        const response = await dispatch(
          editleadfilterByIdAdmin({
            editFilerId: applyFilterId,
            editFilterData: editFilterData,
            status: leadStatus,
          })
        );

        if (response.success) {
          successToast("Filter updated successfully!");
          // eslint-disable-next-line no-lone-blocks
          {
            isShared ? setValue(2) : setValue(1);
          }
          dispatch(fetchLeadFilters({ filter_type: "leads" }));
          dispatch(
            fetchLeadFiltersById({
              filterId: applyFilterId,
              status: leadStatus,
            })
          );
        } else {
          console.error("Error creating user:", response.error);
        }
      } catch (error) {
        console.error("Error creating lead:", error.message);
      } finally {
        // Enable the save button after the API call is completed
        setIsButtonDisabled(false);
      }
    }

    if (!filterByIdApplied) {
      setIsEditing(true);
      if (isButtonDisabled) {
        return;
      }
      setSaveFilterModal(false);
      setIsButtonDisabled(true);
      try {
        const response = await dispatch(
          editleadfilterByIdAdmin({
            editFilerId,
            editFilterData,
            status: leadStatus,
          })
        );

        if (response.success) {
          successToast("Filter updated successfully!");
          // eslint-disable-next-line no-lone-blocks
          {
            isShared ? setValue(2) : setValue(1);
          }
          dispatch(fetchLeadFilters({ filter_type: "leads" }));
          dispatch(
            fetchLeadFiltersById({
              filterId: applyFilterId,
              status: leadStatus,
            })
          );
        } else {
          console.error("Error creating user:", response.error);
        }
      } catch (error) {
        console.error("Error creating lead:", error.message);
      } finally {
        // Enable the save button after the API call is completed
        setIsButtonDisabled(false);
      }
    }
  };

  const handleShareFilterById = async (id) => {
    setIsShared(true);
    setIsEditing(false);
    setFilterByIdApplied(true);
    setOpen(!open);
    setApplyFilterId(id);
    await dispatch(
      fetchLeadFiltersById({
        filterId: id,
        status: leadStatus,
        page:
          projectvalue === 0
            ? hotfilterCurrentPage
            : projectvalue === 1
            ? openfilterCurrentPage
            : projectvalue === 2
            ? estimationfilterRequestedCurrentPage
            : projectvalue === 3
            ? estimationfilterProvidedCurrentPage
            : projectvalue === 4
            ? standbyfiltercurrentpage
            : projectvalue === 5
            ? closefilterCurrentPage
            : projectvalue === 6
            ? wonfilterCurrentPage
            : lostCurrentfilterPage,
        limit: +rowsPerPage,
      })
    );
  };

  const handleDeleteModal = (id) => {
    setFilterId(id);
    setDeleteSavedFilterModal(true);
  };

  const deleteSubmitHandler = async () => {
    await dispatch(deleteFilterById(filterId));

    setDeleteSavedFilterModal(false);
    dispatch(fetchLeadFilters({ filter_type: "leads" }));
  };

  const handleDrawer = async () => {
    setSearchTextError("");
    setOpen(!open);
    if (!filterByIdApplied && !filterApplied) {
      setSearchtext("");
      setSelectedKeywords([]);
      setSelectedTLandBDAs([]);
      setSelectedTechnologies([]);
      setEstimationLink(false);
    }
    if (!filterApplied) {
      setSearchtext("");
      setKeyWordname("");
      setTechnologyName("");
      setTLandBDAname("");
    }

    setprojectKeywords([]);
    setProjectTechnologies([]);
    setProjectTLandBDAs([]);
    setFilterName("");
    setEditFilterName("");
    setEditSearchText("");

    setValue(0);
    if (filterByIdApplied) {
      await dispatch(
        fetchLeadFiltersById({
          filterId: applyFilterId,
          status: leadStatus,
          page:
            projectvalue === 0
              ? hotfilterCurrentPage
              : projectvalue === 1
              ? openfilterCurrentPage
              : projectvalue === 2
              ? estimationfilterRequestedCurrentPage
              : projectvalue === 3
              ? estimationfilterProvidedCurrentPage
              : projectvalue === 4
              ? standbyfiltercurrentpage
              : projectvalue === 5
              ? closefilterCurrentPage
              : projectvalue === 6
              ? wonfilterCurrentPage
              : lostCurrentfilterPage,
          limit: +rowsPerPage,
        })
      );
    }
  };

  const handleApplyFilter = async () => {
    setsearchUser("");
    setHotapplyfilterCurrentPage(1);
    setOpenapplyfilterCurrentPage(1);
    setEstimationapplyfilterRequestedCurrentPage(1);
    setEstimationapplyfilterProvidedCurrentPage(1);
    setWonapplyfilterCurrentPage(1);
    setLostapplyfilterCurrentPage(1);
    setStandByapplyfilterCurrentPage(1);
    setCloseapplyfilterCurrentPage(1);

    if (
      !searchText &&
      selectedKeywords?.length === 0 &&
      selectedTLandBDAs?.length === 0 &&
      selectedTechnologies?.length === 0 &&
      estimationlink === false
    ) {
      setSearchTextError("Please select one field");
      return;
    }
    await dispatch(
      fetchLeadFilters({
        text: searchText,
        keyword: Array.isArray(selectedKeywords)
          ? selectedKeywords.map((option) => option.label).join(",")
          : "",
        tlAndBda: Array.isArray(selectedTLandBDAs)
          ? selectedTLandBDAs.map((option) => option.value).join(",")
          : "",
        technologies: Array.isArray(selectedTechnologies)
          ? selectedTechnologies.map((option) => option.label).join(",")
          : "",
        keywordSwitchValue: keywordSwitchValue ? "and" : "or",
        techStackSwitchValue: techStackSwitchValue ? "and" : "or",
        status: leadStatus,
        estimationprovidedlead: estimationlink === true ? "yes" : "no",
        page: 1,
        limit: +rowsPerPage,
      })
    )
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          setFilterApplied(true);
          setOpen(false);
        } else {
          console.error("Error creating user:", response.error);
        }
      })
      .catch((error) => {
        console.error("Error creating user:", error.message);
      });
    if (!filterApplied) {
      setKeyWordname("");
      setTechnologyName("");
      setTLandBDAname("");
    }
  };

  const handleValueChange = async (newValue) => {
    setWonapplyfilterCurrentPage(1);
    setHotapplyfilterCurrentPage(1);
    setOpenapplyfilterCurrentPage(1);
    setEstimationapplyfilterProvidedCurrentPage(1);
    setEstimationapplyfilterRequestedCurrentPage(1);
    setLostapplyfilterCurrentPage(1);
    setCloseapplyfilterCurrentPage(1);
    setStandByapplyfilterCurrentPage(1);
    setProjectValue(newValue);
  };

  const handleSwitchChange = () => {
    setEstimationLink(!estimationlink);
    if (estimationlink === false) {
      setSearchTextError("");
    }
  };

  const handleFilterById = async (id) => {
    setsearchUser("");
    setIsShared(false);
    setFilterByIdApplied(true);
    setApplyFilterId(id);
    setOpen(!open);
    await dispatch(
      fetchLeadFiltersById({
        filterId: id,
        status: leadStatus,
        page:
          projectvalue === 0
            ? hotfilterCurrentPage
            : projectvalue === 1
            ? openfilterCurrentPage
            : projectvalue === 2
            ? estimationfilterRequestedCurrentPage
            : projectvalue === 3
            ? estimationfilterProvidedCurrentPage
            : projectvalue === 4
            ? standbyfiltercurrentpage
            : projectvalue === 5
            ? closefilterCurrentPage
            : projectvalue === 6
            ? wonfilterCurrentPage
            : lostCurrentfilterPage,
        limit: +rowsPerPage,
      })
    );
  };

  const handleEditFilter = async (id) => {
    setEditFilterId(id);
    // setFilterByIdApplied(true);
    setValue(0);
    setIsEditing(true);
    await dispatch(
      fetchLeadFiltersById({
        filterId: id,
        status: leadStatus,
      })
    );
  };

  const handleClearFilter = () => {
    setEditSearchText("");
    setprojectKeywords([]);
    setProjectTechnologies([]);
    setProjectTLandBDAs([]);
    setKeywordSwitchValue(true);
    settechStackSwitchValue(true);
    setEditedFilterPayload({
      keyword_id: [],
      technology_id: [],
      tlAndBda_id: [],
    });
    setSearchtext("");
    setKeywordPayload([]);
    setTechnologyPayload([]);
    setTLandBDAsPayload([]);
    setSelectedKeywords([]);
    setSelectedTechnologies([]);
    setSelectedTLandBDAs([]);
    setEstimationLink(false);
    setKeyWordname([]);
    setTechnologyName([]);
    setTLandBDAname([]);
    if (filterByIdApplied) {
      setApplyFilterSearchText("");

      setApplyFilterPayload({
        keyword_id: [],
        technology_id: [],
        tlAndBda_id: [],
      });
      setApplyFilterKeywordId([]);
      setApplyFilterTechnologyId([]);
      setApplyFilterTlAndBdaId([]);
    }
  };
  useEffect(() => {
    if (filterApplied) {
      dispatch(
        fetchLeadFilters({
          text: searchText,
          keyword: Array.isArray(selectedKeywords)
            ? selectedKeywords.map((option) => option.label).join(",")
            : "",
          tlAndBda: Array.isArray(selectedTLandBDAs)
            ? selectedTLandBDAs.map((option) => option.value).join(",")
            : "",
          technologies: Array.isArray(selectedTechnologies)
            ? selectedTechnologies.map((option) => option.label).join(",")
            : "",
          keywordSwitchValue: keywordSwitchValue ? "and" : "or",
          techStackSwitchValue: techStackSwitchValue ? "and" : "or",
          status: leadStatus,
          estimationprovidedlead: estimationlink === true ? "yes" : "no",
          page:
            projectvalue === 0
              ? hotapplyfilterCurrentPage
              : projectvalue === 1
              ? openapplyfilterCurrentPage
              : projectvalue === 2
              ? estimationapplyfilterRequestedCurrentPage
              : projectvalue === 3
              ? estimationapplyfilterProvidedCurrentPage
              : projectvalue === 4
              ? standbyapplyfiltercurrentpage
              : projectvalue === 5
              ? closeapplyfilterCurrentPage
              : projectvalue === 6
              ? wonapplyfilterCurrentPage
              : lostapplyCurrentfilterPage,
          limit: +rowsPerPage,
        })
      );
    }
    if (filterByIdApplied) {
      dispatch(
        fetchLeadFiltersById({
          filterId: applyFilterId,
          status: leadStatus,
          page:
            projectvalue === 0
              ? hotfilterCurrentPage
              : projectvalue === 1
              ? openfilterCurrentPage
              : projectvalue === 2
              ? estimationfilterRequestedCurrentPage
              : projectvalue === 3
              ? estimationfilterProvidedCurrentPage
              : projectvalue === 4
              ? standbyfiltercurrentpage
              : projectvalue === 5
              ? closefilterCurrentPage
              : projectvalue === 6
              ? wonfilterCurrentPage
              : lostCurrentfilterPage,
          limit: +rowsPerPage,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    projectvalue,
    leadStatus,
    openapplyfilterCurrentPage,
    hotapplyfilterCurrentPage,
    estimationapplyfilterRequestedCurrentPage,
    estimationapplyfilterProvidedCurrentPage,
    closeapplyfilterCurrentPage,
    wonapplyfilterCurrentPage,
    lostapplyCurrentfilterPage,
    standbyapplyfiltercurrentpage,
    hotfilterCurrentPage,
    openfilterCurrentPage,
    estimationfilterRequestedCurrentPage,
    estimationfilterProvidedCurrentPage,
    closefilterCurrentPage,
    wonfilterCurrentPage,
    lostCurrentfilterPage,
    standbyfiltercurrentpage,
    rowsPerPage,
  ]);

  useEffect(() => {
    if (filtersbyid === null) {
      return;
    }

    setSearchtext(filtersbyid?.filter?.search_text);
    setEditFilterName(filtersbyid?.filter?.filter_name);
    setKeywordSwitchValue(
      filtersbyid?.filter?.keywordMatch === "and" ? true : false
    );
    settechStackSwitchValue(
      filtersbyid?.filter?.technologyStackMatch === "and" ? true : false
    );
    const initialKeywordOptions = filtersbyid?.keyword?.map((keywordName) => ({
      value: keywordName.keyword_id,
      label: keywordName.keywords?.keyword_name,
    }));
    setEstimationLink(
      filtersbyid?.filter?.estimationprovidedlead === "yes" ? true : false
    );
    setSelectedKeywords(initialKeywordOptions);
    const initialtechnologyOptions = filtersbyid?.technologyStacks?.map(
      (technologyName) => ({
        value: technologyName.technology_id,
        label: technologyName.technologies?.technology_name,
      })
    );
    setSelectedTechnologies(initialtechnologyOptions);
    const initialTLandBdaOptions = filtersbyid?.lead_filter_users?.map(
      (user) => ({
        value: user.user_id,
        label:
          user.lead_assignees?.first_name +
          " " +
          user.lead_assignees?.last_name,
      })
    );
    setSelectedTLandBDAs(initialTLandBdaOptions);
    setEditedFilterPayload((prevPayload) => ({
      ...prevPayload,
      keyword_id: initialKeywordOptions?.map((option) => option.value),
      technology_id: initialtechnologyOptions?.map((option) => option.value),
      tlAndBda_id: initialTLandBdaOptions?.map((option) => option.value),
    }));
    if (filterByIdApplied) {
      setApplyFilterName(filtersbyid?.filter?.filter_name);
      const initialKeywordOptions = filtersbyid?.keyword?.map(
        (keywordName) => ({
          value: keywordName.keyword_id,
          label: keywordName.keywords?.keyword_name,
        })
      );
      setSelectedKeywords(initialKeywordOptions || []);
      const initialtechnologyOptions = filtersbyid?.technologyStacks?.map(
        (technologyName) => ({
          value: technologyName.technology_id,
          label: technologyName.technologies?.technology_name,
        })
      );
      setSelectedTechnologies(initialtechnologyOptions || []);
      const initialTLandBdaOptions = filtersbyid?.lead_filter_users?.map(
        (user) => ({
          value: user.user_id,
          label:
            user.lead_assignees?.first_name +
            " " +
            user.lead_assignees?.last_name,
        })
      );
      setSelectedTLandBDAs(initialTLandBdaOptions || []);
      setApplyFilterPayload((prevPayload) => ({
        ...prevPayload,
        keyword_id: initialKeywordOptions?.map((option) => option.value),
        technology_id: initialtechnologyOptions?.map((option) => option.value),
        tlAndBda_id: initialTLandBdaOptions?.map((option) => option.value),
      }));
      setEstimationLink(
        filtersbyid?.filter?.estimationprovidedlead === "yes" ? true : false
      );
    }
  }, [isEditing, filtersbyid, filterByIdApplied]);

  useEffect(() => {
    dispatch(fetchKeywords({ query: "" }));
    dispatch(fetchTechnologies({ query: "" }));
  }, [dispatch]);
  useEffect(() => {
    setFilteredprojects(filters?.leads);
    setHotLeadApplyProjects(filters?.filterdhotleadData);
  }, [filters]);

  useEffect(() => {
    setFilteredByIdProjects(filtersbyid?.lead);
    setHotLeadProjects(filtersbyid?.filterdhotleadData);
    if (filtersbyid?.sharedallusers) {
      const users = filtersbyid?.sharedallusers?.map((user) => ({
        value: user?.users?.id,
        label: `${user?.users?.first_name} ${user?.users?.last_name}`,
      }));
      setMembers(users);
      const projectfiltersComment =
        filtersbyid?.sharedallusers[0]?.lead_filters?.shared_comment;
      setSharedComment(projectfiltersComment);
    }
  }, [filtersbyid, isEdit]);

  useEffect(() => {
    if (!filterApplied && !filterByIdApplied) {
      dispatch(fetchLeadFilters({ filter_type: "leads" }));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchUsers({
        page: "",
        limit: "",
        query: "",
      })
    );
  }, [dispatch]);

  // SIDEBAR DRAWER TABBING PANEL
  const handleHotPageChange = (newPage) => {
    setHotCurrentPage(newPage);
  };
  const handlefilterHotPageChange = (newPage) => {
    setHotfilterCurrentPage(newPage);
  };
  const handleapplyfilterHotPageChange = (newPage) => {
    setHotapplyfilterCurrentPage(newPage);
  };
  const handlefilterOpenPageChange = (newPage) => {
    setOpenfilterCurrentPage(newPage);
  };
  const handleapplyfilterOpenPageChange = (newPage) => {
    setOpenapplyfilterCurrentPage(newPage);
  };
  const handleOpenPageChange = (newPage) => {
    setOpenCurrentPage(newPage);
  };

  const handleEstimationReqPageChange = (newPage) => {
    setEstimationRequestedCurrentPage(newPage);
  };
  const handlefilterEstimationReqPageChange = (newPage) => {
    setEstimationfilterRequestedCurrentPage(newPage);
  };
  const handleapplyfilterEstimationReqPageChange = (newPage) => {
    setEstimationapplyfilterRequestedCurrentPage(newPage);
  };
  const handleEstimationProvPageChange = (newPage) => {
    setEstimationProvidedCurrentPage(newPage);
  };
  const handlefilterEstimationProvPageChange = (newPage) => {
    setEstimationfilterProvidedCurrentPage(newPage);
  };
  const handleapplyfilterEstimationProvPageChange = (newPage) => {
    setEstimationapplyfilterProvidedCurrentPage(newPage);
  };
  const handleClosedPageChange = (newPage) => {
    setCloseCurrentPage(newPage);
  };
  const handlefilterClosedPageChange = (newPage) => {
    setClosefilterCurrentPage(newPage);
  };
  const handleapplyfilterClosedPageChange = (newPage) => {
    setCloseapplyfilterCurrentPage(newPage);
  };
  const handleWonPageChange = (newPage) => {
    setWonCurrentPage(newPage);
  };
  const handlefilterWonPageChange = (newPage) => {
    setWonfilterCurrentPage(newPage);
  };
  const handleapplyfilterWonPageChange = (newPage) => {
    setWonapplyfilterCurrentPage(newPage);
  };
  const handleLostPageChange = (newPage) => {
    setLostCurrentPage(newPage);
  };
  const handlefilterLostPageChange = (newPage) => {
    setLostfilterCurrentPage(newPage);
  };
  const handleapplyfilterLostPageChange = (newPage) => {
    setLostapplyfilterCurrentPage(newPage);
  };
  const handleStandByPageChange = (newpage) => {
    setStandByCurrentPage(newpage);
  };
  const handlefilterStandByPageChange = (newpage) => {
    setStandByfilterCurrentPage(newpage);
  };
  const handleapplyfilterStandByPageChange = (newpage) => {
    setStandByapplyfilterCurrentPage(newpage);
  };
  // START :: EXPORT TO PDF MODAL
  const [SaveFilter, setSaveFilterModal] = useState(false);
  // END :: EXPORT TO PDF MODAL

  // START :: EXPORT TO PDF MODAL
  const [DeleteSavedFilter, setDeleteSavedFilterModal] = useState(false);
  // END :: EXPORT TO PDF MODAL

  const handleSearchLeads = (event) => {
    if (
      hotCurrentPage > 1 ||
      openCurrentPage > 1 ||
      estimationRequestedCurrentPage > 1 ||
      estimationProvidedCurrentPage > 1 ||
      closeCurrentPage > 1 ||
      wonCurrentPage > 1 ||
      lostCurrentPage ||
      standbycurrentpage > 1
    ) {
      setHotCurrentPage(1);
      setOpenCurrentPage(1);
      setEstimationRequestedCurrentPage(1);
      setEstimationProvidedCurrentPage(1);
      setCloseCurrentPage(1);
      setWonCurrentPage(1);
      setLostCurrentPage(1);
      setStandByCurrentPage(1);
    }
    setsearchUser(event.target.value);
    setFilterApplied(false);
    setFilterByIdApplied(false);
  };

  const clearSearchHandler = () => {
    setsearchUser("");
    localStorage.setItem("searchLeadsQuery", JSON.stringify(""));
  };

  const storeLeadsSearchInLocalStorage = () => {
    // Store search text in localStorage as a JSON string
    localStorage.setItem("searchLeadsQuery", JSON.stringify(searchUser));
  };

  useEffect(() => {
    // Load search text from localStorage on component mount
    const savedSearchText = localStorage.getItem("searchLeadsQuery");
    if (savedSearchText) {
      setsearchUser(JSON.parse(savedSearchText));
    }
    localStorage.setItem("searchBidsQuery", JSON.stringify(""));
    localStorage.setItem("searchClientsQuery", JSON.stringify(""));
    localStorage.setItem("searchProjectsQuery", JSON.stringify(""));
    localStorage.setItem("searchUsersQuery", JSON.stringify(""));
  }, []);

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 55,
    height: 30,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="25" viewBox="0 0 20 20"><text x="3.5" y="15" fill="${encodeURIComponent(
            "#fff"
          )}" font-size="12"  font-family="Arial, Helvetica, sans-serif">All</text></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 26,
      height: 26,
      "&::before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="25" viewBox="0 0 20 20"><text x="0" y="15" fill="${encodeURIComponent(
          "#fff"
        )}" font-size="12" font-family="Arial, Helvetica, sans-serif">Any</text></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  return (
    <>
      {/*  START :: LEADS LISTING VIEW */}
      <Box className="content-header project-list-header lead-module-wrapper">
        <Box className="filter-apply-wrapper">
          <Typography variant="h2" component="h2">
            Leads
          </Typography>

          {(filterApplied || filterByIdApplied) && (
            <>
              <Typography variant="h2" component="h2">
                {" "}
                - &nbsp;
                {filterByIdApplied ? filtersbyid?.filter?.filter_name : ""}
              </Typography>
              <Button
                variant="contained"
                size="large"
                className="outline-btn btn add-btn"
                endIcon={<RiCloseLine />}
                disableRipple
                onClick={() => {
                  setFilterApplied(false);
                  setFilterByIdApplied(false);
                  setSearchtext("");
                  setSelectedKeywords("");
                  setSelectedTechnologies("");
                  setSelectedTLandBDAs("");
                  setKeywordPayload("");
                  setTechnologyPayload("");
                  setTLandBDAsPayload("");
                  setKeywordSwitchValue(true);
                  settechStackSwitchValue(true);
                  setEstimationLink(false);
                  setWonapplyfilterCurrentPage(1);
                  setHotapplyfilterCurrentPage(1);
                  setOpenapplyfilterCurrentPage(1);
                  setEstimationapplyfilterProvidedCurrentPage(1);
                  setEstimationapplyfilterRequestedCurrentPage(1);
                  setLostapplyfilterCurrentPage(1);
                  setCloseapplyfilterCurrentPage(1);
                  setStandByapplyfilterCurrentPage(1);
                  setHotfilterCurrentPage(1);
                  setOpenfilterCurrentPage(1);
                  setEstimationfilterRequestedCurrentPage(1);
                  setEstimationfilterProvidedCurrentPage(1);
                  setStandByfilterCurrentPage(1);
                  setClosefilterCurrentPage(1);
                  setWonfilterCurrentPage(1);
                  setLostfilterCurrentPage(1);
                  dispatch(
                    fetchLeads({
                      status: leadStatus,
                      page:
                        projectvalue === 0
                          ? hotCurrentPage
                          : projectvalue === 1
                          ? openCurrentPage
                          : projectvalue === 2
                          ? estimationRequestedCurrentPage
                          : projectvalue === 3
                          ? estimationProvidedCurrentPage
                          : projectvalue === 4
                          ? standbycurrentpage
                          : projectvalue === 5
                          ? closeCurrentPage
                          : projectvalue === 6
                          ? wonCurrentPage
                          : lostCurrentPage,
                      query: searchUser,
                      limit: +rowsPerPage,
                      hot_lead: projectvalue === 0 ? 1 : "",
                    })
                  );
                }}
              >
                Remove filter
              </Button>
            </>
          )}
        </Box>
        <Box variant="div" component="div" className="content-header-right">
          <Box variant="div" component="div" className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
              placeholder="Search Here"
              value={searchUser}
              onChange={handleSearchLeads}
              onBlur={storeLeadsSearchInLocalStorage}
            />
            {searchUser && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={clearSearchHandler}
              />
            )}
          </Box>
          <WithPermission
            permission={updateLeads}
            element={
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn add-btn"
                endIcon={<RiAddCircleLine />}
                disableRipple
                onClick={() => navigate("/leads/create")}
              >
                Create Lead
              </Button>
            }
          />

          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiFilter2Line />}
            disableRipple
            onClick={handleDrawer}
          >
            <Typography
              variant="body1"
              component="span"
              className="filter-counte"
            >
              {totalFilters}
            </Typography>
            Filters
          </Button>
        </Box>
      </Box>

      <Box className="content-layout">
        <LeadList
          onValueChange={handleValueChange}
          leadStatus={leadStatus}
          searchUser={searchUser}
          onHotPageChange={handleHotPageChange}
          onOpenPageChange={handleOpenPageChange}
          onEstimationReqPageChange={handleEstimationReqPageChange}
          onEstimationProvPageChange={handleEstimationProvPageChange}
          onClosedPageChange={handleClosedPageChange}
          onWonPageChange={handleWonPageChange}
          onLostPageChange={handleLostPageChange}
          onStandByPageChange={handleStandByPageChange}
          filteredProjects={filteredProjects}
          filterApplied={filterApplied}
          filteredByIdProjects={filteredByIdProjects}
          filterByIdApplied={filterByIdApplied}
          hotleadProjects={hotleadProjects}
          hotLeadApplyProjects={hotLeadApplyProjects}
          applyFilterId={applyFilterId}
          selectedKeywords={selectedKeywords}
          selectedTLandBDAs={selectedTLandBDAs}
          selectedTechnologies={selectedTechnologies}
          searchText={searchText}
          filters={filters}
          keywordSwitchValue={keywordSwitchValue}
          techStackSwitchValue={techStackSwitchValue}
          filtersbyid={filtersbyid}
          onOpenFilterPageChange={handlefilterOpenPageChange}
          onHotFilterPageChange={handlefilterHotPageChange}
          onEstimationReqFilterPageChange={handlefilterEstimationReqPageChange}
          onEstimationProvFilterPageChange={
            handlefilterEstimationProvPageChange
          }
          onClosedFilterPageChange={handlefilterClosedPageChange}
          onWonFilterPageChange={handlefilterWonPageChange}
          onLostFilterPageChange={handlefilterLostPageChange}
          onStandByFilterPageChange={handlefilterStandByPageChange}
          openappliedFilterPage={openapplyfilterCurrentPage}
          onOpenapplyFilterPageChange={handleapplyfilterOpenPageChange}
          hotapplyfilterCurrentPage={hotapplyfilterCurrentPage}
          onHotapplyFilterPageChange={handleapplyfilterHotPageChange}
          estimationReqAppliedFilterPage={
            estimationapplyfilterRequestedCurrentPage
          }
          onEstimationReqapplyFilterPageChange={
            handleapplyfilterEstimationReqPageChange
          }
          estimationappliedfilterProvidedCurrentPage={
            estimationapplyfilterProvidedCurrentPage
          }
          onEstimationProvapplyFilterPageChange={
            handleapplyfilterEstimationProvPageChange
          }
          closedAppliedFilterPage={closeapplyfilterCurrentPage}
          onClosedapplyFilterPageChange={handleapplyfilterClosedPageChange}
          wonApplyFilterPage={wonapplyfilterCurrentPage}
          onWonappliedFilterPageChange={handleapplyfilterWonPageChange}
          lostAppliedFilterPageChange={lostapplyCurrentfilterPage}
          onLostapplyFilterPageChange={handleapplyfilterLostPageChange}
          standbyappliedfiltercurrentpage={standbyapplyfiltercurrentpage}
          onStandByapplyFilterPageChange={handleapplyfilterStandByPageChange}
          wonfilterPage={wonfilterCurrentPage}
          lostfilterPage={lostCurrentfilterPage}
          openfilterpage={openfilterCurrentPage}
          hotfilterpage={hotfilterCurrentPage}
          estimationprovidedfitlerpage={estimationfilterProvidedCurrentPage}
          estimationrequestedfilterpage={estimationfilterRequestedCurrentPage}
          standbyfilterpage={standbyfiltercurrentpage}
          closedfilterpage={closefilterCurrentPage}
          estimationlink={estimationlink}
          isEditing={isEditing}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Box>
      {/* END :: LEADS LISTING VIEW */}

      {/*  START :: LEAD FILTER PANEL */}
      <Drawer
        className="side-drawer lead-drawer"
        anchor="right"
        open={open}
        onClose={() => handleClose()}
      >
        <Box className="sidebar-tab-wrapper" style={{ maxWidth: "500px" }}>
          <Box className="sidebar-tab-wrapper">
            <Box className="close-btn" onClick={() => handleClose()}>
              <RiCloseLine />
            </Box>
            <Box className="sidebar-tab">
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                scrollButtons="auto"
                variant="scrollable"
              >
                <Tab
                  className="tab-label-btn"
                  label="Filters"
                  {...a11yProps(0)}
                />
                <Tab
                  className="tab-label-btn"
                  label="My Filters"
                  {...a11yProps(1)}
                />
                <Tab
                  className="tab-label-btn"
                  label="Shared Filters"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
          </Box>
          <CustomTabPanel
            value={value}
            index={0}
            className="filter-content-wrapper"
          >
            <Stack spacing={2} sx={{ padding: "24px" }}>
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={estimationlink}
                      onChange={handleSwitchChange}
                    />
                  }
                  label="Estimation Shared"
                />
                {isEditing || filterByIdApplied ? (
                  <>
                    <Typography variant="body1" component="label">
                      Filter Name
                    </Typography>
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Rename Filter"
                      value={
                        filterByIdApplied ? applyfilterName : editFilterName
                      }
                      onChange={(e) => {
                        if (filterByIdApplied) {
                          setApplyFilterName(e.target.value);
                        }
                        setEditFilterName(e.target.value);
                        if (e.target.value) {
                          setEditSearchTextError("");
                        }
                      }}
                    />
                  </>
                ) : null}
                {isEditing
                  ? editsearchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {editsearchTextError}
                      </Typography>
                    )
                  : null}
              </Box>
              <Box>
                <Typography variant="body1" component="label">
                  Search Text
                </Typography>

                <TextField
                  className="input-field"
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  size="small"
                  placeholder="Search Here"
                  value={searchText}
                  onChange={(e) => {
                    setSearchtext(e.target.value);

                    if (e.target.value) {
                      setSearchTextError("");
                      setEditSearchTextError("");
                    }
                  }}
                />

                {!isEditing
                  ? searchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {searchTextError}
                      </Typography>
                    )
                  : editsearchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {editsearchTextError}
                      </Typography>
                    )}
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body1" component="label">
                    Users
                  </Typography>
                </Box>
                <Select
                  isMulti
                  name="colors"
                  options={memberOptions}
                  onChange={(selectedOptions, { removedValue }) => {
                    if (removedValue) {
                      const removedOptionId = removedValue.value; // assuming 'value' contains the ID
                      setRemoveTLandBdaId([
                        ...removeTLandBdaId,
                        removedOptionId,
                      ]);
                    }
                    handleTLandBdaSelect(selectedOptions);
                  }}
                  value={selectedTLandBDAs}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                />

                {!isEditing
                  ? searchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {searchTextError}
                      </Typography>
                    )
                  : editsearchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {editsearchTextError}
                      </Typography>
                    )}
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body1" component="label">
                    Keywords
                  </Typography>

                  <FormControlLabel
                    control={
                      <MaterialUISwitch
                        sx={{ m: 0.5, marginLeft: "-1px" }}
                        defaultChecked
                      />
                    }
                    label="Match :"
                    labelPlacement="start"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "13px",
                        fontWeight: 600,
                      },
                    }} // Adjust the font size here
                    checked={keywordSwitchValue}
                    onChange={handleKeywordMatch}
                  />
                </Box>
                <Select
                  isMulti
                  name="colors"
                  options={keywordsData}
                  onChange={(selectedOptions, { removedValue }) => {
                    if (removedValue) {
                      const removedOptionId = removedValue.value; // assuming 'value' contains the ID
                      setRemoveKeyId([...removeKeyId, removedOptionId]);
                    }
                    handleKeywordSelect(selectedOptions);
                  }}
                  value={selectedKeywords}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                />

                {!isEditing
                  ? searchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {searchTextError}
                      </Typography>
                    )
                  : editsearchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {editsearchTextError}
                      </Typography>
                    )}
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="body1" component="label">
                    Technology Stack
                  </Typography>
                  <FormControlLabel
                    control={
                      <MaterialUISwitch
                        sx={{ m: 0.5, marginLeft: "-1px" }}
                        defaultChecked
                      />
                    }
                    label="Match :"
                    labelPlacement="start"
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        fontSize: "13px",
                        fontWeight: 600,
                      },
                    }} // Adjust the font size here
                    checked={techStackSwitchValue}
                    onChange={handleTechStackMatch}
                  />
                </Box>
                <Select
                  isMulti
                  name="colors"
                  options={technologiesData}
                  onChange={(selectedOptions, { removedValue }) => {
                    if (removedValue) {
                      const removedOptionId = removedValue.value; // assuming 'value' contains the ID
                      setRemoveTechId([...removeTechId, removedOptionId]);
                    }
                    handleTechnologySelect(selectedOptions);
                  }}
                  value={selectedTechnologies}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                />

                {!isEditing
                  ? searchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {searchTextError}
                      </Typography>
                    )
                  : editsearchTextError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {editsearchTextError}
                      </Typography>
                    )}
              </Box>

              <Box
                className="filter-button-wrapper"
                style={{ justifyContent: "center" }}
              >
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn green-btn"
                  disableRipple
                  onClick={() => {
                    handleApplyFilter();
                  }}
                >
                  Apply Filter
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={
                    isEditing || filterByIdApplied ? handleUpdate : handleSave
                  }
                  disabled={isEditing && isButtonDisabled}
                >
                  {isEditing || filterByIdApplied ? "Update" : "Save"}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn cancel-filter-btn"
                  disableRipple
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn clear-filter-tab-1"
                  disableRipple
                  style={{ width: "100%" }}
                  onClick={() => handleClearFilter()}
                >
                  Clear Filter
                </Button>
              </Box>
            </Stack>
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={1}
            className="filter-content-wrapper"
          >
            {filters?.getallfilter?.length > 0 ? (
              filters?.getallfilter?.map((filter) => {
                return (
                  <Box className="filer-accordion-wrapper">
                    <Accordion>
                      <AccordionSummary
                        className="accordion-title"
                        expandIcon={<RiArrowDownSLine />}
                        aria-controls="filter-content-1"
                        id="filter-header-1"
                      >
                        <Typography className="title-content">
                          {filter?.filter_name === null ||
                          filter?.filter_name === ""
                            ? "-"
                            : capitalizeWords(filter?.filter_name)}
                        </Typography>
                        <Stack
                          className="action-btn-wrap"
                          direction="row"
                          spacing={2}
                        >
                          <Button
                            variant="outlined"
                            className="action-btn view-btn"
                            startIcon={<RiFilter2Line />}
                            disableRipple
                            onClick={() => handleFilterById(filter.id)}
                          ></Button>
                          <Button
                            variant="outlined"
                            className="action-btn convert-btn"
                            startIcon={<RiShareLine />}
                            disableRipple
                            onClick={(event) => {
                              event.stopPropagation();
                              handleShareFilterModal(filter.id);
                            }}
                          ></Button>
                          <Button
                            variant="outlined"
                            className="action-btn edit-btn"
                            startIcon={<RiPencilLine />}
                            disableRipple
                            onClick={() => {
                              handleEditFilter(filter.id);
                              setIsShared(false);
                            }}
                          ></Button>

                          <WithPermission
                            permission={fullLeads}
                            element={
                              <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDeleteModal(filter.id);
                                }}
                                disableRipple
                              ></Button>
                            }
                          />
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails className="filter-accordion-details">
                        <Stack spacing={2} className="saved-filter-wrapper">
                          <Box className="saved-filter-content">
                            <Typography
                              variant="body2"
                              component="span"
                              className="title-leads"
                            >
                              <p> Search Text :</p>
                            </Typography>
                            <Typography
                              variant="body2"
                              component="span"
                              className="desc"
                            >
                              {filter?.search_text === null ||
                              filter?.search_text === ""
                                ? "-"
                                : filter?.search_text}
                            </Typography>
                          </Box>
                          <Box className="saved-filter-content">
                            <Typography
                              variant="body2"
                              component="span"
                              className="title-leads"
                            >
                              <p> Users :</p>
                            </Typography>
                            <Box
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                className="desc"
                              >
                                {filter?.lead_filter_assignees?.length > 0
                                  ? filter?.lead_filter_assignees?.map(
                                      (user, index) => (
                                        <Box key={user?.lead_assignees?.id}>
                                          <Typography
                                            variant="body2"
                                            component="span"
                                            className="desc"
                                          >
                                            {user?.lead_assignees?.first_name +
                                              " " +
                                              user?.lead_assignees?.last_name}
                                          </Typography>

                                          {index <
                                            filter?.lead_filter_assignees
                                              ?.length -
                                              1 && <span>, </span>}
                                        </Box>
                                      )
                                    )
                                  : "-"}
                              </Typography>
                            </Box>
                          </Box>
                          <Box className="saved-filter-content">
                            <Typography
                              variant="body2"
                              component="span"
                              className="title-leads"
                            >
                              <p> Keywords :</p>
                            </Typography>
                            <Box
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                className="desc"
                              >
                                {filter?.lead_filter_keywords?.length > 0
                                  ? filter?.lead_filter_keywords?.map(
                                      (keyword, index) => (
                                        <>
                                          <Typography
                                            variant="body2"
                                            component="span"
                                            className="desc"
                                          >
                                            {keyword?.keywords?.keyword_name}
                                          </Typography>

                                          {index <
                                            filter?.lead_filter_keywords
                                              ?.length -
                                              1 && <span>, </span>}
                                        </>
                                      )
                                    )
                                  : "-"}
                              </Typography>
                              <Box
                                className="saved-filter-content"
                                sx={{ marginTop: "10px" }}
                              >
                                <Typography variant="body2" className="title">
                                  Match :
                                </Typography>

                                <ToggleButtonGroup
                                  value={
                                    filter?.keywordMatch === "and"
                                      ? "all"
                                      : "any"
                                  }
                                  disabled
                                  sx={{ height: "0px", marginLeft: "10px" }}
                                >
                                  <ToggleButton
                                    value="all"
                                    sx={{
                                      fontSize: "12px",
                                      padding: "9px",
                                      textTransform: "none",
                                    }}
                                  >
                                    All
                                  </ToggleButton>
                                  <ToggleButton
                                    value="any"
                                    sx={{
                                      fontSize: "12px",
                                      padding: "9px",
                                      textTransform: "none",
                                    }}
                                  >
                                    Any
                                  </ToggleButton>
                                </ToggleButtonGroup>
                              </Box>
                            </Box>
                          </Box>

                          <Box className="saved-filter-content">
                            <Typography
                              variant="body2"
                              className="title-leads"
                              component="span"
                            >
                              <p>Technology Stack :</p>
                            </Typography>
                            <Box
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              <Typography
                                variant="body2"
                                component="span"
                                className="desc"
                              >
                                {filter?.lead_filter_technology_stacks?.length >
                                0
                                  ? filter?.lead_filter_technology_stacks?.map(
                                      (technology, index) => (
                                        <>
                                          <Typography
                                            variant="body2"
                                            component="span"
                                            className="desc"
                                          >
                                            {
                                              technology?.technologies
                                                ?.technology_name
                                            }
                                          </Typography>
                                          {index <
                                            filter
                                              ?.lead_filter_technology_stacks
                                              ?.length -
                                              1 && <span>, </span>}
                                        </>
                                      )
                                    )
                                  : "-"}
                              </Typography>

                              <Box
                                className="saved-filter-content"
                                sx={{ marginTop: "10px" }}
                              >
                                <Typography variant="body2" className="title">
                                  Match :
                                </Typography>
                                <Typography
                                  variant="body2"
                                  component="span"
                                  className="desc"
                                >
                                  <ToggleButtonGroup
                                    value={
                                      filter?.technologyStackMatch === "and"
                                        ? "all"
                                        : "any"
                                    }
                                    disabled
                                    aria-label="Small sizes"
                                    sx={{ height: "0px", marginLeft: "10px" }}
                                  >
                                    <ToggleButton
                                      value="all"
                                      sx={{
                                        fontSize: "12px",
                                        padding: "9px",
                                        textTransform: "none",
                                      }}
                                    >
                                      All
                                    </ToggleButton>
                                    <ToggleButton
                                      value="any"
                                      sx={{
                                        fontSize: "12px",
                                        padding: "9px",
                                        textTransform: "none",
                                      }}
                                    >
                                      Any
                                    </ToggleButton>
                                  </ToggleButtonGroup>
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box className="saved-filter-content">
                            <Typography
                              variant="body2"
                              component="span"
                              className="title-leads"
                            >
                              <p> Estimation Shared:</p>
                            </Typography>
                            <Typography
                              variant="body2"
                              component="span"
                              className="desc"
                            >
                              <>
                                <Typography
                                  variant="body2"
                                  component="span"
                                  className="desc"
                                >
                                  {capitalizeFirstLetter(
                                    filter?.estimationprovidedlead
                                  )}
                                </Typography>
                              </>
                            </Typography>
                          </Box>
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                );
              })
            ) : (
              <Typography variant="body1" component="div" className="no-msg">
                No Filters Found
              </Typography>
            )}
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={2}
            className="filter-content-wrapper"
          >
            {filters?.shareddatas?.length > 0 ? (
              filters?.shareddatas?.map((filter) => (
                <Box className="filer-accordion-wrapper">
                  <Accordion>
                    <AccordionSummary
                      className="accordion-title"
                      expandIcon={<RiArrowDownSLine />}
                      aria-controls="filter-content-1"
                      id="filter-header-1"
                    >
                      <Typography className="title-content">
                        {filter?.lead_filters?.filter_name === "" ||
                        filter?.lead_filters?.filter_name === null
                          ? "-"
                          : capitalizeWords(filter?.lead_filters?.filter_name)}
                      </Typography>
                      <Stack
                        className="action-btn-wrap"
                        direction="row"
                        spacing={2}
                      >
                        <Button
                          variant="outlined"
                          className="action-btn view-btn"
                          startIcon={<RiFilter2Line />}
                          disableRipple
                          onClick={() =>
                            handleShareFilterById(filter.filter_id)
                          }
                        ></Button>
                        {userRole === "Business Manager" ||
                        userRole === "Admin" ||
                        userRole === "Technical Manager" ? (
                          <>
                            <Button
                              variant="outlined"
                              className="action-btn convert-btn"
                              startIcon={<RiShareLine />}
                              disableRipple
                              onClick={(event) => {
                                event.stopPropagation();
                                handleShareFilterModal(filter.filter_id);
                              }}
                            ></Button>{" "}
                            <Button
                              variant="outlined"
                              className="action-btn edit-btn"
                              startIcon={<RiPencilLine />}
                              disableRipple
                              onClick={() => {
                                handleEditFilter(filter.filter_id);
                                setIsShared(true);
                              }}
                            ></Button>
                            <WithPermission
                              permission={fullLeads}
                              element={
                                <Button
                                  variant="outlined"
                                  className="action-btn dlt-btn"
                                  startIcon={<RiDeleteBinLine />}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleDeleteModal(filter.filter_id);
                                  }}
                                  disableRipple
                                ></Button>
                              }
                            />
                          </>
                        ) : null}
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails className="filter-accordion-details">
                      <Stack spacing={2} className="saved-filter-wrapper">
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title-leads"
                          >
                            <p> Created By:</p>
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            className="desc"
                          >
                            {capitalizeFirstLetter(
                              filter?.lead_filters?.users?.first_name
                            )}{" "}
                            {capitalizeFirstLetter(
                              filter?.lead_filters?.users?.last_name
                            )}
                          </Typography>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title-leads"
                          >
                            <p>Shared Comment:</p>
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            className="desc"
                          >
                            {filter?.lead_filters?.shared_comment === "" ||
                            filter?.lead_filters?.shared_comment === null
                              ? "-"
                              : filter?.lead_filters?.shared_comment}{" "}
                          </Typography>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title-leads"
                          >
                            <p> Search Text:</p>
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            className="desc"
                          >
                            {filter?.lead_filters?.search_text === "" ||
                            filter?.lead_filters?.search_text === null
                              ? "-"
                              : filter?.lead_filters?.search_text}
                          </Typography>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title-leads"
                          >
                            <p> Users :</p>
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="body2"
                              component="span"
                              className="desc"
                            >
                              {filter?.lead_filters?.lead_filter_assignees
                                ?.length > 0
                                ? filter?.lead_filters?.lead_filter_assignees?.map(
                                    (user, index) => (
                                      <Box key={user?.lead_assignees?.id}>
                                        <Typography
                                          variant="body2"
                                          component="span"
                                          className="desc"
                                        >
                                          {user?.lead_assignees?.first_name +
                                            " " +
                                            user?.lead_assignees?.last_name}
                                        </Typography>

                                        {index <
                                          filter?.lead_filters
                                            ?.lead_filter_assignees?.length -
                                            1 && <span>, </span>}
                                      </Box>
                                    )
                                  )
                                : null}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title-leads"
                          >
                            <p>Keywords :</p>
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="body2"
                              component="span"
                              className="desc"
                            >
                              {filter?.lead_filters?.lead_filter_keywords
                                ?.length > 0
                                ? filter?.lead_filters?.lead_filter_keywords?.map(
                                    (keyword, index) => (
                                      <>
                                        <Typography
                                          variant="body2"
                                          component="span"
                                          className="desc"
                                        >
                                          {keyword?.keywords?.keyword_name}
                                        </Typography>

                                        {index <
                                          filter?.lead_filters
                                            ?.lead_filter_keywords?.length -
                                            1 && <span>, </span>}
                                      </>
                                    )
                                  )
                                : null}
                            </Typography>
                            <Box
                              className="saved-filter-content"
                              sx={{ marginTop: "10px" }}
                            >
                              <Typography variant="body2" className="title">
                                Match :
                              </Typography>

                              <ToggleButtonGroup
                                value={
                                  filter?.lead_filters?.keywordMatch === "and"
                                    ? "all"
                                    : "any"
                                }
                                disabled
                                sx={{ height: "0px", marginLeft: "10px" }}
                              >
                                <ToggleButton
                                  value="all"
                                  sx={{
                                    fontSize: "12px",
                                    padding: "9px",
                                    textTransform: "none",
                                  }}
                                >
                                  All
                                </ToggleButton>
                                <ToggleButton
                                  value="any"
                                  sx={{
                                    fontSize: "12px",
                                    padding: "9px",
                                    textTransform: "none",
                                  }}
                                >
                                  Any
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title-leads"
                          >
                            <p>Technology Stack :</p>
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              variant="body2"
                              component="span"
                              className="desc"
                            >
                              {filter?.lead_filters
                                ?.lead_filter_technology_stacks?.length > 0
                                ? filter?.lead_filters?.lead_filter_technology_stacks?.map(
                                    (technology, index) => (
                                      <>
                                        <Typography
                                          variant="body2"
                                          component="span"
                                          className="desc"
                                        >
                                          {
                                            technology?.technologies
                                              ?.technology_name
                                          }
                                        </Typography>
                                        {index <
                                          filter?.lead_filters
                                            ?.lead_filter_technology_stacks
                                            ?.length -
                                            1 && <span>, </span>}
                                      </>
                                    )
                                  )
                                : null}
                            </Typography>
                            <Box
                              className="saved-filter-content"
                              sx={{ marginTop: "10px" }}
                            >
                              <Typography variant="body2" className="title">
                                Match :
                              </Typography>

                              <ToggleButtonGroup
                                value={
                                  filter?.lead_filters?.technologyStackMatch ===
                                  "and"
                                    ? "all"
                                    : "any"
                                }
                                disabled
                                sx={{ height: "0px", marginLeft: "10px" }}
                              >
                                <ToggleButton
                                  value="all"
                                  sx={{
                                    fontSize: "12px",
                                    padding: "9px",
                                    textTransform: "none",
                                  }}
                                >
                                  All
                                </ToggleButton>
                                <ToggleButton
                                  value="any"
                                  sx={{
                                    fontSize: "12px",
                                    padding: "9px",
                                    textTransform: "none",
                                  }}
                                >
                                  Any
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="saved-filter-content">
                          <Typography
                            variant="body2"
                            component="span"
                            className="title-leads"
                          >
                            <p> Estimation Shared:</p>
                          </Typography>
                          <Typography
                            variant="body2"
                            component="span"
                            className="desc"
                          >
                            <>
                              <Typography
                                variant="body2"
                                component="span"
                                className="desc"
                              >
                                {capitalizeFirstLetter(
                                  filter?.lead_filters?.estimationprovidedlead
                                )}
                              </Typography>
                            </>
                          </Typography>
                        </Box>
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ))
            ) : (
              <Typography variant="body1" component="div" className="no-msg">
                No Filters Found
              </Typography>
            )}
          </CustomTabPanel>
        </Box>
      </Drawer>
      {/*  START :: SAVE FILTER MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={SaveFilter}
        // onClose={ExportToPdfModalClose}
        closeAfterTransition
      >
        <Fade in={SaveFilter}>
          <Box className="modal-wrapper">
            <Box
              variant="div"
              component="div"
              className="title"
              sx={{ marginBottom: "24px" }}
            >
              <Typography variant="h3" component="h3">
                Save Filter
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body">
              <Typography variant="body1" component="label">
                Name
              </Typography>

              <TextField
                className="input-field"
                fullWidth
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                placeholder="Enter Save Filter Name"
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                  if (e.target.value) {
                    setFilterNameError("");
                  }
                }}
              />
            </Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  disabled={isButtonDisabled}
                  onClick={isEditing ? handleEditFilterSave : handleFiltersave}
                >
                  {isEditing ? "Update" : "Save"}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => setSaveFilterModal(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {/* END :: SAVE FILTER MODAL */}

      {/*  START :: DELETE SAVED FILTER MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={DeleteSavedFilter}
        closeAfterTransition
      >
        <Fade in={DeleteSavedFilter}>
          <Box className="modal-wrapper">
            <Typography variant="h3" component="h3">
              Do you want to delete this saved filter?
            </Typography>
            <Typography variant="body1" component="p">
              This action can’t be undone.
            </Typography>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={deleteSubmitHandler}
                >
                  Delete Filter
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => setDeleteSavedFilterModal(false)}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={ActivityLog}
        // onClose={ExportToPdfModalClose}
        closeAfterTransition
      >
        <Fade in={ActivityLog}>
          <div className="modal-wrapper">
            <Box
              variant="div"
              component="div"
              className="title"
              sx={{ marginBottom: "24px" }}
            >
              <Typography variant="h3" component="h3">
                Share Filter
              </Typography>
            </Box>

            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12}>
                <Typography variant="body1" component="label">
                  Select Member(s)
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  name="colors"
                  isMulti
                  options={memberOptions}
                  value={members}
                  onChange={(selectedOptions, { removedValue }) => {
                    if (removedValue) {
                      const removedOptionId = removedValue.value; // assuming 'value' contains the ID
                      setRemoveKeyId([...removeKeyId, removedOptionId]);
                    }
                    handleOptionChange(selectedOptions);
                  }}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  // menuIsOpen
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                />
                {membererror && (
                  <Typography variant="body1" component="p" className="error">
                    {membererror}
                  </Typography>
                )}
              </Grid>

              <Grid item lg={12} md={12} sm={12}>
                <Typography variant="body1" component="label">
                  Comments
                </Typography>
                <TextareaAutosize
                  className="textarea-field modal-textarea"
                  placeholder="Add Comments"
                  value={sharedcomment}
                  onChange={(e) => setSharedComment(e.target.value)}
                />
              </Grid>
            </Grid>

            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={handleShareModal}
                >
                  Share
                </Button>

                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => {
                    setMembers([]);
                    setSharedComment("");
                    setActivityLogModal(false);
                    setMemberError("");
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
      {/* END :: DELETE SAVED FILTER MODAL */}
    </>
  );
};

export default Leads;

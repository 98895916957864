import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";
// import { successToast } from "../../responses/successToast";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const createFilterSlice = createSlice({
  name: "createFilter",
  initialState,
  reducers: {
    createFilterStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createFilterSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    createFilterFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { createFilterStart, createFilterSuccess, createFilterFailure } =
  createFilterSlice.actions;

export default createFilterSlice.reducer;

export const createFilter = (filterData) => async (dispatch) => {
  try {
    dispatch(createFilterStart());

    const response = await callApi({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/filters`,
      data: filterData,
      params: {
        filter_type: "projects",
      },
    });

    const data = response.data;
    // console.log(response.data, "lead slice");
    dispatch(createFilterSuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(createFilterFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.response.data.message };
  }
};

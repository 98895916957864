import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  List,
  ListItem,
  ListItemText,
  TableContainer,
  TextField,
  Tooltip,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  RiAddCircleLine,
  RiCloseCircleLine,
  RiDeleteBinLine,
  RiEyeLine,
  RiPencilLine,
  RiSearchLine,
} from 'react-icons/ri';
import ExportIcon from '../../assets/images/icons/convert-icon.svg';
import { CustomTablePaginationToolbar } from '../../components/common/Pagination';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import { fetchTemplates } from '../../store/slices/cv-builder/templateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCVs } from '../../store/slices/cv-builder/CVSlice';
import CVTemplateDeleteModal from '../../components/common/deleteCVTemplateModal';
import ExportPdfCVModal from '../../components/common/exportPDFCVModal';

const AllTemplate = {
  id: 0,
  name: 'All',
};
function CvTemplateList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedCVTemplateId, setSelectedCVTemplateId] = useState();
  const [employeeName, setEmployeeName] = useState('');
  const [isTemplate, setIsTemplate] = useState(false);
  const [searchTemplate, setsearchTemplate] = useState('');
  const [searchCV, setsearchCV] = useState('');
  const [exportCancel, setExportCancel] = useState('');
  const [cvTemplateDeleteCancel, setCVTemplateDeleteCancel] = useState(false);
  const templates = useSelector((state) => state.templates.templates);
  const CVs = useSelector((state) => state.CVs.CVs);
  const location = useLocation();
  let { state } = location;

  if (state === null) {
    state = {
      template: AllTemplate,
    };
  }

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const [selectedTemplate, setSelectedTemplate] = useState(state.template);
  useEffect(() => {
    setLoading(true);

    dispatch(fetchTemplates({ search: searchTemplate }));

    setLoading(false);
  }, [dispatch, searchTemplate]);

  useEffect(() => {
    setLoading(true);
    if (selectedTemplate?.id === AllTemplate?.id) {
      dispatch(
        fetchCVs({ page: page, pageSize: rowsPerPage, search: searchCV })
      );
    } else {
      dispatch(
        fetchCVs({
          page: page,
          pageSize: rowsPerPage,
          templateId: selectedTemplate?.id,
          search: searchCV,
        })
      );
    }

    setLoading(false);
  }, [selectedTemplate, page, rowsPerPage, dispatch, searchCV]);
  const handleTemplateClick = (template) => {
    setPage(1);
    setRowsPerPage(10);
    setSelectedTemplate(template);
  };
  const handleExportPDF = (id, name) => {
    setSelectedCVTemplateId(id);
    setEmployeeName(name);
    setExportCancel(true);
  };

  const handleDelete = (id, isTemplate) => {
    setSelectedCVTemplateId(id);
    setCVTemplateDeleteCancel(true);
    setIsTemplate(isTemplate);
    setEmployeeName('');
  };
  const handleDeleteClose = () => {
    setSelectedCVTemplateId();
    setEmployeeName('');
    setCVTemplateDeleteCancel(false);
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  }
  if (loading) {
    return;
  }

  return (
    <>
      <Box className='content-header cvbuilder-list-header'>
        <Typography variant='h2' component='h2'>
          CV Builder
        </Typography>
        <Box variant='div' component='div' className='content-header-right'>
          <Box variant='div' component='div' className='search-bar'>
            <RiSearchLine />
            <TextField
              fullWidth
              id='outlined-basic'
              label=''
              variant='outlined'
              size='small'
              placeholder='Search Template'
              value={searchTemplate}
              onChange={(e) => setsearchTemplate(e.target.value)}
            />
            {searchTemplate && (
              <RiCloseCircleLine
                className='cross-line'
                onClick={(e) => setsearchTemplate('')}
              />
            )}
          </Box>
          <Button
            variant='contained'
            size='large'
            className='primary-btn btn add-btn'
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => navigate('/cv-builder/template/create')}
            sx={{ backgroundColor: 'rgb(124, 185, 0)', color: 'white' }}
          >
            Create Template
          </Button>
        </Box>
      </Box>
      <Box className='content-wrapper cvbuilder-wrapper'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4.5}>
            <Box
              sx={{
                backgroundColor: 'white',
                borderRadius: 2,
              }}
            >
              <Typography
                variant='h6'
                sx={{
                  marginBottom: 2,
                  backgroundColor: 'rgb(124, 185, 0)',
                  color: 'white',
                  fontSize: '18px',
                  fontWeight: '500',
                  padding: 2,
                  borderRadius: 1,
                  textAlign: 'center',
                }}
              >
                Templates
              </Typography>
              {!loading && (
                <List
                  sx={{
                    height: '466px',
                    overflowY: 'auto',
                    '::-webkit-scrollbar': {
                      display: 'none !important',
                    },
                  }}
                >
                  {templates?.length > 0 ? (
                    <>
                      <ListItem
                        button
                        key={'all'}
                        onClick={() => handleTemplateClick(AllTemplate)}
                        sx={{
                          borderRadius: 1,
                          padding: 2,
                          backgroundColor:
                            selectedTemplate?.id === AllTemplate.id
                              ? 'rgb(35, 35, 35)'
                              : 'transparent',
                          color:
                            selectedTemplate?.id === AllTemplate.id
                              ? 'white !important'
                              : 'transparent',

                          '&:hover': {
                            backgroundColor:
                              selectedTemplate?.id === AllTemplate.id &&
                              'rgb(35, 35, 35)',
                          },
                        }}
                      >
                        <ListItemText
                          primary={'All'}
                          sx={{
                            '& span': {
                              fontWeight: '500',
                              color:
                                selectedTemplate?.id === AllTemplate.id
                                  ? 'white'
                                  : 'black',
                            },
                          }}
                        />
                      </ListItem>
                      {templates?.map((template) => (
                        <ListItem
                          button
                          key={template.id}
                          onClick={() => handleTemplateClick(template)}
                          sx={{
                            borderRadius: 1,
                            padding: 2,
                            backgroundColor:
                              selectedTemplate?.id === template.id
                                ? 'rgb(35, 35, 35)'
                                : 'transparent',
                            color:
                              selectedTemplate?.id === template.id
                                ? 'white !important'
                                : 'transparent',

                            '&:hover': {
                              backgroundColor:
                                selectedTemplate?.id === template.id &&
                                'rgb(35, 35, 35)',
                            },
                          }}
                        >
                          <ListItemText
                            primary={template.template_name}
                            sx={{
                              '& span': {
                                fontWeight: '500',
                                color:
                                  selectedTemplate?.id === template.id
                                    ? 'white'
                                    : 'black',
                              },
                            }}
                          />
                          {selectedTemplate.id === template.id && (
                            <Box
                              className='action-btn-wrap'
                              sx={{ gap: '10px', marginLeft: '3px' }}
                            >
                              <Button
                                variant='outlined'
                                className='action-btn view-btn'
                                sx={{
                                  height: '32px',
                                  minWidth: '32px !important',
                                }}
                                startIcon={<RiEyeLine size={18} />}
                                onClick={() =>
                                  navigate(
                                    `/cv-builder/template/${template.id}/view`,
                                    {
                                      state: {
                                        template: selectedTemplate,
                                      },
                                    }
                                  )
                                }
                                disableRipple
                              ></Button>

                              <Button
                                variant='outlined'
                                className='action-btn edit-btn'
                                startIcon={<RiPencilLine size={18} />}
                                sx={{
                                  height: '32px',
                                  minWidth: '32px !important',
                                }}
                                onClick={() =>
                                  navigate(
                                    `/cv-builder/template/${template.id}/edit`,
                                    {
                                      state: {
                                        template: selectedTemplate,
                                      },
                                    }
                                  )
                                }
                                disableRipple
                              ></Button>
                              <Button
                                variant='outlined'
                                className='action-btn dlt-btn'
                                startIcon={<RiDeleteBinLine size={18} />}
                                onClick={() => handleDelete(template.id, true)}
                                sx={{
                                  height: '32px',
                                  minWidth: '32px !important',
                                }}
                                disableRipple
                              ></Button>
                            </Box>
                          )}
                        </ListItem>
                      ))}
                    </>
                  ) : (
                    <ListItem
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        textAlign: 'center',
                      }}
                    >
                      <Typography className='not-found-msg'>
                        No Templates Found
                      </Typography>
                    </ListItem>
                  )}
                </List>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={7.5}>
            <Box
              sx={{
                padding: 2,
                backgroundColor: 'white',
                borderRadius: 2,
              }}
              className='cv-listing-container'
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  overflowY: 'auto',
                }}
                className='cv-listing-header'
                mb={2}
              >
                <Typography variant='h3' component='h3'>
                  CVs
                </Typography>

                <Box
                  variant='div'
                  component='div'
                  className='content-header-right'
                  sx={{
                    '& .search-bar fieldset': {
                      border: '1px solid var(--border) !important',
                    },
                  }}
                >
                  <Box variant='div' component='div' className='search-bar'>
                    <RiSearchLine />
                    <TextField
                      fullWidth
                      id='outlined-basic'
                      label=''
                      variant='outlined'
                      size='small'
                      placeholder='Search CV'
                      value={searchCV}
                      onChange={(e) => setsearchCV(e.target.value)}
                    />
                    {searchCV && (
                      <RiCloseCircleLine
                        className='cross-line'
                        onClick={(e) => setsearchCV('')}
                      />
                    )}
                  </Box>
                  {selectedTemplate.id !== AllTemplate.id && (
                    <Button
                      variant='contained'
                      size='large'
                      className='primary-btn btn add-btn'
                      endIcon={<RiAddCircleLine />}
                      disableRipple
                      onClick={() =>
                        navigate(
                          `/cv-builder/template/${selectedTemplate?.id}/cv/create`,
                          {
                            state: {
                              template: selectedTemplate,
                            },
                          }
                        )
                      }
                      sx={{
                        backgroundColor: 'rgb(124, 185, 0)',
                        color: 'white',
                      }}
                    >
                      Create CV
                    </Button>
                  )}
                </Box>
              </Box>
              {CVs?.cvtemplates?.length > 0 ? (
                <>
                  {' '}
                  <TableContainer
                    className='table-listing'
                    sx={{ height: '350px' }}
                  >
                    <Table
                      sx={{ minWidth: 600 }}
                      aria-label='sticky table'
                      className='listing-table'
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell align='left'>Employee Name</TableCell>

                          <TableCell align='left'>CV Title</TableCell>
                          <TableCell align='left'>Last Update</TableCell>
                          <TableCell align='left'>Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {CVs?.cvtemplates?.map((cv) => (
                          <TableRow
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                            key={cv?.id}
                          >
                            <TableCell align='left'>
                              {cv.employee_name.length > 19 ? (
                                <Tooltip
                                  title={
                                    <div
                                      style={{
                                        color: 'white',
                                        fontSize: '13px',
                                      }}
                                    >
                                      {cv.employee_name}
                                    </div>
                                  }
                                  arrow
                                  placement='bottom-start'
                                >
                                  {`${cv.employee_name.slice(0, 16)}...`}
                                </Tooltip>
                              ) : (
                                cv.employee_name
                              )}
                            </TableCell>

                            <TableCell align='left'>
                              {cv.cv_title.length > 20 ? (
                                <Tooltip
                                  title={
                                    <div
                                      style={{
                                        color: 'white',
                                        fontSize: '13px',
                                      }}
                                    >
                                      {cv.cv_title}
                                    </div>
                                  }
                                  arrow
                                  placement='bottom-start'
                                >
                                  {`${cv.cv_title.slice(0, 17)}...`}
                                </Tooltip>
                              ) : (
                                cv.cv_title
                              )}
                            </TableCell>
                            <TableCell align='left'>
                              {formatDate(cv.updatedAt)}
                            </TableCell>
                            <TableCell align='left'>
                              <Box className='action-btn-wrap'>
                                <Button
                                  variant='outlined'
                                  className='action-btn export-btn'
                                  onClick={() =>
                                    handleExportPDF(cv.id, cv.employee_name)
                                  }
                                >
                                  <img src={ExportIcon} alt='Convert Icon' />
                                </Button>

                                <Button
                                  variant='outlined'
                                  className='action-btn edit-btn'
                                  startIcon={<RiPencilLine />}
                                  onClick={() =>
                                    navigate(
                                      `/cv-builder/template/${cv.template_id}/cv/${cv.id}/edit`,
                                      {
                                        state: {
                                          template: selectedTemplate,
                                        },
                                      }
                                    )
                                  }
                                  disableRipple
                                ></Button>
                                <Button
                                  variant='outlined'
                                  className='action-btn dlt-btn'
                                  startIcon={<RiDeleteBinLine />}
                                  onClick={() => handleDelete(cv.id)}
                                  disableRipple
                                  // title="Delete"
                                ></Button>
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <CustomTablePaginationToolbar
                    count={CVs?.total}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    sibling={0}
                  />
                </>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '453px',
                    textAlign: 'center',
                  }}
                >
                  <Typography className='not-found-msg'>No CV Found</Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CVTemplateDeleteModal
        open={cvTemplateDeleteCancel}
        handleDeleteClose={handleDeleteClose}
        id={selectedCVTemplateId}
        isTemplate={isTemplate}
        setSelectedTemplate={setSelectedTemplate}
        selectedTemplate={selectedTemplate}
        setPage={setPage}
      />
      <ExportPdfCVModal
        exportCancel={exportCancel}
        setExportCancel={setExportCancel}
        cvId={selectedCVTemplateId}
        employeeName={employeeName}
      />
    </>
  );
}

export default CvTemplateList;

import { callApi } from "../../components/common/commonfunctionforapi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export const fetchReleaseNotesDesc = createAsyncThunk(
  "releasenotes/fetchReleaseNotesDesc",
  async ({ id, timezone }, { rejectWithValue }) => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/release-note`,
        params: {
          id: id,

          timezone: timezone,
        },
      });
      // console.log(response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  releasenotes: [],
  status: "idle",
  error: null,
};
const releaseNotesDescSlice = createSlice({
  name: "releaseNotesDesc",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReleaseNotesDesc.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReleaseNotesDesc.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.releasenotes = action.payload;
      })

      .addMatcher(
        (action) =>
          action.type.startsWith("releasenotes/fetchReleaseNotesDesc"),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            state.status = "failed";
            state.error = action.payload.message;
          }
        }
      );
  },
});

export default releaseNotesDescSlice.reducer;

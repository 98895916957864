import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Typography,
  Tooltip,
  Badge,
} from "@mui/material";
import React from "react";
import { DateRangePicker } from "rsuite";

import { useNavigate } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBidReports } from "../../store/slices/bidReportSlice";
import { styled } from "@mui/material/styles";
import { getDateRangeOptions } from "../../utils/DateRanges";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: -2,
    background: "rgb(197, 227, 137)",

    padding: "0 4px",
  },
}));

const TotalBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: -2,
    background: "white",

    padding: "0 4px",
  },
}));

const BidReportList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const bids = useSelector((state) => state.bidReports.bids);

  const initialSelectedDates = [new Date(), new Date()];
  const [selectedDates, setSelectedDates] = useState(initialSelectedDates);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const ranges = getDateRangeOptions();

  const handleDateRangeChange = (date) => {
    // console.log(date);
    if (date === null) {
      setSelectedDates(initialSelectedDates);
      setStartDate(new Date().toISOString().split("T")[0]);
      setEndDate(new Date().toISOString().split("T")[0]);
    } else {
      setSelectedDates(date);
    }
    if (Array.isArray(date) && date.length >= 2) {
      const formattedDates = date.map(
        (date) => date.toISOString().split("T")[0]
      );
      setStartDate(formattedDates[0]);
      setEndDate(formattedDates[1]);
    }
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-indexed
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;

    return formattedDate;
  }

  const generateDateRange = (start, end) => {
    const dateRange = [];
    const current = new Date(start);
    while (current <= end) {
      dateRange.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    return dateRange;
  };

  const datesToShow = generateDateRange(new Date(startDate), new Date(endDate));

  const today = new Date();
  const formattedToday = today.toISOString().split("T")[0];

  const handleCloseButtonClick = () => {
    dispatch(
      fetchBidReports({ startDate: formattedToday, endDate: formattedToday })
    );
  };

  useEffect(() => {
    dispatch(fetchBidReports({ startDate: startDate, endDate: endDate }));
  }, [dispatch, startDate, endDate, selectedDates]);

  return (
    <>
      <Box className="content-header leads-overview-filter bid-overview-wrapper">
        <Box className="report-top-filter">
          <Typography variant="h2" component="h2" className="title-with-btn">
              <Button
                  variant="outlined"
                  className="action-btn back-btn"
                  startIcon={<RiArrowLeftLine />}
                  disableRipple
                  onClick={() => navigate("/reports")}
                  ></Button>
                Bids Overview
          </Typography>
          <DateRangePicker
            showOneCalendar
            className="modal-date-picker"
            style={{ width: "241px" }}
            ranges={ranges}
            onChange={handleDateRangeChange}
            value={selectedDates}
            placeholder="DD-MM-YYYY~DD-MM-YYYY"
            format="dd-MM-yyyy"
            renderStaticRangeLabel={() => null} // This removes the predefined ranges label
            renderCalendarInfo={() => (
              <button onClick={handleCloseButtonClick}>Close</button>
            )}
          />
        </Box>
      </Box>
      <Box className="content-layout bids-overflow-wrapper">
        <Box className="card-wrapper p-24">
        <TableContainer
                  className="billable-report-table-wrapper"
                  style={{
                    maxHeight:
                      bids.length !== 0 ? "calc(100vh - 230px)" : "auto",
                    borderRadius: "5px",
                    width: "100%",
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            backgroundColor: "rgb(197, 227, 137)",
                            position: "sticky",
                            top: 0,
                            left: 0,
                            zIndex: 3,
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Date
                        </TableCell>
                        {bids?.bidsWithinDateRange?.map((bid) => (
                          <TableCell
                            colSpan={3}
                            style={{
                              textAlign: "center",
                              position: "sticky",
                              top: 0,
                              backgroundColor: "rgb(197, 227, 137)",
                              fontWeight: 600,
                              fontSize: "16px",
                              zIndex: 2,
                            }}
                          >
                            {bid?.userWithCounts?.first_name}{" "}
                            {bid?.userWithCounts?.last_name}
                          </TableCell>
                        ))}
                      </TableRow>
                      <TableRow className="report-table-row">
                        <TableCell
                          sx={{
                            position: "sticky",
                            left: "0",
                            top: "56px",
                            zIndex: 3,
                            backgroundColor: "#323232",
                          }}
                          className="report-table-date"
                        >
                          {" "}
                        </TableCell>
                        {bids?.bidsWithinDateRange?.map((bid, index) => (
                          <>
                            <TableCell
                              key={index + "billable"}
                              style={{
                                textAlign: "center",
                                color: "#ffffff",
                                borderLeft: "1px solid white",
                                position: "sticky",
                                top: "56px",
                                backgroundColor: "#323232",
                                zIndex: 2,
                              }}
                            >
                              Bids
                            </TableCell>
                            <TableCell
                              key={index + "nonbillable"}
                              style={{
                                textAlign: "center",
                                color: "#ffffff",
                                // borderRight: "1px solid white",
                                position: "sticky",
                                top: "56px",
                                backgroundColor: "#323232",
                                zIndex: 2,
                              }}
                            >
                              <Tooltip
                                title={
                                  <div
                                    style={{ color: "white", fontSize: "13px" }}
                                  >
                                    Bid's Response Date
                                  </div>
                                }
                                arrow
                                placement="bottom-start"
                              >
                                <Typography
                                  variant="div"
                                  component="div"
                                  style={{ color: "#ffffff" }}
                                >
                                  Opened
                                </Typography>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              key={index + "closure"}
                              style={{
                                textAlign: "center",
                                color: "#ffffff",
                                borderRight: "1px solid white",
                                position: "sticky",
                                top: "56px",
                                backgroundColor: "#323232",
                                zIndex: 2,
                              }}
                            >
                              Closed
                            </TableCell>
                          </>
                        ))}
                      </TableRow>
                      {datesToShow?.map((date) => {
                        const formattedDate = date.toISOString().split("T")[0];
                        const bidsForDate = bids?.bidsWithinDateRange?.filter(
                          (bid) =>
                            bid.userWithCounts && bid.userWithCounts.userBids
                        );
                        return (
                          <TableRow key={date.toISOString()}>
                            <TableCell
                              sx={{
                                textAlign: "left",
                                position: "sticky",
                                left: "0",
                                zIndex: 2,
                                backgroundColor: "#ffff",
                              }}
                            >
                              <Box style={{ width: "150px" }}>
                                {formatDate(date)}
                              </Box>
                            </TableCell>
                            {bidsForDate?.map((bid) => {
                              const userBid =
                                bid?.userWithCounts?.userBids?.find(
                                  (userBid) =>
                                    userBid?.bid_date?.split("T")[0] ===
                                    formattedDate
                                );
                              const userConvertedBid =
                                bid?.userWithCounts?.userBids?.find(
                                  (userBid) =>
                                    userBid?.bid_date?.split("T")[0] ===
                                    formattedDate
                                );
                              const userClosureBid =
                                bid?.userWithCounts?.userBids?.find(
                                  (userBid) =>
                                    userBid?.bid_date?.split("T")[0] ===
                                    formattedDate
                                );
                              return (
                                <>
                                  <TableCell className="time">
                                    {userBid ? (
                                      userBid.number_of_bids > 0 ? (
                                        <StyledBadge
                                          badgeContent={userBid.number_of_bids}
                                        >
                                        </StyledBadge>
                                      ) : (
                                        0 
                                      )
                                    ) : (
                                      0 
                                    )}
                                  </TableCell>

                                  <TableCell className="time">
                                    {userConvertedBid ? (
                                      userConvertedBid.number_of_converted_bids >
                                      0 ? (
                                        <StyledBadge
                                          badgeContent={
                                            userConvertedBid?.number_of_converted_bids
                                          }
                                        >
                                        </StyledBadge>
                                      ) : (
                                        0 
                                      )
                                    ) : (
                                      0 
                                    )}
                                  </TableCell>
                                  <TableCell className="time">
                                    {userClosureBid ? (
                                      userClosureBid.number_of_closure_bids >
                                      0 ? (
                                        <StyledBadge
                                          badgeContent={
                                            userClosureBid?.number_of_closure_bids
                                          }
                                        >
                                        </StyledBadge>
                                      ) : (
                                        0 
                                      )
                                    ) : (
                                      0 
                                    )}
                                  </TableCell>
                                </>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                      <TableRow>
                        {" "}
                        <TableCell
                          sx={{
                            textAlign: "left",
                            position: "sticky",
                            left: "0",
                            zIndex: 3,
                            backgroundColor: "#323232",
                            color: "white",
                            bottom: "56px",
                            borderRight: "1px solid white",
                          }}
                        >
                          Total
                        </TableCell>
                        {bids?.bidsWithinDateRange?.map((bid, index) => (
                          <React.Fragment key={index}>
                            <TableCell
                              className="time"
                              sx={{
                                textAlign: "left",
                                position: "sticky",
                                left: "0",
                                zIndex: 2,
                                backgroundColor: "#323232",
                                color: "white",
                                bottom: "56px",
                              }}
                            >
                              {bid?.userWithCounts?.total_number_of_bids > 0 ? (
                                <TotalBadge
                                  badgeContent={
                                    bid?.userWithCounts?.total_number_of_bids
                                  }
                                  max={9999}
                                >
                                </TotalBadge>
                              ) : (
                                0
                              )}
                            </TableCell>

                            <TableCell
                              className="time"
                              sx={{
                                textAlign: "left",
                                position: "sticky",
                                left: "0",
                                zIndex: 1,
                                backgroundColor: "#323232",
                                color: "white",
                                bottom: "56px",
                              }}
                            >
                              {bid?.userWithCounts
                                ?.total_number_of_converted_bids > 0 ? (
                                <TotalBadge
                                  badgeContent={
                                    bid?.userWithCounts
                                      ?.total_number_of_converted_bids
                                  }
                                  max={9999}
                                >
                                </TotalBadge>
                              ) : (
                                0 
                              )}
                            </TableCell>

                            <TableCell
                              className="time"
                              sx={{
                                textAlign: "left",
                                position: "sticky",
                                left: "0",
                                zIndex: 1,
                                backgroundColor: "#323232",
                                color: "white",
                                bottom: "56px",
                                borderRight: "1px solid white",
                              }}
                            >
                              {bid?.userWithCounts
                                ?.total_number_of_Closure_bids > 0 ? (
                                <TotalBadge
                                  badgeContent={
                                    bid?.userWithCounts
                                      ?.total_number_of_Closure_bids
                                  }
                                  max={9999}
                                >
                                </TotalBadge>
                              ) : (
                                0 
                              )}
                            </TableCell>
                          </React.Fragment>
                        ))}
                      </TableRow>
                      <TableRow>
                        {" "}
                        <TableCell
                          sx={{
                            textAlign: "left",
                            position: "sticky",
                            left: "0",
                            zIndex: 3,
                            backgroundColor: "rgb(197, 227, 137)",
                            bottom: "0",
                            borderRight: "1px solid white",
                            fontWeight: 600,
                          }}
                        >
                          Success Rate (%)
                        </TableCell>
                        {bids?.bidsWithinDateRange?.map((bid, index) => (
                          <React.Fragment key={index}>
                            <TableCell
                              className="time"
                              sx={{
                                textAlign: "left",
                                position: "sticky",
                                left: "0",
                                zIndex: 1,
                                backgroundColor: "rgb(197, 227, 137)",
                                bottom: "0",
                                fontWeight: 600,
                              }}
                            ></TableCell>

                            <TableCell
                              className="time"
                              sx={{
                                textAlign: "left",
                                position: "sticky",
                                left: "0",
                                zIndex: 1,
                                backgroundColor: "rgb(197, 227, 137)",
                                bottom: "0",
                                fontWeight: 600,
                              }}
                            >
                              {`${bid?.userWithCounts?.successRatioofopenbids} %`}
                            </TableCell>

                            <TableCell
                              className="time"
                              sx={{
                                textAlign: "left",
                                position: "sticky",
                                left: "0",
                                zIndex: 1,
                                backgroundColor: "rgb(197, 227, 137)",
                                bottom: "0",
                                borderRight: "1px solid white",
                                fontWeight: 600,
                              }}
                            >
                              {`${bid?.userWithCounts?.successRatioofclosebids} %`}
                            </TableCell>
                          </React.Fragment>
                        ))}
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default BidReportList;

import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import {
  RiAddCircleLine,
  RiArrowLeftLine,
  RiDeleteBinLine,
} from "react-icons/ri";
import { useNavigate, useLocation } from "react-router-dom";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { useDispatch, useSelector } from "react-redux";
import { fetchKeywords } from "../../store/slices/keywordSlice";
import { fetchTechnologies } from "../../store/slices/technologySlice";
import { createLead } from "../../store/slices/createLeadSlice";
import { successToast } from "../../responses/successToast";
import { fetchFeedbacks } from "../../store/slices/getFeedbackSlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import KeywordModal from "../../components/common/keywordModal";
import AddKeywordModal from "../../components/common/addKeywordModal";
import TechnologyModal from "../../components/common/technologyModal";
import AddTechnologyModal from "../../components/common/addTechnologySlice";
import { fetchClients } from "../../store/slices/clientLeadSlice";
import { fetchContacts } from "../../store/slices/contactSlice";
import { fetchUsers } from "../../store/slices/userSlice";
import ContactDrawer from "../../components/common/contactDrawer";
import ConvertAlertModal from "../../components/common/convertAlertModal";
import { callApi } from "../../components/common/commonfunctionforapi";
import EmailAlertModal from "../../components/common/emailAlertModal";
import { fetchBidsById } from "../../store/slices/bidByIdSlice";
import { maskEmail, maskPhoneNumber } from "../../utils/masking";
import { selectIsAdmin } from "../../constants/selectors";
// LEAD SOURCE OPTIONS
const leadSourceOptions = [
  { value: "Upwork", label: "Upwork" },
  { value: "LinkedIn", label: "LinkedIn" },
  { value: "Guru", label: "Guru" },
  { value: "PPH", label: "PPH" },
  { value: "Fiverr", label: "Fiverr" },
  { value: "Clutch", label: "Clutch" },
  { value: "Website", label: "Website" },
  { value: "Email", label: "Email" },
  { value: "Skype", label: "Skype" },
  { value: "Recommendation", label: "Recommendation" },
  { value: "Freelancer.com", label: "Freelancer.com" },
];

// LEAD STATUS OPTIONS
const leadStatusOptions = [
  { value: "open", label: "Open" },
  { value: "estimation_requested", label: "Estimation Requested" },
  { value: "estimation_provided", label: "Estimation Provided" },
  { value: "closed", label: "Not Responding" },
  { value: "won", label: "Won" },
  { value: "lost", label: "Lost" },
  { value: "standBy", label: "StandBy" },
];

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};

function capitalizeWords(input) {
  return input?.replace(/\w+/g, function (word) {
    return word?.charAt(0).toUpperCase() + word.slice(1);
  });
}

const LeadCreate = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bidId = queryParams.get("id");
  const getUserRole = useSelector(selectIsAdmin);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [compName, setCompName] = useState("");
  const [compEmail, setCompEmail] = useState("");
  const [compNumber, setCompNumber] = useState("");
  const [conPerson, setConPerson] = useState("");
  const [conPersonEmail, setConPersonEmail] = useState("");
  const [conPersonNumber, setConPersonNumber] = useState("");
  const [keyFeatures, setKeyFeatures] = useState("");
  const [keyIntegrations, setKeyIntegrations] = useState("");
  const [sourcePayload, setSourcePayload] = useState("");
  const [projectName, setProjectName] = useState("");
  const [estimationLink, setEstimationLink] = useState("");
  const [estimationTime, setEstimationTime] = useState("");
  const [feedbackNote, setFeedbackNote] = useState("");
  const [leadStatus, setLeadStatus] = useState("");
  const keywords = useSelector((state) => state.keywords.keywords);
  const technologies = useSelector((state) => state.technologies.technologies);
  const feedbacks = useSelector((state) => state.feedbacks.feedbacks);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [keywordPayload, setKeywordPayload] = useState([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const [technologyPayload, setTechnologyPayload] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState([]);
  const [feedbackPayload, setFeedbackPayload] = useState([]);

  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [uploadLink, setUploadLink] = useState("");
  const [documentUploadLink, setDocumentUploadLink] = useState("");
  const [imageUploadLink, setImageUploadLink] = useState("");
  const [filename, setFileName] = useState("");
  const [videoFileName, setVideoFileName] = useState("");
  const [imageFileName, setImageFileName] = useState("");
  const [displayedImages, setDisplayedImages] = useState([]);
  const [displayedVideos, setDisplayedVideos] = useState([]);
  const [displayedDocuments, setDisplayedDocuments] = useState([]);
  const [conPerErrorMsg, setConPerErrorMsg] = useState("");
  const [leadSrcErrorMsg, setLeadSrcErrorMsg] = useState("");
  const [leadStatusErrorMsg, setLeadStatusErrorMsg] = useState("");
  const [keywordModalOpen, setKeywordModalOpen] = useState(false);
  const [technologyModalOpen, setTechnologyModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [invalidValue, setInvalidValue] = useState("");
  const [techLoading, setTechLoading] = useState(false);
  const [addTechModal, setAddTechModal] = useState(false);
  const [validTechName, setValidTechName] = useState("");
  const [compEmailError, setCompEmailError] = useState("");
  const [conPerEmailError, setContactPerEmailError] = useState("");
  const [uploadLinkError, setUploadLinkError] = useState("");
  const [value, setValue] = useState("new_opportunity");
  const clients = useSelector((state) => state.clientsLead.clients[0]);
  const [companies, setCompanies] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyPayload, setCompanyPayload] = useState("");
  const users = useSelector((state) => state.users.users);
  const [type /*setType*/] = useState("");
  const [selectedTL, setSelectedTL] = useState("");
  const [selectedBDE, setSelectedBDE] = useState("");
  const [selectedBDA, setSelectedBDA] = useState("");
  const contacts = useSelector((state) => state.contacts.contacts);
  const [companiesperson, setCompaniesPerson] = useState("");
  const [companyPayloadPerson, setCompanyPayloadPerson] = useState("");
  const [companyerror, setCompanyError] = useState("");
  const [open, setOpen] = useState(false);
  const [displayedfilenames, setDisplayedFilenames] = useState([]);
  const [displayedVideofilenames, setDisplayedVideoFilenames] = useState([]);
  const [displayedImagefilenames, setDisplayedImageFilenames] = useState([]);
  const [documentlinkerror, setdocumentLinkError] = useState("");
  const [imagelinkerror, setImageLinkError] = useState("");
  const [emailOpen, setEmailOpen] = useState(false);
  const [sameEmailMessage, setSameEmailMessage] = useState("");
  const bids = useSelector((state) => state.bidsbyId.bids);
  const [documentPaths, setDocumentPaths] = useState([]);
  const [selectedDocImgId, setSelectedDocImgId] = useState([]);
  // lead convert
  const [convertCancel, setConvertCancel] = useState(false);
  const [projecterror, setProjectError] = useState("");
  const [bdaError, setBdaError] = useState("");
  const editContact = false;
  const currentPage = 1;
  const maxLength = 31;

  const initiateLeadCreation = () => {
    // Clear selectedKeywords and set empty array
    setSelectedKeywords([]);
    setKeywordPayload([]);

    // Clear selectedTechnologies and set empty array
    setSelectedTechnologies([]);
    setTechnologyPayload([]);

    // Other lead creation logic...
  };

  const validateEmail = (email) => {
    const emailPattern =
      /^[a-zA-Z0-9](?!.*\.\.)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,63})+$/;
    return emailPattern.test(email);
  };

  const handleRemoveImage = (index) => {
    const updatedVideos = [...displayedImages];
    const updatedVideoPaths = [...displayedImagefilenames];

    // Remove the document and its path at the specified index
    updatedVideos.splice(index, 1);
    updatedVideoPaths.splice(index, 1);

    // Update the state with the modified arrays
    setDisplayedImages(updatedVideos);
    setDisplayedImageFilenames(updatedVideoPaths);
  };

  const handleRemoveVideo = (index) => {
    const updatedVideos = [...displayedVideos];
    const updatedVideoPaths = [...displayedVideofilenames];

    // Remove the document and its path at the specified index
    updatedVideos.splice(index, 1);
    updatedVideoPaths.splice(index, 1);

    // Update the state with the modified arrays
    setDisplayedVideos(updatedVideos);
    setDisplayedVideoFilenames(updatedVideoPaths);
  };

  const handleRemoveDocument = (index, arrayName) => {
    if (arrayName === "selectedDocuments") {
      const updatedDocPaths = [...documentPaths];

      // Remove the document and its path at the specified index
      const updatedDocuments = selectedDocuments.filter(
        (image) => image.linkId !== index
      );

      // Update the state with the modified arrays
      setSelectedDocuments(updatedDocuments);
      setDocumentPaths(updatedDocPaths);
      setSelectedDocImgId([...selectedDocImgId, index]);
    } else if (arrayName === "addedDocuments") {
      const updatedDocuments = [...displayedDocuments];
      const updatedDocPaths = [...displayedfilenames];

      // Remove the document and its path at the specified index
      updatedDocuments.splice(index, 1);
      updatedDocPaths.splice(index, 1);

      // Update the state with the modified arrays
      setDisplayedDocuments(updatedDocuments);
      setDisplayedFilenames(updatedDocPaths);
    }
  };
  //images upload

  // START :: LEAD LINK ADD AND DELETE
  const [projectLinks, setProjectLinks] = useState([
    {
      linkText: "",
    },
  ]);
  const addProjectLink = () => {
    let link = {
      linkText: "",
    };
    setProjectLinks((current) => [...current, link]);
  };

  const addTextToLink = (value, i) => {
    let linkArray = [...projectLinks];
    linkArray[i].linkText = value;
    setProjectLinks(linkArray);
  };

  const handleDeleteLink = (i) => {
    let linkArray = [...projectLinks];
    linkArray.splice(i, 1);
    setProjectLinks(linkArray);
  };
  // END :: LEAD LINK ADD AND DELETE

  // START :: REFERENCE LINK ADD AND DELETE
  const [ReferenceLinks, setReferenceLinks] = useState([
    {
      linkText: "",
    },
  ]);
  const addReferenceLink = () => {
    let link = {
      linkText: "",
    };
    setReferenceLinks((current) => [...current, link]);
  };

  const addReferenceToLink = (value, i) => {
    let linkArray = [...ReferenceLinks];
    linkArray[i].linkText = value;
    setReferenceLinks(linkArray);
  };

  const handleReferenceDeleteLink = (i) => {
    let linkArray = [...ReferenceLinks];
    linkArray.splice(i, 1);
    setReferenceLinks(linkArray);
  };
  // };
  // END :: REFERENCE LINK ADD AND DELETE
  const [defaultContactPerson, setDefaultContactPerson] = useState(null);
  const [selectedContact, setSelectedContact] = useState(defaultContactPerson);
  useEffect(() => {
    if (contacts?.contacts && contacts?.contacts.length > 0) {
      const primaryContact = contacts?.contacts?.find(
        (contact) => contact.is_primary
      );

      if (primaryContact) {
        setSelectedContact({
          label: primaryContact.contact_person_name,
          value: primaryContact.id,
        });
      }
    }
  }, [contacts, companyName]);

  useEffect(() => {
    if (selectedContact) {
      setDefaultContactPerson(selectedContact);
      setCompanyPayloadPerson(selectedContact.label);
      setCompaniesPerson(selectedContact.value);
    }
  }, [selectedContact, companyName]);

  //API call for clients
  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  //API call for keywords
  useEffect(() => {
    // Dispatch the fetchKeywords action to fetch the keywords
    dispatch(fetchKeywords({ query: "" }));
  }, [dispatch]);

  //API call for technologies
  useEffect(() => {
    // Dispatch the fetchKeywords action to fetch the keywords
    dispatch(fetchTechnologies({ query: "" }));
  }, [dispatch]);

  //API call for feddbacks
  useEffect(() => {
    // Dispatch the fetchKeywords action to fetch the keywords
    dispatch(fetchFeedbacks());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchUsers({
        type: type,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchBidsById({ bidId }));
  }, [dispatch, bidId]);
  useEffect(() => {
    if (bidId) {
      setLeadStatus("open");
      setSourcePayload(bids?.platform);
      // eslint-disable-next-line no-unused-vars
      setDisplayedDocuments([bids?.url]);
      setDisplayedFilenames([bids?.platform]);
      setProjectName(bids?.title);
      const selectedOptionForBdmId = BDAOptions?.find(
        (option) => option.value === bids?.bdmId
      );
      const initialSelectedBDA = selectedOptionForBdmId?.value || "";
      setSelectedBDA(initialSelectedBDA);

      const selectedOptionForBdeID = BDEOptions?.find(
        (option) => option.value === bids?.bdeId
      );
      const initialSelectedBDE = selectedOptionForBdeID?.value || "";
      setSelectedBDE(initialSelectedBDE);

      const initialKeywordOptions = bids?.bid_keywords?.map((keywordName) => ({
        value: keywordName?.keywords?.id,
        label: keywordName?.keywords?.keyword_name,
        id: keywordName?.id,
      }));

      // Set the initial options for the Select component
      setSelectedKeywords(initialKeywordOptions);

      const keywordIds =
        bids?.bid_keywords?.map((keyword) => keyword.keyword_id) || [];
      setKeywordPayload(() => [...keywordIds]);

      const initialTechnologyOptions = bids?.bid_technologies?.map(
        (techName) => ({
          value: techName?.technologies?.id,
          label: techName?.technologies?.technology_name,
          id: techName?.id,
        })
      );

      setSelectedTechnologies(initialTechnologyOptions);
      const technologyIds =
        bids?.bid_technologies?.map((tech) => tech.technology_id) || [];
      setTechnologyPayload(() => [...technologyIds]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bids]);

  const handleLinkClick = (event, website) => {
    if (website && !website.startsWith("http")) {
      // If the URL doesn't start with "http" or "https," prevent default behavior
      event.preventDefault();
      window.open(`http://${website}`, "_blank");
    }
  };
  const handleCreate = (inputValue) => {
    setIsLoading(true);
    setInvalidValue(inputValue);

    setTimeout(() => {
      setIsLoading(false);

      setShowModal(true);
    }, 1000); // Simulate the loading process
  };

  // Map the data to the desired format for keywords
  const keywordsData = keywords?.allKeywords?.map((keyword) => ({
    value: keyword.id,
    label: keyword.name,
  }));

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeywordSelect = (selectedOptions) => {
    setSelectedKeywords(selectedOptions);

    const selectedIds = selectedOptions.map((option) => option.value);
    setKeywordPayload(selectedIds);
  };

  const technologiesData = technologies?.allTechnologies?.map((technology) => ({
    value: technology.id,
    label: technology.name,
  }));
  const handleTechCreate = (inputValue) => {
    setTechLoading(true);
    setValidTechName(inputValue);

    setTimeout(() => {
      setTechLoading(false);

      setAddTechModal(true);
    }, 1000); // Simulate the loading process
  };

  const handleTechnologySelect = (selectedOptions) => {
    setSelectedTechnologies(selectedOptions);

    const selectedIds = selectedOptions.map((option) => option.value);

    setTechnologyPayload(selectedIds);
  };

  const feedbackssData = feedbacks.map((feedback) => ({
    value: feedback.id,
    label: feedback.feedback,
  }));

  const handleFeedbackSelect = (selectedOptions) => {
    setSelectedFeedback(selectedOptions);

    const selectedIds = selectedOptions.map((option) => option.value);

    setFeedbackPayload(selectedIds);
  };

  //keyfeature handler
  const handleKeyFeatureChange = (data) => {
    setKeyFeatures(data);
  };

  const handleKeyIntegrationChange = (data) => {
    setKeyIntegrations(data);
  };

  const handleSourceSelect = (selectedValue) => {
    setSourcePayload(selectedValue);
    setLeadSrcErrorMsg("");
  };

  //feedback area
  const handleFeedbackChange = (event) => {
    const value = event.target.value;

    setFeedbackNote(value);
  };
  //lead status
  const handleLeadStatusSelect = (selectedValue) => {
    setLeadStatus(selectedValue); // Update the leadStatus state with the selected value
    setLeadStatusErrorMsg("");
  };
  const addDocument = () => {
    if (!documentUploadLink) {
      setdocumentLinkError("Please enter document link");
      return;
    }
    const newVideo = documentUploadLink;

    setDisplayedDocuments([...displayedDocuments, newVideo]);
    setDisplayedFilenames((prevFilenames) => [...prevFilenames, filename]);
    setFileName("");
    setDocumentUploadLink("");
  };

  const addVideo = () => {
    if (!uploadLink) {
      setUploadLinkError("Please enter video cast link");
      return;
    }
    const newVideo = uploadLink;
    /* Your logic to create a video object from uploadLink */
    // Clear the uploadLink input

    setDisplayedVideos([...displayedVideos, newVideo]);
    setDisplayedVideoFilenames((prevFilenames) => [
      ...prevFilenames,
      videoFileName,
    ]);
    setVideoFileName("");
    setUploadLink("");
  };

  const addImage = () => {
    if (!imageUploadLink) {
      setImageLinkError("Please enter screenshot Link");
      return;
    }
    const newVideo = imageUploadLink;

    setDisplayedImages([...displayedImages, newVideo]);
    setDisplayedImageFilenames((prevFilenames) => [
      ...prevFilenames,
      imageFileName,
    ]);
    setImageFileName("");
    setImageUploadLink("");
  };
  const formData = new FormData();

  displayedImages.forEach((image, index) => {
    formData.append(`screenshot_files[${[index]}]`, image);
  });
  displayedImagefilenames.forEach((image, index) => {
    formData.append(`screenshot_file_name[${[index]}]`, image);
  });

  displayedVideos.forEach((video, index) => {
    formData.append(`video_urls[${[index]}]`, video);
  });
  displayedVideofilenames.forEach((video, index) => {
    formData.append(`video_file_name[${[index]}]`, video);
  });
  displayedDocuments.forEach((document, index) => {
    formData.append(`document_urls[${[index]}]`, document);
  });
  displayedfilenames.forEach((document, index) => {
    formData.append(`file_name[${[index]}]`, document);
  });

  selectedDocuments.forEach((document) => {
    formData.append(`document_files`, document);
  });

  const referenceLinks = ReferenceLinks;

  keywordPayload?.forEach((value) => {
    formData.append("keyword_ids[]", value); // Assuming it's a comma-separated string
  });
  technologyPayload?.forEach((value) => {
    formData.append("technology_ids[]", value); // Assuming it's a comma-separated string
  });
  feedbackPayload.forEach((value) => {
    formData.append("feedback_ids", value); // Assuming it's a comma-separated string
  });
  const referenceLinksArray = referenceLinks.map((element) => element.linkText);

  referenceLinksArray.forEach((value) => {
    formData.append("reference_links[]", value); // Assuming it's a comma-separated string
  });

  const projectLinksArray = projectLinks.map((element) => element.linkText);

  projectLinksArray.forEach((value) => {
    formData.append("lead_links[]", value);
  });

  const companyOptions = clients?.client?.map((client) => ({
    value: client.id,
    label: client.company_name,
  }));

  const companyDetails = clients?.client?.filter(
    (client) => companies.value === client.id
  );

  const companyEmail = companyDetails?.map((email) => email?.company_email);
  const maskedCompanyEmail = companyEmail?.map((email) => maskEmail(email));

  const companyPhone = companyDetails?.map((phone) => phone?.company_phone);
  const maskedComapnyPhone = companyPhone?.map((phone) =>
    maskPhoneNumber(phone)
  );

  const clientId = companyDetails?.[0]?.client_contacts?.map(
    (contact) => contact.client_id
  )[0];
  const contactPersonOptions = contacts?.contacts?.map((contact) => ({
    value: contact.id,
    label: contact.contact_person_name,
  }));
  const contactPersonDetails = contacts?.contacts?.filter(
    (contact) => selectedContact?.value === contact.id
  );
  const contactPersonEmail = contactPersonDetails?.map(
    (contact) => contact?.contact_person_email
  );
  const contactPersonEmailMasked = contactPersonEmail?.map((email) =>
    maskEmail(email)
  );
  const contactPersonPhone = contactPersonDetails?.map(
    (contact) => contact.contact_person_phone
  );
  const contactPersonPhoneMasked = contactPersonPhone?.map((phone) =>
    maskPhoneNumber(phone)
  );

  const contactPersonRef = useRef(null);
  const companyNameRef = useRef(null);
  const statusRef = useRef(null);
  const sourceRef = useRef(null);
  const compEmailRef = useRef(null);
  const contEmailRef = useRef(null);
  const projectRef = useRef(null);
  const bdaRef = useRef(null);
  const [saveButtonClickCount, setSaveButtonClickCount] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [iswonButtonDisabled, setIsWonButtonDisabled] = useState(false);

  useEffect(() => {
    if (compEmailError && compEmailRef.current) {
      compEmailRef.current.focus();
    }
  }, [compEmailRef, compEmailError, saveButtonClickCount]);

  useEffect(() => {
    if (conPerEmailError && contEmailRef.current) {
      contEmailRef.current.focus();
    }
  }, [conPerEmailError, contEmailRef, saveButtonClickCount]);

  useEffect(() => {
    if (leadStatusErrorMsg && statusRef.current) {
      statusRef.current.focus();
    }
  }, [statusRef, leadStatusErrorMsg, saveButtonClickCount]);

  useEffect(() => {
    if (projecterror && projectRef.current) {
      projectRef.current.focus();
    }
  }, [projectRef, projecterror, saveButtonClickCount]);

  useEffect(() => {
    // Scroll to the "Technology Stacks" Typography component
    if (bdaError && bdaRef.current) {
      bdaRef.current.focus();
    }
  }, [bdaError, bdaRef, saveButtonClickCount]);

  useEffect(() => {
    if (leadSrcErrorMsg && sourceRef.current) {
      sourceRef.current.focus();
    }
  }, [sourceRef, leadSrcErrorMsg, saveButtonClickCount]);

  useEffect(() => {
    if (conPerErrorMsg && contactPersonRef.current) {
      contactPersonRef.current.focus();
    }
  }, [contactPersonRef, conPerErrorMsg, saveButtonClickCount]);

  useEffect(() => {
    if (companyerror && companyNameRef.current) {
      companyNameRef.current.focus();
    }
  }, [companyNameRef, companyerror, saveButtonClickCount]);

  const submitData = async () => {
    setSaveButtonClickCount((prevCount) => prevCount + 1);
    if (isButtonDisabled) {
      return;
    }
    let flag = 0;
    // scrollToCompNameIfNeeded();
    if (value === "new_opportunity") {
      if (!compName.trim()) {
        // errorToast("Please fill in all the required fields marked with a star ( * )");
        setCompanyError("Please enter company name");
        flag = 1;
      }

      if (!conPerson.trim()) {
        // errorToast("Please fill in all the required fields marked with a star ( * )");

        // Set conPerErrorMsg only if compEmailError is not the specific error
        setConPerErrorMsg("Please enter contact person name");

        flag = 1;
      }
    }
    if (value === "existing_client") {
      if (!companyPayload) {
        // errorToast("Please fill in all the required fields marked with a star ( * )");
        setCompanyError("Please select company name");
        flag = 1;
      }
      if (!companyPayloadPerson) {
        // errorToast("Please fill in all the required fields marked with a star ( * )");
        setConPerErrorMsg("Please select contact person name");
        return;
      }
    }
    if (!sourcePayload) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setLeadSrcErrorMsg("Please select lead source");
      flag = 1;
    }
    if (!projectName) {
      setProjectError("Please enter project name");
      flag = 1;
    }
    if (!leadStatus) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setLeadStatusErrorMsg("Please select lead status");
      flag = 1;
    }
    if (compEmailError === "Please enter a valid email address") {
      // errorToast("Please enter a valid email address");
      flag = 1;
    }
    if (conPerEmailError === "Please enter a valid email address") {
      // errorToast("Please enter a valid email address");
      flag = 1;
    }
    if (!selectedBDA) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setBdaError("Please select BDM");
      flag = 1;
    }
    if (flag !== 0) {
      return;
    }
    setIsButtonDisabled(true);
    const leadData = {
      company_name: value === "new_opportunity" ? compName : companyPayload,
      company_email: value === "new_opportunity" ? compEmail : companyEmail,
      company_phone: value === "new_opportunity" ? compNumber : companyPhone,
      contact_person:
        value === "new_opportunity" ? conPerson : companyPayloadPerson,
      contact_person_email:
        value === "new_opportunity" ? conPersonEmail : contactPersonEmail,
      contact_person_phone:
        value === "new_opportunity" ? conPersonNumber : contactPersonPhone,
      key_features: keyFeatures,
      key_integration: keyIntegrations,

      lead_source: sourcePayload,
      project_name: projectName,
      ...(bidId && { bid_id: bidId }),
      estimation_link: estimationLink,
      estimation_hours: estimationTime,
      feedback_note: feedbackNote,
      status: leadStatus,
      bda_id: selectedBDA,
      bde_id: selectedBDE,
      tl_id: selectedTL,
      client_id: value === "new_opportunity" ? "" : clientId,
      client_contact_id: value === "new_opportunity" ? "" : companiesperson,
    };

    for (const key in leadData) {
      if (Object.hasOwnProperty.call(leadData, key)) {
        formData.append(key, leadData[key]);
      }
    }
    try {
      const response = await dispatch(
        createLead({ formData, client_type: value })
      );

      if (response.success) {
        navigate("/leads");
        successToast("Lead created successfully!");
        initiateLeadCreation();
      } else {
        console.error("Error creating lead:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      setIsButtonDisabled(false);
    }
  };

  const handleCompanySelect = (selectedOptions) => {
    if (selectedOptions) {
      setCompanyError("");
    }
    setCompanies(selectedOptions);
    setCompanyName(selectedOptions.value);
    setCompanyPayload(selectedOptions.label);
  };

  const closeKeyworddModal = () => setKeywordModalOpen(false);
  const closeTechnologyModal = () => setTechnologyModalOpen(false);
  useEffect(() => {
    if (clientId) {
      dispatch(fetchContacts({ clientId: clientId, page: "", limit: "" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, companyName]);

  const teamleaders = users?.filteredTeamManager;
  const BDA = users?.filteredBda;
  const BDE = users?.filteredBde;

  const TeamLeaderoptions = teamleaders?.map((tl) => ({
    value: tl.id,
    label: `${capitalizeWords(tl.first_name)} ${capitalizeWords(tl.last_name)}`,
  }));

  const BDAOptions = BDA?.map((tl) => ({
    value: tl.id,
    label: `${capitalizeWords(tl.first_name)} ${capitalizeWords(tl.last_name)}`,
  }));
  const BDEOptions = BDE?.map((tl) => ({
    value: tl.id,
    label: `${capitalizeWords(tl.first_name)} ${capitalizeWords(tl.last_name)}`,
  }));

  const handleBDESelect = (selectedOption) => {
    setSelectedBDE(selectedOption);
  };

  const handleBDASelect = (selectedOption) => {
    if (selectedOption.value !== null) {
      setSelectedBDA(null);
    }
    setSelectedBDA(selectedOption);
    setBdaError("");
  };

  const handleTLSelect = (selectedOption) => {
    setSelectedTL(selectedOption);
  };

  const emailCheckHandler = async () => {
    if (compEmail && validateEmail(compEmail)) {
      setCompEmailError(""); // Email is present and valid, clear the error message.
    } else {
      setCompEmailError("Please enter a valid email address");
    }
    if (!compEmail) {
      setCompEmailError("");
    }

    try {
      if (compEmail) {
        const response = await callApi({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/leads/checkemail`,
          params: {
            email: compEmail,
          },
        });
        setSameEmailMessage(response);
        if (response.data.length > 0) {
          setEmailOpen(true);
        }
        return response.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (value === "existing_client") {
      setCompEmailError("");
      setCompanyError("");
      setContactPerEmailError("");
      setConPerErrorMsg("");
    }
  }, [value]);

  // const scrollToCompNameIfNeeded = () => {
  //   const element = compNameRef.current;

  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth", block: "start" });
  //   }
  // };
  // ... rest of your component code

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => navigate("/leads")}
          ></Button>
          Create Lead
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Grid container spacing={3}>
          <Grid item lg={8} md={12} sm={12} xs={12}>
            <Box className="card-wrapper" sx={{ height: "100%" }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Lead Details
                  </Typography>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Select Customer
                  </Typography>
                  <RadioGroup
                    aria-label="company"
                    name="company"
                    value={value}
                    onChange={handleChange}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <FormControlLabel
                      value="new_opportunity"
                      control={<Radio />}
                      label={
                        <span style={{ fontSize: "14px" }}>
                          New Opportunity
                        </span>
                      }
                    />
                    <FormControlLabel
                      value="existing_client"
                      control={<Radio />}
                      label={
                        <span style={{ fontSize: "14px" }}>
                          Existing Client
                        </span>
                      }
                    />
                  </RadioGroup>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Name
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>

                  {value === "new_opportunity" ? (
                    <TextField
                      inputRef={companyNameRef}
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Company Name"
                      value={compName}
                      onChange={(event) => {
                        setCompName(event.target.value);
                        if (event.target.value) {
                          setCompanyError("");
                        }
                      }}
                    />
                  ) : (
                    <Select
                      ref={companyNameRef}
                      name="colors"
                      options={companyOptions}
                      className="muilt-select-field"
                      classNamePrefix="select"
                      placeholder="Select Company"
                      value={
                        companyOptions?.find(
                          (option) => option.value === companyName
                        ) || null
                      }
                      onChange={handleCompanySelect}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "#8CC714",
                        },
                      })}
                    />
                  )}
                  {companyerror && (
                    <Typography variant="body1" component="p" className="error">
                      {companyerror}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Email
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Company Email"
                    inputRef={compEmailRef}
                    value={
                      value === "existing_client"
                        ? getUserRole
                          ? companyEmail
                          : maskedCompanyEmail
                        : compEmail
                    }
                    onChange={(event) => {
                      const email = event.target.value;
                      setCompEmail(email);
                      if (value === "new_opportunity") {
                        if (validateEmail(email) || !email) {
                          setCompEmailError(""); // Clear the error message if the email is valid.
                        } else {
                          setCompEmailError(
                            "Please enter a valid email address"
                          );
                        }
                      }
                    }}
                    onBlur={() => {
                      emailCheckHandler();
                    }}
                    disabled={value === "existing_client"}
                  />
                  {compEmailError && (
                    <Typography variant="body2" className="error" color="error">
                      {compEmailError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Phone No.
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Company Phone No."
                    // type="number"
                    value={
                      value === "existing_client"
                        ? getUserRole
                          ? companyPhone
                          : maskedComapnyPhone
                        : compNumber
                    }
                    onChange={(event) => {
                      setCompNumber(event.target.value);
                    }}
                    disabled={value === "existing_client"}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1" component="label">
                      Contact Person
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                  </Stack>
                  {value === "new_opportunity" ? (
                    <TextField
                      inputRef={contactPersonRef}
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Contact Person Name"
                      value={conPerson}
                      onChange={(event) => {
                        setConPerson(event.target.value);

                        if (event.target.value) {
                          setConPerErrorMsg("");
                        }
                      }}
                    />
                  ) : (
                    <Select
                      ref={contactPersonRef}
                      name="colors"
                      options={companyName ? contactPersonOptions : []}
                      className="muilt-select-field"
                      classNamePrefix="select"
                      placeholder="Select Contact Person"
                      onChange={(newValue) => setSelectedContact(newValue)}
                      value={companyName ? selectedContact : null}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "#8CC714",
                        },
                      })}
                    />
                  )}
                  {conPerErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {conPerErrorMsg}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person's Email
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Contact Person's Email"
                    inputRef={contEmailRef}
                    value={
                      value === "existing_client" && companyName
                        ? getUserRole
                          ? contactPersonEmail
                          : contactPersonEmailMasked
                        : conPersonEmail
                    }
                    onChange={(event) => {
                      const email = event.target.value;
                      setConPersonEmail(email);
                      if (value === "new_opportunity") {
                        if (validateEmail(email) || !email) {
                          setContactPerEmailError(""); // Clear the error message if the email is valid.
                        } else {
                          setContactPerEmailError(
                            "Please enter a valid email address"
                          );
                        }
                      }
                    }}
                    onBlur={() => {
                      if (conPersonEmail && validateEmail(conPersonEmail)) {
                        setContactPerEmailError(""); // Email is present and valid, clear the error message.
                      } else {
                        setContactPerEmailError(
                          "Please enter a valid email address"
                        );
                      }
                      if (!conPersonEmail) {
                        setContactPerEmailError("");
                      }
                    }}
                    disabled={value === "existing_client"}
                  />
                  {conPerEmailError && (
                    <Typography variant="body2" className="error" color="error">
                      {conPerEmailError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person's Phone No.
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Contact Person's Phone No."
                    value={
                      value === "existing_client" && companyName
                        ? getUserRole
                          ? contactPersonPhone
                          : contactPersonPhoneMasked
                        : conPersonNumber
                    }
                    onChange={(event) => {
                      setConPersonNumber(event.target.value);
                    }}
                    disabled={value === "existing_client"}
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1" component="label">
                      Keywords
                    </Typography>
                  </Stack>
                  <CreatableSelect
                    isClearable
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    isMulti
                    name="colors"
                    options={keywordsData}
                    className="muilt-select-field"
                    placeholder="Select Keywords"
                    classNamePrefix="select"
                    onChange={handleKeywordSelect}
                    value={selectedKeywords}
                    onCreateOption={handleCreate}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Grid>
                {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1" component="label">
                      All Tls and BDAs
                    </Typography>
                  </Stack>
                  <CreatableSelect
                    isClearable
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    isMulti
                    name="colors"
                    options={AllTlandBda}
                    className="muilt-select-field"
                    placeholder="Select..."
                    classNamePrefix="select"
                    onChange={handleTLandBDASelect}
                    value={selectedKeywords}
                    onCreateOption={handleCreate}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Grid> */}
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1" component="label">
                      Technology Stacks
                    </Typography>
                  </Stack>
                  <CreatableSelect
                    isClearable
                    isDisabled={techLoading}
                    isLoading={techLoading}
                    isMulti
                    name="colors"
                    options={technologiesData}
                    onChange={handleTechnologySelect}
                    value={selectedTechnologies}
                    onCreateOption={handleTechCreate}
                    className="muilt-select-field"
                    placeholder="Select Technologies"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Key Features
                  </Typography>
                  <Box className="ck-editor-wrapper">
                    <ReactQuill
                      value={keyFeatures}
                      onChange={handleKeyFeatureChange}
                      modules={modules}
                    />
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Key Integration
                  </Typography>
                  <Box className="ck-editor-wrapper">
                    <ReactQuill
                      value={keyIntegrations}
                      onChange={handleKeyIntegrationChange}
                      modules={modules}
                    />
                  </Box>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Lead Source
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Select
                    name="colors"
                    options={leadSourceOptions}
                    ref={sourceRef}
                    value={
                      leadSourceOptions.find(
                        (option) => option.value === sourcePayload
                      ) || null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handleSourceSelect(selectedOption?.value);
                      } else {
                        setSourcePayload("");
                      }
                    }}
                    isClearable={true}
                    className="muilt-select-field"
                    placeholder="Select Lead Source"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                  {leadSrcErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {leadSrcErrorMsg}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    BDE
                  </Typography>
                  <Select
                    name="colors"
                    options={BDEOptions}
                    value={
                      BDEOptions?.find(
                        (option) => option?.value === selectedBDE
                      ) || null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handleBDESelect(selectedOption?.value);
                      } else {
                        setSelectedBDE("");
                      }
                    }}
                    isClearable={true}
                    className="muilt-select-field"
                    placeholder="Select BDE"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    BDM
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Select
                    name="colors"
                    ref={bdaRef}
                    options={BDAOptions}
                    value={
                      BDAOptions?.find(
                        (option) => option?.value === selectedBDA
                      ) || null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handleBDASelect(selectedOption?.value);
                      } else {
                        setSelectedBDA("");
                      }
                    }}
                    isClearable={true}
                    className="muilt-select-field"
                    placeholder="Select BDM"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                  {bdaError && (
                    <Typography variant="body1" component="p" className="error">
                      {bdaError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    TL
                  </Typography>
                  <Select
                    name="colors"
                    options={TeamLeaderoptions}
                    value={
                      TeamLeaderoptions?.find(
                        (option) => option?.value === selectedTL
                      ) || null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handleTLSelect(selectedOption?.value);
                      } else {
                        setSelectedTL("");
                      }
                    }}
                    isClearable={true}
                    className="muilt-select-field"
                    placeholder="Select Team Leader"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Name
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Project Name"
                    value={projectName}
                    inputRef={projectRef}
                    onChange={(event) => {
                      setProjectName(event.target.value);
                      if (event.target.value) {
                        setProjectError("");
                      }
                    }}
                  />
                  {projecterror && (
                    <Typography variant="body1" component="p" className="error">
                      {projecterror}
                    </Typography>
                  )}
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Links
                  </Typography>
                  <Stack container spacing={2}>
                    {projectLinks.map((element, index) => {
                      return (
                        <Box className="add-multi-input" key={index}>
                          <TextField
                            className="input-field"
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Enter Project Link"
                            value={element.linkText}
                            onChange={(e) => {
                              addTextToLink(e.target.value, index);
                            }}
                          />
                          <Box className="action-btn-wrap">
                            {index === projectLinks.length - 1 && (
                              <Button
                                variant="outlined"
                                className="action-btn edit-btn"
                                startIcon={<RiAddCircleLine />}
                                disableRipple
                                onClick={() => addProjectLink()}
                              ></Button>
                            )}
                            {index !== projectLinks.length - 1 && (
                              <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                disableRipple
                                onClick={() => {
                                  handleDeleteLink(index);
                                }}
                              ></Button>
                            )}
                          </Box>
                        </Box>
                      );
                    })}
                  </Stack>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Estimation Link
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Estimation Link"
                    value={estimationLink}
                    onChange={(event) => {
                      setEstimationLink(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Estimation(In Hrs.)
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Estimation"
                    type="number"
                    value={estimationTime}
                    onChange={(event) => {
                      setEstimationTime(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Lead Feedback
                  </Typography>
                  <Select
                    isMulti
                    name="colors"
                    options={feedbackssData}
                    className="muilt-select-field"
                    classNamePrefix="select"
                    placeholder="Select Lead Feedback"
                    onChange={handleFeedbackSelect}
                    value={selectedFeedback}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Feedback Note
                  </Typography>
                  <TextareaAutosize
                    className="textarea-field"
                    placeholder="Add Feedback Note"
                    value={feedbackNote} // Use the state variable as the value
                    onChange={handleFeedbackChange}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Stack spacing={3} className="h-100">
              <Box className="card-wrapper">
                <Typography variant="body1" component="label">
                  Lead Status
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  ref={statusRef}
                  name="colors"
                  options={leadStatusOptions}
                  className="muilt-select-field"
                  value={
                    leadStatusOptions.find(
                      (option) => option.value === leadStatus
                    ) || null
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      handleLeadStatusSelect(selectedOption?.value);
                    } else {
                      setLeadStatus("");
                    }
                  }}
                  isClearable={true}
                  classNamePrefix="select"
                  placeholder="Select Lead Status"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                />
                {leadStatusErrorMsg && (
                  <Typography variant="body1" component="p" className="error">
                    {leadStatusErrorMsg}
                  </Typography>
                )}
              </Box>
              <Box className="card-wrapper add-scroll-space">
                <Stack spacing={3} className="view-info">
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Documents
                  </Typography>
                  <Box className="document-card-content-wrapper over-flow-auto">
                    <Box>
                      <Typography variant="body1" component="label">
                        File Name
                      </Typography>
                      <Stack container spacing={2}>
                        <Box className="add-multi-input">
                          <TextField
                            className="input-field"
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Please Enter File Name"
                            value={filename}
                            onChange={(event) => {
                              setFileName(event.target.value);
                            }}
                          />
                        </Box>
                      </Stack>
                      <Typography
                        variant="body1"
                        component="label"
                        style={{ marginTop: "10px" }}
                      >
                        URL
                      </Typography>
                      <Stack container spacing={2}>
                        <Box className="add-multi-input">
                          <TextField
                            className="input-field"
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Paste Document URL Here"
                            value={documentUploadLink}
                            onChange={(event) => {
                              setDocumentUploadLink(event.target.value);
                              if (event.target.value) {
                                setdocumentLinkError("");
                              }
                            }}
                          />
                          <Button
                            variant="outlined"
                            className="action-btn upload-btn"
                            disableRipple
                            onClick={addDocument}
                          >
                            Upload
                          </Button>
                        </Box>
                      </Stack>
                      {documentlinkerror && (
                        <Typography
                          variant="body2"
                          className="error"
                          color="error"
                        >
                          {documentlinkerror}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Stack
                    className="document-list-wrapper over-flow-auto"
                    spacing={2}
                  >
                    {selectedDocuments?.map((document) => {
                      const text = document?.linkName
                        ? document?.linkName
                        : document.linkText;
                      const displayText =
                        text?.length > maxLength
                          ? text.slice(0, maxLength) + "..."
                          : text;
                      return (
                        <>
                          <Box className="document-upload-wrapper">
                            <Box
                              key={document.linkId}
                              className="document-type"
                            >
                              <Typography variant="body1" component="span">
                                <a
                                  href={document.linkText}
                                  target="_blank"
                                  style={{ color: "var(--primary-text-color)" }}
                                  rel="noopener noreferrer"
                                  onClick={(e) =>
                                    handleLinkClick(e, document.linkText)
                                  }
                                >
                                  {displayText}
                                </a>
                              </Typography>
                            </Box>
                            <Box className="document-action action-btn-wrap">
                              <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                disableRipple
                                onClick={() =>
                                  handleRemoveDocument(
                                    document.linkId,
                                    "selectedDocuments"
                                  )
                                }
                              ></Button>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                    {displayedDocuments &&
                      displayedDocuments.map((video, index) => {
                        const text = displayedfilenames[index]
                          ? displayedfilenames[index]
                          : displayedDocuments[index];
                        const displayText =
                          text?.length > maxLength
                            ? text.slice(0, maxLength) + "..."
                            : text;

                        return (
                          <Box key={index} className="document-upload-wrapper">
                            <Box className="document-type">
                              <a
                                href={displayedDocuments[index]}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) =>
                                  handleLinkClick(e, displayedDocuments[index])
                                }
                              >
                                <Typography variant="body1" component="span">
                                  {displayText}
                                </Typography>
                              </a>
                            </Box>

                            <Box className="document-action action-btn-wrap">
                              <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                disableRipple
                                onClick={() =>
                                  handleRemoveDocument(index, "addedDocuments")
                                }
                              ></Button>
                            </Box>
                          </Box>
                        );
                      })}
                  </Stack>
                </Stack>
              </Box>
              <Box
                className="card-wrapper add-scroll-space view-info"
                sx={{ flex: 1 }}
              >
                <Typography variant="body1" component="label">
                  Reference Links
                </Typography>
                <Stack container spacing={2} className="over-flow-auto">
                  {ReferenceLinks.map((element, index) => {
                    return (
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Enter Reference Link"
                          value={element.linkText}
                          onChange={(e) => {
                            addReferenceToLink(e.target.value, index);
                          }}
                        />
                        <Box className="action-btn-wrap">
                          {index === ReferenceLinks.length - 1 && (
                            <Button
                              variant="outlined"
                              className="action-btn edit-btn"
                              startIcon={<RiAddCircleLine />}
                              disableRipple
                              onClick={() => addReferenceLink()}
                            ></Button>
                          )}
                          {index !== ReferenceLinks.length - 1 && (
                            <Button
                              variant="outlined"
                              className="action-btn dlt-btn"
                              startIcon={<RiDeleteBinLine />}
                              disableRipple
                              onClick={() => {
                                handleReferenceDeleteLink(index);
                              }}
                            ></Button>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                </Stack>
              </Box>
            </Stack>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="card-wrapper add-bottom-scroll-space">
              <Stack spacing={3} className="view-info">
                <Typography variant="h3" component="h3" className="card-title">
                  Lead Video Casts
                </Typography>
                <Box className="document-card-content-wrapper over-flow-auto">
                  <Box>
                    <Typography variant="body1" component="label">
                      File Name
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Please Enter File Name"
                          value={videoFileName}
                          onChange={(event) => {
                            setVideoFileName(event.target.value);
                          }}
                        />
                      </Box>
                    </Stack>
                    <Typography
                      variant="body1"
                      component="label"
                      style={{ marginTop: "10px" }}
                    >
                      Video Cast URL
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Paste Video Cast URL Here"
                          value={uploadLink}
                          onChange={(event) => {
                            setUploadLink(event.target.value);
                            if (event.target.value) {
                              setUploadLinkError("");
                            }
                          }}
                        />
                        <Button
                          variant="outlined"
                          className="action-btn upload-btn"
                          disableRipple
                          onClick={addVideo}
                        >
                          Upload
                        </Button>
                      </Box>
                    </Stack>
                    {uploadLinkError && (
                      <Typography
                        variant="body2"
                        className="error"
                        color="error"
                      >
                        {uploadLinkError}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Stack
                  className="document-list-wrapper over-flow-auto"
                  spacing={2}
                >
                  {displayedVideos &&
                    displayedVideos.map((video, index) => {
                      const text = displayedVideofilenames[index]
                        ? displayedVideofilenames[index]
                        : displayedVideos[index];
                      const displayText =
                        text.length > maxLength
                          ? text.slice(0, maxLength) + "..."
                          : text;
                      return (
                        <Box key={index} className="document-upload-wrapper">
                          <Box className="document-type">
                            <a
                              href={displayedVideos[index]}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) =>
                                handleLinkClick(e, displayedVideos[index])
                              }
                            >
                              <Typography variant="body1" component="span">
                                {displayText}
                              </Typography>
                            </a>
                          </Box>
                          <Box className="document-action action-btn-wrap">
                            <Button
                              variant="outlined"
                              className="action-btn dlt-btn"
                              startIcon={<RiDeleteBinLine />}
                              disableRipple
                              onClick={() => handleRemoveVideo(index)}
                            ></Button>
                          </Box>
                        </Box>
                      );
                    })}
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="card-wrapper add-bottom-scroll-space">
              <Stack spacing={3} className="view-info">
                <Typography variant="h3" component="h3" className="card-title">
                  Lead Screen Shots
                </Typography>
                <Box className="document-card-content-wrapper over-flow-auto">
                  <Box>
                    <Typography variant="body1" component="label">
                      File Name
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Please Enter File Name"
                          value={imageFileName}
                          onChange={(event) => {
                            setImageFileName(event.target.value);
                          }}
                        />
                      </Box>
                    </Stack>
                    <Typography
                      variant="body1"
                      component="label"
                      style={{ marginTop: "10px" }}
                    >
                      Screen Shot URL
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Paste Screen Shot URL Here"
                          value={imageUploadLink}
                          onChange={(event) => {
                            setImageUploadLink(event.target.value);
                            if (event.target.value) {
                              setImageLinkError("");
                            }
                          }}
                        />
                        <Button
                          variant="outlined"
                          className="action-btn upload-btn"
                          disableRipple
                          onClick={addImage}
                        >
                          Upload
                        </Button>
                      </Box>
                    </Stack>
                    {imagelinkerror && (
                      <Typography
                        variant="body2"
                        className="error"
                        color="error"
                      >
                        {imagelinkerror}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Stack
                  className="document-list-wrapper over-flow-auto"
                  spacing={2}
                >
                  {displayedImages &&
                    displayedImages.map((video, index) => {
                      const text = displayedImagefilenames[index]
                        ? displayedImagefilenames[index]
                        : displayedImages[index];
                      const displayText =
                        text.length > maxLength
                          ? text.slice(0, maxLength) + "..."
                          : text;
                      return (
                        <Box key={index} className="document-upload-wrapper">
                          <Box className="document-type">
                            <a
                              href={displayedImages[index]}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) =>
                                handleLinkClick(e, displayedImages[index])
                              }
                            >
                              <Typography variant="body1" component="span">
                                {displayText}
                              </Typography>
                            </a>
                          </Box>
                          <Box className="document-action action-btn-wrap">
                            <Button
                              variant="outlined"
                              className="action-btn dlt-btn"
                              startIcon={<RiDeleteBinLine />}
                              disableRipple
                              onClick={() => handleRemoveImage(index)}
                            ></Button>
                          </Box>
                        </Box>
                      );
                    })}
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="footer-wrapper">
        {leadStatus === "won" ? (
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn w-120"
            disableRipple
            onClick={() => setConvertCancel(true)}
          >
            Save
          </Button>
        ) : (
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn w-120"
            disableRipple
            onClick={submitData}
            disabled={isButtonDisabled}
          >
            Save
          </Button>
        )}
        <Button
          variant="contained"
          size="large"
          className="secondary-btn btn w-120"
          disableRipple
          onClick={() => navigate("/leads")}
        >
          Cancel
        </Button>
      </Box>
      <KeywordModal
        keywordModalOpen={keywordModalOpen}
        handleClose={closeKeyworddModal}
      />
      {showModal && (
        <AddKeywordModal
          invalidValue={invalidValue}
          onClose={() => setShowModal(false)} // Close the modal when needed
        />
      )}
      <TechnologyModal
        technologyModalOpen={technologyModalOpen}
        handleClose={closeTechnologyModal}
      />
      {addTechModal && (
        <AddTechnologyModal
          validTechName={validTechName}
          onClose={() => setAddTechModal(false)} // Close the modal when needed
        />
      )}
      <ContactDrawer
        open={open}
        setOpen={setOpen}
        editContact={editContact}
        currentPage={currentPage}
        clientId={clientId}
      />
      {convertCancel && (
        <ConvertAlertModal
          leadStatus={leadStatus}
          setLeadStatus={setLeadStatus}
          iswonButtonDisabled={iswonButtonDisabled}
          convertCancel={convertCancel}
          setConvertCancel={setConvertCancel}
          onClose={() => setConvertCancel(false)}
          onSubmit={async (developmentStatus) => {
            if (iswonButtonDisabled) {
              return;
            }
            if (value === "new_opportunity") {
              if (!compName.trim()) {
                // errorToast("Please fill in all the required fields marked with a star ( * )");
                setCompanyError("Please enter company name");
                setConvertCancel(false);
                return;
              }
              if (!conPerson.trim()) {
                // errorToast("Please fill in all the required fields marked with a star ( * )");
                setConPerErrorMsg("Please enter contact person name");
                setConvertCancel(false);
                return;
              }
            }
            if (value === "existing_client") {
              if (!companyPayload) {
                // errorToast("Please fill in all the required fields marked with a star ( * )");
                setCompanyError("Please select company name");
                setConvertCancel(false);
                return;
              }
              if (!companyPayloadPerson) {
                // errorToast("Please fill in all the required fields marked with a star ( * )");
                setConPerErrorMsg("Please select contact person name");
                setConvertCancel(false);
                return;
              }
            }
            if (!sourcePayload) {
              // errorToast("Please fill in all the required fields marked with a star ( * )");
              setLeadSrcErrorMsg("Please select lead source");
              setConvertCancel(false);
              return;
            }

            if (!projectName) {
              setProjectError("Please enter project name");
              setConvertCancel(false);
              return;
            }

            if (!leadStatus) {
              // errorToast("Please fill in all the required fields marked with a star ( * )");
              setLeadStatusErrorMsg("Please select lead status");
              setConvertCancel(false);
              return;
            }
            if (compEmailError === "Please enter a valid email address") {
              // errorToast("Please enter a valid email address");
              setConvertCancel(false);
              return;
            }
            if (conPerEmailError === "Please enter a valid email address") {
              // errorToast("Please enter a valid email address");
              setConvertCancel(false);
              return;
            }
            setIsWonButtonDisabled(true);
            const leadData = {
              company_name:
                value === "new_opportunity" ? compName : companyPayload,
              company_email:
                value === "new_opportunity" ? compEmail : companyEmail,
              company_phone:
                value === "new_opportunity" ? compNumber : companyPhone,
              contact_person:
                value === "new_opportunity" ? conPerson : companyPayloadPerson,
              contact_person_email:
                value === "new_opportunity"
                  ? conPersonEmail
                  : contactPersonEmail,
              contact_person_phone:
                value === "new_opportunity"
                  ? conPersonNumber
                  : contactPersonPhone,
              key_features: keyFeatures,
              key_integration: keyIntegrations,

              lead_source: sourcePayload,
              project_name: projectName,

              estimation_link: estimationLink,
              estimation_hours: estimationTime,
              feedback_note: feedbackNote,
              status: leadStatus,
              development_status: developmentStatus,
              bda_id: selectedBDA,
              bde_id: selectedBDE,
              tl_id: selectedTL,
              client_id: value === "new_opportunity" ? "" : clientId,
              client_contact_id:
                value === "new_opportunity" ? "" : companiesperson,
            };
            for (const key in leadData) {
              if (Object.hasOwnProperty.call(leadData, key)) {
                formData.append(key, leadData[key]);
              }
            }
            try {
              const response = await dispatch(
                createLead({ formData, client_type: value })
              );

              if (response.success) {
                navigate("/leads");
                successToast(
                  "Lead created and Converted to Project  successfully!"
                );
                initiateLeadCreation();
              } else {
                console.error("Error creating lead:", response.error);
              }
            } catch (error) {
              console.error("Error creating lead:", error.message);
            } finally {
              // Enable the save button after the API call is completed
              setIsButtonDisabled(false);
              setConvertCancel(false);
            }
          }}
        />
      )}
      <EmailAlertModal
        emailOpen={emailOpen}
        setEmailOpen={setEmailOpen}
        compEmail={compEmail}
        setCompEmail={setCompEmail}
        sameEmailMessage={sameEmailMessage}
      />
    </>
  );
};

export default LeadCreate;

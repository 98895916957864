import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const createLeadSettingSlice = createSlice({
  name: "createLeadSetting",
  initialState,
  reducers: {
    createLeadSettingStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createLeadSettingSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    createLeadSettingFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  createLeadSettingStart,
  createLeadSettingSuccess,
  createLeadSettingFailure,
} = createLeadSettingSlice.actions;

export default createLeadSettingSlice.reducer;

export const createLeadSetting = (settingData) => async (dispatch) => {
  try {
    dispatch(createLeadSettingStart());

    const response = await callApi({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/leadmail-setting`,
      data: settingData,
    });

    const data = response.data;
    // console.log(response.data, "lead slice");
    dispatch(createLeadSettingSuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(createLeadSettingFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status_code,
      })
    );
    return { success: false, error: error.response.message };
  }
};

import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";
// import { successToast } from "../../responses/successToast";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const createShareFilterSlice = createSlice({
  name: "createShareFilter",
  initialState,
  reducers: {
    createShareFilterStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createShareFilterSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    createShareFilterFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  createShareFilterStart,
  createShareFilterSuccess,
  createShareFilterFailure,
} = createShareFilterSlice.actions;

export default createShareFilterSlice.reducer;

export const createShareFilter =
  ({ user_id, filter_id, filter_type, shared_comment, user_delete }) =>
  async (dispatch) => {
    try {
      dispatch(createShareFilterStart());

      const response = await callApi({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/filters`,
        data: {
          shared_comment: shared_comment,
          user_delete: user_delete,
        },
        params: {
          user_id: user_id,
          filter_id: filter_id,
          filter_type: filter_type,
        },
      });

      const data = response.data;
      // console.log(response.data, "lead slice");
      dispatch(createShareFilterSuccess());
      // successToast("Lead creatde successfully!");
      return { success: true, message: data.message };
    } catch (error) {
      dispatch(createShareFilterFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status_code,
        })
      );
      return { success: false, error: error.response.message };
    }
  };

const module = {
  DASHBOARD: 'Dashboard',
  PROJECTS: 'Projects',
  LEADS: 'Leads',
  CLIENT: 'Client',
  USERS: 'Users',
  REPORTS: 'Reports',
  BIDS: 'Bids',
  SETTINGS: 'Settings',
  CV_BUILDER: 'CV Builder',
  ANNOUNCEMENTS: "Announcements",
};

const permissions = {
  VIEW: 'view',
  FULL: 'full',
  NONE: 'none',
  ADD_UPDATE: 'add_update',
};

const score = {
  [permissions.NONE]: 1,
  [permissions.VIEW]: 2,
  [permissions.ADD_UPDATE]: 3,
  [permissions.FULL]: 4,
};
export { module, permissions, score };

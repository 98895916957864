import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { successToast } from "../../responses/successToast";
import { fetchClients } from "../../store/slices/clientSlice";
import { deleteClientById } from "../../store/slices/deleteClientByIdSlice";

const ClientDeleteModal = ({
  clientDelete,
  handleDeleteClose,
  selectedClientId,
  currentPage,
}) => {
  const dispatch = useDispatch();
  const clientId = selectedClientId;

  const submitHandler = () => {
    dispatch(deleteClientById({ clientId }))
      .unwrap()
      .then((result) => {
        const { success } = result;

        if (success) {
          successToast("Client deleted successfully");
          dispatch(fetchClients({ page: currentPage, limit: 10 }))
            .unwrap()
            .then((bidsResult) => {
              // console.log(bidsResult);

              const totalPages = bidsResult[0].totalPages || 1;
              // console.log(totalPages);
              const updatedPage = Math.min(currentPage, totalPages);
              // console.log(updatedPage);

              dispatch(fetchClients({ page: updatedPage, limit: 10 }));
            });
          handleDeleteClose();
        } else {
          handleDeleteClose();
        }
      })
      .catch((error) => {
        console.error("Failed to delete user:", error);
        handleDeleteClose();
      });
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={clientDelete}
        // onClose={ClientDeleteModal}
        closeAfterTransition
      >
        <Fade in={clientDelete}>
          <Box className="modal-wrapper">
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3">
                Do you want to delete this client?
              </Typography>
              <Typography variant="body1" component="p">
                This action can’t be undone.
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body"></Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={submitHandler}
                >
                  Delete
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={handleDeleteClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ClientDeleteModal;

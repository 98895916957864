import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import {
  Button,
  Box,
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  Stack,
  Chip,
  Skeleton,
  Drawer,
  Modal,
  Fade,
  Tabs,
  Tab,
  Tooltip,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  RiArrowLeftLine,
  RiPencilLine,
  RiLinkM,
  RiCloseLine,
  RiDraftLine,
  RiPhoneLine,
  RiAddCircleLine,
  RiAlarmLine,
  RiEyeLine,
  RiDeleteBinLine,
  RiDragMove2Fill,
} from "react-icons/ri";
import { MdAccessAlarm } from "react-icons/md";
// import RootRef from "@material-ui/core/RootRef";
import PropTypes from "prop-types";
import ConvertIcon from "../../assets/images/icons/view-export.svg";
import ProjectDocumentComponent from "../../components/common/projectDocuments";
import ExportModal from "../../../src/components/common/exportToPdfModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectsById } from "../../store/slices/projectbyIdSlice";
import { DatePicker } from "rsuite";
import { createProjectActivity } from "../../store/slices/createProjectActivitySlice";
import { fetchProjectActivities } from "../../store/slices/projectActivityLogSlice";
import { successToast } from "../../responses/successToast";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import ClipboardJS from "clipboard";
import { updateProjects } from "../../utils/permissions";
import WithPermission from "../../components/common/withPermission";
import ProjectActivityDeleteModal from "../../components/common/deleteProjectActivityModal";
import { fetchActivitiesById } from "../../store/slices/activityByIdSlice";
import { editactivityById } from "../../store/slices/editActivitySlice";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { fetchUsers } from "../../store/slices/userSlice";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { editProjectByIdAdmin } from "../../store/slices/editProject";
import moment from "moment";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const customToolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#3e3f42",
          color: "white",
          fontSize: "11px",
          // margin: "0",
          // padding: "10",
          border: "1px solid white",
        },
        arrow: {
          color: "var(--color-white)",
        },
      },
    },
  },
});

// UI LI LIST STYLING
const ListStyle = {
  listStyleType: "decimal",
  pl: 3,
  pt: 0,
  pb: 0,
  "& .MuiListItem-root": {
    display: "list-item",
    paddingTop: 0,
    paddingLeft: "5px",
    paddingBottom: 0,
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28px",
  },
};

// LEAD ACTIVITY OPTIONS
const leadActivityOptions = [
  { value: "call", label: "Call" },
  { value: "note", label: "Note" },
  { value: "followup", label: "Followup" },
];

const ProjectView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location?.state?.path;

  let { state } = location;
  if (state === null) {
    state = {
      status: "live",
      page: 1,
      rowsPerPage: 10,
      filterApplied: false,
      filterByIdApplied: false,
      applyFilterId: "",
      selectedKeywords: [],
      selectedTechnologies: [],
      text: "",
      startDate: [],
      endDate: [],
      date: "",
      destatus: null,
      edit: "",
      keywordSwitchVal: true,
      techStackSwitchVal: true,
    };
  }

  const { projectId } = useParams();
  const projects = useSelector((state) => state.projectsById.projects);
  const activities = useSelector(
    (state) => state.projectActivities.projectactivities
  );

  const [loading, setLoading] = useState(true);

  // START :: EXPORT TO PDF MODAL

  const [exportCancel, setExportCancel] = useState(false);

  const status = 0;

  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [activityError, setActivityError] = useState("");
  const [dateError, setDateError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [commenterror, setCommentError] = useState("");
  const [ActivityLog, setActivityLogModal] = useState(false);
  const [activity, setActivity] = useState("");
  //const [selectedOption, setSelectedOption] = useState(null);
  const [value, setValue] = React.useState(0);
  const [comments, setComments] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  // ACTIVITY DELETE
  const [activityDelete, setActivityDelete] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const handleActivityDeleteOpen = (acitivyId) => {
    setSelectedActivityId(acitivyId);
    setActivityDelete(true);
  };

  const handleActivityDeleteClose = () => setActivityDelete(false);

  const activitybyid = useSelector((state) => state.activitybyId.activities);
  const [activityId, setActivityId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [members, setMembers] = useState([]);
  const [memberspayload, setMembersPayload] = useState([]);
  const [removeKeyId, setRemoveKeyId] = useState([]);
  const [assingeeError, setAssigneeError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const users = useSelector((state) => state.users.users);
  const maxLength = 31;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleLinkClick = (event, website) => {
    if (website && !website.startsWith("http")) {
      event.preventDefault();
      window.open(`http://${website}`, "_blank");
    }
  };
  const handleOptionChange = (selectedOption) => {
    // setSelectedOption(selectedOption);
    setActivity(selectedOption.value);
    setActivityError("");
  };
  const handleActivityLog = () => {
    setOpen(!open);
    dispatch(
      fetchProjectActivities({
        projectId,
        state: value === 0 ? "activities" : value === 1 ? "status" : "updates",
      })
    );
  };

  function truncateLabel(label, maxLength) {
    if (label?.length > maxLength) {
      return label?.substring(0, maxLength) + "...";
    }
    return label;
  }

  const formatActionNote = (actionNote) => {
    if (!actionNote || actionNote.indexOf("~##~") === -1) {
      return actionNote;
    }

    const actions = actionNote.split("~##~");

    if (actions.length === 2) {
      return `${actions[0]} ${truncateLabel(actions[1], 138)}`;
    } else {
      const formattedActions = actions.map((action, index) => {
        let truncatedAction = action;

        if (action.includes("updated Development Status to Stopped")) {
          truncatedAction = truncateLabel(action, 138);
        }

        return (
          <div key={index}>
            {index === 0 ? (
              ` ${truncatedAction}`
            ) : (
              <ul
                style={{
                  marginBottom: "2px",
                }}
              >
                <li> {truncatedAction}</li>
              </ul>
            )}
          </div>
        );
      });
      return formattedActions;
    }
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  }

  function formatActivityDate(activitydate) {
    const date = moment(activitydate, "YYYY-MM-DD");
    return date.format("MMMM DD, YYYY");
  }

  function convertTo24HourFormat(timeStr) {
    if (timeStr === null) {
      return "";
    }

    const [hours, minutes] = timeStr.split(":");
    const hour = parseInt(hours, 10);

    return `${String(hour).padStart(2, "0")}:${minutes}`;
  }

  const timeString = (timestr) => {
    const date = new Date(timestr);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  function formattime(timestamp) {
    const dateObject = new Date(timestamp);
    dateObject.setHours(dateObject.getHours() + 5);
    dateObject.setMinutes(dateObject.getMinutes() + 30);
    const hours = String(dateObject.getUTCHours()).padStart(2, "0");
    const minutes = String(dateObject.getUTCMinutes()).padStart(2, "0");
    const seconds = String(dateObject.getUTCSeconds()).padStart(2, "0");
    const time = `${hours}:${minutes}:${seconds}`;
    return convertTo24HourFormat(time);
  }
  const handleDateChange = (date) => {
    setSelectedDate(date);

    setDateError("");
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);

    setTimeError("");
  };

  const activityData = {
    project_id: projectId,
    activity: activity,
    assignee_user_id: memberspayload,
    delete_assignee_user_id: removeKeyId,
    date:
      activity === "call" || activity === "followup"
        ? moment(selectedDate).format("YYYY-MM-DD")
        : null,
    time:
      activity === "call" || activity === "followup"
        ? timeString(selectedTime)
        : null,
    comments: comments,
  };
  const defaultBdaOption = projects?.primary_bda
    ? {
        value: projects?.primary_bda?.id,
        label: `${projects?.primary_bda?.first_name} ${projects?.primary_bda?.last_name}`,
      }
    : null;

  const secondaryBdaOption = projects?.secondary_bda
    ? {
        value: projects?.secondary_bda?.id,
        label: `${projects?.secondary_bda?.first_name} ${projects?.secondary_bda?.last_name}`,
      }
    : null;

  const defaultTlOption = projects?.primary_tl
    ? {
        value: projects?.primary_tl?.id,
        label: `${projects?.primary_tl?.first_name} ${projects?.primary_tl?.last_name}`,
      }
    : null;

  const secondaryTlOption = projects?.secondary_tl
    ? {
        value: projects?.secondary_tl?.id,
        label: `${projects?.secondary_tl?.first_name} ${projects?.secondary_tl?.last_name}`,
      }
    : null;

  const defaultOptions = [
    defaultBdaOption,
    defaultTlOption,
    secondaryBdaOption,
    secondaryTlOption,
  ].filter((option) => option !== null);

  const [filteredOptions, setFilteredOptions] = useState(defaultOptions);

  const handleActivityModal = async () => {
    setIsEditing(false);
    if (!activity) {
      setActivityError("Please Select Activity");
      return;
    }
    if (activity === "call" || activity === "followup") {
      if (memberspayload?.length === 0 && memberspayload?.length === 0) {
        setAssigneeError("Please select atleast one assignee");
        return;
      }
      if (!selectedDate) {
        setDateError("Please Select Date");
        return;
      }
      if (!selectedTime) {
        setTimeError("Please Select time");
        return;
      }
    }
    if (activity === "note") {
      if (!comments) {
        setCommentError("Please Enter Commment");
        return;
      }
    }

    setIsButtonDisabled(true);

    await dispatch(createProjectActivity(activityData))
      .then((response) => {
        if (response?.success) {
          setActivityLogModal(false);
          setActivity("");
          setComments("");
          setSelectedDate(null);
          setSelectedTime(null);
          setRemoveKeyId([]);
          successToast("Acitivity created successfully");
          dispatch(
            fetchProjectActivities({
              projectId,
              state:
                value === 0 ? "activities" : value === 1 ? "status" : "updates",
            })
          );
          setIsButtonDisabled(false);
        } else {
          console.error("Failed! Something went wrong.");
        }
      })
      .catch((error) => {
        console.error("API call error:", error);
        setIsButtonDisabled(false);
      });
  };

  const handleEditActivitylog = async () => {
    if (!activity) {
      setActivityError("Please Select Activity");
      return;
    }
    if (activity === "call" || activity === "followup") {
      if (memberspayload?.length === 0 && memberspayload?.length === 0) {
        // errorToast("Please fill in all the required fields marked with a star ( * )");
        setAssigneeError("Please select atleast one assignee");
        return;
      }
      if (!selectedDate) {
        setDateError("Please Select Date");
        return;
      }
      if (!selectedTime) {
        setTimeError("Please Select time");
        return;
      }
    }
    if (activity === "note") {
      if (!comments) {
        setCommentError("Please Enter Commment");
        return;
      }
    }

    setIsButtonDisabled(true);
    await dispatch(
      editactivityById({ activityId: activityId, activityData: activityData })
    )
      .then((response) => {
        if (response?.success) {
          setActivityLogModal(false);
          setIsEditing(false);
          setActivity("");
          setComments("");
          setRemoveKeyId([]);
          setSelectedDate(null);
          setSelectedTime(null);
          successToast("Acitivity Updated successfully");
          dispatch(
            fetchProjectActivities({
              projectId,
              state:
                value === 0 ? "activities" : value === 1 ? "status" : "updates",
            })
          );
          setIsButtonDisabled(false);
        } else {
          console.error("Failed! Something went wrong.");
          setIsButtonDisabled(false);
        }
      })
      .catch((error) => {
        console.error("API call error:", error);
      });
  };

  function capitalizeFirstLetter(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }
  function formatStatus(str) {
    const words = str?.split("_");
    const capitalizedWords = words?.map((word) => capitalizeFirstLetter(word));
    return capitalizedWords?.join(" ");
  }
  // END :: EXPORT TO PDF MODAL
  useEffect(() => {
    setLoading(true);
    dispatch(fetchProjectsById({ projectId })).then((response) => {
      setLoading(false);
      if (response?.payload?.deletedAt || response?.error) {
        navigate("/not-found");
      }
    });
  }, [dispatch, projectId, navigate]);

  useEffect(() => {
    dispatch(
      fetchProjectActivities({
        projectId,
        state: value === 0 ? "activities" : value === 1 ? "status" : "updates",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, value]);

  const [isCopying, setIsCopying] = useState(false);
  const clipboardButtonRef = useRef(null);
  let clipboardInstance = null;
  let isToastDisplayed = false;
  const copyLinkHandler = async () => {
    if (isCopying) {
      return;
    }
    setIsCopying(true);
    const publicLink = projects?.random_link;

    if (clipboardInstance) {
      clipboardInstance.destroy();
    }

    clipboardInstance = new ClipboardJS(clipboardButtonRef.current, {
      text: () => publicLink,
    });

    clipboardInstance.on("success", () => {
      if (!isToastDisplayed) {
        toast.success("Copied to clipboard");
        isToastDisplayed = true;
      }
      clipboardInstance.destroy();
      setIsCopying(false);
    });

    clipboardButtonRef.current.click();
  };

  const exportPdfHandler = () => {
    setExportCancel(true);
  };

  function formatDescription(description) {
    if (!description) return "-";

    const paragraphs = description.split("\r\n");
    const formattedText = paragraphs.map((paragraph, index) => (
      <p key={index} className="custom-style">
        {paragraph}
      </p>
    ));

    return formattedText;
  }

  const handleeditopen = (id) => {
    setActivityLogModal(true);
    setIsEditing(true);
    setActivityId(id);
    dispatch(
      fetchActivitiesById({
        activityId: id,
      })
    );
  };

  useEffect(() => {
    if (!isEditing) {
      setMembers(filteredOptions);
      setMembersPayload(filteredOptions.map((option) => option.value));
    }
  }, [isEditing, filteredOptions]);

  useEffect(() => {
    if (isEditing) {
      setActivity(activitybyid?.activity);
      if (activitybyid?.date === null) {
        setSelectedDate(null);
      } else {
        const activityDate = new Date(activitybyid?.date);
        const year = activityDate.getUTCFullYear();
        const month = activityDate.toLocaleString("en", { month: "short" });
        const day = activityDate.getUTCDate();
        const hours = activityDate.getUTCHours();
        const minutes = activityDate.getUTCMinutes();
        const seconds = activityDate.getUTCSeconds();
        const weekday = activityDate.getUTCDay();
        const dayFormat = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
          weekday
        ]; // Map day index to abbreviation
        // Construct the formatted date string
        const formattedDateString = `${dayFormat} ${month} ${day} ${year} ${hours}:${minutes}:${seconds} GMT+0000 (GMT)`;
        const formattedDate = new Date(formattedDateString);

        const offsetInMinutes = formattedDate.getTimezoneOffset();

        // Convert the offset from minutes to hours
        const offsetInHours = offsetInMinutes / 60;

        // Adjust the date based on the offset
        formattedDate.setHours(formattedDate.getHours() + offsetInHours);

        if (activityId?.date === "") {
          setSelectedDate("");
        } else {
          setSelectedDate(formattedDate);
        }
      }
      const assigneeOptions = activitybyid?.project_assignee_users?.map(
        (technologyName) => ({
          value: technologyName?.user_id,
          label: `${technologyName?.user?.first_name} ${technologyName?.user?.last_name}`,
        })
      );
      setMembers(assigneeOptions);
      setMembersPayload(assigneeOptions.map((option) => option.value));
      if (activitybyid?.time === null) {
        setSelectedTime(null);
      } else {
        try {
          const [hours, minutes, seconds] = activitybyid.time
            .split(":")
            .map(Number);

          // Create a new Date object and set the time components
          const newTime = new Date(
            Date.UTC(1970, 0, 1, hours, minutes, seconds)
          );
          // Get the local time zone offset in minutes
          const offsetInMinutes = newTime.getTimezoneOffset();

          // Adjust the time by adding the offset in minutes
          newTime.setMinutes(newTime.getMinutes() + offsetInMinutes);

          setSelectedTime(newTime);
        } catch (error) {
          console.error("Error parsing time:", error);
          setSelectedTime(null); // Handle the error by setting a default value or showing an error message
        }
      }
      if (activitybyid?.comments === "") {
        setComments("");
      } else {
        setComments(activitybyid?.comments);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitybyid]);

  const loggedInUserId = users?.loginuser?.map((login) => login.id);

  const userRoleArray = users?.loginuser?.map(
    (login) => login?.roles?.role_name
  );
  // eslint-disable-next-line no-unused-vars
  const userRole = userRoleArray ? userRoleArray[0] : null;

  const memberOptions = users?.AllTlandBda?.map((user) => ({
    value: user.id,
    label: `${user.first_name} ${user.last_name}`,
  }));

  const handleAssigneeOptionChange = (selectedOptions) => {
    setMembers(selectedOptions);
    const selectedIds = selectedOptions.map((option) => option.value);
    setMembersPayload(selectedIds);
    if (selectedOptions) {
      setAssigneeError("");
    }
  };

  useEffect(() => {
    dispatch(
      fetchUsers({
        page: "",
        limit: "",
        query: "",
      })
    );
  }, [dispatch]);

  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [imageSortinPayload, setImageSortingPayload] = useState([]);
  const [SortinPayload, setSortingPayload] = useState([]);
  useEffect(() => {
    if (projects) {
      const initialImageLinks = projects?.project_screenshot_links?.map(
        (linkObj) => ({
          linkText: linkObj.screenshot_url,
          linkId: linkObj.id,
          linkName: linkObj.file_name,
        })
      );
      setSelectedImages(initialImageLinks);

      const imageIds =
        projects?.project_screenshot_links?.map((keyword) => keyword.id) || [];
      const imageSortingPayloadString = imageIds.join(",");
      setImageSortingPayload((prevKeywordPayload) => imageSortingPayloadString);

      const initialVideoLinks = projects?.project_video_links?.map(
        (linkObj) => ({
          linkText: linkObj.video_url,
          linkId: linkObj.id,
          linkName: linkObj.file_name,
          // linkSortId: linkObj.sort_id,
        })
      );
      setSelectedVideos(initialVideoLinks);

      const videoIds =
        projects?.project_video_links?.map((keyword) => keyword.id) || [];
      const sortingPayloadString = videoIds.join(",");
      setSortingPayload((prevKeywordPayload) => sortingPayloadString);
    }
  }, [projects]);

  const formData = new FormData();

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    // Check the type of draggable (e.g., "video" or other types)
    if (result.type === "video") {
      const updatedVideos = Array.from(selectedVideos);
      const [reorderedItem] = updatedVideos.splice(result.source.index, 1);
      updatedVideos.splice(result.destination.index, 0, reorderedItem);

      setSelectedVideos(updatedVideos);

      const sortingOrderPayload = updatedVideos.map((video) => video.linkId);

      const videoOrder = sortingOrderPayload.join(",");
      setSortingPayload(videoOrder);
    }
  };
  formData.append("video_sorting_order", SortinPayload);

  const handleScreenshotDragEnd = (result) => {
    if (!result.destination) return;

    if (result.type === "screenshot") {
      const updatedScreenshots = Array.from(selectedImages);
      const [reorderedItem] = updatedScreenshots.splice(result.source.index, 1);
      updatedScreenshots.splice(result.destination.index, 0, reorderedItem);

      setSelectedImages(updatedScreenshots);

      const sortingOrderPayload = updatedScreenshots.map(
        (video) => video.linkId
      );
      const imgOrder = sortingOrderPayload.join(","); // Update the variable

      setImageSortingPayload(imgOrder);
    }
  };
  formData.append("scrrenshot_sorting_order", imageSortinPayload);

  const submitHandler = async () => {
    const projectData = {
      status: projects?.status,

      development_status: projects?.development_status,
      project_name: projects?.project_name,
      client_id:
        projects?.client_type === "new_opportunity" ? "" : projects?.client_id,
    };
    for (const key in projectData) {
      if (Object.hasOwnProperty.call(projectData, key)) {
        formData.append(key, projectData[key]);
      }
    }
    try {
      const response = await dispatch(
        editProjectByIdAdmin(projectId, formData, projects?.client_type)
      );

      if (response.success) {
        navigate("/projects", {
          state: {
            page: state.page,
            rowsPerPage: state.rowsPerPage,
            status: state.status,
            filterApplied: state.filterApplied,
            text: state.text,
            selectedKeywords: state.selectedKeywords,
            selectedTechnologies: state.selectedTechnologies,
            selectedTLandBDAs:state.selectedTLandBDAs,
            keywordSwitchVal: state.keywordSwitchVal,
            techStackSwitchVal: state.techStackSwitchVal,
            startDate: state.startDate,
            endDate: state.endDate,
            date: state.date,
            destatus: state.destatus,
            filterByIdApplied: state.filterByIdApplied,
            applyFilterId: state.applyFilterId,
            edit: state.edit,
          },
        });
        successToast("Links sorted successfully!");
      } else {
        console.error("Error creating user:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      setIsButtonDisabled(false);
    }
  };
  
  return (
    <>
      <Box className="content-header view-project-module-wrapper">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() =>path?navigate(path):
              navigate("/projects", {
                state: {
                  page: state.page,
                  rowsPerPage: state.rowsPerPage,
                  status: state.status,
                  filterApplied: state.filterApplied,
                  text: state.text,
                  selectedKeywords: state.selectedKeywords,
                  selectedTLandBDAs:state.selectedTLandBDAs,
                  selectedTechnologies: state.selectedTechnologies,
                  keywordSwitchVal: state.keywordSwitchVal,
                  techStackSwitchVal: state.techStackSwitchVal,
                  startDate: state.startDate,
                  endDate: state.endDate,
                  date: state.date,
                  destatus: state.destatus,
                  filterByIdApplied: state.filterByIdApplied,
                  applyFilterId: state.applyFilterId,
                  edit: state.edit,
                },
              })
            }
          ></Button>
          View Project
        </Typography>
        <Box variant="div" component="div" className="content-header-right">
          {projects?.lead_id ? (
            <Button
              variant="contained"
              size="large"
              className="primary-btn btn add-btn"
              onClick={() => navigate(`/leads/${projects?.lead_id}/view`)}
              endIcon={<RiEyeLine />}
              disableRipple
            >
              View Lead
            </Button>
          ) : null}
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiLinkM />}
            disableRipple
            onClick={copyLinkHandler}
            ref={clipboardButtonRef}
          >
            Copy Public Link
          </Button>
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            disableRipple
            endIcon={<RiAlarmLine />}
            onClick={handleActivityLog}
          >
            Activity Log
          </Button>
          <WithPermission
            permission={updateProjects}
            element={
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn add-btn"
                disableRipple
                onClick={exportPdfHandler}
              >
                Export To PDF{" "}
                <img
                  src={ConvertIcon}
                  alt="Convert Icon"
                  className="add-left-space"
                />
              </Button>
            }
          />
          <WithPermission
            permission={updateProjects}
            element={
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn only-icon-btn"
                endIcon={<RiPencilLine />}
                disableRipple
                onClick={() =>
                  navigate(`/projects/${projectId}/edit`, {
                    state: {
                      page: state.page,
                      rowsPerPage: state.rowsPerPage,
                      status: state.status,
                      filterApplied: state.filterApplied,
                      text: state.text,
                      selectedKeywords: state.selectedKeywords,
                      selectedTechnologies: state.selectedTechnologies,
                      selectedTLandBDAs:state.selectedTLandBDAs,
                      keywordSwitchVal: state.keywordSwitchVal,
                      techStackSwitchVal: state.techStackSwitchVal,
                      startDate: state.startDate,
                      endDate: state.endDate,
                      date: state.date,
                      destatus: state.destatus,
                      filterByIdApplied: state.filterByIdApplied,
                      applyFilterId: state.applyFilterId,
                      edit: state.edit,
                    },
                  })
                }
              ></Button>
            }
          />
        </Box>
      </Box>

      <Box className="content-wrapper">
        <Grid container spacing={3}>
          <Grid item lg={8} md={12} sm={12} xs={12}>
            <Box className="card-wrapper" style={{ height: "100%" }}>
              <Grid container spacing={2} className="view-info">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Project Details
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Name
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                      style={{ wordBreak: "break-all" }}
                    >
                      {capitalizeFirstLetter(
                        projects?.project_name === ""
                          ? "-"
                          : projects?.project_name
                      )}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Description
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width="100%" height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {formatDescription(
                        projects?.project_description === "" ||
                          projects?.project_description === null
                          ? "-"
                          : projects?.project_description
                      )}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography
                    variant="body1"
                    component="label"
                    sx={{ marginBottom: "4px" }}
                  >
                    Keywords
                  </Typography>
                  {loading ? (
                    // Display skeleton placeholders while loading
                    <Box display="flex">
                      {Array.from({ length: 3 }).map((_, index) => (
                        <Skeleton
                          key={index}
                          variant="text"
                          width={100}
                          height={40}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Box variant="div" component="div" className="tag-wrap">
                      {projects?.project_keywords?.length > 0
                        ? projects?.project_keywords?.map((project) => (
                            <Chip label={project.keywords?.keyword_name} />
                          ))
                        : "-"}
                    </Box>
                  )}
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography
                    variant="body1"
                    component="label"
                    sx={{ marginBottom: "4px" }}
                  >
                    Technology Stacks
                  </Typography>
                  {loading ? (
                    <Box display="flex">
                      {Array.from({ length: 3 }).map((_, index) => (
                        <Skeleton
                          key={index}
                          variant="text"
                          width={100}
                          height={40}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Box variant="div" component="div" className="tag-wrap">
                      {projects?.project_technologies?.length > 0
                        ? projects?.project_technologies?.map((project) => (
                            <Chip
                              label={project.technologies?.technology_name}
                            />
                          ))
                        : "-"}
                    </Box>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Key Features
                  </Typography>
                  {projects?.key_features === "" ? (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                      style={{ wordBreak: "break-all" }}
                    >
                      -
                    </Typography>
                  ) : (
                    <ReactQuill
                      value={projects?.key_features}
                      readOnly={true}
                      theme="bubble"
                    />
                  )}
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Key Integrations
                  </Typography>
                  {projects?.key_integration === "" ? (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                      style={{ wordBreak: "break-all" }}
                    >
                      -
                    </Typography>
                  ) : (
                    <ReactQuill
                      value={projects?.key_integration}
                      readOnly={true}
                      theme="bubble"
                    />
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Links
                  </Typography>
                  {loading ? (
                    <List sx={ListStyle}>
                      {Array.from({ length: 3 }).map((_, index) => (
                        <ListItem key={index}>
                          <Skeleton variant="text" width={100} height={30} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <>
                      {projects?.project_links &&
                      projects?.project_links.length > 0 ? (
                        <List sx={ListStyle}>
                          {projects?.project_links.map((project, index) => (
                            <ListItem key={index}>
                              <a
                                href={project.project_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) =>
                                  handleLinkClick(e, project.project_link)
                                }
                                style={{ wordBreak: "break-all" }}
                              >
                                {project.project_link}
                              </a>
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                        >
                          -
                        </Typography>
                      )}
                    </>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Primary TL
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {projects?.primary_tl === null ? (
                        "-"
                      ) : (
                        <>
                          {" "}
                          {capitalizeFirstLetter(
                            projects?.primary_tl?.first_name
                          )}{" "}
                          {capitalizeFirstLetter(
                            projects?.primary_tl?.last_name
                          )}{" "}
                        </>
                      )}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Secondary TL
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {projects?.secondary_tl === null ? (
                        "-"
                      ) : (
                        <>
                          {" "}
                          {capitalizeFirstLetter(
                            projects?.secondary_tl?.first_name
                          )}{" "}
                          {capitalizeFirstLetter(
                            projects?.secondary_tl?.last_name
                          )}{" "}
                        </>
                      )}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Primary BD
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {projects?.primary_bda === null ? (
                        "-"
                      ) : (
                        <>
                          {" "}
                          {capitalizeFirstLetter(
                            projects?.primary_bda?.first_name
                          )}{" "}
                          {capitalizeFirstLetter(
                            projects?.primary_bda?.last_name
                          )}{" "}
                        </>
                      )}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Secondary BD
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {projects?.secondary_bda === null ? (
                        "-"
                      ) : (
                        <>
                          {" "}
                          {capitalizeFirstLetter(
                            projects?.secondary_bda?.first_name
                          )}{" "}
                          {capitalizeFirstLetter(
                            projects?.secondary_bda?.last_name
                          )}{" "}
                        </>
                      )}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Grid container spacing={3}>
              <Grid item lg={12} md={6} sm={6} xs={12}>
                <Box className="card-wrapper view-info h-100">
                  <Typography variant="body1" component="label">
                    Contact Person
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <div
                      className="contact-person"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        gap: "8px",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          // width: "200px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                          style={{ fontSize: "16px" }}
                        >
                          {projects?.client_contacts?.contact_person_name ===
                            "" ||
                          projects?.client_contacts?.contact_person_name ===
                            null
                            ? "-"
                            : projects?.client_contacts?.contact_person_name}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        component="span"
                        className="value1"
                        onClick={() =>
                          navigate(`/clients/${projects?.client_id}/view`)
                        }
                        style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                      >
                        View Details
                      </Typography>
                    </div>
                  )}
                  <Box style={{ marginTop: "8px" }}>
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                      style={{
                        wordBreak: "break-word",
                        fontSize: "14px",
                      }}
                    >
                      (
                      {projects?.projectclients?.company_name
                        ? projects?.projectclients?.company_name
                        : null}
                      )
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={12} md={6} sm={6} xs={12}>
                <Box className="card-wrapper view-info h-100">
                  <Typography variant="body1" component="label">
                    Project Status
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {formatStatus(
                        projects?.status === "developing"
                          ? "Development"
                          : projects?.status
                      )}
                    </Typography>
                  )}
                </Box>
              </Grid>
              <Grid item lg={12} md={6} sm={6} xs={12}>
                <Box className="card-wrapper view-info h-100">
                  <Typography variant="body1" component="label">
                    Development Status
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {formatStatus(projects?.development_status)}
                    </Typography>
                  )}

                  {projects?.development_status === "stopped" ? (
                    <>
                      <Typography
                        variant="body1"
                        component="label"
                        style={{ marginTop: "20px" }}
                      >
                        Reason
                      </Typography>
                      {loading ? (
                        <Skeleton variant="text" width={100} height={30} />
                      ) : (
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                          style={{ wordBreak: "break-word" }}
                        >
                          {projects?.reason ? projects?.reason : "-"}
                        </Typography>
                      )}
                    </>
                  ) : null}
                </Box>
              </Grid>
              <Grid item lg={12} md={6} sm={6} xs={12}>
                <Box className="card-wrapper view-info h-100">
                  <Typography variant="body1" component="label">
                    Development Start Date
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {projects?.start_date === null ||
                    projects?.start_date === ""
                      ? "-"
                      : formatDate(projects?.start_date)}
                  </Typography>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="card-wrapper add-scroll-space">
                  <Stack spacing={2} className="view-info">
                    <Typography
                      variant="h3"
                      component="h3"
                      className="card-title"
                    >
                      Documents
                    </Typography>
                    {projects?.project_document_links &&
                    projects?.project_document_links?.length > 0 ? (
                      // <Grid container rowSpacing={2} columnSpacing={2}>
                      <List sx={ListStyle}>
                        {projects?.project_document_links?.map(
                          (ProjectDocumentList, index) => {
                            const text = ProjectDocumentList?.file_name
                              ? ProjectDocumentList?.file_name
                              : ProjectDocumentList?.project_document_link;
                            const displayText =
                              text?.length > maxLength
                                ? text.slice(0, maxLength) + "..."
                                : text;

                            return (
                              <>
                                <ListItem key={index}>
                                  <>
                                    <ProjectDocumentComponent
                                      ProjectDocumentName={displayText}
                                      ProjectDocumentLink={
                                        ProjectDocumentList.project_document_link
                                      }
                                      handleLinkClick={handleLinkClick}
                                    />
                                  </>
                                </ListItem>
                              </>
                            );
                          }
                        )}
                      </List>
                    ) : (
                      // </Grid>
                      <Typography
                        variant="body1"
                        component="p"
                        className="no-msg-found"
                      >
                        No Documents Found
                      </Typography>
                    )}
                  </Stack>
                </Box>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box
                  className="card-wrapper add-scroll-space view-info"
                  sx={{ flex: 1 }}
                >
                  <Typography variant="body1" component="label">
                    Reference Links
                  </Typography>
                  {loading ? (
                    <List sx={ListStyle}>
                      {Array.from({ length: 3 }).map((_, index) => (
                        <ListItem key={index}>
                          <Skeleton variant="text" width={100} height={30} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <List sx={ListStyle} className="reference-link-wrapper">
                      {projects?.project_reference_links?.length > 0 ? (
                        projects?.project_reference_links?.map(
                          (referencelink, index) => (
                            <ListItem key={index}>
                              <a
                                href={referencelink.project_reference_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) =>
                                  handleLinkClick(
                                    e,
                                    referencelink.project_reference_link
                                  )
                                }
                              >
                                {referencelink.project_reference_link}
                              </a>
                            </ListItem>
                          )
                        )
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                          className="no-msg-found"
                          style={{ paddingTop: "10px" }}
                        >
                          No Reference Links Found
                        </Typography>
                      )}
                    </List>
                  )}
                </Box>
              </Grid>
            </Grid>
            {/* <Stack spacing={3} className="h-100">
            </Stack> */}
          </Grid>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box className="card-wrapper add-scroll-space">
                <Stack spacing={3} className="view-info">
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Project Video Casts
                  </Typography>
                  {projects?.project_video_links &&
                  projects?.project_video_links?.length > 0 ? (
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      <Droppable droppableId="droppable" type="video">
                        {(provided) => (
                          <List
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={ListStyle}
                          >
                            {selectedVideos.map((video, index) => (
                              <Draggable
                                key={video.linkId}
                                draggableId={`video-${video.linkId}`}
                                index={index}
                                type="video" // Make sure to set the type
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Box
                                      style={{ display: "flex", gap: "10px" }}
                                    >
                                      <RiDragMove2Fill
                                        className="drag-icon"
                                        style={{
                                          marginRight: "30px",
                                          marginTop: "3px",
                                        }}
                                      />
                                      <ListItem key={index}>
                                        <ProjectDocumentComponent
                                          ProjectDocumentName={video.linkName}
                                          ProjectDocumentLink={video.linkText}
                                          handleLinkClick={handleLinkClick}
                                        />
                                      </ListItem>
                                    </Box>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </List>
                        )}
                      </Droppable>
                    </Grid>
                  ) : (
                    <Typography
                      variant="body1"
                      component="p"
                      className="no-msg-found"
                    >
                      No Videos Found
                    </Typography>
                  )}
                </Stack>
              </Box>
            </Grid>
          </DragDropContext>
          <DragDropContext onDragEnd={handleScreenshotDragEnd}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Box className="card-wrapper add-scroll-space">
                <Stack spacing={3} className="view-info">
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Project Screen Shots
                  </Typography>
                  {projects?.project_screenshot_links &&
                  projects?.project_screenshot_links?.length > 0 ? (
                    <Grid container rowSpacing={2} columnSpacing={2}>
                      <Droppable
                        droppableId="screenshotDroppable"
                        type="screenshot"
                      >
                        {(provided) => (
                          <List
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={ListStyle}
                          >
                            {selectedImages.map((screenshot, index) => (
                              <Draggable
                                key={screenshot.linkId}
                                draggableId={`screenshot-${screenshot.linkId}`}
                                index={index}
                                type="screenshot" // Make sure to set the type
                              >
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Box
                                      style={{ display: "flex", gap: "10px" }}
                                    >
                                      <RiDragMove2Fill
                                        className="drag-icon"
                                        style={{
                                          marginRight: "30px",
                                          marginTop: "3px",
                                        }}
                                      />
                                      <ListItem key={index}>
                                        <ProjectDocumentComponent
                                          ProjectDocumentName={
                                            screenshot.linkName
                                          }
                                          ProjectDocumentLink={
                                            screenshot.linkText
                                          }
                                          handleLinkClick={handleLinkClick}
                                        />
                                      </ListItem>
                                    </Box>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </List>
                        )}
                      </Droppable>
                    </Grid>
                  ) : (
                    <Typography
                      variant="body1"
                      component="p"
                      className="no-msg-found"
                    >
                      No Screenshots Found
                    </Typography>
                  )}
                </Stack>
              </Box>
            </Grid>
          </DragDropContext>
        </Grid>
      </Box>
      {projects?.project_video_links?.length > 0 ||
      projects?.project_screenshot_links?.length > 0 ? (
        <Box className="footer-wrapper">
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn w-120"
            disableRipple
            onClick={submitHandler}
            disabled={isButtonDisabled}
            style={{ marginRight: "32px" }}
          >
            Sort
          </Button>
        </Box>
      ) : null}
      <ExportModal
        exportCancel={exportCancel}
        setExportCancel={setExportCancel}
        exportProjectId={projectId}
        exportProjectName={projects?.project_name}
        status={status}
      />

      {/*  START :: PROJECT FILTER PANEL */}
      <Drawer
        className="side-drawer project-view-drawer"
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box className="sidebar-tab-wrapper activity-log-wrapper">
          <Box className="sidebar-tab-wrapper">
            <Box className="close-btn" onClick={() => setOpen(false)}>
              <RiCloseLine />
            </Box>
            <Box className="sidebar-tab">
              <Tabs aria-label="basic tabs example">
                <Tab
                  className="tab-label-btn"
                  label="Activity Log"
                  style={{ color: "var(--primary-text-color)" }}
                />
              </Tabs>
            </Box>
          </Box>
          <Box className="sidebar-tab-wrapper">
            <Box className="sidebar-tab" style={{ paddingLeft: "12px" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="tab-label-btn"
                  label="Activities"
                  {...a11yProps(0)}
                />
                <Tab
                  className="tab-label-btn"
                  label="Status"
                  {...a11yProps(1)}
                />
                <Tab
                  className="tab-label-btn"
                  label="Updates"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
          </Box>
          <CustomTabPanel
            value={value}
            index={1}
            className="filter-content-wrapper"
          >
            <Stack spacing={2} sx={{ padding: "24px 14px 24px 24px" }}>
              <Box className="activity-log-list-wrapper">
                {activities?.map((activity) => (
                  <Box className="activity-card-wrapper">
                    <Box className="activity-log-icon activity-call">
                      {activity.activity === "call" ? (
                        <RiPhoneLine />
                      ) : activity?.action_note?.includes(
                          "has successfully created the lead"
                        ) ? (
                        <RiAddCircleLine />
                      ) : (
                        <RiDraftLine />
                      )}
                    </Box>
                    <Box className="activity-content-wrapper">
                      {/* <Typography variant="body1" component="span">
                        {activity.activity === "call"
                          ? formatDate(activity?.date)
                          : formatDate(activity?.createdAt)}
                      </Typography> */}
                      <Typography
                        variant="body1"
                        component="span"
                        className="date-time-drawer"
                      >
                        {activity.activity === "call"
                          ? formatDate(activity?.date)
                          : formatDate(activity?.createdAt)}
                        <Typography
                          variant="body1"
                          component="span"
                          className="mobile-time-drawer"
                        >
                          | {formattime(activity?.createdAt)}
                        </Typography>
                      </Typography>
                      <Box className="activity-content">
                        <Box className="activity-content-header">
                          <Typography variant="h6" component="h6">
                            {activity?.action_note}
                          </Typography>
                          <Typography
                            variant="body1"
                            component="span"
                            // style={{ marginRight: "20px" }}
                          >
                            {formattime(activity?.createdAt)}
                          </Typography>
                        </Box>
                        {activity?.time === null &&
                        activity?.comments === null ? null : (
                          <Box className="activity-content-para">
                            {activity.activity === "call" ? (
                              <Typography variant="body1" component="p">
                                Call schedule At :{" "}
                                {convertTo24HourFormat(activity?.time)}
                              </Typography>
                            ) : null}
                            <Typography variant="body1" component="p">
                              {activity?.comments}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Stack>
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={0}
            className="filter-content-wrapper"
          >
            <Stack spacing={2} sx={{ padding: "24px 14px 24px 24px" }}>
              <Box className="add-activity-log-btn">
                <WithPermission
                  permission={updateProjects}
                  element={
                    <Button
                      variant="contained"
                      size="large"
                      className="primary-btn btn add-btn"
                      endIcon={<RiAddCircleLine />}
                      disableRipple
                      onClick={() => {
                        setActivityLogModal(true);
                        setFilteredOptions(defaultOptions);
                      }}
                    >
                      Create Activity
                    </Button>
                  }
                />
              </Box>

              <Box className="activity-log-list-wrapper">
                {activities?.map((activity) => (
                  <Box className="activity-card-wrapper">
                    <Box className="activity-log-icon activity-call">
                      {activity.activity === "call" ? (
                        <RiPhoneLine />
                      ) : activity.activity === "followup" ? (
                        <MdAccessAlarm />
                      ) : activity?.action_note?.includes(
                          "has successfully created the lead"
                        ) ? (
                        <RiAddCircleLine />
                      ) : (
                        <RiDraftLine />
                      )}
                    </Box>
                    <Box className="activity-content-wrapper">
                      <Typography
                        variant="body1"
                        component="span"
                        className="date-time-drawer"
                      >
                        {activity.activity === "call"
                          ? formatDate(activity?.date)
                          : formatDate(activity?.createdAt)}
                        <Typography
                          variant="body1"
                          component="span"
                          className="mobile-time-drawer"
                        >
                          | {formattime(activity?.createdAt)}
                        </Typography>
                      </Typography>
                      <Box className="activity-content">
                        <Box className="activity-content-header">
                          <Typography variant="h6" component="h6">
                            {activity?.action_note}
                          </Typography>

                          <Typography
                            variant="body1"
                            component="span"
                            // style={{ marginRight: "20px" }}
                          >
                            {formattime(activity?.createdAt)}
                          </Typography>
                        </Box>
                        {activity?.time === null &&
                        activity?.comments === null ? null : (
                          <Box className="activity-content-para">
                            {activity.activity === "call" ||
                            activity.activity === "followup" ? (
                              <Typography
                                variant="body1"
                                component="p"
                                style={{
                                  marginBottom: "10px",
                                  fontWeight: "600",
                                }}
                              >
                                {activity?.activity === "call"
                                  ? "Call"
                                  : "Followup"}{" "}
                                schedule at {formatActivityDate(activity?.date)}
                                {" at "}
                                {convertTo24HourFormat(activity?.time)}
                              </Typography>
                            ) : null}
                            {activity?.comments && (
                              <Typography
                                variant="body1"
                                component="p"
                                className="value"
                                style={{
                                  whiteSpace: "pre-wrap",
                                  // marginTop: "10px",
                                }}
                              >
                                {activity.comments}
                              </Typography>
                            )}
                            {activity?.loginuserId === activity?.user_id ? (
                              <Box
                                className="action-btn-wrap"
                                style={{
                                  justifyContent: "end",
                                  gap: "0px",
                                  position: "relative",
                                  right: "-15px",
                                }}
                              >
                                <ThemeProvider theme={customToolTipTheme}>
                                  <Tooltip
                                    title={
                                      "Editing an activity entry is only allowed within 24 hours of its creation "
                                    }
                                  >
                                    <span>
                                      <WithPermission
                                        permission={updateProjects}
                                        element={
                                          <Button
                                            variant="outlined"
                                            className="action-btn edit-btn"
                                            startIcon={
                                              <RiPencilLine
                                                style={{ fontSize: "16px" }}
                                              />
                                            }
                                            // disableRipple

                                            //title="Edit"
                                            style={{
                                              backgroundColor: "transparent",
                                              opacity:
                                                activity?.hide_data === 0
                                                  ? "0.6"
                                                  : "1",
                                              height: "20px",
                                            }}
                                            onClick={() =>
                                              handleeditopen(activity?.id)
                                            }
                                            disabled={activity?.hide_data === 0}
                                          ></Button>
                                        }
                                      />
                                    </span>
                                  </Tooltip>
                                </ThemeProvider>
                                <ThemeProvider theme={customToolTipTheme}>
                                  <Tooltip
                                    title={
                                      "Deleting an activity entry is only allowed within 24 hours of its creation "
                                    }
                                  >
                                    <span>
                                      <WithPermission
                                        permission={updateProjects}
                                        element={
                                          <Button
                                            variant="outlined"
                                            className="action-btn dlt-btn"
                                            startIcon={
                                              <RiDeleteBinLine
                                                style={{ fontSize: "16px" }}
                                              />
                                            }
                                            disableRipple
                                            style={{
                                              backgroundColor: "transparent",
                                              opacity:
                                                activity?.hide_data === 0
                                                  ? "0.6"
                                                  : "1",
                                              height: "20px",
                                            }}
                                            // title="Delete"
                                            disabled={activity?.hide_data === 0}
                                            onClick={() =>
                                              handleActivityDeleteOpen(
                                                activity.id
                                              )
                                            }
                                          ></Button>
                                        }
                                      />
                                    </span>
                                  </Tooltip>
                                </ThemeProvider>
                              </Box>
                            ) : null}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Stack>
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={2}
            className="filter-content-wrapper"
          >
            <Stack spacing={2} sx={{ padding: "24px 14px 24px 24px" }}>
              <Box className="activity-log-list-wrapper">
                {activities?.map((activity) => (
                  <Box className="activity-card-wrapper">
                    <Box className="activity-log-icon activity-call">
                      {activity.activity === "call" ? (
                        <RiPhoneLine />
                      ) : activity?.action_note?.includes(
                          "has successfully created the lead"
                        ) ? (
                        <RiAddCircleLine />
                      ) : (
                        <RiDraftLine />
                      )}
                    </Box>
                    <Box className="activity-content-wrapper">
                      <Typography
                        variant="body1"
                        component="span"
                        className="date-time-drawer"
                      >
                        {activity.activity === "call"
                          ? formatDate(activity?.date)
                          : formatDate(activity?.createdAt)}
                        <Typography
                          variant="body1"
                          component="span"
                          className="mobile-time-drawer"
                        >
                          | {formattime(activity?.createdAt)}
                        </Typography>
                      </Typography>
                      <Box className="activity-content">
                        <Box className="activity-content-header">
                          <Tooltip
                            title={
                              <div
                                style={{
                                  color: "white",
                                  fontSize: "14px",
                                }}
                              >
                                {!activity?.action_note ||
                                activity?.action_note.indexOf("~##~") === -1
                                  ? activity?.action_note
                                  : (function () {
                                      const actions =
                                        activity?.action_note.split("~##~");

                                      if (actions?.length === 2) {
                                        // If there is one ~##~, display it without bullet points
                                        return actions;
                                      } else {
                                        // If there are multiple ~##~, display each action with bullet points
                                        const formattedActions = actions.map(
                                          (action, index) => (
                                            <div key={index}>
                                              {index === 0 ? (
                                                <span
                                                  style={{
                                                    color: "white",
                                                    fontSize: "14px",
                                                  }}
                                                >{` ${action}`}</span>
                                              ) : (
                                                <ul
                                                  style={{
                                                    marginBottom: "2px",
                                                  }}
                                                >
                                                  <li
                                                    style={{
                                                      color: "white",
                                                      fontSize: "14px",
                                                    }}
                                                  >
                                                    {" "}
                                                    {action}
                                                  </li>
                                                </ul>
                                              )}
                                            </div>
                                          )
                                        );

                                        return formattedActions;
                                      }
                                    })()}
                              </div>
                            }
                            arrow
                            placement="bottom-start"
                          >
                            <Typography variant="h6" component="h6">
                              {formatActionNote(activity?.action_note)}
                            </Typography>
                          </Tooltip>
                          {/* <Tooltip
                                  title={activity?.reason}
                                  arrow
                                  placement="bottom-start"
                                >
                                  <span
                                    variant="h6"
                                    component="h6"
                                    style={{
                                      color: "var(--primary-text-color)",
                                      fontSize: "16px",
                                      fontWeight: "500",
                                      fontStyle: "normal",
                                    }}
                                  >
                                    {truncateLabel(activity?.reason, 32)}
                                  </span>
                                </Tooltip> */}

                          <Typography
                            variant="body1"
                            component="span"
                            style={{ marginRight: "20px" }}
                          >
                            {formattime(activity?.createdAt)}
                          </Typography>
                        </Box>
                        {activity?.time === null &&
                        activity?.comments === null ? null : (
                          <Box className="activity-content-para">
                            {activity.activity === "call" ? (
                              <Typography variant="body1" component="p">
                                Call schedule At :{" "}
                                {convertTo24HourFormat(activity?.time)}
                              </Typography>
                            ) : null}
                            <Typography variant="body1" component="p">
                              {activity?.comments}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Stack>
          </CustomTabPanel>
        </Box>
      </Drawer>
      {/* END :: PROJECT FILTER PANEL */}

      {/*  START :: SAVE ACTIVITY LOG MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={ActivityLog}
        closeAfterTransition
      >
        <Fade in={ActivityLog}>
          <div className="modal-wrapper" style={{ padding: "18px" }}>
            <Box
              variant="div"
              component="div"
              className="title"
              sx={{ marginBottom: "24px" }}
            >
              <Typography variant="h3" component="h3">
                Activity Log
              </Typography>
            </Box>
            {/* <Box variant="div" component="div" className="body">   */}
            <Grid
              container
              spacing={2}
              style={{ maxHeight: "500px", overflow: "auto" }}
            >
              <Grid item lg={12} md={12} sm={12}>
                <Typography variant="body1" component="label">
                  Activity
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  name="colors"
                  options={leadActivityOptions}
                  value={leadActivityOptions?.find(
                    (Option) => Option.value === activity
                  )}
                  onChange={handleOptionChange}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  // menuIsOpen
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                />
                {activityError && (
                  <Typography variant="body1" component="p" className="error">
                    {activityError}
                  </Typography>
                )}
              </Grid>
              <style>
                {`
                .muilt-select-field {
                  .select__placeholder {
                  color:rgba(0, 0, 0, 0.87);
                  font-size:14px
                      }
                }
                `}
              </style>
              {activity && (activity === "call" || activity === "followup") && (
                <>
                  <Grid item lg={12} md={12} sm={12}>
                    <Typography variant="body1" component="label">
                      Assignee
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <Select
                      isMulti
                      name="colors"
                      options={memberOptions}
                      value={members}
                      onChange={(selectedOptions, { removedValue }) => {
                        if (removedValue) {
                          const removedOptionId = removedValue.value;

                          setRemoveKeyId([...removeKeyId, removedOptionId]);
                        }
                        handleAssigneeOptionChange(selectedOptions);
                      }}
                      className="muilt-select-field"
                      classNamePrefix="select"
                      placeholder="Select Assignee"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "var(--green)",
                        },
                      })}
                    />
                    {assingeeError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {assingeeError}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12}>
                    <Typography variant="body1" component="label">
                      Date
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <DatePicker
                      oneTap
                      onChange={handleDateChange}
                      value={selectedDate !== "" ? selectedDate : null}
                      shouldDisableDate={(date) =>
                        moment(date).isBefore(moment().startOf("day"))
                      }
                      format="dd-MM-yyyy"
                      placeholder="dd-mm-yyyy"
                      className="modal-date-picker"
                    />
                    {dateError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {dateError}
                      </Typography>
                    )}
                  </Grid>
                  <style>
                    {`
                    .modal-date-picker .rs-picker-toggle.rs-btn .rs-picker-toggle-placeholder{
                      color:rgba(0, 0, 0, 0.87);
                      font-size:14px
                      }
                    `}
                  </style>
                  <Grid item lg={12} md={12} sm={12}>
                    <Typography variant="body1" component="label">
                      Time
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <TimePicker
                          value={dayjs(selectedTime)}
                          onChange={handleTimeChange}
                          className="modal-date-picker"
                          ampm={false}
                          open={isOpen}
                          onClose={() => setIsOpen(false)}
                          slotProps={{
                            textField: {
                              onClick: () => setIsOpen(true),
                              readOnly: true,
                            },
                          }}
                          disableTextMask
                          // slots={{
                          //   openPickerButton: () => null,
                          // }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {timeError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {timeError}
                      </Typography>
                    )}
                  </Grid>

                  <style>
                    {`
                      &.MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected{
                        /* Your inline styles here */
                        background-color: black !important;
                    width: 125px !important;
                        /* Add any other styles you want */
                      }
                      .rs-picker-toolbar-right .rs-btn.rs-btn-primary.rs-btn-sm {
                    background-color: var(--primary-text-color) !important;

                    color: #fff !important;

                    top: 5px;
                    width: 100% !important;
                  // }
                    `}
                  </style>
                </>
              )}
              <Grid item lg={12} md={12} sm={12}>
                <Typography variant="body1" component="label">
                  Comments
                  {activity === "note" && (
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  )}
                </Typography>
                <TextareaAutosize
                  className="textarea-field modal-textarea"
                  placeholder="Add Comments"
                  value={comments}
                  onChange={(e) => {
                    setComments(e.target.value);
                    if (e.target.value) {
                      setCommentError("");
                    }
                  }}
                />
                {commenterror && (
                  <Typography variant="body1" component="p" className="error">
                    {commenterror}
                  </Typography>
                )}
              </Grid>
              <style>
                {`.textarea-field{
                &::-webkit-input-placeholder {
                  color:black;
                  font-size: 14px;
                }
                }
                `}
              </style>
            </Grid>
            {/* </Box> */}
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={
                    isEditing ? handleEditActivitylog : handleActivityModal
                  }
                  disabled={isButtonDisabled}
                >
                  {isEditing ? "Update" : "Save"}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => {
                    setActivityLogModal(false);
                    // setSelectedOption(null);
                    setActivity("");
                    setCommentError("");
                    setDateError("");
                    setTimeError("");
                    setActivityError("");
                    setIsEditing(false);
                    setComments("");
                    setSelectedDate("");
                    setSelectedTime("");
                    setIsOpen(false);
                    setAssigneeError("");
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
      {/* END :: SAVE ACTIVITY LOG MODAL */}
      <ProjectActivityDeleteModal
        handleActivityDeleteOpen={handleActivityDeleteOpen}
        handleActivityDeleteClose={handleActivityDeleteClose}
        activityDelete={activityDelete}
        selectedActivityId={selectedActivityId}
        value={value}
        projectId={projectId}
      />
    </>
  );
};

export default ProjectView;

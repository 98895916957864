import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const covertleadslice = createSlice({
  name: "covertlead",
  initialState,
  reducers: {
    covertLeadStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    covertLeadSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    covertLeadFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { covertLeadStart, covertLeadSuccess, covertLeadFailure } =
  covertleadslice.actions;

export default covertleadslice.reducer;

export const covertLead =
  ({ leadId, development_status, start_date, project_name }) =>
  async (dispatch) => {
    try {
      dispatch(covertLeadStart());

      const response = await callApi({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/leads/${leadId}`,
        data: {
          development_status: development_status,
          start_date: start_date,
          project_name: project_name,
        },
      });

      const data = response.data;
      dispatch(covertLeadSuccess());
      return { success: true, message: data.message };
    } catch (error) {
      dispatch(covertLeadFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status_code,
        })
      );
      return { success: false, error: error.response.message };
    }
  };

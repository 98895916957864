import React, { useEffect, useState } from "react";

import {
  Button,
  Box,
  Grid,
  Typography,
  Divider,
  Skeleton,
} from "@mui/material";

import { useNavigate, useParams, useLocation } from "react-router-dom";
import { RiArrowLeftLine } from "react-icons/ri";

import { useDispatch, useSelector } from "react-redux";
import { fetchUsersById } from "../../store/slices/userbyIdSlice";

const UserView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      page: 1,
      status: 0,
      rowsPerPage: 10,
    };
  }
  const { userId } = useParams();
  const users = useSelector((state) => state.usersById.users);
  const [loading, setLoading] = useState(true);

  function capitalizeFirstLetter(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }
  const dispatch = useDispatch();
  useEffect(() => {
    setLoading(true);
    dispatch(fetchUsersById({ userId }));
    setLoading(false);
  }, [dispatch, userId]);
  return (
    <>
      {/* START :: LEAD VIEW HEADER */}
      <Box className="content-header view-lead-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() =>
              navigate("/users", {
                state: {
                  page: state.page,
                  status: state.status,
                  rowsPerPage: state.rowsPerPage,
                },
              })
            }
          ></Button>
          View User
        </Typography>
      </Box>
      {/* END :: LEAD VIEW HEADER */}

      {/* START :: LEAD VIEW CONTENT */}
      <Box className="content-wrapper">
        <Box className="card-wrapper">
          <Grid container spacing={2} className="view-info">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h3" component="h3" className="card-title">
                User Details
              </Typography>
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                Company
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography variant="body1" component="span" className="value">
                  {users?.company_name ?? "-"}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: { xs: "block", sm: "none", md: "none", lg: "none" },
              }}
            >
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                Employee Id
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography variant="body1" component="span" className="value">
                  {users?.employee_number ?? "-"}
                </Typography>
              )}
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                Employee Name
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography variant="body1" component="span" className="value">
                  {capitalizeFirstLetter(users?.first_name)}{" "}
                  {capitalizeFirstLetter(users?.last_name)}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              sx={{
                display: { xs: "block", sm: "none", md: "none", lg: "none" },
              }}
            >
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                Role
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography variant="body1" component="span" className="value">
                  {users?.role_name}
                </Typography>
              )}
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Divider className="divider" />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                Employee Contact Number
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography variant="body1" component="span" className="value">
                  {users?.contact_number}
                </Typography>
              )}
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Typography variant="body1" component="label">
                Email
              </Typography>
              {loading ? (
                <Skeleton variant="text" width={100} height={30} />
              ) : (
                <Typography variant="body1" component="span" className="value">
                  {users?.email}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default UserView;

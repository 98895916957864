import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  user: null,
  loading: false,
  error: null,
};

const editBidByIdSlice = createSlice({
  name: "editBidById",
  initialState,
  reducers: {
    editBidByIdStart(state) {
      state.loading = true;
      state.error = null;
    },
    editBidByIdSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    editBidByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { editBidByIdStart, editBidByIdSuccess, editBidByIdFailure } =
  editBidByIdSlice.actions;
export default editBidByIdSlice.reducer;

export const editBidById = (bidId, bidData) => async (dispatch) => {
  try {
    dispatch(editBidByIdStart());
    const response = await callApi({
      method: "patch",
      url: `${process.env.REACT_APP_API_URL}/bids/${bidId}`,
      data: bidData,
    });

    const bid = response;
    const data = response.data;
    dispatch(editBidByIdSuccess(bid));
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(editBidByIdFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status,
      })
    );
    return { success: false, error: error.response.data.message };
  }
};

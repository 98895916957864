import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const convertBidslice = createSlice({
  name: "convertbid",
  initialState,
  reducers: {
    convertBidStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    convertBidSuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    convertBidFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const { convertBidStart, convertBidSuccess, convertBidFailure } =
  convertBidslice.actions;

export default convertBidslice.reducer;

export const convertBid =
  ({ bidId }) =>
  async (dispatch) => {
    try {
      dispatch(convertBidStart());

      const response = await callApi({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/bids/${bidId}`,
      });

      const data = response.data;
      dispatch(convertBidSuccess());
      return { success: true, message: data.message };
    } catch (error) {
      dispatch(convertBidFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status_code,
        })
      );
      return { success: false, error: error.response.message };
    }
  };

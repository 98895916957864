import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarStatus: true,
};

const drawerHandlerSlice = createSlice({
  name: "drawerHandlerSlice",
  initialState,
  reducers: {
    changeSidebarStatus: (state,action) => {
      state.sidebarStatus = action.payload;
    },
  },
});

export const { changeSidebarStatus } = drawerHandlerSlice.actions;

export default drawerHandlerSlice.reducer;

export const handleSidebar = (status) => async (dispatch) => {
  dispatch(changeSidebarStatus(status));
};

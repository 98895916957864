import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  project: null,
  loading: false,
  error: null,
};

const editProjectByIdSlice = createSlice({
  name: "editProjectById",
  initialState,
  reducers: {
    editProjectByIdStart(state) {
      state.loading = true;
      state.error = null;
    },
    editProjectByIdSuccess(state, action) {
      state.loading = false;
      state.project = action.payload;
    },
    editProjectByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  editProjectByIdStart,
  editProjectByIdSuccess,
  editProjectByIdFailure,
} = editProjectByIdSlice.actions;
export default editProjectByIdSlice.reducer;

export const editProjectByIdAdmin =
  (projectId, projectData, client_type) => async (dispatch) => {
    try {
      dispatch(editProjectByIdStart());
      const response = await callApi({
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/projects/${projectId}`,
        data: projectData,
        params: {
          client_type: client_type,
        },
      });

      const project = response;
      const data = response.data;
      dispatch(editProjectByIdSuccess(project));
      return { success: true, message: data.message };
    } catch (error) {
      dispatch(editProjectByIdFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return { success: false, error: error.response.data.message };
    }
  };

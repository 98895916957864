import React, { useState } from "react";
import { Box, Collapse, Typography } from "@mui/material";
import ReactQuill from "react-quill";
import { BiChevronDown } from "react-icons/bi";
import { callApi } from "../../components/common/commonfunctionforapi";
import Skeleton from "@mui/material/Skeleton";
import "react-quill/dist/quill.bubble.css";

const convertDateIntoDDMMYYYY = (date) => {
  const NewDate = new Date(date);
  const formattedDate = `
        ${NewDate.getDate().toString().padStart(2, "0")}-
        ${(NewDate.getMonth() + 1).toString().padStart(2, "0")}-
        ${NewDate.getFullYear().toString().padStart(2, "0")}
    `;
  return formattedDate;
};

const ReleaseNote = ({ id, version, date }) => {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState("");
  // const notesdescription = useSelector(
  //   (state) => state.releaseNotesDesc.releasenotes
  // );
  // console.log(notesdescription);

  const getLocalTimeZone = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZone;
  };

  const getReleaseNoteDescription = async () => {
    try {
      if (!isOpen) {
        setIsOpen(true);
        setIsLoading(true);
        const response = await callApi({
          method: "get",
          url: `${process.env.REACT_APP_LOCAL_CENTRAL_URL}/release-note`,
          params: {
            id: id,

            timezone: getLocalTimeZone(),
          },
        });
        // console.log(response);
        setDescription(response?.data?.description);
        setIsLoading(false);
      } else {
        setIsOpen(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box className="release-note">
        <Box className="title-content">
          <Typography variant="p" component={"p"} className="version-text">
            Version {version.replace("v", "")}
          </Typography>
          <Typography variant="p" component={"p"} className="date-text">
            {convertDateIntoDDMMYYYY(date)}
          </Typography>

          <Box className="release-action-container">
            <BiChevronDown
              onClick={getReleaseNoteDescription}
              className={"down-arrow rotate-transition"}
              style={{ transform: `rotate(${isOpen ? "180deg" : "0deg"})` }}
            />
          </Box>
        </Box>

        <Collapse in={isOpen} timeout="auto" unmountOnExit={true}>
          {isLoading ? (
            <Skeleton variant="rect" width="100%" height={150} />
          ) : (
            <Box className="description-content">
              <ReactQuill
                value={description || "<h4>NO NOTES AVAILABLE</h4>"}
                theme="bubble"
                readOnly
              />
            </Box>
          )}
        </Collapse>
      </Box>
    </>
  );
};

export default ReleaseNote;

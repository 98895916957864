import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { RiCloseLine } from "react-icons/ri";
import { createContact } from "../../store/slices/createContactSlice";
import { successToast } from "../../responses/successToast";
import { fetchContacts } from "../../store/slices/contactSlice";
import { useDispatch } from "react-redux";
import "../../styles/client.scss";

const ContactDrawer = ({
  open,
  setOpen,
  editContact,
  currentPage,
  clientId,
}) => {
  const dispatch = useDispatch();
  const [conPer, setConPer] = useState("");
  const [conPerNo, setConPerNo] = useState("");
  const [conPerEmail, setConPerEmail] = useState("");
  const [nameError, setNameError] = useState("");
  const [contactError, setContactError] = useState("");

  const toatlClientsPerPage = 10;
  // console.log(clientId, "clientId from drawer");
  const handleCloseDrawer = () => {
    setOpen(false);
    setConPer("");
    setConPerEmail("");
    setConPerNo("");
    setNameError("");
    setContactError("");
  };

  const validateEmail = (email) => {
    const emailPattern =
      /^[a-zA-Z0-9](?!.*\.\.)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,63})+$/;
    return emailPattern.test(email);
  };

  const submitContact = () => {
    setNameError("");
    setContactError("");

    let flag = 0;
    // Validate name and either email or contact number
    if (!conPer.trim()) {
      setNameError("Please enter contact person name");
      flag = 1;
    }

    if (!conPerEmail.trim() && !conPerNo.trim()) {
      setContactError("Please enter either email or contact number");
      flag = 1;
    }

    if (conPerEmail.trim() && !validateEmail(conPerEmail.trim())) {
      setContactError("Please enter valid email");
      flag = 1;
    }

    if (flag !== 0) {
      return;
    }
    const contactData = {
      client_id: clientId,
      contact_person_name: conPer,
      contact_person_email: conPerEmail,
      contact_person_phone: conPerNo,
    };
    dispatch(createContact(contactData))
      .then((response) => {
        if (response.success) {
          // console.log(response, "api response");
          setOpen(false);
          successToast("Contact created successfully!");
          dispatch(
            fetchContacts({
              clientId,
              page: "",
              limit: toatlClientsPerPage,
            })
          );
          setConPer("");
          setConPerNo("");
          setConPerEmail("");
        } else {
          console.error("Error creating client:", response.error);
          setConPer("");
          setConPerNo("");
          setConPerEmail("");
        }
      })
      .catch((error) => {
        console.error("Error creating client:", error.message);
      });
  };

  // const updateContact = () => {
  //   // Handle update logic here
  // };

  return (
    <Drawer
      className="side-drawer client-contact-drawer"
      anchor="right"
      open={open}
      onClose={() => {
        handleCloseDrawer();
      }}
    >
      <Box className="sidebar-tab-wrapper">
        <Box className="close-btn" onClick={handleCloseDrawer}>
          <RiCloseLine />
        </Box>
        <Box className="sidebar-tab">
          <Tab
            className="tab-label-btn contact-label"
            label={editContact ? "Edit Contacts" : "Add Contact"}
            disabled
          ></Tab>
        </Box>
      </Box>
      <Stack spacing={2} sx={{ padding: "24px" }}>
        <Box>
          <Typography variant="body1" component="label">
            Contact Person
            <Typography variant="body1" component="span" className="asterisk">
              *
            </Typography>
          </Typography>
          <TextField
            className="input-field"
            fullWidth
            id="outlined-basic"
            label=""
            variant="outlined"
            size="small"
            placeholder="Enter Contact Person"
            value={conPer}
            onChange={(event) => {
              setConPer(event.target.value);
              if (event.target.value) {
                setNameError("");
              } else {
                setNameError("Please enter contact person name");
              }
            }}
          />
          {nameError && (
            <Typography variant="body2" className="error" color="error">
              {nameError}
            </Typography>
          )}
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Contact Person's Phone No.
          </Typography>
          <TextField
            className="input-field"
            fullWidth
            id="outlined-basic"
            label=""
            variant="outlined"
            size="small"
            placeholder="Enter Contact Person's Phone No."
            // type="number"
            value={conPerNo}
            // onChange={(event) => {
            //   setConPerNo(event.target.value);
            //   if (event.target.value) {
            //     setContactError("");
            //   }
            // }}
            onChange={(event) => {
              setConPerNo(event.target.value);
            }}
          />
        </Box>
        <Box>
          <Typography variant="body1" component="label">
            Contact Person's Email
          </Typography>
          <TextField
            className="input-field"
            fullWidth
            id="outlined-basic"
            label=""
            variant="outlined"
            size="small"
            placeholder="Enter Contact Person's Email"
            value={conPerEmail}
            onChange={(event) => {
              setConPerEmail(event.target.value);
              if (event.target.value && validateEmail(event.target.value)) {
                setContactError("");
              } else if (
                event.target.value &&
                !validateEmail(event.target.value)
              ) {
                setContactError("Please enter valid email");
              } else {
                setContactError("Please enter either email or contact number");
              }
            }}
          />
          {contactError && (
            <Typography variant="body2" className="error" color="error">
              {contactError}
            </Typography>
          )}
        </Box>
        <Box className="filter-button-wrapper">
          {editContact ? (
            <Button
              variant="contained"
              size="large"
              className="primary-btn btn"
              disableRipple
              // onClick={updateContact}
            >
              Save
            </Button>
          ) : (
            <Button
              variant="contained"
              size="large"
              className="primary-btn btn"
              disableRipple
              onClick={submitContact}
            >
              Save
            </Button>
          )}
          <Button
            variant="contained"
            size="large"
            className="secondary-btn btn"
            disableRipple
            onClick={handleCloseDrawer}
          >
            Cancel
          </Button>
        </Box>
      </Stack>
    </Drawer>
  );
};

export default ContactDrawer;

import { callApi } from "../../components/common/commonfunctionforapi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
export const fetchLeadFilters = createAsyncThunk(
  "filters/fetchLeadFilters",
  async (
    {
      text,
      keyword,
      tlAndBda,
      technologies,
      status,
      page,
      estimationprovidedlead,
      usersSwitchValue,
      keywordSwitchValue,
      techStackSwitchValue,
      limit,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/filters`,
        params: {
          text: text,
          keyword: keyword,
          user_id: tlAndBda,
          technologies: technologies,
          filter_type: "leads",
          status: status,
          page: page,
          limit: limit,
          usersSwitchValue,
          keywordSwitchValue,
          techStackSwitchValue,
          estimationprovidedlead: estimationprovidedlead,
        },
      });

      return response.data;
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteFilterById = createAsyncThunk(
  "filters/deleteFilterById",
  async (filterId, { rejectWithValue }) => {
    // console.log(filterId);
    try {
      const response = await callApi({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/filters/${filterId}`,
        params: {
          filter_type: "leads",
        },
      });
      // console.log(response.data);

      return { success: true, message: response.data.message };
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  filters: [],
  status: "idle",
  error: null,
};
const filterLeadSlice = createSlice({
  name: "filtersLead",
  initialState,
  reducers: {
    // deleteFilterByIdSuccess: (state, action) => {
    //   const { filterId } = action.payload;
    //   if (Array.isArray(state.filters)) {
    //     state.filters = state.filters.filter(
    //       (filter) => filter.id !== filterId
    //     );
    //   } else {
    //     console.error("state.filters is not an array:", state.filters);
    //   }
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeadFilters.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLeadFilters.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.filters = action.payload;
      })
      .addCase(deleteFilterById.fulfilled, (state, action) => {
        const deletefilterId = action.meta.arg;

        // console.log("State before filtering:", state);
        // console.log("deletefilterId:", deletefilterId);

        if (Array.isArray(state.filters.getallfilter)) {
          state.filters.getallfilter = state.filters.getallfilter.filter(
            (filter) => filter.id !== deletefilterId
          );
        } else if (Array.isArray(state.filters.shareddatas)) {
          state.filters.shareddatas = state.filters.shareddatas.filter(
            (filter) => filter.id !== deletefilterId
          );
        } else {
          console.error(
            "state.filters.getallfilter is not an array:",
            state.filters.getallfilter
          );
        }

        // console.log("State after filtering:", state);
      })
      .addMatcher(
        // Handle both rejected and fulfilled states with the same action
        (action) => action.type.startsWith("filters/fetchLeadFilters/"),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            // Handle the rejected state with the defined value
            state.status = "failed";
            state.error = action.payload.message;
          }
        }
      );
  },
});
export const { deleteFilterByIdSuccess } = filterLeadSlice.actions;
export default filterLeadSlice.reducer;

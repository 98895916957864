import React, { useEffect, useRef } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Stack,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import {
  RiAddCircleLine,
  RiArrowLeftLine,
  RiDeleteBinLine,
  RiCloseCircleLine,
} from "react-icons/ri";
import { useNavigate, useLocation } from "react-router-dom";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { useState } from "react";
import { fetchLeadsById } from "../../store/slices/leadbyIdSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchKeywords } from "../../store/slices/keywordSlice";
import { fetchTechnologies } from "../../store/slices/technologySlice";
import { fetchFeedbacks } from "../../store/slices/getFeedbackSlice";
import { editLeadByIdAdmin } from "../../store/slices/leadEditByIdSlice";
import { successToast } from "../../responses/successToast";
import KeywordModal from "../../components/common/keywordModal";
import TechnologyModal from "../../components/common/technologyModal";
import AddKeywordModal from "../../components/common/addKeywordModal";
import AddTechnologyModal from "../../components/common/addTechnologySlice";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ConvertAlertModal from "../../components/common/convertAlertModal";
import { fetchClients } from "../../store/slices/clientLeadSlice";
import { fetchContacts } from "../../store/slices/contactSlice";
import { fetchUsers } from "../../store/slices/userSlice";
import { callApi } from "../../components/common/commonfunctionforapi";
import { maskEmail, maskPhoneNumber } from "../../utils/masking";
import { selectIsAdmin } from "../../constants/selectors";
import Loader from "../../components/common/loader";
import LeadLostConformationModal from "../../components/common/leadLostConformationModel";
// LEAD SOURCE OPTIONS
const leadSourceOptions = [
  { value: "Upwork", label: "Upwork" },
  { value: "LinkedIn", label: "LinkedIn" },
  { value: "Guru", label: "Guru" },
  { value: "PPH", label: "PPH" },
  { value: "Fiverr", label: "Fiverr" },
  { value: "Clutch", label: "Clutch" },
  { value: "Website", label: "Website" },
  { value: "Email", label: "Email" },
  { value: "Skype", label: "Skype" },
  { value: "Recommendation", label: "Recommendation" },
  { value: "Freelancer.com", label: "Freelancer.com" },
];

// LEAD STATUS OPTIONS
const leadStatusOptions = [
  { value: "open", label: "Open" },
  { value: "estimation_requested", label: "Estimation Requested" },
  { value: "estimation_provided", label: "Estimation Provided" },
  { value: "closed", label: "Not Responding" },
  { value: "won", label: "Won" },
  { value: "lost", label: "Lost" },
  { value: "standBy", label: "StandBy" },
];

const modules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
};

function capitalizeWords(input) {
  return input?.replace(/\w+/g, function (word) {
    return word?.charAt(0).toUpperCase() + word.slice(1);
  });
}

const LeadEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const getUserRole = useSelector(selectIsAdmin);
  const [roleEmail, setRoleEmail] = useState(getUserRole);
  const [rolePhone, setRolePhone] = useState(getUserRole);
  const [roleContEmail, setRoleContEmail] = useState(getUserRole);
  const [roleContPhone, setRoleContPhone] = useState(getUserRole);
  const [compName, setCompName] = useState("");
  const [compEmail, setCompEmail] = useState("");
  const [compNumber, setCompNumber] = useState("");
  const [conPerson, setConPerson] = useState("");
  const [conPersonEmail, setConPersonEmail] = useState("");
  const [conPersonNumber, setConPersonNumber] = useState("");
  const [projectName, setProjectName] = useState("");
  const [estimationLink, setEstimationLink] = useState("");
  const [estimationTime, setEstimationTime] = useState("");
  const [feedbackNote, setFeedbackNote] = useState("");
  const keywords = useSelector((state) => state.keywords.keywords);
  const technologies = useSelector((state) => state.technologies.technologies);
  const feedbacks = useSelector((state) => state.feedbacks.feedbacks);
  const leadData = useSelector((state) => state.leadsbyId.leads);

  const [obtainedClientId, setObtainedClientId] = useState("");

  const clients = useSelector((state) => state.clientsLead.clients[0]);
  const contacts = useSelector((state) => state.contacts.contacts);
  const { leadId } = useParams();
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [keywordPayload, setKeywordPayload] = useState([]);
  const [selectedTechnologies, setSelectedTechnologies] = useState([]);
  const [technologyPayload, setTechnologyPayload] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState([]);
  const [feedbackPayload, setFeedbackPayload] = useState([]);
  const [sourcePayload, setSourcePayload] = useState("");
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [keyFeatures, setKeyFeatures] = useState("");
  const [keyIntegrations, setKeyIntegrations] = useState("");
  const [leadStatus, setLeadStatus] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagePaths, setImagePaths] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  // const [videoPaths, setVideoPaths] = useState([]);
  const [addedVideoPaths, setAddedVideoPaths] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [addedDocuments /*setAddedDocuments*/] = useState([]);
  const [documentPaths, setDocumentPaths] = useState([]);
  // const [documentPaths, setDocumentPaths] = useState([]);
  const [uploadLink, setUploadLink] = useState("");
  const [displayedVideos, setDisplayedVideos] = useState([]);
  const [selectedIdPayload, setSelectedIdPayload] = useState([]);
  const [keywordModalOpen, setKeywordModalOpen] = useState(false);
  const [technologyModalOpen, setTechnologyModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [invalidValue, setInvalidValue] = useState("");
  const [techLoading, setTechLoading] = useState(false);
  const [addTechModal, setAddTechModal] = useState(false);
  const [validTechName, setValidTechName] = useState("");
  const [selectedVideoIdPayload, setSelectedVideoIdPayload] = useState([]);
  const [selectedDocImgId, setSelectedDocImgId] = useState([]);
  const [removeKeyId, setRemoveKeyId] = useState([]);
  const [removeTechId, setRemoveTechId] = useState([]);
  const [removeLeadLinkId, setRemoveLeadLinkId] = useState([]);
  const [removeReferenceLinkId, setRemoveReferenceLinkId] = useState([]);
  const [conPerErrorMsg, setConPerErrorMsg] = useState("");
  const [leadSrcErrorMsg, setLeadSrcErrorMsg] = useState("");
  const [leadStatusErrorMsg, setLeadStatusErrorMsg] = useState("");
  const [compEmailError, setCompEmailError] = useState("");
  const [conPerEmailError, setContactPerEmailError] = useState("");
  const [uploadLinkError, setUploadLinkError] = useState("");
  const [value, setValue] = useState("");
  const [companies, setCompanies] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyNumber, setCompanyNumber] = useState("");
  const [companyPayload, setCompanyPayload] = useState("");
  const [companiesperson, setCompaniesPerson] = useState("");
  const [companyPayloadPerson, setCompanyPayloadPerson] = useState("");
  const [contactPerEmail, setContactPerEmail] = useState("");
  const [contactPersonPhoneNo, setContactPersonPhoneNo] = useState("");
  const [removeFeedbackId, setRemoveFeedbackId] = useState([]);
  const users = useSelector((state) => state.users.users);
  const [type /*setType*/] = useState("");
  const [selectedBDE, setSelectedBDE] = useState("");
  const [selectedBDA, setSelectedBDA] = useState("");
  const [selectedTL, setSelectedTL] = useState("");
  const [documentUploadLink, setDocumentUploadLink] = useState("");
  const [filename, setFileName] = useState("");

  const [displayedDocuments, setDisplayedDocuments] = useState([]);
  const [displayedfilenames, setDisplayedFilenames] = useState([]);
  const [companynameerror, setCompanyNameError] = useState("");
  const [documentlinkerror, setdocumentLinkError] = useState("");
  const [exiscomperror, setExisCompError] = useState("");
  // const [exispersonerror, setExisPersonError] = useState("");
  const [imageUploadLink, setImageUploadLink] = useState("");
  const [videoFileName, setVideoFileName] = useState("");
  const [imageFileName, setImageFileName] = useState("");
  const [displayedImages, setDisplayedImages] = useState([]);
  const [imagelinkerror, setImageLinkError] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [displayedVideofilenames, setDisplayedVideoFilenames] = useState([]);
  const [displayedImagefilenames, setDisplayedImageFilenames] = useState([]);
  const [projecterror, setProjectError] = useState("");
  const [bdaError, setBdaError] = useState("");

  console.log(leadStatus,"KKKK");

  let { state } = location;
  if (state === null) {
    state = {
      status: 0,
      page: 1,
      rowsPerPage: 10,
      filterApplied: false,
      filterByIdApplied: false,
      applyFilterId: "",
      selectedKeywords: [],
      selectedTLandBDAs: [],
      selectedTechnologies: [],
      text: "",
      converted: "",
      edit: "",
      keywordSwitchVal: true,
      techStackSwitchVal: true,
       TLandBDAsSwitchVal:true,
    };
  }
  const maxLength = 31;

  // lead convert
  const [convertCancel, setConvertCancel] = useState(false);

  const validateEmail = (email) => {
    const emailPattern =
      /^[a-zA-Z0-9](?!.*\.\.)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,63})+$/;
    return emailPattern.test(email);
  };

  const handleLinkClick = (event, website) => {
    if (website && !website.startsWith("http")) {
      // If the URL doesn't start with "http" or "https," prevent default behavior
      event.preventDefault();
      window.open(`http://${website}`, "_blank");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await callApi({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/leads/${leadId}`,
        });
        const obtainedClientId = response.data.client_id;
        const contactPersonId = response.data.client_contacts.id;
        const companyPersonName =
          response.data.client_contacts.contact_person_name;
        // Fetch contacts and set obtainedClientId
        dispatch(
          fetchContacts({
            clientId: response.data.client_id,
            page: "",
            limit: "",
          })
        );

        // Set the obtainedClientId
        setObtainedClientId(obtainedClientId);

        setCompaniesPerson(contactPersonId);
        setCompanyPayloadPerson(companyPersonName);
      } catch (error) {
        console.error(error.response);
      }
    };

    fetchData(); // Call the async function when obtainedClientId or leadId changes
  }, [obtainedClientId, leadId, dispatch]);

  useEffect(() => {
    dispatch(fetchLeadsById({ leadId }))
      .then(() => {
        // setLoading(false); // Set loading to false once data is fetched
      })
      .catch((error) => {
        // setLoading(false); // Set loading to false in case of an error
        console.error("Error fetching user data:", error);
      });
  }, [dispatch, leadId]);

  const handleRemoveImage = (index, arrayName) => {
    if (arrayName === "selectedImages") {
      // const updatedDocuments = [...selectedDocuments];
      const updatedDocPaths = [...imagePaths];

      // Remove the document and its path at the specified index
      const updatedDocuments = selectedImages.filter(
        (image) => image.linkId !== index
      );

      // Update the state with the modified arrays
      setSelectedImages(updatedDocuments);
      setImagePaths(updatedDocPaths);
      setSelectedIdPayload([...selectedIdPayload, index]);
    } else if (arrayName === "addedImages") {
      const updatedDocuments = [...displayedImages];
      const updatedDocPaths = [...displayedImagefilenames];

      // Remove the document and its path at the specified index
      updatedDocuments.splice(index, 1);
      updatedDocPaths.splice(index, 1);

      // Update the state with the modified arrays
      setDisplayedImages(updatedDocuments);
      setDisplayedImageFilenames(updatedDocPaths);
    }
  };

  const handleRemoveVideo = (index, arrayName) => {
    if (arrayName === "selectedVideos") {
      const updatedDocPaths = [...addedVideoPaths];

      // Remove the document and its path at the specified index
      const updatedDocuments = selectedVideos.filter(
        (image) => image.linkId !== index
      );

      // Update the state with the modified arrays
      setSelectedVideos(updatedDocuments);
      setAddedVideoPaths(updatedDocPaths);
      setSelectedVideoIdPayload([...selectedVideoIdPayload, index]);
    } else if (arrayName === "addedVideos") {
      const updatedDocuments = [...displayedVideos];
      const updatedDocPaths = [...displayedVideofilenames];

      // Remove the document and its path at the specified index
      updatedDocuments.splice(index, 1);
      updatedDocPaths.splice(index, 1);

      // Update the state with the modified arrays
      setDisplayedVideos(updatedDocuments);
      setDisplayedVideoFilenames(updatedDocPaths);
    }
  };
  const handleRemoveDocument = (index, arrayName) => {
    if (arrayName === "selectedDocuments") {
      // const updatedDocuments = [...selectedDocuments];
      const updatedDocPaths = [...documentPaths];

      // Remove the document and its path at the specified index
      const updatedDocuments = selectedDocuments.filter(
        (image) => image.linkId !== index
      );

      // Update the state with the modified arrays
      setSelectedDocuments(updatedDocuments);
      setDocumentPaths(updatedDocPaths);
      setSelectedDocImgId([...selectedDocImgId, index]);
    } else if (arrayName === "addedDocuments") {
      const updatedDocuments = [...displayedDocuments];
      const updatedDocPaths = [...displayedfilenames];

      // Remove the document and its path at the specified index
      updatedDocuments.splice(index, 1);
      updatedDocPaths.splice(index, 1);

      // Update the state with the modified arrays
      setDisplayedDocuments(updatedDocuments);
      setDisplayedFilenames(updatedDocPaths);
    }
  };

  const addImage = () => {
    if (!imageUploadLink) {
      setImageLinkError("Please Enter Screen shot Link");
      return;
    }
    const newVideo = imageUploadLink;
    /* Your logic to create a video object from uploadLink */
    // Clear the uploadLink input
    setDisplayedImages([...displayedImages, newVideo]);
    setDisplayedImageFilenames((prevFilenames) => [
      ...prevFilenames,
      imageFileName,
    ]);
    setImageUploadLink("");
    setImageFileName("");
  };

  const addVideo = () => {
    if (!uploadLink) {
      setUploadLinkError("Please Enter Video cast Link");
      return;
    }
    const newVideo = uploadLink;
    /* Your logic to create a video object from uploadLink */
    // Clear the uploadLink input
    setDisplayedVideos([...displayedVideos, newVideo]);
    setDisplayedVideoFilenames((prevFilenames) => [
      ...prevFilenames,
      videoFileName,
    ]);
    setUploadLink("");
    setVideoFileName("");
  };

  const addDocument = (index) => {
    if (!documentUploadLink) {
      setdocumentLinkError("Please Enter Document Link");
      return;
    }
    const newVideo = documentUploadLink;

    setDisplayedDocuments([...displayedDocuments, newVideo]);
    setDisplayedFilenames((prevFilenames) => [...prevFilenames, filename]);
    setFileName("");
    setDocumentUploadLink("");
  };
  const formData = new FormData();

  displayedImages.forEach((document, index) => {
    formData.append(`screenshot_files[${[index]}]`, document);
  });
  displayedImagefilenames.forEach((document, index) => {
    formData.append(`screenshot_file_name[${[index]}]`, document);
  });

  displayedVideofilenames.forEach((document, index) => {
    formData.append(`video_file_name[${[index]}]`, document);
  });
  displayedVideos.forEach((video, index) => {
    formData.append(`video_urls[${[index]}]`, video);
  });
  displayedDocuments.forEach((document, index) => {
    formData.append(`document_urls[${[index]}]`, document);
  });
  displayedfilenames.forEach((document, index) => {
    formData.append(`file_name[${[index]}]`, document);
  });

  addedDocuments.forEach((document, index) => {
    formData.append(`document_files`, document);
  });
  selectedIdPayload.forEach((image, index) => {
    formData.append(`ss_delete []`, image);
  });
  selectedVideoIdPayload.forEach((image, index) => {
    formData.append(`video_delete[]`, image);
  });
  selectedDocImgId.forEach((image, index) => {
    formData.append(`docuemnt_delete[]`, image);
  });

  const generateUniqueId = () => {
    return Date.now().toString();
  };
  // START :: LEAD LINK ADD AND DELETE
  const [projectLinks, setProjectLinks] = useState([
    {
      linkText: "",
      linkId: "",
    },
  ]);
  const addProjectLink = () => {
    setProjectLinks((current) => [
      ...current,
      { linkText: "", linkId: generateUniqueId() },
    ]);
  };

  const addTextToLink = (value, i) => {
    let linkArray = [...projectLinks];
    linkArray[i].linkText = value;
    setProjectLinks(linkArray);
  };
  // };

  const handleDeleteLink = (i) => {
    const updatedProjectLinks = projectLinks.filter(
      (image) => image.linkId !== i
    );
    setProjectLinks(updatedProjectLinks);

    setRemoveLeadLinkId([...removeLeadLinkId, i]);
  };
  // END :: LEAD LINK ADD AND DELETE

  // START :: REFERENCE LINK ADD AND DELETE
  const [ReferenceLinks, setReferenceLinks] = useState([
    {
      linkText: "",
      linkId: "",
    },
  ]);
  const addReferenceLink = () => {
    setReferenceLinks((current) => [
      ...current,
      { linkText: "", linkId: generateUniqueId() },
    ]);
  };

  const addReferenceToLink = (value, i) => {
    let linkArray = [...ReferenceLinks];
    linkArray[i].linkText = value;
    setReferenceLinks(linkArray);
  };

  const handleReferenceDeleteLink = (i) => {
    const updatedReferenceLinks = ReferenceLinks.filter(
      (image) => image.linkId !== i
    );
    setReferenceLinks(updatedReferenceLinks);

    setRemoveReferenceLinkId([...removeReferenceLinkId, i]);
  };
  // END :: REFERENCE LINK ADD AND DELETE
  useEffect(() => {
    if (leadData) {
      setValue(leadData?.client_type);
      if (leadData?.client_type === "existing_client") {
        setCompanyName(leadData?.company_name);
        setCompanies(leadData?.client_id);
        setCompanyPayload(leadData?.company_name);
        setCompanyEmail(leadData?.company_email);
        setCompanyNumber(leadData?.company_phone);
        // setCompaniesPerson(leadData?.client_id);
        setCompanyPayloadPerson(leadData?.contact_person);
        setContactPerEmail(leadData?.contact_person_email);
        setContactPersonPhoneNo(leadData?.contact_person_phone);
        setCompName("");
        setCompEmail("");
        setCompNumber("");
        setConPerson("");
        setConPersonEmail("");
        setConPersonNumber("");
      } else if (leadData?.client_type === "new_opportunity") {
        if (leadData?.company_name === "null") {
          setCompName("");
        } else {
          setCompName(leadData?.company_name);
        }
        if (
          leadData?.company_email === "null" ||
          leadData?.company_email === ""
        ) {
          setCompEmail("");
          setRoleEmail(true);
        } else {
          setCompEmail(leadData?.company_email);
          // setRoleEmail(false);
        }
        if (
          leadData?.company_phone === "null" ||
          leadData?.company_phone === ""
        ) {
          setCompNumber("");
          setRolePhone(true);
        } else {
          setCompNumber(leadData?.company_phone);
          // setRolePhone(false);
        }
        if (leadData?.contact_person === "null") {
          setConPerson("");
        } else {
          setConPerson(leadData?.contact_person);
        }
        if (
          leadData?.contact_person_email === "null" ||
          leadData?.contact_person_email === ""
        ) {
          setConPersonEmail("");
          setRoleContEmail(true);
        } else {
          setConPersonEmail(leadData?.contact_person_email);
          // setRoleContEmail(false);
        }
        if (
          leadData?.contact_person_phone === "null" ||
          leadData?.contact_person_phone === ""
        ) {
          setConPersonNumber("");
          setRoleContPhone(true);
        } else {
          setConPersonNumber(leadData?.contact_person_phone);
          // setRoleContPhone(false);
        }

        setCompanyName("");
        setCompanyPayload("");
        setCompanyEmail("");
        setCompanyNumber("");
        setCompaniesPerson("");
        setCompanyPayloadPerson("");
        setContactPerEmail("");
        setContactPersonPhoneNo("");
        setCompanies("");
      }
      if (leadData?.project_name === "null") {
        setProjectName("");
      } else {
        setProjectName(leadData?.project_name);
      }
      if (leadData?.estimation_link === "null") {
        setEstimationLink("");
      } else {
        setEstimationLink(leadData?.estimation_link);
      }

      if (leadData?.estimation_hours === null) {
        setEstimationTime("");
      } else {
        setEstimationTime(leadData?.estimation_hours);
      }
      if (leadData?.feedback_note === "null") {
        setFeedbackNote("");
      } else {
        setFeedbackNote(leadData?.feedback_note);
      }
      if (leadData?.key_features === "null") {
        setKeyFeatures("");
      } else {
        setKeyFeatures(leadData?.key_features);
      }
      if (leadData?.key_integration === "null") {
        setKeyIntegrations("");
      } else {
        setKeyIntegrations(leadData?.key_integration);
      }

      setSourcePayload(leadData?.lead_source);
      if (leadData && !isFormTouched) {
        setLeadStatus(leadData.status);
      }
      // setReferenceLinks(leadData?.lead_reference_links);

      // Map keyword names to match the format expected by Select component
      const initialKeywordOptions = leadData?.lead_keywords?.map(
        (keywordName) => ({
          value: keywordName?.keywords?.id,
          label: keywordName?.keywords?.keyword_name,
          id: keywordName?.id,
        })
      );

      // Set the initial options for the Select component
      setSelectedKeywords(initialKeywordOptions);

      const initialTechnologyOptions = leadData?.lead_technology?.map(
        (techName) => ({
          value: techName?.lead_technology?.id,
          label: techName?.lead_technology?.technology_name,
          id: techName?.id,
        })
      );

      setSelectedTechnologies(initialTechnologyOptions);

      const initialFeedbackOptions = leadData?.lead_feedbacks?.map(
        (feedbackName) => ({
          value: feedbackName?.feedbacks?.id,
          label: feedbackName?.feedbacks?.feedback,
          id: feedbackName?.feedback_id,
        })
      );

      setSelectedFeedback(initialFeedbackOptions);

      const initialProjectLinks = leadData?.lead_project_links?.map(
        (linkObj) => ({
          linkText: linkObj?.lead_link,
          linkId: linkObj?.id,
        })
      );
      if (initialProjectLinks && initialProjectLinks.length > 0) {
        setProjectLinks(initialProjectLinks);
      } else {
        setProjectLinks([
          {
            linkText: "",
            linkId: generateUniqueId(),
          },
        ]);
      }

      const initialReferenceLinks = leadData?.lead_reference_links?.map(
        (linkObj) => ({
          linkText: linkObj?.reference_link_url,
          linkId: linkObj?.id,
        })
      );
      if (initialReferenceLinks && initialReferenceLinks.length > 0) {
        setReferenceLinks(initialReferenceLinks);
      } else {
        setReferenceLinks([
          {
            linkText: "",
            linkId: generateUniqueId(),
          },
        ]);
      }

      const initialImageLinks = leadData?.lead_screenshot_links?.map(
        (linkObj) => ({
          linkText: linkObj.lead_screenshots_url,
          linkId: linkObj.id,
          linkName: linkObj.file_name,
        })
      );
      setSelectedImages(initialImageLinks);

      const initialVideoLinks = leadData?.lead_video_links?.map((linkObj) => ({
        linkText: linkObj.video_url,
        linkId: linkObj.id,
        linkName: linkObj.file_name,
      }));
      setSelectedVideos(initialVideoLinks);

      const initialDocumentLinks = leadData?.lead_document_links?.map(
        (linkObj) => ({
          linkText: linkObj.document_url,
          linkId: linkObj.id,
          linkName: linkObj.file_name,
        })
      );
      setSelectedDocuments(initialDocumentLinks);

      if (leadData?.tl_id === null) {
        setSelectedTL("");
      } else {
        setSelectedTL(leadData?.tl_id);
      }

      if (leadData?.bda_id === null) {
        setSelectedBDA("");
      } else {
        setSelectedBDA(leadData?.bda_id);
      }

      if (leadData?.bde_id === null) {
        setSelectedBDE("");
      } else {
        setSelectedBDE(leadData?.bde_id);
      }
    }
  }, [leadData,isFormTouched]);

  //API call for clients
  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  useEffect(() => {
    // Dispatch the fetchKeywords action to fetch the keywords
    dispatch(fetchKeywords({ query: "" }));
  }, [dispatch]);

  //API call for keywords
  useEffect(() => {
    // Dispatch the fetchKeywords action to fetch the keywords
    dispatch(fetchTechnologies({ query: "" }));
  }, [dispatch]);

  //API call for feddbacks
  useEffect(() => {
    // Dispatch the fetchKeywords action to fetch the keywords
    dispatch(fetchFeedbacks());
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      fetchUsers({
        type: type,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const keywordsData = keywords?.allKeywords?.map((keyword) => ({
    value: keyword.id,
    label: keyword.name,
  }));

  const handleCreate = (inputValue) => {
    setIsLoading(true);
    setInvalidValue(inputValue);
    // Simulate creating an option (replace this with your actual logic)
    setTimeout(() => {
      setIsLoading(false);

      setShowModal(true);
    }, 1000); // Simulate the loading process
  };

  const handleKeywordSelect = (selectedOptions) => {
    setSelectedKeywords(selectedOptions);
    // Construct the comma-separated list of selected IDs for the payload
    const selectedIds = selectedOptions.map((option) => option.value);
    setKeywordPayload(selectedIds);
  };

  const technologiesData = technologies?.allTechnologies?.map((technology) => ({
    value: technology.id,
    label: technology.name,
  }));

  const handleTechCreate = (inputValue) => {
    setTechLoading(true);
    setValidTechName(inputValue);
    // Simulate creating an option (replace this with your actual logic)
    setTimeout(() => {
      setTechLoading(false);
      setAddTechModal(true);
    }, 1000); // Simulate the loading process
  };

  // handle change for technology
  const handleTechnologySelect = (selectedOptions) => {
    setSelectedTechnologies(selectedOptions);

    // Construct the comma-separated list of selected IDs for the payload
    const selectedIds = selectedOptions.map((option) => option.value);
    setTechnologyPayload(selectedIds);
  };

  // Map the data to the desired format for FEEDBACKS
  const feedbackssData = feedbacks.map((feedback) => ({
    value: feedback.id,
    label: feedback.feedback,
  }));
  // handle change for technology
  const handleFeedbackSelect = (selectedOptions) => {
    setSelectedFeedback(selectedOptions);

    // Construct the comma-separated list of selected IDs for the payload
    const selectedIds = selectedOptions.map((option) => option.value);
    setFeedbackPayload(selectedIds);
  };
  //keyfeature handler

  const handleKeyFeatureChange = (data) => {
    // // Update the state variable with the current content
    setKeyFeatures(data);
  };
  //keyintegratione handler
  const handleKeyIntegrationChange = (data) => {
    // Update the state variable with the current content
    setKeyIntegrations(data);
  };

  const handleSourceSelect = (selectedValue) => {
    setSourcePayload(selectedValue);
    setLeadSrcErrorMsg("");
  };
  const handleLeadStatusSelect = (selectedValue) => {
    setLeadStatus(selectedValue);
    setIsFormTouched(true);
    setLeadStatusErrorMsg("");
  };
  const referenceLinks = ReferenceLinks;
  const referenceLinksArray = referenceLinks.map((element) => element.linkText);

  const projectLinksArray = projectLinks.map((element) => element.linkText);

  keywordPayload.forEach((value, index) => {
    formData.append("keyword_ids[]", value); // Assuming it's a comma-separated string
  });
  technologyPayload.forEach((value, index) => {
    formData.append("technology_ids[]", value); // Assuming it's a comma-separated string
  });
  feedbackPayload.forEach((value, index) => {
    formData.append("feedback_ids", value); // Assuming it's a comma-separated string
  });
  referenceLinksArray.forEach((value, index) => {
    formData.append("reference_links[]", value); // Assuming it's a comma-separated string
  });
  projectLinksArray.forEach((value, index) => {
    formData.append("lead_links[]", value);
  });
  removeKeyId.forEach((value, index) => {
    formData.append("keyword_delete[]", value);
  });
  removeTechId.forEach((value, index) => {
    formData.append("technology_delete[]", value);
  });
  removeLeadLinkId.forEach((value, index) => {
    formData.append("project_link_delete[]", value);
  });
  removeReferenceLinkId.forEach((value, index) => {
    formData.append("reference_link_delete[]", value);
  });
  removeFeedbackId.forEach((value, index) => {
    formData.append("feedback_delete[]", value);
  });

  const handleFeedbackChange = (event) => {
    const value = event.target.value;
    // Update the state variable with the current textarea value
    setFeedbackNote(value);
  };

  const contactPersonRef = useRef(null);
  const companyNameRef = useRef(null);
  const compEmailRef = useRef(null);
  const contEmailRef = useRef(null);
  const [saveButtonClickCount, setSaveButtonClickCount] = useState(0);
  const exiscompRef = useRef(null);
  const exiscontRef = useRef(null);
  const projectRef = useRef(null);
  const bdaRef = useRef(null);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [iswonButtonDisabled, setIsWonButtonDisabled] = useState(false);

  useEffect(() => {
    if (compEmailError && compEmailRef.current) {
      compEmailRef.current.focus();
    }
  }, [compEmailRef, compEmailError, saveButtonClickCount]);

  useEffect(() => {
    if (conPerEmailError && contEmailRef.current) {
      contEmailRef.current.focus();
    }
  }, [conPerEmailError, contEmailRef, saveButtonClickCount]);

  useEffect(() => {
    if (projecterror && projectRef.current) {
      projectRef.current.focus();
    }
  }, [projectRef, projecterror, saveButtonClickCount]);

  useEffect(() => {
    // Scroll to the "Technology Stacks" Typography component
    if (bdaError && bdaRef.current) {
      bdaRef.current.focus();
    }
  }, [bdaError, bdaRef, saveButtonClickCount]);

  useEffect(() => {
    if (conPerErrorMsg && contactPersonRef.current) {
      contactPersonRef.current.focus();
    }
  }, [contactPersonRef, conPerErrorMsg, saveButtonClickCount]);

  // useEffect(() => {
  //   if (exispersonerror && exiscontRef.current) {
  //     exiscontRef.current.focus();
  //   }
  // }, [exiscontRef, exispersonerror, saveButtonClickCount]);

  useEffect(() => {
    if (companynameerror && companyNameRef.current) {
      companyNameRef.current.focus();
    }
  }, [companyNameRef, companynameerror, saveButtonClickCount]);

  useEffect(() => {
    if (exiscomperror && exiscompRef.current) {
      exiscompRef.current.focus();
    }
  }, [exiscompRef, exiscomperror, saveButtonClickCount]);

  const handleConfirmation = async () => {
    setShowConfirmationModal(false);
    await submitData();
  };

  const submitData = async () => {
    setSaveButtonClickCount((prevCount) => prevCount + 1);
    if (isButtonDisabled) {
      return;
    }
    let flag = 0;
    if (value === "new_opportunity" && !compName?.trim()) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setCompanyNameError("Please select company name");
      flag = 1;
    }

    if (value === "new_opportunity" && !conPerson.trim()) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setConPerErrorMsg("Please enter contact person name");
      flag = 1;
    }
    if (value === "existing_client" && !companies) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setExisCompError("Please select company name");
      flag = 1;
    }
    // if (value === "existing_client" && !companiesperson) {
    //   // errorToast("Please fill in all the required fields marked with a star ( * )");
    //   setExisPersonError("Please select contact person");
    //   return;
    // }

    if (!sourcePayload) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setLeadSrcErrorMsg("Please select lead source");
      flag = 1;
    }

    if (!projectName) {
      setProjectError("Please enter project name");
      flag = 1;
    }

    if (!leadStatus) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setLeadStatusErrorMsg("Please select lead status");
      flag = 1;
    }
    if (compEmailError === "Please enter a valid email address") {
      // errorToast("Please fill all  required fields");
      flag = 1;
    }
    if (conPerEmailError === "Please enter a valid email address") {
      // errorToast("Please fill all  required fields");
      flag = 1;
    }
    if (!selectedBDA) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setBdaError("Please select BDM");
      flag = 1;
    }
    if (flag !== 0) {
      return;
    }
    if (!showConfirmationModal && leadStatus === "lost") {
      setShowConfirmationModal(true);
      return;
    }
    setIsButtonDisabled(true);

    const leadData = {
      client_type: value,
      company_name: value === "new_opportunity" ? compName : companyPayload,
      company_email: value === "new_opportunity" ? compEmail : companyEmail,
      company_phone: value === "new_opportunity" ? compNumber : companyNumber,
      contact_person:
        value === "new_opportunity" ? conPerson : companyPayloadPerson,
      contact_person_email:
        value === "new_opportunity" ? conPersonEmail : contactPerEmail,
      contact_person_phone:
        value === "new_opportunity" ? conPersonNumber : contactPersonPhoneNo,
      key_features: keyFeatures,
      key_integration: keyIntegrations,
      lead_source: sourcePayload,
      project_name: projectName,
      estimation_link: estimationLink,
      estimation_hours: estimationTime,
      feedback_note: feedbackNote,
      status: leadStatus,
      client_id: value === "new_opportunity" ? "" : companies,
      client_contact_id: value === "new_opportunity" ? "" : companiesperson,
      // bda_id: selectedBDA === "" ? 0 : selectedBDA,
      ...(selectedBDA && { bda_id: selectedBDA }),
      bde_id: selectedBDE === "" ? 0 : selectedBDE,
      // ...(selectedBDE && { bde_id: selectedBDE }),
      tl_id: selectedTL === "" ? 0 : selectedTL,
      // ...(selectedTL && { tl_id: selectedTL }),
    };
    for (const key in leadData) {
      if (Object.hasOwnProperty.call(leadData, key)) {
        formData.append(key, leadData[key]);
      }
    }
    try {
      const response = await dispatch(editLeadByIdAdmin(leadId, formData));

      if (response.success) {
        navigate("/leads", {
          state: {
            page: state.page,
            rowsPerPage: state.rowsPerPage,
            status: state.status,
            filterApplied: state.filterApplied,
            filterByIdApplied: state.filterByIdApplied,
            selectedKeywords: state.selectedKeywords,
            selectedTLandBDAs: state.selectedTLandBDAs,
            selectedTechnologies: state.selectedTechnologies,
            keywordSwitchVal: state.keywordSwitchVal,
            techStackSwitchVal: state.techStackSwitchVal,
            TLandBDAsSwitchVal:state.TLandBDAsSwitchVal,
            text: state.text,
            converted: state.converted,
            applyFilterId: state.applyFilterId,
            edit: state.edit,
          },
        });
        successToast("Lead updated successfully!");
        setSelectedTL("");
        setSelectedBDA("");
        setSelectedBDE("");
        setCompName("");
        setCompEmail("");
        setCompNumber("");
        setConPerson("");
        setConPersonEmail("");
        setConPersonNumber("");
        setCompanyName("");
        setCompanyPayload("");
        setCompanyEmail("");
        setCompanyNumber("");
        setCompaniesPerson("");
        setCompanyPayloadPerson("");
        setContactPerEmail("");
        setContactPersonPhoneNo("");
        setCompanies("");
      } else {
        console.error("Error creating user:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      setIsButtonDisabled(false);
    }
  };

  const closeKeyworddModal = () => setKeywordModalOpen(false);
  const closeTechnologyModal = () => setTechnologyModalOpen(false);

  // functions for existing/new oppoutunity
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const companyOptions = clients?.client?.map((client) => ({
    value: client.id,
    label: client.company_name,
  }));

  const companyDetails = clients?.client?.filter(
    (client) => companies === client?.id
  );
  // console.(companyDetails, "companyDetails");

  const companyEmailChnage = companyDetails?.map(
    (email) => email.company_email
  )[0];

  const companyPhoneChange = companyDetails?.map(
    (phone) => phone.company_phone
  )[0];

  const handleCompanySelect = async (selectedOptions) => {
    if (selectedOptions) {
      setExisCompError("");
    }
    setCompanies(selectedOptions.value);
    setCompanyPayload(selectedOptions.label);

    // Extract the clientId from selectedOptions
    const clientId = selectedOptions.value;

    // Dispatch the action with clientId
    dispatch(fetchContacts({ clientId: clientId, page: "", limit: "" }));
    try {
      const response = await callApi({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/contact`,
        params: {
          page: "",
          limit: "",
          client_id: clientId,
        },
      });
      const primaryContact = response.data?.contacts?.find(
        (contact) => contact.is_primary
      );
      setCompaniesPerson(primaryContact?.id);
      setCompanyPayloadPerson(primaryContact?.contact_person_name);
      setContactPerEmail(primaryContact?.contact_person_email);
      setContactPersonPhoneNo(primaryContact?.contact_person_phone);
      return response.data;
    } catch (error) {
      console.error(error.response);
    }
  };

  // useEffect to set company email and company phone when there is change in company name
  useEffect(() => {
    setCompanyEmail(companyEmailChnage);
    setCompanyNumber(companyPhoneChange);
    setContactPerEmail("");
    setContactPersonPhoneNo("");
  }, [companyEmailChnage, companyPhoneChange]);

  const contactPersonOptions = contacts?.contacts?.map((contact) => ({
    value: contact.id,
    label: contact.contact_person_name,
  }));
  const contactPersonDetails = contacts?.contacts?.filter(
    (contact) => companiesperson === contact.id
  );

  const contactPersonEmail = contactPersonDetails?.map(
    (contact) => contact?.contact_person_email
  )[0];
  const contactPersonPhone = contactPersonDetails?.map(
    (contact) => contact.contact_person_phone
  )[0];
  const handleContactSelect = (selectedOptions) => {
    setCompaniesPerson(selectedOptions.value);
    setCompanyPayloadPerson(selectedOptions.label);
  };
  // useeffect to set the contact person details when we change contact person
  useEffect(() => {
    setContactPerEmail(contactPersonEmail);
    setContactPersonPhoneNo(contactPersonPhone);
  }, [contactPersonEmail, contactPersonPhone]);

  const isStatusWon = leadData?.status === "won";

  const teamleaders = users?.filteredTeamManager;
  const BDA = users?.filteredBda;
  const BDE = users?.filteredBde;

  const TeamLeaderoptions = teamleaders?.map((tl) => ({
    value: tl.id,
    label: `${capitalizeWords(tl.first_name)} ${capitalizeWords(tl.last_name)}`,
  }));

  const BDAOptions = BDA?.map((tl) => ({
    value: tl.id,
    label: `${capitalizeWords(tl.first_name)} ${capitalizeWords(tl.last_name)}`,
  }));

  const BDEOptions = BDE?.map((tl) => ({
    value: tl.id,
    label: `${capitalizeWords(tl.first_name)} ${capitalizeWords(tl.last_name)}`,
  }));

  const handleBDESelect = (selectedOption) => {
    setSelectedBDE(selectedOption);
  };

  const handleBDASelect = (selectedOption) => {
    if (selectedOption.value !== null) {
      setSelectedBDA(null);
    }
    setSelectedBDA(selectedOption);
    setBdaError("");
  };

  const handleTLSelect = (selectedOption) => {
    setSelectedTL(selectedOption);
  };

  if(!leadData){
    return <Loader/>
  }

  return (
    <>
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => {
              navigate("/leads", {
                state: {
                  page: state.page,
                  rowsPerPage: state.rowsPerPage,
                  status: state.status,
                  filterApplied: state.filterApplied,
                  filterByIdApplied: state.filterByIdApplied,
                  selectedKeywords: state.selectedKeywords,
                  selectedTLandBDAs: state.selectedTLandBDAs,
                  selectedTechnologies: state.selectedTechnologies,
                  keywordSwitchVal: state.keywordSwitchVal,
                  techStackSwitchVal: state.techStackSwitchVal,
                  TLandBDAsSwitchVal:state.TLandBDAsSwitchVal,
                  text: state.text,
                  converted: state.converted,
                  applyFilterId: state.applyFilterId,
                  edit: state.edit,
                },
              });
              setSelectedTL("");
              setSelectedBDA("");
              setSelectedBDE("");
              setCompName("");
              setCompEmail("");
              setCompNumber("");
              setConPerson("");
              setConPersonEmail("");
              setConPersonNumber("");
              setCompanyName("");
              setCompanyPayload("");
              setCompanyEmail("");
              setCompanyNumber("");
              setCompaniesPerson("");
              setCompanyPayloadPerson("");
              setContactPerEmail("");
              setContactPersonPhoneNo("");
              setCompanies("");
            }}
          ></Button>
          Edit Lead
        </Typography>
      </Box>

      <Box className="content-wrapper">
        <Grid container spacing={3}>
          <Grid item lg={8} md={12} sm={12} xs={12}>
            <Box className="card-wrapper" sx={{ height: "100%" }}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Lead Details
                  </Typography>
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Select Customer
                    {/* <Typography variant="body1" component="label" className="asterisk">*</Typography> */}
                  </Typography>
                  <RadioGroup
                    aria-label="company"
                    name="company"
                    value={value}
                    onChange={handleChange}
                    style={{ display: "flex", flexDirection: "row" }}
                    // disabled={leadData?.status === "won"}
                  >
                    <FormControlLabel
                      value="new_opportunity"
                      control={<Radio />}
                      label={
                        <span style={{ fontSize: "14px" }}>
                          New Opportunity
                        </span>
                      }
                      disabled={leadData?.status === "won"}
                    />
                    <FormControlLabel
                      value="existing_client"
                      control={<Radio />}
                      label={
                        <span style={{ fontSize: "14px" }}>
                          Existing Client
                        </span>
                      }
                      disabled={leadData?.status === "won"}
                    />
                  </RadioGroup>
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Name
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                    {/* <Typography variant="body1" component="label" className="asterisk">*</Typography> */}
                  </Typography>

                  {value === "new_opportunity" ? (
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Company Name"
                      value={compName}
                      inputRef={companyNameRef}
                      onChange={(event) => {
                        setCompName(event.target.value);
                        if (event.target.value) {
                          setCompanyNameError("");
                        }
                      }}
                    />
                  ) : (
                    <Select
                      name="colors"
                      options={companyOptions}
                      className="muilt-select-field"
                      classNamePrefix="select"
                      placeholder="Select Company"
                      ref={exiscompRef}
                      onChange={(selectedOption) =>
                        handleCompanySelect(selectedOption)
                      }
                      value={
                        companyOptions?.find(
                          (option) => option?.value === companies
                        ) || null
                      }
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "#8CC714",
                        },
                      })}
                      isDisabled={isStatusWon}
                    />
                  )}

                  {value === "existing_client"
                    ? exiscomperror && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {exiscomperror}
                        </Typography>
                      )
                    : companynameerror && (
                        <Typography
                          variant="body1"
                          component="p"
                          className="error"
                        >
                          {companynameerror}
                        </Typography>
                      )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Email
                  </Typography>
                  {value === "new_opportunity" ? (
                    <Box
                      variant="div"
                      component="div"
                      className="cross-line-icon"
                    >
                      <TextField
                        className="input-field"
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        placeholder="Enter Company Email"
                        value={roleEmail ? compEmail : maskEmail(compEmail)}
                        inputRef={compEmailRef}
                        onChange={(event) => {
                          const email = event.target.value;
                          setCompEmail(email);
                          if (validateEmail(email) || !email) {
                            setCompEmailError(""); // Clear the error message if the email is valid.
                          } else {
                            setCompEmailError(
                              "Please enter a valid email address"
                            );
                          }
                        }}
                        onBlur={() => {
                          if (compEmail && validateEmail(compEmail)) {
                            setCompEmailError(""); // Email is present and valid, clear the error message.
                          } else {
                            setCompEmailError(
                              "Please enter a valid email address"
                            );
                          }
                          if (!compEmail) {
                            setCompEmailError("");
                          }
                        }}
                        InputProps={{
                          readOnly: !roleEmail && compEmail,
                        }}
                      />
                      {!roleEmail && (
                        <RiCloseCircleLine
                          className="cross-line"
                          onClick={() => {
                            setCompEmail("");
                            setRoleEmail(true); // Set role to true to make the field editable
                          }}
                        />
                      )}
                    </Box>
                  ) : (
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Company Email"
                      value={
                        getUserRole ? companyEmail : maskEmail(companyEmail)
                      }
                      disabled
                      aria-readonly={isStatusWon}
                    />
                  )}
                  {compEmailError && (
                    <Typography variant="body2" className="error" color="error">
                      {compEmailError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Phone No.
                  </Typography>
                  {value === "new_opportunity" ? (
                    <Box
                      variant="div"
                      component="div"
                      className="cross-line-icon"
                    >
                      <TextField
                        className="input-field"
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        placeholder="Enter Company Phone No."
                        // type="number"
                        value={
                          rolePhone ? compNumber : maskPhoneNumber(compNumber)
                        }
                        onChange={(event) => {
                          setCompNumber(event.target.value);
                        }}
                        InputProps={{
                          readOnly: !rolePhone && compNumber,
                        }}
                      />
                      {!rolePhone && (
                        <RiCloseCircleLine
                          className="cross-line"
                          onClick={() => {
                            setCompNumber("");
                            setRolePhone(true); // Set role to true to make the field editable
                          }}
                        />
                      )}
                    </Box>
                  ) : (
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Company Phone No."
                      // type="number"
                      value={
                        getUserRole
                          ? companyNumber
                          : maskPhoneNumber(companyNumber)
                      }
                      aria-readonly={isStatusWon}
                      disabled
                    />
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  {value === "new_opportunity" ? (
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Contact Person Name"
                      value={conPerson}
                      inputRef={contactPersonRef}
                      onChange={(event) => {
                        setConPerson(event.target.value);

                        if (event.target.value) {
                          setConPerErrorMsg("");
                        }
                      }}
                    />
                  ) : (
                    <Select
                      name="colors"
                      options={contactPersonOptions}
                      className="muilt-select-field"
                      classNamePrefix="select"
                      placeholder="Select Contact Person"
                      onChange={(selectedOption) =>
                        handleContactSelect(selectedOption)
                      }
                      value={
                        contactPersonOptions?.find(
                          (option) => option?.value === companiesperson
                        ) || null
                      }
                      ref={exiscontRef}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "#8CC714",
                        },
                      })}
                    ></Select>
                  )}
                  {/* {exispersonerror && (
                    <Typography variant="body1" component="p" className="error">
                      {exispersonerror}
                    </Typography>
                  )} */}
                  {conPerErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {conPerErrorMsg}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person's Email
                  </Typography>
                  {value === "new_opportunity" ? (
                    <Box
                      variant="div"
                      component="div"
                      className="cross-line-icon"
                    >
                      <TextField
                        className="input-field"
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        placeholder="Enter Contact Person's Email"
                        value={
                          roleContEmail
                            ? conPersonEmail
                            : maskEmail(conPersonEmail)
                        }
                        inputRef={contEmailRef}
                        onChange={(event) => {
                          const email = event.target.value;
                          setConPersonEmail(email);
                          if (validateEmail(email) || !email) {
                            setContactPerEmailError(""); // Clear the error message if the email is valid.
                          } else {
                            setContactPerEmailError(
                              "Please enter a valid email address"
                            );
                          }
                        }}
                        onBlur={() => {
                          if (conPersonEmail && validateEmail(conPersonEmail)) {
                            setContactPerEmailError(""); // Email is present and valid, clear the error message.
                          } else {
                            setContactPerEmailError(
                              "Please enter a valid email address"
                            );
                          }
                          if (!conPersonEmail) {
                            setContactPerEmailError("");
                          }
                        }}
                        InputProps={{
                          readOnly: !roleContEmail && conPersonEmail,
                        }}
                      />
                      {!roleContEmail && (
                        <RiCloseCircleLine
                          className="cross-line"
                          onClick={() => {
                            setConPersonEmail("");
                            setRoleContEmail(true); // Set role to true to make the field editable
                          }}
                        />
                      )}
                    </Box>
                  ) : (
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Contact Person's Email"
                      value={
                        getUserRole
                          ? contactPerEmail
                          : maskEmail(contactPerEmail)
                      }
                      disabled
                    />
                  )}
                  {conPerEmailError && (
                    <Typography variant="body2" className="error" color="error">
                      {conPerEmailError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person's Phone No.
                  </Typography>
                  {value === "new_opportunity" ? (
                    <Box
                      variant="div"
                      component="div"
                      className="cross-line-icon"
                    >
                      <TextField
                        className="input-field"
                        fullWidth
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        size="small"
                        placeholder="Enter Contact Person's Phone No."
                        value={
                          roleContPhone
                            ? conPersonNumber
                            : maskPhoneNumber(conPersonNumber)
                        }
                        onChange={(event) => {
                          setConPersonNumber(event.target.value);
                        }}
                        InputProps={{
                          readOnly: !roleContPhone && conPersonNumber,
                        }}
                      />
                      {!roleContPhone && (
                        <RiCloseCircleLine
                          className="cross-line"
                          onClick={() => {
                            setConPersonNumber("");
                            setRoleContPhone(true); // Set role to true to make the field editable
                          }}
                        />
                      )}
                    </Box>
                  ) : (
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Contact Person's Phone No."
                      value={
                        getUserRole
                          ? contactPersonPhoneNo
                          : maskPhoneNumber(contactPersonPhoneNo)
                      }
                      disabled
                    />
                  )}
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1" component="label">
                      Keywords
                    </Typography>
                  </Stack>
                  <CreatableSelect
                    isClearable
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    isMulti
                    name="colors"
                    options={keywordsData}
                    className="muilt-select-field"
                    placeholder="Select Keywords"
                    classNamePrefix="select"
                    onChange={(selectedOptions, { removedValue }) => {
                      if (removedValue) {
                        const removedOptionId = removedValue.id; // assuming 'value' contains the ID
                        setRemoveKeyId([...removeKeyId, removedOptionId]);
                      }
                      handleKeywordSelect(selectedOptions);
                    }}
                    value={selectedKeywords}
                    onCreateOption={handleCreate}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1" component="label">
                      Technology Stacks
                    </Typography>
                  </Stack>
                  <CreatableSelect
                    isClearable
                    isDisabled={techLoading}
                    isLoading={techLoading}
                    isMulti
                    name="colors"
                    options={technologiesData}
                    className="muilt-select-field"
                    classNamePrefix="select"
                    placeholder="Select Technologies"
                    onChange={(selectedOptions, { removedValue }) => {
                      if (removedValue) {
                        const removedOptionId = removedValue.id; // assuming 'value' contains the ID
                        setRemoveTechId([...removeTechId, removedOptionId]);
                      }
                      handleTechnologySelect(selectedOptions);
                    }}
                    value={selectedTechnologies}
                    onCreateOption={handleTechCreate}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Key Features
                  </Typography>
                  <Box className="ck-editor-wrapper">
                    <ReactQuill
                      value={keyFeatures}
                      onChange={handleKeyFeatureChange}
                      modules={modules}
                    />
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Key Integration
                  </Typography>
                  <Box className="ck-editor-wrapper">
                    <ReactQuill
                      value={keyIntegrations}
                      onChange={handleKeyIntegrationChange}
                      modules={modules}
                    />
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Lead Source
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Select
                    name="colors"
                    options={leadSourceOptions}
                    className="muilt-select-field"
                    value={
                      leadSourceOptions?.find(
                        (option) => option?.value === sourcePayload
                      ) || null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handleSourceSelect(selectedOption?.value);
                      } else {
                        setSourcePayload("");
                      }
                    }}
                    isClearable={true}
                    classNamePrefix="select"
                    placeholder="Select Lead Status"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                  {leadSrcErrorMsg && (
                    <Typography variant="body1" component="p" className="error">
                      {leadSrcErrorMsg}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    BDE
                  </Typography>
                  <Select
                    name="colors"
                    options={BDEOptions}
                    value={BDEOptions?.find(
                      (option) => option?.value === selectedBDE
                    )}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handleBDESelect(selectedOption?.value);
                      } else {
                        setSelectedBDE("");
                      }
                    }}
                    isClearable={true}
                    className="muilt-select-field"
                    placeholder="Select BDE"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    BDM
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <Select
                    name="colors"
                    options={BDAOptions}
                    ref={bdaRef}
                    value={BDAOptions?.find(
                      (option) => option?.value === selectedBDA
                    )}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handleBDASelect(selectedOption?.value);
                      } else {
                        setSelectedBDA("");
                      }
                    }}
                    isClearable={true}
                    className="muilt-select-field"
                    placeholder="Select BDM"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                  {bdaError && (
                    <Typography variant="body1" component="p" className="error">
                      {bdaError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    TL
                  </Typography>
                  <Select
                    name="colors"
                    options={TeamLeaderoptions}
                    value={TeamLeaderoptions?.find(
                      (option) => option?.value === selectedTL
                    )}
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        handleTLSelect(selectedOption?.value);
                      } else {
                        setSelectedTL("");
                      }
                    }}
                    isClearable={true}
                    className="muilt-select-field"
                    placeholder="Select Team Leader"
                    classNamePrefix="select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Name
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    inputRef={projectRef}
                    placeholder="Enter Project Name"
                    value={projectName}
                    onChange={(event) => {
                      setProjectName(event.target.value);
                      if (event.target.value) {
                        setProjectError("");
                      }
                    }}
                  />
                  {projecterror && (
                    <Typography variant="body1" component="p" className="error">
                      {projecterror}
                    </Typography>
                  )}
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Links
                  </Typography>
                  <Stack container spacing={2}>
                    {projectLinks.map((element, index) => {
                      return (
                        <Box className="add-multi-input">
                          <TextField
                            className="input-field"
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Enter Project Link"
                            value={element.linkText}
                            onChange={(e) => {
                              addTextToLink(e.target.value, index);
                            }}
                          />
                          <Box className="action-btn-wrap">
                            {index === projectLinks.length - 1 && (
                              <Button
                                variant="outlined"
                                className="action-btn edit-btn"
                                startIcon={<RiAddCircleLine />}
                                disableRipple
                                onClick={() => addProjectLink()}
                              ></Button>
                            )}
                            {index !== projectLinks.length - 1 && (
                              <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                disableRipple
                                onClick={() => {
                                  handleDeleteLink(element.linkId);
                                }}
                              ></Button>
                            )}
                          </Box>
                        </Box>
                      );
                    })}
                  </Stack>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Estimation Link
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Estimation Link"
                    value={estimationLink}
                    onChange={(event) => {
                      setEstimationLink(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Estimation(In hours)
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Estimation"
                    type="number"
                    value={estimationTime}
                    onChange={(event) => {
                      setEstimationTime(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Lead Feedback
                  </Typography>
                  <Select
                    isMulti
                    name="colors"
                    options={feedbackssData}
                    className="muilt-select-field"
                    onChange={(selectedOptions, { removedValue }) => {
                      if (removedValue) {
                        const removedOptionId = removedValue.id; // assuming 'value' contains the ID
                        setRemoveFeedbackId([
                          ...removeFeedbackId,
                          removedOptionId,
                        ]);
                      }
                      handleFeedbackSelect(selectedOptions);
                    }}
                    value={selectedFeedback}
                    classNamePrefix="select"
                    placeholder="Select Lead Feedback"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Feedback Note
                  </Typography>
                  <TextareaAutosize
                    className="textarea-field"
                    placeholder="Add Feedback Note"
                    value={feedbackNote}
                    onChange={handleFeedbackChange}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Stack spacing={3} className="h-100">
              <Box className="card-wrapper">
                <Typography variant="body1" component="label">
                  Lead Status
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  name="colors"
                  options={leadStatusOptions}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  value={
                    leadStatusOptions.find(
                      (option) => option.value === leadStatus
                    ) || null
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      handleLeadStatusSelect(selectedOption?.value);
                    } else {
                      setLeadStatus("");
                    }
                  }}
                  isClearable={true}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "#8CC714",
                    },
                  })}
                  isDisabled={isStatusWon}
                />
                {leadStatusErrorMsg && (
                  <Typography variant="body1" component="p" className="error">
                    {leadStatusErrorMsg}
                  </Typography>
                )}
              </Box>
              <Box className="card-wrapper add-scroll-space">
                <Stack spacing={3} className="view-info">
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Documents
                  </Typography>
                  <Box className="document-card-content-wrapper over-flow-auto">
                    <Box>
                      <Typography variant="body1" component="label">
                        File Name
                      </Typography>
                      <Stack container spacing={2}>
                        <Box className="add-multi-input">
                          <TextField
                            className="input-field"
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Please Enter File Name"
                            value={filename}
                            onChange={(event) => {
                              setFileName(event.target.value);
                            }}
                          />
                        </Box>
                      </Stack>
                      <Typography
                        variant="body1"
                        component="label"
                        style={{ marginTop: "10px" }}
                      >
                        URL
                      </Typography>
                      <Stack container spacing={2}>
                        <Box className="add-multi-input">
                          <TextField
                            className="input-field"
                            fullWidth
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            size="small"
                            placeholder="Paste Document URL Here"
                            value={documentUploadLink}
                            onChange={(event) => {
                              setDocumentUploadLink(event.target.value);
                              if (event.target.value) {
                                setdocumentLinkError("");
                              }
                            }}
                          />
                          <Button
                            variant="outlined"
                            className="action-btn upload-btn"
                            disableRipple
                            onClick={addDocument}
                          >
                            Upload
                          </Button>
                        </Box>
                      </Stack>
                      {documentlinkerror && (
                        <Typography
                          variant="body2"
                          className="error"
                          color="error"
                        >
                          {documentlinkerror}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Stack
                    className="document-list-wrapper over-flow-auto"
                    spacing={2}
                  >
                    {selectedDocuments?.map((document) => {
                      const text =
                        !document?.linkName || document?.linkName === "null"
                          ? document?.linkText
                          : document.linkName;
                      const displayText =
                        text.length > maxLength
                          ? text.slice(0, maxLength) + "..."
                          : text;
                      return (
                        <>
                          <Box className="document-upload-wrapper">
                            <Box
                              key={document.linkId}
                              className="document-type"
                            >
                              <Typography variant="body1" component="span">
                                <a
                                  href={document.linkText}
                                  target="_blank"
                                  style={{ color: "var(--primary-text-color)" }}
                                  rel="noopener noreferrer"
                                  onClick={(e) =>
                                    handleLinkClick(e, document.linkText)
                                  }
                                >
                                  {displayText}
                                </a>
                              </Typography>
                            </Box>
                            <Box className="document-action action-btn-wrap">
                              <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                disableRipple
                                onClick={() =>
                                  handleRemoveDocument(
                                    document.linkId,
                                    "selectedDocuments"
                                  )
                                }
                              ></Button>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                    {displayedDocuments &&
                      displayedDocuments.map((video, index) => {
                        const text = displayedfilenames[index]
                          ? displayedfilenames[index]
                          : displayedDocuments[index];
                        const displayText =
                          text.length > maxLength
                            ? text.slice(0, maxLength) + "..."
                            : text;

                        return (
                          <Box key={index} className="document-upload-wrapper">
                            <Box className="document-type">
                              <a
                                href={displayedDocuments[index]}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) =>
                                  handleLinkClick(e, displayedDocuments[index])
                                }
                              >
                                <Typography variant="body1" component="span">
                                  {displayText}
                                </Typography>
                              </a>
                            </Box>
                            <Box className="document-action action-btn-wrap">
                              <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                disableRipple
                                onClick={() =>
                                  handleRemoveDocument(index, "addedDocuments")
                                }
                              ></Button>
                            </Box>
                          </Box>
                        );
                      })}
                  </Stack>
                </Stack>
              </Box>
              <Box
                className="card-wrapper add-scroll-space view-info"
                sx={{ flex: 1 }}
              >
                <Typography variant="body1" component="label">
                  Reference Links
                </Typography>
                <Stack container spacing={2} className="over-flow-auto">
                  {ReferenceLinks.map((element, index) => {
                    return (
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Enter Reference Link"
                          value={element.linkText}
                          onChange={(e) => {
                            addReferenceToLink(e.target.value, index);
                          }}
                        />
                        <Box className="action-btn-wrap">
                          {index === ReferenceLinks.length - 1 && (
                            <Button
                              variant="outlined"
                              className="action-btn edit-btn"
                              startIcon={<RiAddCircleLine />}
                              disableRipple
                              onClick={() => addReferenceLink()}
                            ></Button>
                          )}
                          {index !== ReferenceLinks.length - 1 && (
                            <Button
                              variant="outlined"
                              className="action-btn dlt-btn"
                              startIcon={<RiDeleteBinLine />}
                              disableRipple
                              onClick={() => {
                                handleReferenceDeleteLink(element.linkId);
                              }}
                            ></Button>
                          )}
                        </Box>
                      </Box>
                    );
                  })}
                </Stack>
              </Box>
            </Stack>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="card-wrapper add-bottom-scroll-space">
              <Stack spacing={3} className="view-info">
                <Typography variant="h3" component="h3" className="card-title">
                  Lead Video Casts
                </Typography>
                <Box className="document-card-content-wrapper over-flow-auto">
                  <Box>
                    <Typography variant="body1" component="label">
                      File Name
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Please Enter File Name"
                          value={videoFileName}
                          onChange={(event) => {
                            setVideoFileName(event.target.value);
                          }}
                        />
                      </Box>
                    </Stack>
                    <Typography
                      variant="body1"
                      component="label"
                      style={{ marginTop: "10px" }}
                    >
                      Video Cast URL
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Paste Video Cast URL Here"
                          value={uploadLink}
                          onChange={(event) => {
                            setUploadLink(event.target.value);
                            if (event.target.value) {
                              setUploadLinkError("");
                            }
                          }}
                        />
                        <Button
                          variant="outlined"
                          className="action-btn upload-btn"
                          disableRipple
                          onClick={addVideo}
                        >
                          Upload
                        </Button>
                      </Box>
                    </Stack>
                    {uploadLinkError && (
                      <Typography
                        variant="body2"
                        className="error"
                        color="error"
                      >
                        {uploadLinkError}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Stack
                  className="document-list-wrapper over-flow-auto"
                  spacing={2}
                >
                  {selectedVideos?.map((document) => {
                    const text = document?.linkName
                      ? document?.linkName
                      : document.linkText;
                    const displayText =
                      text.length > maxLength
                        ? text.slice(0, maxLength) + "..."
                        : text;
                    return (
                      <>
                        <Box className="document-upload-wrapper">
                          <Box key={document.linkId} className="document-type">
                            <Typography variant="body1" component="span">
                              <a
                                href={document.linkText}
                                target="_blank"
                                style={{ color: "var(--primary-text-color)" }}
                                rel="noopener noreferrer"
                                onClick={(e) =>
                                  handleLinkClick(e, document.linkText)
                                }
                              >
                                {displayText}
                              </a>
                            </Typography>
                          </Box>
                          <Box className="document-action action-btn-wrap">
                            <Button
                              variant="outlined"
                              className="action-btn dlt-btn"
                              startIcon={<RiDeleteBinLine />}
                              disableRipple
                              onClick={() =>
                                handleRemoveVideo(
                                  document.linkId,
                                  "selectedVideos"
                                )
                              }
                            ></Button>
                          </Box>
                        </Box>
                      </>
                    );
                  })}
                  {displayedVideos &&
                    displayedVideos.map((video, index) => {
                      const text = displayedVideofilenames[index]
                        ? displayedVideofilenames[index]
                        : displayedVideos[index];
                      const displayText =
                        text.length > maxLength
                          ? text.slice(0, maxLength) + "..."
                          : text;
                      return (
                        <Box key={index} className="document-upload-wrapper">
                          <Box className="document-type">
                            <a
                              href={displayedVideos[index]}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) =>
                                handleLinkClick(e, displayedVideos[index])
                              }
                            >
                              <Typography variant="body1" component="span">
                                {displayText}
                              </Typography>
                            </a>
                          </Box>
                          <Box className="document-action action-btn-wrap">
                            <Button
                              variant="outlined"
                              className="action-btn dlt-btn"
                              startIcon={<RiDeleteBinLine />}
                              disableRipple
                              onClick={() =>
                                handleRemoveVideo(index, "addedVideos")
                              }
                            ></Button>
                          </Box>
                        </Box>
                      );
                    })}
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="card-wrapper add-bottom-scroll-space">
              <Stack spacing={3} className="view-info">
                <Typography variant="h3" component="h3" className="card-title">
                  Lead Screen Shots
                </Typography>
                <Box className="document-card-content-wrapper over-flow-auto">
                  <Box>
                    <Typography variant="body1" component="label">
                      File Name
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Please Enter File Name"
                          value={imageFileName}
                          onChange={(event) => {
                            setImageFileName(event.target.value);
                          }}
                        />
                      </Box>
                    </Stack>
                    <Typography
                      variant="body1"
                      component="label"
                      style={{ marginTop: "10px" }}
                    >
                      Sreen Shot URL
                    </Typography>
                    <Stack container spacing={2}>
                      <Box className="add-multi-input">
                        <TextField
                          className="input-field"
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          size="small"
                          placeholder="Paste Screen Shot URL Here"
                          value={imageUploadLink}
                          onChange={(event) => {
                            setImageUploadLink(event.target.value);
                            if (event.target.value) {
                              setImageLinkError("");
                            }
                          }}
                        />
                        <Button
                          variant="outlined"
                          className="action-btn upload-btn"
                          disableRipple
                          onClick={addImage}
                        >
                          Upload
                        </Button>
                      </Box>
                    </Stack>
                    {imagelinkerror && (
                      <Typography
                        variant="body2"
                        className="error"
                        color="error"
                      >
                        {imagelinkerror}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Stack
                  className="document-list-wrapper over-flow-auto"
                  spacing={2}
                >
                  {selectedImages?.map((document) => {
                    const text = document?.linkName
                      ? document?.linkName
                      : document.linkText;
                    const displayText =
                      text.length > maxLength
                        ? text.slice(0, maxLength) + "..."
                        : text;
                    return (
                      <>
                        <Box className="document-upload-wrapper">
                          <Box key={document.linkId} className="document-type">
                            <Typography variant="body1" component="span">
                              <a
                                href={document.linkText}
                                target="_blank"
                                style={{ color: "var(--primary-text-color)" }}
                                rel="noopener noreferrer"
                                onClick={(e) =>
                                  handleLinkClick(e, document.linkText)
                                }
                              >
                                {displayText}
                              </a>
                            </Typography>
                          </Box>
                          <Box className="document-action action-btn-wrap">
                            <Button
                              variant="outlined"
                              className="action-btn dlt-btn"
                              startIcon={<RiDeleteBinLine />}
                              disableRipple
                              onClick={() =>
                                handleRemoveImage(
                                  document.linkId,
                                  "selectedImages"
                                )
                              }
                            ></Button>
                          </Box>
                        </Box>
                      </>
                    );
                  })}
                  {displayedImages &&
                    displayedImages.map((video, index) => {
                      const text = displayedImagefilenames[index]
                        ? displayedImagefilenames[index]
                        : displayedImages[index];
                      const displayText =
                        text.length > maxLength
                          ? text.slice(0, maxLength) + "..."
                          : text;
                      return (
                        <Box key={index} className="document-upload-wrapper">
                          <Box className="document-type">
                            <a
                              href={displayedImages[index]}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) =>
                                handleLinkClick(e, displayedImages[index])
                              }
                            >
                              <Typography variant="body1" component="span">
                                {displayText}
                              </Typography>
                            </a>
                          </Box>
                          <Box className="document-action action-btn-wrap">
                            <Button
                              variant="outlined"
                              className="action-btn dlt-btn"
                              startIcon={<RiDeleteBinLine />}
                              disableRipple
                              onClick={() =>
                                handleRemoveImage(index, "addedImages")
                              }
                            ></Button>
                          </Box>
                        </Box>
                      );
                    })}
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className="footer-wrapper">
        {leadStatus === "won" && !isStatusWon ? (
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn w-120"
            disableRipple
            onClick={() => setConvertCancel(true)}
          >
            Save
          </Button>
        ) : (
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn w-120"
            disableRipple
            onClick={submitData}
            disabled={isButtonDisabled}
          >
            Save
          </Button>
        )}
        <Button
          variant="contained"
          size="large"
          className="secondary-btn btn w-120"
          disableRipple
          onClick={() => {
            navigate("/leads", {
              state: {
                page: state.page,
                rowsPerPage: state.rowsPerPage,
                status: state.status,
                filterApplied: state.filterApplied,
                filterByIdApplied: state.filterByIdApplied,
                selectedKeywords: state.selectedKeywords,
                selectedTLandBDAs: state.selectedTLandBDAs,
                selectedTechnologies: state.selectedTechnologies,
                keywordSwitchVal: state.keywordSwitchVal,
                techStackSwitchVal: state.techStackSwitchVal,
                TLandBDAsSwitchVal:state.TLandBDAsSwitchVal,
                text: state.text,
                converted: state.converted,
                applyFilterId: state.applyFilterId,
                edit: state.edit,
              },
            });
            setSelectedTL("");
            setSelectedBDA("");
            setSelectedBDE("");
            setCompName("");
            setCompEmail("");
            setCompNumber("");
            setConPerson("");
            setConPersonEmail("");
            setConPersonNumber("");
            setCompanyName("");
            setCompanyPayload("");
            setCompanyEmail("");
            setCompanyNumber("");
            setCompaniesPerson("");
            setCompanyPayloadPerson("");
            setContactPerEmail("");
            setContactPersonPhoneNo("");
            setCompanies("");
          }}
        >
          Cancel
        </Button>
      </Box>
      <KeywordModal
        keywordModalOpen={keywordModalOpen}
        handleClose={closeKeyworddModal}
      />
      <TechnologyModal
        technologyModalOpen={technologyModalOpen}
        handleClose={closeTechnologyModal}
      />
      {showModal && (
        <AddKeywordModal
          invalidValue={invalidValue}
          onClose={() => setShowModal(false)} // Close the modal when needed
        />
      )}
      {addTechModal && (
        <AddTechnologyModal
          validTechName={validTechName}
          onClose={() => setAddTechModal(false)} // Close the modal when needed
        />
      )}

      {convertCancel && (
        <ConvertAlertModal
          leadStatus={leadStatus}
          setLeadStatus={setLeadStatus}
          convertCancel={convertCancel}
          setConvertCancel={setConvertCancel}
          iswonButtonDisabled={iswonButtonDisabled}
          onClose={() => setConvertCancel(false)}
          onSubmit={async (developmentStatus) => {
            if (iswonButtonDisabled) {
              return;
            }
            if (value === "new_opportunity" && !compName?.trim()) {
              // errorToast("Please fill in all the required fields marked with a star ( * )");
              setCompanyNameError("Please select company name");
              setConvertCancel(false);

              return;
            }
            if (value === "new_opportunity" && !conPerson.trim()) {
              // errorToast("Please fill in all the required fields marked with a star ( * )");
              setConPerErrorMsg("Please enter contact person name");
              setConvertCancel(false);
              return;
            }
            if (value === "existing_client" && !companies) {
              // errorToast("Please fill in all the required fields marked with a star ( * )");
              setExisCompError("Please select company name");
              setConvertCancel(false);
              return;
            }
            // if (value === "existing_client" && !companiesperson) {
            //   // errorToast("Please fill in all the required fields marked with a star ( * )");
            //   setExisPersonError("Please select contact person");
            //   setConvertCancel(false);
            //   return;
            // }
            if (!sourcePayload) {
              // errorToast("Please fill in all the required fields marked with a star ( * )");
              setLeadSrcErrorMsg("Please select lead source");
              setConvertCancel(false);
              return;
            }
            if (!projectName) {
              setProjectError("Please enter project name");
              setConvertCancel(false);
              return;
            }
            if (!leadStatus) {
              // errorToast("Please fill in all the required fields marked with a star ( * )");
              setLeadStatusErrorMsg("Please select lead status");
              setConvertCancel(false);
              return;
            }
            if (compEmailError === "Please enter a valid email address") {
              // errorToast("Please enter a valid email address");
              setConvertCancel(false);
              return;
            }
            if (conPerEmailError === "Please enter a valid email address") {
              // errorToast("Please enter a valid email address");
              setConvertCancel(false);
              return;
            }
            setIsWonButtonDisabled(true);
            const leadData = {
              client_type: value,
              company_name:
                value === "new_opportunity" ? compName : companyPayload,
              company_email:
                value === "new_opportunity" ? compEmail : companyEmail,
              company_phone:
                value === "new_opportunity" ? compNumber : companyNumber,
              contact_person:
                value === "new_opportunity" ? conPerson : companyPayloadPerson,
              contact_person_email:
                value === "new_opportunity" ? conPersonEmail : contactPerEmail,
              contact_person_phone:
                value === "new_opportunity"
                  ? conPersonNumber
                  : contactPersonPhoneNo,
              key_features: keyFeatures,
              key_integration: keyIntegrations,
              lead_source: sourcePayload,
              project_name: projectName,
              estimation_link: estimationLink,
              estimation_hours: estimationTime,
              feedback_note: feedbackNote,
              status: leadStatus,
              development_status: developmentStatus,
              // bda_id: selectedBDA,
              // bde_id: selectedBDE,
              // tl_id: selectedTL,
              ...(selectedBDA && { bda_id: selectedBDA }),
              bde_id: selectedBDE === "" ? 0 : selectedBDE,
              // ...(selectedBDE && { bde_id: selectedBDE }),
              tl_id: selectedTL === "" ? 0 : selectedTL,
              // ...(selectedTL && { tl_id: selectedTL }),
              client_id: value === "new_opportunity" ? "" : companies,
              client_contact_id:
                value === "new_opportunity" ? "" : companiesperson,
            };
            for (const key in leadData) {
              if (Object.hasOwnProperty.call(leadData, key)) {
                formData.append(key, leadData[key]);
              }
            }
            try {
              const response = await dispatch(
                editLeadByIdAdmin(leadId, formData)
              );

              if (response.success) {
                navigate("/leads", {
                state: {
                  page: state.page,
                  rowsPerPage: state.rowsPerPage,
                  status: state.status,
                  filterApplied: state.filterApplied,
                  filterByIdApplied: state.filterByIdApplied,
                  selectedKeywords: state.selectedKeywords,
                  selectedTLandBDAs: state.selectedTLandBDAs,
                  selectedTechnologies: state.selectedTechnologies,
                  keywordSwitchVal: state.keywordSwitchVal,
                  techStackSwitchVal: state.techStackSwitchVal,
                  TLandBDAsSwitchVal:state.TLandBDAsSwitchVal,
                  text: state.text,
                  converted: state.converted,
                  applyFilterId: state.applyFilterId,
                  edit: state.edit,
                },
              });
                successToast("Lead updated successfully!");
              } else {
                console.error("Error creating user:", response.error);
              }
            } catch (error) {
              console.error("Error creating lead:", error.message);
            } finally {
              // Enable the save button after the API call is completed
              setIsWonButtonDisabled(false);
            }
          }}
        />
      )}
      {showConfirmationModal && (
        <LeadLostConformationModal
          leadId={leadData?.id}
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal= {setShowConfirmationModal}
          setLeadStatus={setLeadStatus}
          leadStatus = {leadData?.status}
          setLeadStatusErrorMsg={setLeadStatusErrorMsg}
          activityDeleted={handleConfirmation}
        />
      )}
      ;
    </>
  );
};

export default LeadEdit;

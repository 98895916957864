import { Box, Fade, Modal, Typography } from "@mui/material";

import React from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const ConvertBidToLeadViewModal = ({
  convertCancel,
  setConvertCancel,
  convertBidId,
  handleConvertClose,
}) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();

  const cancelHandler = () => {
    setConvertCancel(false); // Close the modal when "Cancel" button is clicked
  };
  const submitHandler = () => {
    // dispatch(convertBid({ bidId: convertBidId }))
    //   .then((result) => {
    //     // Handle success
    //     const { success } = result;
    // console.log(success, "convert lead success");
    // if (success) {
    //   successToast("Bid converted to Lead successfully");
    //   dispatch(fetchBids({ page: "", limit: 10, query: "" }));
    //   handleConvertClose();
    navigate(`/leads/create?id=${convertBidId}`);
    //     }
    //   })
    //   .catch((error) => {
    //     // Handle error
    //     console.error("Error converting lead:", error);
    //     handleConvertClose();
    //     // You can dispatch an action or show an error message here.
    //   });
  };

  // console.log(leadPayload, "wonSubmit");
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={convertCancel}
        onClose={cancelHandler}
        closeAfterTransition
      >
        <Fade in={convertCancel}>
          <Box className="modal-wrapper">
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3">
                Do you want to convert this Bid to Lead?
              </Typography>

              {/* <Typography variant="body1" component="p">
                  This action can’t be undone.
              </Typography> */}
            </Box>
            <Box variant="div" component="div" className="body"></Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={submitHandler}
                >
                  Convert
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={cancelHandler}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ConvertBidToLeadViewModal;

const DATE_RANGE_TYPE = {
  today: "Today",
  thisWeek: "This week",
  lastWeek: "Last week",
  last7Days: "Last 7 Days",
  thisMonth: "This Month",
  lastMonth: "Last Month",
  last30Days: "Last 30 Days",
};

export const getDateRangeOptions = () => {
  const today = new Date();
  const lastMonthStartDate = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  );
  lastMonthStartDate.setHours(lastMonthStartDate.getHours() + 5);
  lastMonthStartDate.setMinutes(lastMonthStartDate.getMinutes() + 30);
  const lastMonthEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
  lastMonthEndDate.setHours(lastMonthEndDate.getHours() + 5);
  lastMonthEndDate.setMinutes(lastMonthEndDate.getMinutes() + 30);
  const thisMonthStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
  thisMonthStartDate.setHours(thisMonthStartDate.getHours() + 5);
  thisMonthStartDate.setMinutes(thisMonthStartDate.getMinutes() + 30);
  // console.log(thisMonthStartDate, "hhhh");
  const lastWeekStartDate = new Date(
    today.getTime() - (today.getDay() + 7) * 24 * 60 * 60 * 1000
  );

  const lastWeekEndDate = new Date(
    today.getTime() - (today.getDay() + 1) * 24 * 60 * 60 * 1000
  );
  const firstDayOfWeek = today.getDate() - today.getDay();
  const thisWeekStartDate = new Date(today.setDate(firstDayOfWeek));
  const thisWeekEndDate = new Date();

  const ranges = [
    {
      label: DATE_RANGE_TYPE.today,
      value: [new Date(), new Date()],
    },
    {
      label: DATE_RANGE_TYPE.thisWeek,
      value: [thisWeekStartDate, thisWeekEndDate],
    },
    {
      label: DATE_RANGE_TYPE.lastWeek,
      value: [lastWeekStartDate, lastWeekEndDate],
    },
    {
      label: DATE_RANGE_TYPE.last7Days,
      value: [
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        new Date(),
      ],
    },
    {
      label: DATE_RANGE_TYPE.thisMonth,
      value: [thisMonthStartDate, new Date()],
    },
    {
      label: DATE_RANGE_TYPE.lastMonth,
      value: [lastMonthStartDate, lastMonthEndDate],
    },
    {
      label: DATE_RANGE_TYPE.last30Days,
      value: [
        new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
        new Date(),
      ],
    },
  ];

  return ranges;
};

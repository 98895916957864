import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getHasPermission } from '../../utils/userPermissions';

const WithPermission = ({ permission: requiredPermission, element }) => {
  const { userPermissions } = useSelector((state) => ({
    userPermissions: state.permissions.rolePermissions,
  }));

  const hasAccess = useMemo(() => {
    const hasValidPermission = getHasPermission(
      requiredPermission,
      userPermissions
    );

    return hasValidPermission;
  }, [requiredPermission, userPermissions]);

  return hasAccess ? element : null;
};

export default WithPermission;

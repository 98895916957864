import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

// Async thunk action to delete the user by ID
export const deleteContactById = createAsyncThunk(
  "deleteContactById/deleteContact",
  async ({ contactId }, { dispatch }) => {
    // Assuming you have a token stored in the auth slice
    try {
      const response = await callApi({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/contact/${contactId}`,
      });
      // console.log(response.data);
      return { success: true, message: response.data.message };
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      throw new Error(error.response.data.message || "Failed to delete user.");
    }
  }
);

// Create the deleteLeadById slice with initial state
const deleteContactByIdSlice = createSlice({
  name: "deleteContactById",
  initialState: {
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteContactById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteContactById.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteContactById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default deleteContactByIdSlice.reducer;

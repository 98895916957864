import React, { useState, useEffect } from "react";

import {
  Box,
  Button,
  Typography,
  Drawer,
  Stack,
  TextField,
  Switch,
} from "@mui/material";
import Select from "react-select";
import {
  RiAddCircleLine,
  RiSearchLine,
  RiFilter2Line,
  RiCloseLine,
  RiCloseCircleLine,
} from "react-icons/ri";
import { IoCloseOutline } from "react-icons/io5";
import BidList from "./bidList";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { fetchBids } from "../../store/slices/bidSlice";
import WithPermission from "../../components/common/withPermission";
import { updateBids } from "../../utils/permissions";
import { DateRangePicker } from "rsuite";
import { fetchUsers } from "../../store/slices/userSlice";
import { fetchKeywords } from "../../store/slices/keywordSlice";
import { fetchTechnologies } from "../../store/slices/technologySlice";
import { fetchBidFilters } from "../../store/slices/bidFilterSlice";
import { getDateRangeOptions } from "../../utils/DateRanges";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const leadSourceOptions = [
  { value: "Upwork", label: "Upwork" },
  { value: "LinkedIn", label: "LinkedIn" },
  { value: "Guru", label: "Guru" },
  { value: "PPH", label: "PPH" },
  { value: "Fiverr", label: "Fiverr" },
  { value: "Clutch", label: "Clutch" },
  { value: "Website", label: "Website" },
  { value: "Email", label: "Email" },
  { value: "Skype", label: "Skype" },
  { value: "Recommendation", label: "Recommendation" },
  { value: "Freelancer.com", label: "Freelancer.com" },
];

const bidOptions = [
  { value: "Fulltime", label: "Full-Time" },
  { value: "Parttime", label: "Part-Time" },
];

const billingOptions = [
  { value: "Hourly", label: "Hourly" },
  { value: "Fixedcost", label: "Fixed-Cost" },
];

const Bids = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
      page: 1,
      rowsPerPage: 10,
      filteredPage: 1,
      filterApplied: false,
      bd: "",
      keywords: "",
      technologies: "",
      keywordPayload: "",
      technologyPayload: "",
      platform: "",
      billingtype: "",
      bidtype: "",
      bidDate: "",
      startDate: "",
      endDate: "",
      text: "",
      converted: "",
      keywordSwitchVal: true,
      techStackSwitchVal: true,
    };
  }

  const [keywordSwitchValue, setKeywordSwitchValue] = useState(
    state.keywordSwitchVal
  );
  const [techStackSwitchValue, settechStackSwitchValue] = useState(
    state.techStackSwitchVal
  );
  const [currentPage, setCurrentPage] = useState(state.page);
  const [rowsPerPage, setRowsPerPage] = useState(state.rowsPerPage);
  const [searchUser, setsearchUser] = useState("");
  const [open, setOpen] = useState(false);
  // const [value, setValue] = useState(0);
  const [selectedKeywords, setSelectedKeywords] = useState(state.keywords);
  const [keywordPayload, setKeywordPayload] = useState(state.keywordPayload);
  const [selectedTechnologies, setSelectedTechnologies] = useState(
    state.technologies
  );
  const [technologyPayload, setTechnologyPayload] = useState(
    state.technologyPayload
  );
  const [bidDate, setBidDate] = useState(state.bidDate);
  const keywords = useSelector((state) => state.keywords.keywords);
  const technologies = useSelector((state) => state.technologies.technologies);
  const users = useSelector((state) => state.users.users);
  const [platform, setPlatform] = useState(state.platform);
  const [bidtype, setBidType] = useState(state.bidtype);
  const [billingtype, setBillingType] = useState(state.billingtype);
  const [bd, setBd] = useState(state.bd);
  const BDA = users?.filteredBdaAndBde;
  const filters = useSelector((state) => state.bidfilters.bidfilters);
  const [startDate, setStartDate] = useState(state.startDate);
  const [endDate, setEndDate] = useState(state.endDate);
  const [filterApplied, setFilterApplied] = useState(state.filterApplied);
  const [filteredCurrentPage, setFilteredCurrentPage] = useState(
    state.filteredPage
  );
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [searchText, setSearchText] = useState(state.text);
  const [error, setError] = useState("");
  const ranges = getDateRangeOptions();
  const [isChecked, setIsChecked] = useState(state.converted);

  const handleDrawer = () => {
    setOpen(!open);
    setError("");
    if (!filterApplied) {
      setBd("");
      setPlatform("");
      setBillingType([]);
      setStartDate([]);
      setEndDate("");
      setSelectedKeywords([]);
      setKeywordPayload([]);
      setTechnologyPayload([]);
      setSelectedTechnologies([]);
      setBidType("");
      setBidDate("");
      setSearchText("");
    }
  };

  const keywordsData = keywords?.allKeywords?.map((keyword) => ({
    value: keyword.id,
    label: capitalizeWords(keyword.name),
  }));
  const handleKeywordMatch = (event) => {
    setKeywordSwitchValue(event.target.checked);
  }; 
  const handleTechStackMatch = (event) => {
    settechStackSwitchValue(event.target.checked);
  };
  const handleKeywordSelect = (selectedOptions) => {
    if (selectedOptions) {
      setError("");
    }
    setSelectedKeywords(selectedOptions);

    const selectedIds = selectedOptions.map((option) => option.value).join(",");

    setKeywordPayload(selectedIds);
  };

  const handleTechnologySelect = (selectedOptions) => {
    if (selectedOptions) {
      setError("");
    }
    setSelectedTechnologies(selectedOptions);

    const selectedIds = selectedOptions.map((option) => option.value).join(",");

    setTechnologyPayload(selectedIds);
  };

  const technologiesData = technologies?.allTechnologies?.map((technology) => ({
    value: technology.id,
    label: technology.name,
  }));

  function capitalizeWords(input) {
    return input?.replace(/\w+/g, function (word) {
      return word?.charAt(0).toUpperCase() + word.slice(1);
    });
  }

  const BDAOptions = BDA?.map((tl) => ({
    value: tl.id,
    label: `${capitalizeWords(tl.first_name)} ${capitalizeWords(
      tl.last_name
    )} (${tl.role_name})`,
  }));

  const handleBDASelect = (selectedOption) => {
    if (selectedOption) {
      setError("");
    }
    setBd(selectedOption);
  };

  const handlePlatform = (selectedOption) => {
    if (selectedOption) {
      setError("");
    }
    setPlatform(selectedOption);
  };

  const handleBid = (selectedOption) => {
    if (selectedOption) {
      setError("");
    }
    setBidType(selectedOption);
  };

  const handleBilling = (selectedOption) => {
    if (selectedOption) {
      setError("");
    }
    setBillingType(selectedOption);
  };

  const handleBidDateChange = (date) => {
    if (date) {
      setError("");
    }

    setBidDate(date);
    if (Array.isArray(date) && date.length >= 2) {
      const formattedDates = date.map(
        (date) => date.toISOString().split("T")[0]
      );
      setStartDate(formattedDates[0]);
      setEndDate(formattedDates[1]);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterPageChange = (newPage) => {
    setFilteredCurrentPage(newPage);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClearFilter = () => {
    setSearchText("");
    setBd("");
    setStartDate([]);
    setEndDate("");
    setBidDate("");
    setSelectedKeywords([]);
    setKeywordPayload([]);
    setTechnologyPayload([]);
    setSelectedTechnologies([]);
    setBillingType("");
    setBidType("");
    setPlatform("");
    setSearchText("");
  };

  const handleApplyFilter = () => {
    setsearchUser("");
    setFilteredCurrentPage(1);
    setCurrentPage(1);
    if (
      (bidDate === null &&
        !bd &&
        !platform &&
        !bidtype &&
        billingtype.length === 0 &&
        technologyPayload.length === 0 &&
        keywordPayload.length === 0 &&
        !searchText &&
        isChecked === false) ||
      (startDate.length === 0 &&
        endDate.length === 0 &&
        !bd &&
        !platform &&
        !bidtype &&
        billingtype.length === 0 &&
        technologyPayload.length === 0 &&
        keywordPayload.length === 0 &&
        !searchText &&
        isChecked === false)
    ) {
      setError("Please select any one field");
      return;
    }

    dispatch(
      fetchBidFilters({
        search_text: searchText,
        startdate: startDate,
        enddate: endDate,
        bd_id: bd,
        platform: platform,
        billing_type: billingtype,
        bid_type: bidtype,
        keyword_id: keywordPayload,
        keywordSwitchValue: keywordSwitchValue ? "and" : "or",
        techStackSwitchValue: techStackSwitchValue ? "and" : "or",
        technology_id: technologyPayload,
        page: filteredCurrentPage,
        limit: +rowsPerPage,
        converted: isChecked === true ? 1 : 0,
      })
    )
      .then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          setFilterApplied(true);
          setOpen(false);
        } else {
          console.error("Error creating user:", response.error);
        }
      })
      .catch((error) => {
        console.error("Error creating user:", error.message);
      });
  };

  // useEffect(() => {
  //   dispatch(
  //     fetchBids({
  //       page: currentPage,
  //       limit: toatlBidsPerPage,
  //       query: searchUser,
  //     })
  //   )
  //     .unwrap()
  //     .then((data) => {})
  //     .catch((error) => {});
  // }, [dispatch, currentPage, searchUser]);

  // useEffect(() => {
  //   if (currentPage > 1) {
  //     setCurrentPage(1);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [searchUser]);

  useEffect(() => {
    dispatch(fetchUsers({ page: "", query: "", limit: "" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchKeywords({ query: "" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchTechnologies({ query: "" }));
  }, [dispatch]);

  useEffect(() => {
    setFilteredProjects(filters?.filteredBids);
  }, [filters]);

  useEffect(() => {
    if (filterApplied) {
      dispatch(
        fetchBidFilters({
          page: filteredCurrentPage,
          search_text: searchText,
          startdate: startDate,
          enddate: endDate,
          bd_id: bd,
          platform: platform,
          billing_type: billingtype,
          bid_type: bidtype,
          keyword_id: keywordPayload,
          keywordSwitchValue: keywordSwitchValue ? "and" : "or",
          techStackSwitchValue: techStackSwitchValue ? "and" : "or",
          technology_id: technologyPayload,
          converted: isChecked === true ? 1 : 0,
          limit: +rowsPerPage,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filteredCurrentPage, rowsPerPage]);

  const clearSearchHandler = () => {
    setsearchUser("");
    localStorage.setItem("searchBidsQuery", JSON.stringify(""));
  };

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
    if (isChecked === false) {
      setError("");
    }
  };

  useEffect(() => {
    // Load search text from localStorage on component mount
    const savedSearchText = localStorage.getItem("searchBidsQuery");
    if (savedSearchText) {
      setsearchUser(JSON.parse(savedSearchText));
    }
    localStorage.setItem("searchClientsQuery", JSON.stringify(""));
    localStorage.setItem("searchLeadsQuery", JSON.stringify(""));
    localStorage.setItem("searchProjectsQuery", JSON.stringify(""));
    localStorage.setItem("searchUsersQuery", JSON.stringify(""));
  }, []);

  const storeBidsSearchInLocalStorage = (event) => {
    // Store search text in localStorage as a JSON string
    localStorage.setItem("searchBidsQuery", JSON.stringify(searchUser));
  };

  const handleSearchChange = (e) => {
    setsearchUser(e.target.value);
    setFilterApplied(false);
  };

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 55,
    height: 30,
    padding: 7,
    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="25" viewBox="0 0 20 20"><text x="3.5" y="15" fill="${encodeURIComponent(
            "#fff"
          )}" font-size="12"  font-family="Arial, Helvetica, sans-serif">All</text></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 26,
      height: 26,
      "&::before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="25" viewBox="0 0 20 20"><text x="0" y="15" fill="${encodeURIComponent(
          "#fff"
        )}" font-size="12" font-family="Arial, Helvetica, sans-serif">Any</text></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));
  return (
    <>
      <Box className="content-header project-list-header bids-module-wrapper">
        <Box className="filter-apply-wrapper">
          <Typography variant="h2" component="h2">
            Bids
          </Typography>
          {filterApplied && (
            <>
              <Button
                variant="contained"
                size="large"
                className="outline-btn btn add-btn"
                endIcon={<RiCloseLine />}
                disableRipple
                onClick={() => {
                  setFilteredCurrentPage(1);
                  setCurrentPage(1);
                  setFilterApplied(false);
                  setIsChecked(false);
                  setKeywordSwitchValue(true);
                  settechStackSwitchValue(true);
                  setError("");
                  dispatch(
                    fetchBids({
                      page: currentPage,
                      limit: +rowsPerPage,
                      query: searchUser,
                    })
                  );
                }}
              >
                Remove filter
              </Button>
            </>
          )}
        </Box>
        <Box variant="div" component="div" className="content-header-right">
          <Box variant="div" component="div" className="search-bar">
            <RiSearchLine />
            <TextField
              fullWidth
              id="outlined-basic"
              label=""
              variant="outlined"
              size="small"
              placeholder="Search here"
              value={searchUser}
              onChange={handleSearchChange}
              onBlur={storeBidsSearchInLocalStorage}
            />
            {searchUser && (
              <RiCloseCircleLine
                className="cross-line"
                onClick={clearSearchHandler}
              />
            )}
          </Box>
          <WithPermission
            permission={updateBids}
            element={
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn add-btn"
                endIcon={<RiAddCircleLine />}
                disableRipple
                onClick={() => navigate("/bids/create")}
              >
                Create Bid
              </Button>
            }
          />
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn"
            endIcon={<RiFilter2Line />}
            disableRipple
            onClick={handleDrawer}
          >
            {/* <Typography
              variant="body1"
              component="span"
              className="filter-counte"
            >
              3
            </Typography> */}
            Filters
          </Button>
        </Box>
      </Box>

      <Box className="content-layout">
        <BidList
          onPageChange={handlePageChange}
          setCurrentPage={setCurrentPage}
          filterCurrentPage={filteredCurrentPage}
          searchUser={searchUser}
          currentpage={currentPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          filters={filters}
          filterApplied={filterApplied}
          keywordSwitchValue={keywordSwitchValue}
          techStackSwitchValue={techStackSwitchValue}
          onFilterPageChange={handleFilterPageChange}
          filteredProjects={filteredProjects}
          endDate={endDate}
          startDate={startDate}
          bd={bd}
          platform={platform}
          keywordPayload={keywordPayload}
          technologyPayload={technologyPayload}
          billingType={billingtype}
          bidType={bidtype}
          searchText={searchText}
          isChecked={isChecked}
          selectedKeywords={selectedKeywords}
          selectedTechnologies={selectedTechnologies}
          bidDate={bidDate}
        />
      </Box>
      <Drawer
        className="side-drawer bids-drawer"
        anchor="right"
        open={open}
        onClose={() => handleClose()}
      >
        <Box className="sidebar-tab-wrapper" style={{ maxWidth: "500px" }}>
          <Box className="sidebar-tab-wrapper">
            <Box
              className="close-btn"
              onClick={() => handleClose()}
              style={{ top: "11px" }}
            >
              <IoCloseOutline />
            </Box>
            <Box className="sidebar-tab">
              {/* <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="tab-label-btn"
                  label="Filters"
                  {...a11yProps(0)}
                />
                <Tab
                  className="tab-label-btn"
                  label="My Filters"
                  {...a11yProps(1)}
                />
                <Tab
                  className="tab-label-btn"
                  label="Shared Filters"
                  {...a11yProps(2)}
                />
              </Tabs> */}
              <Typography
                variant="h4"
                component="h4"
                style={{
                  paddingBottom: "10px",
                  paddingTop: "10px",
                }}
              >
                Filters
              </Typography>
            </Box>
          </Box>

          {/* <CustomTabPanel
            value={value}
            index={0}
            className="filter-content-wrapper"
          > */}
          <Stack spacing={2} sx={{ padding: "24px" }}>
            <Box variant="div" component="div" className="content-header-right">
              <FormControlLabel
                control={
                  <Switch checked={isChecked} onChange={handleSwitchChange} />
                }
                label="Show Successful Bids"
              />
            </Box>
            <Box>
              <Typography variant="body1" component="label">
                Search Text
              </Typography>

              <TextField
                className="input-field"
                fullWidth
                id="outlined-basic"
                label=""
                variant="outlined"
                size="small"
                placeholder="Search Here"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  if (e.target.value) {
                    setError("");
                  }
                }}
              />
              {error && (
                <Typography variant="body1" component="p" className="error">
                  {error}
                </Typography>
              )}
            </Box>
            <Box>
              {" "}
              <Typography variant="body1" component="label">
                Bid Date
              </Typography>
              <DateRangePicker
                showOneCalendar
                style={{ width: "100%" }}
                placeholder="Select Bid Date"
                className="modal-date-picker drawer-calendar"
                format="dd-MM-yyyy"
                value={bidDate}
                ranges={ranges}
                onKeyDown={(event) => {
                  event.preventDefault();
                }}
                onChange={handleBidDateChange}
              />
              {error && (
                <Typography variant="body1" component="p" className="error">
                  {error}
                </Typography>
              )}
            </Box>
            <Box>
              {" "}
              <Typography variant="body1" component="label">
                BD
              </Typography>{" "}
              <Select
                name="colors"
                className="muilt-select-field"
                classNamePrefix="select"
                placeholder="Select BD"
                options={BDAOptions}
                value={
                  BDAOptions?.find((option) => option?.value === bd) || null
                }
                onChange={(selectedOption) =>
                  handleBDASelect(selectedOption?.value)
                }
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
              />
              {error && (
                <Typography variant="body1" component="p" className="error">
                  {error}
                </Typography>
              )}
            </Box>
            <Box>
              {" "}
              <Typography variant="body1" component="label">
                Platform
              </Typography>{" "}
              <Select
                name="colors"
                className="muilt-select-field"
                classNamePrefix="select"
                placeholder="Select Platform"
                options={leadSourceOptions}
                value={
                  leadSourceOptions?.find(
                    (option) => option?.value === platform
                  ) || null
                }
                onChange={(selectedOption) =>
                  handlePlatform(selectedOption?.value)
                }
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
              />
              {error && (
                <Typography variant="body1" component="p" className="error">
                  {error}
                </Typography>
              )}
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1" component="label">
                  Keywords
                </Typography>
                {/* <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: "5px",
                      "& .MuiSvgIcon-root": {
                        fontSize: 17,
                      },
                    }}
                  >
                    <FormControlLabel
                      value="and"
                      control={<Radio />}
                      label="AND"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "13px",
                          fontWeight: 600,
                          marginTop: "2px",
                          marginLeft:'-5px'
                        },
                      }}
                    />
                    <FormControlLabel
                      value="or"
                      control={<Radio />}
                      label="OR"
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "13px",
                          fontWeight: 600,
                          marginTop: "2px",
                          marginLeft:'-5px'
                        },
                      }}
                    />
                  </RadioGroup> */}
                <FormControlLabel
                  control={
                    <MaterialUISwitch
                      sx={{ m: 0.5, marginLeft: "-1px" }}
                      defaultChecked
                    />
                  }
                  label="Match :"
                  labelPlacement="start"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "13px",
                      fontWeight: 600,
                    },
                  }} // Adjust the font size here
                  checked={keywordSwitchValue}
                  onChange={handleKeywordMatch}
                />
              </Box>
              <Select
                isMulti
                name="colors"
                className="muilt-select-field"
                classNamePrefix="select"
                placeholder="Select Keywords"
                options={keywordsData}
                onChange={handleKeywordSelect}
                value={selectedKeywords}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
              />
              {error && (
                <Typography variant="body1" component="p" className="error">
                  {error}
                </Typography>
              )}
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1" component="label">
                  Technology Stack
                </Typography>
                <FormControlLabel
                  control={
                    <MaterialUISwitch
                      sx={{ m: 0.5, marginLeft: "-1px" }}
                      defaultChecked
                    />
                  }
                  label="Match :"
                  labelPlacement="start"
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "13px",
                      fontWeight: 600,
                    },
                  }} // Adjust the font size here
                  checked={techStackSwitchValue}
                  onChange={handleTechStackMatch}
                />
              </Box>
              <Select
                isMulti
                name="colors"
                className="muilt-select-field"
                classNamePrefix="select"
                placeholder="Select Technologies"
                options={technologiesData}
                onChange={handleTechnologySelect}
                value={selectedTechnologies}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
              />
              {error && (
                <Typography variant="body1" component="p" className="error">
                  {error}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography variant="body1" component="label">
                Billing Type
              </Typography>{" "}
              <Select
                name="colors"
                className="muilt-select-field"
                classNamePrefix="select"
                placeholder="Select Billing Type"
                options={billingOptions}
                value={
                  billingOptions?.find(
                    (option) => option?.value === billingtype
                  ) || null
                }
                onChange={(selectedOption) =>
                  handleBilling(selectedOption?.value)
                }
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
              />
              {error && (
                <Typography variant="body1" component="p" className="error">
                  {error}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography variant="body1" component="label">
                Bid Type
              </Typography>{" "}
              <Select
                name="colors"
                className="muilt-select-field"
                classNamePrefix="select"
                placeholder="Select Bid Type"
                options={bidOptions}
                value={
                  bidOptions?.find((option) => option?.value === bidtype) ||
                  null
                }
                onChange={(selectedOption) => handleBid(selectedOption?.value)}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 10,
                  colors: {
                    ...theme.colors,
                    primary25: "var(--secondary-text-color)",
                    primary: "#8CC714",
                  },
                })}
              />
              {error && (
                <Typography variant="body1" component="p" className="error">
                  {error}
                </Typography>
              )}
            </Box>

            <Box
              className="filter-button-wrapper"
              // style={{ justifyContent: "center" }}
            >
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn green-btn"
                disableRipple
                onClick={handleApplyFilter}
              >
                Apply Filter
              </Button>

              {/* <Button
                variant="contained"
                size="large"
                className="primary-btn btn"
                disableRipple
              >
                Save
              </Button> */}
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn"
                disableRipple
                onClick={() => {
                  setOpen(false);
                  setError("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="large"
                className="secondary-btn btn full-btn"
                disableRipple
                // style={{ width: "100%" }}
                onClick={() => handleClearFilter()}
              >
                Clear Filter
              </Button>
            </Box>
            {/* <Box className="filter-button-wrapper">
              <Button
                variant="contained"
                size="large"
                className="secondary-btn btn"
                disableRipple
                style={{ width: "100%" }}
                onClick={() => handleClearFilter()}
              >
                Clear Filter
              </Button>
            </Box> */}
          </Stack>
          {/* </CustomTabPanel> */}
          {/* <CustomTabPanel
            value={value}
            index={1}
            className="filter-content-wrapper"
          ></CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={2}
            className="filter-content-wrapper"
          ></CustomTabPanel> */}
        </Box>
      </Drawer>
    </>
  );
};

export default Bids;

import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Skeleton,
  Checkbox,
  Pagination,
} from "@mui/material";
import Select from "react-select";
import {
  RiAddCircleLine,
  RiArrowLeftLine,
  RiDeleteBinLine,
  RiPencilLine,
  RiCloseCircleLine,
} from "react-icons/ri";
import { useNavigate, useLocation } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { successToast } from "../../responses/successToast";
// import { errorToast } from "../../responses/errorToast.js";
import { useParams } from "react-router-dom";
import { fetchClientsById } from "../../store/slices/clientbyIdSlice";
import { fetchContacts } from "../../store/slices/contactSlice";
import { editClientByIdAdmin } from "../../store/slices/clientEditBySlice.js";
import ContactDeleteModal from "../../components/common/deleteContactModal";
import "../../styles/client.scss";
import ContactDrawer from "../../components/common/contactDrawer";
import EditcontactDrawer from "../../components/common/editContactDrawer";
import { editContactByIdAdmin } from "../../store/slices/editContactByIdSlice";
import { fetchContactById } from "../../store/slices/contactByIdSlice";
import { maskEmail, maskPhoneNumber } from "../../utils/masking.js";
import { selectIsAdmin } from "../../constants/selectors.js";

const validateEmail = (email) => {
  const emailPattern =
    /^[a-zA-Z0-9](?!.*\.\.)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]*@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,63})+$/;
  return emailPattern.test(email);
};

const ClientEdit = () => {
  const getUserRole = useSelector(selectIsAdmin);
  const [role, setRole] = useState(getUserRole);
  const [rolecompNo, setRoleCompNo] = useState(getUserRole);
  // console.log(role, "role");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { state } = location;
  if (state === null) {
    state = {
     page: 1,
      status: 0,
      rowsPerPage:10
    };
  }
  // console.log(state)
  const [compName, setCompName] = useState("");
  const [compEmail, setCompEmail] = useState("");
  const [compNo, setCompNo] = useState("");
  const [website, setWebsite] = useState("");
  const [skypeid, setSkypeid] = useState("");
  const [compNameError, setCompNameError] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [State, setState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [compEmailError, setCompEmailError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  // drawer for add contact
  const [open, setOpen] = useState(false);
  //
  // CLIENT DELETE
  const [contactDelete, setContactDelete] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState(null);
  const handleDeleteOpen = (clientId) => {
    setSelectedContactId(clientId);
    setContactDelete(true);
  };
  // console.log(setSelectedContactId, "id of the contact to be deleteed");
  const handleDeleteClose = () => setContactDelete(false);
  // CLIENT DELETE COMPLETE

  // drawer for update contact
  const [editContact /*setEditContact*/] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [contId, setContId] = useState(null);
  const { clientId } = useParams();
  const handleEditOpen = (contactId) => {
    // console.log(contactId, "contact id from view for update");
    setContId(contactId);
    dispatch(fetchContactById({ contId: contactId }));
    setEditOpen(true);
  };
  const [selectedPrimaryContact, setSelectedPrimaryContact] = useState(null);

  const handleCheckboxClick = (contactId) => {
    // Find the contact with the given ID from the 'contacts.contacts' array
    const contact = contacts?.contacts.find((c) => c.id === contactId);
    // console.log(contact, "coontact");
    // If the clicked checkbox is already the primary contact, do nothing
    if (contact && contact.is_primary) {
      return;
    }

    const newIsPrimaryValue = contact.is_primary ? 0 : 1;
    // console.log(newIsPrimaryValue, "true/false for selected ");
    setSelectedPrimaryContact(contactId);

    const { contact_person_email, contact_person_name, contact_person_phone } =
      contact;

    const contactData = {
      contact_person_name: contact_person_name,
      contact_person_email: contact_person_email,
      contact_person_phone: contact_person_phone,
      is_primary: newIsPrimaryValue,
    };
    // console.log(contactData, "contac data");
    dispatch(editContactByIdAdmin(contactId, contactData))
      .then((response) => {
        if (response.success) {
          setEditOpen(false);
          successToast("Primary contact person updated successfully!");
          dispatch(
            fetchContacts({
              clientId: clientId,
              page: currentPage,
              limit: toatlClientsPerPage,
            })
          );
        } else {
          console.error("Error updating contact:", response.error);
        }
      })
      .catch((error) => {
        console.error("Error updating contact:", error.message);
      });
  };
  //

  const [currentPage, setCurrentPage] = useState(1);
  const toatlClientsPerPage = 10;
  const clients = useSelector((state) => state.clientsById.clients?.[0]);
  // console.log(clients, "clients");
  const contacts = useSelector((state) => state.contacts.contacts);
  function capitalizeWords(input) {
    return input?.replace(/\w+/g, function (word) {
      return word?.charAt(0).toUpperCase() + word.slice(1);
    });
  }

  const [saveButtonClickCount, setSaveButtonClickCount] = useState(0);
  const compEmailRef = useRef(null);
  const companyNameRef = useRef(null);

  useEffect(() => {
    if (compNameError && companyNameRef.current) {
      companyNameRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [companyNameRef, compNameError, saveButtonClickCount]);

  useEffect(() => {
    if (saveButtonClickCount === 1) {
      if (compEmailError && compEmailRef.current) {
        compEmailRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [compEmailRef, compEmailError, saveButtonClickCount]);

  const submitHandler = async () => {
    setSaveButtonClickCount((prevCount) => prevCount + 1);
    if (isButtonDisabled) {
      return;
    }
    if (!compName.trim()) {
      // errorToast("Please fill in all the required fields marked with a star ( * )");
      setCompNameError("Please enter company name");
      return;
    }

    if (compEmailError === "Please enter a valid email address") {
      // errorToast("Please enter a valid email address");
      return;
    }
    setIsButtonDisabled(true);
    const clientData = {
      company_name: compName,
      company_email: getUserRole
        ? compEmail
        : compEmail || clients?.company_email,
      company_phone: getUserRole ? compNo : compNo || clients?.company_phone,
      website: website,
      skype_id: skypeid,
      country: selectedCountry?.name?.common,
      address: address,
      city: city,
      state: State,
      zip_code: zipcode,
    };
    try {
      const response = await dispatch(
        editClientByIdAdmin(clientId, clientData)
      );

      if (response.success) {
        // console.log(response, "api response");
        navigate("/clients");
        successToast("Client updated successfully!");
      } else {
        console.error("Error creating client:", response.error);
      }
    } catch (error) {
      console.error("Error creating lead:", error.message);
    } finally {
      // Enable the save button after the API call is completed
      setIsButtonDisabled(false);
    }
  };

  useEffect(() => {
    // Define your API endpoint to fetch the list of countries
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        // Update the state with the fetched data and sort it alphabetically
        const sortedCountries = data.sort((a, b) =>
          a.name.common.localeCompare(b.name.common)
        );
        setCountries(sortedCountries);
        // Find the matching country object and set it as selectedCountry
        if (clients?.country === null || clients?.country === "") {
          setSelectedCountry("");
        }
        if (clients?.country) {
          const matchingCountry = sortedCountries.find(
            (countryObj) => countryObj.name.common === clients.country
          );
          setSelectedCountry(matchingCountry);
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [clients]);

  useEffect(() => {
    dispatch(fetchClientsById({ clientId }));
  }, [dispatch, clientId]);

  useEffect(() => {
    setLoading(true);
    dispatch(
      fetchContacts({
        clientId: clientId,
        page: currentPage,
        limit: toatlClientsPerPage,
      })
    );
    setLoading(false);
  }, [dispatch, clientId, currentPage]);

  useEffect(() => {
    // const country = clients?.country;
    // console.log(country, "country");
    if (clients) {
      setCompName(clients?.company_name);
      if (clients?.company_email === "" || clients?.company_email === null) {
        setRole(true);
        setCompEmail("");
      } else {
        // setRole(false);
        setCompEmail(clients?.company_email);
      }
      if (clients?.company_phone === "" || clients?.company_phone === null) {
        setRoleCompNo(true);
        setCompNo("");
      } else {
        // setRoleCompNo(false);
        setCompNo(clients?.company_phone);
      }

      if (clients?.website === null || clients?.website === "") {
        setWebsite("");
      } else {
        setWebsite(clients?.website);
      }
      if (clients?.skype_id === null || clients?.skype_id === "") {
        setSkypeid("");
      } else {
        setSkypeid(clients?.skype_id);
      }
      if (clients?.city === null || clients?.city === "") {
        setCity("");
      } else {
        setCity(clients?.city);
      }
      if (clients?.address === null || clients?.address === "") {
        setAddress("");
      } else {
        setAddress(clients?.address);
      }
      if (clients?.state === null || clients?.state === "") {
        setState("");
      } else {
        setState(clients?.state);
      }
      if (clients?.zip_code === null || clients?.zip_code === "") {
        setZipcode("");
      } else {
        setZipcode(clients?.zip_code);
      }
    }
  }, [clients]);

  // console.log(selectedCountry, "//////selected country");
  const handlePageChange = (event, newPage) => {
    //onPageChange(newPage);
    setCurrentPage(newPage);

    // console.log(newPage);
  };
  // console.log(currentPage);

  const clearcompEmail = () => {
    setCompEmail("");
  };

  return (
    <>
      <Box className="content-header edit-client-wrapper">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() =>
              navigate("/clients", {
                state: { page: state !== null ? state.page : 1,rowsPerPage:state.rowsPerPage },
              })
            }
          ></Button>
          Edit Client
        </Typography>
        <Button
          variant="contained"
          size="large"
          className="primary-btn btn add-btn"
          disableRipple
          endIcon={<RiAddCircleLine />}
          onClick={() => setOpen(!open)}
        >
          Add Contact
        </Button>
      </Box>

      <Box className="content-wrapper">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography
              variant="h3"
              component="h3"
              className="card-title"
              ref={companyNameRef}
            />
            <Typography
              variant="h3"
              component="h3"
              className="card-title"
              ref={compEmailRef}
            />
            <Box className="card-wrapper">
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Client Detail
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Name
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Company Name"
                    value={compName}
                    //inputRef={companyNameRef}
                    onChange={(event) => {
                      setCompName(event.target.value);
                      if (event.target.value) {
                        setCompNameError("");
                      }
                    }}
                  />
                  {compNameError && (
                    <Typography variant="body1" component="p" className="error">
                      {compNameError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Email
                  </Typography>
                  <Box
                    variant="div"
                    component="div"
                    className="cross-line-icon"
                  >
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Company Email"
                      value={role ? compEmail : maskEmail(compEmail)}
                      // onChange={(event) => {
                      //   setCompEmail(event.target.value);
                      // }}
                      onChange={(event) => {
                        const email = event.target.value;
                        setCompEmail(email);
                        if (validateEmail(email) || !email) {
                          setCompEmailError(""); // Clear the error message if the email is valid.
                        } else {
                          setCompEmailError(
                            "Please enter a valid email address"
                          );
                        }
                      }}
                      InputProps={{
                        readOnly: !role && compEmail,
                      }}
                    />
                    {!role && (
                      <RiCloseCircleLine
                        className="cross-line"
                        onClick={() => {
                          clearcompEmail();
                          setRole(true); // Set role to true to make the field editable
                        }}
                      />
                    )}
                  </Box>
                  {compEmailError && (
                    <Typography variant="body2" className="error" color="error">
                      {compEmailError}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Phone No.
                  </Typography>
                  <Box
                    variant="div"
                    component="div"
                    className="cross-line-icon"
                  >
                    <TextField
                      className="input-field"
                      fullWidth
                      id="outlined-basic"
                      label=""
                      variant="outlined"
                      size="small"
                      placeholder="Enter Company Phone No."
                      // type="number"
                      value={rolecompNo ? compNo : maskPhoneNumber(compNo)}
                      onChange={(event) => {
                        // Remove non-numeric characters
                        setCompNo(event.target.value);
                      }}
                      InputProps={{
                        readOnly: !rolecompNo && compNo,
                      }}
                    />
                    {!rolecompNo && (
                      <RiCloseCircleLine
                        className="cross-line"
                        onClick={() => {
                          setCompNo("");
                          setRoleCompNo(true); // Set role to true to make the field editable
                        }}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Website
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Website"
                    value={website}
                    onChange={(event) => {
                      setWebsite(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Skype Id
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Skype Id"
                    value={skypeid}
                    onChange={(event) => {
                      setSkypeid(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box className="card-wrapper">
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Address
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Address Line 1
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter address"
                    value={address}
                    onChange={(event) => {
                      setAddress(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    City
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter City"
                    value={city}
                    onChange={(event) => {
                      setCity(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    State
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter State"
                    value={State}
                    onChange={(event) => {
                      setState(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Country
                  </Typography>
                  <Select
                    name="colors"
                    options={countries}
                    value={selectedCountry}
                    onChange={(selectedOption) => {
                      setSelectedCountry(selectedOption);
                      // console.log("Selected Country:", selectedOption);
                    }}
                    getOptionValue={(option) => option.name.common} // Use the country name as the value
                    getOptionLabel={(option) => option.name.common}
                    className="muilt-select-field"
                    classNamePrefix="select"
                    placeholder="Select Country"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 10,
                      colors: {
                        ...theme.colors,
                        primary25: "var(--secondary-text-color)",
                        primary: "#8CC714",
                      },
                    })}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Zip Code
                  </Typography>
                  <TextField
                    className="input-field"
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    size="small"
                    placeholder="Enter Zip Code"
                    value={zipcode}
                    onChange={(event) => {
                      setZipcode(event.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box className="card-wrapper p-0">
                <Typography variant="h3" component="h3" className="card-title title-space">
                  Contacts
                </Typography>
              {loading ? (
                <TableContainer className="table-listing" sx={{overflow:"auto"}}>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="table"
                    className="listing-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Set as Primary</TableCell>
                        <TableCell align="left">Contact Person</TableCell>
                        <TableCell align="left">
                          Contact Person's Phone No.
                        </TableCell>
                        <TableCell align="left">
                          Contact Person's Email
                        </TableCell>
                        <TableCell align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.from({ length: 6 }).map((_, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Skeleton variant="text" width="100%" height={40} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width="100%" height={40} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width="100%" height={40} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width="100%" height={40} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width="100%" height={40} />
                          </TableCell>
                          <TableCell>
                            <Skeleton variant="text" width="100%" height={40} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : contacts?.contacts?.length > 0 ? (
                <TableContainer className="table-listing" sx={{overflow:"auto"}}>
                  <Table
                    sx={{ minWidth: 650 }}
                    aria-label="table"
                    className="listing-table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Set as Primary</TableCell>
                        <TableCell align="left">Contact Person</TableCell>
                        <TableCell align="left">
                          Contact Person's Phone No.
                        </TableCell>
                        <TableCell align="left">
                          Contact Person's Email
                        </TableCell>
                        <TableCell align="left">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {contacts?.contacts?.map((contact) => (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={contact.id}
                        >
                          <TableCell align="left">
                            <Checkbox
                              checked={
                                contact.is_primary ||
                                selectedPrimaryContact === contact.id
                              }
                              onClick={() => handleCheckboxClick(contact?.id)}
                            />
                          </TableCell>
                          <TableCell align="left">
                            {contact?.contact_person_name === "" ||
                            contact?.contact_person_name === null
                              ? "-"
                              : capitalizeWords(contact?.contact_person_name)}
                          </TableCell>

                          <TableCell align="left">
                            {getUserRole
                              ? contact?.contact_person_phone === "" ||
                                contact?.contact_person_phone === null
                                ? "-"
                                : contact?.contact_person_phone
                              : contact?.contact_person_phone === "" ||
                                contact?.contact_person_phone === null
                              ? "-"
                              : maskPhoneNumber(contact?.contact_person_phone)}
                          </TableCell>
                          <TableCell align="left">
                            {getUserRole
                              ? contact?.contact_person_email === "" ||
                                contact?.contact_person_email === null
                                ? "-"
                                : contact?.contact_person_email
                              : contact?.contact_person_email === "" ||
                                contact?.contact_person_email === null
                              ? "-"
                              : maskEmail(contact?.contact_person_email)}
                          </TableCell>
                          <TableCell align="left">
                            <Box className="action-btn-wrap">
                              <Button
                                variant="outlined"
                                className="action-btn edit-btn"
                                startIcon={<RiPencilLine />}
                                title="Edit"
                                onClick={() => {
                                  handleEditOpen(contact?.id);
                                }}
                                disableRipple
                              ></Button>
                              <Button
                                variant="outlined"
                                className="action-btn dlt-btn"
                                startIcon={<RiDeleteBinLine />}
                                title="Delete"
                                disableRipple
                                onClick={() => handleDeleteOpen(contact?.id)}
                              ></Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Box
                    variant="div"
                    component="div"
                    className="pagination-wrapper"
                  >
                    <Pagination
                      count={contacts?.totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      shape="rounded"
                    />
                  </Box>
                </TableContainer>
              ) : (
                <Typography variant="body1" component="div" className="no-msg">
                  No Contacts Found
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box className="footer-wrapper">
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn w-120"
            disableRipple
            onClick={submitHandler}
            disabled={isButtonDisabled}
          >
            Save
          </Button>
          <Button
            variant="contained"
            size="large"
            className="secondary-btn btn w-120"
            disableRipple
            onClick={() =>
              navigate("/clients", {
                state: { page: state !== null ? state.page : 1 },
              })
            }
          >
            Cancel
          </Button>
        </Box>
      </Box>
      {/*  START :: LEAD FILTER PANEL */}
      <ContactDrawer
        open={open}
        setOpen={setOpen}
        editContact={editContact}
        currentPage={currentPage}
        clientId={clientId}
      />
      {/* END :: LEAD FILTER PANEL */}
      <EditcontactDrawer
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        handleEditOpen={handleEditOpen}
        contId={contId}
        currentPage={currentPage}
        clientId={clientId}
      />
      <ContactDeleteModal
        handleDeleteOpen={handleDeleteOpen}
        handleDeleteClose={handleDeleteClose}
        contactDelete={contactDelete}
        selectedContactId={selectedContactId}
        clientId={clientId}
        currentPage={currentPage}
      />
    </>
  );
};

export default ClientEdit;

import { callApi } from "../../components/common/commonfunctionforapi";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";

export const deleteKeywordById = createAsyncThunk(
  "keywords/fetchKeywordsById",
  async ({ deleteId }, { rejectWithValue, dispatch }) => {
    // console.log(keywordId);
    try {
      const response = await callApi({
        method: "delete",
        url: `${process.env.REACT_APP_API_URL}/keywords`,
        params: {
          deleteId: deleteId,
        },
      });
      // console.log(response.data);

      return { success: true, message: response.data.message };
    } catch (error) {
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  loading: false,
  error: null,
  //data: null,
};
const deleteKeywordByIdSlice = createSlice({
  name: "deleteKeywordById",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(deleteKeywordById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteKeywordById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // state.data = action.payload;
      })
      .addCase(deleteKeywordById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default deleteKeywordByIdSlice.reducer;

import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { successToast } from "../../responses/successToast";
import { deleteBidById } from "../../store/slices/deleteBidByIdSlice";
import { fetchBids } from "../../store/slices/bidSlice";
import { fetchBidFilters } from "../../store/slices/bidFilterSlice";

const BidDeleteModal = ({
  clientDelete,
  handleDeleteClose,
  selectedClientId,
  currentPage,
  filterApplied,
  filteredCurrentPage,
  searchText,
  endDate,
  startDate,
  bd,
  platform,
  keywordPayload,
  technologyPayload,
  billingType,
  bidType,
  isChecked,
}) => {
  const dispatch = useDispatch();
  const clientId = selectedClientId;

  const submitHandler = () => {
    if (filterApplied) {
      dispatch(deleteBidById({ clientId: clientId }))
        .unwrap()
        .then((result) => {
          const { success } = result;

          if (success) {
            successToast("Bid deleted successfully");
            dispatch(
              fetchBidFilters({
                page: filteredCurrentPage,
                limit: 10,
                search_text: searchText,
                startdate: startDate,
                enddate: endDate,
                bd_id: bd,
                platform: platform,
                billing_type: billingType,
                bid_type: bidType,
                keyword_id: keywordPayload,
                technology_id: technologyPayload,
                converted: isChecked === true ? 1 : 0,
              })
            )
              .unwrap()
              .then((bidsResult) => {
                const totalPages = bidsResult?.totalPages || 1;
                const updatedPage = Math.min(currentPage, totalPages);

                dispatch(
                  fetchBidFilters({
                    page: updatedPage,
                    limit: 10,
                    search_text: searchText,
                    startdate: startDate,
                    enddate: endDate,
                    bd_id: bd,
                    platform: platform,
                    billing_type: billingType,
                    bid_type: bidType,
                    keyword_id: keywordPayload,
                    technology_id: technologyPayload,
                    converted: isChecked === true ? 1 : 0,
                  })
                );
              });
            handleDeleteClose();
          } else {
            handleDeleteClose();
          }
        })
        .catch((error) => {
          console.error("Failed to delete bid:", error);
          handleDeleteClose();
        });
    } else {
      dispatch(deleteBidById({ clientId: clientId }))
        .unwrap()
        .then((result) => {
          const { success } = result;

          if (success) {
            successToast("Bid deleted successfully");
            dispatch(fetchBids({ page: currentPage, limit: 10 }))
              .unwrap()
              .then((bidsResult) => {
                const totalPages = bidsResult?.totalPages || 1;
                const updatedPage = Math.min(currentPage, totalPages);

                dispatch(fetchBids({ page: updatedPage, limit: 10 }));
              });
            handleDeleteClose();
          } else {
            handleDeleteClose();
          }
        })
        .catch((error) => {
          console.error("Failed to delete bid:", error);
          handleDeleteClose();
        });
    }
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={clientDelete}
        closeAfterTransition
      >
        <Fade in={clientDelete}>
          <Box className="modal-wrapper">
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3">
                Do you want to delete this Bid?
              </Typography>
              <Typography variant="body1" component="p">
                This action can’t be undone.
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body"></Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={submitHandler}
                >
                  Delete
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={handleDeleteClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default BidDeleteModal;

import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
// import "../../styles/announcement.scss";
import { RiCloseLine } from "react-icons/ri";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";

const AnnouncementModal2 = ({
  openReadAllModal,
  announcement,
  viewAnnouncementOpen,
  setViewAnnouncementOpen,
}) => {
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewAnnouncementOpen}
        onClose={() => setViewAnnouncementOpen(false)}
        closeAfterTransition
      >
        <Fade in={viewAnnouncementOpen}>
          <Box className="modal-wrapper-announcement modal-ui">
            <Box className="modal-body">
              <Box
                variant="div"
                component="div"
                className="title"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                  }}
                >
                  <CampaignOutlinedIcon sx={{fontSize:"35px"}}></CampaignOutlinedIcon>
                  <Typography variant="h6">Announcement Detail</Typography>
                </Box>
                <Box variant="div" component="div" className="btn-group">
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn custom-icon-btn"
                    disableRipple
                    endIcon={<RiCloseLine />}
                    onClick={() => setViewAnnouncementOpen(false)}
                  ></Button>
                </Box>
              </Box>
              <Box
                variant="div"
                component="div"
                className="announcement-detail"
              >
                <Typography
                  variant="body1"
                  component="label"
                  className="announcement-title"
                  sx={{ whiteSpace: "pre-wrap" }}
                >
                  {announcement?.name}
                </Typography>
                <Typography
                  variant="body1"
                  component="label"
                  className="announcement-desc"
                  sx={{
                    whiteSpace: "pre-wrap",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `<div style="display: flex; flex-direction: column;">${
                      announcement?.content || ""
                    }</div>`,
                  }}
                />

                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                  }}
                >
                  <Typography
                    variant="body1"
                    component="div"
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    {announcement?.authorName?.charAt(0).toUpperCase() +
                      announcement?.authorName?.slice(1)}
                  </Typography>
                  <Typography
                    variant="body1"
                    component="div"
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    {announcement?.date}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AnnouncementModal2;

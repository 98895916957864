import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  user: null,
  loading: false,
  error: null,
};

const editTechnologySlice = createSlice({
  name: "editTechnology",
  initialState,
  reducers: {
    editTechnologyStart(state) {
      state.loading = true;
      state.error = null;
    },
    editTechnologySuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    editTechnologyFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  editTechnologyStart,
  editTechnologySuccess,
  editTechnologyFailure,
} = editTechnologySlice.actions;
export default editTechnologySlice.reducer;

export const editTechnologyById =
  ({ editId, technology_name }) =>
  async (dispatch) => {
    // console.log(techId, techName, "tech FROM SLICE");
    try {
      dispatch(editTechnologyStart());
      const response = await callApi({
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/technologies/${editId}`,
        data: { technology_name: technology_name },
      });

      const lead = response;
      const data = response.data;
      dispatch(editTechnologySuccess(lead));
      return { success: true, message: data.message };
    } catch (error) {
      dispatch(editTechnologyFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return { success: false, error: error.response.data.message };
    }
  };

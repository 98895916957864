import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  user: null,
  loading: false,
  error: null,
};

const editKeywordSlice = createSlice({
  name: "editKeyword",
  initialState,
  reducers: {
    editKeywordStart(state) {
      state.loading = true;
      state.error = null;
    },
    editKeywordSuccess(state, action) {
      state.loading = false;
      state.user = action.payload;
    },
    editKeywordFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { editKeywordStart, editKeywordSuccess, editKeywordFailure } =
  editKeywordSlice.actions;
export default editKeywordSlice.reducer;

export const editKeywordById =
  ({ editId, keyword_name }) =>
  async (dispatch) => {
    // console.log(keywordId, keyName, "KEY FROM SLICE");
    try {
      dispatch(editKeywordStart());
      const response = await callApi({
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/keywords/${editId}`,
        data: { keyword_name: keyword_name },
      });

      const lead = response;
      const data = response.data;
      dispatch(editKeywordSuccess(lead));
      return { success: true, message: data.message };
    } catch (error) {
      dispatch(editKeywordFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return { success: false, error: error.response.data.message };
    }
  };

import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";

const initialState = {
  filter: null,
  loading: false,
  error: null,
};

const editFilterByIdSlice = createSlice({
  name: "editFilterById",
  initialState,
  reducers: {
    editFilterByIdStart(state) {
      state.loading = true;
      state.error = null;
    },
    editFilterByIdSuccess(state, action) {
      state.loading = false;
      state.filter = action.payload;
    },
    editFilterByIdFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  editFilterByIdStart,
  editFilterByIdSuccess,
  editFilterByIdFailure,
} = editFilterByIdSlice.actions;
export default editFilterByIdSlice.reducer;

export const editfilterByIdAdmin =
  ({ editFilerId, editFilterData, status }) =>
  async (dispatch) => {
    try {
      dispatch(editFilterByIdStart());
      const response = await callApi({
        method: "patch",
        url: `${process.env.REACT_APP_API_URL}/filters/${editFilerId}`,
        data: editFilterData,
        params: {
          filter_type: "projects",
          status: status,
        },
      });

      const filter = response;
      const data = response.data;
      dispatch(editFilterByIdSuccess(filter));

      return { success: true, message: data.message };
    } catch (error) {
      dispatch(editFilterByIdFailure(error.message));
      dispatch(
        setFeedback({
          status: "fail",
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return { success: false, error: error.response.data.message };
    }
  };

import { Box, Fade, Modal, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { createKeyword } from "../../store/slices/createKeywordSlice";
import { successToast } from "../../responses/successToast";
// import { useNavigate } from "react-router-dom";
import { fetchKeywords } from "../../store/slices/keywordSlice";

const AddKeywordModal = ({ invalidValue, onClose }) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const keywords = useSelector((state) => state.keywords.keywords);
  const keyName = invalidValue;
  // console.log(keyName, "keyword from create lead");
  const submitHandler = () => {
    dispatch(createKeyword(keyName))
      .then((response) => {
        if (response.success) {
          // console.log(response, "api response");
          dispatch(fetchKeywords({ query: "" }));
          onClose();
          successToast("Keyword created successfully!");
        } else {
          console.error("Error creating user:", response.error);
        }
      })
      .catch((error) => {
        console.error("Error creating user:", error.message);
      });
  };
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={invalidValue}
        // onClose={deleteModalClose}
        closeAfterTransition
      >
        <Fade in={invalidValue}>
          <Box className="modal-wrapper">
            <Box variant="div" component="div" className="title">
              <Typography variant="h3" component="h3">
                Do you want to add this keyword?
              </Typography>
            </Box>
            <Box variant="div" component="div" className="body"></Box>
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={submitHandler}
                >
                  Add
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AddKeywordModal;

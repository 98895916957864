import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  Button,
  Box,
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  Stack,
  Chip,
  Drawer,
  Tabs,
  Tab,
  Modal,
  Fade,
  TextareaAutosize,
  Skeleton,
  Tooltip,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  RiAddCircleLine,
  RiAlarmLine,
  RiArrowLeftLine,
  RiCloseLine,
  RiDraftLine,
  RiPencilLine,
  RiPhoneLine,
  RiRepeat2Line,
  RiEyeLine,
  RiDeleteBinLine,
} from "react-icons/ri";
import { MdAccessAlarm } from "react-icons/md";
import PropTypes from "prop-types";
import ProjectDocumentComponent from "../../components/common/projectDocuments";
import ConvertLeadToProjectModal from "../../components/common/convertLeadToProjectModal";
import AttachLeadToProject from "../../components/common/attachLeadToProjectModal";
import { DatePicker } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeadsById } from "../../store/slices/leadbyIdSlice";
import { fetchActivities } from "../../store/slices/activitylogSlice";
import { createActivity } from "../../store/slices/cresteActivitySlice";
import { successToast } from "../../responses/successToast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { updateLeads, viewLeads } from "../../utils/permissions";
import WithPermission from "../../components/common/withPermission";
import LeadActivityDeleteModal from "../../components/common/deleteLeadActivityModal";
import { fetchLeadActivitiesById } from "../../store/slices/leadActivitybyIdSlice";
import { editleadActivityById } from "../../store/slices/editLeadActivitySlice";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { fetchUsers } from "../../store/slices/userSlice";
import dayjs from "dayjs";
import { maskEmail, maskPhoneNumber } from "../../utils/masking";
import { selectIsAdmin } from "../../constants/selectors";
import moment from "moment";

function capitalizeWords(input) {
  return input?.replace(/\w+/g, function (word) {
    return word?.charAt(0).toUpperCase() + word.slice(1);
  });
}

const handleLinkClick = (event, website) => {
  if (website && !website.startsWith("http")) {
    event.preventDefault();
    window.open(`http://${website}`, "_blank");
  }
};
// SIDEBAR DRAWER TABBING PANEL
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const customToolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#3e3f42",
          color: "white",
          fontSize: "11px",
          // margin: "0",
          // padding: "10",
          border: "1px solid white",
        },
        arrow: {
          color: "var(--color-white)",
        },
      },
    },
  },
});

// UI LI LIST STYLING
const ListStyle = {
  listStyleType: "decimal",
  pl: 3,
  pt: 0,
  pb: 0,
  "& .MuiListItem-root": {
    display: "list-item",
    paddingTop: 0,
    paddingLeft: "5px",
    paddingBottom: 0,
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "28px",
    "& a": {
      //wordBreak: "break-all",
    },
  },
};

// LEAD ACTIVITY OPTIONS
const leadActivityOptions = [
  { value: "call", label: "Call" },
  { value: "note", label: "Note" },
  { value: "followup", label: "Followup" },
];

const LeadView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
 
  const path = location?.state?.path;
  let { state } = location;
  if (state === null) {
    state = {
      status: 0,
      page: 1,
      rowsPerPage: 10,
      filterApplied: false,
      filterByIdApplied: false,
      applyFilterId: "",
      selectedKeywords: [],
      selectedTLandBDAs: [],
      selectedTechnologies: [],
      text: "",
      converted: "",
      edit: "",
      keywordSwitchVal: true,
      techStackSwitchVal: true,
      TLandBDAsSwitchVal:true,
    };
  }
  const { leadId } = useParams();
  const leads = useSelector((state) => state.leadsbyId.leads);
  const getUserRole = useSelector(selectIsAdmin);

  const activities = useSelector((state) => state.activities.activities);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState("");
  const [commenterror, setCommentError] = useState("");
  const [activityError, setActivityError] = useState("");
  const activitybyid = useSelector(
    (state) => state.leadactivitybyId.activities
  );
  const [activityId, setActivityId] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [dateError, setDateError] = useState("");
  const [timeError, setTimeError] = useState("");
  const [assingeeError, setAssigneeError] = useState("");
  const maxLength = 31;
  const [isOpen, setIsOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const users = useSelector((state) => state.users.users);
  const [memberspayload, setMembersPayload] = useState([]);
  const [removeKeyId, setRemoveKeyId] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // SIDEBAR DRAWER TABBING PANEL
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // START :: EXPORT TO PDF MODAL

  const [convertCancel, setConvertCancel] = useState(false);
  const [attachCancel, setAttachCancel] = useState(false);
  const [convertLeadId, setConvertLeadId] = useState(null);
  const [leadProjectName, setLeadProjectName] = useState("");
  const handleConvertOpen = (leadId, projectName) => {
    setConvertLeadId(leadId);
    setLeadProjectName(projectName);
    setConvertCancel(true);
  };

  const handleAttachOpen = (leadId, projectName) => {
    setConvertLeadId(leadId);
    setLeadProjectName(projectName);
    setAttachCancel(true);
  };


  // START :: setActivityLogModal
  const [ActivityLog, setActivityLogModal] = useState(false);
  const [activity, setActivity] = useState("");
  // END :: setActivityLogModal

  // START :: MANAGE A STATE FOR SELECT OPTION
  //const [selectedOption, setSelectedOption] = useState(null);
  const handleOptionChange = (selectedOption) => {
    // setSelectedOption(selectedOption);
    setActivity(selectedOption.value);
    setActivityError("");
  };

  // ACTIVITY DELETE
  const [activityDelete, setActivityDelete] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const handleActivityDeleteOpen = (acitivyId) => {
    setSelectedActivityId(acitivyId);
    setActivityDelete(true);
  };

  const handleActivityDeleteClose = () => setActivityDelete(false);

  const formatActionNote = (actionNote) => {
    if (!actionNote || actionNote.indexOf("~##~") === -1) {
      return actionNote;
    }

    const actions = actionNote.split("~##~");

    if (actions.length === 2) {
      return actions;
    } else {
      const formattedActions = actions.map((action, index) => (
        <div key={index}>
          {index === 0 ? (
            ` ${action}`
          ) : (
            <ul
              style={{
                marginBottom: "2px",
              }}
            >
              <li> {action}</li>
            </ul>
          )}
        </div>
      ));
      return formattedActions;
    }
  };

  // END :: MANAGE A STATE FOR SELECT OPTION
  const handleActivityLog = () => {
    setOpen(!open);
    dispatch(
      fetchActivities({
        leadId,
        state: value === 0 ? "activities" : value === 1 ? "status" : "updates",
      })
    );
  };

  function formatDate(inputDate) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(inputDate).toLocaleDateString(undefined, options);
  }

  function formatActivityDate(activitydate) {
    const date = moment(activitydate, "YYYY-MM-DD");
    return date.format("MMMM DD, YYYY");
  }

  function capitalizeFirstLetter(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }

  function formatStatus(str) {
    const words = str?.split("_");
    const capitalizedWords = words?.map((word) => capitalizeFirstLetter(word));
    return capitalizedWords?.join(" ");
  }

  function convertTo24HourFormat(timeStr) {
    if (timeStr === null) {
      return "";
    }

    const [hours, minutes] = timeStr.split(":");
    const hour = parseInt(hours, 10);

    return `${String(hour).padStart(2, "0")}:${minutes}`;
  }

  function formattime(timestamp) {
    const dateObject = new Date(timestamp);
    dateObject.setHours(dateObject.getHours() + 5);
    dateObject.setMinutes(dateObject.getMinutes() + 30);
    const hours = String(dateObject.getUTCHours()).padStart(2, "0");
    const minutes = String(dateObject.getUTCMinutes()).padStart(2, "0");
    const seconds = String(dateObject.getUTCSeconds()).padStart(2, "0");
    const time = `${hours}:${minutes}:${seconds}`;
    return convertTo24HourFormat(time);
  }
  const [selectedDate, setSelectedDate] = useState(""); // State for date
  const [selectedTime, setSelectedTime] = useState(""); // State for time
  const timeString = (timestr) => {
    const date = new Date(timestr); // Replace this with your date
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDateError("");
  };
  const handleTimeChange = (time) => {
    setSelectedTime(time);

    setTimeError("");
  };

  const activityData = {
    lead_id: leadId,
    activity: activity,
    assignee_user_id: memberspayload,
    delete_assignee_user_id: removeKeyId,
    date:
      activity === "call" || activity === "followup"
        ? moment(selectedDate).format("YYYY-MM-DD")
        : null,
    time:
      activity === "call" || activity === "followup"
        ? timeString(selectedTime)
        : null,
    comments: comments,
  };

  const defaultBdaOption = leads?.bda
    ? {
        value: leads.bda.id,
        label: `${leads.bda.first_name} ${leads.bda.last_name}`,
      }
    : null;

  const defaultTlOption = leads?.tl
    ? {
        value: leads.tl.id,
        label: `${leads.tl.first_name} ${leads.tl.last_name}`,
      }
    : null;

  const defaultBDMOption = leads?.bde
    ? {
        value: leads.bde.id,
        label: `${leads.bde.first_name} ${leads.bde.last_name}`,
      }
    : null;

  // Filter out null options
  const defaultOptions = [
    defaultBdaOption,
    defaultTlOption,
    defaultBDMOption,
  ].filter((option) => option !== null);

  // ...

  // Use defaultOptions to initialize filteredOptions state
  const [filteredOptions, setFilteredOptions] = useState(defaultOptions);
  const handleActivityModal = async () => {
    setIsEditing(false);
    if (!activity) {
      setActivityError("Please Select Activity");
      return;
    }
    if (activity === "call" || activity === "followup") {
      if (memberspayload.length === 0 && memberspayload?.length === 0) {
        // errorToast("Please fill in all the required fields marked with a star ( * )");
        setAssigneeError("Please select atleast one assignee");
        return;
      }
      if (!selectedDate) {
        setDateError("Please Select Date");
        return;
      }
      if (!selectedTime) {
        setTimeError("Please Select time");
        return;
      }
    }
    if (activity === "note") {
      if (!comments) {
        setCommentError("Please Enter Commment");
        return;
      }
    }

    setIsButtonDisabled(true);
    await dispatch(createActivity(activityData))
      .then((response) => {
        if (response?.success) {
          setActivityLogModal(false);
          setActivity("");
          setComments("");
          setSelectedDate(null);
          setSelectedTime(null);
          setRemoveKeyId([]);
          successToast("Acitivity created successfully");
          dispatch(
            fetchActivities({
              leadId,
              state:
                value === 0 ? "activities" : value === 1 ? "status" : "updates",
            })
          );
          setIsButtonDisabled(false);
        } else {
          console.error("Failed! Something went wrong.");
          setIsButtonDisabled(false);
        }
      })
      .catch((error) => {
        console.error("API call error:", error);
      });
  };

  const handleEditActivitylog = async () => {
    if (!activity) {
      setActivityError("Please Select Activity");
      return;
    }
    if (activity === "call" || activity === "followup") {
      if (memberspayload.length === 0 && memberspayload?.length === 0) {
        // errorToast("Please fill in all the required fields marked with a star ( * )");
        setAssigneeError("Please select atleast one assignee");
        return;
      }
      if (!selectedDate) {
        setDateError("Please Select Date");
        return;
      }
      if (!selectedTime) {
        setTimeError("Please Select time");
        return;
      }
    }
    if (activity === "note") {
      if (!comments) {
        setCommentError("Please Enter Commment");
        return;
      }
    }
    setIsButtonDisabled(true);
    await dispatch(
      editleadActivityById({
        activityId: activityId,
        activityData: activityData,
      })
    )
      .then((response) => {
        if (response?.success) {
          setActivityLogModal(false);
          setIsEditing(false);
          setActivity("");
          setComments("");
          setSelectedDate(null);
          setSelectedTime(null);
          setRemoveKeyId([]);
          successToast("Acitivity Updated successfully");
          dispatch(
            fetchActivities({
              leadId,
              state:
                value === 0 ? "activities" : value === 1 ? "status" : "updates",
            })
          );
          setIsButtonDisabled(false);
        } else {
          console.error("Failed! Something went wrong.");
          setIsButtonDisabled(false);
        }
      })
      .catch((error) => {
        console.error("API call error:", error);
      });
  };

  const handleeditopen = (id) => {
    setActivityLogModal(true);
    setIsEditing(true);
    setActivityId(id);
    dispatch(
      fetchLeadActivitiesById({
        activityId: id,
      })
    );
  };

  useEffect(() => {
    if (!isEditing) {
      setMembers(filteredOptions);
      setMembersPayload(filteredOptions.map((option) => option.value));
    }
  }, [isEditing, filteredOptions]);

  useEffect(() => {
    if (isEditing) {
      setActivity(activitybyid?.activity);
      if (activitybyid?.date === null) {
        setSelectedDate(null);
      } else {
        const activityDate = new Date(activitybyid?.date);
        const year = activityDate.getUTCFullYear();
        const month = activityDate.toLocaleString("en", { month: "short" });
        const day = activityDate.getUTCDate();
        const hours = activityDate.getUTCHours();
        const minutes = activityDate.getUTCMinutes();
        const seconds = activityDate.getUTCSeconds();
        const weekday = activityDate.getUTCDay();
        const dayFormat = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
          weekday
        ]; // Map day index to abbreviation
        // Construct the formatted date string
        const formattedDateString = `${dayFormat} ${month} ${day} ${year} ${hours}:${minutes}:${seconds} GMT+0000 (GMT)`;
        const formattedDate = new Date(formattedDateString);

        const offsetInMinutes = formattedDate.getTimezoneOffset();

        // Convert the offset from minutes to hours
        const offsetInHours = offsetInMinutes / 60;

        // Adjust the date based on the offset
        formattedDate.setHours(formattedDate.getHours() + offsetInHours);

        if (activityId?.date === "") {
          setSelectedDate("");
        } else {
          setSelectedDate(formattedDate);
        }
      }
      const assigneeOptions = activitybyid?.lead_assignee_users?.map(
        (technologyName) => ({
          value: technologyName?.user_id,
          label: `${technologyName?.user?.first_name} ${technologyName?.user?.last_name}`,
        })
      );
      setMembers(assigneeOptions);
      setMembersPayload(assigneeOptions.map((option) => option.value));
      if (activitybyid?.time === null) {
        setSelectedTime(null);
      } else {
        try {
          const [hours, minutes, seconds] = activitybyid.time
            .split(":")
            .map(Number);

          // Create a new Date object and set the time components
          const newTime = new Date(
            Date.UTC(1970, 0, 1, hours, minutes, seconds)
          );
          // Get the local time zone offset in minutes
          const offsetInMinutes = newTime.getTimezoneOffset();

          // Adjust the time by adding the offset in minutes
          newTime.setMinutes(newTime.getMinutes() + offsetInMinutes);

          setSelectedTime(newTime);
        } catch (error) {
          console.error("Error parsing time:", error);
          setSelectedTime(null); // Handle the error by setting a default value or showing an error message
        }
      }

      if (activitybyid?.comments === "") {
        setComments("");
      } else {
        setComments(activitybyid?.comments);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activitybyid]);
  const loggedInUserId = users?.loginuser?.map((login) => login.id);

  const userRoleArray = users?.loginuser?.map(
    (login) => login?.roles?.role_name
  );
  // eslint-disable-next-line no-unused-vars
  const userRole = userRoleArray ? userRoleArray[0] : null;

  const memberOptions = users?.AllTlandBda?.map((user) => ({
    value: user.id,
    label: `${user.first_name} ${user.last_name}`,
  }));
  const handleAssigneeOptionChange = (selectedOptions) => {
    setMembers(selectedOptions);
    const selectedIds = selectedOptions.map((option) => option.value);
    setMembersPayload(selectedIds);
    if (selectedOptions) {
      setAssigneeError("");
    }
  };

  useEffect(() => {
    setLoading(true);
    dispatch(fetchLeadsById({ leadId })).then((response) => {
      setLoading(false);
      if (response?.payload?.deletedAt || response?.error) {
        navigate("/not-found");
      }
    });
  }, [dispatch, leadId, navigate]);

  useEffect(() => {
    dispatch(
      fetchActivities({
        leadId,
        state: value === 0 ? "activities" : value === 1 ? "status" : "updates",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, value]);

  useEffect(() => {
    dispatch(
      fetchUsers({
        page: "",
        limit: "",
        query: "",
      })
    );
  }, [dispatch]);

  return (
    <>
      {/* START :: LEAD VIEW HEADER */}
      <Box className="content-header view-lead-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() =>path?navigate(path):
              navigate("/leads", {
                state: {
                  page: state.page,
                  rowsPerPage: state.rowsPerPage,
                  status: state.status,
                  filterApplied: state.filterApplied,
                  filterByIdApplied: state.filterByIdApplied,
                  applyFilterId: state.applyFilterId,
                  selectedKeywords: state.selectedKeywords,
                  selectedTLandBDAs: state.selectedTLandBDAs,
                  selectedTechnologies: state.selectedTechnologies,
                  keywordSwitchVal: state.keywordSwitchVal,
                  techStackSwitchVal: state.techStackSwitchVal,
                  TLandBDAsSwitchVal:state.TLandBDAsSwitchVal,
                  text: state.text,
                  converted: state.converted,
                  edit: state.edit,
                },
              })
            }
          ></Button>
          View Lead
        </Typography>
        <Box variant="div" component="div" className="content-header-right">
          {leads?.bid_id ? (
            <WithPermission
              permission={viewLeads}
              element={
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn add-btn bid-btn-lead"
                  disableRipple
                  endIcon={<RiEyeLine />}
                  onClick={() => navigate(`/bids/${leads?.bid_id}/view`)}
                >
                  View Bid
                </Button>
              }
            />
          ) : null}
          {leads?.status === "won" && leads?.project_id !== null ? (
            <WithPermission
              permission={viewLeads}
              element={
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn add-btn won-btn-lead"
                  disableRipple
                  endIcon={<RiEyeLine />}
                  onClick={() =>
                    navigate(`/projects/${leads?.project_id}/view`)
                  }
                >
                  Lead Won - View Project
                </Button>
              }
            />
          ) : (
            <WithPermission
              permission={updateLeads}
              element={
                <>
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn add-btn attach-btn"
                    disableRipple
                    onClick={() =>
                      handleAttachOpen(leads?.id, leads?.project_name)
                    }
                  >
                    Attach Project
                  </Button>
                  OR
                  <Button
                    variant="contained"
                    size="large"
                    className="primary-btn btn add-btn convert-btn"
                    disableRipple
                    endIcon={<RiRepeat2Line />}
                    onClick={() =>
                      handleConvertOpen(leads?.id, leads?.project_name)
                    }
                  >
                    Convert To Project
                  </Button>
                </>
              }
            />
          )}
          <Button
            variant="contained"
            size="large"
            className="primary-btn btn add-btn activity-btn"
            disableRipple
            endIcon={<RiAlarmLine />}
            onClick={handleActivityLog}
          >
            Activity Log
          </Button>
          <WithPermission
            permission={updateLeads}
            element={
              <Button
                variant="contained"
                size="large"
                className="primary-btn btn only-icon-btn"
                endIcon={<RiPencilLine />}
                disableRipple
                onClick={() =>
                  navigate(`/leads/${leadId}/edit`, {
                    state: {
                      page: state.page,
                      rowsPerPage: state.rowsPerPage,
                      status: state.status,
                      filterApplied: state.filterApplied,
                      filterByIdApplied: state.filterByIdApplied,
                      applyFilterId: state.applyFilterId,
                      selectedKeywords: state.selectedKeywords,
                      selectedTLandBDAs: state.selectedTLandBDAs,
                      selectedTechnologies: state.selectedTechnologies,
                      keywordSwitchVal: state.keywordSwitchVal,
                  techStackSwitchVal: state.techStackSwitchVal,
                  TLandBDAsSwitchVal:state.TLandBDAsSwitchVal,
                      text: state.text,
                      converted: state.converted,
                      edit: state.edit,
                    },
                  })
                }
              ></Button>
            }
          />
        </Box>
      </Box>
      {/* END :: LEAD VIEW HEADER */}

      {/* START :: LEAD VIEW CONTENT */}
      <Box className="content-wrapper">
        <Grid container spacing={3}>
          <Grid item lg={8} md={12}>
            <Box className="card-wrapper">
              <Grid container spacing={2} className="view-info">
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Lead Details
                  </Typography>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Name
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {leads?.company_name === "" ||
                      leads?.company_name === "null"
                        ? "-"
                        : leads?.company_name}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Email
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {getUserRole
                        ? leads?.company_email === "" ||
                          leads?.company_email === "null"
                          ? "-"
                          : leads?.company_email
                        : leads?.company_email === "" ||
                          leads?.company_email === "null"
                        ? "-"
                        : maskEmail(leads?.company_email)}
                    </Typography>
                  )}
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Company Phone No.
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {getUserRole
                        ? leads?.company_phone === "" ||
                          leads?.company_phone === "null"
                          ? "-"
                          : leads?.company_phone
                        : leads?.company_phone === "" ||
                          leads?.company_phone === "null"
                        ? "-"
                        : maskPhoneNumber(leads?.company_phone)}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {leads?.contact_person === "null"
                        ? "-"
                        : leads?.contact_person}
                    </Typography>
                  )}
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person’s Email
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {getUserRole
                        ? leads?.contact_person_email === "" ||
                          leads?.contact_person_email === "null"
                          ? "-"
                          : leads?.contact_person_email
                        : leads?.contact_person_email === "" ||
                          leads?.contact_person_email === "null"
                        ? "-"
                        : maskEmail(leads?.contact_person_email)}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Contact Person’s Phone No.
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {getUserRole
                        ? leads?.contact_person_phone === "" ||
                          leads?.contact_person_phone === "null"
                          ? "-"
                          : leads?.contact_person_phone
                        : leads?.contact_person_phone === "" ||
                          leads?.contact_person_phone === "null"
                        ? "-"
                        : maskPhoneNumber(leads?.contact_person_phone)}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Keywords
                  </Typography>
                  {loading ? (
                    // Display skeleton placeholders while loading
                    <Box display="flex">
                      {Array.from({ length: 3 }).map((_, index) => (
                        <Skeleton
                          key={index}
                          variant="text"
                          width={100}
                          height={40}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Box variant="div" component="div" className="tag-wrap">
                      {leads?.lead_keywords?.length > 0
                        ? leads?.lead_keywords?.map((lead) => (
                            <Chip label={lead?.keywords?.keyword_name} />
                          ))
                        : "-"}
                    </Box>
                  )}
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Technology Stacks
                  </Typography>
                  {loading ? (
                    <Box display="flex">
                      {Array.from({ length: 3 }).map((_, index) => (
                        <Skeleton
                          key={index}
                          variant="text"
                          width={100}
                          height={40}
                        />
                      ))}
                    </Box>
                  ) : (
                    <Box variant="div" component="div" className="tag-wrap">
                      {leads?.lead_technology?.length > 0
                        ? leads?.lead_technology?.map((lead) => (
                            <Chip
                              label={lead?.lead_technology?.technology_name}
                            />
                          ))
                        : "-"}
                    </Box>
                  )}
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Key Features
                  </Typography>
                  {leads?.key_features === "" ||
                  leads?.key_features === "null" ? (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                      style={{ wordBreak: "break-all" }}
                    >
                      -
                    </Typography>
                  ) : (
                    <ReactQuill
                      value={leads?.key_features}
                      readOnly={true}
                      theme="bubble"
                    />
                  )}
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Key Integrations
                  </Typography>
                  {leads?.key_integration === "" ||
                  leads?.key_integration === "null" ? (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                      style={{ wordBreak: "break-all" }}
                    >
                      -
                    </Typography>
                  ) : (
                    <ReactQuill
                      value={leads?.key_integration}
                      readOnly={true}
                      theme="bubble"
                    />
                  )}
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Name
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                      style={{ wordBreak: "break-all" }}
                    >
                      {leads?.project_name === "" ||
                      leads?.project_name === "null"
                        ? "-"
                        : leads?.project_name}
                    </Typography>
                  )}
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Project Links
                  </Typography>
                  {loading ? (
                    // If loading is true, render skeleton loaders
                    <List sx={ListStyle}>
                      {Array.from({ length: 3 }).map((_, index) => (
                        <ListItem key={index}>
                          <Skeleton variant="text" width={100} height={30} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <>
                      {leads?.lead_project_links &&
                      leads?.lead_project_links?.length > 0 ? (
                        <List sx={ListStyle}>
                          {leads?.lead_project_links?.map((lead, index) => (
                            <ListItem key={index}>
                              <a
                                href={lead?.lead_link}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) =>
                                  handleLinkClick(e, lead?.lead_link)
                                }
                                style={{ wordBreak: "break-all" }}
                              >
                                {lead?.lead_link}
                              </a>
                            </ListItem>
                          ))}
                        </List>
                      ) : (
                        <Typography
                          variant="body1"
                          component="span"
                          className="value"
                        >
                          -
                        </Typography>
                      )}
                    </>
                  )}
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Estimation Link
                  </Typography>

                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      <a
                        href={leads?.estimation_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ wordBreak: "break-all" }}
                        onClick={(e) =>
                          handleLinkClick(e, leads?.estimation_link)
                        }
                      >
                        {" "}
                        {leads?.estimation_link === "" ||
                        leads?.estimation_link === "null"
                          ? "-"
                          : leads?.estimation_link}
                      </a>
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Estimation(In hours)
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {leads?.estimation_hours === null ||
                      leads?.estimation_hours === "null"
                        ? "-"
                        : leads?.estimation_hours}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Team Leader
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {leads?.tl === null || leads?.ti === "null"
                        ? "-"
                        : `${capitalizeWords(
                            leads?.tl.first_name
                          )} ${capitalizeWords(leads?.tl.last_name)}`}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    BDM
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {leads?.bda === null || leads?.bda === "null"
                        ? "-"
                        : `${capitalizeWords(
                            leads?.bda.first_name
                          )} ${capitalizeWords(leads?.bda.last_name)}`}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    BDE
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {leads?.bde === null || leads?.bde === "null"
                        ? "-"
                        : `${capitalizeWords(
                            leads?.bde.first_name
                          )} ${capitalizeWords(leads?.bde.last_name)}`}
                    </Typography>
                  )}
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    display: {
                      xs: "block",
                      sm: "none",
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Divider className="divider" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography variant="body1" component="label">
                    Lead Feedback
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : leads?.lead_feedbacks?.length > 0 ? (
                    leads?.lead_feedbacks?.map((lead) => (
                      <ListItem style={{ paddingLeft: "0px" }}>
                        {lead?.feedbacks?.feedback}
                      </ListItem>
                    ))
                  ) : (
                    "-"
                  )}
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Lead Source
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {capitalizeFirstLetter(leads?.lead_source)}
                    </Typography>
                  )}
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Divider className="divider" />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography variant="body1" component="label">
                    Feedback Note
                  </Typography>
                  {loading ? (
                    <Skeleton variant="text" width={100} height={30} />
                  ) : (
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                      style={{ wordBreak: "break-all" }}
                    >
                      {leads?.feedback_note === "" ||
                      leads?.feedback_note === "null"
                        ? "-"
                        : leads?.feedback_note}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}>
            <Stack spacing={3} className="h-100">
              <Box className="card-wrapper view-info">
                <Typography variant="body1" component="label">
                  Contact Person
                </Typography>
                {loading ? (
                  <Skeleton variant="text" width={100} height={30} />
                ) : (
                  <div
                    className="contact-person"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      component="span"
                      className="value"
                    >
                      {leads?.contact_person === null ||
                      leads?.contact_person === "null" ||
                      leads?.contact_person === ""
                        ? "-"
                        : leads?.contact_person}
                    </Typography>

                    {leads?.client_id === null ? null : (
                      <Typography
                        variant="body1"
                        component="span"
                        className="value1"
                        onClick={() =>
                          navigate(`/clients/${leads?.client_id}/view`)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        View Details
                      </Typography>
                    )}
                  </div>
                )}
              </Box>
              <Box className="card-wrapper view-info">
                <Typography variant="body1" component="label">
                  Lead Status
                </Typography>
                {loading ? (
                  <Skeleton variant="text" width={100} height={30} />
                ) : (
                  <Typography
                    variant="body1"
                    component="span"
                    className="value"
                  >
                    {formatStatus(
                      leads?.status === "closed"
                        ? "Not Responding"
                        : leads?.status
                    )}
                  </Typography>
                )}
              </Box>
              <Box className="card-wrapper add-scroll-space">
                <Stack spacing={3} className="view-info">
                  <Typography
                    variant="h3"
                    component="h3"
                    className="card-title"
                  >
                    Documents
                  </Typography>
                  {leads?.lead_document_links &&
                  leads?.lead_document_links.length > 0 ? (
                    <List sx={ListStyle}>
                      {leads?.lead_document_links.map(
                        (ProjectDocumentList, index) => {
                          const text =
                            !ProjectDocumentList?.file_name ||
                            ProjectDocumentList?.file_name === "null"
                              ? ProjectDocumentList?.document_url
                              : ProjectDocumentList?.file_name;
                          const displayText =
                            text?.length > maxLength
                              ? text.slice(0, maxLength) + "..."
                              : text;
                          return (
                            <>
                              <ListItem key={index}>
                                <>
                                  <ProjectDocumentComponent
                                    ProjectDocumentName={displayText}
                                    ProjectDocumentLink={
                                      ProjectDocumentList.document_url
                                    }
                                    handleLinkClick={handleLinkClick}
                                  />
                                </>
                              </ListItem>
                            </>
                          );
                        }
                      )}
                    </List>
                  ) : (
                    // </Box>
                    <Typography
                      variant="body1"
                      component="p"
                      className="no-msg-found"
                    >
                      No Documents Found
                    </Typography>
                  )}
                </Stack>
              </Box>
              <Box
                className="card-wrapper add-scroll-space view-info"
                sx={{ flex: 1 }}
              >
                <Typography variant="body1" component="label">
                  Reference Links
                </Typography>

                {loading ? (
                  // If loading is true, render skeleton loaders
                  <List sx={ListStyle}>
                    {Array.from({ length: 3 }).map((_, index) => (
                      <ListItem key={index}>
                        <Skeleton variant="text" width={100} height={30} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <List sx={ListStyle} className="reference-link-wrapper">
                    {leads?.lead_reference_links?.length > 0 ? (
                      leads?.lead_reference_links?.map(
                        (referencelink, index) => (
                          <ListItem key={index}>
                            <a
                              href={referencelink.reference_link_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) =>
                                handleLinkClick(
                                  e,
                                  referencelink.reference_link_url
                                )
                              }
                            >
                              {referencelink.reference_link_url}
                            </a>
                          </ListItem>
                        )
                      )
                    ) : (
                      <Typography
                        variant="body1"
                        component="p"
                        className="no-msg-found"
                        style={{ marginTop: "24px" }}
                      >
                        No Reference Links Found
                      </Typography>
                    )}
                  </List>
                )}
              </Box>
            </Stack>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="card-wrapper add-scroll-space">
              <Stack spacing={3} className="view-info">
                <Typography variant="h3" component="h3" className="card-title">
                  Lead Video Casts
                </Typography>
                {leads?.lead_video_links &&
                leads?.lead_video_links.length > 0 ? (
                  // <Box className="document-card-content-wrapper over-flow-auto">
                  <Grid container rowSpacing={2} columnSpacing={2}>
                    <List sx={ListStyle}>
                      {leads?.lead_video_links?.map(
                        (ProjectDocumentList, index) => {
                          const text = ProjectDocumentList?.file_name
                            ? ProjectDocumentList?.file_name
                            : ProjectDocumentList?.video_url;
                          const displayText =
                            text?.length > maxLength
                              ? text.slice(0, maxLength) + "..."
                              : text;

                          return (
                            <>
                              <ListItem key={index}>
                                <>
                                  <ProjectDocumentComponent
                                    ProjectDocumentName={displayText}
                                    //ProjectDocumentImg={imageSrc}
                                    ProjectDocumentLink={
                                      ProjectDocumentList.video_url
                                    }
                                    handleLinkClick={handleLinkClick}
                                  />
                                </>
                              </ListItem>
                            </>
                          );
                        }
                      )}
                    </List>
                  </Grid>
                ) : (
                  <Typography
                    variant="body1"
                    component="p"
                    className="no-msg-found"
                  >
                    No Videos Found
                  </Typography>
                )}
              </Stack>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box className="card-wrapper add-scroll-space">
              <Stack spacing={3} className="view-info">
                <Typography variant="h3" component="h3" className="card-title">
                  Lead Screen Shots
                </Typography>
                {leads?.lead_screenshot_links &&
                leads?.lead_screenshot_links.length > 0 ? (
                  // <Box className="document-card-content-wrapper over-flow-auto">
                  <Grid container rowSpacing={2} columnSpacing={2}>
                    <List sx={ListStyle}>
                      {leads?.lead_screenshot_links?.map(
                        (ProjectDocumentList, index) => {
                          const text = ProjectDocumentList?.file_name
                            ? ProjectDocumentList?.file_name
                            : ProjectDocumentList?.lead_screenshots_url;
                          const displayText =
                            text?.length > maxLength
                              ? text.slice(0, maxLength) + "..."
                              : text;

                          return (
                            <>
                              <ListItem key={index}>
                                <>
                                  <ProjectDocumentComponent
                                    ProjectDocumentName={displayText}
                                    //ProjectDocumentImg={imageSrc}
                                    ProjectDocumentLink={
                                      ProjectDocumentList.lead_screenshots_url
                                    }
                                    handleLinkClick={handleLinkClick}
                                  />
                                </>
                              </ListItem>
                            </>
                          );
                        }
                      )}
                    </List>
                  </Grid>
                ) : (
                  // </Box>
                  <Typography
                    variant="body1"
                    component="p"
                    className="no-msg-found"
                  >
                    No ScreenShots Found
                  </Typography>
                )}
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* END :: LEAD VIEW CONTENT */}

      {/* START :: CONVERT TO PROJECT MODAL */}
      <ConvertLeadToProjectModal
        convertCancel={convertCancel}
        convertLeadId={convertLeadId}
        value="view"
        leadProjectName={leadProjectName}
        setConvertCancel={setConvertCancel}
      />
      <AttachLeadToProject
        attachCancel={attachCancel}
        convertLeadId={convertLeadId}
        value="view"
        leadProjectName={leadProjectName}
        setAttachCancel={setAttachCancel}
      />
      {/* END :: CONVERT TO PROJECT MODAL */}

      {/*  START :: PROJECT FILTER PANEL */}
      <Drawer
        className="side-drawer lead-view-drawer"
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box className="sidebar-tab-wrapper activity-log-wrapper">
          <Box className="sidebar-tab-wrapper">
            <Box className="close-btn" onClick={() => setOpen(false)}>
              <RiCloseLine />
            </Box>
            <Box className="sidebar-tab">
              <Tabs
                // value={value}
                // onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  className="tab-label-btn"
                  label="Activity Log"
                  style={{ color: "var(--primary-text-color)" }}
                  //{...a11yProps(0)}
                />
              </Tabs>
            </Box>
          </Box>
          <Box className="sidebar-tab-wrapper">
            <Box className="sidebar-tab" style={{ paddingLeft: "12px" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {" "}
                <Tab
                  className="tab-label-btn"
                  label="Activities"
                  {...a11yProps(0)}
                />
                <Tab
                  className="tab-label-btn"
                  label="Status"
                  {...a11yProps(1)}
                />
                <Tab
                  className="tab-label-btn"
                  label="Updates"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
          </Box>
          <CustomTabPanel
            value={value}
            index={1}
            className="filter-content-wrapper"
          >
            <Stack spacing={2} sx={{ padding: "24px 14px 24px 24px" }}>
              <Box className="activity-log-list-wrapper">
                {activities?.map((activity) => (
                  <Box className="activity-card-wrapper">
                    <Box className="activity-log-icon activity-call">
                      <RiPencilLine />
                    </Box>
                    <Box className="activity-content-wrapper">
                      <Typography
                        variant="body1"
                        component="span"
                        className="date-time-drawer"
                      >
                        {activity.activity === "call"
                          ? formatDate(activity?.date)
                          : formatDate(activity?.createdAt)}
                        <Typography
                          variant="body1"
                          component="span"
                          className="mobile-time-drawer"
                        >
                          | {formattime(activity?.createdAt)}
                        </Typography>
                      </Typography>
                      <Box className="activity-content">
                        <Box className="activity-content-header">
                          <Typography variant="h6" component="h6">
                            {activity?.action_note}
                          </Typography>
                          <Typography
                            variant="body1"
                            component="span"
                            // style={{ marginRight: "20px" }}
                          >
                            {formattime(activity?.createdAt)}
                          </Typography>
                        </Box>
                        {activity?.time === null &&
                        activity?.comments === null ? null : (
                          <Box className="activity-content-para">
                            {activity.activity === "call" ? (
                              <Typography variant="body1" component="p">
                                Call schedule At :{" "}
                                {convertTo24HourFormat(activity?.time)}
                              </Typography>
                            ) : null}
                            <Typography variant="body1" component="p">
                              {activity?.comments}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Stack>
          </CustomTabPanel>

          <CustomTabPanel
            value={value}
            index={0}
            className="filter-content-wrapper"
          >
            <Stack spacing={2} sx={{ padding: "24px 14px 24px 24px" }}>
              <Box className="add-activity-log-btn">
                <WithPermission
                  permission={updateLeads}
                  element={
                    <Button
                      variant="contained"
                      size="large"
                      className="primary-btn btn add-btn"
                      endIcon={<RiAddCircleLine />}
                      disableRipple
                      onClick={() => {
                        setActivityLogModal(true);
                        setFilteredOptions(defaultOptions);
                      }}
                    >
                      Create Activity
                    </Button>
                  }
                />
              </Box>

              <Box className="activity-log-list-wrapper">
                {activities?.map((activity) => (
                  <Box className="activity-card-wrapper">
                    <Box className="activity-log-icon activity-call">
                      {activity.activity === "call" ? (
                        <RiPhoneLine />
                      ) : activity.activity === "followup" ? (
                        <MdAccessAlarm />
                      ) : activity?.action_note?.includes(
                          "has successfully created the lead"
                        ) ? (
                        <RiAddCircleLine />
                      ) : (
                        <RiDraftLine />
                      )}
                    </Box>
                    <Box className="activity-content-wrapper">
                      <Typography
                        variant="body1"
                        component="span"
                        className="date-time-drawer"
                      >
                        {formatDate(activity?.createdAt)}
                        <Typography
                          variant="body1"
                          component="span"
                          className="mobile-time-drawer"
                        >
                          | {formattime(activity?.createdAt)}
                        </Typography>
                      </Typography>
                      <Box className="activity-content">
                        <Box className="activity-content-header">
                          <Typography variant="h6" component="h6">
                            {activity?.action_note}
                          </Typography>
                          <Typography
                            variant="body1"
                            component="span"
                            // style={{ marginRight: "20px" }}
                          >
                            {formattime(activity?.createdAt)}
                          </Typography>
                        </Box>
                        {activity?.time === null &&
                        activity?.comments === null ? null : (
                          <Box className="activity-content-para">
                            {activity.activity === "call" ||
                            activity.activity === "followup" ? (
                              <Typography
                                variant="body1"
                                component="p"
                                style={{
                                  marginBottom: "10px",
                                  fontWeight: "600",
                                }}
                              >
                                {activity?.activity === "call"
                                  ? "Call"
                                  : "Followup"}{" "}
                                schedule at {formatActivityDate(activity?.date)}
                                {" at "}
                                {convertTo24HourFormat(activity?.time)}
                              </Typography>
                            ) : null}
                            {activity?.comments && (
                              <Typography
                                variant="body1"
                                component="p"
                                className="value"
                                style={{
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {activity.comments}
                              </Typography>
                            )}
                            {activity?.loginuserId === activity?.user_id ? (
                              <Box
                                className="action-btn-wrap"
                                style={{
                                  justifyContent: "end",
                                  gap: "0px",
                                  position: "relative",
                                  right: "-15px",
                                  // position: "relative",
                                  // top:
                                  //   (activity?.activity === "call" ||
                                  //     activity?.activity === "followup") &&
                                  //   activity?.comments !== ""
                                  //     ? "-22px"
                                  //     : "-13px",
                                }}
                              >
                                <ThemeProvider theme={customToolTipTheme}>
                                  <Tooltip
                                    title={
                                      "Editing an activity entry is only allowed within 24 hours of its creation "
                                    }
                                  >
                                    <span>
                                      <WithPermission
                                        permission={updateLeads}
                                        element={
                                          <Button
                                            variant="outlined"
                                            className="action-btn edit-btn"
                                            startIcon={
                                              <RiPencilLine
                                                style={{ fontSize: "16px" }}
                                              />
                                            }
                                            // disableRipple

                                            //title="Edit"
                                            style={{
                                              backgroundColor: "transparent",
                                              opacity:
                                                activity?.hide_data === 0
                                                  ? "0.6"
                                                  : "1",
                                              height: "20px",
                                            }}
                                            onClick={() =>
                                              handleeditopen(activity?.id)
                                            }
                                            disabled={activity?.hide_data === 0}
                                          ></Button>
                                        }
                                      />
                                    </span>
                                  </Tooltip>
                                </ThemeProvider>
                                <ThemeProvider theme={customToolTipTheme}>
                                  <Tooltip
                                    title={
                                      "Deleting an activity entry is only allowed within 24 hours of its creation "
                                    }
                                  >
                                    <span>
                                      <WithPermission
                                        permission={updateLeads}
                                        element={
                                          <Button
                                            variant="outlined"
                                            className="action-btn dlt-btn"
                                            startIcon={
                                              <RiDeleteBinLine
                                                style={{ fontSize: "16px" }}
                                              />
                                            }
                                            disableRipple
                                            style={{
                                              backgroundColor: "transparent",
                                              opacity:
                                                activity?.hide_data === 0
                                                  ? "0.6"
                                                  : "1",
                                              height: "20px",
                                            }}
                                            // title="Delete"
                                            disabled={activity?.hide_data === 0}
                                            onClick={() =>
                                              handleActivityDeleteOpen(
                                                activity.id
                                              )
                                            }
                                          ></Button>
                                        }
                                      />
                                    </span>
                                  </Tooltip>
                                </ThemeProvider>
                              </Box>
                            ) : null}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Stack>
          </CustomTabPanel>

          <CustomTabPanel
            value={value}
            index={2}
            className="filter-content-wrapper"
          >
            <Stack spacing={2} sx={{ padding: "24px 14px 24px 24px" }}>
              <Box className="activity-log-list-wrapper">
                {activities?.map((activity) => (
                  <Box className="activity-card-wrapper">
                    <Box className="activity-log-icon activity-call">
                      {activity?.action_note?.includes(
                        "has successfully created the lead"
                      ) ? (
                        <RiAddCircleLine />
                      ) : (
                        <RiPencilLine />
                      )}
                    </Box>
                    <Box className="activity-content-wrapper">
                      <Typography
                        variant="body1"
                        component="span"
                        className="date-time-drawer"
                      >
                        {activity.activity === "call"
                          ? formatDate(activity?.date)
                          : formatDate(activity?.createdAt)}
                        <Typography
                          variant="body1"
                          component="span"
                          className="mobile-time-drawer"
                        >
                          | {formattime(activity?.createdAt)}
                        </Typography>
                      </Typography>
                      <Box className="activity-content">
                        <Box className="activity-content">
                          <Box className="activity-content-header">
                            <Tooltip
                              title={
                                <div
                                  style={{
                                    color: "white",
                                    fontSize: "14px",
                                  }}
                                >
                                  {!activity?.action_note ||
                                  activity?.action_note.indexOf("~##~") === -1
                                    ? activity?.action_note
                                    : (function () {
                                        const actions =
                                          activity?.action_note.split("~##~");

                                        if (actions.length === 2) {
                                          // If there is one ~##~, display it without bullet points
                                          return actions;
                                        } else {
                                          // If there are multiple ~##~, display each action with bullet points
                                          const formattedActions = actions.map(
                                            (action, index) => (
                                              <div key={index}>
                                                {index === 0 ? (
                                                  <span
                                                    style={{
                                                      color: "white",
                                                      fontSize: "14px",
                                                    }}
                                                  >{` ${action}`}</span>
                                                ) : (
                                                  <ul
                                                    style={{
                                                      marginBottom: "2px",
                                                    }}
                                                  >
                                                    <li
                                                      style={{
                                                        color: "white",
                                                        fontSize: "14px",
                                                      }}
                                                    >
                                                      {" "}
                                                      {action}
                                                    </li>
                                                  </ul>
                                                )}
                                              </div>
                                            )
                                          );

                                          return formattedActions;
                                        }
                                      })()}
                                </div>
                              }
                              arrow
                              placement="bottom-start"
                            >
                              <Typography variant="h6" component="h6">
                                {formatActionNote(activity?.action_note)}
                              </Typography>
                            </Tooltip>
                            <Typography variant="body1" component="span">
                              {formattime(activity?.createdAt)}
                            </Typography>
                          </Box>
                          {activity?.time === null &&
                          activity?.comments === null ? null : (
                            <Box className="activity-content-para">
                              {activity.activity === "call" ? (
                                <Typography variant="body1" component="p">
                                  Call schedule At :{" "}
                                  {convertTo24HourFormat(activity?.time)}
                                </Typography>
                              ) : null}
                              <Typography variant="body1" component="p">
                                {activity?.comments}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Stack>
          </CustomTabPanel>
        </Box>
      </Drawer>
      {/* END :: PROJECT FILTER PANEL */}

      {/*  START :: SAVE ACTIVITY LOG MODAL */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={ActivityLog}
        // onClose={ExportToPdfModalClose}

        closeAfterTransition
      >
        <Fade in={ActivityLog}>
          <div className="modal-wrapper" style={{ padding: "18px" }}>
            <Box
              variant="div"
              component="div"
              className="title"
              sx={{ marginBottom: "24px" }}
            >
              <Typography variant="h3" component="h3">
                Activity Log
              </Typography>
            </Box>
            <Grid
              container
              spacing={2}
              style={{ maxHeight: "500px", overflow: "auto" }}
            >
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Activity
                  <Typography
                    variant="body1"
                    component="span"
                    className="asterisk"
                  >
                    *
                  </Typography>
                </Typography>
                <Select
                  name="colors"
                  options={leadActivityOptions}
                  value={leadActivityOptions?.find(
                    (Option) => Option.value === activity
                  )}
                  onChange={handleOptionChange}
                  className="muilt-select-field"
                  classNamePrefix="select"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                    colors: {
                      ...theme.colors,
                      primary25: "var(--secondary-text-color)",
                      primary: "var(--green)",
                    },
                  })}
                />
                {activityError && (
                  <Typography variant="body1" component="p" className="error">
                    {activityError}
                  </Typography>
                )}
              </Grid>
              <style>
                {`
                .muilt-select-field {
                  .select__placeholder {
                  color:rgba(0, 0, 0, 0.87);
                  font-size:14px
                      }
                }
                `}
              </style>
              {activity && (activity === "call" || activity === "followup") && (
                <>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                      Assignee
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <Select
                      isMulti
                      name="colors"
                      options={memberOptions}
                      value={members}
                      onChange={(selectedOptions, { removedValue }) => {
                        if (removedValue) {
                          const removedOptionId = removedValue.value;

                          setRemoveKeyId([...removeKeyId, removedOptionId]);
                        }
                        handleAssigneeOptionChange(selectedOptions);
                      }}
                      className="muilt-select-field"
                      classNamePrefix="select"
                      placeholder="Select Assignee"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 10,
                        colors: {
                          ...theme.colors,
                          primary25: "var(--secondary-text-color)",
                          primary: "var(--green)",
                        },
                      })}
                    />
                    {assingeeError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {assingeeError}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                      Date
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <DatePicker
                      value={selectedDate !== "" ? selectedDate : null}
                      oneTap
                      onChange={handleDateChange}
                      format="dd-MM-yyyy"
                      //appearance="default"
                      placeholder="dd-mm-yyyy"
                      shouldDisableDate={(date) =>
                        moment(date).isBefore(moment().startOf("day"))
                      }
                      className="modal-date-picker"
                    />
                    {dateError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {dateError}
                      </Typography>
                    )}
                  </Grid>
                  <style>
                    {`
                    .modal-date-picker .rs-picker-toggle.rs-btn .rs-picker-toggle-placeholder{
                      color:rgba(0, 0, 0, 0.87);
                      font-size:14px
                      }
                    `}
                  </style>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography variant="body1" component="label">
                      Time
                      <Typography
                        variant="body1"
                        component="span"
                        className="asterisk"
                      >
                        *
                      </Typography>
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <TimePicker
                          value={dayjs(selectedTime)}
                          onChange={handleTimeChange}
                          className="modal-date-picker"
                          ampm={false}
                          open={isOpen}
                          onClose={() => setIsOpen(false)}
                          slotProps={{
                            textField: {
                              onClick: () => setIsOpen(true),
                              readOnly: true,
                            },
                          }}
                          disableTextMask
                          // slots={{
                          //   openPickerButton: () => null,
                          // }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    {timeError && (
                      <Typography
                        variant="body1"
                        component="p"
                        className="error"
                      >
                        {timeError}
                      </Typography>
                    )}
                  </Grid>
                  <style>
                    {`
                    .rs-stack-item {
                      /* Your inline styles here */
                    display:block
                      /* Add any other styles you want */
                    }
                    .rs-picker-toolbar-right .rs-btn.rs-btn-primary.rs-btn-sm {
                    background-color: var(--primary-text-color) !important;

                    color: #fff !important;

                    top: 5px;
                    width: 100% !important;
                  // }import { moment } from 'moment';

                    `}
                  </style>
                </>
              )}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="body1" component="label">
                  Comments
                  {activity === "note" && (
                    <Typography
                      variant="body1"
                      component="span"
                      className="asterisk"
                    >
                      *
                    </Typography>
                  )}
                </Typography>
                <TextareaAutosize
                  className="textarea-field modal-textarea"
                  placeholder="Add Comments"
                  value={comments}
                  onChange={(e) => {
                    setComments(e.target.value);
                    if (e.target.value) {
                      setCommentError("");
                    }
                  }}
                />
                {commenterror && (
                  <Typography variant="body1" component="p" className="error">
                    {commenterror}
                  </Typography>
                )}
              </Grid>
              <style>
                {`.textarea-field{
                &::-webkit-input-placeholder {
                  color:black;
                  font-size: 14px;
                }
                }
                `}
              </style>
            </Grid>
            {/* </Box> */}
            <Box variant="div" component="div" className="footer">
              <Box variant="div" component="div" className="btn-group">
                <Button
                  variant="contained"
                  size="large"
                  className="primary-btn btn"
                  disableRipple
                  onClick={
                    isEditing ? handleEditActivitylog : handleActivityModal
                  }
                  disabled={isButtonDisabled}
                >
                  {isEditing ? "Update" : "Save"}
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  className="secondary-btn btn"
                  disableRipple
                  onClick={() => {
                    setActivityLogModal(false);
                    //setSelectedOption(null);
                    setActivity("");
                    setCommentError("");
                    setDateError("");
                    setTimeError("");
                    setActivityError("");
                    setIsEditing(false);
                    setComments("");
                    setSelectedDate("");
                    setSelectedTime("");
                    setIsOpen(false);
                    setAssigneeError("");
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
      {/* END :: SAVE ACTIVITY LOG MODAL */}
      <LeadActivityDeleteModal
        handleActivityDeleteOpen={handleActivityDeleteOpen}
        handleActivityDeleteClose={handleActivityDeleteClose}
        activityDelete={activityDelete}
        selectedActivityId={selectedActivityId}
        value={value}
        leadId={leadId}
      />
    </>
  );
};

export default LeadView;

import { createSlice } from "@reduxjs/toolkit";
import { setFeedback } from "./feedbackSlice";
import { callApi } from "../../components/common/commonfunctionforapi";
// import { successToast } from "../../responses/successToast";

const initialState = {
  isLoading: false,
  success: false,
  error: null,
};

const createTechnologySlice = createSlice({
  name: "createTechnology",
  initialState,
  reducers: {
    createTechnologyStart: (state) => {
      state.isLoading = true;
      state.success = false;
      state.error = null;
    },
    createTechnologySuccess: (state) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    },
    createTechnologyFailure: (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload;
    },
  },
});

export const {
  createTechnologyStart,
  createTechnologySuccess,
  createTechnologyFailure,
} = createTechnologySlice.actions;

export default createTechnologySlice.reducer;

export const createTechnology = (techName) => async (dispatch) => {
  try {
    dispatch(createTechnologyStart());

    const response = await callApi({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/technologies`,
      data: { technology_name: techName },
    });

    const data = response.data;
    // console.log(response.data, "lead slice");
    dispatch(createTechnologySuccess());
    // successToast("Lead creatde successfully!");
    return { success: true, message: data.message };
  } catch (error) {
    dispatch(createTechnologyFailure(error.message));
    dispatch(
      setFeedback({
        status: "fail",
        message: error.response.data.message,
        code: error.response.status_code,
      })
    );
    return { success: false, error: error.response.message };
  }
};

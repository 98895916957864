import { callApi } from '../../../components/common/commonfunctionforapi';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setFeedback } from '.././feedbackSlice';

export const fetchTemplates = createAsyncThunk(
  'templates/fetchTemplates',
  async ({ search }, { dispatch, rejectWithValue }) => {
    try {
      const response = await callApi({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/cv_builders`,
        params: {
          search: search,
        },
      });

      return response.data;
    } catch (error) {
      dispatch(
        setFeedback({
          status: 'fail',
          message: error.response.data.message,
          code: error.response.status,
        })
      );
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  templates: [],
  status: 'idle',
  error: null,
};
const Templateslice = createSlice({
  name: 'templates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTemplates.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTemplates.fulfilled, (state, action) => {
        state.status = 'succeeded';

        state.templates = action.payload;
      })
      .addMatcher(
        // Handle both rejected and fulfilled states with the same action
        (action) => action.type.startsWith('templates/fetchTemplates/'),
        (state, action) => {
          if (action.meta.rejectedWithValue) {
            // Handle the rejected state with the defined value
            state.status = 'failed';
            state.error = action.payload.message;
          }
        }
      );
  },
});

export default Templateslice.reducer;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  Button,
  Paper,
  Grid,
  Switch,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import {
  RiArrowLeftLine,
  RiCalendarCheckFill,
  RiArrowDownCircleLine,
} from "react-icons/ri";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchReports } from "../../store/slices/reportSlice";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Loader from "../../components/common/loader";

function capitalizeWords(input) {
  return input?.replace(/\w+/g, function (word) {
    return word?.charAt(0).toUpperCase() + word.slice(1);
  });
}

function capitalizeFirstLetter(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
}

function formatStatus(str) {
  const words = str?.split("_");
  const capitalizedWords = words?.map((word) => capitalizeFirstLetter(word));
  return capitalizedWords?.join(" ");
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -16,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    background: "#ebf5d9",
    fontWeight: "700",
  },
}));

function truncateLabel(label, maxLength) {
  if (label?.length > maxLength) {
    return label.substring(0, maxLength) + "... ";
  }
  return label;
}

function formatDate(inputDate) {
  const options = { year: "numeric", month: "short", day: "numeric" };
  return new Date(inputDate).toLocaleDateString(undefined, options);
}

const KanbanCard = ({
  value,
  title,
  developmentStatus,
  onClick,
  contactPerson, // Define these props
  primaryBDA,
  secondaryBDA,
  secondaryBdaId,
  primaryTL,
  primaryBdaId,
  primaryTlId,
  status,
  startDate,
  projectId,
}) => {
  const getCardClass = (developmentStatus) => {
    switch (developmentStatus) {
      case "stopped":
        return "red";
      case "ongoing":
        return "green";
      default:
        return "orange";
    }
  };
  const cardClass = getCardClass(developmentStatus);

  const handleCardClick = () => {
    // Navigate to the desired path in a new tab
    window.open(`/projects/${projectId}/view`, "_blank");
  };

  return (
    <Paper
      elevation={3}
      className={`custom-card-${cardClass}`}
      onClick={handleCardClick}
    >
      {title === "" ? (
        <Typography
          variant="h6"
          style={{
            fontSize: "16px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          -
        </Typography>
      ) : (
        <Box
          variant="div"
          component="div"
          style={{
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <Tooltip
            title={
              <div
                style={{
                  color: "white",
                  fontSize: "13px",
                }}
              >
                {title}
              </div>
            }
            arrow
            placement="bottom-start"
          >
            <Typography
              variant="h6"
              style={{
                fontSize: "16px",
              }}
            >
              {truncateLabel(capitalizeWords(title), 22)}
            </Typography>
          </Tooltip>
        </Box>
      )}
      <Box>
        <Typography variant="body1" className="field-title">
          Contact Person:{" "}
          {
            <Tooltip
              title={
                <div style={{ color: "white", fontSize: "13px" }}>
                  {capitalizeWords(contactPerson)}
                </div>
              }
              arrow
              placement="bottom-start"
            >
              <span style={{ fontWeight: "600" }}>
                {truncateLabel(capitalizeWords(contactPerson), 13)}
              </span>
            </Tooltip>
          }
        </Typography>
        {value === 0 ? (
          <Typography variant="body1" className="field-title">
            Primary BD:{" "}
            <span style={{ fontWeight: "600" }}>
              {primaryBdaId ? (
                <Tooltip
                  title={
                    <div style={{ color: "white", fontSize: "13px" }}>
                      {capitalizeWords(primaryBDA.first_name)}{" "}
                      {capitalizeWords(primaryBDA.last_name)}
                    </div>
                  }
                  arrow
                  placement="bottom-start"
                >
                  {truncateLabel(
                    `${capitalizeWords(
                      primaryBDA.first_name
                    )} ${capitalizeWords(primaryBDA.last_name)}`,
                    16
                  )}
                </Tooltip>
              ) : (
                "-"
              )}
            </span>
          </Typography>
        ) : null}
        {value === 1 ? (
          <Typography variant="body1" className="field-title">
            Primary TL:{" "}
            <span style={{ fontWeight: "600" }}>
              {primaryTlId ? (
                <Tooltip
                  title={
                    <div style={{ color: "white", fontSize: "13px" }}>
                      {capitalizeWords(primaryTL.first_name)}{" "}
                      {capitalizeWords(primaryTL.last_name)}
                    </div>
                  }
                  arrow
                  placement="bottom-start"
                >
                  {truncateLabel(
                    `${capitalizeWords(primaryTL.first_name)} ${capitalizeWords(
                      primaryTL.last_name
                    )}`,
                    16
                  )}
                </Tooltip>
              ) : (
                "-"
              )}
            </span>
          </Typography>
        ) : null}
        <Typography variant="body1" className="field-title">
          Secondary BD:{" "}
          <span style={{ fontWeight: "600" }}>
            {secondaryBdaId ? (
              <Tooltip
                title={
                  <div style={{ color: "white", fontSize: "13px" }}>
                    {capitalizeWords(secondaryBDA.first_name)}{" "}
                    {capitalizeWords(secondaryBDA.last_name)}
                  </div>
                }
                arrow
                placement="bottom-start"
              >
                {truncateLabel(
                  `${capitalizeWords(
                    secondaryBDA.first_name
                  )} ${capitalizeWords(secondaryBDA.last_name)}`,
                  14
                )}
              </Tooltip>
            ) : (
              "-"
            )}
          </span>
        </Typography>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            // paddingRight: "10px",
            gap: "7px",
            alignItems: "center",
            marginTop: "18px",
          }}
        >
          <Typography
            variant="body1"
            className="field-title"
            style={{ display: "flex", gap: "4px", alignItems: "end" }}
          >
            <Tooltip
              title={
                <div
                  style={{
                    color: "white",
                    fontSize: "13px",
                  }}
                >
                  Development Start Date
                </div>
              }
              arrow
              placement="bottom-start"
            >
              <Box className="datepickericon">
                <RiCalendarCheckFill />
              </Box>
            </Tooltip>
            {
              <span style={{ fontWeight: "600", fontSize: "12px" }}>
                {startDate === null ? (
                  "-"
                ) : (
                  <Tooltip
                    title={
                      <div
                        style={{
                          color: "white",
                          fontSize: "13px",
                        }}
                      >
                        Development Start Date
                      </div>
                    }
                    arrow
                    placement="bottom-start"
                  >
                    {formatDate(startDate)}
                  </Tooltip>
                )}
              </span>
            }
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "right",
              paddingRight: "10px",
              gap: "7px",
              alignItems: "center",
            }}
          >
            <Box
              style={{
                borderRadius: "50%",
                background:
                  status === "live"
                    ? "green"
                    : status === "developing"
                    ? "blue"
                    : status === "beta_testing"
                    ? "yellow"
                    : "red",
                width: "12px",
                height: "12px",
                marginBottom: "2px",
              }}
            ></Box>
            <Typography variant="body1" className="field-title">
              {
                <Tooltip
                  title={
                    <div style={{ color: "white", fontSize: "13px" }}>
                      {formatStatus(
                        status === "developing" ? "Development" : status
                      )}
                    </div>
                  }
                  arrow
                  placement="bottom-start"
                >
                  <span style={{ fontWeight: "600", fontSize: "12px" }}>
                    {formatStatus(
                      status === "developing" ? "Development" : status
                    )}
                  </span>
                </Tooltip>
              }
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

const ReportList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reports = useSelector((state) => state.reports.reports);
  // console.log(reports, "reports");
  const [value, setValue] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isTooltipOpen, setTooltipOpen] = useState(false);
  const [rows, setRows] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [showLoadMore, setShowLoadMore] = useState(false);

  const loadMoreHandler = () => {
    setRows((prev) => prev + 10);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setRows(10);
  };

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
    setRows(10);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const response = await dispatch(
          fetchReports({
            role: value === 0 ? "TL" : "BD",
            hide: isChecked === true ? 1 : 0,
            raw: rows,
          })
        );

        const shouldShowLoadMore = response.payload.some(
          (userData) => userData.total_projects_count > rows
        );

        setShowLoadMore(shouldShowLoadMore);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [dispatch, isChecked, rows, value]);

  const ProjectCard = ({ project, navigate }) => (
    <Grid item key={project.id} style={{ marginBottom: "16px" }}>
      <Paper className="custom-column">
        <KanbanCard
          value={value}
          title={capitalizeWords(project?.project_name)}
          developmentStatus={project?.development_status}
          status={project?.status}
          contactPerson={capitalizeWords(project?.contact_person_name)}
          secondaryBdaId={project?.secondary_bda_id}
          primaryBdaId={project?.primary_bda_id}
          primaryTlId={project?.primary_tl_id}
          startDate={project?.start_date}
          primaryBDA={{
            first_name: capitalizeWords(project?.primary_bda_first_name),
            last_name: capitalizeWords(project?.primary_bda_last_name),
          }}
          secondaryBDA={{
            first_name: capitalizeWords(project?.secondary_bda_first_name),
            last_name: capitalizeWords(project?.secondary_bda_last_name),
          }}
          primaryTL={{
            first_name: capitalizeWords(project?.primary_tl_first_name),
            last_name: capitalizeWords(project?.primary_tl_last_name),
          }}
          projectId={project?.id}
        />
      </Paper>
    </Grid>
  );

  return (
    <>
      {/* ... Header code ... */}
      <Box className="content-header">
        <Typography variant="h2" component="h2" className="title-with-btn">
          <Button
            variant="outlined"
            className="action-btn back-btn"
            startIcon={<RiArrowLeftLine />}
            disableRipple
            onClick={() => {
              navigate("/reports");
            }}
          ></Button>
          Project Allocation
        </Typography>
      </Box>
      {isLoading && !showLoadMore && <Loader />}
      <Box className="content-layout" sx={{paddingBottom:"12px"}}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="tab-project-wrapper">
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="tab-area"
            >
              <Tab label="TL" style={{ color: "var(--primary-text-color)" }} />
              <Tab label="BD" style={{ color: "var(--primary-text-color)" }} />
            </Tabs>
            <Box variant="div" component="div" className="content-header-right">
              <Box
                style={{
                  display: "flex",
                  paddingRight: "10px",
                  gap: "8px",
                  alignItems: "center",
                  flexWrap:"wrap",
                }}
              >
                <Box sx={{display:'flex', alignItems:"center", gap:"8px"}}>
                  <Box
                    style={{
                      borderRadius: "50%",
                      background: "var(--green)",
                      width: "12px",
                      height: "12px",
                      marginBottom: "2px",
                    }}
                  ></Box>
                  <Typography variant="body1" className="field-title">
                    {
                      <span style={{ fontWeight: "600", fontSize: "12px" }}>
                        Ongoing
                      </span>
                    }
                  </Typography>
                </Box>
                <Box sx={{display:'flex', alignItems:"center", gap:"8px"}}>
                  {isChecked ? (
                    <Box
                      style={{
                        borderRadius: "50%",
                        background: "var(--fire-btn-icon)",
                        width: "12px",
                        height: "12px",
                        marginBottom: "2px",
                      }}
                    ></Box>
                  ) : null}
                  {isChecked ? (
                    <Typography variant="body1" className="field-title">
                      {
                        <span style={{ fontWeight: "600", fontSize: "12px" }}>
                          Planned
                        </span>
                      }
                    </Typography>
                  ) : null}
                </Box>
                <Box sx={{display:'flex', alignItems:"center", gap:"8px"}}>
                  {isChecked ? (
                    <Box
                      style={{
                        borderRadius: "50%",
                        background: "var(--delete-btn-icon)",
                        width: "12px",
                        height: "12px",
                        marginBottom: "2px",
                      }}
                    ></Box>
                  ) : null}
                  {isChecked ? (
                    <Typography variant="body1" className="field-title">
                      {
                        <span style={{ fontWeight: "600", fontSize: "12px" }}>
                          Stopped
                        </span>
                      }
                    </Typography>
                  ) : null}
                </Box>
              </Box>
              <FormControlLabel
                control={
                  <Switch checked={isChecked} onChange={handleSwitchChange} />
                }
                label="Show All Projects"
              />
            </Box>
          </Box>
          <TableContainer
            className="billable-report-table-wrapper"
            style={{
              maxHeight: "calc(100vh - 230px)",
              borderRadius: "5px",
              width: "calc(100% - 50px)",
              overflowX: "auto",
              whiteSpace: "nowrap",
              marginTop: "24px",
              paddingBottom: "4px",
              marginLeft: "24px",
            }}
          >
            <Table stickyHeader>
              <TableHead className="head-row">
                <TableRow>
                  {reports?.map((report) => (
                    <TableCell
                      key={report.id}
                      align="center"
                      sx={{
                        // zIndex: 10000,
                        backgroundColor: "#323232",
                        color: "#ffffff",
                        padding: "5px",
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                      onMouseEnter={() => setTooltipOpen(true)}
                      onMouseLeave={() => setTooltipOpen(false)}
                    >
                      {(report?.first_name + report?.last_name)?.length > 16 ? (
                        <Tooltip
                          title={
                            <div
                              style={{
                                color: "white",
                                fontSize: "13px",
                              }}
                            >
                              {capitalizeWords(report?.first_name)}{" "}
                              {capitalizeWords(report?.last_name)}{" "}
                            </div>
                          }
                          arrow
                          placement="bottom-start"
                        >
                          <span style={{ color: "white" }}>
                            {truncateLabel(
                              capitalizeWords(
                                report?.first_name + " " + report?.last_name
                              ),
                              16
                            )}
                          </span>
                        </Tooltip>
                      ) : (
                        <>
                          {capitalizeWords(report?.first_name)}{" "}
                          {capitalizeWords(report?.last_name)}
                        </>
                      )}
                      <StyledBadge
                        badgeContent={
                          report?.total_projects_count === 0
                            ? "0"
                            : report?.total_projects_count
                        }
                      />
                      {isTooltipOpen && (
                        <Tooltip
                          title={capitalizeWords(
                            `${report?.first_name} ${reports.last_name}`
                          )}
                        ></Tooltip>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className="body-row">
                <TableRow>
                  {reports?.map((report) =>
                    report?.project?.length > 0 ? (
                      <TableCell>
                        <Box>
                          {report?.project?.map((project) => (
                            <ProjectCard
                              key={project?.id}
                              project={project}
                              navigate={navigate}
                            />
                          ))}
                        </Box>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <Box>
                          <Grid>
                            <Paper className="custom-column">
                              <Typography
                                variant="body1"
                                className="field-title"
                                style={{
                                  backgroundColor: "var(--light-grey)",
                                  paddingLeft: "7px",
                                  height: "150px",
                                  borderLeft: "4px solid var(--textgrey)",
                                  borderRadius:"4px",
                                }}
                              >
                                <Typography
                                  component="h6"
                                  variant="h6"
                                  style={{
                                    paddingTop: "10px",
                                    fontWeight: "500",
                                    fontSize: "16px",
                                  }}
                                >
                                  No Projects Found
                                </Typography>
                              </Typography>
                            </Paper>
                          </Grid>
                        </Box>
                      </TableCell>
                    )
                  )}
                </TableRow>
              </TableBody>
            </Table>
            {isLoading && showLoadMore && (
              <Box className="report-load-more-section">
                <CircularProgress />
              </Box>
            )}
            {showLoadMore && !isLoading && (
              <Box className="report-load-more-section">
                <Button
                  variant="contained"
                  size="medium"
                  className="primary-btn btn add-btn"
                  endIcon={<RiArrowDownCircleLine />}
                  disableRipple
                  onClick={loadMoreHandler}
                >
                  <Typography variant="body1" component="p">
                    Load More
                  </Typography>
                </Button>
              </Box>
            )}
        </TableContainer>
      </Box>
    </>
  );
};

export default ReportList;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  Tabs,
  Tab,
  Chip,
  Grid,
  Tooltip,
  Box,
  Fade,
  Modal,
  Typography,
  IconButton,
  ListItem,
  ListItemText,
  Link,
  Divider,
} from "@mui/material";
import { callApi } from "./commonfunctionforapi";
import CloseIcon from "@mui/icons-material/Close";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import * as techstackpop from "../../styles/techstackpop.scss";
import { height, width } from "@mui/system";
const ViewProjectsBidsModal = ({
  open,
  setViewModalOpen,
  techId,
  techStack,
  componentFor,
  path,
}) => {
  const [value, setValue] = useState(0);
  const [data, setData] = useState({
    Projects: [],
    ProjectsId: [],
    Leads: [],
    LeadsId: [],
    Bids: [],
    BidsId: [],
  });
  let navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(0);
    async function getData() {
      let response;
      if (componentFor === "technology") {
        response = await callApi({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/technologies/${techId}/projects`,
        });
      } else {
        response = await callApi({
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/keywords/${techId}/projects`,
        });
      }

      let { Bids, Leads, Projects } = response.data;

      setData({
        Projects: Projects.map((ele) => ele.project_name),
        ProjectsId: Projects.map((ele) => ele.id),
        Leads: Leads.map((ele) => ele.project_name),
        LeadsId: Leads.map((ele) => ele.id),
        Bids: Bids.map((ele) => ele.title),
        BidsId: Bids.map((ele) => ele.id),
      });
    }
    getData();
  }, [techId]);

  // const tabLabel = (role) => {
  //   return (
  //     <Box>
  //       <span className="user-name">{role}</span>
  //     </Box>
  //   );
  // };
  const StyledBadge = styled(Badge)(() => ({
    "& .MuiBadge-badge": {
      position: "unset",
      transform: "unset",
      marginLeft: "4px",
      lineHeight: "16px",
      // right: -16,
      // top: -2,
      fontSize: "14px",
      padding: "0 4px",
      background: "#7cb900",
      fontWeight: "700",
      color: "#fff",
      zIndex: 0,
      marginTop: "-4px",
    },
  }));
  const tabLabel = (role, count) => {
    return (
      <Box>
        <span variant="body1" component="span" className="user-name">
          {role}
        </span>{" "}
        {count !== 0 && (
          <StyledBadge badgeContent={count} max={99999}></StyledBadge>
        )}
      </Box>
    );
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Box>
    );
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        onClose={() => setViewModalOpen(false)}
      >
        <Fade in={open}>
          <Box
            className="modal-wrapper techstackpop"
            sx={{
              maxWidth: "none",
              width: "75vw",
              height: "85vh",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              padding: "18px",
            }}
          >
            <Box
              variant="div"
              component="div"
              className="title"
              sx={{ marginBottom: "24px" }}
            >
              <Stack
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography variant="h3" component="h3">
                  {componentFor === "technology"
                    ? tabLabel(
                        `Technology Stack : ${techStack}`,
                        data?.Leads?.length +
                          data?.Projects?.length +
                          data?.Bids?.length
                      )
                    : tabLabel(
                        `Keyword : ${techStack}`,
                        data?.Leads?.length +
                          data?.Projects?.length +
                          data?.Bids?.length
                      )}
                  {/* <StyledBadge badgeContent={data?.Leads?.length + data?.Projects?.length + data?.Bids?.length}
                    color="success"
                    max={9999} showZero> {techStack} </StyledBadge> */}
                </Typography>
                <IconButton
                  color="inherit"
                  onClick={() => setViewModalOpen(false)}
                >
                  <CloseIcon></CloseIcon>
                </IconButton>
              </Stack>
            </Box>

            <Box
              variant="div"
              component="div"
              className="tab-wrapper user-listing"
            >
              <Box
                className="tab-checkbox-wrapper"
                sx={{
                  overflow: "auto",
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="custom-auto"
                  indicatorColor="secondary"
                  aria-labelledby="tabsaria"
                  sx={{ overflowX: "auto", overflowY: "auto" }}
                >
                  {/* <Tab style={{ padding: "8px 25px",textAlign:'center' }} label={<StyledBadge showZero badgeContent={data?.Projects?.length}
                        color="success"
                        max={9999}>{tabLabel("Projects")}</StyledBadge>} {...a11yProps(0)} /> */}
                  <Tab
                    key={0 + "_resignation_top_tabs"}
                    label={tabLabel("Projects", data?.Projects?.length)}
                    {...a11yProps(0)}
                  />

                  {/* <Tab style={{ padding: "8px 25px",textAlign:'center'  }} label={<StyledBadge showZero badgeContent={data?.Leads?.length}
                        color="success"
                        max={9999} >{tabLabel("Leads")} </StyledBadge>} {...a11yProps(1)} /> */}
                  <Tab
                    key={1 + "_resignation_top_tabs"}
                    label={tabLabel("Leads", data?.Leads?.length)}
                    {...a11yProps(0)}
                  />

                  {/* <Tab style={{ padding: "8px 25px",textAlign:'center'  }} label={<StyledBadge showZero badgeContent={data?.Bids?.length}
                        color="success"
                        max={9999}>{tabLabel("Bids")}</StyledBadge>} {...a11yProps(2)} /> */}
                  <Tab
                    key={2 + "_resignation_top_tabs"}
                    label={tabLabel("Bids", data?.Bids?.length)}
                    {...a11yProps(0)}
                  />
                </Tabs>
              </Box>

              <CustomTabPanel
                value={value}
                index={0}
                className="user-listing-tables"
              >
                {data.Projects.length > 0 ? (
                  <Box className="checkbox-list-wrapper">
                    <List>
                      {data.Projects.map((ele, index) => (
                        <Link
                          href={`/projects/${data.ProjectsId[index]}/view`}
                          target="_blank"
                          underline="none"
                          sx={{
                            textDecoration: "none", // Ensures no underline at any state
                            "&:hover": {
                              textDecoration: "none", // No underline on hover
                            },
                            "&:focus": {
                              textDecoration: "none", // No underline on focus
                            },
                            "&:visited": {
                              textDecoration: "none", // No underline after visiting
                            },
                          }}
                        >
                          <ListItem
                            key={index}
                            className="tab-checkbox-list"
                            disablePadding
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{
                                marginRight: 1,
                                marginTop: "3px",
                                marginBottom: "4px",
                              }}
                              alignSelf={"flex-start"}
                            >
                              {index + 1}.
                            </Typography>
                            <ListItemText
                              primary={
                                <>
                                  <Typography
                                    noWrap
                                    variant="subtitle1"
                                    sx={{
                                      display: "-webkit-box",
                                      WebkitBoxOrient: "vertical",
                                      WebkitLineClamp: 2,
                                      lineHeight: 1.5,
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "normal", // Ensure the text wraps instead of staying in one line
                                    }}
                                  >
                                    <Tooltip
                                      title={ele}
                                      placement="bottom"
                                      arrow
                                    >
                                      <span>{ele}</span>
                                    </Tooltip>
                                  </Typography>
                                </>
                              }
                            />
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  </Box>
                ) : null}

                {data.Projects.length === 0 && (
                  <Box
                    component="div"
                    sx={{
                      textAlign: "center",
                      width: "100%",
                      height: "100%",
                      padding: "20px",
                      color: "GrayText",
                    }}
                  >
                    <Typography variant="body1">No Projects Found</Typography>
                  </Box>
                )}
              </CustomTabPanel>

              <CustomTabPanel
                value={value}
                index={1}
                className="user-listing-tables"
              >
                {data.Leads.length > 0 ? (
                  <Stack
                    className="checkbox-list-wrapper"
                    aria-labelledby="hey "
                  >
                    <List className="tab-checkbox-list">
                      {data.Leads.map((ele, index) => (
                        <>
                          <Link
                            href={`/leads/${data.LeadsId[index]}/view`}
                            target="_blank"
                            underline="none"
                            sx={{
                              textDecoration: "none", // Ensures no underline at any state
                              "&:hover": {
                                textDecoration: "none", // No underline on hover
                              },
                              "&:focus": {
                                textDecoration: "none", // No underline on focus
                              },
                              "&:visited": {
                                textDecoration: "none", // No underline after visiting
                              },
                            }}
                          >
                            <ListItem
                              key={index}
                              className="tab-checkbox-list"
                              disablePadding
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  marginRight: 1,
                                  marginTop: "3px",
                                  marginBottom: "4px",
                                }}
                                alignSelf={"flex-start"}
                              >
                                {index + 1}.
                              </Typography>
                              <ListItemText
                                primary={
                                  <>
                                    
                                      <Typography
                                        noWrap
                                        variant="subtitle1"
                                        sx={{
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          WebkitLineClamp: 2,
                                          lineHeight: 1.5,
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "normal", // Ensure the text wraps instead of staying in one line
                                        }}
                                      >
                                       <Tooltip
                                      title={ele}
                                      placement="bottom"
                                      arrow
                                    >
                                      <span>{ele}</span>
                                    </Tooltip>
                                      </Typography>
                                    
                                  </>
                                }
                              />
                            </ListItem>
                          </Link>
                        </>
                      ))}
                    </List>
                  </Stack>
                ) : null}

                {data.Leads.length === 0 && (
                  <Box
                    component="div"
                    sx={{
                      textAlign: "center",
                      width: "100%",
                      height: "100%",
                      padding: "20px",
                      color: "GrayText",
                    }}
                  >
                    <Typography variant="body1">No Leads Found</Typography>
                  </Box>
                )}
              </CustomTabPanel>

              <CustomTabPanel
                value={value}
                index={2}
                className="user-listing-tables"
              >
                {data.Bids.length > 0 ? (
                  <Box className="checkbox-list-wrapper">
                    <List>
                      {data.Bids.map((ele, index) => (
                        <>
                          <Link
                            href={`/bids/${data.BidsId[index]}/view`}
                            target="_blank"
                            underline="none"
                            sx={{
                              textDecoration: "none", // Ensures no underline at any state
                              "&:hover": {
                                textDecoration: "none", // No underline on hover
                              },
                              "&:focus": {
                                textDecoration: "none", // No underline on focus
                              },
                              "&:visited": {
                                textDecoration: "none", // No underline after visiting
                              },
                            }}
                          >
                            <ListItem
                              key={index}
                              className="tab-checkbox-list"
                              disablePadding
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  marginRight: 1,
                                  marginTop: "3px",
                                  marginBottom: "4px",
                                }}
                                alignSelf={"flex-start"}
                              >
                                {index + 1}.
                              </Typography>
                              <ListItemText
                                primary={
                                  <>
                                   
                                      <Typography
                                        noWrap
                                        variant="subtitle1"
                                        sx={{
                                          display: "-webkit-box",
                                          WebkitBoxOrient: "vertical",
                                          WebkitLineClamp: 2,
                                          lineHeight: 1.5,
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "normal", // Ensure the text wraps instead of staying in one line
                                        }}
                                      >
                                        <Tooltip
                                      title={ele}
                                      placement="bottom"
                                      arrow
                                    >
                                      <span>{ele}</span>
                                    </Tooltip>
                                      </Typography>
                                   
                                  </>
                                }
                              />
                            </ListItem>
                          </Link>
                        </>
                      ))}
                    </List>
                  </Box>
                ) : null}

                {data.Bids.length === 0 && (
                  <Box
                    component="div"
                    sx={{
                      textAlign: "center",
                      width: "100%",
                      height: "100%",
                      padding: "20px",
                      color: "GrayText",
                    }}
                  >
                    <Typography variant="body1">No Bids Found</Typography>
                  </Box>
                )}
              </CustomTabPanel>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ViewProjectsBidsModal;
